import {
  useMyTrialQuery,
  useSetAvatarMutation,
  useSetEmergencyPhoneNumbersMutation,
  useSetNicknameMutation,
} from '~/graphql/operations';
import { computed, unref, watch } from 'vue';
import { rawAccessToken } from '~/auth';
import { logicAnd, logicNot } from '@vueuse/math';
import { AvatarIcon, EmergencyContactInput } from '~/graphql/types';
import { LocationQueryRaw, RouteParams, RouteQueryAndHash } from 'vue-router';
import { useDebounceFn } from '@vueuse/shared';

export function useMyCurrentTrial() {
  const { executeMutation: avatarMutation } = useSetAvatarMutation();
  const { executeMutation: phoneNumbersMutation } =
    useSetEmergencyPhoneNumbersMutation();
  const { executeMutation: nicknameMutation } = useSetNicknameMutation();

  const debouncedPhoneNumbersMutation = useDebounceFn(
    phoneNumbersMutation,
    800
  );

  const debouncedNicknameMutation = useDebounceFn(nicknameMutation, 800);

  const { data, executeQuery, isPaused } = useMyTrialQuery({
    pause: logicNot(rawAccessToken),
  });

  watch(rawAccessToken, () =>
    executeQuery({ requestPolicy: 'cache-and-network' })
  );

  const avatar = computed<AvatarIcon | null | undefined>({
    get() {
      return trial.value?.avatar as AvatarIcon;
    },
    async set(avatar) {
      if (!trial.value) throw new Error('no current trial');
      await avatarMutation({ avatar });
    },
  });

  const lastResumableRoute = computed(
    () =>
      trial.value?.lastResumableRoute as {
        fullPath: string;
        hash?: string | null;
        name?: string | null;
        params?: RouteParams | null;
        path?: string | null;
        query?: LocationQueryRaw | null;
        resumable?: boolean | null;
      }
  );

  const nickname = computed<string | null | undefined>({
    get() {
      return trial.value?.subjectsNickname;
    },
    async set(nickname) {
      if (!trial.value) throw new Error('no current trial');
      await debouncedNicknameMutation({
        nickname: nickname?.trim() ? nickname : null,
      });
    },
  });

  const emergencyPhoneNumbers = computed<
    Array<EmergencyContactInput | null | undefined> | undefined
  >({
    get() {
      return trial.value?.emergencyPhoneNumbers;
    },
    async set(phoneNumbers) {
      if (!trial.value) throw new Error('no current trial');
      if (!phoneNumbers) throw new Error('phoneNumbers must be set');
      await debouncedPhoneNumbersMutation({
        phoneNumbers: phoneNumbers as EmergencyContactInput[],
      });
    },
  });

  const trial = computed(() =>
    isPaused.value ? undefined : data.value?.myCurrentTrial
  );

  const isAvatarComplete = logicAnd(avatar);
  const isNicknameComplete = computed(() => nickname.value?.trim());
  const isEmergencyPhoneNumbersComplete = computed(
    () => true
    // filled in at least one emergency phone number
    //emergencyPhoneNumbers.value?.some(
    //  (entry) => entry?.phoneNumber?.trim() && entry?.name?.trim()
    //)
  );

  const isOnboardingComplete = logicAnd(
    isNicknameComplete,
    isEmergencyPhoneNumbersComplete,
    isAvatarComplete
  );

  const isInTreatment = computed(() => {
    const currentTrial = unref(trial);
    if (!currentTrial) return undefined;
    else
      return (
        !!currentTrial.canAccessErSince || !!currentTrial.canAccessSipSince
      );
  });

  const currentIntervention = computed(() => {
    const currentTrial = unref(trial);
    if (!currentTrial) return undefined;
    else if (currentTrial.canAccessSipSince && currentTrial.canAccessErSince)
      return currentTrial.canAccessSipSince > currentTrial.canAccessErSince
        ? 'sip'
        : 'er';
    else if (currentTrial.canAccessSipSince) return 'sip';
    else if (currentTrial.canAccessErSince) return 'er';
    else return undefined;
  });

  return {
    trial,
    avatar,
    nickname,
    emergencyPhoneNumbers,
    isAvatarComplete,
    isNicknameComplete,
    isEmergencyPhoneNumbersComplete,
    isOnboardingComplete,
    lastResumableRoute,
    currentIntervention,
    isInTreatment,
  };
}
