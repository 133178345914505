/*
 * How to add icons: https://fontawesome.com/docs/web/use-with/vue/add-icons
 */

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faUserSecret as fasUserSecret,
  faBackward,
  faChevronLeft,
  faPlay,
  faStop,
  faBackwardFast,
  faForwardFast,
  faRepeat,
  faCircle,
  faDotCircle,
  faPen,
  faLightbulb,
  faMusic,
  faPlus,
  faDice,
  faHeadphones,
  faPersonWalking,
  faLungs,
  faHatWizard,
  faTape,
  faBook,
  faCouch,
  faArrowRightFromBracket,
  faCropSimple,
  faScrewdriverWrench,
  faSuitcaseMedical,
  faBullseye,
  faEnvelope,
  faChartLine,
  faPerson,
  faImages,
  faGem,
  faPersonShelter,
  faHand,
  faArrowsLeftRightToLine,
  faEarListen,
  faCalendarDays,
  faInbox,
  faUserGroup,
  faMask,
  faList,
  faImage,
  faTent,
  faSpa,
  faShieldDog,
  faQuestion,
  faPersonChalkboard,
  faTree,
  faFaceSmile,
  faPeopleGroup,
  faGift,
  faCircleStop,
  faPause,
  faBookBookmark,
  faStar,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';

library.add(
  faXmark,
  fasUserSecret,
  faUser,
  faBackward,
  faChevronLeft,
  faPlay,
  faStop,
  faBackwardFast,
  faForwardFast,
  faRepeat,
  faCircle,
  faDotCircle,
  faPen,
  faLightbulb,
  faMusic,
  faPlus,
  faDice,
  faHeadphones,
  faPersonWalking,
  faLungs,
  faHatWizard,
  faTape,
  faBook,
  faCouch,
  faArrowRightFromBracket,
  faCropSimple,
  faScrewdriverWrench,
  faSuitcaseMedical,
  faBullseye,
  faEnvelope,
  faChartLine,
  faPerson,
  faImages,
  faGem,
  faPersonShelter,
  faHand,
  faArrowsLeftRightToLine,
  faEarListen,
  faCalendarDays,
  faInbox,
  faUserGroup,
  faMask,
  faList,
  faImage,
  faTent,
  faSpa,
  faShieldDog,
  faQuestion,
  faPersonChalkboard,
  faTree,
  faFaceSmile,
  faPeopleGroup,
  faGift,
  faCircleStop,
  faPause,
  faBookBookmark,
  faStar
);

export default library;
