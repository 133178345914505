<template>
  <router-view v-slot="{ Component }">
    <template v-if="Component">
      <suspense>
        <!-- show page-->
        <component :is="Component"></component>
        <!-- show fallback while `async setup()` is resolving... -->
        <template #fallback>loading...</template>
      </suspense>
    </template>
  </router-view>
</template>
