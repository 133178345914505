<template>
  <article class="container">
    <div class="measurement__top">
      <!--
      <img :src="logoImg" class="measurement__start-logo"/>
      -->
      <nav v-if="isSignedIn">
        <a href="#" @click.prevent="logout()">Ausloggen</a>
        <router-link
          v-if="
            hasResourceRole('assistant', 'face-trial-app') ||
            hasResourceRole('admin', 'face-trial-app')
          "
          to="/admin/cases"
          >Fallübersicht</router-link
        >
      </nav>
      <nav v-else>
        <a href="#" @click.prevent="login()">Einloggen</a>
        <a href="#" @click.prevent="register()">Neu anmelden</a>
      </nav>
    </div>
    <section v-if="!isSignedIn">
      <p>Herzlich willkommen!</p>
      <p>
        Sie sind nicht eingeloggt. Um an der Studie mitzumachen, können sich
        <a href="#" @click.prevent="login()">einloggen</a> oder, falls Sie zum
        ersten Mal hier sind,
        <a href="#" @click.prevent="register()">neu anmelden.</a>
      </p>
    </section>
    <user-tasks
      v-if="isSignedIn"
      with-key="fill-in-ema"
      include-variables
      :assigned-to="true"
    >
      <template #empty>
        <section>
          <p>Hallo {{ accessToken?.preferred_username }}</p>
          <p>
            Im Moment gibt es keine Fragen zu beantworten. Mit dem nächsten SMS
            erhalten Sie die weiteren Fragen. Wir freuen uns auf Ihre Antworten!
          </p>
          <p>
            <router-link to="/ema/chart3"
              >Ihre Messungen als Grafik</router-link
            >
          </p>
        </section>
      </template>

      <template #default="{ task }">
        <section>
          <p>Hallo {{ accessToken?.preferred_username }}</p>
          <p>
            <router-link
              :to="{
                name: 'ema-id',
                params: { id: task.variables?.emaId?.value },
              }"
              >Hier geht’s zu den Fragen.
            </router-link>
          </p>
        </section>
      </template>
    </user-tasks>

    <nav v-if="hasRole('test-participant')">
      <router-link to="/tech">Technische Daten</router-link>
      <router-link to="/ema/all">Alle EMA-Items</router-link>
    </nav>
  </article>
</template>

<script lang="ts" setup>
import { useNow } from '@vueuse/core';
import { computed } from 'vue';
import {
  accessToken,
  isSignedIn,
  login,
  register,
  logout,
  hasRole,
  hasResourceRole,
} from '~/auth';
import { useFetchEmaEntriesQuery } from '~/graphql/operations';

const now = useNow({ interval: 30 * 1000 /* 30s */ });
const ago = computed(() => {
  return new Date(now.value.getTime() - 35 * 60 * 1000 /* 35m */);
});

const { data } = useFetchEmaEntriesQuery({
  variables: computed(() => ({
    orderBy: 'SCHEDULED_AT_ASC',
    filter: {
      completedAt: { isNull: true },
      scheduledAt: {
        greaterThan: ago.value.toISOString(),
        lessThanOrEqualTo: now.value.toISOString(),
      },
    },
  })),
});

const currentEma = computed(() => data.value?.emaEntries3s?.nodes[0]);
</script>

<style scoped>
article {
  display: grid;
  grid-template-rows: min-content auto min-content;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 1rem;
}

section {
  max-width: 600px;
  text-align: left;
  justify-self: center;
  align-self: center;
}

nav {
  display: flex;
  gap: 1rem;
  justify-self: end;
}
</style>
