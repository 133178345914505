import { computed, ref, watch, watchEffect } from 'vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { print as printGraphqlAst } from 'graphql/language/printer';
import useKeycloak from '~/utils/keycloak';
import { linkWithLocalStorage } from '~/utils/link-with-storage';
import { RegisterAfterKeycloakSignIn } from '~/graphql/operations';

export const rawRefreshToken = linkWithLocalStorage(
  ref<string | null>(),
  'refreshToken'
);
export const rawAccessToken = linkWithLocalStorage(
  ref<string | null>(),
  'accessToken'
);

export const {
  accessToken,
  login: loginWithKeycloak,
  logout: logoutFromKeycloak,
  ready,
  hasRole,
  hasResourceRole,
  refreshToken,
} = useKeycloak({
  url: 'https://www.face-selbsthilfe-app.ch/auth',
  realm: 'face-trial',
  clientId: 'face-trial-app',
  checkLoginIframe: false,
  accessToken: rawAccessToken,
  refreshToken: rawRefreshToken,
});

export const logout = async () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  await logoutFromKeycloak();
};

export const login = async () => {
  const route = useRoute();
  const redirectUri =
    route && route.query.afterLogin
      ? new URL(route.query.afterLogin as string, window.document.baseURI).href
      : window.document.baseURI;

  return await loginWithKeycloak({
    redirectUri,
    scope: 'openid email offline_access',
  });
};

export const register = async () => {
  const route = useRoute();
  const redirectUri =
    route && route.params.afterLogin
      ? new URL(route.query.afterLogin as string, window.document.baseURI).href
      : window.document.baseURI;

  return await loginWithKeycloak({
    redirectUri,
    scope: 'openid email phone offline_access',
    action: 'register',
  });
};

export const currentUserId = computed(() => accessToken.value?.sub);

export const isSignedIn = computed(() => {
  return typeof currentUserId.value === 'string';
});

export const ifSignedOut = (
  callback: (invalidate?: (cb: () => void) => void) => void
) => {
  watchEffect((invalidate) => {
    if (!rawAccessToken.value) callback(invalidate);
  });
};

export const ifSignedIn = (
  callback: (invalidate?: (cb: () => void) => void) => void
) => {
  watchEffect((invalidate) => {
    if (typeof rawAccessToken.value == 'string') callback(invalidate);
  });
};

export const currentUser = ref<{ id: string; username: string }>();

watch(
  () => accessToken.value?.sub,
  async (newSub, oldSub, invalidate) => {
    if (
      !newSub ||
      !navigator.onLine ||
      newSub === oldSub ||
      !rawAccessToken.value
    )
      return;

    // abort requests, if newSub changed while they run.
    const abortController = new AbortController();
    invalidate(() => abortController.abort());

    // use the JWT to register or update an application user.
    const createUserResponse = await fetch('/graphql', {
      method: 'POST',
      signal: abortController.signal,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${rawAccessToken.value}`,
      },
      body: JSON.stringify({
        query: printGraphqlAst(RegisterAfterKeycloakSignIn),
      }),
    });

    const {
      data: {
        signUpWithAccessToken: {
          result: { user },
        },
      },
    } = await createUserResponse.json();
    currentUser.value = user;
  },
  {
    immediate: true,
  }
);
