import {
  TaskPostQuery,
  TaskPostQueryResult,
  TypedVariable,
  VariableFilter,
} from '~/domain/TaskPostQuery';
import { computedAsync, MaybeRef } from '@vueuse/core';
import { readonly, ref, unref, watch } from 'vue';
import { useMyCurrentTrial } from '~/domain/use-my-current-trial';
import { rawAccessToken } from '~/auth';

export interface UseTasksConfig {
  assignedTo?: MaybeRef<string | boolean | undefined>;
  key?: MaybeRef<string | string[] | undefined>;
  variables?: MaybeRef<VariableFilter[] | undefined>;
  taskVariables?: MaybeRef<VariableFilter[] | undefined>;
  includeVariables?: MaybeRef<boolean | undefined>;
  includeLocalVariables?: MaybeRef<boolean | undefined>;
  pause?: MaybeRef<boolean | undefined>;
  poll?: MaybeRef<number | undefined>;
}

// https://docs.camunda.org/manual/7.18/reference/rest/task/get-query/
// https://docs.camunda.org/manual/7.18/reference/rest/task/post-query/
export function useTasks(config: UseTasksConfig) {
  const { trial: trialRef } = useMyCurrentTrial();

  // setup polling
  let pollInterval: number;
  const fetches = ref(0);

  function reload() {
    fetches.value += 1;
  }

  watch(
    () => unref(config.poll),
    (pollValue) => {
      if (pollInterval) window.clearInterval(pollInterval);
      if (pollValue) pollInterval = window.setInterval(reload, pollValue);
    },
    {
      immediate: true,
    }
  );

  const tasks = computedAsync(
    async (onCancel) => {
      unref(fetches);
      const trial = unref(trialRef);
      const currentToken = unref(rawAccessToken);
      const assignedTo = unref(config.assignedTo);
      const taskKey = unref(config.key);
      const variables = unref(config.variables);
      const taskVariables = unref(config.taskVariables);
      const includeVariables = unref(config.includeVariables);
      const includeLocalVariables = unref(config.includeLocalVariables);
      const pause = unref(config.pause);
      if (pause) return undefined;
      const abortController = new AbortController();

      async function cancellableFetch(
        url: RequestInfo,
        init?: Pick<RequestInit, 'body' | 'method'>
      ) {
        const headers: HeadersInit = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        };
        if (currentToken) headers.Authorization = `Bearer ${currentToken}`;
        const response = await fetch(url, {
          signal: abortController.signal,
          headers,
          ...init,
        });

        if (response.status >= 400)
          throw new Error(
            `response failed with status ${response.status}: ${response.statusText}`
          );
        else return await response.json();
      }

      const query: TaskPostQuery = { processVariables: [], taskVariables: [] };

      // query by assignee, if given
      if (assignedTo === true) {
        query.assigneeExpression = '${currentUser()}';
      } else if (typeof assignedTo === 'string') {
        if (assignedTo.match(/^\$\{.+}$/)) {
          query.assigneeExpression = assignedTo;
        } else {
          query.assignee = assignedTo;
        }
      }

      // query by task definition key, if given
      if (typeof taskKey === 'string') query.taskDefinitionKey = taskKey;
      else if (taskKey) query.taskDefinitionKeyIn = taskKey.join(',');

      // query by variables
      if (variables) query.processVariables?.push(...variables);
      if (taskVariables) query.taskVariables?.push(...taskVariables);

      // query by trial
      if (trial)
        query.processVariables?.push({
          name: 'trialId',
          operator: 'eq',
          value: trial.id,
        });

      // run query
      onCancel(() => abortController.abort());
      const result: TaskPostQueryResult[] = await cancellableFetch(
        '/camunda/engine-rest/task',
        {
          method: 'POST',
          body: JSON.stringify(query),
        }
      );

      // fetch variables, if required
      const variableRequests: Array<Promise<void>> = [];

      if (includeVariables) {
        variableRequests.push(
          ...result.map(async (task) => {
            task.variables = await cancellableFetch(
              `/camunda/engine-rest/task/${task.id}/variables`
            );
          })
        );
      }

      if (includeLocalVariables)
        variableRequests.push(
          ...result.map(async (task) => {
            task.localVariables = await cancellableFetch(
              `/camunda/engine-rest/task/${task.id}/localVariables`
            );
          })
        );

      await Promise.allSettled(variableRequests);

      return result;
    },
    [] as TaskPostQueryResult[],
    {
      onError(error) {
        console.error(error);
      },
    }
  );

  return { tasks, reload, fetches: readonly(fetches) };
}

export interface CompleteTaskParams {
  id: string;
  variables?: Record<string, TypedVariable>;
  withVariablesInReturn?: boolean;
}

export async function completeTask({ id, ...body }: CompleteTaskParams) {
  const completeRequest = await fetch(
    `/camunda/engine-rest/task/${id}/complete`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${rawAccessToken.value}`,
      },
      body: JSON.stringify(body),
    }
  );

  if (completeRequest.status < 400) return await completeRequest.json();
  else {
    console.error(await completeRequest.json());
    throw new Error(
      `failed to complete task ${id} with status ${completeRequest.status} ${completeRequest.statusText}`
    );
  }
}
