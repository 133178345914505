import { RouteRecordRaw } from 'vue-router';
import generatedRoutes from '~pages';

export function getTitleForRoute(routeId: string) {
  for (const route of generatedRoutes as RouteRecordRaw[]) {
    if (route.name === routeId) {
      const meta = route.meta as any | undefined;
      return (
        meta?.frontmatter?.shortTitle ??
        meta?.frontmatter?.title ??
        meta?.title ??
        route.name
      );
    }
  }
  return routeId;
}
