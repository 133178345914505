<template>
  <button @click="showMenu = true">
    <img src="/icons/menu.svg" alt="Menü öffnen" />
  </button>
  <teleport v-if="showMenu" to="body">
    <div id="burger-menu-overlay">
      <div id="burger-menu">
        <button @click="showMenu = false">
          <font-awesome-icon icon="fa-xmark" style="font-size: 2rem" />
        </button>
        <nav>
          <router-link :to="{ name: 'dashboard' }" @click="showMenu = false"
            >Dashboard</router-link
          >
          <router-link v-if="isInTreatment" :to="{ name: 'chat' }" @click="showMenu = false"
            >Nachrichten</router-link
          >
          <router-link :to="{ name: 'imprint' }" @click="showMenu = false"
            >Impressum</router-link
          >
          <div
            v-if="isSignedIn"
            style="cursor: pointer"
            @click="
              showMenu = false;
              logout();
            "
          >
            Ausloggen
          </div>
          <div
            v-if="!isSignedIn"
            style="cursor: pointer"
            @click="
              showMenu = false;
              login();
            "
          >
            Einloggen
          </div>
          <div
            v-if="!isSignedIn"
            style="cursor: pointer"
            @click="
              showMenu = false;
              register();
            "
          >
            Erstmalig anmelden
          </div>
        </nav>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { useStorage } from '@vueuse/core';
import { isSignedIn, login, logout, register } from '~/auth';
import { useMyCurrentTrial } from '~/domain/use-my-current-trial';

const showMenu = useStorage<boolean>('showBurgerMenu', false, sessionStorage);
const { isInTreatment } = useMyCurrentTrial();
</script>

<style scoped>
a[href] {
  color: black;
}

#burger-menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#burger-menu {
  display: grid;
  align-content: flex-start;
  width: 80%;
  float: right;
  height: 100%;
  overflow-y: scroll;
  padding: var(--page-margin);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.95),
    rgba(255, 255, 255, 0.85)
  );
  color: black;

  & > button {
    justify-self: flex-end;
  }

  & > nav {
    margin-top: var(--space-xxl);
    font-weight: 450;
    font-size: larger;
    display: grid;
    gap: var(--space-md);
  }
}
</style>
