import Keycloak, {
  KeycloakConfig,
  KeycloakTokenParsed,
  KeycloakError,
  KeycloakInitOptions,
} from 'keycloak-js';
import { ref, Ref, isRef } from 'vue';

export type Config = KeycloakConfig & Pick<KeycloakInitOptions, 'checkLoginIframe' | 'onLoad'> & {
  accessToken?: string | Ref<string | null | undefined>
  refreshToken?: string | Ref<string | null | undefined>
};

export const useKeycloak = (config: Config) => {
  const { checkLoginIframe, onLoad, accessToken: initialAccessToken, refreshToken: initialRefreshToken, ...constructorSettings } = config;

  const keycloak = Keycloak(constructorSettings);
  const rawAccessToken = isRef(initialAccessToken) ? initialAccessToken : ref(
    initialAccessToken
  );
  const rawRefreshToken = isRef(initialRefreshToken) ? initialRefreshToken : ref(
    initialAccessToken
  );
  const accessToken = ref<KeycloakTokenParsed | undefined>();
  const refreshToken = ref<KeycloakTokenParsed | undefined>();
  const error = ref<KeycloakError | undefined>();

  const updateRefs = () => {
    rawAccessToken.value = keycloak.token;
    rawRefreshToken.value = keycloak.refreshToken;
    accessToken.value = keycloak.tokenParsed;
    refreshToken.value = keycloak.refreshTokenParsed;
  };

  keycloak.onAuthLogout = updateRefs;
  keycloak.onAuthRefreshError = updateRefs;
  keycloak.onAuthRefreshSuccess = updateRefs;
  keycloak.onAuthSuccess = updateRefs;
  keycloak.onReady = updateRefs;
  
  keycloak.onTokenExpired = async () => {
    await keycloak.updateToken(10);
    updateRefs();
  };
  
  keycloak.onAuthError = (detectedError) => {
    error.value = detectedError;
  };

  const ready = keycloak.init({
    onLoad,
    checkLoginIframe: checkLoginIframe ?? false,
    token: rawAccessToken.value ?? undefined,
    refreshToken: rawRefreshToken.value ?? undefined,
  });

  const login = async (options?: Keycloak.KeycloakLoginOptions) => {
    await keycloak.login(options);
    updateRefs();
  };

  const logout = async () => {
    await keycloak.logout();
    updateRefs();
  };

  const hasRole = (role: string) => {
    return keycloak.hasRealmRole(role)
  }

  const hasResourceRole = (role: string, resource?: string) => {
    return keycloak.hasResourceRole(role, resource)
  }

  return {
    accessToken,
    error,
    login,
    logout,
    hasRole,
    hasResourceRole,
    rawAccessToken,
    rawRefreshToken,
    refreshToken,
    ready
  };
};

export default useKeycloak