<route lang="yaml">
name: dashboard
meta:
  skipFromFavorites: true
</route>

<template>
  <menu-page v-if="!isSignedIn" title="Einloggen">
    <p>Willkommen zurück in der App. Logge Dich bitte ein.</p>
    <div class="login-buttons">
      <button class="btn bg-cyan" @click="login()">Einloggen</button>
      <!--
      <button class="btn bg-cyan" @click="register()">
        Erstmalig anmelden
      </button>
      -->
    </div>
  </menu-page>

  <menu-page v-else title="Dashboard">
    <!-- show current EMA exercises as overlay -->
    <user-tasks
      v-if="isSignedIn"
      with-key="fill-in-ema"
      include-variables
      :assigned-to="true"
      :poll="7500"
    >
      <template #default="{ task }">
        <teleport to="#modals">
          <router-link
            class="modal bg-cyan"
            style="color: white"
            :to="{
              name: 'ema-id',
              params: { id: task.variables?.emaId?.value },
            }"
            >EMA-Fragen beantworten
          </router-link>
        </teleport>
      </template>
      <template #empty>
        <p v-if="trial && !isInTreatment">
          Zurzeit steht keine Kurzbefragung an. Die Befragung ist jeweils nur
          für 30 Minuten verfügbar. Sie erhalten eine SMS, sobald die nächste
          Befragung ansteht.
        </p>
      </template>
    </user-tasks>

    <netflix-menu v-if="isInTreatment || enableAll" title="Weitermachen bei">
      <router-link
        v-if="lastResumableRoute?.name"
        class="netflix-menu__item btn btn_dark bg-cyan"
        :to="{ name: lastResumableRoute.name }"
      >
        {{ getTitleForRoute(lastResumableRoute.name) }}
      </router-link>
      <router-link
        class="netflix-menu__item btn btn_dark bg-cyan"
        :to="{
          name: 'favorites',
        }"
      >
        Lese&shy;zeichen
      </router-link>
    </netflix-menu>

    <netflix-menu
      v-if="isInTreatment || enableAll"
      title="Themen"
      list-tag="div"
    >
      <router-link
        class="netflix-menu__item btn btn_dark bg-cyan"
        to="/themen/einfuehrung-modul-0"
      >
        Einführungs&shy;kapitel
      </router-link>
      <router-link
        v-if="trial?.canAccessErSince || enableAll"
        class="netflix-menu__item btn btn_dark bg-cyan"
        :to="{
          name: 'themen.emotionsregulation',
        }"
      >
        Umgang mit Gefühlen
      </router-link>
      <div
        v-else
        class="netflix-menu__item btn btn_dark bg-cyan"
        aria-disabled="true"
      >
        Umgang mit Gefühlen
      </div>
      <router-link
        v-if="trial?.canAccessSipSince || enableAll"
        class="netflix-menu__item btn btn_dark bg-cyan"
        :to="{
          name: 'themen.soziale-informationsverarbeitung',
        }"
      >
        Beziehungen und soziale Fertigkeiten
      </router-link>
      <div
        v-else
        class="netflix-menu__item btn btn_dark bg-cyan"
        aria-disabled="true"
      >
        Beziehungen und soziale Fertigkeiten
      </div>

      <router-link
        v-if="
          (trial?.canAccessSipSince && trial?.canAccessErSince) || enableAll
        "
        class="netflix-menu__item btn btn_dark bg-cyan"
        :to="{
          name: 'themen.abschlusskapitel',
        }"
      >
        Abschluss&shy;kapitel
      </router-link>
      <div
        v-else
        class="netflix-menu__item btn btn_dark bg-cyan"
        aria-disabled="true"
      >
        Abschluss&shy;kapitel
      </div>
    </netflix-menu>

    <netflix-menu
      v-if="isInTreatment || enableAll"
      title="Tools"
      list-tag="div"
    >
      <router-link
        v-if="trial?.canAccessErSince || enableAll"
        class="netflix-menu__item btn btn_dark bg-purple"
        :to="{ name: 'tools.er' }"
        >Tools Gefühle</router-link
      >
      <div
        v-else
        class="netflix-menu__item btn btn_dark bg-purple"
        aria-disabled="true"
      >
        Tools Gefühle
      </div>
      <router-link
        v-if="trial?.canAccessSipSince || enableAll"
        class="netflix-menu__item btn btn_dark bg-purple"
        :to="{ name: 'tools.sip' }"
        >Tools Beziehungen</router-link
      >
      <div
        v-else
        class="netflix-menu__item btn btn_dark bg-purple"
        aria-disabled="true"
      >
        Tools Beziehungen
      </div>
      <router-link
        v-if="trial?.canAccessSipSince || trial?.canAccessErSince || enableAll"
        class="netflix-menu__item btn btn_dark bg-purple"
        :to="{ name: 'favoriteTools' }"
        >Meine Lieblings-Tools</router-link
      >
      <div
        v-else
        class="netflix-menu__item btn btn_dark bg-purple"
        aria-disabled="true"
      >
        Meine Lieblings-Tools
      </div>
    </netflix-menu>

    <netflix-menu
      v-if="isInTreatment || enableAll"
      title="Meine Unterstützung"
      list-tag="div"
    >
      <!-- TODO @Timo: Hier Favoriten von Tools verlinken -->
      <router-link
        v-if="trial?.canAccessErSince || enableAll"
        class="netflix-menu__item btn btn_dark bg-yellow"
        :to="{ name: 'tools.er' }"
        >Meine Tools</router-link
      >
      <router-link
        v-else-if="trial?.canAccessSipSince || enableAll"
        class="netflix-menu__item btn btn_dark bg-yellow"
        :to="{ name: 'tools.sip' }"
        >Meine Tools</router-link
      >
      <div
        v-else
        class="netflix-menu__item btn btn_dark bg-yellow"
        aria-disabled="true"
      >
        Meine Tools
      </div>
      <router-link
        class="netflix-menu__item btn btn_dark bg-yellow"
        :to="{ name: 'profil/notfallkontakte' }"
        >Meine Kontakte</router-link
      >
      <router-link
        class="netflix-menu__item btn btn_dark bg-yellow"
        :to="{ name: 'emi.helpful-thoughts' }"
        >Meine hilfreichen Gedanken</router-link
      >
    </netflix-menu>

    <netflix-menu title="Ich" list-tag="div">
      <router-link
        v-if="isInTreatment || enableAll"
        class="netflix-menu__item btn btn_dark bg-green"
        :to="{ name: 'themen.einführung.persönliche-ziele-festlegen' }"
        >Meine Ziele</router-link
      >
      <router-link
        v-if="isInTreatment || enableAll"
        class="netflix-menu__item btn btn_dark bg-green"
        :to="{ name: 'chat' }"
        >Meine Nachrichten</router-link
      >
      <router-link
        v-if="isInTreatment || enableAll"
        class="netflix-menu__item btn btn_dark bg-green"
        :to="{ name: 'profil/avatar' }"
        >Mein Avatar</router-link
      >
      <router-link
        v-if="isInTreatment || enableAll"
        class="netflix-menu__item btn btn_dark bg-green"
        :to="{ name: 'profil/notfallkontakte' }"
        >Meine Kontakte</router-link
      >
      <router-link
        v-if="isInTreatment || enableAll"
        class="netflix-menu__item btn btn_dark bg-green"
        :to="{ name: 'profil/nickname' }"
        >Mein App-Name</router-link
      >
      <router-link
        class="netflix-menu__item btn btn_dark bg-green"
        :to="{ name: 'ema.results' }"
        >Meine Befragungen</router-link
      >
    </netflix-menu>
  </menu-page>
</template>

<script lang="ts" setup>
import { useHead } from '@vueuse/head';
import { useMyCurrentTrial } from '~/domain/use-my-current-trial';
import { computed, unref } from 'vue';
import { isSignedIn, login, register } from '~/auth';
import { useRouter } from 'vue-router';
import { whenever } from '@vueuse/shared';
import { getTitleForRoute } from '../utils/get-title-for-route';
import { useRouteQuery } from '@vueuse/router';
import { logicAnd, logicNot } from '@vueuse/math';

useHead({ title: 'Dashboard | FACE App' });

const router = useRouter();
const enableAll = useRouteQuery('enable-all');
const {
  trial,
  isOnboardingComplete,
  lastResumableRoute,
  isInTreatment,
  currentIntervention,
} = useMyCurrentTrial();

whenever(
  logicAnd(trial, isInTreatment, logicNot(isOnboardingComplete)),
  () => {
    router.replace({ name: 'onboarding' });
  },
  {
    immediate: true,
  }
);
</script>

<style scoped>
.btn.btn_dark[aria-disabled='true'] {
  opacity: 0.5;
}

.login-buttons {
  display: grid;
  gap: var(--space-md);
}
</style>
