import { createApp } from 'vue';
import urql, { createClient } from '@urql/vue';
import { createHead } from '@vueuse/head';
import { createRouter, createWebHistory } from 'vue-router';
import { setupLayouts } from 'virtual:generated-layouts';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { DateTime, Duration, Settings } from 'luxon';

import '~/assets/style.css';
import '~/assets/styles/index.css';
import '~/fontawesome';

import 'swiper/css';
import 'swiper/css/hash-navigation';
import 'swiper/css/pagination';

import './polyfills';
import App from './App.vue';
import { trackError } from './utils/error-tracker';
import generatedRoutes from '~pages';
import { currentUser, isSignedIn, rawAccessToken, register } from './auth';
import { CreateEventDocument } from '~/graphql/operations';
import StackTrace from 'stacktrace-js';

const client = createClient({
  url: '/graphql',
  fetchOptions: () => {
    const authToken = rawAccessToken.value;
    return authToken
      ? {
          headers: { authorization: `Bearer ${authToken}` },
        }
      : {};
  },
});

// remove service workers that were installed with VitePWA
// https://stackoverflow.com/questions/33704791/how-do-i-uninstall-a-service-worker
if ('serviceWorker' in navigator) {
  navigator.serviceWorker?.getRegistrations().then(function (registrations) {
    if (registrations.length === 0)
      console.debug('so service workers registered');
    for (const registration of registrations) {
      registration
        .unregister()
        .then(() =>
          console.log(`removed registration of service worker ${registration}`)
        )
        .catch(() =>
          console.error(`failed to remove service worker ${registration}`)
        );
    }
  });
}

Settings.defaultLocale = 'de';

const head = createHead({
  title: 'FACE App',
});

const routes = setupLayouts([...generatedRoutes]);

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior: (to, _from, savedPosition) => {
    if (to.hash) {
      return { el: to.hash, top: 20 };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to) => {
  if (
    to.matched.some((route) => route.meta.requiresLogin) &&
    !isSignedIn.value
  ) {
    return {
      name: 'login',
      replace: true,
      query: { afterLogin: to.fullPath },
    };
  }
});

router.afterEach(async (to) => {
  await client
    .mutation(CreateEventDocument, {
      type: 'route',
      payload: {
        fullPath: to.fullPath,
        hash: to.hash,
        name: to.name,
        params: to.params,
        path: to.path,
        query: to.query,
        resumable: to.meta?.resumable,
      },
    })
    .toPromise();
});

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

const app = createApp(App)
  .component('font-awesome-icon', FontAwesomeIcon)
  .use(head)
  .use(router)
  .use(urql, client)
  .provide('currentUser', currentUser);

app.config.globalProperties.$formatDate = (date: string, format?: string) => {
  return DateTime.fromISO(date).toFormat(format ?? 'LL');
};

app.config.globalProperties.$formatDuration = (seconds: number) => {
  if (isNaN(seconds)) return '—';
  if (seconds > 3600)
    return Duration.fromMillis(seconds * 1000).toFormat('hh:mm:ss');
  else return Duration.fromMillis(seconds * 1000).toFormat('mm:ss');
};

app.config.globalProperties.$currentUser = currentUser;

app.mount('#app');

app.config.errorHandler = async (error: any, instance, info) => {
  // get stack if available
  const stack =
    error instanceof Error ? await StackTrace.fromError(error) : null;

  console.error('Vue caught an error: ', error);
  console.debug('stack of last error: ', stack);
  console.debug('component instance of last error: ', instance);
  console.debug('info of last error: ', info);

  await client
    .mutation(CreateEventDocument, {
      type: 'app.config.errorHandler',
      payload: {
        error: {
          message: error?.message,
          name: error?.name,
        },
        info,
        instance: {
          name: instance?.$options.name,
        },
        route: {
          name: instance?.$route?.name,
          fullPath: instance?.$route?.fullPath,
        },
        stack,
      },
    })
    .toPromise();
};

app.config.warnHandler = async (message, instance, trace) => {
  console.warn('Vue caught a warning: ', message);
  console.debug('trace of last warning: ', trace);
  console.debug('component instance of last warning: ', instance);

  await client.mutation(CreateEventDocument, {
    type: 'app.config.warnHandler',
    payload: {
      message,
      trace,
      instance: {
        name: instance?.$options.name,
      },
      route: {
        name: instance?.$route?.name,
        fullPath: instance?.$route?.fullPath,
      },
    },
  });
};

window.onerror = async (event, source, lineno, colno, error) => {
  const stack = error ? await StackTrace.fromError(error) : null;
  client.mutation(CreateEventDocument, {
    type: 'window.onerror',
    payload: {
      colno,
      error: {
        message: error?.message,
        name: error?.name,
      },
      event: event.toString(),
      lineno,
      source,
      stack,
    },
  });
};
