export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  /** An IPv4 or IPv6 host address, and optionally its subnet. */
  InternetAddress: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  MimeType: any;
  /** A builtin object identifier type for a relation name */
  RegClass: any;
  /** The exact time of day, does not include the date. May or may not have a timezone offset. */
  Time: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
};

/** All input for the `acceptMembershipInvitation` mutation. */
export type AcceptMembershipInvitationInput = {
  byId?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `acceptMembershipInvitation` mutation. */
export type AcceptMembershipInvitationPayload = {
  __typename?: 'AcceptMembershipInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  result?: Maybe<AcceptMembershipInvitationRecord>;
};

/** The return type of our `acceptMembershipInvitation` mutation. */
export type AcceptMembershipInvitationRecord = {
  __typename?: 'AcceptMembershipInvitationRecord';
  invitation?: Maybe<MembershipInvitation>;
  membership?: Maybe<Membership>;
};

export type AuthToken = {
  __typename?: 'AuthToken';
  azp?: Maybe<Scalars['String']>;
  exp?: Maybe<Scalars['BigInt']>;
  iat?: Maybe<Scalars['BigInt']>;
  iss?: Maybe<Scalars['String']>;
  sub?: Maybe<Scalars['String']>;
};

export enum AvatarIcon {
  Bird = 'BIRD',
  Blue = 'BLUE',
  Dog = 'DOG',
  Elefant = 'ELEFANT',
  Purple = 'PURPLE',
  Space = 'SPACE',
  Tree = 'TREE',
  Waves = 'WAVES',
  Yellow = 'YELLOW'
}

/** A filter to be used against AvatarIcon fields. All fields are combined with a logical ‘and.’ */
export type AvatarIconFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AvatarIcon>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AvatarIcon>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AvatarIcon>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AvatarIcon>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AvatarIcon>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AvatarIcon>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AvatarIcon>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AvatarIcon>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AvatarIcon>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AvatarIcon>>;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigFloat']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigFloat']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigFloat']>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type Commentable = Node & {
  __typename?: 'Commentable';
  /** Reads and enables pagination through a set of `Conversation`. */
  conversationsByAboutCommentableId: ConversationsConnection;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  numberOfPostings?: Maybe<Scalars['BigInt']>;
  numberOfUnreadPostings?: Maybe<Scalars['BigInt']>;
  origin: Scalars['RegClass'];
  /** Reads and enables pagination through a set of `Posting`. */
  postingsByAboutCommentableId: PostingsConnection;
  registeredAt: Scalars['Datetime'];
  /** Reads a single `Trial` that is related to this `Commentable`. */
  trialById?: Maybe<Trial>;
  /**
   * Reads and enables pagination through a set of `Trial`.
   * @deprecated Please use trialById instead
   */
  trialsById: TrialsConnection;
};


export type CommentableConversationsByAboutCommentableIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConversationCondition>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};


export type CommentablePostingsByAboutCommentableIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingCondition>;
  filter?: InputMaybe<PostingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};


export type CommentableTrialsByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

export type CommentableAggregates = {
  __typename?: 'CommentableAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<CommentableAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<CommentableDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<CommentableMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<CommentableMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<CommentableStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<CommentableStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<CommentableSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<CommentableVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<CommentableVarianceSampleAggregates>;
};

export type CommentableAverageAggregates = {
  __typename?: 'CommentableAverageAggregates';
  /** Mean average of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Commentable` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CommentableCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `numberOfPostings` field. */
  numberOfPostings?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `numberOfUnreadPostings` field. */
  numberOfUnreadPostings?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `origin` field. */
  origin?: InputMaybe<Scalars['RegClass']>;
  /** Checks for equality with the object’s `registeredAt` field. */
  registeredAt?: InputMaybe<Scalars['Datetime']>;
};

export type CommentableDistinctCountAggregates = {
  __typename?: 'CommentableDistinctCountAggregates';
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of origin across the matching connection */
  origin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of registeredAt across the matching connection */
  registeredAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Commentable` object types. All fields are combined with a logical ‘and.’ */
export type CommentableFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CommentableFilter>>;
  /** Filter by the object’s `conversationsByAboutCommentableId` relation. */
  conversationsByAboutCommentableId?: InputMaybe<CommentableToManyConversationFilter>;
  /** Some related `conversationsByAboutCommentableId` exist. */
  conversationsByAboutCommentableIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CommentableFilter>;
  /** Filter by the object’s `numberOfPostings` field. */
  numberOfPostings?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `numberOfUnreadPostings` field. */
  numberOfUnreadPostings?: InputMaybe<BigIntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CommentableFilter>>;
  /** Filter by the object’s `postingsByAboutCommentableId` relation. */
  postingsByAboutCommentableId?: InputMaybe<CommentableToManyPostingFilter>;
  /** Some related `postingsByAboutCommentableId` exist. */
  postingsByAboutCommentableIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `registeredAt` field. */
  registeredAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `trialById` relation. */
  trialById?: InputMaybe<TrialFilter>;
  /** A related `trialById` exists. */
  trialByIdExists?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `Commentable` */
export type CommentableInput = {
  id: Scalars['UUID'];
  origin: Scalars['RegClass'];
  registeredAt?: InputMaybe<Scalars['Datetime']>;
};

export type CommentableMaxAggregates = {
  __typename?: 'CommentableMaxAggregates';
  /** Maximum of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigInt']>;
  /** Maximum of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigInt']>;
  /** Maximum of origin across the matching connection */
  origin?: Maybe<Scalars['RegClass']>;
};

export type CommentableMinAggregates = {
  __typename?: 'CommentableMinAggregates';
  /** Minimum of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigInt']>;
  /** Minimum of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigInt']>;
  /** Minimum of origin across the matching connection */
  origin?: Maybe<Scalars['RegClass']>;
};

/** Represents an update to a `Commentable`. Fields that are set will be updated. */
export type CommentablePatch = {
  id?: InputMaybe<Scalars['UUID']>;
  origin?: InputMaybe<Scalars['RegClass']>;
  registeredAt?: InputMaybe<Scalars['Datetime']>;
};

export type CommentableStddevPopulationAggregates = {
  __typename?: 'CommentableStddevPopulationAggregates';
  /** Population standard deviation of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

export type CommentableStddevSampleAggregates = {
  __typename?: 'CommentableStddevSampleAggregates';
  /** Sample standard deviation of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

export type CommentableSumAggregates = {
  __typename?: 'CommentableSumAggregates';
  /** Sum of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigFloat']>;
  /** Sum of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
  /** Sum of origin across the matching connection */
  origin: Scalars['BigFloat'];
};

/** A filter to be used against many `Conversation` object types. All fields are combined with a logical ‘and.’ */
export type CommentableToManyConversationFilter = {
  /** Aggregates across related `Conversation` match the filter criteria. */
  aggregates?: InputMaybe<ConversationAggregatesFilter>;
  /** Every related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConversationFilter>;
  /** No related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConversationFilter>;
  /** Some related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConversationFilter>;
};

/** A filter to be used against many `Posting` object types. All fields are combined with a logical ‘and.’ */
export type CommentableToManyPostingFilter = {
  /** Aggregates across related `Posting` match the filter criteria. */
  aggregates?: InputMaybe<PostingAggregatesFilter>;
  /** Every related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostingFilter>;
  /** No related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostingFilter>;
  /** Some related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostingFilter>;
};

export type CommentableVariancePopulationAggregates = {
  __typename?: 'CommentableVariancePopulationAggregates';
  /** Population variance of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

export type CommentableVarianceSampleAggregates = {
  __typename?: 'CommentableVarianceSampleAggregates';
  /** Sample variance of this field across the matching connection. */
  numberOfPostings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of this field across the matching connection. */
  numberOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Commentable` values. */
export type CommentablesConnection = {
  __typename?: 'CommentablesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<CommentableAggregates>;
  /** A list of edges which contains the `Commentable` and cursor to aid in pagination. */
  edges: Array<CommentablesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<CommentableAggregates>>;
  /** A list of `Commentable` objects. */
  nodes: Array<Commentable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Commentable` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Commentable` values. */
export type CommentablesConnectionGroupedAggregatesArgs = {
  groupBy: Array<CommentablesGroupBy>;
  having?: InputMaybe<CommentablesHavingInput>;
};

/** A `Commentable` edge in the connection. */
export type CommentablesEdge = {
  __typename?: 'CommentablesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Commentable` at the end of the edge. */
  node: Commentable;
};

/** Grouping methods for `Commentable` for usage during aggregation. */
export enum CommentablesGroupBy {
  Origin = 'ORIGIN',
  RegisteredAt = 'REGISTERED_AT',
  RegisteredAtTruncatedToDay = 'REGISTERED_AT_TRUNCATED_TO_DAY',
  RegisteredAtTruncatedToHour = 'REGISTERED_AT_TRUNCATED_TO_HOUR'
}

export type CommentablesHavingAverageCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingAverageCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingAverageInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingAverageCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingAverageCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CommentablesHavingDistinctCountCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingDistinctCountCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingDistinctCountInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingDistinctCountCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingDistinctCountCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Commentable` aggregates. */
export type CommentablesHavingInput = {
  AND?: InputMaybe<Array<CommentablesHavingInput>>;
  OR?: InputMaybe<Array<CommentablesHavingInput>>;
  average?: InputMaybe<CommentablesHavingAverageInput>;
  distinctCount?: InputMaybe<CommentablesHavingDistinctCountInput>;
  max?: InputMaybe<CommentablesHavingMaxInput>;
  min?: InputMaybe<CommentablesHavingMinInput>;
  stddevPopulation?: InputMaybe<CommentablesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<CommentablesHavingStddevSampleInput>;
  sum?: InputMaybe<CommentablesHavingSumInput>;
  variancePopulation?: InputMaybe<CommentablesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<CommentablesHavingVarianceSampleInput>;
};

export type CommentablesHavingMaxCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingMaxCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingMaxInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingMaxCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingMaxCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CommentablesHavingMinCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingMinCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingMinInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingMinCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingMinCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CommentablesHavingStddevPopulationCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingStddevPopulationCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingStddevPopulationInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingStddevPopulationCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingStddevPopulationCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CommentablesHavingStddevSampleCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingStddevSampleCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingStddevSampleInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingStddevSampleCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingStddevSampleCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CommentablesHavingSumCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingSumCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingSumInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingSumCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingSumCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CommentablesHavingVariancePopulationCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingVariancePopulationCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingVariancePopulationInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingVariancePopulationCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingVariancePopulationCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type CommentablesHavingVarianceSampleCommentablesNumberOfPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingVarianceSampleCommentablesNumberOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type CommentablesHavingVarianceSampleInput = {
  numberOfPostings?: InputMaybe<CommentablesHavingVarianceSampleCommentablesNumberOfPostingsInput>;
  numberOfUnreadPostings?: InputMaybe<CommentablesHavingVarianceSampleCommentablesNumberOfUnreadPostingsInput>;
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Commentable`. */
export enum CommentablesOrderBy {
  ConversationsByAboutCommentableIdAverageAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdAverageAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdAverageAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdAverageAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdAverageCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdAverageCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdAverageCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdAverageCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdAverageIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ID_ASC',
  ConversationsByAboutCommentableIdAverageIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ID_DESC',
  ConversationsByAboutCommentableIdAverageIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdAverageIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdAverageLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdAverageLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdAveragePrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdAveragePrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdAverageTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdAverageTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdAverageTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_TITLE_ASC',
  ConversationsByAboutCommentableIdAverageTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_TITLE_DESC',
  ConversationsByAboutCommentableIdAverageUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdAverageUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_UPDATED_AT_DESC',
  ConversationsByAboutCommentableIdCountAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_COUNT_ASC',
  ConversationsByAboutCommentableIdCountDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_COUNT_DESC',
  ConversationsByAboutCommentableIdDistinctCountAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdDistinctCountAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdDistinctCountAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdDistinctCountAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdDistinctCountCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdDistinctCountCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdDistinctCountCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdDistinctCountCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdDistinctCountIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ID_ASC',
  ConversationsByAboutCommentableIdDistinctCountIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ID_DESC',
  ConversationsByAboutCommentableIdDistinctCountIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdDistinctCountIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdDistinctCountLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdDistinctCountLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdDistinctCountPrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdDistinctCountPrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdDistinctCountTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdDistinctCountTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdDistinctCountTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_TITLE_ASC',
  ConversationsByAboutCommentableIdDistinctCountTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_TITLE_DESC',
  ConversationsByAboutCommentableIdDistinctCountUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdDistinctCountUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  ConversationsByAboutCommentableIdMaxAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdMaxAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdMaxAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdMaxAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdMaxCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdMaxCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdMaxCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdMaxCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdMaxIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_ID_ASC',
  ConversationsByAboutCommentableIdMaxIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_ID_DESC',
  ConversationsByAboutCommentableIdMaxIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdMaxIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdMaxLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdMaxLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdMaxPrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdMaxPrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdMaxTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdMaxTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdMaxTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_TITLE_ASC',
  ConversationsByAboutCommentableIdMaxTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_TITLE_DESC',
  ConversationsByAboutCommentableIdMaxUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdMaxUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MAX_UPDATED_AT_DESC',
  ConversationsByAboutCommentableIdMinAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdMinAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdMinAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdMinAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdMinCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdMinCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdMinCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdMinCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdMinIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_ID_ASC',
  ConversationsByAboutCommentableIdMinIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_ID_DESC',
  ConversationsByAboutCommentableIdMinIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdMinIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdMinLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdMinLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdMinPrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdMinPrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdMinTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdMinTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdMinTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_TITLE_ASC',
  ConversationsByAboutCommentableIdMinTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_TITLE_DESC',
  ConversationsByAboutCommentableIdMinUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdMinUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_MIN_UPDATED_AT_DESC',
  ConversationsByAboutCommentableIdStddevPopulationAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdStddevPopulationAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdStddevPopulationAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdStddevPopulationAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdStddevPopulationCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdStddevPopulationCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdStddevPopulationCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdStddevPopulationCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdStddevPopulationIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ID_ASC',
  ConversationsByAboutCommentableIdStddevPopulationIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ID_DESC',
  ConversationsByAboutCommentableIdStddevPopulationIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdStddevPopulationIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdStddevPopulationLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdStddevPopulationLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdStddevPopulationPrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdStddevPopulationPrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdStddevPopulationTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdStddevPopulationTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdStddevPopulationTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_TITLE_ASC',
  ConversationsByAboutCommentableIdStddevPopulationTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_TITLE_DESC',
  ConversationsByAboutCommentableIdStddevPopulationUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdStddevPopulationUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  ConversationsByAboutCommentableIdStddevSampleAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdStddevSampleAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdStddevSampleAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdStddevSampleAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdStddevSampleCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdStddevSampleCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdStddevSampleCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdStddevSampleCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdStddevSampleIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ID_ASC',
  ConversationsByAboutCommentableIdStddevSampleIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ID_DESC',
  ConversationsByAboutCommentableIdStddevSampleIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdStddevSampleIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdStddevSampleLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdStddevSampleLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdStddevSamplePrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdStddevSamplePrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdStddevSampleTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdStddevSampleTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdStddevSampleTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_TITLE_ASC',
  ConversationsByAboutCommentableIdStddevSampleTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_TITLE_DESC',
  ConversationsByAboutCommentableIdStddevSampleUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdStddevSampleUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ConversationsByAboutCommentableIdSumAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdSumAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdSumAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdSumAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdSumCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdSumCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdSumCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdSumCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdSumIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_ID_ASC',
  ConversationsByAboutCommentableIdSumIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_ID_DESC',
  ConversationsByAboutCommentableIdSumIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdSumIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdSumLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdSumLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdSumPrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdSumPrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdSumTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdSumTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdSumTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_TITLE_ASC',
  ConversationsByAboutCommentableIdSumTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_TITLE_DESC',
  ConversationsByAboutCommentableIdSumUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdSumUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_SUM_UPDATED_AT_DESC',
  ConversationsByAboutCommentableIdVariancePopulationAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdVariancePopulationAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdVariancePopulationAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdVariancePopulationAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdVariancePopulationCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdVariancePopulationCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdVariancePopulationCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdVariancePopulationCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdVariancePopulationIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ID_ASC',
  ConversationsByAboutCommentableIdVariancePopulationIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ID_DESC',
  ConversationsByAboutCommentableIdVariancePopulationIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdVariancePopulationIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdVariancePopulationLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdVariancePopulationLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdVariancePopulationPrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdVariancePopulationPrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdVariancePopulationTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdVariancePopulationTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdVariancePopulationTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_TITLE_ASC',
  ConversationsByAboutCommentableIdVariancePopulationTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_TITLE_DESC',
  ConversationsByAboutCommentableIdVariancePopulationUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdVariancePopulationUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ConversationsByAboutCommentableIdVarianceSampleAboutCommentableIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByAboutCommentableIdVarianceSampleAboutCommentableIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByAboutCommentableIdVarianceSampleAbstractAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ABSTRACT_ASC',
  ConversationsByAboutCommentableIdVarianceSampleAbstractDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ABSTRACT_DESC',
  ConversationsByAboutCommentableIdVarianceSampleCreatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ConversationsByAboutCommentableIdVarianceSampleCreatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ConversationsByAboutCommentableIdVarianceSampleCreatorIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_CREATOR_ID_ASC',
  ConversationsByAboutCommentableIdVarianceSampleCreatorIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_CREATOR_ID_DESC',
  ConversationsByAboutCommentableIdVarianceSampleIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ID_ASC',
  ConversationsByAboutCommentableIdVarianceSampleIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ID_DESC',
  ConversationsByAboutCommentableIdVarianceSampleIsPublicAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_IS_PUBLIC_ASC',
  ConversationsByAboutCommentableIdVarianceSampleIsPublicDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_IS_PUBLIC_DESC',
  ConversationsByAboutCommentableIdVarianceSampleLanguageAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_LANGUAGE_ASC',
  ConversationsByAboutCommentableIdVarianceSampleLanguageDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_LANGUAGE_DESC',
  ConversationsByAboutCommentableIdVarianceSamplePrivateToGroupIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByAboutCommentableIdVarianceSamplePrivateToGroupIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByAboutCommentableIdVarianceSampleTenantIdAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  ConversationsByAboutCommentableIdVarianceSampleTenantIdDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  ConversationsByAboutCommentableIdVarianceSampleTitleAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_TITLE_ASC',
  ConversationsByAboutCommentableIdVarianceSampleTitleDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_TITLE_DESC',
  ConversationsByAboutCommentableIdVarianceSampleUpdatedAtAsc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ConversationsByAboutCommentableIdVarianceSampleUpdatedAtDesc = 'CONVERSATIONS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  NumberOfPostingsAsc = 'NUMBER_OF_POSTINGS_ASC',
  NumberOfPostingsDesc = 'NUMBER_OF_POSTINGS_DESC',
  NumberOfUnreadPostingsAsc = 'NUMBER_OF_UNREAD_POSTINGS_ASC',
  NumberOfUnreadPostingsDesc = 'NUMBER_OF_UNREAD_POSTINGS_DESC',
  OriginAsc = 'ORIGIN_ASC',
  OriginDesc = 'ORIGIN_DESC',
  PostingsByAboutCommentableIdAverageAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdAverageAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdAverageAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdAverageAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdAverageAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdAverageAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdAverageBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_BODY_ASC',
  PostingsByAboutCommentableIdAverageBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_BODY_DESC',
  PostingsByAboutCommentableIdAverageConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdAverageConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdAverageCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_CREATED_AT_ASC',
  PostingsByAboutCommentableIdAverageCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_CREATED_AT_DESC',
  PostingsByAboutCommentableIdAverageHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_HASHTAGS_ASC',
  PostingsByAboutCommentableIdAverageHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_HASHTAGS_DESC',
  PostingsByAboutCommentableIdAverageIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ID_ASC',
  PostingsByAboutCommentableIdAverageIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_ID_DESC',
  PostingsByAboutCommentableIdAverageLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_LANGUAGE_ASC',
  PostingsByAboutCommentableIdAverageLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_LANGUAGE_DESC',
  PostingsByAboutCommentableIdAverageMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdAverageMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdAverageSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_SENDER_ID_ASC',
  PostingsByAboutCommentableIdAverageSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_SENDER_ID_DESC',
  PostingsByAboutCommentableIdAverageSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_SUBJECT_ASC',
  PostingsByAboutCommentableIdAverageSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_SUBJECT_DESC',
  PostingsByAboutCommentableIdAverageSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdAverageSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdAverageUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdAverageUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_AVERAGE_UPDATED_AT_DESC',
  PostingsByAboutCommentableIdCountAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_COUNT_ASC',
  PostingsByAboutCommentableIdCountDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_COUNT_DESC',
  PostingsByAboutCommentableIdDistinctCountAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdDistinctCountAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdDistinctCountAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdDistinctCountAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdDistinctCountAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdDistinctCountAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdDistinctCountBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_BODY_ASC',
  PostingsByAboutCommentableIdDistinctCountBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_BODY_DESC',
  PostingsByAboutCommentableIdDistinctCountConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdDistinctCountConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdDistinctCountCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  PostingsByAboutCommentableIdDistinctCountCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  PostingsByAboutCommentableIdDistinctCountHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_HASHTAGS_ASC',
  PostingsByAboutCommentableIdDistinctCountHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_HASHTAGS_DESC',
  PostingsByAboutCommentableIdDistinctCountIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ID_ASC',
  PostingsByAboutCommentableIdDistinctCountIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_ID_DESC',
  PostingsByAboutCommentableIdDistinctCountLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_LANGUAGE_ASC',
  PostingsByAboutCommentableIdDistinctCountLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_LANGUAGE_DESC',
  PostingsByAboutCommentableIdDistinctCountMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdDistinctCountMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdDistinctCountSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_SENDER_ID_ASC',
  PostingsByAboutCommentableIdDistinctCountSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_SENDER_ID_DESC',
  PostingsByAboutCommentableIdDistinctCountSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_SUBJECT_ASC',
  PostingsByAboutCommentableIdDistinctCountSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_SUBJECT_DESC',
  PostingsByAboutCommentableIdDistinctCountSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdDistinctCountSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdDistinctCountUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdDistinctCountUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  PostingsByAboutCommentableIdMaxAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdMaxAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdMaxAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdMaxAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdMaxAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdMaxAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdMaxBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_BODY_ASC',
  PostingsByAboutCommentableIdMaxBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_BODY_DESC',
  PostingsByAboutCommentableIdMaxConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdMaxConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdMaxCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_CREATED_AT_ASC',
  PostingsByAboutCommentableIdMaxCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_CREATED_AT_DESC',
  PostingsByAboutCommentableIdMaxHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_HASHTAGS_ASC',
  PostingsByAboutCommentableIdMaxHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_HASHTAGS_DESC',
  PostingsByAboutCommentableIdMaxIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_ID_ASC',
  PostingsByAboutCommentableIdMaxIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_ID_DESC',
  PostingsByAboutCommentableIdMaxLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_LANGUAGE_ASC',
  PostingsByAboutCommentableIdMaxLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_LANGUAGE_DESC',
  PostingsByAboutCommentableIdMaxMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdMaxMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdMaxSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_SENDER_ID_ASC',
  PostingsByAboutCommentableIdMaxSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_SENDER_ID_DESC',
  PostingsByAboutCommentableIdMaxSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_SUBJECT_ASC',
  PostingsByAboutCommentableIdMaxSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_SUBJECT_DESC',
  PostingsByAboutCommentableIdMaxSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdMaxSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdMaxUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdMaxUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MAX_UPDATED_AT_DESC',
  PostingsByAboutCommentableIdMinAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdMinAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdMinAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdMinAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdMinAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdMinAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdMinBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_BODY_ASC',
  PostingsByAboutCommentableIdMinBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_BODY_DESC',
  PostingsByAboutCommentableIdMinConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdMinConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdMinCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_CREATED_AT_ASC',
  PostingsByAboutCommentableIdMinCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_CREATED_AT_DESC',
  PostingsByAboutCommentableIdMinHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_HASHTAGS_ASC',
  PostingsByAboutCommentableIdMinHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_HASHTAGS_DESC',
  PostingsByAboutCommentableIdMinIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_ID_ASC',
  PostingsByAboutCommentableIdMinIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_ID_DESC',
  PostingsByAboutCommentableIdMinLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_LANGUAGE_ASC',
  PostingsByAboutCommentableIdMinLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_LANGUAGE_DESC',
  PostingsByAboutCommentableIdMinMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdMinMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdMinSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_SENDER_ID_ASC',
  PostingsByAboutCommentableIdMinSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_SENDER_ID_DESC',
  PostingsByAboutCommentableIdMinSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_SUBJECT_ASC',
  PostingsByAboutCommentableIdMinSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_SUBJECT_DESC',
  PostingsByAboutCommentableIdMinSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdMinSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdMinUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdMinUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_MIN_UPDATED_AT_DESC',
  PostingsByAboutCommentableIdStddevPopulationAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdStddevPopulationAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdStddevPopulationAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdStddevPopulationAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdStddevPopulationAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdStddevPopulationAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdStddevPopulationBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_BODY_ASC',
  PostingsByAboutCommentableIdStddevPopulationBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_BODY_DESC',
  PostingsByAboutCommentableIdStddevPopulationConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdStddevPopulationConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdStddevPopulationCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  PostingsByAboutCommentableIdStddevPopulationCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  PostingsByAboutCommentableIdStddevPopulationHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_HASHTAGS_ASC',
  PostingsByAboutCommentableIdStddevPopulationHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_HASHTAGS_DESC',
  PostingsByAboutCommentableIdStddevPopulationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ID_ASC',
  PostingsByAboutCommentableIdStddevPopulationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_ID_DESC',
  PostingsByAboutCommentableIdStddevPopulationLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_LANGUAGE_ASC',
  PostingsByAboutCommentableIdStddevPopulationLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_LANGUAGE_DESC',
  PostingsByAboutCommentableIdStddevPopulationMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdStddevPopulationMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdStddevPopulationSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_SENDER_ID_ASC',
  PostingsByAboutCommentableIdStddevPopulationSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_SENDER_ID_DESC',
  PostingsByAboutCommentableIdStddevPopulationSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_SUBJECT_ASC',
  PostingsByAboutCommentableIdStddevPopulationSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_SUBJECT_DESC',
  PostingsByAboutCommentableIdStddevPopulationSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdStddevPopulationSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdStddevPopulationUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdStddevPopulationUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  PostingsByAboutCommentableIdStddevSampleAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdStddevSampleAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdStddevSampleAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdStddevSampleAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdStddevSampleAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdStddevSampleAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdStddevSampleBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_BODY_ASC',
  PostingsByAboutCommentableIdStddevSampleBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_BODY_DESC',
  PostingsByAboutCommentableIdStddevSampleConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdStddevSampleConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdStddevSampleCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  PostingsByAboutCommentableIdStddevSampleCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  PostingsByAboutCommentableIdStddevSampleHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_HASHTAGS_ASC',
  PostingsByAboutCommentableIdStddevSampleHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_HASHTAGS_DESC',
  PostingsByAboutCommentableIdStddevSampleIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ID_ASC',
  PostingsByAboutCommentableIdStddevSampleIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_ID_DESC',
  PostingsByAboutCommentableIdStddevSampleLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_LANGUAGE_ASC',
  PostingsByAboutCommentableIdStddevSampleLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_LANGUAGE_DESC',
  PostingsByAboutCommentableIdStddevSampleMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdStddevSampleMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdStddevSampleSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_SENDER_ID_ASC',
  PostingsByAboutCommentableIdStddevSampleSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_SENDER_ID_DESC',
  PostingsByAboutCommentableIdStddevSampleSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  PostingsByAboutCommentableIdStddevSampleSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  PostingsByAboutCommentableIdStddevSampleSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdStddevSampleSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdStddevSampleUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdStddevSampleUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  PostingsByAboutCommentableIdSumAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdSumAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdSumAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdSumAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdSumAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdSumAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdSumBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_BODY_ASC',
  PostingsByAboutCommentableIdSumBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_BODY_DESC',
  PostingsByAboutCommentableIdSumConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdSumConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdSumCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_CREATED_AT_ASC',
  PostingsByAboutCommentableIdSumCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_CREATED_AT_DESC',
  PostingsByAboutCommentableIdSumHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_HASHTAGS_ASC',
  PostingsByAboutCommentableIdSumHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_HASHTAGS_DESC',
  PostingsByAboutCommentableIdSumIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_ID_ASC',
  PostingsByAboutCommentableIdSumIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_ID_DESC',
  PostingsByAboutCommentableIdSumLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_LANGUAGE_ASC',
  PostingsByAboutCommentableIdSumLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_LANGUAGE_DESC',
  PostingsByAboutCommentableIdSumMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdSumMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdSumSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_SENDER_ID_ASC',
  PostingsByAboutCommentableIdSumSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_SENDER_ID_DESC',
  PostingsByAboutCommentableIdSumSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_SUBJECT_ASC',
  PostingsByAboutCommentableIdSumSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_SUBJECT_DESC',
  PostingsByAboutCommentableIdSumSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdSumSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdSumUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdSumUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_SUM_UPDATED_AT_DESC',
  PostingsByAboutCommentableIdVariancePopulationAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdVariancePopulationAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdVariancePopulationAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdVariancePopulationAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdVariancePopulationAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdVariancePopulationAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdVariancePopulationBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_BODY_ASC',
  PostingsByAboutCommentableIdVariancePopulationBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_BODY_DESC',
  PostingsByAboutCommentableIdVariancePopulationConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdVariancePopulationConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdVariancePopulationCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  PostingsByAboutCommentableIdVariancePopulationCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  PostingsByAboutCommentableIdVariancePopulationHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_HASHTAGS_ASC',
  PostingsByAboutCommentableIdVariancePopulationHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_HASHTAGS_DESC',
  PostingsByAboutCommentableIdVariancePopulationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ID_ASC',
  PostingsByAboutCommentableIdVariancePopulationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_ID_DESC',
  PostingsByAboutCommentableIdVariancePopulationLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_LANGUAGE_ASC',
  PostingsByAboutCommentableIdVariancePopulationLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_LANGUAGE_DESC',
  PostingsByAboutCommentableIdVariancePopulationMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdVariancePopulationMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdVariancePopulationSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_SENDER_ID_ASC',
  PostingsByAboutCommentableIdVariancePopulationSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_SENDER_ID_DESC',
  PostingsByAboutCommentableIdVariancePopulationSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  PostingsByAboutCommentableIdVariancePopulationSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  PostingsByAboutCommentableIdVariancePopulationSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdVariancePopulationSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdVariancePopulationUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdVariancePopulationUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  PostingsByAboutCommentableIdVarianceSampleAboutCommentableIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsByAboutCommentableIdVarianceSampleAboutCommentableIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsByAboutCommentableIdVarianceSampleAnsweredPostingIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ANSWERED_POSTING_ID_ASC',
  PostingsByAboutCommentableIdVarianceSampleAnsweredPostingIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ANSWERED_POSTING_ID_DESC',
  PostingsByAboutCommentableIdVarianceSampleAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsByAboutCommentableIdVarianceSampleAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsByAboutCommentableIdVarianceSampleBodyAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_BODY_ASC',
  PostingsByAboutCommentableIdVarianceSampleBodyDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_BODY_DESC',
  PostingsByAboutCommentableIdVarianceSampleConversationIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_CONVERSATION_ID_ASC',
  PostingsByAboutCommentableIdVarianceSampleConversationIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_CONVERSATION_ID_DESC',
  PostingsByAboutCommentableIdVarianceSampleCreatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PostingsByAboutCommentableIdVarianceSampleCreatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PostingsByAboutCommentableIdVarianceSampleHashtagsAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_HASHTAGS_ASC',
  PostingsByAboutCommentableIdVarianceSampleHashtagsDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_HASHTAGS_DESC',
  PostingsByAboutCommentableIdVarianceSampleIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ID_ASC',
  PostingsByAboutCommentableIdVarianceSampleIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_ID_DESC',
  PostingsByAboutCommentableIdVarianceSampleLanguageAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_LANGUAGE_ASC',
  PostingsByAboutCommentableIdVarianceSampleLanguageDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_LANGUAGE_DESC',
  PostingsByAboutCommentableIdVarianceSampleMentionedUsernamesAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_MENTIONED_USERNAMES_ASC',
  PostingsByAboutCommentableIdVarianceSampleMentionedUsernamesDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_MENTIONED_USERNAMES_DESC',
  PostingsByAboutCommentableIdVarianceSampleSenderIdAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_SENDER_ID_ASC',
  PostingsByAboutCommentableIdVarianceSampleSenderIdDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_SENDER_ID_DESC',
  PostingsByAboutCommentableIdVarianceSampleSubjectAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  PostingsByAboutCommentableIdVarianceSampleSubjectDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  PostingsByAboutCommentableIdVarianceSampleSubmittedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_SUBMITTED_AT_ASC',
  PostingsByAboutCommentableIdVarianceSampleSubmittedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_SUBMITTED_AT_DESC',
  PostingsByAboutCommentableIdVarianceSampleUpdatedAtAsc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  PostingsByAboutCommentableIdVarianceSampleUpdatedAtDesc = 'POSTINGS_BY_ABOUT_COMMENTABLE_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RegisteredAtAsc = 'REGISTERED_AT_ASC',
  RegisteredAtDesc = 'REGISTERED_AT_DESC'
}

export type Conversation = Node & {
  __typename?: 'Conversation';
  /** Reads a single `Commentable` that is related to this `Conversation`. */
  aboutCommentable?: Maybe<Commentable>;
  aboutCommentableId?: Maybe<Scalars['UUID']>;
  abstract?: Maybe<Scalars['String']>;
  countOfUnreadPostings?: Maybe<Scalars['BigInt']>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Conversation`. */
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['UUID']>;
  fulltext?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isPublic: Scalars['Boolean'];
  language: Scalars['String'];
  latestActivity?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Posting`. */
  postings: PostingsConnection;
  /** Reads a single `Group` that is related to this `Conversation`. */
  privateGroup?: Maybe<Group>;
  privateToGroupId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Tenant` that is related to this `Conversation`. */
  tenant?: Maybe<Tenant>;
  tenantId: Scalars['UUID'];
  title?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Trial`. */
  trialsByInternalMessagesId: TrialsConnection;
  /** Reads and enables pagination through a set of `Trial`. */
  trialsByMessagesId: TrialsConnection;
  /** Reads and enables pagination through a set of `Posting`. */
  unreadPostings: PostingsConnection;
  updatedAt: Scalars['Datetime'];
};


export type ConversationLatestActivityArgs = {
  includeMemberships?: InputMaybe<Scalars['Boolean']>;
};


export type ConversationPostingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingCondition>;
  filter?: InputMaybe<PostingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};


export type ConversationTrialsByInternalMessagesIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};


export type ConversationTrialsByMessagesIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};


export type ConversationUnreadPostingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingCondition>;
  filter?: InputMaybe<PostingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};

export type ConversationAggregates = {
  __typename?: 'ConversationAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ConversationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ConversationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ConversationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ConversationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ConversationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ConversationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ConversationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ConversationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ConversationVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Conversation` object types. */
export type ConversationAggregatesFilter = {
  /** Mean average aggregate over matching `Conversation` objects. */
  average?: InputMaybe<ConversationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Conversation` objects. */
  distinctCount?: InputMaybe<ConversationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Conversation` object to be included within the aggregate. */
  filter?: InputMaybe<ConversationFilter>;
  /** Maximum aggregate over matching `Conversation` objects. */
  max?: InputMaybe<ConversationMaxAggregateFilter>;
  /** Minimum aggregate over matching `Conversation` objects. */
  min?: InputMaybe<ConversationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Conversation` objects. */
  stddevPopulation?: InputMaybe<ConversationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Conversation` objects. */
  stddevSample?: InputMaybe<ConversationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Conversation` objects. */
  sum?: InputMaybe<ConversationSumAggregateFilter>;
  /** Population variance aggregate over matching `Conversation` objects. */
  variancePopulation?: InputMaybe<ConversationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Conversation` objects. */
  varianceSample?: InputMaybe<ConversationVarianceSampleAggregateFilter>;
};

export type ConversationAverageAggregateFilter = {
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
};

export type ConversationAverageAggregates = {
  __typename?: 'ConversationAverageAggregates';
  /** Mean average of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `Conversation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ConversationCondition = {
  /** Checks for equality with the object’s `aboutCommentableId` field. */
  aboutCommentableId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `abstract` field. */
  abstract?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `countOfUnreadPostings` field. */
  countOfUnreadPostings?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `creatorId` field. */
  creatorId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fulltext` field. */
  fulltext?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `language` field. */
  language?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `latestActivity` field. */
  latestActivity?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `privateToGroupId` field. */
  privateToGroupId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type ConversationDistinctCountAggregateFilter = {
  aboutCommentableId?: InputMaybe<BigIntFilter>;
  abstract?: InputMaybe<BigIntFilter>;
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  creatorId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isPublic?: InputMaybe<BigIntFilter>;
  language?: InputMaybe<BigIntFilter>;
  latestActivity?: InputMaybe<DatetimeFilter>;
  privateToGroupId?: InputMaybe<BigIntFilter>;
  tenantId?: InputMaybe<BigIntFilter>;
  title?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type ConversationDistinctCountAggregates = {
  __typename?: 'ConversationDistinctCountAggregates';
  /** Distinct count of aboutCommentableId across the matching connection */
  aboutCommentableId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of abstract across the matching connection */
  abstract?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creatorId across the matching connection */
  creatorId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  fulltext?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isPublic across the matching connection */
  isPublic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of language across the matching connection */
  language?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  latestActivity?: Maybe<Scalars['BigInt']>;
  /** Distinct count of privateToGroupId across the matching connection */
  privateToGroupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenantId across the matching connection */
  tenantId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};


export type ConversationDistinctCountAggregatesLatestActivityArgs = {
  includeMemberships?: InputMaybe<Scalars['Boolean']>;
};

/** A filter to be used against `Conversation` object types. All fields are combined with a logical ‘and.’ */
export type ConversationFilter = {
  /** Filter by the object’s `aboutCommentable` relation. */
  aboutCommentable?: InputMaybe<CommentableFilter>;
  /** A related `aboutCommentable` exists. */
  aboutCommentableExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `aboutCommentableId` field. */
  aboutCommentableId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `abstract` field. */
  abstract?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ConversationFilter>>;
  /** Filter by the object’s `countOfUnreadPostings` field. */
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `creator` relation. */
  creator?: InputMaybe<UserFilter>;
  /** A related `creator` exists. */
  creatorExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `creatorId` field. */
  creatorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `language` field. */
  language?: InputMaybe<StringFilter>;
  /** Filter by the object’s `latestActivity` field. */
  latestActivity?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ConversationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ConversationFilter>>;
  /** Filter by the object’s `postings` relation. */
  postings?: InputMaybe<ConversationToManyPostingFilter>;
  /** Some related `postings` exist. */
  postingsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `privateGroup` relation. */
  privateGroup?: InputMaybe<GroupFilter>;
  /** A related `privateGroup` exists. */
  privateGroupExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `privateToGroupId` field. */
  privateToGroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenant` relation. */
  tenant?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trialsByInternalMessagesId` relation. */
  trialsByInternalMessagesId?: InputMaybe<ConversationToManyTrialFilter>;
  /** Some related `trialsByInternalMessagesId` exist. */
  trialsByInternalMessagesIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `trialsByMessagesId` relation. */
  trialsByMessagesId?: InputMaybe<ConversationToManyTrialFilter>;
  /** Some related `trialsByMessagesId` exist. */
  trialsByMessagesIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Conversation` */
export type ConversationInput = {
  aboutCommentableId?: InputMaybe<Scalars['UUID']>;
  abstract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  creatorId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  privateToGroupId?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type ConversationMaxAggregateFilter = {
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
};

export type ConversationMaxAggregates = {
  __typename?: 'ConversationMaxAggregates';
  /** Maximum of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigInt']>;
};

export type ConversationMinAggregateFilter = {
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
};

export type ConversationMinAggregates = {
  __typename?: 'ConversationMinAggregates';
  /** Minimum of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigInt']>;
};

/** Represents an update to a `Conversation`. Fields that are set will be updated. */
export type ConversationPatch = {
  aboutCommentableId?: InputMaybe<Scalars['UUID']>;
  abstract?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  creatorId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  isPublic?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Scalars['String']>;
  privateToGroupId?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type ConversationStddevPopulationAggregateFilter = {
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
};

export type ConversationStddevPopulationAggregates = {
  __typename?: 'ConversationStddevPopulationAggregates';
  /** Population standard deviation of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
};

export type ConversationStddevSampleAggregateFilter = {
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
};

export type ConversationStddevSampleAggregates = {
  __typename?: 'ConversationStddevSampleAggregates';
  /** Sample standard deviation of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
};

export type ConversationSumAggregateFilter = {
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
};

export type ConversationSumAggregates = {
  __typename?: 'ConversationSumAggregates';
  /** Sum of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against many `Posting` object types. All fields are combined with a logical ‘and.’ */
export type ConversationToManyPostingFilter = {
  /** Aggregates across related `Posting` match the filter criteria. */
  aggregates?: InputMaybe<PostingAggregatesFilter>;
  /** Every related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostingFilter>;
  /** No related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostingFilter>;
  /** Some related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostingFilter>;
};

/** A filter to be used against many `Trial` object types. All fields are combined with a logical ‘and.’ */
export type ConversationToManyTrialFilter = {
  /** Aggregates across related `Trial` match the filter criteria. */
  aggregates?: InputMaybe<TrialAggregatesFilter>;
  /** Every related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TrialFilter>;
  /** No related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TrialFilter>;
  /** Some related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TrialFilter>;
};

export type ConversationVariancePopulationAggregateFilter = {
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
};

export type ConversationVariancePopulationAggregates = {
  __typename?: 'ConversationVariancePopulationAggregates';
  /** Population variance of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
};

export type ConversationVarianceSampleAggregateFilter = {
  countOfUnreadPostings?: InputMaybe<BigIntFilter>;
};

export type ConversationVarianceSampleAggregates = {
  __typename?: 'ConversationVarianceSampleAggregates';
  /** Sample variance of this field across the matching connection. */
  countOfUnreadPostings?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Conversation` values. */
export type ConversationsConnection = {
  __typename?: 'ConversationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ConversationAggregates>;
  /** A list of edges which contains the `Conversation` and cursor to aid in pagination. */
  edges: Array<ConversationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ConversationAggregates>>;
  /** A list of `Conversation` objects. */
  nodes: Array<Conversation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Conversation` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Conversation` values. */
export type ConversationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ConversationsGroupBy>;
  having?: InputMaybe<ConversationsHavingInput>;
};

/** A `Conversation` edge in the connection. */
export type ConversationsEdge = {
  __typename?: 'ConversationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Conversation` at the end of the edge. */
  node: Conversation;
};

/** Grouping methods for `Conversation` for usage during aggregation. */
export enum ConversationsGroupBy {
  AboutCommentableId = 'ABOUT_COMMENTABLE_ID',
  Abstract = 'ABSTRACT',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatorId = 'CREATOR_ID',
  IsPublic = 'IS_PUBLIC',
  Language = 'LANGUAGE',
  PrivateToGroupId = 'PRIVATE_TO_GROUP_ID',
  TenantId = 'TENANT_ID',
  Title = 'TITLE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type ConversationsHavingAverageConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingAverageInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingAverageConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConversationsHavingDistinctCountConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingDistinctCountConversationsLatestActivityArgsInput = {
  includeMemberships?: InputMaybe<Scalars['Boolean']>;
};

export type ConversationsHavingDistinctCountConversationsLatestActivityInput = {
  args?: InputMaybe<ConversationsHavingDistinctCountConversationsLatestActivityArgsInput>;
  filter?: InputMaybe<HavingDatetimeFilter>;
};

export type ConversationsHavingDistinctCountInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingDistinctCountConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  latestActivity?: InputMaybe<ConversationsHavingDistinctCountConversationsLatestActivityInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Conversation` aggregates. */
export type ConversationsHavingInput = {
  AND?: InputMaybe<Array<ConversationsHavingInput>>;
  OR?: InputMaybe<Array<ConversationsHavingInput>>;
  average?: InputMaybe<ConversationsHavingAverageInput>;
  distinctCount?: InputMaybe<ConversationsHavingDistinctCountInput>;
  max?: InputMaybe<ConversationsHavingMaxInput>;
  min?: InputMaybe<ConversationsHavingMinInput>;
  stddevPopulation?: InputMaybe<ConversationsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ConversationsHavingStddevSampleInput>;
  sum?: InputMaybe<ConversationsHavingSumInput>;
  variancePopulation?: InputMaybe<ConversationsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ConversationsHavingVarianceSampleInput>;
};

export type ConversationsHavingMaxConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingMaxInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingMaxConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConversationsHavingMinConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingMinInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingMinConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConversationsHavingStddevPopulationConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingStddevPopulationInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingStddevPopulationConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConversationsHavingStddevSampleConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingStddevSampleInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingStddevSampleConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConversationsHavingSumConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingSumInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingSumConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConversationsHavingVariancePopulationConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingVariancePopulationInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingVariancePopulationConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConversationsHavingVarianceSampleConversationsCountOfUnreadPostingsInput = {
  filter?: InputMaybe<HavingBigintFilter>;
};

export type ConversationsHavingVarianceSampleInput = {
  countOfUnreadPostings?: InputMaybe<ConversationsHavingVarianceSampleConversationsCountOfUnreadPostingsInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Conversation`. */
export enum ConversationsOrderBy {
  AboutCommentableIdAsc = 'ABOUT_COMMENTABLE_ID_ASC',
  AboutCommentableIdDesc = 'ABOUT_COMMENTABLE_ID_DESC',
  AbstractAsc = 'ABSTRACT_ASC',
  AbstractDesc = 'ABSTRACT_DESC',
  CountOfUnreadPostingsAsc = 'COUNT_OF_UNREAD_POSTINGS_ASC',
  CountOfUnreadPostingsDesc = 'COUNT_OF_UNREAD_POSTINGS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatorIdAsc = 'CREATOR_ID_ASC',
  CreatorIdDesc = 'CREATOR_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  LanguageAsc = 'LANGUAGE_ASC',
  LanguageDesc = 'LANGUAGE_DESC',
  LatestActivityAsc = 'LATEST_ACTIVITY_ASC',
  LatestActivityDesc = 'LATEST_ACTIVITY_DESC',
  Natural = 'NATURAL',
  PostingsAverageAboutCommentableIdAsc = 'POSTINGS_AVERAGE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsAverageAboutCommentableIdDesc = 'POSTINGS_AVERAGE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsAverageAnsweredPostingIdAsc = 'POSTINGS_AVERAGE_ANSWERED_POSTING_ID_ASC',
  PostingsAverageAnsweredPostingIdDesc = 'POSTINGS_AVERAGE_ANSWERED_POSTING_ID_DESC',
  PostingsAverageAnsweredPostingWasDeletedAsc = 'POSTINGS_AVERAGE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsAverageAnsweredPostingWasDeletedDesc = 'POSTINGS_AVERAGE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsAverageBodyAsc = 'POSTINGS_AVERAGE_BODY_ASC',
  PostingsAverageBodyDesc = 'POSTINGS_AVERAGE_BODY_DESC',
  PostingsAverageConversationIdAsc = 'POSTINGS_AVERAGE_CONVERSATION_ID_ASC',
  PostingsAverageConversationIdDesc = 'POSTINGS_AVERAGE_CONVERSATION_ID_DESC',
  PostingsAverageCreatedAtAsc = 'POSTINGS_AVERAGE_CREATED_AT_ASC',
  PostingsAverageCreatedAtDesc = 'POSTINGS_AVERAGE_CREATED_AT_DESC',
  PostingsAverageHashtagsAsc = 'POSTINGS_AVERAGE_HASHTAGS_ASC',
  PostingsAverageHashtagsDesc = 'POSTINGS_AVERAGE_HASHTAGS_DESC',
  PostingsAverageIdAsc = 'POSTINGS_AVERAGE_ID_ASC',
  PostingsAverageIdDesc = 'POSTINGS_AVERAGE_ID_DESC',
  PostingsAverageLanguageAsc = 'POSTINGS_AVERAGE_LANGUAGE_ASC',
  PostingsAverageLanguageDesc = 'POSTINGS_AVERAGE_LANGUAGE_DESC',
  PostingsAverageMentionedUsernamesAsc = 'POSTINGS_AVERAGE_MENTIONED_USERNAMES_ASC',
  PostingsAverageMentionedUsernamesDesc = 'POSTINGS_AVERAGE_MENTIONED_USERNAMES_DESC',
  PostingsAverageSenderIdAsc = 'POSTINGS_AVERAGE_SENDER_ID_ASC',
  PostingsAverageSenderIdDesc = 'POSTINGS_AVERAGE_SENDER_ID_DESC',
  PostingsAverageSubjectAsc = 'POSTINGS_AVERAGE_SUBJECT_ASC',
  PostingsAverageSubjectDesc = 'POSTINGS_AVERAGE_SUBJECT_DESC',
  PostingsAverageSubmittedAtAsc = 'POSTINGS_AVERAGE_SUBMITTED_AT_ASC',
  PostingsAverageSubmittedAtDesc = 'POSTINGS_AVERAGE_SUBMITTED_AT_DESC',
  PostingsAverageUpdatedAtAsc = 'POSTINGS_AVERAGE_UPDATED_AT_ASC',
  PostingsAverageUpdatedAtDesc = 'POSTINGS_AVERAGE_UPDATED_AT_DESC',
  PostingsCountAsc = 'POSTINGS_COUNT_ASC',
  PostingsCountDesc = 'POSTINGS_COUNT_DESC',
  PostingsDistinctCountAboutCommentableIdAsc = 'POSTINGS_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_ASC',
  PostingsDistinctCountAboutCommentableIdDesc = 'POSTINGS_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_DESC',
  PostingsDistinctCountAnsweredPostingIdAsc = 'POSTINGS_DISTINCT_COUNT_ANSWERED_POSTING_ID_ASC',
  PostingsDistinctCountAnsweredPostingIdDesc = 'POSTINGS_DISTINCT_COUNT_ANSWERED_POSTING_ID_DESC',
  PostingsDistinctCountAnsweredPostingWasDeletedAsc = 'POSTINGS_DISTINCT_COUNT_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsDistinctCountAnsweredPostingWasDeletedDesc = 'POSTINGS_DISTINCT_COUNT_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsDistinctCountBodyAsc = 'POSTINGS_DISTINCT_COUNT_BODY_ASC',
  PostingsDistinctCountBodyDesc = 'POSTINGS_DISTINCT_COUNT_BODY_DESC',
  PostingsDistinctCountConversationIdAsc = 'POSTINGS_DISTINCT_COUNT_CONVERSATION_ID_ASC',
  PostingsDistinctCountConversationIdDesc = 'POSTINGS_DISTINCT_COUNT_CONVERSATION_ID_DESC',
  PostingsDistinctCountCreatedAtAsc = 'POSTINGS_DISTINCT_COUNT_CREATED_AT_ASC',
  PostingsDistinctCountCreatedAtDesc = 'POSTINGS_DISTINCT_COUNT_CREATED_AT_DESC',
  PostingsDistinctCountHashtagsAsc = 'POSTINGS_DISTINCT_COUNT_HASHTAGS_ASC',
  PostingsDistinctCountHashtagsDesc = 'POSTINGS_DISTINCT_COUNT_HASHTAGS_DESC',
  PostingsDistinctCountIdAsc = 'POSTINGS_DISTINCT_COUNT_ID_ASC',
  PostingsDistinctCountIdDesc = 'POSTINGS_DISTINCT_COUNT_ID_DESC',
  PostingsDistinctCountLanguageAsc = 'POSTINGS_DISTINCT_COUNT_LANGUAGE_ASC',
  PostingsDistinctCountLanguageDesc = 'POSTINGS_DISTINCT_COUNT_LANGUAGE_DESC',
  PostingsDistinctCountMentionedUsernamesAsc = 'POSTINGS_DISTINCT_COUNT_MENTIONED_USERNAMES_ASC',
  PostingsDistinctCountMentionedUsernamesDesc = 'POSTINGS_DISTINCT_COUNT_MENTIONED_USERNAMES_DESC',
  PostingsDistinctCountSenderIdAsc = 'POSTINGS_DISTINCT_COUNT_SENDER_ID_ASC',
  PostingsDistinctCountSenderIdDesc = 'POSTINGS_DISTINCT_COUNT_SENDER_ID_DESC',
  PostingsDistinctCountSubjectAsc = 'POSTINGS_DISTINCT_COUNT_SUBJECT_ASC',
  PostingsDistinctCountSubjectDesc = 'POSTINGS_DISTINCT_COUNT_SUBJECT_DESC',
  PostingsDistinctCountSubmittedAtAsc = 'POSTINGS_DISTINCT_COUNT_SUBMITTED_AT_ASC',
  PostingsDistinctCountSubmittedAtDesc = 'POSTINGS_DISTINCT_COUNT_SUBMITTED_AT_DESC',
  PostingsDistinctCountUpdatedAtAsc = 'POSTINGS_DISTINCT_COUNT_UPDATED_AT_ASC',
  PostingsDistinctCountUpdatedAtDesc = 'POSTINGS_DISTINCT_COUNT_UPDATED_AT_DESC',
  PostingsMaxAboutCommentableIdAsc = 'POSTINGS_MAX_ABOUT_COMMENTABLE_ID_ASC',
  PostingsMaxAboutCommentableIdDesc = 'POSTINGS_MAX_ABOUT_COMMENTABLE_ID_DESC',
  PostingsMaxAnsweredPostingIdAsc = 'POSTINGS_MAX_ANSWERED_POSTING_ID_ASC',
  PostingsMaxAnsweredPostingIdDesc = 'POSTINGS_MAX_ANSWERED_POSTING_ID_DESC',
  PostingsMaxAnsweredPostingWasDeletedAsc = 'POSTINGS_MAX_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsMaxAnsweredPostingWasDeletedDesc = 'POSTINGS_MAX_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsMaxBodyAsc = 'POSTINGS_MAX_BODY_ASC',
  PostingsMaxBodyDesc = 'POSTINGS_MAX_BODY_DESC',
  PostingsMaxConversationIdAsc = 'POSTINGS_MAX_CONVERSATION_ID_ASC',
  PostingsMaxConversationIdDesc = 'POSTINGS_MAX_CONVERSATION_ID_DESC',
  PostingsMaxCreatedAtAsc = 'POSTINGS_MAX_CREATED_AT_ASC',
  PostingsMaxCreatedAtDesc = 'POSTINGS_MAX_CREATED_AT_DESC',
  PostingsMaxHashtagsAsc = 'POSTINGS_MAX_HASHTAGS_ASC',
  PostingsMaxHashtagsDesc = 'POSTINGS_MAX_HASHTAGS_DESC',
  PostingsMaxIdAsc = 'POSTINGS_MAX_ID_ASC',
  PostingsMaxIdDesc = 'POSTINGS_MAX_ID_DESC',
  PostingsMaxLanguageAsc = 'POSTINGS_MAX_LANGUAGE_ASC',
  PostingsMaxLanguageDesc = 'POSTINGS_MAX_LANGUAGE_DESC',
  PostingsMaxMentionedUsernamesAsc = 'POSTINGS_MAX_MENTIONED_USERNAMES_ASC',
  PostingsMaxMentionedUsernamesDesc = 'POSTINGS_MAX_MENTIONED_USERNAMES_DESC',
  PostingsMaxSenderIdAsc = 'POSTINGS_MAX_SENDER_ID_ASC',
  PostingsMaxSenderIdDesc = 'POSTINGS_MAX_SENDER_ID_DESC',
  PostingsMaxSubjectAsc = 'POSTINGS_MAX_SUBJECT_ASC',
  PostingsMaxSubjectDesc = 'POSTINGS_MAX_SUBJECT_DESC',
  PostingsMaxSubmittedAtAsc = 'POSTINGS_MAX_SUBMITTED_AT_ASC',
  PostingsMaxSubmittedAtDesc = 'POSTINGS_MAX_SUBMITTED_AT_DESC',
  PostingsMaxUpdatedAtAsc = 'POSTINGS_MAX_UPDATED_AT_ASC',
  PostingsMaxUpdatedAtDesc = 'POSTINGS_MAX_UPDATED_AT_DESC',
  PostingsMinAboutCommentableIdAsc = 'POSTINGS_MIN_ABOUT_COMMENTABLE_ID_ASC',
  PostingsMinAboutCommentableIdDesc = 'POSTINGS_MIN_ABOUT_COMMENTABLE_ID_DESC',
  PostingsMinAnsweredPostingIdAsc = 'POSTINGS_MIN_ANSWERED_POSTING_ID_ASC',
  PostingsMinAnsweredPostingIdDesc = 'POSTINGS_MIN_ANSWERED_POSTING_ID_DESC',
  PostingsMinAnsweredPostingWasDeletedAsc = 'POSTINGS_MIN_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsMinAnsweredPostingWasDeletedDesc = 'POSTINGS_MIN_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsMinBodyAsc = 'POSTINGS_MIN_BODY_ASC',
  PostingsMinBodyDesc = 'POSTINGS_MIN_BODY_DESC',
  PostingsMinConversationIdAsc = 'POSTINGS_MIN_CONVERSATION_ID_ASC',
  PostingsMinConversationIdDesc = 'POSTINGS_MIN_CONVERSATION_ID_DESC',
  PostingsMinCreatedAtAsc = 'POSTINGS_MIN_CREATED_AT_ASC',
  PostingsMinCreatedAtDesc = 'POSTINGS_MIN_CREATED_AT_DESC',
  PostingsMinHashtagsAsc = 'POSTINGS_MIN_HASHTAGS_ASC',
  PostingsMinHashtagsDesc = 'POSTINGS_MIN_HASHTAGS_DESC',
  PostingsMinIdAsc = 'POSTINGS_MIN_ID_ASC',
  PostingsMinIdDesc = 'POSTINGS_MIN_ID_DESC',
  PostingsMinLanguageAsc = 'POSTINGS_MIN_LANGUAGE_ASC',
  PostingsMinLanguageDesc = 'POSTINGS_MIN_LANGUAGE_DESC',
  PostingsMinMentionedUsernamesAsc = 'POSTINGS_MIN_MENTIONED_USERNAMES_ASC',
  PostingsMinMentionedUsernamesDesc = 'POSTINGS_MIN_MENTIONED_USERNAMES_DESC',
  PostingsMinSenderIdAsc = 'POSTINGS_MIN_SENDER_ID_ASC',
  PostingsMinSenderIdDesc = 'POSTINGS_MIN_SENDER_ID_DESC',
  PostingsMinSubjectAsc = 'POSTINGS_MIN_SUBJECT_ASC',
  PostingsMinSubjectDesc = 'POSTINGS_MIN_SUBJECT_DESC',
  PostingsMinSubmittedAtAsc = 'POSTINGS_MIN_SUBMITTED_AT_ASC',
  PostingsMinSubmittedAtDesc = 'POSTINGS_MIN_SUBMITTED_AT_DESC',
  PostingsMinUpdatedAtAsc = 'POSTINGS_MIN_UPDATED_AT_ASC',
  PostingsMinUpdatedAtDesc = 'POSTINGS_MIN_UPDATED_AT_DESC',
  PostingsStddevPopulationAboutCommentableIdAsc = 'POSTINGS_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  PostingsStddevPopulationAboutCommentableIdDesc = 'POSTINGS_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  PostingsStddevPopulationAnsweredPostingIdAsc = 'POSTINGS_STDDEV_POPULATION_ANSWERED_POSTING_ID_ASC',
  PostingsStddevPopulationAnsweredPostingIdDesc = 'POSTINGS_STDDEV_POPULATION_ANSWERED_POSTING_ID_DESC',
  PostingsStddevPopulationAnsweredPostingWasDeletedAsc = 'POSTINGS_STDDEV_POPULATION_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsStddevPopulationAnsweredPostingWasDeletedDesc = 'POSTINGS_STDDEV_POPULATION_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsStddevPopulationBodyAsc = 'POSTINGS_STDDEV_POPULATION_BODY_ASC',
  PostingsStddevPopulationBodyDesc = 'POSTINGS_STDDEV_POPULATION_BODY_DESC',
  PostingsStddevPopulationConversationIdAsc = 'POSTINGS_STDDEV_POPULATION_CONVERSATION_ID_ASC',
  PostingsStddevPopulationConversationIdDesc = 'POSTINGS_STDDEV_POPULATION_CONVERSATION_ID_DESC',
  PostingsStddevPopulationCreatedAtAsc = 'POSTINGS_STDDEV_POPULATION_CREATED_AT_ASC',
  PostingsStddevPopulationCreatedAtDesc = 'POSTINGS_STDDEV_POPULATION_CREATED_AT_DESC',
  PostingsStddevPopulationHashtagsAsc = 'POSTINGS_STDDEV_POPULATION_HASHTAGS_ASC',
  PostingsStddevPopulationHashtagsDesc = 'POSTINGS_STDDEV_POPULATION_HASHTAGS_DESC',
  PostingsStddevPopulationIdAsc = 'POSTINGS_STDDEV_POPULATION_ID_ASC',
  PostingsStddevPopulationIdDesc = 'POSTINGS_STDDEV_POPULATION_ID_DESC',
  PostingsStddevPopulationLanguageAsc = 'POSTINGS_STDDEV_POPULATION_LANGUAGE_ASC',
  PostingsStddevPopulationLanguageDesc = 'POSTINGS_STDDEV_POPULATION_LANGUAGE_DESC',
  PostingsStddevPopulationMentionedUsernamesAsc = 'POSTINGS_STDDEV_POPULATION_MENTIONED_USERNAMES_ASC',
  PostingsStddevPopulationMentionedUsernamesDesc = 'POSTINGS_STDDEV_POPULATION_MENTIONED_USERNAMES_DESC',
  PostingsStddevPopulationSenderIdAsc = 'POSTINGS_STDDEV_POPULATION_SENDER_ID_ASC',
  PostingsStddevPopulationSenderIdDesc = 'POSTINGS_STDDEV_POPULATION_SENDER_ID_DESC',
  PostingsStddevPopulationSubjectAsc = 'POSTINGS_STDDEV_POPULATION_SUBJECT_ASC',
  PostingsStddevPopulationSubjectDesc = 'POSTINGS_STDDEV_POPULATION_SUBJECT_DESC',
  PostingsStddevPopulationSubmittedAtAsc = 'POSTINGS_STDDEV_POPULATION_SUBMITTED_AT_ASC',
  PostingsStddevPopulationSubmittedAtDesc = 'POSTINGS_STDDEV_POPULATION_SUBMITTED_AT_DESC',
  PostingsStddevPopulationUpdatedAtAsc = 'POSTINGS_STDDEV_POPULATION_UPDATED_AT_ASC',
  PostingsStddevPopulationUpdatedAtDesc = 'POSTINGS_STDDEV_POPULATION_UPDATED_AT_DESC',
  PostingsStddevSampleAboutCommentableIdAsc = 'POSTINGS_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsStddevSampleAboutCommentableIdDesc = 'POSTINGS_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsStddevSampleAnsweredPostingIdAsc = 'POSTINGS_STDDEV_SAMPLE_ANSWERED_POSTING_ID_ASC',
  PostingsStddevSampleAnsweredPostingIdDesc = 'POSTINGS_STDDEV_SAMPLE_ANSWERED_POSTING_ID_DESC',
  PostingsStddevSampleAnsweredPostingWasDeletedAsc = 'POSTINGS_STDDEV_SAMPLE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsStddevSampleAnsweredPostingWasDeletedDesc = 'POSTINGS_STDDEV_SAMPLE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsStddevSampleBodyAsc = 'POSTINGS_STDDEV_SAMPLE_BODY_ASC',
  PostingsStddevSampleBodyDesc = 'POSTINGS_STDDEV_SAMPLE_BODY_DESC',
  PostingsStddevSampleConversationIdAsc = 'POSTINGS_STDDEV_SAMPLE_CONVERSATION_ID_ASC',
  PostingsStddevSampleConversationIdDesc = 'POSTINGS_STDDEV_SAMPLE_CONVERSATION_ID_DESC',
  PostingsStddevSampleCreatedAtAsc = 'POSTINGS_STDDEV_SAMPLE_CREATED_AT_ASC',
  PostingsStddevSampleCreatedAtDesc = 'POSTINGS_STDDEV_SAMPLE_CREATED_AT_DESC',
  PostingsStddevSampleHashtagsAsc = 'POSTINGS_STDDEV_SAMPLE_HASHTAGS_ASC',
  PostingsStddevSampleHashtagsDesc = 'POSTINGS_STDDEV_SAMPLE_HASHTAGS_DESC',
  PostingsStddevSampleIdAsc = 'POSTINGS_STDDEV_SAMPLE_ID_ASC',
  PostingsStddevSampleIdDesc = 'POSTINGS_STDDEV_SAMPLE_ID_DESC',
  PostingsStddevSampleLanguageAsc = 'POSTINGS_STDDEV_SAMPLE_LANGUAGE_ASC',
  PostingsStddevSampleLanguageDesc = 'POSTINGS_STDDEV_SAMPLE_LANGUAGE_DESC',
  PostingsStddevSampleMentionedUsernamesAsc = 'POSTINGS_STDDEV_SAMPLE_MENTIONED_USERNAMES_ASC',
  PostingsStddevSampleMentionedUsernamesDesc = 'POSTINGS_STDDEV_SAMPLE_MENTIONED_USERNAMES_DESC',
  PostingsStddevSampleSenderIdAsc = 'POSTINGS_STDDEV_SAMPLE_SENDER_ID_ASC',
  PostingsStddevSampleSenderIdDesc = 'POSTINGS_STDDEV_SAMPLE_SENDER_ID_DESC',
  PostingsStddevSampleSubjectAsc = 'POSTINGS_STDDEV_SAMPLE_SUBJECT_ASC',
  PostingsStddevSampleSubjectDesc = 'POSTINGS_STDDEV_SAMPLE_SUBJECT_DESC',
  PostingsStddevSampleSubmittedAtAsc = 'POSTINGS_STDDEV_SAMPLE_SUBMITTED_AT_ASC',
  PostingsStddevSampleSubmittedAtDesc = 'POSTINGS_STDDEV_SAMPLE_SUBMITTED_AT_DESC',
  PostingsStddevSampleUpdatedAtAsc = 'POSTINGS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  PostingsStddevSampleUpdatedAtDesc = 'POSTINGS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  PostingsSumAboutCommentableIdAsc = 'POSTINGS_SUM_ABOUT_COMMENTABLE_ID_ASC',
  PostingsSumAboutCommentableIdDesc = 'POSTINGS_SUM_ABOUT_COMMENTABLE_ID_DESC',
  PostingsSumAnsweredPostingIdAsc = 'POSTINGS_SUM_ANSWERED_POSTING_ID_ASC',
  PostingsSumAnsweredPostingIdDesc = 'POSTINGS_SUM_ANSWERED_POSTING_ID_DESC',
  PostingsSumAnsweredPostingWasDeletedAsc = 'POSTINGS_SUM_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsSumAnsweredPostingWasDeletedDesc = 'POSTINGS_SUM_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsSumBodyAsc = 'POSTINGS_SUM_BODY_ASC',
  PostingsSumBodyDesc = 'POSTINGS_SUM_BODY_DESC',
  PostingsSumConversationIdAsc = 'POSTINGS_SUM_CONVERSATION_ID_ASC',
  PostingsSumConversationIdDesc = 'POSTINGS_SUM_CONVERSATION_ID_DESC',
  PostingsSumCreatedAtAsc = 'POSTINGS_SUM_CREATED_AT_ASC',
  PostingsSumCreatedAtDesc = 'POSTINGS_SUM_CREATED_AT_DESC',
  PostingsSumHashtagsAsc = 'POSTINGS_SUM_HASHTAGS_ASC',
  PostingsSumHashtagsDesc = 'POSTINGS_SUM_HASHTAGS_DESC',
  PostingsSumIdAsc = 'POSTINGS_SUM_ID_ASC',
  PostingsSumIdDesc = 'POSTINGS_SUM_ID_DESC',
  PostingsSumLanguageAsc = 'POSTINGS_SUM_LANGUAGE_ASC',
  PostingsSumLanguageDesc = 'POSTINGS_SUM_LANGUAGE_DESC',
  PostingsSumMentionedUsernamesAsc = 'POSTINGS_SUM_MENTIONED_USERNAMES_ASC',
  PostingsSumMentionedUsernamesDesc = 'POSTINGS_SUM_MENTIONED_USERNAMES_DESC',
  PostingsSumSenderIdAsc = 'POSTINGS_SUM_SENDER_ID_ASC',
  PostingsSumSenderIdDesc = 'POSTINGS_SUM_SENDER_ID_DESC',
  PostingsSumSubjectAsc = 'POSTINGS_SUM_SUBJECT_ASC',
  PostingsSumSubjectDesc = 'POSTINGS_SUM_SUBJECT_DESC',
  PostingsSumSubmittedAtAsc = 'POSTINGS_SUM_SUBMITTED_AT_ASC',
  PostingsSumSubmittedAtDesc = 'POSTINGS_SUM_SUBMITTED_AT_DESC',
  PostingsSumUpdatedAtAsc = 'POSTINGS_SUM_UPDATED_AT_ASC',
  PostingsSumUpdatedAtDesc = 'POSTINGS_SUM_UPDATED_AT_DESC',
  PostingsVariancePopulationAboutCommentableIdAsc = 'POSTINGS_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  PostingsVariancePopulationAboutCommentableIdDesc = 'POSTINGS_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  PostingsVariancePopulationAnsweredPostingIdAsc = 'POSTINGS_VARIANCE_POPULATION_ANSWERED_POSTING_ID_ASC',
  PostingsVariancePopulationAnsweredPostingIdDesc = 'POSTINGS_VARIANCE_POPULATION_ANSWERED_POSTING_ID_DESC',
  PostingsVariancePopulationAnsweredPostingWasDeletedAsc = 'POSTINGS_VARIANCE_POPULATION_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsVariancePopulationAnsweredPostingWasDeletedDesc = 'POSTINGS_VARIANCE_POPULATION_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsVariancePopulationBodyAsc = 'POSTINGS_VARIANCE_POPULATION_BODY_ASC',
  PostingsVariancePopulationBodyDesc = 'POSTINGS_VARIANCE_POPULATION_BODY_DESC',
  PostingsVariancePopulationConversationIdAsc = 'POSTINGS_VARIANCE_POPULATION_CONVERSATION_ID_ASC',
  PostingsVariancePopulationConversationIdDesc = 'POSTINGS_VARIANCE_POPULATION_CONVERSATION_ID_DESC',
  PostingsVariancePopulationCreatedAtAsc = 'POSTINGS_VARIANCE_POPULATION_CREATED_AT_ASC',
  PostingsVariancePopulationCreatedAtDesc = 'POSTINGS_VARIANCE_POPULATION_CREATED_AT_DESC',
  PostingsVariancePopulationHashtagsAsc = 'POSTINGS_VARIANCE_POPULATION_HASHTAGS_ASC',
  PostingsVariancePopulationHashtagsDesc = 'POSTINGS_VARIANCE_POPULATION_HASHTAGS_DESC',
  PostingsVariancePopulationIdAsc = 'POSTINGS_VARIANCE_POPULATION_ID_ASC',
  PostingsVariancePopulationIdDesc = 'POSTINGS_VARIANCE_POPULATION_ID_DESC',
  PostingsVariancePopulationLanguageAsc = 'POSTINGS_VARIANCE_POPULATION_LANGUAGE_ASC',
  PostingsVariancePopulationLanguageDesc = 'POSTINGS_VARIANCE_POPULATION_LANGUAGE_DESC',
  PostingsVariancePopulationMentionedUsernamesAsc = 'POSTINGS_VARIANCE_POPULATION_MENTIONED_USERNAMES_ASC',
  PostingsVariancePopulationMentionedUsernamesDesc = 'POSTINGS_VARIANCE_POPULATION_MENTIONED_USERNAMES_DESC',
  PostingsVariancePopulationSenderIdAsc = 'POSTINGS_VARIANCE_POPULATION_SENDER_ID_ASC',
  PostingsVariancePopulationSenderIdDesc = 'POSTINGS_VARIANCE_POPULATION_SENDER_ID_DESC',
  PostingsVariancePopulationSubjectAsc = 'POSTINGS_VARIANCE_POPULATION_SUBJECT_ASC',
  PostingsVariancePopulationSubjectDesc = 'POSTINGS_VARIANCE_POPULATION_SUBJECT_DESC',
  PostingsVariancePopulationSubmittedAtAsc = 'POSTINGS_VARIANCE_POPULATION_SUBMITTED_AT_ASC',
  PostingsVariancePopulationSubmittedAtDesc = 'POSTINGS_VARIANCE_POPULATION_SUBMITTED_AT_DESC',
  PostingsVariancePopulationUpdatedAtAsc = 'POSTINGS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  PostingsVariancePopulationUpdatedAtDesc = 'POSTINGS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  PostingsVarianceSampleAboutCommentableIdAsc = 'POSTINGS_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsVarianceSampleAboutCommentableIdDesc = 'POSTINGS_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsVarianceSampleAnsweredPostingIdAsc = 'POSTINGS_VARIANCE_SAMPLE_ANSWERED_POSTING_ID_ASC',
  PostingsVarianceSampleAnsweredPostingIdDesc = 'POSTINGS_VARIANCE_SAMPLE_ANSWERED_POSTING_ID_DESC',
  PostingsVarianceSampleAnsweredPostingWasDeletedAsc = 'POSTINGS_VARIANCE_SAMPLE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsVarianceSampleAnsweredPostingWasDeletedDesc = 'POSTINGS_VARIANCE_SAMPLE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsVarianceSampleBodyAsc = 'POSTINGS_VARIANCE_SAMPLE_BODY_ASC',
  PostingsVarianceSampleBodyDesc = 'POSTINGS_VARIANCE_SAMPLE_BODY_DESC',
  PostingsVarianceSampleConversationIdAsc = 'POSTINGS_VARIANCE_SAMPLE_CONVERSATION_ID_ASC',
  PostingsVarianceSampleConversationIdDesc = 'POSTINGS_VARIANCE_SAMPLE_CONVERSATION_ID_DESC',
  PostingsVarianceSampleCreatedAtAsc = 'POSTINGS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PostingsVarianceSampleCreatedAtDesc = 'POSTINGS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PostingsVarianceSampleHashtagsAsc = 'POSTINGS_VARIANCE_SAMPLE_HASHTAGS_ASC',
  PostingsVarianceSampleHashtagsDesc = 'POSTINGS_VARIANCE_SAMPLE_HASHTAGS_DESC',
  PostingsVarianceSampleIdAsc = 'POSTINGS_VARIANCE_SAMPLE_ID_ASC',
  PostingsVarianceSampleIdDesc = 'POSTINGS_VARIANCE_SAMPLE_ID_DESC',
  PostingsVarianceSampleLanguageAsc = 'POSTINGS_VARIANCE_SAMPLE_LANGUAGE_ASC',
  PostingsVarianceSampleLanguageDesc = 'POSTINGS_VARIANCE_SAMPLE_LANGUAGE_DESC',
  PostingsVarianceSampleMentionedUsernamesAsc = 'POSTINGS_VARIANCE_SAMPLE_MENTIONED_USERNAMES_ASC',
  PostingsVarianceSampleMentionedUsernamesDesc = 'POSTINGS_VARIANCE_SAMPLE_MENTIONED_USERNAMES_DESC',
  PostingsVarianceSampleSenderIdAsc = 'POSTINGS_VARIANCE_SAMPLE_SENDER_ID_ASC',
  PostingsVarianceSampleSenderIdDesc = 'POSTINGS_VARIANCE_SAMPLE_SENDER_ID_DESC',
  PostingsVarianceSampleSubjectAsc = 'POSTINGS_VARIANCE_SAMPLE_SUBJECT_ASC',
  PostingsVarianceSampleSubjectDesc = 'POSTINGS_VARIANCE_SAMPLE_SUBJECT_DESC',
  PostingsVarianceSampleSubmittedAtAsc = 'POSTINGS_VARIANCE_SAMPLE_SUBMITTED_AT_ASC',
  PostingsVarianceSampleSubmittedAtDesc = 'POSTINGS_VARIANCE_SAMPLE_SUBMITTED_AT_DESC',
  PostingsVarianceSampleUpdatedAtAsc = 'POSTINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  PostingsVarianceSampleUpdatedAtDesc = 'POSTINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivateToGroupIdAsc = 'PRIVATE_TO_GROUP_ID_ASC',
  PrivateToGroupIdDesc = 'PRIVATE_TO_GROUP_ID_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  TrialsByInternalMessagesIdAverageAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_AVATAR_ASC',
  TrialsByInternalMessagesIdAverageAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_AVATAR_DESC',
  TrialsByInternalMessagesIdAverageCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdAverageCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdAverageCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdAverageCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdAverageCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdAverageCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdAverageCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdAverageCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdAverageCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CREATED_AT_ASC',
  TrialsByInternalMessagesIdAverageCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CREATED_AT_DESC',
  TrialsByInternalMessagesIdAverageCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdAverageCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdAverageEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdAverageEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdAverageHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdAverageHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdAverageHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdAverageHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdAverageHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdAverageHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdAverageHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdAverageHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdAverageHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdAverageHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdAverageHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdAverageHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdAverageHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdAverageHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdAverageIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_ID_ASC',
  TrialsByInternalMessagesIdAverageIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_ID_DESC',
  TrialsByInternalMessagesIdAverageInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdAverageInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdAverageMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdAverageMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdAverageMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdAverageMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdAverageMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdAverageMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdAverageMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdAverageMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdAverageNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_NAME_ASC',
  TrialsByInternalMessagesIdAverageNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_NAME_DESC',
  TrialsByInternalMessagesIdAverageStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_STAFF_ID_ASC',
  TrialsByInternalMessagesIdAverageStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_STAFF_ID_DESC',
  TrialsByInternalMessagesIdAverageSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdAverageSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdAverageSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdAverageSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdAverageTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_TENANT_ID_ASC',
  TrialsByInternalMessagesIdAverageTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_TENANT_ID_DESC',
  TrialsByInternalMessagesIdAverageUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdAverageUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_AVERAGE_UPDATED_AT_DESC',
  TrialsByInternalMessagesIdCountAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_COUNT_ASC',
  TrialsByInternalMessagesIdCountDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_COUNT_DESC',
  TrialsByInternalMessagesIdDistinctCountAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_AVATAR_ASC',
  TrialsByInternalMessagesIdDistinctCountAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_AVATAR_DESC',
  TrialsByInternalMessagesIdDistinctCountCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdDistinctCountCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdDistinctCountCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdDistinctCountCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdDistinctCountCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdDistinctCountCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdDistinctCountCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdDistinctCountCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdDistinctCountCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  TrialsByInternalMessagesIdDistinctCountCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  TrialsByInternalMessagesIdDistinctCountCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdDistinctCountCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdDistinctCountEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdDistinctCountEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdDistinctCountHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdDistinctCountHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdDistinctCountHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdDistinctCountHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdDistinctCountHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdDistinctCountHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdDistinctCountHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdDistinctCountHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdDistinctCountHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdDistinctCountHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdDistinctCountHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdDistinctCountHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdDistinctCountHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdDistinctCountHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdDistinctCountIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_ID_ASC',
  TrialsByInternalMessagesIdDistinctCountIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_ID_DESC',
  TrialsByInternalMessagesIdDistinctCountInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdDistinctCountInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdDistinctCountMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdDistinctCountMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdDistinctCountMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdDistinctCountMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdDistinctCountMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdDistinctCountMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdDistinctCountNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_NAME_ASC',
  TrialsByInternalMessagesIdDistinctCountNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_NAME_DESC',
  TrialsByInternalMessagesIdDistinctCountStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_STAFF_ID_ASC',
  TrialsByInternalMessagesIdDistinctCountStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_STAFF_ID_DESC',
  TrialsByInternalMessagesIdDistinctCountSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdDistinctCountSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdDistinctCountSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdDistinctCountSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdDistinctCountTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  TrialsByInternalMessagesIdDistinctCountTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  TrialsByInternalMessagesIdDistinctCountUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdDistinctCountUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  TrialsByInternalMessagesIdMaxAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_AVATAR_ASC',
  TrialsByInternalMessagesIdMaxAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_AVATAR_DESC',
  TrialsByInternalMessagesIdMaxCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdMaxCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdMaxCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdMaxCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdMaxCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdMaxCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdMaxCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdMaxCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdMaxCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CREATED_AT_ASC',
  TrialsByInternalMessagesIdMaxCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CREATED_AT_DESC',
  TrialsByInternalMessagesIdMaxCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdMaxCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdMaxEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdMaxEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdMaxHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdMaxHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdMaxHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdMaxHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdMaxHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdMaxHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdMaxHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdMaxHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdMaxHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdMaxHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdMaxHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdMaxHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdMaxHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdMaxHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdMaxIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_ID_ASC',
  TrialsByInternalMessagesIdMaxIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_ID_DESC',
  TrialsByInternalMessagesIdMaxInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdMaxInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdMaxMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdMaxMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdMaxMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdMaxMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdMaxMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdMaxMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdMaxMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdMaxMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdMaxNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_NAME_ASC',
  TrialsByInternalMessagesIdMaxNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_NAME_DESC',
  TrialsByInternalMessagesIdMaxStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_STAFF_ID_ASC',
  TrialsByInternalMessagesIdMaxStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_STAFF_ID_DESC',
  TrialsByInternalMessagesIdMaxSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdMaxSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdMaxSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdMaxSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdMaxTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_TENANT_ID_ASC',
  TrialsByInternalMessagesIdMaxTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_TENANT_ID_DESC',
  TrialsByInternalMessagesIdMaxUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdMaxUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MAX_UPDATED_AT_DESC',
  TrialsByInternalMessagesIdMinAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_AVATAR_ASC',
  TrialsByInternalMessagesIdMinAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_AVATAR_DESC',
  TrialsByInternalMessagesIdMinCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdMinCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdMinCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdMinCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdMinCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdMinCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdMinCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdMinCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdMinCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CREATED_AT_ASC',
  TrialsByInternalMessagesIdMinCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CREATED_AT_DESC',
  TrialsByInternalMessagesIdMinCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdMinCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdMinEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdMinEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdMinHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdMinHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdMinHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdMinHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdMinHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdMinHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdMinHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdMinHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdMinHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdMinHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdMinHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdMinHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdMinHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdMinHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdMinIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_ID_ASC',
  TrialsByInternalMessagesIdMinIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_ID_DESC',
  TrialsByInternalMessagesIdMinInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdMinInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdMinMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdMinMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdMinMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdMinMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdMinMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdMinMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdMinMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdMinMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdMinNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_NAME_ASC',
  TrialsByInternalMessagesIdMinNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_NAME_DESC',
  TrialsByInternalMessagesIdMinStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_STAFF_ID_ASC',
  TrialsByInternalMessagesIdMinStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_STAFF_ID_DESC',
  TrialsByInternalMessagesIdMinSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdMinSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdMinSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdMinSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdMinTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_TENANT_ID_ASC',
  TrialsByInternalMessagesIdMinTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_TENANT_ID_DESC',
  TrialsByInternalMessagesIdMinUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdMinUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_MIN_UPDATED_AT_DESC',
  TrialsByInternalMessagesIdStddevPopulationAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_AVATAR_ASC',
  TrialsByInternalMessagesIdStddevPopulationAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_AVATAR_DESC',
  TrialsByInternalMessagesIdStddevPopulationCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdStddevPopulationCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdStddevPopulationCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdStddevPopulationCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdStddevPopulationCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdStddevPopulationCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdStddevPopulationCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdStddevPopulationCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdStddevPopulationCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  TrialsByInternalMessagesIdStddevPopulationCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  TrialsByInternalMessagesIdStddevPopulationCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdStddevPopulationCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdStddevPopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdStddevPopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdStddevPopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdStddevPopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdStddevPopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdStddevPopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdStddevPopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdStddevPopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdStddevPopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdStddevPopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdStddevPopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdStddevPopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdStddevPopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdStddevPopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdStddevPopulationHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdStddevPopulationHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdStddevPopulationIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_ID_ASC',
  TrialsByInternalMessagesIdStddevPopulationIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_ID_DESC',
  TrialsByInternalMessagesIdStddevPopulationInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdStddevPopulationInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdStddevPopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdStddevPopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdStddevPopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdStddevPopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdStddevPopulationMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdStddevPopulationMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdStddevPopulationNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_NAME_ASC',
  TrialsByInternalMessagesIdStddevPopulationNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_NAME_DESC',
  TrialsByInternalMessagesIdStddevPopulationStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_STAFF_ID_ASC',
  TrialsByInternalMessagesIdStddevPopulationStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_STAFF_ID_DESC',
  TrialsByInternalMessagesIdStddevPopulationSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdStddevPopulationSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdStddevPopulationSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdStddevPopulationSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdStddevPopulationTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  TrialsByInternalMessagesIdStddevPopulationTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  TrialsByInternalMessagesIdStddevPopulationUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdStddevPopulationUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  TrialsByInternalMessagesIdStddevSampleAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_AVATAR_ASC',
  TrialsByInternalMessagesIdStddevSampleAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_AVATAR_DESC',
  TrialsByInternalMessagesIdStddevSampleCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdStddevSampleCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdStddevSampleCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdStddevSampleCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdStddevSampleCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdStddevSampleCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdStddevSampleCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdStddevSampleCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdStddevSampleCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  TrialsByInternalMessagesIdStddevSampleCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  TrialsByInternalMessagesIdStddevSampleCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdStddevSampleCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdStddevSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdStddevSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdStddevSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdStddevSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdStddevSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdStddevSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdStddevSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdStddevSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdStddevSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdStddevSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdStddevSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdStddevSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdStddevSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdStddevSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdStddevSampleHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdStddevSampleHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdStddevSampleIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_ID_ASC',
  TrialsByInternalMessagesIdStddevSampleIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_ID_DESC',
  TrialsByInternalMessagesIdStddevSampleInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdStddevSampleInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdStddevSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdStddevSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdStddevSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdStddevSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdStddevSampleMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdStddevSampleMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdStddevSampleNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_NAME_ASC',
  TrialsByInternalMessagesIdStddevSampleNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_NAME_DESC',
  TrialsByInternalMessagesIdStddevSampleStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_STAFF_ID_ASC',
  TrialsByInternalMessagesIdStddevSampleStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_STAFF_ID_DESC',
  TrialsByInternalMessagesIdStddevSampleSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdStddevSampleSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdStddevSampleSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdStddevSampleSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdStddevSampleTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  TrialsByInternalMessagesIdStddevSampleTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  TrialsByInternalMessagesIdStddevSampleUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdStddevSampleUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TrialsByInternalMessagesIdSumAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_AVATAR_ASC',
  TrialsByInternalMessagesIdSumAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_AVATAR_DESC',
  TrialsByInternalMessagesIdSumCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdSumCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdSumCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdSumCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdSumCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdSumCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdSumCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdSumCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdSumCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CREATED_AT_ASC',
  TrialsByInternalMessagesIdSumCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CREATED_AT_DESC',
  TrialsByInternalMessagesIdSumCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdSumCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdSumEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdSumEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdSumHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdSumHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdSumHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdSumHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdSumHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdSumHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdSumHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdSumHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdSumHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdSumHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdSumHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdSumHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdSumHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdSumHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdSumIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_ID_ASC',
  TrialsByInternalMessagesIdSumIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_ID_DESC',
  TrialsByInternalMessagesIdSumInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdSumInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdSumMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdSumMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdSumMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdSumMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdSumMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdSumMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdSumMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdSumMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdSumNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_NAME_ASC',
  TrialsByInternalMessagesIdSumNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_NAME_DESC',
  TrialsByInternalMessagesIdSumStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_STAFF_ID_ASC',
  TrialsByInternalMessagesIdSumStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_STAFF_ID_DESC',
  TrialsByInternalMessagesIdSumSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdSumSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdSumSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdSumSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdSumTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_TENANT_ID_ASC',
  TrialsByInternalMessagesIdSumTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_TENANT_ID_DESC',
  TrialsByInternalMessagesIdSumUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdSumUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_SUM_UPDATED_AT_DESC',
  TrialsByInternalMessagesIdVariancePopulationAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_AVATAR_ASC',
  TrialsByInternalMessagesIdVariancePopulationAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_AVATAR_DESC',
  TrialsByInternalMessagesIdVariancePopulationCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdVariancePopulationCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdVariancePopulationCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdVariancePopulationCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdVariancePopulationCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdVariancePopulationCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdVariancePopulationCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdVariancePopulationCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdVariancePopulationCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  TrialsByInternalMessagesIdVariancePopulationCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  TrialsByInternalMessagesIdVariancePopulationCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdVariancePopulationCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdVariancePopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdVariancePopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdVariancePopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdVariancePopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdVariancePopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdVariancePopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdVariancePopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdVariancePopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdVariancePopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdVariancePopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdVariancePopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdVariancePopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdVariancePopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdVariancePopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdVariancePopulationHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdVariancePopulationHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdVariancePopulationIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_ID_ASC',
  TrialsByInternalMessagesIdVariancePopulationIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_ID_DESC',
  TrialsByInternalMessagesIdVariancePopulationInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdVariancePopulationInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdVariancePopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdVariancePopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdVariancePopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdVariancePopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdVariancePopulationMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdVariancePopulationMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdVariancePopulationNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_NAME_ASC',
  TrialsByInternalMessagesIdVariancePopulationNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_NAME_DESC',
  TrialsByInternalMessagesIdVariancePopulationStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_STAFF_ID_ASC',
  TrialsByInternalMessagesIdVariancePopulationStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_STAFF_ID_DESC',
  TrialsByInternalMessagesIdVariancePopulationSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdVariancePopulationSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdVariancePopulationSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdVariancePopulationSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdVariancePopulationTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  TrialsByInternalMessagesIdVariancePopulationTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  TrialsByInternalMessagesIdVariancePopulationUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdVariancePopulationUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TrialsByInternalMessagesIdVarianceSampleAvatarAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_AVATAR_ASC',
  TrialsByInternalMessagesIdVarianceSampleAvatarDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_AVATAR_DESC',
  TrialsByInternalMessagesIdVarianceSampleCannotUseTheInterventionAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByInternalMessagesIdVarianceSampleCannotUseTheInterventionDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByInternalMessagesIdVarianceSampleCanAccessErSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByInternalMessagesIdVarianceSampleCanAccessErSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByInternalMessagesIdVarianceSampleCanAccessSipSinceAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByInternalMessagesIdVarianceSampleCanAccessSipSinceDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByInternalMessagesIdVarianceSampleCanBeIncludedAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsByInternalMessagesIdVarianceSampleCanBeIncludedDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsByInternalMessagesIdVarianceSampleCreatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TrialsByInternalMessagesIdVarianceSampleCreatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TrialsByInternalMessagesIdVarianceSampleCreatedByIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CREATED_BY_ID_ASC',
  TrialsByInternalMessagesIdVarianceSampleCreatedByIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_CREATED_BY_ID_DESC',
  TrialsByInternalMessagesIdVarianceSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByInternalMessagesIdVarianceSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByInternalMessagesIdVarianceSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByInternalMessagesIdVarianceSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByInternalMessagesIdVarianceSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByInternalMessagesIdVarianceSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByInternalMessagesIdVarianceSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByInternalMessagesIdVarianceSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByInternalMessagesIdVarianceSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByInternalMessagesIdVarianceSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByInternalMessagesIdVarianceSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByInternalMessagesIdVarianceSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByInternalMessagesIdVarianceSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByInternalMessagesIdVarianceSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByInternalMessagesIdVarianceSampleHumanReadableIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsByInternalMessagesIdVarianceSampleHumanReadableIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsByInternalMessagesIdVarianceSampleIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_ID_ASC',
  TrialsByInternalMessagesIdVarianceSampleIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_ID_DESC',
  TrialsByInternalMessagesIdVarianceSampleInternalMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdVarianceSampleInternalMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdVarianceSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdVarianceSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByInternalMessagesIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByInternalMessagesIdVarianceSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByInternalMessagesIdVarianceSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByInternalMessagesIdVarianceSampleMessagesIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_MESSAGES_ID_ASC',
  TrialsByInternalMessagesIdVarianceSampleMessagesIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_MESSAGES_ID_DESC',
  TrialsByInternalMessagesIdVarianceSampleNameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_NAME_ASC',
  TrialsByInternalMessagesIdVarianceSampleNameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_NAME_DESC',
  TrialsByInternalMessagesIdVarianceSampleStaffIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_STAFF_ID_ASC',
  TrialsByInternalMessagesIdVarianceSampleStaffIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_STAFF_ID_DESC',
  TrialsByInternalMessagesIdVarianceSampleSubjectsNicknameAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsByInternalMessagesIdVarianceSampleSubjectsNicknameDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsByInternalMessagesIdVarianceSampleSubjectIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_SUBJECT_ID_ASC',
  TrialsByInternalMessagesIdVarianceSampleSubjectIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_SUBJECT_ID_DESC',
  TrialsByInternalMessagesIdVarianceSampleTenantIdAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  TrialsByInternalMessagesIdVarianceSampleTenantIdDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  TrialsByInternalMessagesIdVarianceSampleUpdatedAtAsc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TrialsByInternalMessagesIdVarianceSampleUpdatedAtDesc = 'TRIALS_BY_INTERNAL_MESSAGES_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TrialsByMessagesIdAverageAvatarAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_AVATAR_ASC',
  TrialsByMessagesIdAverageAvatarDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_AVATAR_DESC',
  TrialsByMessagesIdAverageCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdAverageCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdAverageCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdAverageCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdAverageCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdAverageCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdAverageCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdAverageCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdAverageCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CREATED_AT_ASC',
  TrialsByMessagesIdAverageCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CREATED_AT_DESC',
  TrialsByMessagesIdAverageCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CREATED_BY_ID_ASC',
  TrialsByMessagesIdAverageCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_CREATED_BY_ID_DESC',
  TrialsByMessagesIdAverageEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdAverageEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdAverageHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdAverageHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdAverageHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdAverageHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdAverageHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdAverageHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdAverageHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdAverageHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdAverageHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdAverageHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdAverageHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdAverageHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdAverageHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdAverageHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdAverageIdAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_ID_ASC',
  TrialsByMessagesIdAverageIdDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_ID_DESC',
  TrialsByMessagesIdAverageInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdAverageInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdAverageMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdAverageMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdAverageMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdAverageMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdAverageMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdAverageMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdAverageMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_MESSAGES_ID_ASC',
  TrialsByMessagesIdAverageMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_MESSAGES_ID_DESC',
  TrialsByMessagesIdAverageNameAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_NAME_ASC',
  TrialsByMessagesIdAverageNameDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_NAME_DESC',
  TrialsByMessagesIdAverageStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_STAFF_ID_ASC',
  TrialsByMessagesIdAverageStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_STAFF_ID_DESC',
  TrialsByMessagesIdAverageSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdAverageSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdAverageSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_SUBJECT_ID_ASC',
  TrialsByMessagesIdAverageSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_SUBJECT_ID_DESC',
  TrialsByMessagesIdAverageTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_TENANT_ID_ASC',
  TrialsByMessagesIdAverageTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_TENANT_ID_DESC',
  TrialsByMessagesIdAverageUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_UPDATED_AT_ASC',
  TrialsByMessagesIdAverageUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_AVERAGE_UPDATED_AT_DESC',
  TrialsByMessagesIdCountAsc = 'TRIALS_BY_MESSAGES_ID_COUNT_ASC',
  TrialsByMessagesIdCountDesc = 'TRIALS_BY_MESSAGES_ID_COUNT_DESC',
  TrialsByMessagesIdDistinctCountAvatarAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_AVATAR_ASC',
  TrialsByMessagesIdDistinctCountAvatarDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_AVATAR_DESC',
  TrialsByMessagesIdDistinctCountCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdDistinctCountCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdDistinctCountCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdDistinctCountCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdDistinctCountCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdDistinctCountCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdDistinctCountCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdDistinctCountCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdDistinctCountCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  TrialsByMessagesIdDistinctCountCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  TrialsByMessagesIdDistinctCountCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CREATED_BY_ID_ASC',
  TrialsByMessagesIdDistinctCountCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_CREATED_BY_ID_DESC',
  TrialsByMessagesIdDistinctCountEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdDistinctCountEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdDistinctCountHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdDistinctCountHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdDistinctCountHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdDistinctCountHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdDistinctCountHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdDistinctCountHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdDistinctCountHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdDistinctCountHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdDistinctCountHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdDistinctCountHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdDistinctCountHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdDistinctCountHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdDistinctCountHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdDistinctCountHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdDistinctCountIdAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_ID_ASC',
  TrialsByMessagesIdDistinctCountIdDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_ID_DESC',
  TrialsByMessagesIdDistinctCountInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdDistinctCountInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdDistinctCountMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdDistinctCountMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdDistinctCountMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdDistinctCountMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdDistinctCountMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_MESSAGES_ID_ASC',
  TrialsByMessagesIdDistinctCountMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_MESSAGES_ID_DESC',
  TrialsByMessagesIdDistinctCountNameAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_NAME_ASC',
  TrialsByMessagesIdDistinctCountNameDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_NAME_DESC',
  TrialsByMessagesIdDistinctCountStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_STAFF_ID_ASC',
  TrialsByMessagesIdDistinctCountStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_STAFF_ID_DESC',
  TrialsByMessagesIdDistinctCountSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdDistinctCountSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdDistinctCountSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_SUBJECT_ID_ASC',
  TrialsByMessagesIdDistinctCountSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_SUBJECT_ID_DESC',
  TrialsByMessagesIdDistinctCountTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  TrialsByMessagesIdDistinctCountTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  TrialsByMessagesIdDistinctCountUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  TrialsByMessagesIdDistinctCountUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  TrialsByMessagesIdMaxAvatarAsc = 'TRIALS_BY_MESSAGES_ID_MAX_AVATAR_ASC',
  TrialsByMessagesIdMaxAvatarDesc = 'TRIALS_BY_MESSAGES_ID_MAX_AVATAR_DESC',
  TrialsByMessagesIdMaxCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_MAX_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdMaxCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_MAX_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdMaxCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_MAX_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdMaxCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_MAX_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdMaxCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_MAX_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdMaxCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_MAX_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdMaxCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_MAX_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdMaxCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_MAX_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdMaxCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_MAX_CREATED_AT_ASC',
  TrialsByMessagesIdMaxCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_MAX_CREATED_AT_DESC',
  TrialsByMessagesIdMaxCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_MAX_CREATED_BY_ID_ASC',
  TrialsByMessagesIdMaxCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_MAX_CREATED_BY_ID_DESC',
  TrialsByMessagesIdMaxEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_MAX_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdMaxEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_MAX_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdMaxHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdMaxHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdMaxHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdMaxHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdMaxHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdMaxHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdMaxHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdMaxHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdMaxHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdMaxHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdMaxHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdMaxHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdMaxHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_MAX_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdMaxHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_MAX_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdMaxIdAsc = 'TRIALS_BY_MESSAGES_ID_MAX_ID_ASC',
  TrialsByMessagesIdMaxIdDesc = 'TRIALS_BY_MESSAGES_ID_MAX_ID_DESC',
  TrialsByMessagesIdMaxInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_MAX_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdMaxInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_MAX_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdMaxMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdMaxMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdMaxMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdMaxMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdMaxMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdMaxMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdMaxMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_MAX_MESSAGES_ID_ASC',
  TrialsByMessagesIdMaxMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_MAX_MESSAGES_ID_DESC',
  TrialsByMessagesIdMaxNameAsc = 'TRIALS_BY_MESSAGES_ID_MAX_NAME_ASC',
  TrialsByMessagesIdMaxNameDesc = 'TRIALS_BY_MESSAGES_ID_MAX_NAME_DESC',
  TrialsByMessagesIdMaxStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_MAX_STAFF_ID_ASC',
  TrialsByMessagesIdMaxStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_MAX_STAFF_ID_DESC',
  TrialsByMessagesIdMaxSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_MAX_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdMaxSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_MAX_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdMaxSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_MAX_SUBJECT_ID_ASC',
  TrialsByMessagesIdMaxSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_MAX_SUBJECT_ID_DESC',
  TrialsByMessagesIdMaxTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_MAX_TENANT_ID_ASC',
  TrialsByMessagesIdMaxTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_MAX_TENANT_ID_DESC',
  TrialsByMessagesIdMaxUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_MAX_UPDATED_AT_ASC',
  TrialsByMessagesIdMaxUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_MAX_UPDATED_AT_DESC',
  TrialsByMessagesIdMinAvatarAsc = 'TRIALS_BY_MESSAGES_ID_MIN_AVATAR_ASC',
  TrialsByMessagesIdMinAvatarDesc = 'TRIALS_BY_MESSAGES_ID_MIN_AVATAR_DESC',
  TrialsByMessagesIdMinCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_MIN_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdMinCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_MIN_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdMinCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_MIN_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdMinCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_MIN_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdMinCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_MIN_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdMinCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_MIN_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdMinCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_MIN_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdMinCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_MIN_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdMinCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_MIN_CREATED_AT_ASC',
  TrialsByMessagesIdMinCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_MIN_CREATED_AT_DESC',
  TrialsByMessagesIdMinCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_MIN_CREATED_BY_ID_ASC',
  TrialsByMessagesIdMinCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_MIN_CREATED_BY_ID_DESC',
  TrialsByMessagesIdMinEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_MIN_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdMinEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_MIN_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdMinHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdMinHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdMinHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdMinHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdMinHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdMinHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdMinHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdMinHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdMinHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdMinHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdMinHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdMinHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdMinHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_MIN_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdMinHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_MIN_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdMinIdAsc = 'TRIALS_BY_MESSAGES_ID_MIN_ID_ASC',
  TrialsByMessagesIdMinIdDesc = 'TRIALS_BY_MESSAGES_ID_MIN_ID_DESC',
  TrialsByMessagesIdMinInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_MIN_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdMinInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_MIN_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdMinMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdMinMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdMinMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdMinMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdMinMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdMinMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdMinMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_MIN_MESSAGES_ID_ASC',
  TrialsByMessagesIdMinMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_MIN_MESSAGES_ID_DESC',
  TrialsByMessagesIdMinNameAsc = 'TRIALS_BY_MESSAGES_ID_MIN_NAME_ASC',
  TrialsByMessagesIdMinNameDesc = 'TRIALS_BY_MESSAGES_ID_MIN_NAME_DESC',
  TrialsByMessagesIdMinStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_MIN_STAFF_ID_ASC',
  TrialsByMessagesIdMinStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_MIN_STAFF_ID_DESC',
  TrialsByMessagesIdMinSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_MIN_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdMinSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_MIN_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdMinSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_MIN_SUBJECT_ID_ASC',
  TrialsByMessagesIdMinSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_MIN_SUBJECT_ID_DESC',
  TrialsByMessagesIdMinTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_MIN_TENANT_ID_ASC',
  TrialsByMessagesIdMinTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_MIN_TENANT_ID_DESC',
  TrialsByMessagesIdMinUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_MIN_UPDATED_AT_ASC',
  TrialsByMessagesIdMinUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_MIN_UPDATED_AT_DESC',
  TrialsByMessagesIdStddevPopulationAvatarAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_AVATAR_ASC',
  TrialsByMessagesIdStddevPopulationAvatarDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_AVATAR_DESC',
  TrialsByMessagesIdStddevPopulationCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdStddevPopulationCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdStddevPopulationCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdStddevPopulationCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdStddevPopulationCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdStddevPopulationCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdStddevPopulationCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdStddevPopulationCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdStddevPopulationCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  TrialsByMessagesIdStddevPopulationCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  TrialsByMessagesIdStddevPopulationCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CREATED_BY_ID_ASC',
  TrialsByMessagesIdStddevPopulationCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_CREATED_BY_ID_DESC',
  TrialsByMessagesIdStddevPopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdStddevPopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdStddevPopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdStddevPopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdStddevPopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdStddevPopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdStddevPopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdStddevPopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdStddevPopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdStddevPopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdStddevPopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdStddevPopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdStddevPopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdStddevPopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdStddevPopulationHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdStddevPopulationHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdStddevPopulationIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_ID_ASC',
  TrialsByMessagesIdStddevPopulationIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_ID_DESC',
  TrialsByMessagesIdStddevPopulationInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdStddevPopulationInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdStddevPopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdStddevPopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdStddevPopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdStddevPopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdStddevPopulationMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_MESSAGES_ID_ASC',
  TrialsByMessagesIdStddevPopulationMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_MESSAGES_ID_DESC',
  TrialsByMessagesIdStddevPopulationNameAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_NAME_ASC',
  TrialsByMessagesIdStddevPopulationNameDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_NAME_DESC',
  TrialsByMessagesIdStddevPopulationStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_STAFF_ID_ASC',
  TrialsByMessagesIdStddevPopulationStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_STAFF_ID_DESC',
  TrialsByMessagesIdStddevPopulationSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdStddevPopulationSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdStddevPopulationSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_SUBJECT_ID_ASC',
  TrialsByMessagesIdStddevPopulationSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_SUBJECT_ID_DESC',
  TrialsByMessagesIdStddevPopulationTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  TrialsByMessagesIdStddevPopulationTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  TrialsByMessagesIdStddevPopulationUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  TrialsByMessagesIdStddevPopulationUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  TrialsByMessagesIdStddevSampleAvatarAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_AVATAR_ASC',
  TrialsByMessagesIdStddevSampleAvatarDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_AVATAR_DESC',
  TrialsByMessagesIdStddevSampleCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdStddevSampleCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdStddevSampleCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdStddevSampleCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdStddevSampleCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdStddevSampleCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdStddevSampleCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdStddevSampleCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdStddevSampleCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  TrialsByMessagesIdStddevSampleCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  TrialsByMessagesIdStddevSampleCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CREATED_BY_ID_ASC',
  TrialsByMessagesIdStddevSampleCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_CREATED_BY_ID_DESC',
  TrialsByMessagesIdStddevSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdStddevSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdStddevSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdStddevSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdStddevSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdStddevSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdStddevSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdStddevSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdStddevSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdStddevSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdStddevSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdStddevSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdStddevSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdStddevSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdStddevSampleHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdStddevSampleHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdStddevSampleIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_ID_ASC',
  TrialsByMessagesIdStddevSampleIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_ID_DESC',
  TrialsByMessagesIdStddevSampleInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdStddevSampleInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdStddevSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdStddevSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdStddevSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdStddevSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdStddevSampleMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_MESSAGES_ID_ASC',
  TrialsByMessagesIdStddevSampleMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_MESSAGES_ID_DESC',
  TrialsByMessagesIdStddevSampleNameAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_NAME_ASC',
  TrialsByMessagesIdStddevSampleNameDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_NAME_DESC',
  TrialsByMessagesIdStddevSampleStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_STAFF_ID_ASC',
  TrialsByMessagesIdStddevSampleStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_STAFF_ID_DESC',
  TrialsByMessagesIdStddevSampleSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdStddevSampleSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdStddevSampleSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_SUBJECT_ID_ASC',
  TrialsByMessagesIdStddevSampleSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_SUBJECT_ID_DESC',
  TrialsByMessagesIdStddevSampleTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  TrialsByMessagesIdStddevSampleTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  TrialsByMessagesIdStddevSampleUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TrialsByMessagesIdStddevSampleUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TrialsByMessagesIdSumAvatarAsc = 'TRIALS_BY_MESSAGES_ID_SUM_AVATAR_ASC',
  TrialsByMessagesIdSumAvatarDesc = 'TRIALS_BY_MESSAGES_ID_SUM_AVATAR_DESC',
  TrialsByMessagesIdSumCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_SUM_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdSumCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_SUM_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdSumCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_SUM_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdSumCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_SUM_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdSumCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_SUM_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdSumCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_SUM_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdSumCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_SUM_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdSumCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_SUM_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdSumCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_SUM_CREATED_AT_ASC',
  TrialsByMessagesIdSumCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_SUM_CREATED_AT_DESC',
  TrialsByMessagesIdSumCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_SUM_CREATED_BY_ID_ASC',
  TrialsByMessagesIdSumCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_SUM_CREATED_BY_ID_DESC',
  TrialsByMessagesIdSumEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_SUM_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdSumEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_SUM_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdSumHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdSumHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdSumHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdSumHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdSumHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdSumHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdSumHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdSumHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdSumHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdSumHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdSumHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdSumHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdSumHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_SUM_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdSumHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_SUM_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdSumIdAsc = 'TRIALS_BY_MESSAGES_ID_SUM_ID_ASC',
  TrialsByMessagesIdSumIdDesc = 'TRIALS_BY_MESSAGES_ID_SUM_ID_DESC',
  TrialsByMessagesIdSumInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_SUM_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdSumInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_SUM_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdSumMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdSumMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdSumMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdSumMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdSumMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdSumMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdSumMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_SUM_MESSAGES_ID_ASC',
  TrialsByMessagesIdSumMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_SUM_MESSAGES_ID_DESC',
  TrialsByMessagesIdSumNameAsc = 'TRIALS_BY_MESSAGES_ID_SUM_NAME_ASC',
  TrialsByMessagesIdSumNameDesc = 'TRIALS_BY_MESSAGES_ID_SUM_NAME_DESC',
  TrialsByMessagesIdSumStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_SUM_STAFF_ID_ASC',
  TrialsByMessagesIdSumStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_SUM_STAFF_ID_DESC',
  TrialsByMessagesIdSumSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_SUM_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdSumSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_SUM_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdSumSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_SUM_SUBJECT_ID_ASC',
  TrialsByMessagesIdSumSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_SUM_SUBJECT_ID_DESC',
  TrialsByMessagesIdSumTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_SUM_TENANT_ID_ASC',
  TrialsByMessagesIdSumTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_SUM_TENANT_ID_DESC',
  TrialsByMessagesIdSumUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_SUM_UPDATED_AT_ASC',
  TrialsByMessagesIdSumUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_SUM_UPDATED_AT_DESC',
  TrialsByMessagesIdVariancePopulationAvatarAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_AVATAR_ASC',
  TrialsByMessagesIdVariancePopulationAvatarDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_AVATAR_DESC',
  TrialsByMessagesIdVariancePopulationCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdVariancePopulationCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdVariancePopulationCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdVariancePopulationCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdVariancePopulationCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdVariancePopulationCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdVariancePopulationCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdVariancePopulationCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdVariancePopulationCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  TrialsByMessagesIdVariancePopulationCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  TrialsByMessagesIdVariancePopulationCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CREATED_BY_ID_ASC',
  TrialsByMessagesIdVariancePopulationCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_CREATED_BY_ID_DESC',
  TrialsByMessagesIdVariancePopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdVariancePopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdVariancePopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdVariancePopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdVariancePopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdVariancePopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdVariancePopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdVariancePopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdVariancePopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdVariancePopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdVariancePopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdVariancePopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdVariancePopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdVariancePopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdVariancePopulationHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdVariancePopulationHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdVariancePopulationIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_ID_ASC',
  TrialsByMessagesIdVariancePopulationIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_ID_DESC',
  TrialsByMessagesIdVariancePopulationInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdVariancePopulationInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdVariancePopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdVariancePopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdVariancePopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdVariancePopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdVariancePopulationMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_MESSAGES_ID_ASC',
  TrialsByMessagesIdVariancePopulationMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_MESSAGES_ID_DESC',
  TrialsByMessagesIdVariancePopulationNameAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_NAME_ASC',
  TrialsByMessagesIdVariancePopulationNameDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_NAME_DESC',
  TrialsByMessagesIdVariancePopulationStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_STAFF_ID_ASC',
  TrialsByMessagesIdVariancePopulationStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_STAFF_ID_DESC',
  TrialsByMessagesIdVariancePopulationSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdVariancePopulationSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdVariancePopulationSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_SUBJECT_ID_ASC',
  TrialsByMessagesIdVariancePopulationSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_SUBJECT_ID_DESC',
  TrialsByMessagesIdVariancePopulationTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  TrialsByMessagesIdVariancePopulationTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  TrialsByMessagesIdVariancePopulationUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TrialsByMessagesIdVariancePopulationUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TrialsByMessagesIdVarianceSampleAvatarAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_AVATAR_ASC',
  TrialsByMessagesIdVarianceSampleAvatarDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_AVATAR_DESC',
  TrialsByMessagesIdVarianceSampleCannotUseTheInterventionAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByMessagesIdVarianceSampleCannotUseTheInterventionDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByMessagesIdVarianceSampleCanAccessErSinceAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByMessagesIdVarianceSampleCanAccessErSinceDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByMessagesIdVarianceSampleCanAccessSipSinceAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByMessagesIdVarianceSampleCanAccessSipSinceDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByMessagesIdVarianceSampleCanBeIncludedAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsByMessagesIdVarianceSampleCanBeIncludedDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsByMessagesIdVarianceSampleCreatedAtAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TrialsByMessagesIdVarianceSampleCreatedAtDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TrialsByMessagesIdVarianceSampleCreatedByIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CREATED_BY_ID_ASC',
  TrialsByMessagesIdVarianceSampleCreatedByIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_CREATED_BY_ID_DESC',
  TrialsByMessagesIdVarianceSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByMessagesIdVarianceSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByMessagesIdVarianceSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByMessagesIdVarianceSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByMessagesIdVarianceSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByMessagesIdVarianceSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByMessagesIdVarianceSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByMessagesIdVarianceSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByMessagesIdVarianceSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByMessagesIdVarianceSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByMessagesIdVarianceSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByMessagesIdVarianceSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByMessagesIdVarianceSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByMessagesIdVarianceSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByMessagesIdVarianceSampleHumanReadableIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsByMessagesIdVarianceSampleHumanReadableIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsByMessagesIdVarianceSampleIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_ID_ASC',
  TrialsByMessagesIdVarianceSampleIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_ID_DESC',
  TrialsByMessagesIdVarianceSampleInternalMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByMessagesIdVarianceSampleInternalMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByMessagesIdVarianceSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdVarianceSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByMessagesIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByMessagesIdVarianceSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByMessagesIdVarianceSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByMessagesIdVarianceSampleMessagesIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_MESSAGES_ID_ASC',
  TrialsByMessagesIdVarianceSampleMessagesIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_MESSAGES_ID_DESC',
  TrialsByMessagesIdVarianceSampleNameAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_NAME_ASC',
  TrialsByMessagesIdVarianceSampleNameDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_NAME_DESC',
  TrialsByMessagesIdVarianceSampleStaffIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_STAFF_ID_ASC',
  TrialsByMessagesIdVarianceSampleStaffIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_STAFF_ID_DESC',
  TrialsByMessagesIdVarianceSampleSubjectsNicknameAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsByMessagesIdVarianceSampleSubjectsNicknameDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsByMessagesIdVarianceSampleSubjectIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_SUBJECT_ID_ASC',
  TrialsByMessagesIdVarianceSampleSubjectIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_SUBJECT_ID_DESC',
  TrialsByMessagesIdVarianceSampleTenantIdAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  TrialsByMessagesIdVarianceSampleTenantIdDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  TrialsByMessagesIdVarianceSampleUpdatedAtAsc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TrialsByMessagesIdVarianceSampleUpdatedAtDesc = 'TRIALS_BY_MESSAGES_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** All input for the create `Commentable` mutation. */
export type CreateCommentableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Commentable` to be created by this mutation. */
  commentable: CommentableInput;
};

/** The output of our create `Commentable` mutation. */
export type CreateCommentablePayload = {
  __typename?: 'CreateCommentablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Commentable` that was created by this mutation. */
  commentable?: Maybe<Commentable>;
  /** An edge for our `Commentable`. May be used by Relay 1. */
  commentableEdge?: Maybe<CommentablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `Commentable` mutation. */
export type CreateCommentablePayloadCommentableEdgeArgs = {
  orderBy?: InputMaybe<Array<CommentablesOrderBy>>;
};

/** All input for the create `Conversation` mutation. */
export type CreateConversationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Conversation` to be created by this mutation. */
  conversation: ConversationInput;
};

/** The output of our create `Conversation` mutation. */
export type CreateConversationPayload = {
  __typename?: 'CreateConversationPayload';
  /** Reads a single `Commentable` that is related to this `Conversation`. */
  aboutCommentable?: Maybe<Commentable>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Conversation` that was created by this mutation. */
  conversation?: Maybe<Conversation>;
  /** An edge for our `Conversation`. May be used by Relay 1. */
  conversationEdge?: Maybe<ConversationsEdge>;
  /** Reads a single `User` that is related to this `Conversation`. */
  creator?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Conversation`. */
  privateGroup?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Conversation`. */
  tenant?: Maybe<Tenant>;
};


/** The output of our create `Conversation` mutation. */
export type CreateConversationPayloadConversationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};

/** All input for the create `EmaEntries2` mutation. */
export type CreateEmaEntries2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EmaEntries2` to be created by this mutation. */
  emaEntries2: EmaEntries2Input;
};

/** The output of our create `EmaEntries2` mutation. */
export type CreateEmaEntries2Payload = {
  __typename?: 'CreateEmaEntries2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntries2` that was created by this mutation. */
  emaEntries2?: Maybe<EmaEntries2>;
  /** An edge for our `EmaEntries2`. May be used by Relay 1. */
  emaEntries2Edge?: Maybe<EmaEntries2sEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntries2`. */
  subject?: Maybe<User>;
};


/** The output of our create `EmaEntries2` mutation. */
export type CreateEmaEntries2PayloadEmaEntries2EdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntries2sOrderBy>>;
};

/** All input for the create `EmaEntries3` mutation. */
export type CreateEmaEntries3Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EmaEntries3` to be created by this mutation. */
  emaEntries3: EmaEntries3Input;
};

/** The output of our create `EmaEntries3` mutation. */
export type CreateEmaEntries3Payload = {
  __typename?: 'CreateEmaEntries3Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntries3` that was created by this mutation. */
  emaEntries3?: Maybe<EmaEntries3>;
  /** An edge for our `EmaEntries3`. May be used by Relay 1. */
  emaEntries3Edge?: Maybe<EmaEntries3sEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `EmaEntries3`. */
  trial?: Maybe<Trial>;
};


/** The output of our create `EmaEntries3` mutation. */
export type CreateEmaEntries3PayloadEmaEntries3EdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntries3sOrderBy>>;
};

/** All input for the create `EmaEntry` mutation. */
export type CreateEmaEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EmaEntry` to be created by this mutation. */
  emaEntry: EmaEntryInput;
};

/** The output of our create `EmaEntry` mutation. */
export type CreateEmaEntryPayload = {
  __typename?: 'CreateEmaEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntry` that was created by this mutation. */
  emaEntry?: Maybe<EmaEntry>;
  /** An edge for our `EmaEntry`. May be used by Relay 1. */
  emaEntryEdge?: Maybe<EmaEntriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntry`. */
  subject?: Maybe<User>;
};


/** The output of our create `EmaEntry` mutation. */
export type CreateEmaEntryPayloadEmaEntryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntriesOrderBy>>;
};

/** All input for the create `Event` mutation. */
export type CreateEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Event` to be created by this mutation. */
  event: EventInput;
};

/** The output of our create `Event` mutation. */
export type CreateEventPayload = {
  __typename?: 'CreateEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was created by this mutation. */
  event?: Maybe<Event>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `FavoritePage` mutation. */
export type CreateFavoritePageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FavoritePage` to be created by this mutation. */
  favoritePage: FavoritePageInput;
};

/** The output of our create `FavoritePage` mutation. */
export type CreateFavoritePagePayload = {
  __typename?: 'CreateFavoritePagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FavoritePage` that was created by this mutation. */
  favoritePage?: Maybe<FavoritePage>;
  /** An edge for our `FavoritePage`. May be used by Relay 1. */
  favoritePageEdge?: Maybe<FavoritePagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `FavoritePage`. */
  trial?: Maybe<Trial>;
};


/** The output of our create `FavoritePage` mutation. */
export type CreateFavoritePagePayloadFavoritePageEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritePagesOrderBy>>;
};

/** All input for the create `GenericEmiExercise` mutation. */
export type CreateGenericEmiExerciseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `GenericEmiExercise` to be created by this mutation. */
  genericEmiExercise: GenericEmiExerciseInput;
};

/** The output of our create `GenericEmiExercise` mutation. */
export type CreateGenericEmiExercisePayload = {
  __typename?: 'CreateGenericEmiExercisePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GenericEmiExercise` that was created by this mutation. */
  genericEmiExercise?: Maybe<GenericEmiExercise>;
  /** An edge for our `GenericEmiExercise`. May be used by Relay 1. */
  genericEmiExerciseEdge?: Maybe<GenericEmiExercisesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `GenericEmiExercise`. */
  trial?: Maybe<Trial>;
};


/** The output of our create `GenericEmiExercise` mutation. */
export type CreateGenericEmiExercisePayloadGenericEmiExerciseEdgeArgs = {
  orderBy?: InputMaybe<Array<GenericEmiExercisesOrderBy>>;
};

/** All input for the create `GroupHome` mutation. */
export type CreateGroupHomeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `GroupHome` to be created by this mutation. */
  groupHome: GroupHomeInput;
};

/** The output of our create `GroupHome` mutation. */
export type CreateGroupHomePayload = {
  __typename?: 'CreateGroupHomePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupHome` that was created by this mutation. */
  groupHome?: Maybe<GroupHome>;
  /** An edge for our `GroupHome`. May be used by Relay 1. */
  groupHomeEdge?: Maybe<GroupHomesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `GroupHome` mutation. */
export type CreateGroupHomePayloadGroupHomeEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupHomesOrderBy>>;
};

/** All input for the create `Group` mutation. */
export type CreateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Group` to be created by this mutation. */
  group: GroupInput;
};

/** The output of our create `Group` mutation. */
export type CreateGroupPayload = {
  __typename?: 'CreateGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Group`. */
  creator?: Maybe<User>;
  /** The `Group` that was created by this mutation. */
  group?: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
  /** Reads a single `GroupHome` that is related to this `Group`. */
  home?: Maybe<GroupHome>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Group`. */
  tenant?: Maybe<Tenant>;
};


/** The output of our create `Group` mutation. */
export type CreateGroupPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the create `LimesurveyToken` mutation. */
export type CreateLimesurveyTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LimesurveyToken` to be created by this mutation. */
  limesurveyToken: LimesurveyTokenInput;
};

/** The output of our create `LimesurveyToken` mutation. */
export type CreateLimesurveyTokenPayload = {
  __typename?: 'CreateLimesurveyTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LimesurveyToken` that was created by this mutation. */
  limesurveyToken?: Maybe<LimesurveyToken>;
  /** An edge for our `LimesurveyToken`. May be used by Relay 1. */
  limesurveyTokenEdge?: Maybe<LimesurveyTokensEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `LimesurveyToken`. */
  trial?: Maybe<Trial>;
};


/** The output of our create `LimesurveyToken` mutation. */
export type CreateLimesurveyTokenPayloadLimesurveyTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<LimesurveyTokensOrderBy>>;
};

/** All input for the create `Membership` mutation. */
export type CreateMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Membership` to be created by this mutation. */
  membership: MembershipInput;
};

/** All input for the create `MembershipInvitationConfirmation` mutation. */
export type CreateMembershipInvitationConfirmationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MembershipInvitationConfirmation` to be created by this mutation. */
  membershipInvitationConfirmation: MembershipInvitationConfirmationInput;
};

/** The output of our create `MembershipInvitationConfirmation` mutation. */
export type CreateMembershipInvitationConfirmationPayload = {
  __typename?: 'CreateMembershipInvitationConfirmationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `MembershipInvitation` that is related to this `MembershipInvitationConfirmation`. */
  invitation?: Maybe<MembershipInvitation>;
  /** Reads a single `Membership` that is related to this `MembershipInvitationConfirmation`. */
  membership?: Maybe<Membership>;
  /** The `MembershipInvitationConfirmation` that was created by this mutation. */
  membershipInvitationConfirmation?: Maybe<MembershipInvitationConfirmation>;
  /** An edge for our `MembershipInvitationConfirmation`. May be used by Relay 1. */
  membershipInvitationConfirmationEdge?: Maybe<MembershipInvitationConfirmationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `MembershipInvitationConfirmation` mutation. */
export type CreateMembershipInvitationConfirmationPayloadMembershipInvitationConfirmationEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipInvitationConfirmationsOrderBy>>;
};

/** All input for the create `MembershipInvitation` mutation. */
export type CreateMembershipInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MembershipInvitation` to be created by this mutation. */
  membershipInvitation: MembershipInvitationInput;
};

/** The output of our create `MembershipInvitation` mutation. */
export type CreateMembershipInvitationPayload = {
  __typename?: 'CreateMembershipInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  group?: Maybe<Group>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  invitedGroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  invitedUser?: Maybe<User>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  inviter?: Maybe<User>;
  /** The `MembershipInvitation` that was created by this mutation. */
  membershipInvitation?: Maybe<MembershipInvitation>;
  /** An edge for our `MembershipInvitation`. May be used by Relay 1. */
  membershipInvitationEdge?: Maybe<MembershipInvitationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our create `MembershipInvitation` mutation. */
export type CreateMembershipInvitationPayloadMembershipInvitationEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};

/** The output of our create `Membership` mutation. */
export type CreateMembershipPayload = {
  __typename?: 'CreateMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Group` that is related to this `Membership`. */
  group?: Maybe<Group>;
  /** The `Membership` that was created by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Membership`. */
  subgroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our create `Membership` mutation. */
export type CreateMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the create `PostWaitRandomizationTicket` mutation. */
export type CreatePostWaitRandomizationTicketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PostWaitRandomizationTicket` to be created by this mutation. */
  postWaitRandomizationTicket: PostWaitRandomizationTicketInput;
};

/** The output of our create `PostWaitRandomizationTicket` mutation. */
export type CreatePostWaitRandomizationTicketPayload = {
  __typename?: 'CreatePostWaitRandomizationTicketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PostWaitRandomizationTicket` that was created by this mutation. */
  postWaitRandomizationTicket?: Maybe<PostWaitRandomizationTicket>;
  /** An edge for our `PostWaitRandomizationTicket`. May be used by Relay 1. */
  postWaitRandomizationTicketEdge?: Maybe<PostWaitRandomizationTicketsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `PostWaitRandomizationTicket`. */
  trial?: Maybe<Trial>;
};


/** The output of our create `PostWaitRandomizationTicket` mutation. */
export type CreatePostWaitRandomizationTicketPayloadPostWaitRandomizationTicketEdgeArgs = {
  orderBy?: InputMaybe<Array<PostWaitRandomizationTicketsOrderBy>>;
};

/** All input for the create `Posting` mutation. */
export type CreatePostingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Posting` to be created by this mutation. */
  posting: PostingInput;
};

/** The output of our create `Posting` mutation. */
export type CreatePostingPayload = {
  __typename?: 'CreatePostingPayload';
  /** Reads a single `Commentable` that is related to this `Posting`. */
  aboutCommentable?: Maybe<Commentable>;
  /** Reads a single `Posting` that is related to this `Posting`. */
  answeredPosting?: Maybe<Posting>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Conversation` that is related to this `Posting`. */
  conversation?: Maybe<Conversation>;
  /** The `Posting` that was created by this mutation. */
  posting?: Maybe<Posting>;
  /** An edge for our `Posting`. May be used by Relay 1. */
  postingEdge?: Maybe<PostingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Posting`. */
  sender?: Maybe<User>;
};


/** The output of our create `Posting` mutation. */
export type CreatePostingPayloadPostingEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};

/** All input for the create `PostingReceipt` mutation. */
export type CreatePostingReceiptInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PostingReceipt` to be created by this mutation. */
  postingReceipt: PostingReceiptInput;
};

/** The output of our create `PostingReceipt` mutation. */
export type CreatePostingReceiptPayload = {
  __typename?: 'CreatePostingReceiptPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Posting` that is related to this `PostingReceipt`. */
  posting?: Maybe<Posting>;
  /** The `PostingReceipt` that was created by this mutation. */
  postingReceipt?: Maybe<PostingReceipt>;
  /** An edge for our `PostingReceipt`. May be used by Relay 1. */
  postingReceiptEdge?: Maybe<PostingReceiptsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostingReceipt`. */
  user?: Maybe<User>;
};


/** The output of our create `PostingReceipt` mutation. */
export type CreatePostingReceiptPayloadPostingReceiptEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};

/** All input for the create `RandomizationTicket` mutation. */
export type CreateRandomizationTicketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RandomizationTicket` to be created by this mutation. */
  randomizationTicket: RandomizationTicketInput;
};

/** The output of our create `RandomizationTicket` mutation. */
export type CreateRandomizationTicketPayload = {
  __typename?: 'CreateRandomizationTicketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RandomizationTicket` that was created by this mutation. */
  randomizationTicket?: Maybe<RandomizationTicket>;
  /** An edge for our `RandomizationTicket`. May be used by Relay 1. */
  randomizationTicketEdge?: Maybe<RandomizationTicketsEdge>;
  /** Reads a single `Trial` that is related to this `RandomizationTicket`. */
  trial?: Maybe<Trial>;
};


/** The output of our create `RandomizationTicket` mutation. */
export type CreateRandomizationTicketPayloadRandomizationTicketEdgeArgs = {
  orderBy?: InputMaybe<Array<RandomizationTicketsOrderBy>>;
};

/** All input for the create `RawUsageDatum` mutation. */
export type CreateRawUsageDatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RawUsageDatum` to be created by this mutation. */
  rawUsageDatum: RawUsageDatumInput;
};

/** The output of our create `RawUsageDatum` mutation. */
export type CreateRawUsageDatumPayload = {
  __typename?: 'CreateRawUsageDatumPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RawUsageDatum` that was created by this mutation. */
  rawUsageDatum?: Maybe<RawUsageDatum>;
  /** An edge for our `RawUsageDatum`. May be used by Relay 1. */
  rawUsageDatumEdge?: Maybe<RawUsageDataEdge>;
  /** Reads a single `Trial` that is related to this `RawUsageDatum`. */
  trial?: Maybe<Trial>;
  /** Reads a single `User` that is related to this `RawUsageDatum`. */
  user?: Maybe<User>;
};


/** The output of our create `RawUsageDatum` mutation. */
export type CreateRawUsageDatumPayloadRawUsageDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<RawUsageDataOrderBy>>;
};

/** All input for the create `Tenant` mutation. */
export type CreateTenantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Tenant` to be created by this mutation. */
  tenant: TenantInput;
};

/** The output of our create `Tenant` mutation. */
export type CreateTenantPayload = {
  __typename?: 'CreateTenantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Tenant` that was created by this mutation. */
  tenant?: Maybe<Tenant>;
  /** An edge for our `Tenant`. May be used by Relay 1. */
  tenantEdge?: Maybe<TenantsEdge>;
};


/** The output of our create `Tenant` mutation. */
export type CreateTenantPayloadTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantsOrderBy>>;
};

/** All input for the create `Trial` mutation. */
export type CreateTrialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Trial` to be created by this mutation. */
  trial: TrialInput;
};

/** The output of our create `Trial` mutation. */
export type CreateTrialPayload = {
  __typename?: 'CreateTrialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Commentable` that is related to this `Trial`. */
  commentableById?: Maybe<Commentable>;
  /** Reads a single `User` that is related to this `Trial`. */
  createdBy?: Maybe<User>;
  /** Reads a single `GroupHome` that is related to this `Trial`. */
  groupHomeById?: Maybe<GroupHome>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  internalMessages?: Maybe<Conversation>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  messages?: Maybe<Conversation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Trial`. */
  staff?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Trial`. */
  subject?: Maybe<User>;
  /** Reads a single `Tenant` that is related to this `Trial`. */
  tenant?: Maybe<Tenant>;
  /** The `Trial` that was created by this mutation. */
  trial?: Maybe<Trial>;
  /** An edge for our `Trial`. May be used by Relay 1. */
  trialEdge?: Maybe<TrialsEdge>;
};


/** The output of our create `Trial` mutation. */
export type CreateTrialPayloadTrialEdgeArgs = {
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

/** All input for the create `UserCredential` mutation. */
export type CreateUserCredentialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserCredential` to be created by this mutation. */
  userCredential: UserCredentialInput;
};

/** The output of our create `UserCredential` mutation. */
export type CreateUserCredentialPayload = {
  __typename?: 'CreateUserCredentialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `UserCredential`. */
  tenant?: Maybe<Tenant>;
  /** The `UserCredential` that was created by this mutation. */
  userCredential?: Maybe<UserCredential>;
  /** An edge for our `UserCredential`. May be used by Relay 1. */
  userCredentialEdge?: Maybe<UserCredentialsEdge>;
};


/** The output of our create `UserCredential` mutation. */
export type CreateUserCredentialPayloadUserCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<UserCredentialsOrderBy>>;
};

/** All input for the create `UserEmailAddress` mutation. */
export type CreateUserEmailAddressInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserEmailAddress` to be created by this mutation. */
  userEmailAddress: UserEmailAddressInput;
};

/** The output of our create `UserEmailAddress` mutation. */
export type CreateUserEmailAddressPayload = {
  __typename?: 'CreateUserEmailAddressPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserEmailAddress` that was created by this mutation. */
  userEmailAddress?: Maybe<UserEmailAddress>;
  /** An edge for our `UserEmailAddress`. May be used by Relay 1. */
  userEmailAddressEdge?: Maybe<UserEmailAddressesEdge>;
};


/** The output of our create `UserEmailAddress` mutation. */
export type CreateUserEmailAddressPayloadUserEmailAddressEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmailAddressesOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `UserCredential` that is related to this `User`. */
  credentials?: Maybe<UserCredential>;
  /** Reads a single `UserEmailAddress` that is related to this `User`. */
  emailAddress?: Maybe<UserEmailAddress>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `User`. */
  tenant?: Maybe<Tenant>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** All input for the `deleteCommentableByNodeId` mutation. */
export type DeleteCommentableByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Commentable` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCommentable` mutation. */
export type DeleteCommentableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Commentable` mutation. */
export type DeleteCommentablePayload = {
  __typename?: 'DeleteCommentablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Commentable` that was deleted by this mutation. */
  commentable?: Maybe<Commentable>;
  /** An edge for our `Commentable`. May be used by Relay 1. */
  commentableEdge?: Maybe<CommentablesEdge>;
  deletedCommentableNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `Commentable` mutation. */
export type DeleteCommentablePayloadCommentableEdgeArgs = {
  orderBy?: InputMaybe<Array<CommentablesOrderBy>>;
};

/** All input for the `deleteConversationByNodeId` mutation. */
export type DeleteConversationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Conversation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteConversation` mutation. */
export type DeleteConversationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Conversation` mutation. */
export type DeleteConversationPayload = {
  __typename?: 'DeleteConversationPayload';
  /** Reads a single `Commentable` that is related to this `Conversation`. */
  aboutCommentable?: Maybe<Commentable>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Conversation` that was deleted by this mutation. */
  conversation?: Maybe<Conversation>;
  /** An edge for our `Conversation`. May be used by Relay 1. */
  conversationEdge?: Maybe<ConversationsEdge>;
  /** Reads a single `User` that is related to this `Conversation`. */
  creator?: Maybe<User>;
  deletedConversationNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Group` that is related to this `Conversation`. */
  privateGroup?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Conversation`. */
  tenant?: Maybe<Tenant>;
};


/** The output of our delete `Conversation` mutation. */
export type DeleteConversationPayloadConversationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};

/** All input for the `deleteEmaEntries2ByNodeId` mutation. */
export type DeleteEmaEntries2ByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmaEntries2` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEmaEntries2` mutation. */
export type DeleteEmaEntries2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `EmaEntries2` mutation. */
export type DeleteEmaEntries2Payload = {
  __typename?: 'DeleteEmaEntries2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEmaEntries2NodeId?: Maybe<Scalars['ID']>;
  /** The `EmaEntries2` that was deleted by this mutation. */
  emaEntries2?: Maybe<EmaEntries2>;
  /** An edge for our `EmaEntries2`. May be used by Relay 1. */
  emaEntries2Edge?: Maybe<EmaEntries2sEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntries2`. */
  subject?: Maybe<User>;
};


/** The output of our delete `EmaEntries2` mutation. */
export type DeleteEmaEntries2PayloadEmaEntries2EdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntries2sOrderBy>>;
};

/** All input for the `deleteEmaEntries3ByNodeId` mutation. */
export type DeleteEmaEntries3ByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmaEntries3` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEmaEntries3` mutation. */
export type DeleteEmaEntries3Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `EmaEntries3` mutation. */
export type DeleteEmaEntries3Payload = {
  __typename?: 'DeleteEmaEntries3Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEmaEntries3NodeId?: Maybe<Scalars['ID']>;
  /** The `EmaEntries3` that was deleted by this mutation. */
  emaEntries3?: Maybe<EmaEntries3>;
  /** An edge for our `EmaEntries3`. May be used by Relay 1. */
  emaEntries3Edge?: Maybe<EmaEntries3sEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `EmaEntries3`. */
  trial?: Maybe<Trial>;
};


/** The output of our delete `EmaEntries3` mutation. */
export type DeleteEmaEntries3PayloadEmaEntries3EdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntries3sOrderBy>>;
};

/** All input for the `deleteEmaEntryByNodeId` mutation. */
export type DeleteEmaEntryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmaEntry` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEmaEntry` mutation. */
export type DeleteEmaEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `EmaEntry` mutation. */
export type DeleteEmaEntryPayload = {
  __typename?: 'DeleteEmaEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedEmaEntryNodeId?: Maybe<Scalars['ID']>;
  /** The `EmaEntry` that was deleted by this mutation. */
  emaEntry?: Maybe<EmaEntry>;
  /** An edge for our `EmaEntry`. May be used by Relay 1. */
  emaEntryEdge?: Maybe<EmaEntriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntry`. */
  subject?: Maybe<User>;
};


/** The output of our delete `EmaEntry` mutation. */
export type DeleteEmaEntryPayloadEmaEntryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntriesOrderBy>>;
};

/** All input for the `deleteFavoritePageByNodeId` mutation. */
export type DeleteFavoritePageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FavoritePage` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFavoritePage` mutation. */
export type DeleteFavoritePageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  route: Scalars['String'];
  trialId: Scalars['UUID'];
};

/** The output of our delete `FavoritePage` mutation. */
export type DeleteFavoritePagePayload = {
  __typename?: 'DeleteFavoritePagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedFavoritePageNodeId?: Maybe<Scalars['ID']>;
  /** The `FavoritePage` that was deleted by this mutation. */
  favoritePage?: Maybe<FavoritePage>;
  /** An edge for our `FavoritePage`. May be used by Relay 1. */
  favoritePageEdge?: Maybe<FavoritePagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `FavoritePage`. */
  trial?: Maybe<Trial>;
};


/** The output of our delete `FavoritePage` mutation. */
export type DeleteFavoritePagePayloadFavoritePageEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritePagesOrderBy>>;
};

/** All input for the `deleteGenericEmiExerciseByNodeId` mutation. */
export type DeleteGenericEmiExerciseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GenericEmiExercise` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGenericEmiExerciseByTrialIdAndName` mutation. */
export type DeleteGenericEmiExerciseByTrialIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  trialId: Scalars['UUID'];
};

/** All input for the `deleteGenericEmiExercise` mutation. */
export type DeleteGenericEmiExerciseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GenericEmiExercise` mutation. */
export type DeleteGenericEmiExercisePayload = {
  __typename?: 'DeleteGenericEmiExercisePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedGenericEmiExerciseNodeId?: Maybe<Scalars['ID']>;
  /** The `GenericEmiExercise` that was deleted by this mutation. */
  genericEmiExercise?: Maybe<GenericEmiExercise>;
  /** An edge for our `GenericEmiExercise`. May be used by Relay 1. */
  genericEmiExerciseEdge?: Maybe<GenericEmiExercisesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `GenericEmiExercise`. */
  trial?: Maybe<Trial>;
};


/** The output of our delete `GenericEmiExercise` mutation. */
export type DeleteGenericEmiExercisePayloadGenericEmiExerciseEdgeArgs = {
  orderBy?: InputMaybe<Array<GenericEmiExercisesOrderBy>>;
};

/** All input for the `deleteGroupByNodeId` mutation. */
export type DeleteGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Group` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGroupHomeByNodeId` mutation. */
export type DeleteGroupHomeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GroupHome` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGroupHome` mutation. */
export type DeleteGroupHomeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `GroupHome` mutation. */
export type DeleteGroupHomePayload = {
  __typename?: 'DeleteGroupHomePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedGroupHomeNodeId?: Maybe<Scalars['ID']>;
  /** The `GroupHome` that was deleted by this mutation. */
  groupHome?: Maybe<GroupHome>;
  /** An edge for our `GroupHome`. May be used by Relay 1. */
  groupHomeEdge?: Maybe<GroupHomesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `GroupHome` mutation. */
export type DeleteGroupHomePayloadGroupHomeEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupHomesOrderBy>>;
};

/** All input for the `deleteGroup` mutation. */
export type DeleteGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Group` mutation. */
export type DeleteGroupPayload = {
  __typename?: 'DeleteGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Group`. */
  creator?: Maybe<User>;
  deletedGroupNodeId?: Maybe<Scalars['ID']>;
  /** The `Group` that was deleted by this mutation. */
  group?: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
  /** Reads a single `GroupHome` that is related to this `Group`. */
  home?: Maybe<GroupHome>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Group`. */
  tenant?: Maybe<Tenant>;
};


/** The output of our delete `Group` mutation. */
export type DeleteGroupPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `deleteLimesurveyTokenByNodeId` mutation. */
export type DeleteLimesurveyTokenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LimesurveyToken` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLimesurveyTokenByTrialIdAndTimeOfMeasurement` mutation. */
export type DeleteLimesurveyTokenByTrialIdAndTimeOfMeasurementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  timeOfMeasurement: Scalars['String'];
  trialId: Scalars['UUID'];
};

/** All input for the `deleteLimesurveyToken` mutation. */
export type DeleteLimesurveyTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  surveyId: Scalars['Int'];
  token: Scalars['String'];
};

/** The output of our delete `LimesurveyToken` mutation. */
export type DeleteLimesurveyTokenPayload = {
  __typename?: 'DeleteLimesurveyTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedLimesurveyTokenNodeId?: Maybe<Scalars['ID']>;
  /** The `LimesurveyToken` that was deleted by this mutation. */
  limesurveyToken?: Maybe<LimesurveyToken>;
  /** An edge for our `LimesurveyToken`. May be used by Relay 1. */
  limesurveyTokenEdge?: Maybe<LimesurveyTokensEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `LimesurveyToken`. */
  trial?: Maybe<Trial>;
};


/** The output of our delete `LimesurveyToken` mutation. */
export type DeleteLimesurveyTokenPayloadLimesurveyTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<LimesurveyTokensOrderBy>>;
};

/** All input for the `deleteMembershipByGroupIdAndSubgroupId` mutation. */
export type DeleteMembershipByGroupIdAndSubgroupIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['UUID'];
  subgroupId: Scalars['UUID'];
};

/** All input for the `deleteMembershipByGroupIdAndUserId` mutation. */
export type DeleteMembershipByGroupIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** All input for the `deleteMembershipByNodeId` mutation. */
export type DeleteMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Membership` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMembership` mutation. */
export type DeleteMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteMembershipInvitationByNodeId` mutation. */
export type DeleteMembershipInvitationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MembershipInvitation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMembershipInvitationConfirmationByNodeId` mutation. */
export type DeleteMembershipInvitationConfirmationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MembershipInvitationConfirmation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMembershipInvitationConfirmation` mutation. */
export type DeleteMembershipInvitationConfirmationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `MembershipInvitationConfirmation` mutation. */
export type DeleteMembershipInvitationConfirmationPayload = {
  __typename?: 'DeleteMembershipInvitationConfirmationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMembershipInvitationConfirmationNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `MembershipInvitation` that is related to this `MembershipInvitationConfirmation`. */
  invitation?: Maybe<MembershipInvitation>;
  /** Reads a single `Membership` that is related to this `MembershipInvitationConfirmation`. */
  membership?: Maybe<Membership>;
  /** The `MembershipInvitationConfirmation` that was deleted by this mutation. */
  membershipInvitationConfirmation?: Maybe<MembershipInvitationConfirmation>;
  /** An edge for our `MembershipInvitationConfirmation`. May be used by Relay 1. */
  membershipInvitationConfirmationEdge?: Maybe<MembershipInvitationConfirmationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `MembershipInvitationConfirmation` mutation. */
export type DeleteMembershipInvitationConfirmationPayloadMembershipInvitationConfirmationEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipInvitationConfirmationsOrderBy>>;
};

/** All input for the `deleteMembershipInvitation` mutation. */
export type DeleteMembershipInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `MembershipInvitation` mutation. */
export type DeleteMembershipInvitationPayload = {
  __typename?: 'DeleteMembershipInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMembershipInvitationNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  group?: Maybe<Group>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  invitedGroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  invitedUser?: Maybe<User>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  inviter?: Maybe<User>;
  /** The `MembershipInvitation` that was deleted by this mutation. */
  membershipInvitation?: Maybe<MembershipInvitation>;
  /** An edge for our `MembershipInvitation`. May be used by Relay 1. */
  membershipInvitationEdge?: Maybe<MembershipInvitationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our delete `MembershipInvitation` mutation. */
export type DeleteMembershipInvitationPayloadMembershipInvitationEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};

/** The output of our delete `Membership` mutation. */
export type DeleteMembershipPayload = {
  __typename?: 'DeleteMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedMembershipNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Group` that is related to this `Membership`. */
  group?: Maybe<Group>;
  /** The `Membership` that was deleted by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Membership`. */
  subgroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our delete `Membership` mutation. */
export type DeleteMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the `deletePostWaitRandomizationTicketByNodeId` mutation. */
export type DeletePostWaitRandomizationTicketByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PostWaitRandomizationTicket` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePostWaitRandomizationTicketByTrialId` mutation. */
export type DeletePostWaitRandomizationTicketByTrialIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  trialId: Scalars['UUID'];
};

/** All input for the `deletePostWaitRandomizationTicket` mutation. */
export type DeletePostWaitRandomizationTicketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `PostWaitRandomizationTicket` mutation. */
export type DeletePostWaitRandomizationTicketPayload = {
  __typename?: 'DeletePostWaitRandomizationTicketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPostWaitRandomizationTicketNodeId?: Maybe<Scalars['ID']>;
  /** The `PostWaitRandomizationTicket` that was deleted by this mutation. */
  postWaitRandomizationTicket?: Maybe<PostWaitRandomizationTicket>;
  /** An edge for our `PostWaitRandomizationTicket`. May be used by Relay 1. */
  postWaitRandomizationTicketEdge?: Maybe<PostWaitRandomizationTicketsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `PostWaitRandomizationTicket`. */
  trial?: Maybe<Trial>;
};


/** The output of our delete `PostWaitRandomizationTicket` mutation. */
export type DeletePostWaitRandomizationTicketPayloadPostWaitRandomizationTicketEdgeArgs = {
  orderBy?: InputMaybe<Array<PostWaitRandomizationTicketsOrderBy>>;
};

/** All input for the `deletePostingByNodeId` mutation. */
export type DeletePostingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Posting` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePosting` mutation. */
export type DeletePostingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Posting` mutation. */
export type DeletePostingPayload = {
  __typename?: 'DeletePostingPayload';
  /** Reads a single `Commentable` that is related to this `Posting`. */
  aboutCommentable?: Maybe<Commentable>;
  /** Reads a single `Posting` that is related to this `Posting`. */
  answeredPosting?: Maybe<Posting>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Conversation` that is related to this `Posting`. */
  conversation?: Maybe<Conversation>;
  deletedPostingNodeId?: Maybe<Scalars['ID']>;
  /** The `Posting` that was deleted by this mutation. */
  posting?: Maybe<Posting>;
  /** An edge for our `Posting`. May be used by Relay 1. */
  postingEdge?: Maybe<PostingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Posting`. */
  sender?: Maybe<User>;
};


/** The output of our delete `Posting` mutation. */
export type DeletePostingPayloadPostingEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};

/** All input for the `deletePostingReceiptByNodeId` mutation. */
export type DeletePostingReceiptByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PostingReceipt` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePostingReceipt` mutation. */
export type DeletePostingReceiptInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  postingId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The output of our delete `PostingReceipt` mutation. */
export type DeletePostingReceiptPayload = {
  __typename?: 'DeletePostingReceiptPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPostingReceiptNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Posting` that is related to this `PostingReceipt`. */
  posting?: Maybe<Posting>;
  /** The `PostingReceipt` that was deleted by this mutation. */
  postingReceipt?: Maybe<PostingReceipt>;
  /** An edge for our `PostingReceipt`. May be used by Relay 1. */
  postingReceiptEdge?: Maybe<PostingReceiptsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostingReceipt`. */
  user?: Maybe<User>;
};


/** The output of our delete `PostingReceipt` mutation. */
export type DeletePostingReceiptPayloadPostingReceiptEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};

/** All input for the `deleteRandomizationTicketByNodeId` mutation. */
export type DeleteRandomizationTicketByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RandomizationTicket` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRandomizationTicketByTrialId` mutation. */
export type DeleteRandomizationTicketByTrialIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  trialId: Scalars['UUID'];
};

/** All input for the `deleteRandomizationTicket` mutation. */
export type DeleteRandomizationTicketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `RandomizationTicket` mutation. */
export type DeleteRandomizationTicketPayload = {
  __typename?: 'DeleteRandomizationTicketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRandomizationTicketNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RandomizationTicket` that was deleted by this mutation. */
  randomizationTicket?: Maybe<RandomizationTicket>;
  /** An edge for our `RandomizationTicket`. May be used by Relay 1. */
  randomizationTicketEdge?: Maybe<RandomizationTicketsEdge>;
  /** Reads a single `Trial` that is related to this `RandomizationTicket`. */
  trial?: Maybe<Trial>;
};


/** The output of our delete `RandomizationTicket` mutation. */
export type DeleteRandomizationTicketPayloadRandomizationTicketEdgeArgs = {
  orderBy?: InputMaybe<Array<RandomizationTicketsOrderBy>>;
};

/** All input for the `deleteRawUsageDatumByNodeId` mutation. */
export type DeleteRawUsageDatumByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RawUsageDatum` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteRawUsageDatum` mutation. */
export type DeleteRawUsageDatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `RawUsageDatum` mutation. */
export type DeleteRawUsageDatumPayload = {
  __typename?: 'DeleteRawUsageDatumPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedRawUsageDatumNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RawUsageDatum` that was deleted by this mutation. */
  rawUsageDatum?: Maybe<RawUsageDatum>;
  /** An edge for our `RawUsageDatum`. May be used by Relay 1. */
  rawUsageDatumEdge?: Maybe<RawUsageDataEdge>;
  /** Reads a single `Trial` that is related to this `RawUsageDatum`. */
  trial?: Maybe<Trial>;
  /** Reads a single `User` that is related to this `RawUsageDatum`. */
  user?: Maybe<User>;
};


/** The output of our delete `RawUsageDatum` mutation. */
export type DeleteRawUsageDatumPayloadRawUsageDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<RawUsageDataOrderBy>>;
};

/** All input for the `deleteTenantByName` mutation. */
export type DeleteTenantByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteTenantByNodeId` mutation. */
export type DeleteTenantByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Tenant` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTenantByUrl` mutation. */
export type DeleteTenantByUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  url: Scalars['String'];
};

/** All input for the `deleteTenant` mutation. */
export type DeleteTenantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Tenant` mutation. */
export type DeleteTenantPayload = {
  __typename?: 'DeleteTenantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedTenantNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Tenant` that was deleted by this mutation. */
  tenant?: Maybe<Tenant>;
  /** An edge for our `Tenant`. May be used by Relay 1. */
  tenantEdge?: Maybe<TenantsEdge>;
};


/** The output of our delete `Tenant` mutation. */
export type DeleteTenantPayloadTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantsOrderBy>>;
};

/** All input for the `deleteTrialByHumanReadableId` mutation. */
export type DeleteTrialByHumanReadableIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  humanReadableId: Scalars['BigInt'];
};

/** All input for the `deleteTrialByName` mutation. */
export type DeleteTrialByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

/** All input for the `deleteTrialByNodeId` mutation. */
export type DeleteTrialByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Trial` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteTrial` mutation. */
export type DeleteTrialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Trial` mutation. */
export type DeleteTrialPayload = {
  __typename?: 'DeleteTrialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Commentable` that is related to this `Trial`. */
  commentableById?: Maybe<Commentable>;
  /** Reads a single `User` that is related to this `Trial`. */
  createdBy?: Maybe<User>;
  deletedTrialNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `GroupHome` that is related to this `Trial`. */
  groupHomeById?: Maybe<GroupHome>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  internalMessages?: Maybe<Conversation>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  messages?: Maybe<Conversation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Trial`. */
  staff?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Trial`. */
  subject?: Maybe<User>;
  /** Reads a single `Tenant` that is related to this `Trial`. */
  tenant?: Maybe<Tenant>;
  /** The `Trial` that was deleted by this mutation. */
  trial?: Maybe<Trial>;
  /** An edge for our `Trial`. May be used by Relay 1. */
  trialEdge?: Maybe<TrialsEdge>;
};


/** The output of our delete `Trial` mutation. */
export type DeleteTrialPayloadTrialEdgeArgs = {
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

/** All input for the `deleteUserByCredentialsId` mutation. */
export type DeleteUserByCredentialsIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  credentialsId: Scalars['UUID'];
};

/** All input for the `deleteUserByEmailAddressId` mutation. */
export type DeleteUserByEmailAddressIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddressId: Scalars['UUID'];
};

/** All input for the `deleteUserByIdAndTenantId` mutation. */
export type DeleteUserByIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  tenantId: Scalars['UUID'];
};

/** All input for the `deleteUserByNodeId` mutation. */
export type DeleteUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserByUsernameAndTenantId` mutation. */
export type DeleteUserByUsernameAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  tenantId: Scalars['UUID'];
  username: Scalars['String'];
};

/** All input for the `deleteUserCredentialByExternalAuthIdAndTenantId` mutation. */
export type DeleteUserCredentialByExternalAuthIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalAuthId: Scalars['String'];
  tenantId: Scalars['UUID'];
};

/** All input for the `deleteUserCredentialByNodeId` mutation. */
export type DeleteUserCredentialByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserCredential` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserCredential` mutation. */
export type DeleteUserCredentialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `UserCredential` mutation. */
export type DeleteUserCredentialPayload = {
  __typename?: 'DeleteUserCredentialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserCredentialNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `UserCredential`. */
  tenant?: Maybe<Tenant>;
  /** The `UserCredential` that was deleted by this mutation. */
  userCredential?: Maybe<UserCredential>;
  /** An edge for our `UserCredential`. May be used by Relay 1. */
  userCredentialEdge?: Maybe<UserCredentialsEdge>;
};


/** The output of our delete `UserCredential` mutation. */
export type DeleteUserCredentialPayloadUserCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<UserCredentialsOrderBy>>;
};

/** All input for the `deleteUserEmailAddressByEmailAddressAndTenantId` mutation. */
export type DeleteUserEmailAddressByEmailAddressAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  tenantId: Scalars['UUID'];
};

/** All input for the `deleteUserEmailAddressByNodeId` mutation. */
export type DeleteUserEmailAddressByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserEmailAddress` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserEmailAddress` mutation. */
export type DeleteUserEmailAddressInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `UserEmailAddress` mutation. */
export type DeleteUserEmailAddressPayload = {
  __typename?: 'DeleteUserEmailAddressPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedUserEmailAddressNodeId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserEmailAddress` that was deleted by this mutation. */
  userEmailAddress?: Maybe<UserEmailAddress>;
  /** An edge for our `UserEmailAddress`. May be used by Relay 1. */
  userEmailAddressEdge?: Maybe<UserEmailAddressesEdge>;
};


/** The output of our delete `UserEmailAddress` mutation. */
export type DeleteUserEmailAddressPayloadUserEmailAddressEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmailAddressesOrderBy>>;
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: 'DeleteUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `UserCredential` that is related to this `User`. */
  credentials?: Maybe<UserCredential>;
  deletedUserNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `UserEmailAddress` that is related to this `User`. */
  emailAddress?: Maybe<UserEmailAddress>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `User`. */
  tenant?: Maybe<Tenant>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type EmaEntries2 = Node & {
  __typename?: 'EmaEntries2';
  angry?: Maybe<Scalars['Int']>;
  anxious?: Maybe<Scalars['Int']>;
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  burden?: Maybe<Scalars['Int']>;
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  deleted: Scalars['Boolean'];
  guilty?: Maybe<Scalars['Int']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: Maybe<Scalars['Boolean']>;
  happy?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  lonely?: Maybe<Scalars['Int']>;
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['Int']>;
  noSocialContactDueToOuterConditions?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  plannedHowToProceed?: Maybe<Scalars['Int']>;
  relaxed?: Maybe<Scalars['Int']>;
  rumination?: Maybe<Scalars['Int']>;
  sad?: Maybe<Scalars['Int']>;
  satisfied?: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['Datetime'];
  startedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `EmaEntries2`. */
  subject?: Maybe<User>;
  subjectId: Scalars['UUID'];
  tenseDuringContact?: Maybe<Scalars['Int']>;
  testy?: Maybe<Scalars['Int']>;
  testyDuringContact?: Maybe<Scalars['Int']>;
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  triedToCalmMyselfDown?: Maybe<Scalars['Int']>;
  triedToChangeMyPerspective?: Maybe<Scalars['Int']>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  updatedAtUtc?: Maybe<Scalars['String']>;
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

export type EmaEntries2Aggregates = {
  __typename?: 'EmaEntries2Aggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EmaEntries2AverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmaEntries2DistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EmaEntries2MaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EmaEntries2MinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EmaEntries2StddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EmaEntries2StddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EmaEntries2SumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EmaEntries2VariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EmaEntries2VarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `EmaEntries2` object types. */
export type EmaEntries2AggregatesFilter = {
  /** Mean average aggregate over matching `EmaEntries2` objects. */
  average?: InputMaybe<EmaEntries2AverageAggregateFilter>;
  /** Distinct count aggregate over matching `EmaEntries2` objects. */
  distinctCount?: InputMaybe<EmaEntries2DistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `EmaEntries2` object to be included within the aggregate. */
  filter?: InputMaybe<EmaEntries2Filter>;
  /** Maximum aggregate over matching `EmaEntries2` objects. */
  max?: InputMaybe<EmaEntries2MaxAggregateFilter>;
  /** Minimum aggregate over matching `EmaEntries2` objects. */
  min?: InputMaybe<EmaEntries2MinAggregateFilter>;
  /** Population standard deviation aggregate over matching `EmaEntries2` objects. */
  stddevPopulation?: InputMaybe<EmaEntries2StddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `EmaEntries2` objects. */
  stddevSample?: InputMaybe<EmaEntries2StddevSampleAggregateFilter>;
  /** Sum aggregate over matching `EmaEntries2` objects. */
  sum?: InputMaybe<EmaEntries2SumAggregateFilter>;
  /** Population variance aggregate over matching `EmaEntries2` objects. */
  variancePopulation?: InputMaybe<EmaEntries2VariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `EmaEntries2` objects. */
  varianceSample?: InputMaybe<EmaEntries2VarianceSampleAggregateFilter>;
};

export type EmaEntries2AverageAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  burden?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  rumination?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToChangeMyPerspective?: InputMaybe<BigFloatFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries2AverageAggregates = {
  __typename?: 'EmaEntries2AverageAggregates';
  /** Mean average of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Mean average of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Mean average of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of burden across the matching connection */
  burden?: Maybe<Scalars['BigFloat']>;
  /** Mean average of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Mean average of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Mean average of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Mean average of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of rumination across the matching connection */
  rumination?: Maybe<Scalars['BigFloat']>;
  /** Mean average of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Mean average of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Mean average of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EmaEntries2` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EmaEntries2Condition = {
  /** Checks for equality with the object’s `angry` field. */
  angry?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `anxious` field. */
  anxious?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `anxiousDuringContact` field. */
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `avoidedSocialContact` field. */
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `burden` field. */
  burden?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `comfortableDuringContact` field. */
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `guilty` field. */
  guilty?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `hadAnyContactWithOthersSinceTheLastSurvey` field. */
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `happy` field. */
  happy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `lackOfSocialContactBothersMe` field. */
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lonely` field. */
  lonely?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lookedForAdviceAndComfortFromOthers` field. */
  lookedForAdviceAndComfortFromOthers?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `noSocialContactDueToOuterConditions` field. */
  noSocialContactDueToOuterConditions?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `notTriedToAlterMyThoughtsAndFeelings` field. */
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `plannedHowToProceed` field. */
  plannedHowToProceed?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `relaxed` field. */
  relaxed?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rumination` field. */
  rumination?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sad` field. */
  sad?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `satisfied` field. */
  satisfied?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `scheduledAt` field. */
  scheduledAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `subjectId` field. */
  subjectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tenseDuringContact` field. */
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `testy` field. */
  testy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `testyDuringContact` field. */
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `thoughtALotAboutMyThoughtsAndFeelings` field. */
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToAcceptMyThoughtsAndFeelings` field. */
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToCalmMyselfDown` field. */
  triedToCalmMyselfDown?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToChangeMyPerspective` field. */
  triedToChangeMyPerspective?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToDistractMyselfFromMyThoughtsAndFeelings` field. */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToHideMyThoughtsAndFeelings` field. */
  triedToHideMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToRepressMyThoughtsAndFeelings` field. */
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unconfidentDuringContact` field. */
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAtUtc` field. */
  updatedAtUtc?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `wouldHaveLikedSocialContact` field. */
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntries2DistinctCountAggregateFilter = {
  angry?: InputMaybe<BigIntFilter>;
  anxious?: InputMaybe<BigIntFilter>;
  anxiousDuringContact?: InputMaybe<BigIntFilter>;
  avoidedSocialContact?: InputMaybe<BigIntFilter>;
  burden?: InputMaybe<BigIntFilter>;
  comfortableDuringContact?: InputMaybe<BigIntFilter>;
  completedAt?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  guilty?: InputMaybe<BigIntFilter>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<BigIntFilter>;
  happy?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigIntFilter>;
  lonely?: InputMaybe<BigIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  plannedHowToProceed?: InputMaybe<BigIntFilter>;
  relaxed?: InputMaybe<BigIntFilter>;
  rumination?: InputMaybe<BigIntFilter>;
  sad?: InputMaybe<BigIntFilter>;
  satisfied?: InputMaybe<BigIntFilter>;
  scheduledAt?: InputMaybe<BigIntFilter>;
  startedAt?: InputMaybe<BigIntFilter>;
  subjectId?: InputMaybe<BigIntFilter>;
  tenseDuringContact?: InputMaybe<BigIntFilter>;
  testy?: InputMaybe<BigIntFilter>;
  testyDuringContact?: InputMaybe<BigIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<BigIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  unconfidentDuringContact?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedAtUtc?: InputMaybe<StringFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigIntFilter>;
};

export type EmaEntries2DistinctCountAggregates = {
  __typename?: 'EmaEntries2DistinctCountAggregates';
  /** Distinct count of angry across the matching connection */
  angry?: Maybe<Scalars['BigInt']>;
  /** Distinct count of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigInt']>;
  /** Distinct count of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of burden across the matching connection */
  burden?: Maybe<Scalars['BigInt']>;
  /** Distinct count of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of completedAt across the matching connection */
  completedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars['BigInt']>;
  /** Distinct count of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hadAnyContactWithOthersSinceTheLastSurvey across the matching connection */
  hadAnyContactWithOthersSinceTheLastSurvey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of happy across the matching connection */
  happy?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigInt']>;
  /** Distinct count of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigInt']>;
  /** Distinct count of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of rumination across the matching connection */
  rumination?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sad across the matching connection */
  sad?: Maybe<Scalars['BigInt']>;
  /** Distinct count of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduledAt across the matching connection */
  scheduledAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of subjectId across the matching connection */
  subjectId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of testy across the matching connection */
  testy?: Maybe<Scalars['BigInt']>;
  /** Distinct count of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  updatedAtUtc?: Maybe<Scalars['BigInt']>;
  /** Distinct count of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EmaEntries2` object types. All fields are combined with a logical ‘and.’ */
export type EmaEntries2Filter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmaEntries2Filter>>;
  /** Filter by the object’s `angry` field. */
  angry?: InputMaybe<IntFilter>;
  /** Filter by the object’s `anxious` field. */
  anxious?: InputMaybe<IntFilter>;
  /** Filter by the object’s `anxiousDuringContact` field. */
  anxiousDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `avoidedSocialContact` field. */
  avoidedSocialContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `burden` field. */
  burden?: InputMaybe<IntFilter>;
  /** Filter by the object’s `comfortableDuringContact` field. */
  comfortableDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `guilty` field. */
  guilty?: InputMaybe<IntFilter>;
  /** Filter by the object’s `hadAnyContactWithOthersSinceTheLastSurvey` field. */
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `happy` field. */
  happy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lackOfSocialContactBothersMe` field. */
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lonely` field. */
  lonely?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lookedForAdviceAndComfortFromOthers` field. */
  lookedForAdviceAndComfortFromOthers?: InputMaybe<IntFilter>;
  /** Filter by the object’s `noSocialContactDueToOuterConditions` field. */
  noSocialContactDueToOuterConditions?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmaEntries2Filter>;
  /** Filter by the object’s `notTriedToAlterMyThoughtsAndFeelings` field. */
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmaEntries2Filter>>;
  /** Filter by the object’s `plannedHowToProceed` field. */
  plannedHowToProceed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `relaxed` field. */
  relaxed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `rumination` field. */
  rumination?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sad` field. */
  sad?: InputMaybe<IntFilter>;
  /** Filter by the object’s `satisfied` field. */
  satisfied?: InputMaybe<IntFilter>;
  /** Filter by the object’s `scheduledAt` field. */
  scheduledAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `subject` relation. */
  subject?: InputMaybe<UserFilter>;
  /** Filter by the object’s `subjectId` field. */
  subjectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenseDuringContact` field. */
  tenseDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `testy` field. */
  testy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `testyDuringContact` field. */
  testyDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `thoughtALotAboutMyThoughtsAndFeelings` field. */
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToAcceptMyThoughtsAndFeelings` field. */
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToCalmMyselfDown` field. */
  triedToCalmMyselfDown?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToChangeMyPerspective` field. */
  triedToChangeMyPerspective?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToDistractMyselfFromMyThoughtsAndFeelings` field. */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToHideMyThoughtsAndFeelings` field. */
  triedToHideMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToRepressMyThoughtsAndFeelings` field. */
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `unconfidentDuringContact` field. */
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAtUtc` field. */
  updatedAtUtc?: InputMaybe<StringFilter>;
  /** Filter by the object’s `wouldHaveLikedSocialContact` field. */
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

/** Grouping methods for `EmaEntries2` for usage during aggregation. */
export enum EmaEntries2GroupBy {
  Angry = 'ANGRY',
  Anxious = 'ANXIOUS',
  AnxiousDuringContact = 'ANXIOUS_DURING_CONTACT',
  AvoidedSocialContact = 'AVOIDED_SOCIAL_CONTACT',
  Burden = 'BURDEN',
  ComfortableDuringContact = 'COMFORTABLE_DURING_CONTACT',
  CompletedAt = 'COMPLETED_AT',
  CompletedAtTruncatedToDay = 'COMPLETED_AT_TRUNCATED_TO_DAY',
  CompletedAtTruncatedToHour = 'COMPLETED_AT_TRUNCATED_TO_HOUR',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  Deleted = 'DELETED',
  Guilty = 'GUILTY',
  HadAnyContactWithOthersSinceTheLastSurvey = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY',
  Happy = 'HAPPY',
  LackOfSocialContactBothersMe = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME',
  Lonely = 'LONELY',
  LookedForAdviceAndComfortFromOthers = 'LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS',
  NotTriedToAlterMyThoughtsAndFeelings = 'NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS',
  NoSocialContactDueToOuterConditions = 'NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS',
  PlannedHowToProceed = 'PLANNED_HOW_TO_PROCEED',
  Relaxed = 'RELAXED',
  Rumination = 'RUMINATION',
  Sad = 'SAD',
  Satisfied = 'SATISFIED',
  ScheduledAt = 'SCHEDULED_AT',
  ScheduledAtTruncatedToDay = 'SCHEDULED_AT_TRUNCATED_TO_DAY',
  ScheduledAtTruncatedToHour = 'SCHEDULED_AT_TRUNCATED_TO_HOUR',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  SubjectId = 'SUBJECT_ID',
  TenseDuringContact = 'TENSE_DURING_CONTACT',
  Testy = 'TESTY',
  TestyDuringContact = 'TESTY_DURING_CONTACT',
  ThoughtALotAboutMyThoughtsAndFeelings = 'THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS',
  TriedToAcceptMyThoughtsAndFeelings = 'TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS',
  TriedToCalmMyselfDown = 'TRIED_TO_CALM_MYSELF_DOWN',
  TriedToChangeMyPerspective = 'TRIED_TO_CHANGE_MY_PERSPECTIVE',
  TriedToDistractMyselfFromMyThoughtsAndFeelings = 'TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS',
  TriedToHideMyThoughtsAndFeelings = 'TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS',
  TriedToRepressMyThoughtsAndFeelings = 'TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS',
  UnconfidentDuringContact = 'UNCONFIDENT_DURING_CONTACT',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  WouldHaveLikedSocialContact = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT'
}

export type EmaEntries2HavingAverageInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries2HavingDistinctCountInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `EmaEntries2` aggregates. */
export type EmaEntries2HavingInput = {
  AND?: InputMaybe<Array<EmaEntries2HavingInput>>;
  OR?: InputMaybe<Array<EmaEntries2HavingInput>>;
  average?: InputMaybe<EmaEntries2HavingAverageInput>;
  distinctCount?: InputMaybe<EmaEntries2HavingDistinctCountInput>;
  max?: InputMaybe<EmaEntries2HavingMaxInput>;
  min?: InputMaybe<EmaEntries2HavingMinInput>;
  stddevPopulation?: InputMaybe<EmaEntries2HavingStddevPopulationInput>;
  stddevSample?: InputMaybe<EmaEntries2HavingStddevSampleInput>;
  sum?: InputMaybe<EmaEntries2HavingSumInput>;
  variancePopulation?: InputMaybe<EmaEntries2HavingVariancePopulationInput>;
  varianceSample?: InputMaybe<EmaEntries2HavingVarianceSampleInput>;
};

export type EmaEntries2HavingMaxInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries2HavingMinInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries2HavingStddevPopulationInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries2HavingStddevSampleInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries2HavingSumInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries2HavingVariancePopulationInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries2HavingVarianceSampleInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  burden?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  rumination?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<HavingIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

/** An input for mutations affecting `EmaEntries2` */
export type EmaEntries2Input = {
  angry?: InputMaybe<Scalars['Int']>;
  anxious?: InputMaybe<Scalars['Int']>;
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  burden?: InputMaybe<Scalars['Int']>;
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  guilty?: InputMaybe<Scalars['Int']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  happy?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  lonely?: InputMaybe<Scalars['Int']>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<Scalars['Int']>;
  noSocialContactDueToOuterConditions?: InputMaybe<Scalars['Int']>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  plannedHowToProceed?: InputMaybe<Scalars['Int']>;
  relaxed?: InputMaybe<Scalars['Int']>;
  rumination?: InputMaybe<Scalars['Int']>;
  sad?: InputMaybe<Scalars['Int']>;
  satisfied?: InputMaybe<Scalars['Int']>;
  scheduledAt: Scalars['Datetime'];
  startedAt?: InputMaybe<Scalars['Datetime']>;
  subjectId?: InputMaybe<Scalars['UUID']>;
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  testy?: InputMaybe<Scalars['Int']>;
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToCalmMyselfDown?: InputMaybe<Scalars['Int']>;
  triedToChangeMyPerspective?: InputMaybe<Scalars['Int']>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntries2MaxAggregateFilter = {
  angry?: InputMaybe<IntFilter>;
  anxious?: InputMaybe<IntFilter>;
  anxiousDuringContact?: InputMaybe<IntFilter>;
  avoidedSocialContact?: InputMaybe<IntFilter>;
  burden?: InputMaybe<IntFilter>;
  comfortableDuringContact?: InputMaybe<IntFilter>;
  guilty?: InputMaybe<IntFilter>;
  happy?: InputMaybe<IntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  lonely?: InputMaybe<IntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<IntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<IntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  plannedHowToProceed?: InputMaybe<IntFilter>;
  relaxed?: InputMaybe<IntFilter>;
  rumination?: InputMaybe<IntFilter>;
  sad?: InputMaybe<IntFilter>;
  satisfied?: InputMaybe<IntFilter>;
  tenseDuringContact?: InputMaybe<IntFilter>;
  testy?: InputMaybe<IntFilter>;
  testyDuringContact?: InputMaybe<IntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToCalmMyselfDown?: InputMaybe<IntFilter>;
  triedToChangeMyPerspective?: InputMaybe<IntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

export type EmaEntries2MaxAggregates = {
  __typename?: 'EmaEntries2MaxAggregates';
  /** Maximum of angry across the matching connection */
  angry?: Maybe<Scalars['Int']>;
  /** Maximum of anxious across the matching connection */
  anxious?: Maybe<Scalars['Int']>;
  /** Maximum of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  /** Maximum of burden across the matching connection */
  burden?: Maybe<Scalars['Int']>;
  /** Maximum of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of guilty across the matching connection */
  guilty?: Maybe<Scalars['Int']>;
  /** Maximum of happy across the matching connection */
  happy?: Maybe<Scalars['Int']>;
  /** Maximum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  /** Maximum of lonely across the matching connection */
  lonely?: Maybe<Scalars['Int']>;
  /** Maximum of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['Int']>;
  /** Maximum of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['Int']>;
  /** Maximum of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Maximum of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['Int']>;
  /** Maximum of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['Int']>;
  /** Maximum of rumination across the matching connection */
  rumination?: Maybe<Scalars['Int']>;
  /** Maximum of sad across the matching connection */
  sad?: Maybe<Scalars['Int']>;
  /** Maximum of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['Int']>;
  /** Maximum of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of testy across the matching connection */
  testy?: Maybe<Scalars['Int']>;
  /** Maximum of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Maximum of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Maximum of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['Int']>;
  /** Maximum of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective?: Maybe<Scalars['Int']>;
  /** Maximum of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Maximum of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Maximum of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Maximum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

export type EmaEntries2MinAggregateFilter = {
  angry?: InputMaybe<IntFilter>;
  anxious?: InputMaybe<IntFilter>;
  anxiousDuringContact?: InputMaybe<IntFilter>;
  avoidedSocialContact?: InputMaybe<IntFilter>;
  burden?: InputMaybe<IntFilter>;
  comfortableDuringContact?: InputMaybe<IntFilter>;
  guilty?: InputMaybe<IntFilter>;
  happy?: InputMaybe<IntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  lonely?: InputMaybe<IntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<IntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<IntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  plannedHowToProceed?: InputMaybe<IntFilter>;
  relaxed?: InputMaybe<IntFilter>;
  rumination?: InputMaybe<IntFilter>;
  sad?: InputMaybe<IntFilter>;
  satisfied?: InputMaybe<IntFilter>;
  tenseDuringContact?: InputMaybe<IntFilter>;
  testy?: InputMaybe<IntFilter>;
  testyDuringContact?: InputMaybe<IntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToCalmMyselfDown?: InputMaybe<IntFilter>;
  triedToChangeMyPerspective?: InputMaybe<IntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

export type EmaEntries2MinAggregates = {
  __typename?: 'EmaEntries2MinAggregates';
  /** Minimum of angry across the matching connection */
  angry?: Maybe<Scalars['Int']>;
  /** Minimum of anxious across the matching connection */
  anxious?: Maybe<Scalars['Int']>;
  /** Minimum of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  /** Minimum of burden across the matching connection */
  burden?: Maybe<Scalars['Int']>;
  /** Minimum of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of guilty across the matching connection */
  guilty?: Maybe<Scalars['Int']>;
  /** Minimum of happy across the matching connection */
  happy?: Maybe<Scalars['Int']>;
  /** Minimum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  /** Minimum of lonely across the matching connection */
  lonely?: Maybe<Scalars['Int']>;
  /** Minimum of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['Int']>;
  /** Minimum of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['Int']>;
  /** Minimum of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Minimum of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['Int']>;
  /** Minimum of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['Int']>;
  /** Minimum of rumination across the matching connection */
  rumination?: Maybe<Scalars['Int']>;
  /** Minimum of sad across the matching connection */
  sad?: Maybe<Scalars['Int']>;
  /** Minimum of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['Int']>;
  /** Minimum of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of testy across the matching connection */
  testy?: Maybe<Scalars['Int']>;
  /** Minimum of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Minimum of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Minimum of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['Int']>;
  /** Minimum of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective?: Maybe<Scalars['Int']>;
  /** Minimum of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Minimum of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Minimum of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Minimum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EmaEntries2`. Fields that are set will be updated. */
export type EmaEntries2Patch = {
  angry?: InputMaybe<Scalars['Int']>;
  anxious?: InputMaybe<Scalars['Int']>;
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  burden?: InputMaybe<Scalars['Int']>;
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  guilty?: InputMaybe<Scalars['Int']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  happy?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  lonely?: InputMaybe<Scalars['Int']>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<Scalars['Int']>;
  noSocialContactDueToOuterConditions?: InputMaybe<Scalars['Int']>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  plannedHowToProceed?: InputMaybe<Scalars['Int']>;
  relaxed?: InputMaybe<Scalars['Int']>;
  rumination?: InputMaybe<Scalars['Int']>;
  sad?: InputMaybe<Scalars['Int']>;
  satisfied?: InputMaybe<Scalars['Int']>;
  scheduledAt?: InputMaybe<Scalars['Datetime']>;
  startedAt?: InputMaybe<Scalars['Datetime']>;
  subjectId?: InputMaybe<Scalars['UUID']>;
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  testy?: InputMaybe<Scalars['Int']>;
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToCalmMyselfDown?: InputMaybe<Scalars['Int']>;
  triedToChangeMyPerspective?: InputMaybe<Scalars['Int']>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntries2StddevPopulationAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  burden?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  rumination?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToChangeMyPerspective?: InputMaybe<BigFloatFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries2StddevPopulationAggregates = {
  __typename?: 'EmaEntries2StddevPopulationAggregates';
  /** Population standard deviation of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of burden across the matching connection */
  burden?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of rumination across the matching connection */
  rumination?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntries2StddevSampleAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  burden?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  rumination?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToChangeMyPerspective?: InputMaybe<BigFloatFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries2StddevSampleAggregates = {
  __typename?: 'EmaEntries2StddevSampleAggregates';
  /** Sample standard deviation of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of burden across the matching connection */
  burden?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of rumination across the matching connection */
  rumination?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntries2SumAggregateFilter = {
  angry?: InputMaybe<BigIntFilter>;
  anxious?: InputMaybe<BigIntFilter>;
  anxiousDuringContact?: InputMaybe<BigIntFilter>;
  avoidedSocialContact?: InputMaybe<BigIntFilter>;
  burden?: InputMaybe<BigIntFilter>;
  comfortableDuringContact?: InputMaybe<BigIntFilter>;
  guilty?: InputMaybe<BigIntFilter>;
  happy?: InputMaybe<BigIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigIntFilter>;
  lonely?: InputMaybe<BigIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigIntFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  plannedHowToProceed?: InputMaybe<BigIntFilter>;
  relaxed?: InputMaybe<BigIntFilter>;
  rumination?: InputMaybe<BigIntFilter>;
  sad?: InputMaybe<BigIntFilter>;
  satisfied?: InputMaybe<BigIntFilter>;
  tenseDuringContact?: InputMaybe<BigIntFilter>;
  testy?: InputMaybe<BigIntFilter>;
  testyDuringContact?: InputMaybe<BigIntFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigIntFilter>;
  triedToChangeMyPerspective?: InputMaybe<BigIntFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  unconfidentDuringContact?: InputMaybe<BigIntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigIntFilter>;
};

export type EmaEntries2SumAggregates = {
  __typename?: 'EmaEntries2SumAggregates';
  /** Sum of angry across the matching connection */
  angry: Scalars['BigInt'];
  /** Sum of anxious across the matching connection */
  anxious: Scalars['BigInt'];
  /** Sum of anxiousDuringContact across the matching connection */
  anxiousDuringContact: Scalars['BigInt'];
  /** Sum of avoidedSocialContact across the matching connection */
  avoidedSocialContact: Scalars['BigInt'];
  /** Sum of burden across the matching connection */
  burden: Scalars['BigInt'];
  /** Sum of comfortableDuringContact across the matching connection */
  comfortableDuringContact: Scalars['BigInt'];
  /** Sum of guilty across the matching connection */
  guilty: Scalars['BigInt'];
  /** Sum of happy across the matching connection */
  happy: Scalars['BigInt'];
  /** Sum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe: Scalars['BigInt'];
  /** Sum of lonely across the matching connection */
  lonely: Scalars['BigInt'];
  /** Sum of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers: Scalars['BigInt'];
  /** Sum of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions: Scalars['BigInt'];
  /** Sum of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings: Scalars['BigInt'];
  /** Sum of plannedHowToProceed across the matching connection */
  plannedHowToProceed: Scalars['BigInt'];
  /** Sum of relaxed across the matching connection */
  relaxed: Scalars['BigInt'];
  /** Sum of rumination across the matching connection */
  rumination: Scalars['BigInt'];
  /** Sum of sad across the matching connection */
  sad: Scalars['BigInt'];
  /** Sum of satisfied across the matching connection */
  satisfied: Scalars['BigInt'];
  /** Sum of tenseDuringContact across the matching connection */
  tenseDuringContact: Scalars['BigInt'];
  /** Sum of testy across the matching connection */
  testy: Scalars['BigInt'];
  /** Sum of testyDuringContact across the matching connection */
  testyDuringContact: Scalars['BigInt'];
  /** Sum of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings: Scalars['BigInt'];
  /** Sum of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings: Scalars['BigInt'];
  /** Sum of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown: Scalars['BigInt'];
  /** Sum of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective: Scalars['BigInt'];
  /** Sum of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings: Scalars['BigInt'];
  /** Sum of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings: Scalars['BigInt'];
  /** Sum of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings: Scalars['BigInt'];
  /** Sum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact: Scalars['BigInt'];
  /** Sum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact: Scalars['BigInt'];
};

export type EmaEntries2VariancePopulationAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  burden?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  rumination?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToChangeMyPerspective?: InputMaybe<BigFloatFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries2VariancePopulationAggregates = {
  __typename?: 'EmaEntries2VariancePopulationAggregates';
  /** Population variance of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Population variance of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Population variance of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of burden across the matching connection */
  burden?: Maybe<Scalars['BigFloat']>;
  /** Population variance of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Population variance of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Population variance of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Population variance of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of rumination across the matching connection */
  rumination?: Maybe<Scalars['BigFloat']>;
  /** Population variance of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Population variance of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Population variance of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntries2VarianceSampleAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  burden?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  notTriedToAlterMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  rumination?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  thoughtALotAboutMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToAcceptMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToChangeMyPerspective?: InputMaybe<BigFloatFilter>;
  triedToDistractMyselfFromMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToHideMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries2VarianceSampleAggregates = {
  __typename?: 'EmaEntries2VarianceSampleAggregates';
  /** Sample variance of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of burden across the matching connection */
  burden?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of notTriedToAlterMyThoughtsAndFeelings across the matching connection */
  notTriedToAlterMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of rumination across the matching connection */
  rumination?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of thoughtALotAboutMyThoughtsAndFeelings across the matching connection */
  thoughtALotAboutMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToAcceptMyThoughtsAndFeelings across the matching connection */
  triedToAcceptMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToChangeMyPerspective across the matching connection */
  triedToChangeMyPerspective?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToDistractMyselfFromMyThoughtsAndFeelings across the matching connection */
  triedToDistractMyselfFromMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToHideMyThoughtsAndFeelings across the matching connection */
  triedToHideMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `EmaEntries2` values. */
export type EmaEntries2sConnection = {
  __typename?: 'EmaEntries2sConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmaEntries2Aggregates>;
  /** A list of edges which contains the `EmaEntries2` and cursor to aid in pagination. */
  edges: Array<EmaEntries2sEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmaEntries2Aggregates>>;
  /** A list of `EmaEntries2` objects. */
  nodes: Array<EmaEntries2>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmaEntries2` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `EmaEntries2` values. */
export type EmaEntries2sConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmaEntries2GroupBy>;
  having?: InputMaybe<EmaEntries2HavingInput>;
};

/** A `EmaEntries2` edge in the connection. */
export type EmaEntries2sEdge = {
  __typename?: 'EmaEntries2sEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EmaEntries2` at the end of the edge. */
  node: EmaEntries2;
};

/** Methods to use when ordering `EmaEntries2`. */
export enum EmaEntries2sOrderBy {
  AngryAsc = 'ANGRY_ASC',
  AngryDesc = 'ANGRY_DESC',
  AnxiousAsc = 'ANXIOUS_ASC',
  AnxiousDesc = 'ANXIOUS_DESC',
  AnxiousDuringContactAsc = 'ANXIOUS_DURING_CONTACT_ASC',
  AnxiousDuringContactDesc = 'ANXIOUS_DURING_CONTACT_DESC',
  AvoidedSocialContactAsc = 'AVOIDED_SOCIAL_CONTACT_ASC',
  AvoidedSocialContactDesc = 'AVOIDED_SOCIAL_CONTACT_DESC',
  BurdenAsc = 'BURDEN_ASC',
  BurdenDesc = 'BURDEN_DESC',
  ComfortableDuringContactAsc = 'COMFORTABLE_DURING_CONTACT_ASC',
  ComfortableDuringContactDesc = 'COMFORTABLE_DURING_CONTACT_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  GuiltyAsc = 'GUILTY_ASC',
  GuiltyDesc = 'GUILTY_DESC',
  HadAnyContactWithOthersSinceTheLastSurveyAsc = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  HadAnyContactWithOthersSinceTheLastSurveyDesc = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  HappyAsc = 'HAPPY_ASC',
  HappyDesc = 'HAPPY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LackOfSocialContactBothersMeAsc = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  LackOfSocialContactBothersMeDesc = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  LonelyAsc = 'LONELY_ASC',
  LonelyDesc = 'LONELY_DESC',
  LookedForAdviceAndComfortFromOthersAsc = 'LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  LookedForAdviceAndComfortFromOthersDesc = 'LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  Natural = 'NATURAL',
  NotTriedToAlterMyThoughtsAndFeelingsAsc = 'NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  NotTriedToAlterMyThoughtsAndFeelingsDesc = 'NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  NoSocialContactDueToOuterConditionsAsc = 'NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  NoSocialContactDueToOuterConditionsDesc = 'NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  PlannedHowToProceedAsc = 'PLANNED_HOW_TO_PROCEED_ASC',
  PlannedHowToProceedDesc = 'PLANNED_HOW_TO_PROCEED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RelaxedAsc = 'RELAXED_ASC',
  RelaxedDesc = 'RELAXED_DESC',
  RuminationAsc = 'RUMINATION_ASC',
  RuminationDesc = 'RUMINATION_DESC',
  SadAsc = 'SAD_ASC',
  SadDesc = 'SAD_DESC',
  SatisfiedAsc = 'SATISFIED_ASC',
  SatisfiedDesc = 'SATISFIED_DESC',
  ScheduledAtAsc = 'SCHEDULED_AT_ASC',
  ScheduledAtDesc = 'SCHEDULED_AT_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC',
  SubjectIdAsc = 'SUBJECT_ID_ASC',
  SubjectIdDesc = 'SUBJECT_ID_DESC',
  TenseDuringContactAsc = 'TENSE_DURING_CONTACT_ASC',
  TenseDuringContactDesc = 'TENSE_DURING_CONTACT_DESC',
  TestyAsc = 'TESTY_ASC',
  TestyDesc = 'TESTY_DESC',
  TestyDuringContactAsc = 'TESTY_DURING_CONTACT_ASC',
  TestyDuringContactDesc = 'TESTY_DURING_CONTACT_DESC',
  ThoughtALotAboutMyThoughtsAndFeelingsAsc = 'THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  ThoughtALotAboutMyThoughtsAndFeelingsDesc = 'THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  TriedToAcceptMyThoughtsAndFeelingsAsc = 'TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  TriedToAcceptMyThoughtsAndFeelingsDesc = 'TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  TriedToCalmMyselfDownAsc = 'TRIED_TO_CALM_MYSELF_DOWN_ASC',
  TriedToCalmMyselfDownDesc = 'TRIED_TO_CALM_MYSELF_DOWN_DESC',
  TriedToChangeMyPerspectiveAsc = 'TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  TriedToChangeMyPerspectiveDesc = 'TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  TriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  TriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  TriedToHideMyThoughtsAndFeelingsAsc = 'TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  TriedToHideMyThoughtsAndFeelingsDesc = 'TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  TriedToRepressMyThoughtsAndFeelingsAsc = 'TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  TriedToRepressMyThoughtsAndFeelingsDesc = 'TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  UnconfidentDuringContactAsc = 'UNCONFIDENT_DURING_CONTACT_ASC',
  UnconfidentDuringContactDesc = 'UNCONFIDENT_DURING_CONTACT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedAtUtcAsc = 'UPDATED_AT_UTC_ASC',
  UpdatedAtUtcDesc = 'UPDATED_AT_UTC_DESC',
  WouldHaveLikedSocialContactAsc = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  WouldHaveLikedSocialContactDesc = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC'
}

export type EmaEntries3 = Node & {
  __typename?: 'EmaEntries3';
  angry?: Maybe<Scalars['Int']>;
  anxious?: Maybe<Scalars['Int']>;
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['Int']>;
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  burdens?: Maybe<Array<Maybe<Scalars['String']>>>;
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['Datetime']>;
  couldResolveBurdensInAdvance?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  deleted: Scalars['Boolean'];
  determined?: Maybe<Scalars['Int']>;
  energetic?: Maybe<Scalars['Int']>;
  enthusiastic?: Maybe<Scalars['Int']>;
  expectBurdenInTheNearFuture?: Maybe<Scalars['Int']>;
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['Int']>;
  guilty?: Maybe<Scalars['Int']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: Maybe<Scalars['Boolean']>;
  happy?: Maybe<Scalars['Int']>;
  happyDuringContact?: Maybe<Scalars['Int']>;
  helpless?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  inspiredDuringContact?: Maybe<Scalars['Int']>;
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['Int']>;
  lonely?: Maybe<Scalars['Int']>;
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['Int']>;
  noBurdenForExternalReasons?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  plannedHowToProceed?: Maybe<Scalars['Int']>;
  relaxed?: Maybe<Scalars['Int']>;
  sad?: Maybe<Scalars['Int']>;
  satisfied?: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['Datetime'];
  smsReceivedAt?: Maybe<Scalars['Time']>;
  socialDistress?: Maybe<Scalars['Int']>;
  startedAt?: Maybe<Scalars['Datetime']>;
  strain?: Maybe<Scalars['Int']>;
  strength?: Maybe<Scalars['Int']>;
  stressed?: Maybe<Scalars['Int']>;
  tenseDuringContact?: Maybe<Scalars['Int']>;
  testy?: Maybe<Scalars['Int']>;
  testyDuringContact?: Maybe<Scalars['Int']>;
  /** Reads a single `Trial` that is related to this `EmaEntries3`. */
  trial?: Maybe<Trial>;
  trialId: Scalars['UUID'];
  triedToCalmMyselfDown?: Maybe<Scalars['Int']>;
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  triedToThinkDifferently?: Maybe<Scalars['Int']>;
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['Int']>;
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  understoodDuringContact?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  wave?: Maybe<Scalars['String']>;
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

export type EmaEntries3Aggregates = {
  __typename?: 'EmaEntries3Aggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EmaEntries3AverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmaEntries3DistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EmaEntries3MaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EmaEntries3MinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EmaEntries3StddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EmaEntries3StddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EmaEntries3SumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EmaEntries3VariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EmaEntries3VarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `EmaEntries3` object types. */
export type EmaEntries3AggregatesFilter = {
  /** Mean average aggregate over matching `EmaEntries3` objects. */
  average?: InputMaybe<EmaEntries3AverageAggregateFilter>;
  /** Distinct count aggregate over matching `EmaEntries3` objects. */
  distinctCount?: InputMaybe<EmaEntries3DistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `EmaEntries3` object to be included within the aggregate. */
  filter?: InputMaybe<EmaEntries3Filter>;
  /** Maximum aggregate over matching `EmaEntries3` objects. */
  max?: InputMaybe<EmaEntries3MaxAggregateFilter>;
  /** Minimum aggregate over matching `EmaEntries3` objects. */
  min?: InputMaybe<EmaEntries3MinAggregateFilter>;
  /** Population standard deviation aggregate over matching `EmaEntries3` objects. */
  stddevPopulation?: InputMaybe<EmaEntries3StddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `EmaEntries3` objects. */
  stddevSample?: InputMaybe<EmaEntries3StddevSampleAggregateFilter>;
  /** Sum aggregate over matching `EmaEntries3` objects. */
  sum?: InputMaybe<EmaEntries3SumAggregateFilter>;
  /** Population variance aggregate over matching `EmaEntries3` objects. */
  variancePopulation?: InputMaybe<EmaEntries3VariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `EmaEntries3` objects. */
  varianceSample?: InputMaybe<EmaEntries3VarianceSampleAggregateFilter>;
};

export type EmaEntries3AverageAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<BigFloatFilter>;
  determined?: InputMaybe<BigFloatFilter>;
  energetic?: InputMaybe<BigFloatFilter>;
  enthusiastic?: InputMaybe<BigFloatFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<BigFloatFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  happyDuringContact?: InputMaybe<BigFloatFilter>;
  helpless?: InputMaybe<BigFloatFilter>;
  inspiredDuringContact?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noBurdenForExternalReasons?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  socialDistress?: InputMaybe<BigFloatFilter>;
  strain?: InputMaybe<BigFloatFilter>;
  strength?: InputMaybe<BigFloatFilter>;
  stressed?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  understoodDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries3AverageAggregates = {
  __typename?: 'EmaEntries3AverageAggregates';
  /** Mean average of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Mean average of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Mean average of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['BigFloat']>;
  /** Mean average of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance?: Maybe<Scalars['BigFloat']>;
  /** Mean average of determined across the matching connection */
  determined?: Maybe<Scalars['BigFloat']>;
  /** Mean average of energetic across the matching connection */
  energetic?: Maybe<Scalars['BigFloat']>;
  /** Mean average of enthusiastic across the matching connection */
  enthusiastic?: Maybe<Scalars['BigFloat']>;
  /** Mean average of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture?: Maybe<Scalars['BigFloat']>;
  /** Mean average of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['BigFloat']>;
  /** Mean average of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Mean average of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Mean average of happyDuringContact across the matching connection */
  happyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of helpless across the matching connection */
  helpless?: Maybe<Scalars['BigFloat']>;
  /** Mean average of inspiredDuringContact across the matching connection */
  inspiredDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Mean average of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Mean average of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Mean average of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Mean average of socialDistress across the matching connection */
  socialDistress?: Maybe<Scalars['BigFloat']>;
  /** Mean average of strain across the matching connection */
  strain?: Maybe<Scalars['BigFloat']>;
  /** Mean average of strength across the matching connection */
  strength?: Maybe<Scalars['BigFloat']>;
  /** Mean average of stressed across the matching connection */
  stressed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Mean average of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Mean average of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['BigFloat']>;
  /** Mean average of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of understoodDuringContact across the matching connection */
  understoodDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EmaEntries3` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EmaEntries3Condition = {
  /** Checks for equality with the object’s `angry` field. */
  angry?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `anxious` field. */
  anxious?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `anxiousDuringContact` field. */
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `atThatMomentIFeltMoreComfortableAlone` field. */
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `avoidedSocialContact` field. */
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `burdens` field. */
  burdens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `comfortableDuringContact` field. */
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `couldResolveBurdensInAdvance` field. */
  couldResolveBurdensInAdvance?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `determined` field. */
  determined?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `energetic` field. */
  energetic?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `enthusiastic` field. */
  enthusiastic?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `expectBurdenInTheNearFuture` field. */
  expectBurdenInTheNearFuture?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `expectedBurdenThatDidNotOccur` field. */
  expectedBurdenThatDidNotOccur?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `guilty` field. */
  guilty?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `hadAnyContactWithOthersSinceTheLastSurvey` field. */
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `happy` field. */
  happy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `happyDuringContact` field. */
  happyDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `helpless` field. */
  helpless?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `inspiredDuringContact` field. */
  inspiredDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lackOfSocialContactBothersMe` field. */
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lackOfSocialContactForExternalReasons` field. */
  lackOfSocialContactForExternalReasons?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lonely` field. */
  lonely?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lookedForAdviceAndComfortFromOthers` field. */
  lookedForAdviceAndComfortFromOthers?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `noBurdenForExternalReasons` field. */
  noBurdenForExternalReasons?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `plannedHowToProceed` field. */
  plannedHowToProceed?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `relaxed` field. */
  relaxed?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sad` field. */
  sad?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `satisfied` field. */
  satisfied?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `scheduledAt` field. */
  scheduledAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `smsReceivedAt` field. */
  smsReceivedAt?: InputMaybe<Scalars['Time']>;
  /** Checks for equality with the object’s `socialDistress` field. */
  socialDistress?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `strain` field. */
  strain?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `strength` field. */
  strength?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `stressed` field. */
  stressed?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `tenseDuringContact` field. */
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `testy` field. */
  testy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `testyDuringContact` field. */
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `trialId` field. */
  trialId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `triedToCalmMyselfDown` field. */
  triedToCalmMyselfDown?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToRepressMyThoughtsAndFeelings` field. */
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToThinkDifferently` field. */
  triedToThinkDifferently?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `typicalThatNoBurdenHaveOccured` field. */
  typicalThatNoBurdenHaveOccured?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unconfidentDuringContact` field. */
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `understoodDuringContact` field. */
  understoodDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `wave` field. */
  wave?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `wouldHaveLikedSocialContact` field. */
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntries3DistinctCountAggregateFilter = {
  angry?: InputMaybe<BigIntFilter>;
  anxious?: InputMaybe<BigIntFilter>;
  anxiousDuringContact?: InputMaybe<BigIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<BigIntFilter>;
  avoidedSocialContact?: InputMaybe<BigIntFilter>;
  burdens?: InputMaybe<BigIntFilter>;
  comfortableDuringContact?: InputMaybe<BigIntFilter>;
  completedAt?: InputMaybe<BigIntFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  determined?: InputMaybe<BigIntFilter>;
  energetic?: InputMaybe<BigIntFilter>;
  enthusiastic?: InputMaybe<BigIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<BigIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<BigIntFilter>;
  guilty?: InputMaybe<BigIntFilter>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<BigIntFilter>;
  happy?: InputMaybe<BigIntFilter>;
  happyDuringContact?: InputMaybe<BigIntFilter>;
  helpless?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  inspiredDuringContact?: InputMaybe<BigIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<BigIntFilter>;
  lonely?: InputMaybe<BigIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<BigIntFilter>;
  plannedHowToProceed?: InputMaybe<BigIntFilter>;
  relaxed?: InputMaybe<BigIntFilter>;
  sad?: InputMaybe<BigIntFilter>;
  satisfied?: InputMaybe<BigIntFilter>;
  scheduledAt?: InputMaybe<BigIntFilter>;
  smsReceivedAt?: InputMaybe<BigIntFilter>;
  socialDistress?: InputMaybe<BigIntFilter>;
  startedAt?: InputMaybe<BigIntFilter>;
  strain?: InputMaybe<BigIntFilter>;
  strength?: InputMaybe<BigIntFilter>;
  stressed?: InputMaybe<BigIntFilter>;
  tenseDuringContact?: InputMaybe<BigIntFilter>;
  testy?: InputMaybe<BigIntFilter>;
  testyDuringContact?: InputMaybe<BigIntFilter>;
  trialId?: InputMaybe<BigIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToThinkDifferently?: InputMaybe<BigIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<BigIntFilter>;
  unconfidentDuringContact?: InputMaybe<BigIntFilter>;
  understoodDuringContact?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  wave?: InputMaybe<BigIntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigIntFilter>;
};

export type EmaEntries3DistinctCountAggregates = {
  __typename?: 'EmaEntries3DistinctCountAggregates';
  /** Distinct count of angry across the matching connection */
  angry?: Maybe<Scalars['BigInt']>;
  /** Distinct count of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigInt']>;
  /** Distinct count of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of burdens across the matching connection */
  burdens?: Maybe<Scalars['BigInt']>;
  /** Distinct count of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of completedAt across the matching connection */
  completedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars['BigInt']>;
  /** Distinct count of determined across the matching connection */
  determined?: Maybe<Scalars['BigInt']>;
  /** Distinct count of energetic across the matching connection */
  energetic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of enthusiastic across the matching connection */
  enthusiastic?: Maybe<Scalars['BigInt']>;
  /** Distinct count of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture?: Maybe<Scalars['BigInt']>;
  /** Distinct count of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['BigInt']>;
  /** Distinct count of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hadAnyContactWithOthersSinceTheLastSurvey across the matching connection */
  hadAnyContactWithOthersSinceTheLastSurvey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of happy across the matching connection */
  happy?: Maybe<Scalars['BigInt']>;
  /** Distinct count of happyDuringContact across the matching connection */
  happyDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of helpless across the matching connection */
  helpless?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inspiredDuringContact across the matching connection */
  inspiredDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigInt']>;
  /** Distinct count of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons?: Maybe<Scalars['BigInt']>;
  /** Distinct count of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sad across the matching connection */
  sad?: Maybe<Scalars['BigInt']>;
  /** Distinct count of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduledAt across the matching connection */
  scheduledAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of smsReceivedAt across the matching connection */
  smsReceivedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of socialDistress across the matching connection */
  socialDistress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of strain across the matching connection */
  strain?: Maybe<Scalars['BigInt']>;
  /** Distinct count of strength across the matching connection */
  strength?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stressed across the matching connection */
  stressed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of testy across the matching connection */
  testy?: Maybe<Scalars['BigInt']>;
  /** Distinct count of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trialId across the matching connection */
  trialId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigInt']>;
  /** Distinct count of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of understoodDuringContact across the matching connection */
  understoodDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of wave across the matching connection */
  wave?: Maybe<Scalars['BigInt']>;
  /** Distinct count of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EmaEntries3` object types. All fields are combined with a logical ‘and.’ */
export type EmaEntries3Filter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmaEntries3Filter>>;
  /** Filter by the object’s `angry` field. */
  angry?: InputMaybe<IntFilter>;
  /** Filter by the object’s `anxious` field. */
  anxious?: InputMaybe<IntFilter>;
  /** Filter by the object’s `anxiousDuringContact` field. */
  anxiousDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `atThatMomentIFeltMoreComfortableAlone` field. */
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<IntFilter>;
  /** Filter by the object’s `avoidedSocialContact` field. */
  avoidedSocialContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `burdens` field. */
  burdens?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `comfortableDuringContact` field. */
  comfortableDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `couldResolveBurdensInAdvance` field. */
  couldResolveBurdensInAdvance?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `determined` field. */
  determined?: InputMaybe<IntFilter>;
  /** Filter by the object’s `energetic` field. */
  energetic?: InputMaybe<IntFilter>;
  /** Filter by the object’s `enthusiastic` field. */
  enthusiastic?: InputMaybe<IntFilter>;
  /** Filter by the object’s `expectBurdenInTheNearFuture` field. */
  expectBurdenInTheNearFuture?: InputMaybe<IntFilter>;
  /** Filter by the object’s `expectedBurdenThatDidNotOccur` field. */
  expectedBurdenThatDidNotOccur?: InputMaybe<IntFilter>;
  /** Filter by the object’s `guilty` field. */
  guilty?: InputMaybe<IntFilter>;
  /** Filter by the object’s `hadAnyContactWithOthersSinceTheLastSurvey` field. */
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `happy` field. */
  happy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `happyDuringContact` field. */
  happyDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `helpless` field. */
  helpless?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `inspiredDuringContact` field. */
  inspiredDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lackOfSocialContactBothersMe` field. */
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lackOfSocialContactForExternalReasons` field. */
  lackOfSocialContactForExternalReasons?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lonely` field. */
  lonely?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lookedForAdviceAndComfortFromOthers` field. */
  lookedForAdviceAndComfortFromOthers?: InputMaybe<IntFilter>;
  /** Filter by the object’s `noBurdenForExternalReasons` field. */
  noBurdenForExternalReasons?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmaEntries3Filter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmaEntries3Filter>>;
  /** Filter by the object’s `plannedHowToProceed` field. */
  plannedHowToProceed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `relaxed` field. */
  relaxed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sad` field. */
  sad?: InputMaybe<IntFilter>;
  /** Filter by the object’s `satisfied` field. */
  satisfied?: InputMaybe<IntFilter>;
  /** Filter by the object’s `scheduledAt` field. */
  scheduledAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `smsReceivedAt` field. */
  smsReceivedAt?: InputMaybe<TimeFilter>;
  /** Filter by the object’s `socialDistress` field. */
  socialDistress?: InputMaybe<IntFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `strain` field. */
  strain?: InputMaybe<IntFilter>;
  /** Filter by the object’s `strength` field. */
  strength?: InputMaybe<IntFilter>;
  /** Filter by the object’s `stressed` field. */
  stressed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tenseDuringContact` field. */
  tenseDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `testy` field. */
  testy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `testyDuringContact` field. */
  testyDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `trial` relation. */
  trial?: InputMaybe<TrialFilter>;
  /** Filter by the object’s `trialId` field. */
  trialId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `triedToCalmMyselfDown` field. */
  triedToCalmMyselfDown?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToRepressMyThoughtsAndFeelings` field. */
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToThinkDifferently` field. */
  triedToThinkDifferently?: InputMaybe<IntFilter>;
  /** Filter by the object’s `typicalThatNoBurdenHaveOccured` field. */
  typicalThatNoBurdenHaveOccured?: InputMaybe<IntFilter>;
  /** Filter by the object’s `unconfidentDuringContact` field. */
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `understoodDuringContact` field. */
  understoodDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `wave` field. */
  wave?: InputMaybe<StringFilter>;
  /** Filter by the object’s `wouldHaveLikedSocialContact` field. */
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

/** Grouping methods for `EmaEntries3` for usage during aggregation. */
export enum EmaEntries3GroupBy {
  Angry = 'ANGRY',
  Anxious = 'ANXIOUS',
  AnxiousDuringContact = 'ANXIOUS_DURING_CONTACT',
  AtThatMomentIFeltMoreComfortableAlone = 'AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE',
  AvoidedSocialContact = 'AVOIDED_SOCIAL_CONTACT',
  Burdens = 'BURDENS',
  ComfortableDuringContact = 'COMFORTABLE_DURING_CONTACT',
  CompletedAt = 'COMPLETED_AT',
  CompletedAtTruncatedToDay = 'COMPLETED_AT_TRUNCATED_TO_DAY',
  CompletedAtTruncatedToHour = 'COMPLETED_AT_TRUNCATED_TO_HOUR',
  CouldResolveBurdensInAdvance = 'COULD_RESOLVE_BURDENS_IN_ADVANCE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  Deleted = 'DELETED',
  Determined = 'DETERMINED',
  Energetic = 'ENERGETIC',
  Enthusiastic = 'ENTHUSIASTIC',
  ExpectedBurdenThatDidNotOccur = 'EXPECTED_BURDEN_THAT_DID_NOT_OCCUR',
  ExpectBurdenInTheNearFuture = 'EXPECT_BURDEN_IN_THE_NEAR_FUTURE',
  Guilty = 'GUILTY',
  HadAnyContactWithOthersSinceTheLastSurvey = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY',
  Happy = 'HAPPY',
  HappyDuringContact = 'HAPPY_DURING_CONTACT',
  Helpless = 'HELPLESS',
  InspiredDuringContact = 'INSPIRED_DURING_CONTACT',
  LackOfSocialContactBothersMe = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME',
  LackOfSocialContactForExternalReasons = 'LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS',
  Lonely = 'LONELY',
  LookedForAdviceAndComfortFromOthers = 'LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS',
  NoBurdenForExternalReasons = 'NO_BURDEN_FOR_EXTERNAL_REASONS',
  PlannedHowToProceed = 'PLANNED_HOW_TO_PROCEED',
  Relaxed = 'RELAXED',
  Sad = 'SAD',
  Satisfied = 'SATISFIED',
  ScheduledAt = 'SCHEDULED_AT',
  ScheduledAtTruncatedToDay = 'SCHEDULED_AT_TRUNCATED_TO_DAY',
  ScheduledAtTruncatedToHour = 'SCHEDULED_AT_TRUNCATED_TO_HOUR',
  SmsReceivedAt = 'SMS_RECEIVED_AT',
  SocialDistress = 'SOCIAL_DISTRESS',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  Strain = 'STRAIN',
  Strength = 'STRENGTH',
  Stressed = 'STRESSED',
  TenseDuringContact = 'TENSE_DURING_CONTACT',
  Testy = 'TESTY',
  TestyDuringContact = 'TESTY_DURING_CONTACT',
  TrialId = 'TRIAL_ID',
  TriedToCalmMyselfDown = 'TRIED_TO_CALM_MYSELF_DOWN',
  TriedToRepressMyThoughtsAndFeelings = 'TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS',
  TriedToThinkDifferently = 'TRIED_TO_THINK_DIFFERENTLY',
  TypicalThatNoBurdenHaveOccured = 'TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED',
  UnconfidentDuringContact = 'UNCONFIDENT_DURING_CONTACT',
  UnderstoodDuringContact = 'UNDERSTOOD_DURING_CONTACT',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  Wave = 'WAVE',
  WouldHaveLikedSocialContact = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT'
}

export type EmaEntries3HavingAverageInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries3HavingDistinctCountInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `EmaEntries3` aggregates. */
export type EmaEntries3HavingInput = {
  AND?: InputMaybe<Array<EmaEntries3HavingInput>>;
  OR?: InputMaybe<Array<EmaEntries3HavingInput>>;
  average?: InputMaybe<EmaEntries3HavingAverageInput>;
  distinctCount?: InputMaybe<EmaEntries3HavingDistinctCountInput>;
  max?: InputMaybe<EmaEntries3HavingMaxInput>;
  min?: InputMaybe<EmaEntries3HavingMinInput>;
  stddevPopulation?: InputMaybe<EmaEntries3HavingStddevPopulationInput>;
  stddevSample?: InputMaybe<EmaEntries3HavingStddevSampleInput>;
  sum?: InputMaybe<EmaEntries3HavingSumInput>;
  variancePopulation?: InputMaybe<EmaEntries3HavingVariancePopulationInput>;
  varianceSample?: InputMaybe<EmaEntries3HavingVarianceSampleInput>;
};

export type EmaEntries3HavingMaxInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries3HavingMinInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries3HavingStddevPopulationInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries3HavingStddevSampleInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries3HavingSumInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries3HavingVariancePopulationInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntries3HavingVarianceSampleInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determined?: InputMaybe<HavingIntFilter>;
  energetic?: InputMaybe<HavingIntFilter>;
  enthusiastic?: InputMaybe<HavingIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<HavingIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<HavingIntFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  happyDuringContact?: InputMaybe<HavingIntFilter>;
  helpless?: InputMaybe<HavingIntFilter>;
  inspiredDuringContact?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<HavingIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<HavingIntFilter>;
  plannedHowToProceed?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  socialDistress?: InputMaybe<HavingIntFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  strain?: InputMaybe<HavingIntFilter>;
  strength?: InputMaybe<HavingIntFilter>;
  stressed?: InputMaybe<HavingIntFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<HavingIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  understoodDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

/** An input for mutations affecting `EmaEntries3` */
export type EmaEntries3Input = {
  angry?: InputMaybe<Scalars['Int']>;
  anxious?: InputMaybe<Scalars['Int']>;
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<Scalars['Int']>;
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  burdens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Datetime']>;
  couldResolveBurdensInAdvance?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  determined?: InputMaybe<Scalars['Int']>;
  energetic?: InputMaybe<Scalars['Int']>;
  enthusiastic?: InputMaybe<Scalars['Int']>;
  expectBurdenInTheNearFuture?: InputMaybe<Scalars['Int']>;
  expectedBurdenThatDidNotOccur?: InputMaybe<Scalars['Int']>;
  guilty?: InputMaybe<Scalars['Int']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  happy?: InputMaybe<Scalars['Int']>;
  happyDuringContact?: InputMaybe<Scalars['Int']>;
  helpless?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  inspiredDuringContact?: InputMaybe<Scalars['Int']>;
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  lackOfSocialContactForExternalReasons?: InputMaybe<Scalars['Int']>;
  lonely?: InputMaybe<Scalars['Int']>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<Scalars['Int']>;
  noBurdenForExternalReasons?: InputMaybe<Scalars['Int']>;
  plannedHowToProceed?: InputMaybe<Scalars['Int']>;
  relaxed?: InputMaybe<Scalars['Int']>;
  sad?: InputMaybe<Scalars['Int']>;
  satisfied?: InputMaybe<Scalars['Int']>;
  scheduledAt: Scalars['Datetime'];
  smsReceivedAt?: InputMaybe<Scalars['Time']>;
  socialDistress?: InputMaybe<Scalars['Int']>;
  startedAt?: InputMaybe<Scalars['Datetime']>;
  strain?: InputMaybe<Scalars['Int']>;
  strength?: InputMaybe<Scalars['Int']>;
  stressed?: InputMaybe<Scalars['Int']>;
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  testy?: InputMaybe<Scalars['Int']>;
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  trialId: Scalars['UUID'];
  triedToCalmMyselfDown?: InputMaybe<Scalars['Int']>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToThinkDifferently?: InputMaybe<Scalars['Int']>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<Scalars['Int']>;
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  understoodDuringContact?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  wave?: InputMaybe<Scalars['String']>;
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntries3MaxAggregateFilter = {
  angry?: InputMaybe<IntFilter>;
  anxious?: InputMaybe<IntFilter>;
  anxiousDuringContact?: InputMaybe<IntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<IntFilter>;
  avoidedSocialContact?: InputMaybe<IntFilter>;
  comfortableDuringContact?: InputMaybe<IntFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<IntFilter>;
  determined?: InputMaybe<IntFilter>;
  energetic?: InputMaybe<IntFilter>;
  enthusiastic?: InputMaybe<IntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<IntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<IntFilter>;
  guilty?: InputMaybe<IntFilter>;
  happy?: InputMaybe<IntFilter>;
  happyDuringContact?: InputMaybe<IntFilter>;
  helpless?: InputMaybe<IntFilter>;
  inspiredDuringContact?: InputMaybe<IntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<IntFilter>;
  lonely?: InputMaybe<IntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<IntFilter>;
  noBurdenForExternalReasons?: InputMaybe<IntFilter>;
  plannedHowToProceed?: InputMaybe<IntFilter>;
  relaxed?: InputMaybe<IntFilter>;
  sad?: InputMaybe<IntFilter>;
  satisfied?: InputMaybe<IntFilter>;
  socialDistress?: InputMaybe<IntFilter>;
  strain?: InputMaybe<IntFilter>;
  strength?: InputMaybe<IntFilter>;
  stressed?: InputMaybe<IntFilter>;
  tenseDuringContact?: InputMaybe<IntFilter>;
  testy?: InputMaybe<IntFilter>;
  testyDuringContact?: InputMaybe<IntFilter>;
  triedToCalmMyselfDown?: InputMaybe<IntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToThinkDifferently?: InputMaybe<IntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<IntFilter>;
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  understoodDuringContact?: InputMaybe<IntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

export type EmaEntries3MaxAggregates = {
  __typename?: 'EmaEntries3MaxAggregates';
  /** Maximum of angry across the matching connection */
  angry?: Maybe<Scalars['Int']>;
  /** Maximum of anxious across the matching connection */
  anxious?: Maybe<Scalars['Int']>;
  /** Maximum of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['Int']>;
  /** Maximum of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  /** Maximum of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance?: Maybe<Scalars['Int']>;
  /** Maximum of determined across the matching connection */
  determined?: Maybe<Scalars['Int']>;
  /** Maximum of energetic across the matching connection */
  energetic?: Maybe<Scalars['Int']>;
  /** Maximum of enthusiastic across the matching connection */
  enthusiastic?: Maybe<Scalars['Int']>;
  /** Maximum of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture?: Maybe<Scalars['Int']>;
  /** Maximum of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['Int']>;
  /** Maximum of guilty across the matching connection */
  guilty?: Maybe<Scalars['Int']>;
  /** Maximum of happy across the matching connection */
  happy?: Maybe<Scalars['Int']>;
  /** Maximum of happyDuringContact across the matching connection */
  happyDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of helpless across the matching connection */
  helpless?: Maybe<Scalars['Int']>;
  /** Maximum of inspiredDuringContact across the matching connection */
  inspiredDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  /** Maximum of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['Int']>;
  /** Maximum of lonely across the matching connection */
  lonely?: Maybe<Scalars['Int']>;
  /** Maximum of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['Int']>;
  /** Maximum of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons?: Maybe<Scalars['Int']>;
  /** Maximum of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['Int']>;
  /** Maximum of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['Int']>;
  /** Maximum of sad across the matching connection */
  sad?: Maybe<Scalars['Int']>;
  /** Maximum of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['Int']>;
  /** Maximum of socialDistress across the matching connection */
  socialDistress?: Maybe<Scalars['Int']>;
  /** Maximum of strain across the matching connection */
  strain?: Maybe<Scalars['Int']>;
  /** Maximum of strength across the matching connection */
  strength?: Maybe<Scalars['Int']>;
  /** Maximum of stressed across the matching connection */
  stressed?: Maybe<Scalars['Int']>;
  /** Maximum of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of testy across the matching connection */
  testy?: Maybe<Scalars['Int']>;
  /** Maximum of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['Int']>;
  /** Maximum of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Maximum of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['Int']>;
  /** Maximum of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['Int']>;
  /** Maximum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of understoodDuringContact across the matching connection */
  understoodDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

export type EmaEntries3MinAggregateFilter = {
  angry?: InputMaybe<IntFilter>;
  anxious?: InputMaybe<IntFilter>;
  anxiousDuringContact?: InputMaybe<IntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<IntFilter>;
  avoidedSocialContact?: InputMaybe<IntFilter>;
  comfortableDuringContact?: InputMaybe<IntFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<IntFilter>;
  determined?: InputMaybe<IntFilter>;
  energetic?: InputMaybe<IntFilter>;
  enthusiastic?: InputMaybe<IntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<IntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<IntFilter>;
  guilty?: InputMaybe<IntFilter>;
  happy?: InputMaybe<IntFilter>;
  happyDuringContact?: InputMaybe<IntFilter>;
  helpless?: InputMaybe<IntFilter>;
  inspiredDuringContact?: InputMaybe<IntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<IntFilter>;
  lonely?: InputMaybe<IntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<IntFilter>;
  noBurdenForExternalReasons?: InputMaybe<IntFilter>;
  plannedHowToProceed?: InputMaybe<IntFilter>;
  relaxed?: InputMaybe<IntFilter>;
  sad?: InputMaybe<IntFilter>;
  satisfied?: InputMaybe<IntFilter>;
  socialDistress?: InputMaybe<IntFilter>;
  strain?: InputMaybe<IntFilter>;
  strength?: InputMaybe<IntFilter>;
  stressed?: InputMaybe<IntFilter>;
  tenseDuringContact?: InputMaybe<IntFilter>;
  testy?: InputMaybe<IntFilter>;
  testyDuringContact?: InputMaybe<IntFilter>;
  triedToCalmMyselfDown?: InputMaybe<IntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<IntFilter>;
  triedToThinkDifferently?: InputMaybe<IntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<IntFilter>;
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  understoodDuringContact?: InputMaybe<IntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

export type EmaEntries3MinAggregates = {
  __typename?: 'EmaEntries3MinAggregates';
  /** Minimum of angry across the matching connection */
  angry?: Maybe<Scalars['Int']>;
  /** Minimum of anxious across the matching connection */
  anxious?: Maybe<Scalars['Int']>;
  /** Minimum of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['Int']>;
  /** Minimum of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  /** Minimum of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance?: Maybe<Scalars['Int']>;
  /** Minimum of determined across the matching connection */
  determined?: Maybe<Scalars['Int']>;
  /** Minimum of energetic across the matching connection */
  energetic?: Maybe<Scalars['Int']>;
  /** Minimum of enthusiastic across the matching connection */
  enthusiastic?: Maybe<Scalars['Int']>;
  /** Minimum of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture?: Maybe<Scalars['Int']>;
  /** Minimum of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['Int']>;
  /** Minimum of guilty across the matching connection */
  guilty?: Maybe<Scalars['Int']>;
  /** Minimum of happy across the matching connection */
  happy?: Maybe<Scalars['Int']>;
  /** Minimum of happyDuringContact across the matching connection */
  happyDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of helpless across the matching connection */
  helpless?: Maybe<Scalars['Int']>;
  /** Minimum of inspiredDuringContact across the matching connection */
  inspiredDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  /** Minimum of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['Int']>;
  /** Minimum of lonely across the matching connection */
  lonely?: Maybe<Scalars['Int']>;
  /** Minimum of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['Int']>;
  /** Minimum of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons?: Maybe<Scalars['Int']>;
  /** Minimum of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['Int']>;
  /** Minimum of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['Int']>;
  /** Minimum of sad across the matching connection */
  sad?: Maybe<Scalars['Int']>;
  /** Minimum of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['Int']>;
  /** Minimum of socialDistress across the matching connection */
  socialDistress?: Maybe<Scalars['Int']>;
  /** Minimum of strain across the matching connection */
  strain?: Maybe<Scalars['Int']>;
  /** Minimum of strength across the matching connection */
  strength?: Maybe<Scalars['Int']>;
  /** Minimum of stressed across the matching connection */
  stressed?: Maybe<Scalars['Int']>;
  /** Minimum of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of testy across the matching connection */
  testy?: Maybe<Scalars['Int']>;
  /** Minimum of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['Int']>;
  /** Minimum of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['Int']>;
  /** Minimum of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['Int']>;
  /** Minimum of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['Int']>;
  /** Minimum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of understoodDuringContact across the matching connection */
  understoodDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EmaEntries3`. Fields that are set will be updated. */
export type EmaEntries3Patch = {
  angry?: InputMaybe<Scalars['Int']>;
  anxious?: InputMaybe<Scalars['Int']>;
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<Scalars['Int']>;
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  burdens?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Datetime']>;
  couldResolveBurdensInAdvance?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  determined?: InputMaybe<Scalars['Int']>;
  energetic?: InputMaybe<Scalars['Int']>;
  enthusiastic?: InputMaybe<Scalars['Int']>;
  expectBurdenInTheNearFuture?: InputMaybe<Scalars['Int']>;
  expectedBurdenThatDidNotOccur?: InputMaybe<Scalars['Int']>;
  guilty?: InputMaybe<Scalars['Int']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  happy?: InputMaybe<Scalars['Int']>;
  happyDuringContact?: InputMaybe<Scalars['Int']>;
  helpless?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  inspiredDuringContact?: InputMaybe<Scalars['Int']>;
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  lackOfSocialContactForExternalReasons?: InputMaybe<Scalars['Int']>;
  lonely?: InputMaybe<Scalars['Int']>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<Scalars['Int']>;
  noBurdenForExternalReasons?: InputMaybe<Scalars['Int']>;
  plannedHowToProceed?: InputMaybe<Scalars['Int']>;
  relaxed?: InputMaybe<Scalars['Int']>;
  sad?: InputMaybe<Scalars['Int']>;
  satisfied?: InputMaybe<Scalars['Int']>;
  scheduledAt?: InputMaybe<Scalars['Datetime']>;
  smsReceivedAt?: InputMaybe<Scalars['Time']>;
  socialDistress?: InputMaybe<Scalars['Int']>;
  startedAt?: InputMaybe<Scalars['Datetime']>;
  strain?: InputMaybe<Scalars['Int']>;
  strength?: InputMaybe<Scalars['Int']>;
  stressed?: InputMaybe<Scalars['Int']>;
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  testy?: InputMaybe<Scalars['Int']>;
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  trialId?: InputMaybe<Scalars['UUID']>;
  triedToCalmMyselfDown?: InputMaybe<Scalars['Int']>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<Scalars['Int']>;
  triedToThinkDifferently?: InputMaybe<Scalars['Int']>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<Scalars['Int']>;
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  understoodDuringContact?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  wave?: InputMaybe<Scalars['String']>;
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntries3StddevPopulationAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<BigFloatFilter>;
  determined?: InputMaybe<BigFloatFilter>;
  energetic?: InputMaybe<BigFloatFilter>;
  enthusiastic?: InputMaybe<BigFloatFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<BigFloatFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  happyDuringContact?: InputMaybe<BigFloatFilter>;
  helpless?: InputMaybe<BigFloatFilter>;
  inspiredDuringContact?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noBurdenForExternalReasons?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  socialDistress?: InputMaybe<BigFloatFilter>;
  strain?: InputMaybe<BigFloatFilter>;
  strength?: InputMaybe<BigFloatFilter>;
  stressed?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  understoodDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries3StddevPopulationAggregates = {
  __typename?: 'EmaEntries3StddevPopulationAggregates';
  /** Population standard deviation of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of determined across the matching connection */
  determined?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of energetic across the matching connection */
  energetic?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of enthusiastic across the matching connection */
  enthusiastic?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of happyDuringContact across the matching connection */
  happyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of helpless across the matching connection */
  helpless?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of inspiredDuringContact across the matching connection */
  inspiredDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of socialDistress across the matching connection */
  socialDistress?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of strain across the matching connection */
  strain?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of strength across the matching connection */
  strength?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of stressed across the matching connection */
  stressed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of understoodDuringContact across the matching connection */
  understoodDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntries3StddevSampleAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<BigFloatFilter>;
  determined?: InputMaybe<BigFloatFilter>;
  energetic?: InputMaybe<BigFloatFilter>;
  enthusiastic?: InputMaybe<BigFloatFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<BigFloatFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  happyDuringContact?: InputMaybe<BigFloatFilter>;
  helpless?: InputMaybe<BigFloatFilter>;
  inspiredDuringContact?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noBurdenForExternalReasons?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  socialDistress?: InputMaybe<BigFloatFilter>;
  strain?: InputMaybe<BigFloatFilter>;
  strength?: InputMaybe<BigFloatFilter>;
  stressed?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  understoodDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries3StddevSampleAggregates = {
  __typename?: 'EmaEntries3StddevSampleAggregates';
  /** Sample standard deviation of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of determined across the matching connection */
  determined?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of energetic across the matching connection */
  energetic?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of enthusiastic across the matching connection */
  enthusiastic?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of happyDuringContact across the matching connection */
  happyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of helpless across the matching connection */
  helpless?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of inspiredDuringContact across the matching connection */
  inspiredDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of socialDistress across the matching connection */
  socialDistress?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of strain across the matching connection */
  strain?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of strength across the matching connection */
  strength?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of stressed across the matching connection */
  stressed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of understoodDuringContact across the matching connection */
  understoodDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntries3SumAggregateFilter = {
  angry?: InputMaybe<BigIntFilter>;
  anxious?: InputMaybe<BigIntFilter>;
  anxiousDuringContact?: InputMaybe<BigIntFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<BigIntFilter>;
  avoidedSocialContact?: InputMaybe<BigIntFilter>;
  comfortableDuringContact?: InputMaybe<BigIntFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<BigIntFilter>;
  determined?: InputMaybe<BigIntFilter>;
  energetic?: InputMaybe<BigIntFilter>;
  enthusiastic?: InputMaybe<BigIntFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<BigIntFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<BigIntFilter>;
  guilty?: InputMaybe<BigIntFilter>;
  happy?: InputMaybe<BigIntFilter>;
  happyDuringContact?: InputMaybe<BigIntFilter>;
  helpless?: InputMaybe<BigIntFilter>;
  inspiredDuringContact?: InputMaybe<BigIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigIntFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<BigIntFilter>;
  lonely?: InputMaybe<BigIntFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigIntFilter>;
  noBurdenForExternalReasons?: InputMaybe<BigIntFilter>;
  plannedHowToProceed?: InputMaybe<BigIntFilter>;
  relaxed?: InputMaybe<BigIntFilter>;
  sad?: InputMaybe<BigIntFilter>;
  satisfied?: InputMaybe<BigIntFilter>;
  socialDistress?: InputMaybe<BigIntFilter>;
  strain?: InputMaybe<BigIntFilter>;
  strength?: InputMaybe<BigIntFilter>;
  stressed?: InputMaybe<BigIntFilter>;
  tenseDuringContact?: InputMaybe<BigIntFilter>;
  testy?: InputMaybe<BigIntFilter>;
  testyDuringContact?: InputMaybe<BigIntFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigIntFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigIntFilter>;
  triedToThinkDifferently?: InputMaybe<BigIntFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<BigIntFilter>;
  unconfidentDuringContact?: InputMaybe<BigIntFilter>;
  understoodDuringContact?: InputMaybe<BigIntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigIntFilter>;
};

export type EmaEntries3SumAggregates = {
  __typename?: 'EmaEntries3SumAggregates';
  /** Sum of angry across the matching connection */
  angry: Scalars['BigInt'];
  /** Sum of anxious across the matching connection */
  anxious: Scalars['BigInt'];
  /** Sum of anxiousDuringContact across the matching connection */
  anxiousDuringContact: Scalars['BigInt'];
  /** Sum of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone: Scalars['BigInt'];
  /** Sum of avoidedSocialContact across the matching connection */
  avoidedSocialContact: Scalars['BigInt'];
  /** Sum of comfortableDuringContact across the matching connection */
  comfortableDuringContact: Scalars['BigInt'];
  /** Sum of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance: Scalars['BigInt'];
  /** Sum of determined across the matching connection */
  determined: Scalars['BigInt'];
  /** Sum of energetic across the matching connection */
  energetic: Scalars['BigInt'];
  /** Sum of enthusiastic across the matching connection */
  enthusiastic: Scalars['BigInt'];
  /** Sum of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture: Scalars['BigInt'];
  /** Sum of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur: Scalars['BigInt'];
  /** Sum of guilty across the matching connection */
  guilty: Scalars['BigInt'];
  /** Sum of happy across the matching connection */
  happy: Scalars['BigInt'];
  /** Sum of happyDuringContact across the matching connection */
  happyDuringContact: Scalars['BigInt'];
  /** Sum of helpless across the matching connection */
  helpless: Scalars['BigInt'];
  /** Sum of inspiredDuringContact across the matching connection */
  inspiredDuringContact: Scalars['BigInt'];
  /** Sum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe: Scalars['BigInt'];
  /** Sum of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons: Scalars['BigInt'];
  /** Sum of lonely across the matching connection */
  lonely: Scalars['BigInt'];
  /** Sum of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers: Scalars['BigInt'];
  /** Sum of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons: Scalars['BigInt'];
  /** Sum of plannedHowToProceed across the matching connection */
  plannedHowToProceed: Scalars['BigInt'];
  /** Sum of relaxed across the matching connection */
  relaxed: Scalars['BigInt'];
  /** Sum of sad across the matching connection */
  sad: Scalars['BigInt'];
  /** Sum of satisfied across the matching connection */
  satisfied: Scalars['BigInt'];
  /** Sum of socialDistress across the matching connection */
  socialDistress: Scalars['BigInt'];
  /** Sum of strain across the matching connection */
  strain: Scalars['BigInt'];
  /** Sum of strength across the matching connection */
  strength: Scalars['BigInt'];
  /** Sum of stressed across the matching connection */
  stressed: Scalars['BigInt'];
  /** Sum of tenseDuringContact across the matching connection */
  tenseDuringContact: Scalars['BigInt'];
  /** Sum of testy across the matching connection */
  testy: Scalars['BigInt'];
  /** Sum of testyDuringContact across the matching connection */
  testyDuringContact: Scalars['BigInt'];
  /** Sum of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown: Scalars['BigInt'];
  /** Sum of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings: Scalars['BigInt'];
  /** Sum of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently: Scalars['BigInt'];
  /** Sum of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured: Scalars['BigInt'];
  /** Sum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact: Scalars['BigInt'];
  /** Sum of understoodDuringContact across the matching connection */
  understoodDuringContact: Scalars['BigInt'];
  /** Sum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact: Scalars['BigInt'];
};

export type EmaEntries3VariancePopulationAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<BigFloatFilter>;
  determined?: InputMaybe<BigFloatFilter>;
  energetic?: InputMaybe<BigFloatFilter>;
  enthusiastic?: InputMaybe<BigFloatFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<BigFloatFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  happyDuringContact?: InputMaybe<BigFloatFilter>;
  helpless?: InputMaybe<BigFloatFilter>;
  inspiredDuringContact?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noBurdenForExternalReasons?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  socialDistress?: InputMaybe<BigFloatFilter>;
  strain?: InputMaybe<BigFloatFilter>;
  strength?: InputMaybe<BigFloatFilter>;
  stressed?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  understoodDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries3VariancePopulationAggregates = {
  __typename?: 'EmaEntries3VariancePopulationAggregates';
  /** Population variance of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Population variance of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Population variance of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['BigFloat']>;
  /** Population variance of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance?: Maybe<Scalars['BigFloat']>;
  /** Population variance of determined across the matching connection */
  determined?: Maybe<Scalars['BigFloat']>;
  /** Population variance of energetic across the matching connection */
  energetic?: Maybe<Scalars['BigFloat']>;
  /** Population variance of enthusiastic across the matching connection */
  enthusiastic?: Maybe<Scalars['BigFloat']>;
  /** Population variance of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture?: Maybe<Scalars['BigFloat']>;
  /** Population variance of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['BigFloat']>;
  /** Population variance of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Population variance of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Population variance of happyDuringContact across the matching connection */
  happyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of helpless across the matching connection */
  helpless?: Maybe<Scalars['BigFloat']>;
  /** Population variance of inspiredDuringContact across the matching connection */
  inspiredDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Population variance of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Population variance of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Population variance of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Population variance of socialDistress across the matching connection */
  socialDistress?: Maybe<Scalars['BigFloat']>;
  /** Population variance of strain across the matching connection */
  strain?: Maybe<Scalars['BigFloat']>;
  /** Population variance of strength across the matching connection */
  strength?: Maybe<Scalars['BigFloat']>;
  /** Population variance of stressed across the matching connection */
  stressed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Population variance of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Population variance of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['BigFloat']>;
  /** Population variance of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of understoodDuringContact across the matching connection */
  understoodDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntries3VarianceSampleAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  atThatMomentIFeltMoreComfortableAlone?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  couldResolveBurdensInAdvance?: InputMaybe<BigFloatFilter>;
  determined?: InputMaybe<BigFloatFilter>;
  energetic?: InputMaybe<BigFloatFilter>;
  enthusiastic?: InputMaybe<BigFloatFilter>;
  expectBurdenInTheNearFuture?: InputMaybe<BigFloatFilter>;
  expectedBurdenThatDidNotOccur?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  happyDuringContact?: InputMaybe<BigFloatFilter>;
  helpless?: InputMaybe<BigFloatFilter>;
  inspiredDuringContact?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactForExternalReasons?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  lookedForAdviceAndComfortFromOthers?: InputMaybe<BigFloatFilter>;
  noBurdenForExternalReasons?: InputMaybe<BigFloatFilter>;
  plannedHowToProceed?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  socialDistress?: InputMaybe<BigFloatFilter>;
  strain?: InputMaybe<BigFloatFilter>;
  strength?: InputMaybe<BigFloatFilter>;
  stressed?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedToCalmMyselfDown?: InputMaybe<BigFloatFilter>;
  triedToRepressMyThoughtsAndFeelings?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  typicalThatNoBurdenHaveOccured?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  understoodDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntries3VarianceSampleAggregates = {
  __typename?: 'EmaEntries3VarianceSampleAggregates';
  /** Sample variance of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of atThatMomentIFeltMoreComfortableAlone across the matching connection */
  atThatMomentIFeltMoreComfortableAlone?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of couldResolveBurdensInAdvance across the matching connection */
  couldResolveBurdensInAdvance?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of determined across the matching connection */
  determined?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of energetic across the matching connection */
  energetic?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of enthusiastic across the matching connection */
  enthusiastic?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of expectBurdenInTheNearFuture across the matching connection */
  expectBurdenInTheNearFuture?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of expectedBurdenThatDidNotOccur across the matching connection */
  expectedBurdenThatDidNotOccur?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of happyDuringContact across the matching connection */
  happyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of helpless across the matching connection */
  helpless?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of inspiredDuringContact across the matching connection */
  inspiredDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lackOfSocialContactForExternalReasons across the matching connection */
  lackOfSocialContactForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lookedForAdviceAndComfortFromOthers across the matching connection */
  lookedForAdviceAndComfortFromOthers?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of noBurdenForExternalReasons across the matching connection */
  noBurdenForExternalReasons?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of plannedHowToProceed across the matching connection */
  plannedHowToProceed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of socialDistress across the matching connection */
  socialDistress?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of strain across the matching connection */
  strain?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of strength across the matching connection */
  strength?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of stressed across the matching connection */
  stressed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToCalmMyselfDown across the matching connection */
  triedToCalmMyselfDown?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToRepressMyThoughtsAndFeelings across the matching connection */
  triedToRepressMyThoughtsAndFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of typicalThatNoBurdenHaveOccured across the matching connection */
  typicalThatNoBurdenHaveOccured?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of understoodDuringContact across the matching connection */
  understoodDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `EmaEntries3` values. */
export type EmaEntries3sConnection = {
  __typename?: 'EmaEntries3sConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmaEntries3Aggregates>;
  /** A list of edges which contains the `EmaEntries3` and cursor to aid in pagination. */
  edges: Array<EmaEntries3sEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmaEntries3Aggregates>>;
  /** A list of `EmaEntries3` objects. */
  nodes: Array<EmaEntries3>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmaEntries3` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `EmaEntries3` values. */
export type EmaEntries3sConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmaEntries3GroupBy>;
  having?: InputMaybe<EmaEntries3HavingInput>;
};

/** A `EmaEntries3` edge in the connection. */
export type EmaEntries3sEdge = {
  __typename?: 'EmaEntries3sEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EmaEntries3` at the end of the edge. */
  node: EmaEntries3;
};

/** Methods to use when ordering `EmaEntries3`. */
export enum EmaEntries3sOrderBy {
  AngryAsc = 'ANGRY_ASC',
  AngryDesc = 'ANGRY_DESC',
  AnxiousAsc = 'ANXIOUS_ASC',
  AnxiousDesc = 'ANXIOUS_DESC',
  AnxiousDuringContactAsc = 'ANXIOUS_DURING_CONTACT_ASC',
  AnxiousDuringContactDesc = 'ANXIOUS_DURING_CONTACT_DESC',
  AtThatMomentIFeltMoreComfortableAloneAsc = 'AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  AtThatMomentIFeltMoreComfortableAloneDesc = 'AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  AvoidedSocialContactAsc = 'AVOIDED_SOCIAL_CONTACT_ASC',
  AvoidedSocialContactDesc = 'AVOIDED_SOCIAL_CONTACT_DESC',
  BurdensAsc = 'BURDENS_ASC',
  BurdensDesc = 'BURDENS_DESC',
  ComfortableDuringContactAsc = 'COMFORTABLE_DURING_CONTACT_ASC',
  ComfortableDuringContactDesc = 'COMFORTABLE_DURING_CONTACT_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CouldResolveBurdensInAdvanceAsc = 'COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  CouldResolveBurdensInAdvanceDesc = 'COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  DeterminedAsc = 'DETERMINED_ASC',
  DeterminedDesc = 'DETERMINED_DESC',
  EnergeticAsc = 'ENERGETIC_ASC',
  EnergeticDesc = 'ENERGETIC_DESC',
  EnthusiasticAsc = 'ENTHUSIASTIC_ASC',
  EnthusiasticDesc = 'ENTHUSIASTIC_DESC',
  ExpectedBurdenThatDidNotOccurAsc = 'EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  ExpectedBurdenThatDidNotOccurDesc = 'EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  ExpectBurdenInTheNearFutureAsc = 'EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  ExpectBurdenInTheNearFutureDesc = 'EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  GuiltyAsc = 'GUILTY_ASC',
  GuiltyDesc = 'GUILTY_DESC',
  HadAnyContactWithOthersSinceTheLastSurveyAsc = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  HadAnyContactWithOthersSinceTheLastSurveyDesc = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  HappyAsc = 'HAPPY_ASC',
  HappyDesc = 'HAPPY_DESC',
  HappyDuringContactAsc = 'HAPPY_DURING_CONTACT_ASC',
  HappyDuringContactDesc = 'HAPPY_DURING_CONTACT_DESC',
  HelplessAsc = 'HELPLESS_ASC',
  HelplessDesc = 'HELPLESS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InspiredDuringContactAsc = 'INSPIRED_DURING_CONTACT_ASC',
  InspiredDuringContactDesc = 'INSPIRED_DURING_CONTACT_DESC',
  LackOfSocialContactBothersMeAsc = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  LackOfSocialContactBothersMeDesc = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  LackOfSocialContactForExternalReasonsAsc = 'LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  LackOfSocialContactForExternalReasonsDesc = 'LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  LonelyAsc = 'LONELY_ASC',
  LonelyDesc = 'LONELY_DESC',
  LookedForAdviceAndComfortFromOthersAsc = 'LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  LookedForAdviceAndComfortFromOthersDesc = 'LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  Natural = 'NATURAL',
  NoBurdenForExternalReasonsAsc = 'NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  NoBurdenForExternalReasonsDesc = 'NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  PlannedHowToProceedAsc = 'PLANNED_HOW_TO_PROCEED_ASC',
  PlannedHowToProceedDesc = 'PLANNED_HOW_TO_PROCEED_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RelaxedAsc = 'RELAXED_ASC',
  RelaxedDesc = 'RELAXED_DESC',
  SadAsc = 'SAD_ASC',
  SadDesc = 'SAD_DESC',
  SatisfiedAsc = 'SATISFIED_ASC',
  SatisfiedDesc = 'SATISFIED_DESC',
  ScheduledAtAsc = 'SCHEDULED_AT_ASC',
  ScheduledAtDesc = 'SCHEDULED_AT_DESC',
  SmsReceivedAtAsc = 'SMS_RECEIVED_AT_ASC',
  SmsReceivedAtDesc = 'SMS_RECEIVED_AT_DESC',
  SocialDistressAsc = 'SOCIAL_DISTRESS_ASC',
  SocialDistressDesc = 'SOCIAL_DISTRESS_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC',
  StrainAsc = 'STRAIN_ASC',
  StrainDesc = 'STRAIN_DESC',
  StrengthAsc = 'STRENGTH_ASC',
  StrengthDesc = 'STRENGTH_DESC',
  StressedAsc = 'STRESSED_ASC',
  StressedDesc = 'STRESSED_DESC',
  TenseDuringContactAsc = 'TENSE_DURING_CONTACT_ASC',
  TenseDuringContactDesc = 'TENSE_DURING_CONTACT_DESC',
  TestyAsc = 'TESTY_ASC',
  TestyDesc = 'TESTY_DESC',
  TestyDuringContactAsc = 'TESTY_DURING_CONTACT_ASC',
  TestyDuringContactDesc = 'TESTY_DURING_CONTACT_DESC',
  TrialIdAsc = 'TRIAL_ID_ASC',
  TrialIdDesc = 'TRIAL_ID_DESC',
  TriedToCalmMyselfDownAsc = 'TRIED_TO_CALM_MYSELF_DOWN_ASC',
  TriedToCalmMyselfDownDesc = 'TRIED_TO_CALM_MYSELF_DOWN_DESC',
  TriedToRepressMyThoughtsAndFeelingsAsc = 'TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  TriedToRepressMyThoughtsAndFeelingsDesc = 'TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  TriedToThinkDifferentlyAsc = 'TRIED_TO_THINK_DIFFERENTLY_ASC',
  TriedToThinkDifferentlyDesc = 'TRIED_TO_THINK_DIFFERENTLY_DESC',
  TypicalThatNoBurdenHaveOccuredAsc = 'TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  TypicalThatNoBurdenHaveOccuredDesc = 'TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  UnconfidentDuringContactAsc = 'UNCONFIDENT_DURING_CONTACT_ASC',
  UnconfidentDuringContactDesc = 'UNCONFIDENT_DURING_CONTACT_DESC',
  UnderstoodDuringContactAsc = 'UNDERSTOOD_DURING_CONTACT_ASC',
  UnderstoodDuringContactDesc = 'UNDERSTOOD_DURING_CONTACT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WaveAsc = 'WAVE_ASC',
  WaveDesc = 'WAVE_DESC',
  WouldHaveLikedSocialContactAsc = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  WouldHaveLikedSocialContactDesc = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC'
}

/** A connection to a list of `EmaEntry` values. */
export type EmaEntriesConnection = {
  __typename?: 'EmaEntriesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<EmaEntryAggregates>;
  /** A list of edges which contains the `EmaEntry` and cursor to aid in pagination. */
  edges: Array<EmaEntriesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<EmaEntryAggregates>>;
  /** A list of `EmaEntry` objects. */
  nodes: Array<EmaEntry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmaEntry` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `EmaEntry` values. */
export type EmaEntriesConnectionGroupedAggregatesArgs = {
  groupBy: Array<EmaEntriesGroupBy>;
  having?: InputMaybe<EmaEntriesHavingInput>;
};

/** A `EmaEntry` edge in the connection. */
export type EmaEntriesEdge = {
  __typename?: 'EmaEntriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EmaEntry` at the end of the edge. */
  node: EmaEntry;
};

/** Grouping methods for `EmaEntry` for usage during aggregation. */
export enum EmaEntriesGroupBy {
  Angry = 'ANGRY',
  Anxious = 'ANXIOUS',
  AnxiousDuringContact = 'ANXIOUS_DURING_CONTACT',
  AvoidedSocialContact = 'AVOIDED_SOCIAL_CONTACT',
  ComfortableDuringContact = 'COMFORTABLE_DURING_CONTACT',
  CompletedAt = 'COMPLETED_AT',
  CompletedAtTruncatedToDay = 'COMPLETED_AT_TRUNCATED_TO_DAY',
  CompletedAtTruncatedToHour = 'COMPLETED_AT_TRUNCATED_TO_HOUR',
  CopingHelpedInTheSituation = 'COPING_HELPED_IN_THE_SITUATION',
  CouldThinkOfNothingElseThanTheSitation = 'COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  Deleted = 'DELETED',
  Guilty = 'GUILTY',
  HadAnyContactWithOthersSinceTheLastSurvey = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY',
  HadAStressfulSituation = 'HAD_A_STRESSFUL_SITUATION',
  Happy = 'HAPPY',
  LackOfSocialContactBothersMe = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME',
  Lonely = 'LONELY',
  NoSocialContactDueToOuterConditions = 'NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS',
  Relaxed = 'RELAXED',
  Sad = 'SAD',
  Satisfied = 'SATISFIED',
  ScheduledAt = 'SCHEDULED_AT',
  ScheduledAtTruncatedToDay = 'SCHEDULED_AT_TRUNCATED_TO_DAY',
  ScheduledAtTruncatedToHour = 'SCHEDULED_AT_TRUNCATED_TO_HOUR',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  SubjectId = 'SUBJECT_ID',
  TenseDuringContact = 'TENSE_DURING_CONTACT',
  Testy = 'TESTY',
  TestyDuringContact = 'TESTY_DURING_CONTACT',
  TriedNotToThinkOfTheSitation = 'TRIED_NOT_TO_THINK_OF_THE_SITATION',
  TriedSomethingElseToCopeWithTheSituation = 'TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION',
  TriedToAcceptTheSituation = 'TRIED_TO_ACCEPT_THE_SITUATION',
  TriedToAlterFeelings = 'TRIED_TO_ALTER_FEELINGS',
  TriedToCalmMyself = 'TRIED_TO_CALM_MYSELF',
  TriedToChangeTheSituation = 'TRIED_TO_CHANGE_THE_SITUATION',
  TriedToFindASolutionForTheProblem = 'TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM',
  TriedToFindTheCauseOfTheProblem = 'TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM',
  TriedToThinkDifferently = 'TRIED_TO_THINK_DIFFERENTLY',
  UnconfidentDuringContact = 'UNCONFIDENT_DURING_CONTACT',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  WouldHaveLikedSocialContact = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT'
}

export type EmaEntriesHavingAverageInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntriesHavingDistinctCountInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `EmaEntry` aggregates. */
export type EmaEntriesHavingInput = {
  AND?: InputMaybe<Array<EmaEntriesHavingInput>>;
  OR?: InputMaybe<Array<EmaEntriesHavingInput>>;
  average?: InputMaybe<EmaEntriesHavingAverageInput>;
  distinctCount?: InputMaybe<EmaEntriesHavingDistinctCountInput>;
  max?: InputMaybe<EmaEntriesHavingMaxInput>;
  min?: InputMaybe<EmaEntriesHavingMinInput>;
  stddevPopulation?: InputMaybe<EmaEntriesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<EmaEntriesHavingStddevSampleInput>;
  sum?: InputMaybe<EmaEntriesHavingSumInput>;
  variancePopulation?: InputMaybe<EmaEntriesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<EmaEntriesHavingVarianceSampleInput>;
};

export type EmaEntriesHavingMaxInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntriesHavingMinInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntriesHavingStddevPopulationInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntriesHavingStddevSampleInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntriesHavingSumInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntriesHavingVariancePopulationInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

export type EmaEntriesHavingVarianceSampleInput = {
  angry?: InputMaybe<HavingIntFilter>;
  anxious?: InputMaybe<HavingIntFilter>;
  anxiousDuringContact?: InputMaybe<HavingIntFilter>;
  avoidedSocialContact?: InputMaybe<HavingIntFilter>;
  comfortableDuringContact?: InputMaybe<HavingIntFilter>;
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  copingHelpedInTheSituation?: InputMaybe<HavingIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  guilty?: InputMaybe<HavingIntFilter>;
  happy?: InputMaybe<HavingIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<HavingIntFilter>;
  lonely?: InputMaybe<HavingIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<HavingIntFilter>;
  relaxed?: InputMaybe<HavingIntFilter>;
  sad?: InputMaybe<HavingIntFilter>;
  satisfied?: InputMaybe<HavingIntFilter>;
  scheduledAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  tenseDuringContact?: InputMaybe<HavingIntFilter>;
  testy?: InputMaybe<HavingIntFilter>;
  testyDuringContact?: InputMaybe<HavingIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<HavingIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<HavingIntFilter>;
  triedToAlterFeelings?: InputMaybe<HavingIntFilter>;
  triedToCalmMyself?: InputMaybe<HavingIntFilter>;
  triedToChangeTheSituation?: InputMaybe<HavingIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<HavingIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<HavingIntFilter>;
  triedToThinkDifferently?: InputMaybe<HavingIntFilter>;
  unconfidentDuringContact?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `EmaEntry`. */
export enum EmaEntriesOrderBy {
  AngryAsc = 'ANGRY_ASC',
  AngryDesc = 'ANGRY_DESC',
  AnxiousAsc = 'ANXIOUS_ASC',
  AnxiousDesc = 'ANXIOUS_DESC',
  AnxiousDuringContactAsc = 'ANXIOUS_DURING_CONTACT_ASC',
  AnxiousDuringContactDesc = 'ANXIOUS_DURING_CONTACT_DESC',
  AvoidedSocialContactAsc = 'AVOIDED_SOCIAL_CONTACT_ASC',
  AvoidedSocialContactDesc = 'AVOIDED_SOCIAL_CONTACT_DESC',
  ComfortableDuringContactAsc = 'COMFORTABLE_DURING_CONTACT_ASC',
  ComfortableDuringContactDesc = 'COMFORTABLE_DURING_CONTACT_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CopingHelpedInTheSituationAsc = 'COPING_HELPED_IN_THE_SITUATION_ASC',
  CopingHelpedInTheSituationDesc = 'COPING_HELPED_IN_THE_SITUATION_DESC',
  CouldThinkOfNothingElseThanTheSitationAsc = 'COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  CouldThinkOfNothingElseThanTheSitationDesc = 'COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeletedAsc = 'DELETED_ASC',
  DeletedDesc = 'DELETED_DESC',
  GuiltyAsc = 'GUILTY_ASC',
  GuiltyDesc = 'GUILTY_DESC',
  HadAnyContactWithOthersSinceTheLastSurveyAsc = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  HadAnyContactWithOthersSinceTheLastSurveyDesc = 'HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  HadAStressfulSituationAsc = 'HAD_A_STRESSFUL_SITUATION_ASC',
  HadAStressfulSituationDesc = 'HAD_A_STRESSFUL_SITUATION_DESC',
  HappyAsc = 'HAPPY_ASC',
  HappyDesc = 'HAPPY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LackOfSocialContactBothersMeAsc = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  LackOfSocialContactBothersMeDesc = 'LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  LonelyAsc = 'LONELY_ASC',
  LonelyDesc = 'LONELY_DESC',
  Natural = 'NATURAL',
  NoSocialContactDueToOuterConditionsAsc = 'NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  NoSocialContactDueToOuterConditionsDesc = 'NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RelaxedAsc = 'RELAXED_ASC',
  RelaxedDesc = 'RELAXED_DESC',
  SadAsc = 'SAD_ASC',
  SadDesc = 'SAD_DESC',
  SatisfiedAsc = 'SATISFIED_ASC',
  SatisfiedDesc = 'SATISFIED_DESC',
  ScheduledAtAsc = 'SCHEDULED_AT_ASC',
  ScheduledAtDesc = 'SCHEDULED_AT_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC',
  SubjectIdAsc = 'SUBJECT_ID_ASC',
  SubjectIdDesc = 'SUBJECT_ID_DESC',
  TenseDuringContactAsc = 'TENSE_DURING_CONTACT_ASC',
  TenseDuringContactDesc = 'TENSE_DURING_CONTACT_DESC',
  TestyAsc = 'TESTY_ASC',
  TestyDesc = 'TESTY_DESC',
  TestyDuringContactAsc = 'TESTY_DURING_CONTACT_ASC',
  TestyDuringContactDesc = 'TESTY_DURING_CONTACT_DESC',
  TriedNotToThinkOfTheSitationAsc = 'TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  TriedNotToThinkOfTheSitationDesc = 'TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  TriedSomethingElseToCopeWithTheSituationAsc = 'TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  TriedSomethingElseToCopeWithTheSituationDesc = 'TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  TriedToAcceptTheSituationAsc = 'TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  TriedToAcceptTheSituationDesc = 'TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  TriedToAlterFeelingsAsc = 'TRIED_TO_ALTER_FEELINGS_ASC',
  TriedToAlterFeelingsDesc = 'TRIED_TO_ALTER_FEELINGS_DESC',
  TriedToCalmMyselfAsc = 'TRIED_TO_CALM_MYSELF_ASC',
  TriedToCalmMyselfDesc = 'TRIED_TO_CALM_MYSELF_DESC',
  TriedToChangeTheSituationAsc = 'TRIED_TO_CHANGE_THE_SITUATION_ASC',
  TriedToChangeTheSituationDesc = 'TRIED_TO_CHANGE_THE_SITUATION_DESC',
  TriedToFindASolutionForTheProblemAsc = 'TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  TriedToFindASolutionForTheProblemDesc = 'TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  TriedToFindTheCauseOfTheProblemAsc = 'TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  TriedToFindTheCauseOfTheProblemDesc = 'TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  TriedToThinkDifferentlyAsc = 'TRIED_TO_THINK_DIFFERENTLY_ASC',
  TriedToThinkDifferentlyDesc = 'TRIED_TO_THINK_DIFFERENTLY_DESC',
  UnconfidentDuringContactAsc = 'UNCONFIDENT_DURING_CONTACT_ASC',
  UnconfidentDuringContactDesc = 'UNCONFIDENT_DURING_CONTACT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedAtUtcAsc = 'UPDATED_AT_UTC_ASC',
  UpdatedAtUtcDesc = 'UPDATED_AT_UTC_DESC',
  WouldHaveLikedSocialContactAsc = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  WouldHaveLikedSocialContactDesc = 'WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC'
}

export type EmaEntry = Node & {
  __typename?: 'EmaEntry';
  angry?: Maybe<Scalars['Int']>;
  anxious?: Maybe<Scalars['Int']>;
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['Datetime']>;
  copingHelpedInTheSituation?: Maybe<Scalars['Int']>;
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  deleted: Scalars['Boolean'];
  guilty?: Maybe<Scalars['Int']>;
  hadAStressfulSituation?: Maybe<Scalars['Boolean']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: Maybe<Scalars['Boolean']>;
  happy?: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  lonely?: Maybe<Scalars['Int']>;
  noSocialContactDueToOuterConditions?: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  relaxed?: Maybe<Scalars['Int']>;
  sad?: Maybe<Scalars['Int']>;
  satisfied?: Maybe<Scalars['Int']>;
  scheduledAt: Scalars['Datetime'];
  startedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `EmaEntry`. */
  subject?: Maybe<User>;
  subjectId: Scalars['UUID'];
  tenseDuringContact?: Maybe<Scalars['Int']>;
  testy?: Maybe<Scalars['Int']>;
  testyDuringContact?: Maybe<Scalars['Int']>;
  triedNotToThinkOfTheSitation?: Maybe<Scalars['Int']>;
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['Int']>;
  triedToAcceptTheSituation?: Maybe<Scalars['Int']>;
  triedToAlterFeelings?: Maybe<Scalars['Int']>;
  triedToCalmMyself?: Maybe<Scalars['Int']>;
  triedToChangeTheSituation?: Maybe<Scalars['Int']>;
  triedToFindASolutionForTheProblem?: Maybe<Scalars['Int']>;
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['Int']>;
  triedToThinkDifferently?: Maybe<Scalars['Int']>;
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  updatedAtUtc?: Maybe<Scalars['String']>;
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

export type EmaEntryAggregates = {
  __typename?: 'EmaEntryAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<EmaEntryAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<EmaEntryDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<EmaEntryMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<EmaEntryMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<EmaEntryStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<EmaEntryStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<EmaEntrySumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<EmaEntryVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<EmaEntryVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `EmaEntry` object types. */
export type EmaEntryAggregatesFilter = {
  /** Mean average aggregate over matching `EmaEntry` objects. */
  average?: InputMaybe<EmaEntryAverageAggregateFilter>;
  /** Distinct count aggregate over matching `EmaEntry` objects. */
  distinctCount?: InputMaybe<EmaEntryDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `EmaEntry` object to be included within the aggregate. */
  filter?: InputMaybe<EmaEntryFilter>;
  /** Maximum aggregate over matching `EmaEntry` objects. */
  max?: InputMaybe<EmaEntryMaxAggregateFilter>;
  /** Minimum aggregate over matching `EmaEntry` objects. */
  min?: InputMaybe<EmaEntryMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `EmaEntry` objects. */
  stddevPopulation?: InputMaybe<EmaEntryStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `EmaEntry` objects. */
  stddevSample?: InputMaybe<EmaEntryStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `EmaEntry` objects. */
  sum?: InputMaybe<EmaEntrySumAggregateFilter>;
  /** Population variance aggregate over matching `EmaEntry` objects. */
  variancePopulation?: InputMaybe<EmaEntryVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `EmaEntry` objects. */
  varianceSample?: InputMaybe<EmaEntryVarianceSampleAggregateFilter>;
};

export type EmaEntryAverageAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  copingHelpedInTheSituation?: InputMaybe<BigFloatFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<BigFloatFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAcceptTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAlterFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyself?: InputMaybe<BigFloatFilter>;
  triedToChangeTheSituation?: InputMaybe<BigFloatFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<BigFloatFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntryAverageAggregates = {
  __typename?: 'EmaEntryAverageAggregates';
  /** Mean average of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Mean average of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Mean average of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Mean average of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Mean average of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Mean average of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Mean average of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Mean average of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Mean average of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Mean average of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Mean average of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Mean average of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Mean average of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToCalmMyself across the matching connection */
  triedToCalmMyself?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Mean average of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Mean average of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Mean average of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `EmaEntry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EmaEntryCondition = {
  /** Checks for equality with the object’s `angry` field. */
  angry?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `anxious` field. */
  anxious?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `anxiousDuringContact` field. */
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `avoidedSocialContact` field. */
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `comfortableDuringContact` field. */
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `copingHelpedInTheSituation` field. */
  copingHelpedInTheSituation?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `couldThinkOfNothingElseThanTheSitation` field. */
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `guilty` field. */
  guilty?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `hadAStressfulSituation` field. */
  hadAStressfulSituation?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `hadAnyContactWithOthersSinceTheLastSurvey` field. */
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `happy` field. */
  happy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `lackOfSocialContactBothersMe` field. */
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lonely` field. */
  lonely?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `noSocialContactDueToOuterConditions` field. */
  noSocialContactDueToOuterConditions?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `relaxed` field. */
  relaxed?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sad` field. */
  sad?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `satisfied` field. */
  satisfied?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `scheduledAt` field. */
  scheduledAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `subjectId` field. */
  subjectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tenseDuringContact` field. */
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `testy` field. */
  testy?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `testyDuringContact` field. */
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedNotToThinkOfTheSitation` field. */
  triedNotToThinkOfTheSitation?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedSomethingElseToCopeWithTheSituation` field. */
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToAcceptTheSituation` field. */
  triedToAcceptTheSituation?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToAlterFeelings` field. */
  triedToAlterFeelings?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToCalmMyself` field. */
  triedToCalmMyself?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToChangeTheSituation` field. */
  triedToChangeTheSituation?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToFindASolutionForTheProblem` field. */
  triedToFindASolutionForTheProblem?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToFindTheCauseOfTheProblem` field. */
  triedToFindTheCauseOfTheProblem?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `triedToThinkDifferently` field. */
  triedToThinkDifferently?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unconfidentDuringContact` field. */
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAtUtc` field. */
  updatedAtUtc?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `wouldHaveLikedSocialContact` field. */
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntryDistinctCountAggregateFilter = {
  angry?: InputMaybe<BigIntFilter>;
  anxious?: InputMaybe<BigIntFilter>;
  anxiousDuringContact?: InputMaybe<BigIntFilter>;
  avoidedSocialContact?: InputMaybe<BigIntFilter>;
  comfortableDuringContact?: InputMaybe<BigIntFilter>;
  completedAt?: InputMaybe<BigIntFilter>;
  copingHelpedInTheSituation?: InputMaybe<BigIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  guilty?: InputMaybe<BigIntFilter>;
  hadAStressfulSituation?: InputMaybe<BigIntFilter>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<BigIntFilter>;
  happy?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigIntFilter>;
  lonely?: InputMaybe<BigIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigIntFilter>;
  relaxed?: InputMaybe<BigIntFilter>;
  sad?: InputMaybe<BigIntFilter>;
  satisfied?: InputMaybe<BigIntFilter>;
  scheduledAt?: InputMaybe<BigIntFilter>;
  startedAt?: InputMaybe<BigIntFilter>;
  subjectId?: InputMaybe<BigIntFilter>;
  tenseDuringContact?: InputMaybe<BigIntFilter>;
  testy?: InputMaybe<BigIntFilter>;
  testyDuringContact?: InputMaybe<BigIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<BigIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<BigIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<BigIntFilter>;
  triedToAlterFeelings?: InputMaybe<BigIntFilter>;
  triedToCalmMyself?: InputMaybe<BigIntFilter>;
  triedToChangeTheSituation?: InputMaybe<BigIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<BigIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<BigIntFilter>;
  triedToThinkDifferently?: InputMaybe<BigIntFilter>;
  unconfidentDuringContact?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedAtUtc?: InputMaybe<StringFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigIntFilter>;
};

export type EmaEntryDistinctCountAggregates = {
  __typename?: 'EmaEntryDistinctCountAggregates';
  /** Distinct count of angry across the matching connection */
  angry?: Maybe<Scalars['BigInt']>;
  /** Distinct count of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigInt']>;
  /** Distinct count of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of completedAt across the matching connection */
  completedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars['BigInt']>;
  /** Distinct count of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hadAStressfulSituation across the matching connection */
  hadAStressfulSituation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hadAnyContactWithOthersSinceTheLastSurvey across the matching connection */
  hadAnyContactWithOthersSinceTheLastSurvey?: Maybe<Scalars['BigInt']>;
  /** Distinct count of happy across the matching connection */
  happy?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigInt']>;
  /** Distinct count of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigInt']>;
  /** Distinct count of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigInt']>;
  /** Distinct count of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigInt']>;
  /** Distinct count of sad across the matching connection */
  sad?: Maybe<Scalars['BigInt']>;
  /** Distinct count of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigInt']>;
  /** Distinct count of scheduledAt across the matching connection */
  scheduledAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of subjectId across the matching connection */
  subjectId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of testy across the matching connection */
  testy?: Maybe<Scalars['BigInt']>;
  /** Distinct count of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToCalmMyself across the matching connection */
  triedToCalmMyself?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['BigInt']>;
  /** Distinct count of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigInt']>;
  /** Distinct count of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  updatedAtUtc?: Maybe<Scalars['BigInt']>;
  /** Distinct count of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `EmaEntry` object types. All fields are combined with a logical ‘and.’ */
export type EmaEntryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EmaEntryFilter>>;
  /** Filter by the object’s `angry` field. */
  angry?: InputMaybe<IntFilter>;
  /** Filter by the object’s `anxious` field. */
  anxious?: InputMaybe<IntFilter>;
  /** Filter by the object’s `anxiousDuringContact` field. */
  anxiousDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `avoidedSocialContact` field. */
  avoidedSocialContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `comfortableDuringContact` field. */
  comfortableDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `copingHelpedInTheSituation` field. */
  copingHelpedInTheSituation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `couldThinkOfNothingElseThanTheSitation` field. */
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `guilty` field. */
  guilty?: InputMaybe<IntFilter>;
  /** Filter by the object’s `hadAStressfulSituation` field. */
  hadAStressfulSituation?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hadAnyContactWithOthersSinceTheLastSurvey` field. */
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `happy` field. */
  happy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lackOfSocialContactBothersMe` field. */
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  /** Filter by the object’s `lonely` field. */
  lonely?: InputMaybe<IntFilter>;
  /** Filter by the object’s `noSocialContactDueToOuterConditions` field. */
  noSocialContactDueToOuterConditions?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<EmaEntryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EmaEntryFilter>>;
  /** Filter by the object’s `relaxed` field. */
  relaxed?: InputMaybe<IntFilter>;
  /** Filter by the object’s `sad` field. */
  sad?: InputMaybe<IntFilter>;
  /** Filter by the object’s `satisfied` field. */
  satisfied?: InputMaybe<IntFilter>;
  /** Filter by the object’s `scheduledAt` field. */
  scheduledAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `subject` relation. */
  subject?: InputMaybe<UserFilter>;
  /** Filter by the object’s `subjectId` field. */
  subjectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `tenseDuringContact` field. */
  tenseDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `testy` field. */
  testy?: InputMaybe<IntFilter>;
  /** Filter by the object’s `testyDuringContact` field. */
  testyDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedNotToThinkOfTheSitation` field. */
  triedNotToThinkOfTheSitation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedSomethingElseToCopeWithTheSituation` field. */
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToAcceptTheSituation` field. */
  triedToAcceptTheSituation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToAlterFeelings` field. */
  triedToAlterFeelings?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToCalmMyself` field. */
  triedToCalmMyself?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToChangeTheSituation` field. */
  triedToChangeTheSituation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToFindASolutionForTheProblem` field. */
  triedToFindASolutionForTheProblem?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToFindTheCauseOfTheProblem` field. */
  triedToFindTheCauseOfTheProblem?: InputMaybe<IntFilter>;
  /** Filter by the object’s `triedToThinkDifferently` field. */
  triedToThinkDifferently?: InputMaybe<IntFilter>;
  /** Filter by the object’s `unconfidentDuringContact` field. */
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAtUtc` field. */
  updatedAtUtc?: InputMaybe<StringFilter>;
  /** Filter by the object’s `wouldHaveLikedSocialContact` field. */
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `EmaEntry` */
export type EmaEntryInput = {
  angry?: InputMaybe<Scalars['Int']>;
  anxious?: InputMaybe<Scalars['Int']>;
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Datetime']>;
  copingHelpedInTheSituation?: InputMaybe<Scalars['Int']>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  guilty?: InputMaybe<Scalars['Int']>;
  hadAStressfulSituation?: InputMaybe<Scalars['Boolean']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  happy?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  lonely?: InputMaybe<Scalars['Int']>;
  noSocialContactDueToOuterConditions?: InputMaybe<Scalars['Int']>;
  relaxed?: InputMaybe<Scalars['Int']>;
  sad?: InputMaybe<Scalars['Int']>;
  satisfied?: InputMaybe<Scalars['Int']>;
  scheduledAt: Scalars['Datetime'];
  startedAt?: InputMaybe<Scalars['Datetime']>;
  subjectId?: InputMaybe<Scalars['UUID']>;
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  testy?: InputMaybe<Scalars['Int']>;
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  triedNotToThinkOfTheSitation?: InputMaybe<Scalars['Int']>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<Scalars['Int']>;
  triedToAcceptTheSituation?: InputMaybe<Scalars['Int']>;
  triedToAlterFeelings?: InputMaybe<Scalars['Int']>;
  triedToCalmMyself?: InputMaybe<Scalars['Int']>;
  triedToChangeTheSituation?: InputMaybe<Scalars['Int']>;
  triedToFindASolutionForTheProblem?: InputMaybe<Scalars['Int']>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<Scalars['Int']>;
  triedToThinkDifferently?: InputMaybe<Scalars['Int']>;
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntryMaxAggregateFilter = {
  angry?: InputMaybe<IntFilter>;
  anxious?: InputMaybe<IntFilter>;
  anxiousDuringContact?: InputMaybe<IntFilter>;
  avoidedSocialContact?: InputMaybe<IntFilter>;
  comfortableDuringContact?: InputMaybe<IntFilter>;
  copingHelpedInTheSituation?: InputMaybe<IntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<IntFilter>;
  guilty?: InputMaybe<IntFilter>;
  happy?: InputMaybe<IntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  lonely?: InputMaybe<IntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<IntFilter>;
  relaxed?: InputMaybe<IntFilter>;
  sad?: InputMaybe<IntFilter>;
  satisfied?: InputMaybe<IntFilter>;
  tenseDuringContact?: InputMaybe<IntFilter>;
  testy?: InputMaybe<IntFilter>;
  testyDuringContact?: InputMaybe<IntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<IntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<IntFilter>;
  triedToAcceptTheSituation?: InputMaybe<IntFilter>;
  triedToAlterFeelings?: InputMaybe<IntFilter>;
  triedToCalmMyself?: InputMaybe<IntFilter>;
  triedToChangeTheSituation?: InputMaybe<IntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<IntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<IntFilter>;
  triedToThinkDifferently?: InputMaybe<IntFilter>;
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

export type EmaEntryMaxAggregates = {
  __typename?: 'EmaEntryMaxAggregates';
  /** Maximum of angry across the matching connection */
  angry?: Maybe<Scalars['Int']>;
  /** Maximum of anxious across the matching connection */
  anxious?: Maybe<Scalars['Int']>;
  /** Maximum of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  /** Maximum of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation?: Maybe<Scalars['Int']>;
  /** Maximum of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['Int']>;
  /** Maximum of guilty across the matching connection */
  guilty?: Maybe<Scalars['Int']>;
  /** Maximum of happy across the matching connection */
  happy?: Maybe<Scalars['Int']>;
  /** Maximum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  /** Maximum of lonely across the matching connection */
  lonely?: Maybe<Scalars['Int']>;
  /** Maximum of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['Int']>;
  /** Maximum of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['Int']>;
  /** Maximum of sad across the matching connection */
  sad?: Maybe<Scalars['Int']>;
  /** Maximum of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['Int']>;
  /** Maximum of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of testy across the matching connection */
  testy?: Maybe<Scalars['Int']>;
  /** Maximum of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation?: Maybe<Scalars['Int']>;
  /** Maximum of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['Int']>;
  /** Maximum of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation?: Maybe<Scalars['Int']>;
  /** Maximum of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings?: Maybe<Scalars['Int']>;
  /** Maximum of triedToCalmMyself across the matching connection */
  triedToCalmMyself?: Maybe<Scalars['Int']>;
  /** Maximum of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation?: Maybe<Scalars['Int']>;
  /** Maximum of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem?: Maybe<Scalars['Int']>;
  /** Maximum of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['Int']>;
  /** Maximum of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['Int']>;
  /** Maximum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  /** Maximum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

export type EmaEntryMinAggregateFilter = {
  angry?: InputMaybe<IntFilter>;
  anxious?: InputMaybe<IntFilter>;
  anxiousDuringContact?: InputMaybe<IntFilter>;
  avoidedSocialContact?: InputMaybe<IntFilter>;
  comfortableDuringContact?: InputMaybe<IntFilter>;
  copingHelpedInTheSituation?: InputMaybe<IntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<IntFilter>;
  guilty?: InputMaybe<IntFilter>;
  happy?: InputMaybe<IntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<IntFilter>;
  lonely?: InputMaybe<IntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<IntFilter>;
  relaxed?: InputMaybe<IntFilter>;
  sad?: InputMaybe<IntFilter>;
  satisfied?: InputMaybe<IntFilter>;
  tenseDuringContact?: InputMaybe<IntFilter>;
  testy?: InputMaybe<IntFilter>;
  testyDuringContact?: InputMaybe<IntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<IntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<IntFilter>;
  triedToAcceptTheSituation?: InputMaybe<IntFilter>;
  triedToAlterFeelings?: InputMaybe<IntFilter>;
  triedToCalmMyself?: InputMaybe<IntFilter>;
  triedToChangeTheSituation?: InputMaybe<IntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<IntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<IntFilter>;
  triedToThinkDifferently?: InputMaybe<IntFilter>;
  unconfidentDuringContact?: InputMaybe<IntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<IntFilter>;
};

export type EmaEntryMinAggregates = {
  __typename?: 'EmaEntryMinAggregates';
  /** Minimum of angry across the matching connection */
  angry?: Maybe<Scalars['Int']>;
  /** Minimum of anxious across the matching connection */
  anxious?: Maybe<Scalars['Int']>;
  /** Minimum of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['Int']>;
  /** Minimum of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation?: Maybe<Scalars['Int']>;
  /** Minimum of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['Int']>;
  /** Minimum of guilty across the matching connection */
  guilty?: Maybe<Scalars['Int']>;
  /** Minimum of happy across the matching connection */
  happy?: Maybe<Scalars['Int']>;
  /** Minimum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['Int']>;
  /** Minimum of lonely across the matching connection */
  lonely?: Maybe<Scalars['Int']>;
  /** Minimum of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['Int']>;
  /** Minimum of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['Int']>;
  /** Minimum of sad across the matching connection */
  sad?: Maybe<Scalars['Int']>;
  /** Minimum of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['Int']>;
  /** Minimum of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of testy across the matching connection */
  testy?: Maybe<Scalars['Int']>;
  /** Minimum of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation?: Maybe<Scalars['Int']>;
  /** Minimum of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['Int']>;
  /** Minimum of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation?: Maybe<Scalars['Int']>;
  /** Minimum of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings?: Maybe<Scalars['Int']>;
  /** Minimum of triedToCalmMyself across the matching connection */
  triedToCalmMyself?: Maybe<Scalars['Int']>;
  /** Minimum of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation?: Maybe<Scalars['Int']>;
  /** Minimum of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem?: Maybe<Scalars['Int']>;
  /** Minimum of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['Int']>;
  /** Minimum of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['Int']>;
  /** Minimum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['Int']>;
  /** Minimum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `EmaEntry`. Fields that are set will be updated. */
export type EmaEntryPatch = {
  angry?: InputMaybe<Scalars['Int']>;
  anxious?: InputMaybe<Scalars['Int']>;
  anxiousDuringContact?: InputMaybe<Scalars['Int']>;
  avoidedSocialContact?: InputMaybe<Scalars['Int']>;
  comfortableDuringContact?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Datetime']>;
  copingHelpedInTheSituation?: InputMaybe<Scalars['Int']>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  guilty?: InputMaybe<Scalars['Int']>;
  hadAStressfulSituation?: InputMaybe<Scalars['Boolean']>;
  hadAnyContactWithOthersSinceTheLastSurvey?: InputMaybe<Scalars['Boolean']>;
  happy?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  lackOfSocialContactBothersMe?: InputMaybe<Scalars['Int']>;
  lonely?: InputMaybe<Scalars['Int']>;
  noSocialContactDueToOuterConditions?: InputMaybe<Scalars['Int']>;
  relaxed?: InputMaybe<Scalars['Int']>;
  sad?: InputMaybe<Scalars['Int']>;
  satisfied?: InputMaybe<Scalars['Int']>;
  scheduledAt?: InputMaybe<Scalars['Datetime']>;
  startedAt?: InputMaybe<Scalars['Datetime']>;
  subjectId?: InputMaybe<Scalars['UUID']>;
  tenseDuringContact?: InputMaybe<Scalars['Int']>;
  testy?: InputMaybe<Scalars['Int']>;
  testyDuringContact?: InputMaybe<Scalars['Int']>;
  triedNotToThinkOfTheSitation?: InputMaybe<Scalars['Int']>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<Scalars['Int']>;
  triedToAcceptTheSituation?: InputMaybe<Scalars['Int']>;
  triedToAlterFeelings?: InputMaybe<Scalars['Int']>;
  triedToCalmMyself?: InputMaybe<Scalars['Int']>;
  triedToChangeTheSituation?: InputMaybe<Scalars['Int']>;
  triedToFindASolutionForTheProblem?: InputMaybe<Scalars['Int']>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<Scalars['Int']>;
  triedToThinkDifferently?: InputMaybe<Scalars['Int']>;
  unconfidentDuringContact?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  wouldHaveLikedSocialContact?: InputMaybe<Scalars['Int']>;
};

export type EmaEntryStddevPopulationAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  copingHelpedInTheSituation?: InputMaybe<BigFloatFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<BigFloatFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAcceptTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAlterFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyself?: InputMaybe<BigFloatFilter>;
  triedToChangeTheSituation?: InputMaybe<BigFloatFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<BigFloatFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntryStddevPopulationAggregates = {
  __typename?: 'EmaEntryStddevPopulationAggregates';
  /** Population standard deviation of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToCalmMyself across the matching connection */
  triedToCalmMyself?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population standard deviation of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntryStddevSampleAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  copingHelpedInTheSituation?: InputMaybe<BigFloatFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<BigFloatFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAcceptTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAlterFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyself?: InputMaybe<BigFloatFilter>;
  triedToChangeTheSituation?: InputMaybe<BigFloatFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<BigFloatFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntryStddevSampleAggregates = {
  __typename?: 'EmaEntryStddevSampleAggregates';
  /** Sample standard deviation of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToCalmMyself across the matching connection */
  triedToCalmMyself?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample standard deviation of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntrySumAggregateFilter = {
  angry?: InputMaybe<BigIntFilter>;
  anxious?: InputMaybe<BigIntFilter>;
  anxiousDuringContact?: InputMaybe<BigIntFilter>;
  avoidedSocialContact?: InputMaybe<BigIntFilter>;
  comfortableDuringContact?: InputMaybe<BigIntFilter>;
  copingHelpedInTheSituation?: InputMaybe<BigIntFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<BigIntFilter>;
  guilty?: InputMaybe<BigIntFilter>;
  happy?: InputMaybe<BigIntFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigIntFilter>;
  lonely?: InputMaybe<BigIntFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigIntFilter>;
  relaxed?: InputMaybe<BigIntFilter>;
  sad?: InputMaybe<BigIntFilter>;
  satisfied?: InputMaybe<BigIntFilter>;
  tenseDuringContact?: InputMaybe<BigIntFilter>;
  testy?: InputMaybe<BigIntFilter>;
  testyDuringContact?: InputMaybe<BigIntFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<BigIntFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<BigIntFilter>;
  triedToAcceptTheSituation?: InputMaybe<BigIntFilter>;
  triedToAlterFeelings?: InputMaybe<BigIntFilter>;
  triedToCalmMyself?: InputMaybe<BigIntFilter>;
  triedToChangeTheSituation?: InputMaybe<BigIntFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<BigIntFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<BigIntFilter>;
  triedToThinkDifferently?: InputMaybe<BigIntFilter>;
  unconfidentDuringContact?: InputMaybe<BigIntFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigIntFilter>;
};

export type EmaEntrySumAggregates = {
  __typename?: 'EmaEntrySumAggregates';
  /** Sum of angry across the matching connection */
  angry: Scalars['BigInt'];
  /** Sum of anxious across the matching connection */
  anxious: Scalars['BigInt'];
  /** Sum of anxiousDuringContact across the matching connection */
  anxiousDuringContact: Scalars['BigInt'];
  /** Sum of avoidedSocialContact across the matching connection */
  avoidedSocialContact: Scalars['BigInt'];
  /** Sum of comfortableDuringContact across the matching connection */
  comfortableDuringContact: Scalars['BigInt'];
  /** Sum of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation: Scalars['BigInt'];
  /** Sum of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation: Scalars['BigInt'];
  /** Sum of guilty across the matching connection */
  guilty: Scalars['BigInt'];
  /** Sum of happy across the matching connection */
  happy: Scalars['BigInt'];
  /** Sum of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe: Scalars['BigInt'];
  /** Sum of lonely across the matching connection */
  lonely: Scalars['BigInt'];
  /** Sum of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions: Scalars['BigInt'];
  /** Sum of relaxed across the matching connection */
  relaxed: Scalars['BigInt'];
  /** Sum of sad across the matching connection */
  sad: Scalars['BigInt'];
  /** Sum of satisfied across the matching connection */
  satisfied: Scalars['BigInt'];
  /** Sum of tenseDuringContact across the matching connection */
  tenseDuringContact: Scalars['BigInt'];
  /** Sum of testy across the matching connection */
  testy: Scalars['BigInt'];
  /** Sum of testyDuringContact across the matching connection */
  testyDuringContact: Scalars['BigInt'];
  /** Sum of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation: Scalars['BigInt'];
  /** Sum of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation: Scalars['BigInt'];
  /** Sum of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation: Scalars['BigInt'];
  /** Sum of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings: Scalars['BigInt'];
  /** Sum of triedToCalmMyself across the matching connection */
  triedToCalmMyself: Scalars['BigInt'];
  /** Sum of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation: Scalars['BigInt'];
  /** Sum of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem: Scalars['BigInt'];
  /** Sum of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem: Scalars['BigInt'];
  /** Sum of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently: Scalars['BigInt'];
  /** Sum of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact: Scalars['BigInt'];
  /** Sum of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact: Scalars['BigInt'];
};

export type EmaEntryVariancePopulationAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  copingHelpedInTheSituation?: InputMaybe<BigFloatFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<BigFloatFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAcceptTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAlterFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyself?: InputMaybe<BigFloatFilter>;
  triedToChangeTheSituation?: InputMaybe<BigFloatFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<BigFloatFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntryVariancePopulationAggregates = {
  __typename?: 'EmaEntryVariancePopulationAggregates';
  /** Population variance of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Population variance of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Population variance of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Population variance of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Population variance of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Population variance of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Population variance of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Population variance of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Population variance of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Population variance of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Population variance of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Population variance of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Population variance of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToCalmMyself across the matching connection */
  triedToCalmMyself?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Population variance of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Population variance of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Population variance of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmaEntryVarianceSampleAggregateFilter = {
  angry?: InputMaybe<BigFloatFilter>;
  anxious?: InputMaybe<BigFloatFilter>;
  anxiousDuringContact?: InputMaybe<BigFloatFilter>;
  avoidedSocialContact?: InputMaybe<BigFloatFilter>;
  comfortableDuringContact?: InputMaybe<BigFloatFilter>;
  copingHelpedInTheSituation?: InputMaybe<BigFloatFilter>;
  couldThinkOfNothingElseThanTheSitation?: InputMaybe<BigFloatFilter>;
  guilty?: InputMaybe<BigFloatFilter>;
  happy?: InputMaybe<BigFloatFilter>;
  lackOfSocialContactBothersMe?: InputMaybe<BigFloatFilter>;
  lonely?: InputMaybe<BigFloatFilter>;
  noSocialContactDueToOuterConditions?: InputMaybe<BigFloatFilter>;
  relaxed?: InputMaybe<BigFloatFilter>;
  sad?: InputMaybe<BigFloatFilter>;
  satisfied?: InputMaybe<BigFloatFilter>;
  tenseDuringContact?: InputMaybe<BigFloatFilter>;
  testy?: InputMaybe<BigFloatFilter>;
  testyDuringContact?: InputMaybe<BigFloatFilter>;
  triedNotToThinkOfTheSitation?: InputMaybe<BigFloatFilter>;
  triedSomethingElseToCopeWithTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAcceptTheSituation?: InputMaybe<BigFloatFilter>;
  triedToAlterFeelings?: InputMaybe<BigFloatFilter>;
  triedToCalmMyself?: InputMaybe<BigFloatFilter>;
  triedToChangeTheSituation?: InputMaybe<BigFloatFilter>;
  triedToFindASolutionForTheProblem?: InputMaybe<BigFloatFilter>;
  triedToFindTheCauseOfTheProblem?: InputMaybe<BigFloatFilter>;
  triedToThinkDifferently?: InputMaybe<BigFloatFilter>;
  unconfidentDuringContact?: InputMaybe<BigFloatFilter>;
  wouldHaveLikedSocialContact?: InputMaybe<BigFloatFilter>;
};

export type EmaEntryVarianceSampleAggregates = {
  __typename?: 'EmaEntryVarianceSampleAggregates';
  /** Sample variance of angry across the matching connection */
  angry?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of anxious across the matching connection */
  anxious?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of anxiousDuringContact across the matching connection */
  anxiousDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of avoidedSocialContact across the matching connection */
  avoidedSocialContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of comfortableDuringContact across the matching connection */
  comfortableDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of copingHelpedInTheSituation across the matching connection */
  copingHelpedInTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of couldThinkOfNothingElseThanTheSitation across the matching connection */
  couldThinkOfNothingElseThanTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of guilty across the matching connection */
  guilty?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of happy across the matching connection */
  happy?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lackOfSocialContactBothersMe across the matching connection */
  lackOfSocialContactBothersMe?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of lonely across the matching connection */
  lonely?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of noSocialContactDueToOuterConditions across the matching connection */
  noSocialContactDueToOuterConditions?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of relaxed across the matching connection */
  relaxed?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of sad across the matching connection */
  sad?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of satisfied across the matching connection */
  satisfied?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of tenseDuringContact across the matching connection */
  tenseDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of testy across the matching connection */
  testy?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of testyDuringContact across the matching connection */
  testyDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedNotToThinkOfTheSitation across the matching connection */
  triedNotToThinkOfTheSitation?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedSomethingElseToCopeWithTheSituation across the matching connection */
  triedSomethingElseToCopeWithTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToAcceptTheSituation across the matching connection */
  triedToAcceptTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToAlterFeelings across the matching connection */
  triedToAlterFeelings?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToCalmMyself across the matching connection */
  triedToCalmMyself?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToChangeTheSituation across the matching connection */
  triedToChangeTheSituation?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToFindASolutionForTheProblem across the matching connection */
  triedToFindASolutionForTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToFindTheCauseOfTheProblem across the matching connection */
  triedToFindTheCauseOfTheProblem?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of triedToThinkDifferently across the matching connection */
  triedToThinkDifferently?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of unconfidentDuringContact across the matching connection */
  unconfidentDuringContact?: Maybe<Scalars['BigFloat']>;
  /** Sample variance of wouldHaveLikedSocialContact across the matching connection */
  wouldHaveLikedSocialContact?: Maybe<Scalars['BigFloat']>;
};

export type EmergencyContact = {
  __typename?: 'EmergencyContact';
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
};

/** An input for mutations affecting `EmergencyContact` */
export type EmergencyContactInput = {
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type Event = Node & {
  __typename?: 'Event';
  accountId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  level?: Maybe<LogLevel>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  payload?: Maybe<Scalars['JSON']>;
  remoteAddress?: Maybe<Scalars['String']>;
  trackedAt: Scalars['Datetime'];
  type: Scalars['String'];
  userAgent?: Maybe<Scalars['String']>;
};

/** An input for mutations affecting `Event` */
export type EventInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  level?: InputMaybe<LogLevel>;
  payload?: InputMaybe<Scalars['JSON']>;
  remoteAddress?: InputMaybe<Scalars['String']>;
  trackedAt?: InputMaybe<Scalars['Datetime']>;
  type: Scalars['String'];
  userAgent?: InputMaybe<Scalars['String']>;
};

export type FavoritePage = Node & {
  __typename?: 'FavoritePage';
  addedAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  route: Scalars['String'];
  /** Reads a single `Trial` that is related to this `FavoritePage`. */
  trial?: Maybe<Trial>;
  trialId: Scalars['UUID'];
};

export type FavoritePageAggregates = {
  __typename?: 'FavoritePageAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<FavoritePageDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `FavoritePage` object types. */
export type FavoritePageAggregatesFilter = {
  /** Distinct count aggregate over matching `FavoritePage` objects. */
  distinctCount?: InputMaybe<FavoritePageDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `FavoritePage` object to be included within the aggregate. */
  filter?: InputMaybe<FavoritePageFilter>;
};

/**
 * A condition to be used against `FavoritePage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FavoritePageCondition = {
  /** Checks for equality with the object’s `addedAt` field. */
  addedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `route` field. */
  route?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `trialId` field. */
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type FavoritePageDistinctCountAggregateFilter = {
  addedAt?: InputMaybe<BigIntFilter>;
  route?: InputMaybe<BigIntFilter>;
  trialId?: InputMaybe<BigIntFilter>;
};

export type FavoritePageDistinctCountAggregates = {
  __typename?: 'FavoritePageDistinctCountAggregates';
  /** Distinct count of addedAt across the matching connection */
  addedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of route across the matching connection */
  route?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trialId across the matching connection */
  trialId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `FavoritePage` object types. All fields are combined with a logical ‘and.’ */
export type FavoritePageFilter = {
  /** Filter by the object’s `addedAt` field. */
  addedAt?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FavoritePageFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<FavoritePageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FavoritePageFilter>>;
  /** Filter by the object’s `route` field. */
  route?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trial` relation. */
  trial?: InputMaybe<TrialFilter>;
  /** Filter by the object’s `trialId` field. */
  trialId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `FavoritePage` */
export type FavoritePageInput = {
  addedAt?: InputMaybe<Scalars['Datetime']>;
  route: Scalars['String'];
  trialId?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `FavoritePage`. Fields that are set will be updated. */
export type FavoritePagePatch = {
  addedAt?: InputMaybe<Scalars['Datetime']>;
  route?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `FavoritePage` values. */
export type FavoritePagesConnection = {
  __typename?: 'FavoritePagesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<FavoritePageAggregates>;
  /** A list of edges which contains the `FavoritePage` and cursor to aid in pagination. */
  edges: Array<FavoritePagesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<FavoritePageAggregates>>;
  /** A list of `FavoritePage` objects. */
  nodes: Array<FavoritePage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FavoritePage` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `FavoritePage` values. */
export type FavoritePagesConnectionGroupedAggregatesArgs = {
  groupBy: Array<FavoritePagesGroupBy>;
  having?: InputMaybe<FavoritePagesHavingInput>;
};

/** A `FavoritePage` edge in the connection. */
export type FavoritePagesEdge = {
  __typename?: 'FavoritePagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `FavoritePage` at the end of the edge. */
  node: FavoritePage;
};

/** Grouping methods for `FavoritePage` for usage during aggregation. */
export enum FavoritePagesGroupBy {
  AddedAt = 'ADDED_AT',
  AddedAtTruncatedToDay = 'ADDED_AT_TRUNCATED_TO_DAY',
  AddedAtTruncatedToHour = 'ADDED_AT_TRUNCATED_TO_HOUR',
  Route = 'ROUTE',
  TrialId = 'TRIAL_ID'
}

export type FavoritePagesHavingAverageInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FavoritePagesHavingDistinctCountInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `FavoritePage` aggregates. */
export type FavoritePagesHavingInput = {
  AND?: InputMaybe<Array<FavoritePagesHavingInput>>;
  OR?: InputMaybe<Array<FavoritePagesHavingInput>>;
  average?: InputMaybe<FavoritePagesHavingAverageInput>;
  distinctCount?: InputMaybe<FavoritePagesHavingDistinctCountInput>;
  max?: InputMaybe<FavoritePagesHavingMaxInput>;
  min?: InputMaybe<FavoritePagesHavingMinInput>;
  stddevPopulation?: InputMaybe<FavoritePagesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<FavoritePagesHavingStddevSampleInput>;
  sum?: InputMaybe<FavoritePagesHavingSumInput>;
  variancePopulation?: InputMaybe<FavoritePagesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<FavoritePagesHavingVarianceSampleInput>;
};

export type FavoritePagesHavingMaxInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FavoritePagesHavingMinInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FavoritePagesHavingStddevPopulationInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FavoritePagesHavingStddevSampleInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FavoritePagesHavingSumInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FavoritePagesHavingVariancePopulationInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type FavoritePagesHavingVarianceSampleInput = {
  addedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `FavoritePage`. */
export enum FavoritePagesOrderBy {
  AddedAtAsc = 'ADDED_AT_ASC',
  AddedAtDesc = 'ADDED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RouteAsc = 'ROUTE_ASC',
  RouteDesc = 'ROUTE_DESC',
  TrialIdAsc = 'TRIAL_ID_ASC',
  TrialIdDesc = 'TRIAL_ID_DESC'
}

/** All input for the `generateGravatarHashFromEmail` mutation. */
export type GenerateGravatarHashFromEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `generateGravatarHashFromEmail` mutation. */
export type GenerateGravatarHashFromEmailPayload = {
  __typename?: 'GenerateGravatarHashFromEmailPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  string?: Maybe<Scalars['String']>;
};

/** All input for the `generateSamplingPlan2` mutation. */
export type GenerateSamplingPlan2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  forDays?: InputMaybe<Scalars['Int']>;
};

/** The output of our `generateSamplingPlan2` mutation. */
export type GenerateSamplingPlan2Payload = {
  __typename?: 'GenerateSamplingPlan2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  emaEntries2S?: Maybe<Array<EmaEntries2>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `generateSamplingPlan3` mutation. */
export type GenerateSamplingPlan3Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  forDays?: InputMaybe<Scalars['Int']>;
  startsAt?: InputMaybe<Scalars['Date']>;
};

/** The output of our `generateSamplingPlan3` mutation. */
export type GenerateSamplingPlan3Payload = {
  __typename?: 'GenerateSamplingPlan3Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  emaEntries3S?: Maybe<Array<EmaEntries3>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `generateSamplingPlan` mutation. */
export type GenerateSamplingPlanInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  forDays?: InputMaybe<Scalars['Int']>;
};

/** The output of our `generateSamplingPlan` mutation. */
export type GenerateSamplingPlanPayload = {
  __typename?: 'GenerateSamplingPlanPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  emaEntries?: Maybe<Array<EmaEntry>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type GenericEmiExercise = Node & {
  __typename?: 'GenericEmiExercise';
  body: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Trial` that is related to this `GenericEmiExercise`. */
  trial?: Maybe<Trial>;
  trialId: Scalars['UUID'];
  type: Scalars['String'];
  updatedAt: Scalars['Datetime'];
};

export type GenericEmiExerciseAggregates = {
  __typename?: 'GenericEmiExerciseAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<GenericEmiExerciseDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `GenericEmiExercise` object types. */
export type GenericEmiExerciseAggregatesFilter = {
  /** Distinct count aggregate over matching `GenericEmiExercise` objects. */
  distinctCount?: InputMaybe<GenericEmiExerciseDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `GenericEmiExercise` object to be included within the aggregate. */
  filter?: InputMaybe<GenericEmiExerciseFilter>;
};

/**
 * A condition to be used against `GenericEmiExercise` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GenericEmiExerciseCondition = {
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `trialId` field. */
  trialId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type GenericEmiExerciseDistinctCountAggregateFilter = {
  body?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  trialId?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type GenericEmiExerciseDistinctCountAggregates = {
  __typename?: 'GenericEmiExerciseDistinctCountAggregates';
  /** Distinct count of body across the matching connection */
  body?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trialId across the matching connection */
  trialId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `GenericEmiExercise` object types. All fields are combined with a logical ‘and.’ */
export type GenericEmiExerciseFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GenericEmiExerciseFilter>>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GenericEmiExerciseFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GenericEmiExerciseFilter>>;
  /** Filter by the object’s `trial` relation. */
  trial?: InputMaybe<TrialFilter>;
  /** Filter by the object’s `trialId` field. */
  trialId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `GenericEmiExercise` */
export type GenericEmiExerciseInput = {
  body?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
  type: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `GenericEmiExercise`. Fields that are set will be updated. */
export type GenericEmiExercisePatch = {
  body?: InputMaybe<Scalars['JSON']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `GenericEmiExercise` values. */
export type GenericEmiExercisesConnection = {
  __typename?: 'GenericEmiExercisesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GenericEmiExerciseAggregates>;
  /** A list of edges which contains the `GenericEmiExercise` and cursor to aid in pagination. */
  edges: Array<GenericEmiExercisesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GenericEmiExerciseAggregates>>;
  /** A list of `GenericEmiExercise` objects. */
  nodes: Array<GenericEmiExercise>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GenericEmiExercise` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `GenericEmiExercise` values. */
export type GenericEmiExercisesConnectionGroupedAggregatesArgs = {
  groupBy: Array<GenericEmiExercisesGroupBy>;
  having?: InputMaybe<GenericEmiExercisesHavingInput>;
};

/** A `GenericEmiExercise` edge in the connection. */
export type GenericEmiExercisesEdge = {
  __typename?: 'GenericEmiExercisesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GenericEmiExercise` at the end of the edge. */
  node: GenericEmiExercise;
};

/** Grouping methods for `GenericEmiExercise` for usage during aggregation. */
export enum GenericEmiExercisesGroupBy {
  Body = 'BODY',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  Name = 'NAME',
  TrialId = 'TRIAL_ID',
  Type = 'TYPE',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type GenericEmiExercisesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GenericEmiExercisesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `GenericEmiExercise` aggregates. */
export type GenericEmiExercisesHavingInput = {
  AND?: InputMaybe<Array<GenericEmiExercisesHavingInput>>;
  OR?: InputMaybe<Array<GenericEmiExercisesHavingInput>>;
  average?: InputMaybe<GenericEmiExercisesHavingAverageInput>;
  distinctCount?: InputMaybe<GenericEmiExercisesHavingDistinctCountInput>;
  max?: InputMaybe<GenericEmiExercisesHavingMaxInput>;
  min?: InputMaybe<GenericEmiExercisesHavingMinInput>;
  stddevPopulation?: InputMaybe<GenericEmiExercisesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<GenericEmiExercisesHavingStddevSampleInput>;
  sum?: InputMaybe<GenericEmiExercisesHavingSumInput>;
  variancePopulation?: InputMaybe<GenericEmiExercisesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<GenericEmiExercisesHavingVarianceSampleInput>;
};

export type GenericEmiExercisesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GenericEmiExercisesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GenericEmiExercisesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GenericEmiExercisesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GenericEmiExercisesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GenericEmiExercisesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GenericEmiExercisesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `GenericEmiExercise`. */
export enum GenericEmiExercisesOrderBy {
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrialIdAsc = 'TRIAL_ID_ASC',
  TrialIdDesc = 'TRIAL_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type Group = Node & {
  __typename?: 'Group';
  canJoin: GroupVisibility;
  canManage: GroupRole;
  countOfMembers?: Maybe<Scalars['BigInt']>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Group`. */
  creator?: Maybe<User>;
  creatorId?: Maybe<Scalars['UUID']>;
  externalNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  flags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads a single `GroupHome` that is related to this `Group`. */
  home?: Maybe<GroupHome>;
  homeId?: Maybe<Scalars['UUID']>;
  id: Scalars['UUID'];
  /** Reads and enables pagination through a set of `MembershipInvitation`. */
  membershipInvitations: MembershipInvitationsConnection;
  /** Reads and enables pagination through a set of `MembershipInvitation`. */
  membershipInvitationsByInvitedGroupId: MembershipInvitationsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships: MembershipsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  membershipsBySubgroupId: MembershipsConnection;
  myEffectiveRole?: Maybe<GroupRole>;
  /** Reads and enables pagination through a set of `MembershipInvitation`. */
  myIssuedInvitations: MembershipInvitationsConnection;
  myMembership?: Maybe<Membership>;
  myMembershipExists?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Membership`. */
  myMemberships: MembershipsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  myPeersMemberships: MembershipsConnection;
  /** Reads and enables pagination through a set of `MembershipInvitation`. */
  myReceivedInvitations: MembershipInvitationsConnection;
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Conversation`. */
  privateConversations: ConversationsConnection;
  /** Reads a single `Tenant` that is related to this `Group`. */
  tenant?: Maybe<Tenant>;
  tenantId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `Trial`. */
  trialsByStaffId: TrialsConnection;
  updatedAt: Scalars['Datetime'];
};


export type GroupCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};


export type GroupMembershipInvitationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationCondition>;
  filter?: InputMaybe<MembershipInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};


export type GroupMembershipInvitationsByInvitedGroupIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationCondition>;
  filter?: InputMaybe<MembershipInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};


export type GroupMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type GroupMembershipsBySubgroupIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type GroupMyIssuedInvitationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationCondition>;
  filter?: InputMaybe<MembershipInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};


export type GroupMyMembershipExistsArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};


export type GroupMyMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type GroupMyPeersMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeSupergroups?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type GroupMyReceivedInvitationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationCondition>;
  filter?: InputMaybe<MembershipInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeGroupInvitations?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};


export type GroupPrivateConversationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConversationCondition>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};


export type GroupTrialsByStaffIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

export type GroupAggregates = {
  __typename?: 'GroupAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<GroupAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<GroupDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<GroupMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<GroupMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<GroupStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<GroupStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<GroupSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<GroupVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<GroupVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Group` object types. */
export type GroupAggregatesFilter = {
  /** Mean average aggregate over matching `Group` objects. */
  average?: InputMaybe<GroupAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Group` objects. */
  distinctCount?: InputMaybe<GroupDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Group` object to be included within the aggregate. */
  filter?: InputMaybe<GroupFilter>;
  /** Maximum aggregate over matching `Group` objects. */
  max?: InputMaybe<GroupMaxAggregateFilter>;
  /** Minimum aggregate over matching `Group` objects. */
  min?: InputMaybe<GroupMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Group` objects. */
  stddevPopulation?: InputMaybe<GroupStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Group` objects. */
  stddevSample?: InputMaybe<GroupStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Group` objects. */
  sum?: InputMaybe<GroupSumAggregateFilter>;
  /** Population variance aggregate over matching `Group` objects. */
  variancePopulation?: InputMaybe<GroupVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Group` objects. */
  varianceSample?: InputMaybe<GroupVarianceSampleAggregateFilter>;
};

export type GroupAverageAggregateFilter = {
  countOfMembers?: InputMaybe<BigIntFilter>;
};

export type GroupAverageAggregates = {
  __typename?: 'GroupAverageAggregates';
  /** Mean average of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigFloat']>;
};


export type GroupAverageAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

/** A condition to be used against `Group` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GroupCondition = {
  /** Checks for equality with the object’s `canJoin` field. */
  canJoin?: InputMaybe<GroupVisibility>;
  /** Checks for equality with the object’s `canManage` field. */
  canManage?: InputMaybe<GroupRole>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `creatorId` field. */
  creatorId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `externalNames` field. */
  externalNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `flags` field. */
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `homeId` field. */
  homeId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `myEffectiveRole` field. */
  myEffectiveRole?: InputMaybe<GroupRole>;
  /** Checks for equality with the object’s `myMembershipExists` field. */
  myMembershipExists?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type GroupDistinctCountAggregateFilter = {
  canJoin?: InputMaybe<BigIntFilter>;
  canManage?: InputMaybe<BigIntFilter>;
  countOfMembers?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  creatorId?: InputMaybe<BigIntFilter>;
  externalNames?: InputMaybe<BigIntFilter>;
  flags?: InputMaybe<BigIntFilter>;
  homeId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  myEffectiveRole?: InputMaybe<GroupRoleFilter>;
  myMembershipExists?: InputMaybe<BooleanFilter>;
  name?: InputMaybe<BigIntFilter>;
  tenantId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type GroupDistinctCountAggregates = {
  __typename?: 'GroupDistinctCountAggregates';
  /** Distinct count of canJoin across the matching connection */
  canJoin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of canManage across the matching connection */
  canManage?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of creatorId across the matching connection */
  creatorId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of externalNames across the matching connection */
  externalNames?: Maybe<Scalars['BigInt']>;
  /** Distinct count of flags across the matching connection */
  flags?: Maybe<Scalars['BigInt']>;
  /** Distinct count of homeId across the matching connection */
  homeId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  myEffectiveRole?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  myMembership?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  myMembershipExists?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenantId across the matching connection */
  tenantId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};


export type GroupDistinctCountAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};


export type GroupDistinctCountAggregatesMyMembershipExistsArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

/** A filter to be used against `Group` object types. All fields are combined with a logical ‘and.’ */
export type GroupFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GroupFilter>>;
  /** Filter by the object’s `canJoin` field. */
  canJoin?: InputMaybe<GroupVisibilityFilter>;
  /** Filter by the object’s `canManage` field. */
  canManage?: InputMaybe<GroupRoleFilter>;
  /** Filter by the object’s `countOfMembers` field. */
  countOfMembers?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `creator` relation. */
  creator?: InputMaybe<UserFilter>;
  /** A related `creator` exists. */
  creatorExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `creatorId` field. */
  creatorId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `externalNames` field. */
  externalNames?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `flags` field. */
  flags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `home` relation. */
  home?: InputMaybe<GroupHomeFilter>;
  /** A related `home` exists. */
  homeExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `homeId` field. */
  homeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `membershipInvitations` relation. */
  membershipInvitations?: InputMaybe<GroupToManyMembershipInvitationFilter>;
  /** Filter by the object’s `membershipInvitationsByInvitedGroupId` relation. */
  membershipInvitationsByInvitedGroupId?: InputMaybe<GroupToManyMembershipInvitationFilter>;
  /** Some related `membershipInvitationsByInvitedGroupId` exist. */
  membershipInvitationsByInvitedGroupIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Some related `membershipInvitations` exist. */
  membershipInvitationsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `memberships` relation. */
  memberships?: InputMaybe<GroupToManyMembershipFilter>;
  /** Filter by the object’s `membershipsBySubgroupId` relation. */
  membershipsBySubgroupId?: InputMaybe<GroupToManyMembershipFilter>;
  /** Some related `membershipsBySubgroupId` exist. */
  membershipsBySubgroupIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Some related `memberships` exist. */
  membershipsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `myEffectiveRole` field. */
  myEffectiveRole?: InputMaybe<GroupRoleFilter>;
  /** Filter by the object’s `myMembershipExists` field. */
  myMembershipExists?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GroupFilter>>;
  /** Filter by the object’s `privateConversations` relation. */
  privateConversations?: InputMaybe<GroupToManyConversationFilter>;
  /** Some related `privateConversations` exist. */
  privateConversationsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `tenant` relation. */
  tenant?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `trialsByStaffId` relation. */
  trialsByStaffId?: InputMaybe<GroupToManyTrialFilter>;
  /** Some related `trialsByStaffId` exist. */
  trialsByStaffIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

export type GroupHome = Node & {
  __typename?: 'GroupHome';
  /** Reads and enables pagination through a set of `Group`. */
  groupsByHomeId: GroupsConnection;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  origin: Scalars['RegClass'];
  registeredAt: Scalars['Datetime'];
  /** Reads a single `Trial` that is related to this `GroupHome`. */
  trialById?: Maybe<Trial>;
  /**
   * Reads and enables pagination through a set of `Trial`.
   * @deprecated Please use trialById instead
   */
  trialsById: TrialsConnection;
};


export type GroupHomeGroupsByHomeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GroupCondition>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};


export type GroupHomeTrialsByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

export type GroupHomeAggregates = {
  __typename?: 'GroupHomeAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<GroupHomeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<GroupHomeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<GroupHomeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<GroupHomeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<GroupHomeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<GroupHomeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<GroupHomeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<GroupHomeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<GroupHomeVarianceSampleAggregates>;
};

export type GroupHomeAverageAggregates = {
  __typename?: 'GroupHomeAverageAggregates';
  /** Mean average of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `GroupHome` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GroupHomeCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `origin` field. */
  origin?: InputMaybe<Scalars['RegClass']>;
  /** Checks for equality with the object’s `registeredAt` field. */
  registeredAt?: InputMaybe<Scalars['Datetime']>;
};

export type GroupHomeDistinctCountAggregates = {
  __typename?: 'GroupHomeDistinctCountAggregates';
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of origin across the matching connection */
  origin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of registeredAt across the matching connection */
  registeredAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `GroupHome` object types. All fields are combined with a logical ‘and.’ */
export type GroupHomeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GroupHomeFilter>>;
  /** Filter by the object’s `groupsByHomeId` relation. */
  groupsByHomeId?: InputMaybe<GroupHomeToManyGroupFilter>;
  /** Some related `groupsByHomeId` exist. */
  groupsByHomeIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GroupHomeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GroupHomeFilter>>;
  /** Filter by the object’s `registeredAt` field. */
  registeredAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `trialById` relation. */
  trialById?: InputMaybe<TrialFilter>;
  /** A related `trialById` exists. */
  trialByIdExists?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `GroupHome` */
export type GroupHomeInput = {
  id: Scalars['UUID'];
  origin: Scalars['RegClass'];
  registeredAt?: InputMaybe<Scalars['Datetime']>;
};

export type GroupHomeMaxAggregates = {
  __typename?: 'GroupHomeMaxAggregates';
  /** Maximum of origin across the matching connection */
  origin?: Maybe<Scalars['RegClass']>;
};

export type GroupHomeMinAggregates = {
  __typename?: 'GroupHomeMinAggregates';
  /** Minimum of origin across the matching connection */
  origin?: Maybe<Scalars['RegClass']>;
};

/** Represents an update to a `GroupHome`. Fields that are set will be updated. */
export type GroupHomePatch = {
  id?: InputMaybe<Scalars['UUID']>;
  origin?: InputMaybe<Scalars['RegClass']>;
  registeredAt?: InputMaybe<Scalars['Datetime']>;
};

export type GroupHomeStddevPopulationAggregates = {
  __typename?: 'GroupHomeStddevPopulationAggregates';
  /** Population standard deviation of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

export type GroupHomeStddevSampleAggregates = {
  __typename?: 'GroupHomeStddevSampleAggregates';
  /** Sample standard deviation of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

export type GroupHomeSumAggregates = {
  __typename?: 'GroupHomeSumAggregates';
  /** Sum of origin across the matching connection */
  origin: Scalars['BigFloat'];
};

/** A filter to be used against many `Group` object types. All fields are combined with a logical ‘and.’ */
export type GroupHomeToManyGroupFilter = {
  /** Aggregates across related `Group` match the filter criteria. */
  aggregates?: InputMaybe<GroupAggregatesFilter>;
  /** Every related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<GroupFilter>;
  /** No related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<GroupFilter>;
  /** Some related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<GroupFilter>;
};

export type GroupHomeVariancePopulationAggregates = {
  __typename?: 'GroupHomeVariancePopulationAggregates';
  /** Population variance of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

export type GroupHomeVarianceSampleAggregates = {
  __typename?: 'GroupHomeVarianceSampleAggregates';
  /** Sample variance of origin across the matching connection */
  origin?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `GroupHome` values. */
export type GroupHomesConnection = {
  __typename?: 'GroupHomesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupHomeAggregates>;
  /** A list of edges which contains the `GroupHome` and cursor to aid in pagination. */
  edges: Array<GroupHomesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupHomeAggregates>>;
  /** A list of `GroupHome` objects. */
  nodes: Array<GroupHome>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GroupHome` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `GroupHome` values. */
export type GroupHomesConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupHomesGroupBy>;
  having?: InputMaybe<GroupHomesHavingInput>;
};

/** A `GroupHome` edge in the connection. */
export type GroupHomesEdge = {
  __typename?: 'GroupHomesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GroupHome` at the end of the edge. */
  node: GroupHome;
};

/** Grouping methods for `GroupHome` for usage during aggregation. */
export enum GroupHomesGroupBy {
  Origin = 'ORIGIN',
  RegisteredAt = 'REGISTERED_AT',
  RegisteredAtTruncatedToDay = 'REGISTERED_AT_TRUNCATED_TO_DAY',
  RegisteredAtTruncatedToHour = 'REGISTERED_AT_TRUNCATED_TO_HOUR'
}

export type GroupHomesHavingAverageInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupHomesHavingDistinctCountInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `GroupHome` aggregates. */
export type GroupHomesHavingInput = {
  AND?: InputMaybe<Array<GroupHomesHavingInput>>;
  OR?: InputMaybe<Array<GroupHomesHavingInput>>;
  average?: InputMaybe<GroupHomesHavingAverageInput>;
  distinctCount?: InputMaybe<GroupHomesHavingDistinctCountInput>;
  max?: InputMaybe<GroupHomesHavingMaxInput>;
  min?: InputMaybe<GroupHomesHavingMinInput>;
  stddevPopulation?: InputMaybe<GroupHomesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<GroupHomesHavingStddevSampleInput>;
  sum?: InputMaybe<GroupHomesHavingSumInput>;
  variancePopulation?: InputMaybe<GroupHomesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<GroupHomesHavingVarianceSampleInput>;
};

export type GroupHomesHavingMaxInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupHomesHavingMinInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupHomesHavingStddevPopulationInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupHomesHavingStddevSampleInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupHomesHavingSumInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupHomesHavingVariancePopulationInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupHomesHavingVarianceSampleInput = {
  registeredAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `GroupHome`. */
export enum GroupHomesOrderBy {
  GroupsByHomeIdAverageCanJoinAsc = 'GROUPS_BY_HOME_ID_AVERAGE_CAN_JOIN_ASC',
  GroupsByHomeIdAverageCanJoinDesc = 'GROUPS_BY_HOME_ID_AVERAGE_CAN_JOIN_DESC',
  GroupsByHomeIdAverageCanManageAsc = 'GROUPS_BY_HOME_ID_AVERAGE_CAN_MANAGE_ASC',
  GroupsByHomeIdAverageCanManageDesc = 'GROUPS_BY_HOME_ID_AVERAGE_CAN_MANAGE_DESC',
  GroupsByHomeIdAverageCreatedAtAsc = 'GROUPS_BY_HOME_ID_AVERAGE_CREATED_AT_ASC',
  GroupsByHomeIdAverageCreatedAtDesc = 'GROUPS_BY_HOME_ID_AVERAGE_CREATED_AT_DESC',
  GroupsByHomeIdAverageCreatorIdAsc = 'GROUPS_BY_HOME_ID_AVERAGE_CREATOR_ID_ASC',
  GroupsByHomeIdAverageCreatorIdDesc = 'GROUPS_BY_HOME_ID_AVERAGE_CREATOR_ID_DESC',
  GroupsByHomeIdAverageExternalNamesAsc = 'GROUPS_BY_HOME_ID_AVERAGE_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdAverageExternalNamesDesc = 'GROUPS_BY_HOME_ID_AVERAGE_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdAverageFlagsAsc = 'GROUPS_BY_HOME_ID_AVERAGE_FLAGS_ASC',
  GroupsByHomeIdAverageFlagsDesc = 'GROUPS_BY_HOME_ID_AVERAGE_FLAGS_DESC',
  GroupsByHomeIdAverageHomeIdAsc = 'GROUPS_BY_HOME_ID_AVERAGE_HOME_ID_ASC',
  GroupsByHomeIdAverageHomeIdDesc = 'GROUPS_BY_HOME_ID_AVERAGE_HOME_ID_DESC',
  GroupsByHomeIdAverageIdAsc = 'GROUPS_BY_HOME_ID_AVERAGE_ID_ASC',
  GroupsByHomeIdAverageIdDesc = 'GROUPS_BY_HOME_ID_AVERAGE_ID_DESC',
  GroupsByHomeIdAverageNameAsc = 'GROUPS_BY_HOME_ID_AVERAGE_NAME_ASC',
  GroupsByHomeIdAverageNameDesc = 'GROUPS_BY_HOME_ID_AVERAGE_NAME_DESC',
  GroupsByHomeIdAverageTenantIdAsc = 'GROUPS_BY_HOME_ID_AVERAGE_TENANT_ID_ASC',
  GroupsByHomeIdAverageTenantIdDesc = 'GROUPS_BY_HOME_ID_AVERAGE_TENANT_ID_DESC',
  GroupsByHomeIdAverageUpdatedAtAsc = 'GROUPS_BY_HOME_ID_AVERAGE_UPDATED_AT_ASC',
  GroupsByHomeIdAverageUpdatedAtDesc = 'GROUPS_BY_HOME_ID_AVERAGE_UPDATED_AT_DESC',
  GroupsByHomeIdCountAsc = 'GROUPS_BY_HOME_ID_COUNT_ASC',
  GroupsByHomeIdCountDesc = 'GROUPS_BY_HOME_ID_COUNT_DESC',
  GroupsByHomeIdDistinctCountCanJoinAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_CAN_JOIN_ASC',
  GroupsByHomeIdDistinctCountCanJoinDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_CAN_JOIN_DESC',
  GroupsByHomeIdDistinctCountCanManageAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_CAN_MANAGE_ASC',
  GroupsByHomeIdDistinctCountCanManageDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_CAN_MANAGE_DESC',
  GroupsByHomeIdDistinctCountCreatedAtAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  GroupsByHomeIdDistinctCountCreatedAtDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  GroupsByHomeIdDistinctCountCreatorIdAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_CREATOR_ID_ASC',
  GroupsByHomeIdDistinctCountCreatorIdDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_CREATOR_ID_DESC',
  GroupsByHomeIdDistinctCountExternalNamesAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdDistinctCountExternalNamesDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdDistinctCountFlagsAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_FLAGS_ASC',
  GroupsByHomeIdDistinctCountFlagsDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_FLAGS_DESC',
  GroupsByHomeIdDistinctCountHomeIdAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_HOME_ID_ASC',
  GroupsByHomeIdDistinctCountHomeIdDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_HOME_ID_DESC',
  GroupsByHomeIdDistinctCountIdAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_ID_ASC',
  GroupsByHomeIdDistinctCountIdDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_ID_DESC',
  GroupsByHomeIdDistinctCountNameAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_NAME_ASC',
  GroupsByHomeIdDistinctCountNameDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_NAME_DESC',
  GroupsByHomeIdDistinctCountTenantIdAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  GroupsByHomeIdDistinctCountTenantIdDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  GroupsByHomeIdDistinctCountUpdatedAtAsc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  GroupsByHomeIdDistinctCountUpdatedAtDesc = 'GROUPS_BY_HOME_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  GroupsByHomeIdMaxCanJoinAsc = 'GROUPS_BY_HOME_ID_MAX_CAN_JOIN_ASC',
  GroupsByHomeIdMaxCanJoinDesc = 'GROUPS_BY_HOME_ID_MAX_CAN_JOIN_DESC',
  GroupsByHomeIdMaxCanManageAsc = 'GROUPS_BY_HOME_ID_MAX_CAN_MANAGE_ASC',
  GroupsByHomeIdMaxCanManageDesc = 'GROUPS_BY_HOME_ID_MAX_CAN_MANAGE_DESC',
  GroupsByHomeIdMaxCreatedAtAsc = 'GROUPS_BY_HOME_ID_MAX_CREATED_AT_ASC',
  GroupsByHomeIdMaxCreatedAtDesc = 'GROUPS_BY_HOME_ID_MAX_CREATED_AT_DESC',
  GroupsByHomeIdMaxCreatorIdAsc = 'GROUPS_BY_HOME_ID_MAX_CREATOR_ID_ASC',
  GroupsByHomeIdMaxCreatorIdDesc = 'GROUPS_BY_HOME_ID_MAX_CREATOR_ID_DESC',
  GroupsByHomeIdMaxExternalNamesAsc = 'GROUPS_BY_HOME_ID_MAX_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdMaxExternalNamesDesc = 'GROUPS_BY_HOME_ID_MAX_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdMaxFlagsAsc = 'GROUPS_BY_HOME_ID_MAX_FLAGS_ASC',
  GroupsByHomeIdMaxFlagsDesc = 'GROUPS_BY_HOME_ID_MAX_FLAGS_DESC',
  GroupsByHomeIdMaxHomeIdAsc = 'GROUPS_BY_HOME_ID_MAX_HOME_ID_ASC',
  GroupsByHomeIdMaxHomeIdDesc = 'GROUPS_BY_HOME_ID_MAX_HOME_ID_DESC',
  GroupsByHomeIdMaxIdAsc = 'GROUPS_BY_HOME_ID_MAX_ID_ASC',
  GroupsByHomeIdMaxIdDesc = 'GROUPS_BY_HOME_ID_MAX_ID_DESC',
  GroupsByHomeIdMaxNameAsc = 'GROUPS_BY_HOME_ID_MAX_NAME_ASC',
  GroupsByHomeIdMaxNameDesc = 'GROUPS_BY_HOME_ID_MAX_NAME_DESC',
  GroupsByHomeIdMaxTenantIdAsc = 'GROUPS_BY_HOME_ID_MAX_TENANT_ID_ASC',
  GroupsByHomeIdMaxTenantIdDesc = 'GROUPS_BY_HOME_ID_MAX_TENANT_ID_DESC',
  GroupsByHomeIdMaxUpdatedAtAsc = 'GROUPS_BY_HOME_ID_MAX_UPDATED_AT_ASC',
  GroupsByHomeIdMaxUpdatedAtDesc = 'GROUPS_BY_HOME_ID_MAX_UPDATED_AT_DESC',
  GroupsByHomeIdMinCanJoinAsc = 'GROUPS_BY_HOME_ID_MIN_CAN_JOIN_ASC',
  GroupsByHomeIdMinCanJoinDesc = 'GROUPS_BY_HOME_ID_MIN_CAN_JOIN_DESC',
  GroupsByHomeIdMinCanManageAsc = 'GROUPS_BY_HOME_ID_MIN_CAN_MANAGE_ASC',
  GroupsByHomeIdMinCanManageDesc = 'GROUPS_BY_HOME_ID_MIN_CAN_MANAGE_DESC',
  GroupsByHomeIdMinCreatedAtAsc = 'GROUPS_BY_HOME_ID_MIN_CREATED_AT_ASC',
  GroupsByHomeIdMinCreatedAtDesc = 'GROUPS_BY_HOME_ID_MIN_CREATED_AT_DESC',
  GroupsByHomeIdMinCreatorIdAsc = 'GROUPS_BY_HOME_ID_MIN_CREATOR_ID_ASC',
  GroupsByHomeIdMinCreatorIdDesc = 'GROUPS_BY_HOME_ID_MIN_CREATOR_ID_DESC',
  GroupsByHomeIdMinExternalNamesAsc = 'GROUPS_BY_HOME_ID_MIN_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdMinExternalNamesDesc = 'GROUPS_BY_HOME_ID_MIN_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdMinFlagsAsc = 'GROUPS_BY_HOME_ID_MIN_FLAGS_ASC',
  GroupsByHomeIdMinFlagsDesc = 'GROUPS_BY_HOME_ID_MIN_FLAGS_DESC',
  GroupsByHomeIdMinHomeIdAsc = 'GROUPS_BY_HOME_ID_MIN_HOME_ID_ASC',
  GroupsByHomeIdMinHomeIdDesc = 'GROUPS_BY_HOME_ID_MIN_HOME_ID_DESC',
  GroupsByHomeIdMinIdAsc = 'GROUPS_BY_HOME_ID_MIN_ID_ASC',
  GroupsByHomeIdMinIdDesc = 'GROUPS_BY_HOME_ID_MIN_ID_DESC',
  GroupsByHomeIdMinNameAsc = 'GROUPS_BY_HOME_ID_MIN_NAME_ASC',
  GroupsByHomeIdMinNameDesc = 'GROUPS_BY_HOME_ID_MIN_NAME_DESC',
  GroupsByHomeIdMinTenantIdAsc = 'GROUPS_BY_HOME_ID_MIN_TENANT_ID_ASC',
  GroupsByHomeIdMinTenantIdDesc = 'GROUPS_BY_HOME_ID_MIN_TENANT_ID_DESC',
  GroupsByHomeIdMinUpdatedAtAsc = 'GROUPS_BY_HOME_ID_MIN_UPDATED_AT_ASC',
  GroupsByHomeIdMinUpdatedAtDesc = 'GROUPS_BY_HOME_ID_MIN_UPDATED_AT_DESC',
  GroupsByHomeIdStddevPopulationCanJoinAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_CAN_JOIN_ASC',
  GroupsByHomeIdStddevPopulationCanJoinDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_CAN_JOIN_DESC',
  GroupsByHomeIdStddevPopulationCanManageAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_CAN_MANAGE_ASC',
  GroupsByHomeIdStddevPopulationCanManageDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_CAN_MANAGE_DESC',
  GroupsByHomeIdStddevPopulationCreatedAtAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  GroupsByHomeIdStddevPopulationCreatedAtDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  GroupsByHomeIdStddevPopulationCreatorIdAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_CREATOR_ID_ASC',
  GroupsByHomeIdStddevPopulationCreatorIdDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_CREATOR_ID_DESC',
  GroupsByHomeIdStddevPopulationExternalNamesAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdStddevPopulationExternalNamesDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdStddevPopulationFlagsAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_FLAGS_ASC',
  GroupsByHomeIdStddevPopulationFlagsDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_FLAGS_DESC',
  GroupsByHomeIdStddevPopulationHomeIdAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_HOME_ID_ASC',
  GroupsByHomeIdStddevPopulationHomeIdDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_HOME_ID_DESC',
  GroupsByHomeIdStddevPopulationIdAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_ID_ASC',
  GroupsByHomeIdStddevPopulationIdDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_ID_DESC',
  GroupsByHomeIdStddevPopulationNameAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_NAME_ASC',
  GroupsByHomeIdStddevPopulationNameDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_NAME_DESC',
  GroupsByHomeIdStddevPopulationTenantIdAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  GroupsByHomeIdStddevPopulationTenantIdDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  GroupsByHomeIdStddevPopulationUpdatedAtAsc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  GroupsByHomeIdStddevPopulationUpdatedAtDesc = 'GROUPS_BY_HOME_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  GroupsByHomeIdStddevSampleCanJoinAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_CAN_JOIN_ASC',
  GroupsByHomeIdStddevSampleCanJoinDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_CAN_JOIN_DESC',
  GroupsByHomeIdStddevSampleCanManageAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_CAN_MANAGE_ASC',
  GroupsByHomeIdStddevSampleCanManageDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_CAN_MANAGE_DESC',
  GroupsByHomeIdStddevSampleCreatedAtAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  GroupsByHomeIdStddevSampleCreatedAtDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  GroupsByHomeIdStddevSampleCreatorIdAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_CREATOR_ID_ASC',
  GroupsByHomeIdStddevSampleCreatorIdDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_CREATOR_ID_DESC',
  GroupsByHomeIdStddevSampleExternalNamesAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdStddevSampleExternalNamesDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdStddevSampleFlagsAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_FLAGS_ASC',
  GroupsByHomeIdStddevSampleFlagsDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_FLAGS_DESC',
  GroupsByHomeIdStddevSampleHomeIdAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_HOME_ID_ASC',
  GroupsByHomeIdStddevSampleHomeIdDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_HOME_ID_DESC',
  GroupsByHomeIdStddevSampleIdAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_ID_ASC',
  GroupsByHomeIdStddevSampleIdDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_ID_DESC',
  GroupsByHomeIdStddevSampleNameAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_NAME_ASC',
  GroupsByHomeIdStddevSampleNameDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_NAME_DESC',
  GroupsByHomeIdStddevSampleTenantIdAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  GroupsByHomeIdStddevSampleTenantIdDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  GroupsByHomeIdStddevSampleUpdatedAtAsc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  GroupsByHomeIdStddevSampleUpdatedAtDesc = 'GROUPS_BY_HOME_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  GroupsByHomeIdSumCanJoinAsc = 'GROUPS_BY_HOME_ID_SUM_CAN_JOIN_ASC',
  GroupsByHomeIdSumCanJoinDesc = 'GROUPS_BY_HOME_ID_SUM_CAN_JOIN_DESC',
  GroupsByHomeIdSumCanManageAsc = 'GROUPS_BY_HOME_ID_SUM_CAN_MANAGE_ASC',
  GroupsByHomeIdSumCanManageDesc = 'GROUPS_BY_HOME_ID_SUM_CAN_MANAGE_DESC',
  GroupsByHomeIdSumCreatedAtAsc = 'GROUPS_BY_HOME_ID_SUM_CREATED_AT_ASC',
  GroupsByHomeIdSumCreatedAtDesc = 'GROUPS_BY_HOME_ID_SUM_CREATED_AT_DESC',
  GroupsByHomeIdSumCreatorIdAsc = 'GROUPS_BY_HOME_ID_SUM_CREATOR_ID_ASC',
  GroupsByHomeIdSumCreatorIdDesc = 'GROUPS_BY_HOME_ID_SUM_CREATOR_ID_DESC',
  GroupsByHomeIdSumExternalNamesAsc = 'GROUPS_BY_HOME_ID_SUM_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdSumExternalNamesDesc = 'GROUPS_BY_HOME_ID_SUM_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdSumFlagsAsc = 'GROUPS_BY_HOME_ID_SUM_FLAGS_ASC',
  GroupsByHomeIdSumFlagsDesc = 'GROUPS_BY_HOME_ID_SUM_FLAGS_DESC',
  GroupsByHomeIdSumHomeIdAsc = 'GROUPS_BY_HOME_ID_SUM_HOME_ID_ASC',
  GroupsByHomeIdSumHomeIdDesc = 'GROUPS_BY_HOME_ID_SUM_HOME_ID_DESC',
  GroupsByHomeIdSumIdAsc = 'GROUPS_BY_HOME_ID_SUM_ID_ASC',
  GroupsByHomeIdSumIdDesc = 'GROUPS_BY_HOME_ID_SUM_ID_DESC',
  GroupsByHomeIdSumNameAsc = 'GROUPS_BY_HOME_ID_SUM_NAME_ASC',
  GroupsByHomeIdSumNameDesc = 'GROUPS_BY_HOME_ID_SUM_NAME_DESC',
  GroupsByHomeIdSumTenantIdAsc = 'GROUPS_BY_HOME_ID_SUM_TENANT_ID_ASC',
  GroupsByHomeIdSumTenantIdDesc = 'GROUPS_BY_HOME_ID_SUM_TENANT_ID_DESC',
  GroupsByHomeIdSumUpdatedAtAsc = 'GROUPS_BY_HOME_ID_SUM_UPDATED_AT_ASC',
  GroupsByHomeIdSumUpdatedAtDesc = 'GROUPS_BY_HOME_ID_SUM_UPDATED_AT_DESC',
  GroupsByHomeIdVariancePopulationCanJoinAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_CAN_JOIN_ASC',
  GroupsByHomeIdVariancePopulationCanJoinDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_CAN_JOIN_DESC',
  GroupsByHomeIdVariancePopulationCanManageAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_CAN_MANAGE_ASC',
  GroupsByHomeIdVariancePopulationCanManageDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_CAN_MANAGE_DESC',
  GroupsByHomeIdVariancePopulationCreatedAtAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  GroupsByHomeIdVariancePopulationCreatedAtDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  GroupsByHomeIdVariancePopulationCreatorIdAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_CREATOR_ID_ASC',
  GroupsByHomeIdVariancePopulationCreatorIdDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_CREATOR_ID_DESC',
  GroupsByHomeIdVariancePopulationExternalNamesAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdVariancePopulationExternalNamesDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdVariancePopulationFlagsAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_FLAGS_ASC',
  GroupsByHomeIdVariancePopulationFlagsDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_FLAGS_DESC',
  GroupsByHomeIdVariancePopulationHomeIdAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_HOME_ID_ASC',
  GroupsByHomeIdVariancePopulationHomeIdDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_HOME_ID_DESC',
  GroupsByHomeIdVariancePopulationIdAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_ID_ASC',
  GroupsByHomeIdVariancePopulationIdDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_ID_DESC',
  GroupsByHomeIdVariancePopulationNameAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_NAME_ASC',
  GroupsByHomeIdVariancePopulationNameDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_NAME_DESC',
  GroupsByHomeIdVariancePopulationTenantIdAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  GroupsByHomeIdVariancePopulationTenantIdDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  GroupsByHomeIdVariancePopulationUpdatedAtAsc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  GroupsByHomeIdVariancePopulationUpdatedAtDesc = 'GROUPS_BY_HOME_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  GroupsByHomeIdVarianceSampleCanJoinAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_CAN_JOIN_ASC',
  GroupsByHomeIdVarianceSampleCanJoinDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_CAN_JOIN_DESC',
  GroupsByHomeIdVarianceSampleCanManageAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_CAN_MANAGE_ASC',
  GroupsByHomeIdVarianceSampleCanManageDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_CAN_MANAGE_DESC',
  GroupsByHomeIdVarianceSampleCreatedAtAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GroupsByHomeIdVarianceSampleCreatedAtDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GroupsByHomeIdVarianceSampleCreatorIdAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_CREATOR_ID_ASC',
  GroupsByHomeIdVarianceSampleCreatorIdDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_CREATOR_ID_DESC',
  GroupsByHomeIdVarianceSampleExternalNamesAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_EXTERNAL_NAMES_ASC',
  GroupsByHomeIdVarianceSampleExternalNamesDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_EXTERNAL_NAMES_DESC',
  GroupsByHomeIdVarianceSampleFlagsAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_FLAGS_ASC',
  GroupsByHomeIdVarianceSampleFlagsDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_FLAGS_DESC',
  GroupsByHomeIdVarianceSampleHomeIdAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_HOME_ID_ASC',
  GroupsByHomeIdVarianceSampleHomeIdDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_HOME_ID_DESC',
  GroupsByHomeIdVarianceSampleIdAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_ID_ASC',
  GroupsByHomeIdVarianceSampleIdDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_ID_DESC',
  GroupsByHomeIdVarianceSampleNameAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_NAME_ASC',
  GroupsByHomeIdVarianceSampleNameDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_NAME_DESC',
  GroupsByHomeIdVarianceSampleTenantIdAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  GroupsByHomeIdVarianceSampleTenantIdDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  GroupsByHomeIdVarianceSampleUpdatedAtAsc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  GroupsByHomeIdVarianceSampleUpdatedAtDesc = 'GROUPS_BY_HOME_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  OriginAsc = 'ORIGIN_ASC',
  OriginDesc = 'ORIGIN_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RegisteredAtAsc = 'REGISTERED_AT_ASC',
  RegisteredAtDesc = 'REGISTERED_AT_DESC'
}

/** An input for mutations affecting `Group` */
export type GroupInput = {
  canJoin?: InputMaybe<GroupVisibility>;
  canManage?: InputMaybe<GroupRole>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  creatorId?: InputMaybe<Scalars['UUID']>;
  externalNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type GroupMaxAggregateFilter = {
  countOfMembers?: InputMaybe<BigIntFilter>;
};

export type GroupMaxAggregates = {
  __typename?: 'GroupMaxAggregates';
  /** Maximum of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigInt']>;
};


export type GroupMaxAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupMinAggregateFilter = {
  countOfMembers?: InputMaybe<BigIntFilter>;
};

export type GroupMinAggregates = {
  __typename?: 'GroupMinAggregates';
  /** Minimum of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigInt']>;
};


export type GroupMinAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

/** Represents an update to a `Group`. Fields that are set will be updated. */
export type GroupPatch = {
  canJoin?: InputMaybe<GroupVisibility>;
  canManage?: InputMaybe<GroupRole>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  creatorId?: InputMaybe<Scalars['UUID']>;
  externalNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  homeId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export enum GroupRole {
  Banned = 'BANNED',
  Guest = 'GUEST',
  Member = 'MEMBER',
  Moderator = 'MODERATOR',
  Owner = 'OWNER'
}

/** A filter to be used against GroupRole fields. All fields are combined with a logical ‘and.’ */
export type GroupRoleFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<GroupRole>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<GroupRole>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<GroupRole>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<GroupRole>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<GroupRole>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<GroupRole>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<GroupRole>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<GroupRole>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<GroupRole>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<GroupRole>>;
};

export type GroupStddevPopulationAggregateFilter = {
  countOfMembers?: InputMaybe<BigIntFilter>;
};

export type GroupStddevPopulationAggregates = {
  __typename?: 'GroupStddevPopulationAggregates';
  /** Population standard deviation of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigFloat']>;
};


export type GroupStddevPopulationAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupStddevSampleAggregateFilter = {
  countOfMembers?: InputMaybe<BigIntFilter>;
};

export type GroupStddevSampleAggregates = {
  __typename?: 'GroupStddevSampleAggregates';
  /** Sample standard deviation of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigFloat']>;
};


export type GroupStddevSampleAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupSumAggregateFilter = {
  countOfMembers?: InputMaybe<BigIntFilter>;
};

export type GroupSumAggregates = {
  __typename?: 'GroupSumAggregates';
  /** Sum of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigFloat']>;
};


export type GroupSumAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

/** A filter to be used against many `Conversation` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyConversationFilter = {
  /** Aggregates across related `Conversation` match the filter criteria. */
  aggregates?: InputMaybe<ConversationAggregatesFilter>;
  /** Every related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConversationFilter>;
  /** No related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConversationFilter>;
  /** Some related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConversationFilter>;
};

/** A filter to be used against many `Membership` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyMembershipFilter = {
  /** Aggregates across related `Membership` match the filter criteria. */
  aggregates?: InputMaybe<MembershipAggregatesFilter>;
  /** Every related `Membership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MembershipFilter>;
  /** No related `Membership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MembershipFilter>;
  /** Some related `Membership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MembershipFilter>;
};

/** A filter to be used against many `MembershipInvitation` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyMembershipInvitationFilter = {
  /** Aggregates across related `MembershipInvitation` match the filter criteria. */
  aggregates?: InputMaybe<MembershipInvitationAggregatesFilter>;
  /** Every related `MembershipInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MembershipInvitationFilter>;
  /** No related `MembershipInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MembershipInvitationFilter>;
  /** Some related `MembershipInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MembershipInvitationFilter>;
};

/** A filter to be used against many `Trial` object types. All fields are combined with a logical ‘and.’ */
export type GroupToManyTrialFilter = {
  /** Aggregates across related `Trial` match the filter criteria. */
  aggregates?: InputMaybe<TrialAggregatesFilter>;
  /** Every related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TrialFilter>;
  /** No related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TrialFilter>;
  /** Some related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TrialFilter>;
};

export type GroupVariancePopulationAggregateFilter = {
  countOfMembers?: InputMaybe<BigIntFilter>;
};

export type GroupVariancePopulationAggregates = {
  __typename?: 'GroupVariancePopulationAggregates';
  /** Population variance of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigFloat']>;
};


export type GroupVariancePopulationAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupVarianceSampleAggregateFilter = {
  countOfMembers?: InputMaybe<BigIntFilter>;
};

export type GroupVarianceSampleAggregates = {
  __typename?: 'GroupVarianceSampleAggregates';
  /** Sample variance of this field across the matching connection. */
  countOfMembers?: Maybe<Scalars['BigFloat']>;
};


export type GroupVarianceSampleAggregatesCountOfMembersArgs = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export enum GroupVisibility {
  Peers = 'PEERS',
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Users = 'USERS'
}

/** A filter to be used against GroupVisibility fields. All fields are combined with a logical ‘and.’ */
export type GroupVisibilityFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<GroupVisibility>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<GroupVisibility>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<GroupVisibility>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<GroupVisibility>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<GroupVisibility>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<GroupVisibility>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<GroupVisibility>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<GroupVisibility>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<GroupVisibility>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<GroupVisibility>>;
};

/** A connection to a list of `Group` values. */
export type GroupsConnection = {
  __typename?: 'GroupsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<GroupAggregates>;
  /** A list of edges which contains the `Group` and cursor to aid in pagination. */
  edges: Array<GroupsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<GroupAggregates>>;
  /** A list of `Group` objects. */
  nodes: Array<Group>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Group` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Group` values. */
export type GroupsConnectionGroupedAggregatesArgs = {
  groupBy: Array<GroupsGroupBy>;
  having?: InputMaybe<GroupsHavingInput>;
};

/** A `Group` edge in the connection. */
export type GroupsEdge = {
  __typename?: 'GroupsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Group` at the end of the edge. */
  node: Group;
};

/** Grouping methods for `Group` for usage during aggregation. */
export enum GroupsGroupBy {
  CanJoin = 'CAN_JOIN',
  CanManage = 'CAN_MANAGE',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatorId = 'CREATOR_ID',
  ExternalNames = 'EXTERNAL_NAMES',
  Flags = 'FLAGS',
  HomeId = 'HOME_ID',
  Name = 'NAME',
  TenantId = 'TENANT_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type GroupsHavingAverageGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingAverageGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingAverageGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingAverageInput = {
  countOfMembers?: InputMaybe<GroupsHavingAverageGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupsHavingDistinctCountGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingDistinctCountGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingDistinctCountGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingDistinctCountInput = {
  countOfMembers?: InputMaybe<GroupsHavingDistinctCountGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Group` aggregates. */
export type GroupsHavingInput = {
  AND?: InputMaybe<Array<GroupsHavingInput>>;
  OR?: InputMaybe<Array<GroupsHavingInput>>;
  average?: InputMaybe<GroupsHavingAverageInput>;
  distinctCount?: InputMaybe<GroupsHavingDistinctCountInput>;
  max?: InputMaybe<GroupsHavingMaxInput>;
  min?: InputMaybe<GroupsHavingMinInput>;
  stddevPopulation?: InputMaybe<GroupsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<GroupsHavingStddevSampleInput>;
  sum?: InputMaybe<GroupsHavingSumInput>;
  variancePopulation?: InputMaybe<GroupsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<GroupsHavingVarianceSampleInput>;
};

export type GroupsHavingMaxGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingMaxGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingMaxGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingMaxInput = {
  countOfMembers?: InputMaybe<GroupsHavingMaxGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupsHavingMinGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingMinGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingMinGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingMinInput = {
  countOfMembers?: InputMaybe<GroupsHavingMinGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupsHavingStddevPopulationGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingStddevPopulationGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingStddevPopulationGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingStddevPopulationInput = {
  countOfMembers?: InputMaybe<GroupsHavingStddevPopulationGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupsHavingStddevSampleGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingStddevSampleGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingStddevSampleGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingStddevSampleInput = {
  countOfMembers?: InputMaybe<GroupsHavingStddevSampleGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupsHavingSumGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingSumGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingSumGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingSumInput = {
  countOfMembers?: InputMaybe<GroupsHavingSumGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupsHavingVariancePopulationGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingVariancePopulationGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingVariancePopulationGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingVariancePopulationInput = {
  countOfMembers?: InputMaybe<GroupsHavingVariancePopulationGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type GroupsHavingVarianceSampleGroupsCountOfMembersArgsInput = {
  ifRoleIsAtLeast?: InputMaybe<GroupRole>;
  ifRoleIsAtMost?: InputMaybe<GroupRole>;
};

export type GroupsHavingVarianceSampleGroupsCountOfMembersInput = {
  args?: InputMaybe<GroupsHavingVarianceSampleGroupsCountOfMembersArgsInput>;
  filter?: InputMaybe<HavingBigintFilter>;
};

export type GroupsHavingVarianceSampleInput = {
  countOfMembers?: InputMaybe<GroupsHavingVarianceSampleGroupsCountOfMembersInput>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Group`. */
export enum GroupsOrderBy {
  CanJoinAsc = 'CAN_JOIN_ASC',
  CanJoinDesc = 'CAN_JOIN_DESC',
  CanManageAsc = 'CAN_MANAGE_ASC',
  CanManageDesc = 'CAN_MANAGE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatorIdAsc = 'CREATOR_ID_ASC',
  CreatorIdDesc = 'CREATOR_ID_DESC',
  ExternalNamesAsc = 'EXTERNAL_NAMES_ASC',
  ExternalNamesDesc = 'EXTERNAL_NAMES_DESC',
  FlagsAsc = 'FLAGS_ASC',
  FlagsDesc = 'FLAGS_DESC',
  HomeIdAsc = 'HOME_ID_ASC',
  HomeIdDesc = 'HOME_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  MembershipsAverageCreatedAtAsc = 'MEMBERSHIPS_AVERAGE_CREATED_AT_ASC',
  MembershipsAverageCreatedAtDesc = 'MEMBERSHIPS_AVERAGE_CREATED_AT_DESC',
  MembershipsAverageFlagsAsc = 'MEMBERSHIPS_AVERAGE_FLAGS_ASC',
  MembershipsAverageFlagsDesc = 'MEMBERSHIPS_AVERAGE_FLAGS_DESC',
  MembershipsAverageGroupIdAsc = 'MEMBERSHIPS_AVERAGE_GROUP_ID_ASC',
  MembershipsAverageGroupIdDesc = 'MEMBERSHIPS_AVERAGE_GROUP_ID_DESC',
  MembershipsAverageIdAsc = 'MEMBERSHIPS_AVERAGE_ID_ASC',
  MembershipsAverageIdDesc = 'MEMBERSHIPS_AVERAGE_ID_DESC',
  MembershipsAverageIsManagedExternallyAsc = 'MEMBERSHIPS_AVERAGE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsAverageIsManagedExternallyDesc = 'MEMBERSHIPS_AVERAGE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsAverageRoleAsc = 'MEMBERSHIPS_AVERAGE_ROLE_ASC',
  MembershipsAverageRoleDesc = 'MEMBERSHIPS_AVERAGE_ROLE_DESC',
  MembershipsAverageSubgroupIdAsc = 'MEMBERSHIPS_AVERAGE_SUBGROUP_ID_ASC',
  MembershipsAverageSubgroupIdDesc = 'MEMBERSHIPS_AVERAGE_SUBGROUP_ID_DESC',
  MembershipsAverageUpdatedAtAsc = 'MEMBERSHIPS_AVERAGE_UPDATED_AT_ASC',
  MembershipsAverageUpdatedAtDesc = 'MEMBERSHIPS_AVERAGE_UPDATED_AT_DESC',
  MembershipsAverageUserIdAsc = 'MEMBERSHIPS_AVERAGE_USER_ID_ASC',
  MembershipsAverageUserIdDesc = 'MEMBERSHIPS_AVERAGE_USER_ID_DESC',
  MembershipsBySubgroupIdAverageCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_CREATED_AT_ASC',
  MembershipsBySubgroupIdAverageCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_CREATED_AT_DESC',
  MembershipsBySubgroupIdAverageFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_FLAGS_ASC',
  MembershipsBySubgroupIdAverageFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_FLAGS_DESC',
  MembershipsBySubgroupIdAverageGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_GROUP_ID_ASC',
  MembershipsBySubgroupIdAverageGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_GROUP_ID_DESC',
  MembershipsBySubgroupIdAverageIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_ID_ASC',
  MembershipsBySubgroupIdAverageIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_ID_DESC',
  MembershipsBySubgroupIdAverageIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdAverageIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdAverageRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_ROLE_ASC',
  MembershipsBySubgroupIdAverageRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_ROLE_DESC',
  MembershipsBySubgroupIdAverageSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdAverageSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdAverageUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_UPDATED_AT_ASC',
  MembershipsBySubgroupIdAverageUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_UPDATED_AT_DESC',
  MembershipsBySubgroupIdAverageUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_USER_ID_ASC',
  MembershipsBySubgroupIdAverageUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_AVERAGE_USER_ID_DESC',
  MembershipsBySubgroupIdCountAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_COUNT_ASC',
  MembershipsBySubgroupIdCountDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_COUNT_DESC',
  MembershipsBySubgroupIdDistinctCountCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  MembershipsBySubgroupIdDistinctCountCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  MembershipsBySubgroupIdDistinctCountFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_FLAGS_ASC',
  MembershipsBySubgroupIdDistinctCountFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_FLAGS_DESC',
  MembershipsBySubgroupIdDistinctCountGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_GROUP_ID_ASC',
  MembershipsBySubgroupIdDistinctCountGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_GROUP_ID_DESC',
  MembershipsBySubgroupIdDistinctCountIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_ID_ASC',
  MembershipsBySubgroupIdDistinctCountIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_ID_DESC',
  MembershipsBySubgroupIdDistinctCountIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdDistinctCountIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdDistinctCountRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_ROLE_ASC',
  MembershipsBySubgroupIdDistinctCountRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_ROLE_DESC',
  MembershipsBySubgroupIdDistinctCountSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdDistinctCountSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdDistinctCountUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  MembershipsBySubgroupIdDistinctCountUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  MembershipsBySubgroupIdDistinctCountUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_USER_ID_ASC',
  MembershipsBySubgroupIdDistinctCountUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_DISTINCT_COUNT_USER_ID_DESC',
  MembershipsBySubgroupIdMaxCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_CREATED_AT_ASC',
  MembershipsBySubgroupIdMaxCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_CREATED_AT_DESC',
  MembershipsBySubgroupIdMaxFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_FLAGS_ASC',
  MembershipsBySubgroupIdMaxFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_FLAGS_DESC',
  MembershipsBySubgroupIdMaxGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_GROUP_ID_ASC',
  MembershipsBySubgroupIdMaxGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_GROUP_ID_DESC',
  MembershipsBySubgroupIdMaxIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_ID_ASC',
  MembershipsBySubgroupIdMaxIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_ID_DESC',
  MembershipsBySubgroupIdMaxIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdMaxIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdMaxRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_ROLE_ASC',
  MembershipsBySubgroupIdMaxRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_ROLE_DESC',
  MembershipsBySubgroupIdMaxSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdMaxSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdMaxUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_UPDATED_AT_ASC',
  MembershipsBySubgroupIdMaxUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_UPDATED_AT_DESC',
  MembershipsBySubgroupIdMaxUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_USER_ID_ASC',
  MembershipsBySubgroupIdMaxUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MAX_USER_ID_DESC',
  MembershipsBySubgroupIdMinCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_CREATED_AT_ASC',
  MembershipsBySubgroupIdMinCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_CREATED_AT_DESC',
  MembershipsBySubgroupIdMinFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_FLAGS_ASC',
  MembershipsBySubgroupIdMinFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_FLAGS_DESC',
  MembershipsBySubgroupIdMinGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_GROUP_ID_ASC',
  MembershipsBySubgroupIdMinGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_GROUP_ID_DESC',
  MembershipsBySubgroupIdMinIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_ID_ASC',
  MembershipsBySubgroupIdMinIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_ID_DESC',
  MembershipsBySubgroupIdMinIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdMinIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdMinRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_ROLE_ASC',
  MembershipsBySubgroupIdMinRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_ROLE_DESC',
  MembershipsBySubgroupIdMinSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdMinSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdMinUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_UPDATED_AT_ASC',
  MembershipsBySubgroupIdMinUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_UPDATED_AT_DESC',
  MembershipsBySubgroupIdMinUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_USER_ID_ASC',
  MembershipsBySubgroupIdMinUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_MIN_USER_ID_DESC',
  MembershipsBySubgroupIdStddevPopulationCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  MembershipsBySubgroupIdStddevPopulationCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  MembershipsBySubgroupIdStddevPopulationFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_FLAGS_ASC',
  MembershipsBySubgroupIdStddevPopulationFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_FLAGS_DESC',
  MembershipsBySubgroupIdStddevPopulationGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_GROUP_ID_ASC',
  MembershipsBySubgroupIdStddevPopulationGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_GROUP_ID_DESC',
  MembershipsBySubgroupIdStddevPopulationIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_ID_ASC',
  MembershipsBySubgroupIdStddevPopulationIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_ID_DESC',
  MembershipsBySubgroupIdStddevPopulationIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdStddevPopulationIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdStddevPopulationRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_ROLE_ASC',
  MembershipsBySubgroupIdStddevPopulationRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_ROLE_DESC',
  MembershipsBySubgroupIdStddevPopulationSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdStddevPopulationSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdStddevPopulationUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  MembershipsBySubgroupIdStddevPopulationUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  MembershipsBySubgroupIdStddevPopulationUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_USER_ID_ASC',
  MembershipsBySubgroupIdStddevPopulationUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_POPULATION_USER_ID_DESC',
  MembershipsBySubgroupIdStddevSampleCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  MembershipsBySubgroupIdStddevSampleCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  MembershipsBySubgroupIdStddevSampleFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_FLAGS_ASC',
  MembershipsBySubgroupIdStddevSampleFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_FLAGS_DESC',
  MembershipsBySubgroupIdStddevSampleGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_GROUP_ID_ASC',
  MembershipsBySubgroupIdStddevSampleGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_GROUP_ID_DESC',
  MembershipsBySubgroupIdStddevSampleIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_ID_ASC',
  MembershipsBySubgroupIdStddevSampleIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_ID_DESC',
  MembershipsBySubgroupIdStddevSampleIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdStddevSampleIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdStddevSampleRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_ROLE_ASC',
  MembershipsBySubgroupIdStddevSampleRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_ROLE_DESC',
  MembershipsBySubgroupIdStddevSampleSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdStddevSampleSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdStddevSampleUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  MembershipsBySubgroupIdStddevSampleUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  MembershipsBySubgroupIdStddevSampleUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_USER_ID_ASC',
  MembershipsBySubgroupIdStddevSampleUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_STDDEV_SAMPLE_USER_ID_DESC',
  MembershipsBySubgroupIdSumCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_CREATED_AT_ASC',
  MembershipsBySubgroupIdSumCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_CREATED_AT_DESC',
  MembershipsBySubgroupIdSumFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_FLAGS_ASC',
  MembershipsBySubgroupIdSumFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_FLAGS_DESC',
  MembershipsBySubgroupIdSumGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_GROUP_ID_ASC',
  MembershipsBySubgroupIdSumGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_GROUP_ID_DESC',
  MembershipsBySubgroupIdSumIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_ID_ASC',
  MembershipsBySubgroupIdSumIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_ID_DESC',
  MembershipsBySubgroupIdSumIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdSumIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdSumRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_ROLE_ASC',
  MembershipsBySubgroupIdSumRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_ROLE_DESC',
  MembershipsBySubgroupIdSumSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdSumSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdSumUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_UPDATED_AT_ASC',
  MembershipsBySubgroupIdSumUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_UPDATED_AT_DESC',
  MembershipsBySubgroupIdSumUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_USER_ID_ASC',
  MembershipsBySubgroupIdSumUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_SUM_USER_ID_DESC',
  MembershipsBySubgroupIdVariancePopulationCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  MembershipsBySubgroupIdVariancePopulationCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  MembershipsBySubgroupIdVariancePopulationFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_FLAGS_ASC',
  MembershipsBySubgroupIdVariancePopulationFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_FLAGS_DESC',
  MembershipsBySubgroupIdVariancePopulationGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_GROUP_ID_ASC',
  MembershipsBySubgroupIdVariancePopulationGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_GROUP_ID_DESC',
  MembershipsBySubgroupIdVariancePopulationIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_ID_ASC',
  MembershipsBySubgroupIdVariancePopulationIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_ID_DESC',
  MembershipsBySubgroupIdVariancePopulationIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdVariancePopulationIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdVariancePopulationRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_ROLE_ASC',
  MembershipsBySubgroupIdVariancePopulationRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_ROLE_DESC',
  MembershipsBySubgroupIdVariancePopulationSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdVariancePopulationSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdVariancePopulationUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  MembershipsBySubgroupIdVariancePopulationUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  MembershipsBySubgroupIdVariancePopulationUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_USER_ID_ASC',
  MembershipsBySubgroupIdVariancePopulationUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_POPULATION_USER_ID_DESC',
  MembershipsBySubgroupIdVarianceSampleCreatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  MembershipsBySubgroupIdVarianceSampleCreatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  MembershipsBySubgroupIdVarianceSampleFlagsAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_FLAGS_ASC',
  MembershipsBySubgroupIdVarianceSampleFlagsDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_FLAGS_DESC',
  MembershipsBySubgroupIdVarianceSampleGroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_GROUP_ID_ASC',
  MembershipsBySubgroupIdVarianceSampleGroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_GROUP_ID_DESC',
  MembershipsBySubgroupIdVarianceSampleIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_ID_ASC',
  MembershipsBySubgroupIdVarianceSampleIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_ID_DESC',
  MembershipsBySubgroupIdVarianceSampleIsManagedExternallyAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsBySubgroupIdVarianceSampleIsManagedExternallyDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsBySubgroupIdVarianceSampleRoleAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_ROLE_ASC',
  MembershipsBySubgroupIdVarianceSampleRoleDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_ROLE_DESC',
  MembershipsBySubgroupIdVarianceSampleSubgroupIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_SUBGROUP_ID_ASC',
  MembershipsBySubgroupIdVarianceSampleSubgroupIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_SUBGROUP_ID_DESC',
  MembershipsBySubgroupIdVarianceSampleUpdatedAtAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  MembershipsBySubgroupIdVarianceSampleUpdatedAtDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  MembershipsBySubgroupIdVarianceSampleUserIdAsc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_USER_ID_ASC',
  MembershipsBySubgroupIdVarianceSampleUserIdDesc = 'MEMBERSHIPS_BY_SUBGROUP_ID_VARIANCE_SAMPLE_USER_ID_DESC',
  MembershipsCountAsc = 'MEMBERSHIPS_COUNT_ASC',
  MembershipsCountDesc = 'MEMBERSHIPS_COUNT_DESC',
  MembershipsDistinctCountCreatedAtAsc = 'MEMBERSHIPS_DISTINCT_COUNT_CREATED_AT_ASC',
  MembershipsDistinctCountCreatedAtDesc = 'MEMBERSHIPS_DISTINCT_COUNT_CREATED_AT_DESC',
  MembershipsDistinctCountFlagsAsc = 'MEMBERSHIPS_DISTINCT_COUNT_FLAGS_ASC',
  MembershipsDistinctCountFlagsDesc = 'MEMBERSHIPS_DISTINCT_COUNT_FLAGS_DESC',
  MembershipsDistinctCountGroupIdAsc = 'MEMBERSHIPS_DISTINCT_COUNT_GROUP_ID_ASC',
  MembershipsDistinctCountGroupIdDesc = 'MEMBERSHIPS_DISTINCT_COUNT_GROUP_ID_DESC',
  MembershipsDistinctCountIdAsc = 'MEMBERSHIPS_DISTINCT_COUNT_ID_ASC',
  MembershipsDistinctCountIdDesc = 'MEMBERSHIPS_DISTINCT_COUNT_ID_DESC',
  MembershipsDistinctCountIsManagedExternallyAsc = 'MEMBERSHIPS_DISTINCT_COUNT_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsDistinctCountIsManagedExternallyDesc = 'MEMBERSHIPS_DISTINCT_COUNT_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsDistinctCountRoleAsc = 'MEMBERSHIPS_DISTINCT_COUNT_ROLE_ASC',
  MembershipsDistinctCountRoleDesc = 'MEMBERSHIPS_DISTINCT_COUNT_ROLE_DESC',
  MembershipsDistinctCountSubgroupIdAsc = 'MEMBERSHIPS_DISTINCT_COUNT_SUBGROUP_ID_ASC',
  MembershipsDistinctCountSubgroupIdDesc = 'MEMBERSHIPS_DISTINCT_COUNT_SUBGROUP_ID_DESC',
  MembershipsDistinctCountUpdatedAtAsc = 'MEMBERSHIPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  MembershipsDistinctCountUpdatedAtDesc = 'MEMBERSHIPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  MembershipsDistinctCountUserIdAsc = 'MEMBERSHIPS_DISTINCT_COUNT_USER_ID_ASC',
  MembershipsDistinctCountUserIdDesc = 'MEMBERSHIPS_DISTINCT_COUNT_USER_ID_DESC',
  MembershipsMaxCreatedAtAsc = 'MEMBERSHIPS_MAX_CREATED_AT_ASC',
  MembershipsMaxCreatedAtDesc = 'MEMBERSHIPS_MAX_CREATED_AT_DESC',
  MembershipsMaxFlagsAsc = 'MEMBERSHIPS_MAX_FLAGS_ASC',
  MembershipsMaxFlagsDesc = 'MEMBERSHIPS_MAX_FLAGS_DESC',
  MembershipsMaxGroupIdAsc = 'MEMBERSHIPS_MAX_GROUP_ID_ASC',
  MembershipsMaxGroupIdDesc = 'MEMBERSHIPS_MAX_GROUP_ID_DESC',
  MembershipsMaxIdAsc = 'MEMBERSHIPS_MAX_ID_ASC',
  MembershipsMaxIdDesc = 'MEMBERSHIPS_MAX_ID_DESC',
  MembershipsMaxIsManagedExternallyAsc = 'MEMBERSHIPS_MAX_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsMaxIsManagedExternallyDesc = 'MEMBERSHIPS_MAX_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsMaxRoleAsc = 'MEMBERSHIPS_MAX_ROLE_ASC',
  MembershipsMaxRoleDesc = 'MEMBERSHIPS_MAX_ROLE_DESC',
  MembershipsMaxSubgroupIdAsc = 'MEMBERSHIPS_MAX_SUBGROUP_ID_ASC',
  MembershipsMaxSubgroupIdDesc = 'MEMBERSHIPS_MAX_SUBGROUP_ID_DESC',
  MembershipsMaxUpdatedAtAsc = 'MEMBERSHIPS_MAX_UPDATED_AT_ASC',
  MembershipsMaxUpdatedAtDesc = 'MEMBERSHIPS_MAX_UPDATED_AT_DESC',
  MembershipsMaxUserIdAsc = 'MEMBERSHIPS_MAX_USER_ID_ASC',
  MembershipsMaxUserIdDesc = 'MEMBERSHIPS_MAX_USER_ID_DESC',
  MembershipsMinCreatedAtAsc = 'MEMBERSHIPS_MIN_CREATED_AT_ASC',
  MembershipsMinCreatedAtDesc = 'MEMBERSHIPS_MIN_CREATED_AT_DESC',
  MembershipsMinFlagsAsc = 'MEMBERSHIPS_MIN_FLAGS_ASC',
  MembershipsMinFlagsDesc = 'MEMBERSHIPS_MIN_FLAGS_DESC',
  MembershipsMinGroupIdAsc = 'MEMBERSHIPS_MIN_GROUP_ID_ASC',
  MembershipsMinGroupIdDesc = 'MEMBERSHIPS_MIN_GROUP_ID_DESC',
  MembershipsMinIdAsc = 'MEMBERSHIPS_MIN_ID_ASC',
  MembershipsMinIdDesc = 'MEMBERSHIPS_MIN_ID_DESC',
  MembershipsMinIsManagedExternallyAsc = 'MEMBERSHIPS_MIN_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsMinIsManagedExternallyDesc = 'MEMBERSHIPS_MIN_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsMinRoleAsc = 'MEMBERSHIPS_MIN_ROLE_ASC',
  MembershipsMinRoleDesc = 'MEMBERSHIPS_MIN_ROLE_DESC',
  MembershipsMinSubgroupIdAsc = 'MEMBERSHIPS_MIN_SUBGROUP_ID_ASC',
  MembershipsMinSubgroupIdDesc = 'MEMBERSHIPS_MIN_SUBGROUP_ID_DESC',
  MembershipsMinUpdatedAtAsc = 'MEMBERSHIPS_MIN_UPDATED_AT_ASC',
  MembershipsMinUpdatedAtDesc = 'MEMBERSHIPS_MIN_UPDATED_AT_DESC',
  MembershipsMinUserIdAsc = 'MEMBERSHIPS_MIN_USER_ID_ASC',
  MembershipsMinUserIdDesc = 'MEMBERSHIPS_MIN_USER_ID_DESC',
  MembershipsStddevPopulationCreatedAtAsc = 'MEMBERSHIPS_STDDEV_POPULATION_CREATED_AT_ASC',
  MembershipsStddevPopulationCreatedAtDesc = 'MEMBERSHIPS_STDDEV_POPULATION_CREATED_AT_DESC',
  MembershipsStddevPopulationFlagsAsc = 'MEMBERSHIPS_STDDEV_POPULATION_FLAGS_ASC',
  MembershipsStddevPopulationFlagsDesc = 'MEMBERSHIPS_STDDEV_POPULATION_FLAGS_DESC',
  MembershipsStddevPopulationGroupIdAsc = 'MEMBERSHIPS_STDDEV_POPULATION_GROUP_ID_ASC',
  MembershipsStddevPopulationGroupIdDesc = 'MEMBERSHIPS_STDDEV_POPULATION_GROUP_ID_DESC',
  MembershipsStddevPopulationIdAsc = 'MEMBERSHIPS_STDDEV_POPULATION_ID_ASC',
  MembershipsStddevPopulationIdDesc = 'MEMBERSHIPS_STDDEV_POPULATION_ID_DESC',
  MembershipsStddevPopulationIsManagedExternallyAsc = 'MEMBERSHIPS_STDDEV_POPULATION_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsStddevPopulationIsManagedExternallyDesc = 'MEMBERSHIPS_STDDEV_POPULATION_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsStddevPopulationRoleAsc = 'MEMBERSHIPS_STDDEV_POPULATION_ROLE_ASC',
  MembershipsStddevPopulationRoleDesc = 'MEMBERSHIPS_STDDEV_POPULATION_ROLE_DESC',
  MembershipsStddevPopulationSubgroupIdAsc = 'MEMBERSHIPS_STDDEV_POPULATION_SUBGROUP_ID_ASC',
  MembershipsStddevPopulationSubgroupIdDesc = 'MEMBERSHIPS_STDDEV_POPULATION_SUBGROUP_ID_DESC',
  MembershipsStddevPopulationUpdatedAtAsc = 'MEMBERSHIPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  MembershipsStddevPopulationUpdatedAtDesc = 'MEMBERSHIPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  MembershipsStddevPopulationUserIdAsc = 'MEMBERSHIPS_STDDEV_POPULATION_USER_ID_ASC',
  MembershipsStddevPopulationUserIdDesc = 'MEMBERSHIPS_STDDEV_POPULATION_USER_ID_DESC',
  MembershipsStddevSampleCreatedAtAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  MembershipsStddevSampleCreatedAtDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  MembershipsStddevSampleFlagsAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_FLAGS_ASC',
  MembershipsStddevSampleFlagsDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_FLAGS_DESC',
  MembershipsStddevSampleGroupIdAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_GROUP_ID_ASC',
  MembershipsStddevSampleGroupIdDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_GROUP_ID_DESC',
  MembershipsStddevSampleIdAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_ID_ASC',
  MembershipsStddevSampleIdDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_ID_DESC',
  MembershipsStddevSampleIsManagedExternallyAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsStddevSampleIsManagedExternallyDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsStddevSampleRoleAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_ROLE_ASC',
  MembershipsStddevSampleRoleDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_ROLE_DESC',
  MembershipsStddevSampleSubgroupIdAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_SUBGROUP_ID_ASC',
  MembershipsStddevSampleSubgroupIdDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_SUBGROUP_ID_DESC',
  MembershipsStddevSampleUpdatedAtAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  MembershipsStddevSampleUpdatedAtDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  MembershipsStddevSampleUserIdAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_USER_ID_ASC',
  MembershipsStddevSampleUserIdDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_USER_ID_DESC',
  MembershipsSumCreatedAtAsc = 'MEMBERSHIPS_SUM_CREATED_AT_ASC',
  MembershipsSumCreatedAtDesc = 'MEMBERSHIPS_SUM_CREATED_AT_DESC',
  MembershipsSumFlagsAsc = 'MEMBERSHIPS_SUM_FLAGS_ASC',
  MembershipsSumFlagsDesc = 'MEMBERSHIPS_SUM_FLAGS_DESC',
  MembershipsSumGroupIdAsc = 'MEMBERSHIPS_SUM_GROUP_ID_ASC',
  MembershipsSumGroupIdDesc = 'MEMBERSHIPS_SUM_GROUP_ID_DESC',
  MembershipsSumIdAsc = 'MEMBERSHIPS_SUM_ID_ASC',
  MembershipsSumIdDesc = 'MEMBERSHIPS_SUM_ID_DESC',
  MembershipsSumIsManagedExternallyAsc = 'MEMBERSHIPS_SUM_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsSumIsManagedExternallyDesc = 'MEMBERSHIPS_SUM_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsSumRoleAsc = 'MEMBERSHIPS_SUM_ROLE_ASC',
  MembershipsSumRoleDesc = 'MEMBERSHIPS_SUM_ROLE_DESC',
  MembershipsSumSubgroupIdAsc = 'MEMBERSHIPS_SUM_SUBGROUP_ID_ASC',
  MembershipsSumSubgroupIdDesc = 'MEMBERSHIPS_SUM_SUBGROUP_ID_DESC',
  MembershipsSumUpdatedAtAsc = 'MEMBERSHIPS_SUM_UPDATED_AT_ASC',
  MembershipsSumUpdatedAtDesc = 'MEMBERSHIPS_SUM_UPDATED_AT_DESC',
  MembershipsSumUserIdAsc = 'MEMBERSHIPS_SUM_USER_ID_ASC',
  MembershipsSumUserIdDesc = 'MEMBERSHIPS_SUM_USER_ID_DESC',
  MembershipsVariancePopulationCreatedAtAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  MembershipsVariancePopulationCreatedAtDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  MembershipsVariancePopulationFlagsAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_FLAGS_ASC',
  MembershipsVariancePopulationFlagsDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_FLAGS_DESC',
  MembershipsVariancePopulationGroupIdAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_GROUP_ID_ASC',
  MembershipsVariancePopulationGroupIdDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_GROUP_ID_DESC',
  MembershipsVariancePopulationIdAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_ID_ASC',
  MembershipsVariancePopulationIdDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_ID_DESC',
  MembershipsVariancePopulationIsManagedExternallyAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsVariancePopulationIsManagedExternallyDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsVariancePopulationRoleAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_ROLE_ASC',
  MembershipsVariancePopulationRoleDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_ROLE_DESC',
  MembershipsVariancePopulationSubgroupIdAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_SUBGROUP_ID_ASC',
  MembershipsVariancePopulationSubgroupIdDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_SUBGROUP_ID_DESC',
  MembershipsVariancePopulationUpdatedAtAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  MembershipsVariancePopulationUpdatedAtDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  MembershipsVariancePopulationUserIdAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_USER_ID_ASC',
  MembershipsVariancePopulationUserIdDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_USER_ID_DESC',
  MembershipsVarianceSampleCreatedAtAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  MembershipsVarianceSampleCreatedAtDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  MembershipsVarianceSampleFlagsAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_FLAGS_ASC',
  MembershipsVarianceSampleFlagsDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_FLAGS_DESC',
  MembershipsVarianceSampleGroupIdAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  MembershipsVarianceSampleGroupIdDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  MembershipsVarianceSampleIdAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_ID_ASC',
  MembershipsVarianceSampleIdDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_ID_DESC',
  MembershipsVarianceSampleIsManagedExternallyAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsVarianceSampleIsManagedExternallyDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsVarianceSampleRoleAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_ROLE_ASC',
  MembershipsVarianceSampleRoleDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_ROLE_DESC',
  MembershipsVarianceSampleSubgroupIdAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_SUBGROUP_ID_ASC',
  MembershipsVarianceSampleSubgroupIdDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_SUBGROUP_ID_DESC',
  MembershipsVarianceSampleUpdatedAtAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  MembershipsVarianceSampleUpdatedAtDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  MembershipsVarianceSampleUserIdAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_USER_ID_ASC',
  MembershipsVarianceSampleUserIdDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_USER_ID_DESC',
  MembershipInvitationsAverageAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_ACCEPTED_AT_ASC',
  MembershipInvitationsAverageAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_ACCEPTED_AT_DESC',
  MembershipInvitationsAverageAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsAverageAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsAverageDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsAverageDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsAverageGroupIdAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_GROUP_ID_ASC',
  MembershipInvitationsAverageGroupIdDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_GROUP_ID_DESC',
  MembershipInvitationsAverageIdAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_ID_ASC',
  MembershipInvitationsAverageIdDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_ID_DESC',
  MembershipInvitationsAverageInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITED_AS_ASC',
  MembershipInvitationsAverageInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITED_AS_DESC',
  MembershipInvitationsAverageInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITED_AT_ASC',
  MembershipInvitationsAverageInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITED_AT_DESC',
  MembershipInvitationsAverageInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsAverageInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsAverageInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITED_USER_ID_ASC',
  MembershipInvitationsAverageInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITED_USER_ID_DESC',
  MembershipInvitationsAverageInviterIdAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITER_ID_ASC',
  MembershipInvitationsAverageInviterIdDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_INVITER_ID_DESC',
  MembershipInvitationsAverageMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_MAXIMUM_USES_ASC',
  MembershipInvitationsAverageMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_MAXIMUM_USES_DESC',
  MembershipInvitationsAverageNoteAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_NOTE_ASC',
  MembershipInvitationsAverageNoteDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_NOTE_DESC',
  MembershipInvitationsAverageValidUntilAsc = 'MEMBERSHIP_INVITATIONS_AVERAGE_VALID_UNTIL_ASC',
  MembershipInvitationsAverageValidUntilDesc = 'MEMBERSHIP_INVITATIONS_AVERAGE_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdAverageAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdAverageAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdAverageAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdAverageAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdAverageDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdAverageDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdAverageGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdAverageGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdAverageIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_ID_ASC',
  MembershipInvitationsByInvitedGroupIdAverageIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_ID_DESC',
  MembershipInvitationsByInvitedGroupIdAverageInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdAverageInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdAverageInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdAverageInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdAverageInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdAverageInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdAverageInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdAverageInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdAverageInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdAverageInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdAverageMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdAverageMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdAverageNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdAverageNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdAverageValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdAverageValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_AVERAGE_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdCountAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_COUNT_ASC',
  MembershipInvitationsByInvitedGroupIdCountDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_COUNT_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_ID_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_ID_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdDistinctCountValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdDistinctCountValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_DISTINCT_COUNT_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdMaxAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdMaxAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdMaxAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdMaxAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdMaxDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdMaxDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdMaxGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMaxGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMaxIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMaxIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMaxInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdMaxInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdMaxInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdMaxInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdMaxInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMaxInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMaxInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMaxInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMaxInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMaxInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMaxMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdMaxMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdMaxNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdMaxNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdMaxValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdMaxValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MAX_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdMinAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdMinAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdMinAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdMinAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdMinDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdMinDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdMinGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMinGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMinIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMinIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMinInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdMinInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdMinInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdMinInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdMinInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMinInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMinInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMinInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMinInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdMinInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdMinMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdMinMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdMinNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdMinNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdMinValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdMinValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_MIN_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdStddevPopulationValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_POPULATION_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdStddevSampleValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdStddevSampleValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_STDDEV_SAMPLE_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdSumAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdSumAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdSumAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdSumAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdSumDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdSumDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdSumGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdSumGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdSumIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_ID_ASC',
  MembershipInvitationsByInvitedGroupIdSumIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_ID_DESC',
  MembershipInvitationsByInvitedGroupIdSumInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdSumInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdSumInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdSumInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdSumInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdSumInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdSumInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdSumInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdSumInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdSumInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdSumMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdSumMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdSumNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdSumNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdSumValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdSumValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_SUM_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdVariancePopulationValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_POPULATION_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITED_AS_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITED_AS_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITED_AT_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITED_AT_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITER_ID_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_INVITER_ID_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_NOTE_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_NOTE_DESC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedGroupIdVarianceSampleValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_GROUP_ID_VARIANCE_SAMPLE_VALID_UNTIL_DESC',
  MembershipInvitationsCountAsc = 'MEMBERSHIP_INVITATIONS_COUNT_ASC',
  MembershipInvitationsCountDesc = 'MEMBERSHIP_INVITATIONS_COUNT_DESC',
  MembershipInvitationsDistinctCountAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_ACCEPTED_AT_ASC',
  MembershipInvitationsDistinctCountAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_ACCEPTED_AT_DESC',
  MembershipInvitationsDistinctCountAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsDistinctCountAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsDistinctCountDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsDistinctCountDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsDistinctCountGroupIdAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_GROUP_ID_ASC',
  MembershipInvitationsDistinctCountGroupIdDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_GROUP_ID_DESC',
  MembershipInvitationsDistinctCountIdAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_ID_ASC',
  MembershipInvitationsDistinctCountIdDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_ID_DESC',
  MembershipInvitationsDistinctCountInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITED_AS_ASC',
  MembershipInvitationsDistinctCountInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITED_AS_DESC',
  MembershipInvitationsDistinctCountInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITED_AT_ASC',
  MembershipInvitationsDistinctCountInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITED_AT_DESC',
  MembershipInvitationsDistinctCountInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITED_GROUP_ID_ASC',
  MembershipInvitationsDistinctCountInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITED_GROUP_ID_DESC',
  MembershipInvitationsDistinctCountInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITED_USER_ID_ASC',
  MembershipInvitationsDistinctCountInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITED_USER_ID_DESC',
  MembershipInvitationsDistinctCountInviterIdAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITER_ID_ASC',
  MembershipInvitationsDistinctCountInviterIdDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_INVITER_ID_DESC',
  MembershipInvitationsDistinctCountMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_MAXIMUM_USES_ASC',
  MembershipInvitationsDistinctCountMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_MAXIMUM_USES_DESC',
  MembershipInvitationsDistinctCountNoteAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_NOTE_ASC',
  MembershipInvitationsDistinctCountNoteDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_NOTE_DESC',
  MembershipInvitationsDistinctCountValidUntilAsc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_VALID_UNTIL_ASC',
  MembershipInvitationsDistinctCountValidUntilDesc = 'MEMBERSHIP_INVITATIONS_DISTINCT_COUNT_VALID_UNTIL_DESC',
  MembershipInvitationsMaxAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_MAX_ACCEPTED_AT_ASC',
  MembershipInvitationsMaxAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_MAX_ACCEPTED_AT_DESC',
  MembershipInvitationsMaxAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_MAX_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsMaxAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_MAX_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsMaxDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_MAX_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsMaxDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_MAX_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsMaxGroupIdAsc = 'MEMBERSHIP_INVITATIONS_MAX_GROUP_ID_ASC',
  MembershipInvitationsMaxGroupIdDesc = 'MEMBERSHIP_INVITATIONS_MAX_GROUP_ID_DESC',
  MembershipInvitationsMaxIdAsc = 'MEMBERSHIP_INVITATIONS_MAX_ID_ASC',
  MembershipInvitationsMaxIdDesc = 'MEMBERSHIP_INVITATIONS_MAX_ID_DESC',
  MembershipInvitationsMaxInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_MAX_INVITED_AS_ASC',
  MembershipInvitationsMaxInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_MAX_INVITED_AS_DESC',
  MembershipInvitationsMaxInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_MAX_INVITED_AT_ASC',
  MembershipInvitationsMaxInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_MAX_INVITED_AT_DESC',
  MembershipInvitationsMaxInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_MAX_INVITED_GROUP_ID_ASC',
  MembershipInvitationsMaxInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_MAX_INVITED_GROUP_ID_DESC',
  MembershipInvitationsMaxInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_MAX_INVITED_USER_ID_ASC',
  MembershipInvitationsMaxInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_MAX_INVITED_USER_ID_DESC',
  MembershipInvitationsMaxInviterIdAsc = 'MEMBERSHIP_INVITATIONS_MAX_INVITER_ID_ASC',
  MembershipInvitationsMaxInviterIdDesc = 'MEMBERSHIP_INVITATIONS_MAX_INVITER_ID_DESC',
  MembershipInvitationsMaxMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_MAX_MAXIMUM_USES_ASC',
  MembershipInvitationsMaxMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_MAX_MAXIMUM_USES_DESC',
  MembershipInvitationsMaxNoteAsc = 'MEMBERSHIP_INVITATIONS_MAX_NOTE_ASC',
  MembershipInvitationsMaxNoteDesc = 'MEMBERSHIP_INVITATIONS_MAX_NOTE_DESC',
  MembershipInvitationsMaxValidUntilAsc = 'MEMBERSHIP_INVITATIONS_MAX_VALID_UNTIL_ASC',
  MembershipInvitationsMaxValidUntilDesc = 'MEMBERSHIP_INVITATIONS_MAX_VALID_UNTIL_DESC',
  MembershipInvitationsMinAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_MIN_ACCEPTED_AT_ASC',
  MembershipInvitationsMinAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_MIN_ACCEPTED_AT_DESC',
  MembershipInvitationsMinAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_MIN_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsMinAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_MIN_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsMinDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_MIN_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsMinDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_MIN_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsMinGroupIdAsc = 'MEMBERSHIP_INVITATIONS_MIN_GROUP_ID_ASC',
  MembershipInvitationsMinGroupIdDesc = 'MEMBERSHIP_INVITATIONS_MIN_GROUP_ID_DESC',
  MembershipInvitationsMinIdAsc = 'MEMBERSHIP_INVITATIONS_MIN_ID_ASC',
  MembershipInvitationsMinIdDesc = 'MEMBERSHIP_INVITATIONS_MIN_ID_DESC',
  MembershipInvitationsMinInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_MIN_INVITED_AS_ASC',
  MembershipInvitationsMinInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_MIN_INVITED_AS_DESC',
  MembershipInvitationsMinInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_MIN_INVITED_AT_ASC',
  MembershipInvitationsMinInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_MIN_INVITED_AT_DESC',
  MembershipInvitationsMinInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_MIN_INVITED_GROUP_ID_ASC',
  MembershipInvitationsMinInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_MIN_INVITED_GROUP_ID_DESC',
  MembershipInvitationsMinInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_MIN_INVITED_USER_ID_ASC',
  MembershipInvitationsMinInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_MIN_INVITED_USER_ID_DESC',
  MembershipInvitationsMinInviterIdAsc = 'MEMBERSHIP_INVITATIONS_MIN_INVITER_ID_ASC',
  MembershipInvitationsMinInviterIdDesc = 'MEMBERSHIP_INVITATIONS_MIN_INVITER_ID_DESC',
  MembershipInvitationsMinMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_MIN_MAXIMUM_USES_ASC',
  MembershipInvitationsMinMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_MIN_MAXIMUM_USES_DESC',
  MembershipInvitationsMinNoteAsc = 'MEMBERSHIP_INVITATIONS_MIN_NOTE_ASC',
  MembershipInvitationsMinNoteDesc = 'MEMBERSHIP_INVITATIONS_MIN_NOTE_DESC',
  MembershipInvitationsMinValidUntilAsc = 'MEMBERSHIP_INVITATIONS_MIN_VALID_UNTIL_ASC',
  MembershipInvitationsMinValidUntilDesc = 'MEMBERSHIP_INVITATIONS_MIN_VALID_UNTIL_DESC',
  MembershipInvitationsStddevPopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationsStddevPopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationsStddevPopulationAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsStddevPopulationAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsStddevPopulationDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsStddevPopulationDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsStddevPopulationGroupIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_GROUP_ID_ASC',
  MembershipInvitationsStddevPopulationGroupIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_GROUP_ID_DESC',
  MembershipInvitationsStddevPopulationIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_ID_ASC',
  MembershipInvitationsStddevPopulationIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_ID_DESC',
  MembershipInvitationsStddevPopulationInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITED_AS_ASC',
  MembershipInvitationsStddevPopulationInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITED_AS_DESC',
  MembershipInvitationsStddevPopulationInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITED_AT_ASC',
  MembershipInvitationsStddevPopulationInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITED_AT_DESC',
  MembershipInvitationsStddevPopulationInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITED_GROUP_ID_ASC',
  MembershipInvitationsStddevPopulationInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITED_GROUP_ID_DESC',
  MembershipInvitationsStddevPopulationInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITED_USER_ID_ASC',
  MembershipInvitationsStddevPopulationInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITED_USER_ID_DESC',
  MembershipInvitationsStddevPopulationInviterIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITER_ID_ASC',
  MembershipInvitationsStddevPopulationInviterIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_INVITER_ID_DESC',
  MembershipInvitationsStddevPopulationMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_MAXIMUM_USES_ASC',
  MembershipInvitationsStddevPopulationMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_MAXIMUM_USES_DESC',
  MembershipInvitationsStddevPopulationNoteAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_NOTE_ASC',
  MembershipInvitationsStddevPopulationNoteDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_NOTE_DESC',
  MembershipInvitationsStddevPopulationValidUntilAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_VALID_UNTIL_ASC',
  MembershipInvitationsStddevPopulationValidUntilDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_POPULATION_VALID_UNTIL_DESC',
  MembershipInvitationsStddevSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationsStddevSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationsStddevSampleAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsStddevSampleAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsStddevSampleDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsStddevSampleDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsStddevSampleGroupIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_GROUP_ID_ASC',
  MembershipInvitationsStddevSampleGroupIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_GROUP_ID_DESC',
  MembershipInvitationsStddevSampleIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_ID_ASC',
  MembershipInvitationsStddevSampleIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_ID_DESC',
  MembershipInvitationsStddevSampleInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITED_AS_ASC',
  MembershipInvitationsStddevSampleInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITED_AS_DESC',
  MembershipInvitationsStddevSampleInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITED_AT_ASC',
  MembershipInvitationsStddevSampleInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITED_AT_DESC',
  MembershipInvitationsStddevSampleInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsStddevSampleInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsStddevSampleInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITED_USER_ID_ASC',
  MembershipInvitationsStddevSampleInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITED_USER_ID_DESC',
  MembershipInvitationsStddevSampleInviterIdAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITER_ID_ASC',
  MembershipInvitationsStddevSampleInviterIdDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_INVITER_ID_DESC',
  MembershipInvitationsStddevSampleMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_MAXIMUM_USES_ASC',
  MembershipInvitationsStddevSampleMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_MAXIMUM_USES_DESC',
  MembershipInvitationsStddevSampleNoteAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_NOTE_ASC',
  MembershipInvitationsStddevSampleNoteDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_NOTE_DESC',
  MembershipInvitationsStddevSampleValidUntilAsc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_VALID_UNTIL_ASC',
  MembershipInvitationsStddevSampleValidUntilDesc = 'MEMBERSHIP_INVITATIONS_STDDEV_SAMPLE_VALID_UNTIL_DESC',
  MembershipInvitationsSumAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_SUM_ACCEPTED_AT_ASC',
  MembershipInvitationsSumAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_SUM_ACCEPTED_AT_DESC',
  MembershipInvitationsSumAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_SUM_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsSumAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_SUM_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsSumDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_SUM_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsSumDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_SUM_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsSumGroupIdAsc = 'MEMBERSHIP_INVITATIONS_SUM_GROUP_ID_ASC',
  MembershipInvitationsSumGroupIdDesc = 'MEMBERSHIP_INVITATIONS_SUM_GROUP_ID_DESC',
  MembershipInvitationsSumIdAsc = 'MEMBERSHIP_INVITATIONS_SUM_ID_ASC',
  MembershipInvitationsSumIdDesc = 'MEMBERSHIP_INVITATIONS_SUM_ID_DESC',
  MembershipInvitationsSumInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_SUM_INVITED_AS_ASC',
  MembershipInvitationsSumInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_SUM_INVITED_AS_DESC',
  MembershipInvitationsSumInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_SUM_INVITED_AT_ASC',
  MembershipInvitationsSumInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_SUM_INVITED_AT_DESC',
  MembershipInvitationsSumInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_SUM_INVITED_GROUP_ID_ASC',
  MembershipInvitationsSumInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_SUM_INVITED_GROUP_ID_DESC',
  MembershipInvitationsSumInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_SUM_INVITED_USER_ID_ASC',
  MembershipInvitationsSumInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_SUM_INVITED_USER_ID_DESC',
  MembershipInvitationsSumInviterIdAsc = 'MEMBERSHIP_INVITATIONS_SUM_INVITER_ID_ASC',
  MembershipInvitationsSumInviterIdDesc = 'MEMBERSHIP_INVITATIONS_SUM_INVITER_ID_DESC',
  MembershipInvitationsSumMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_SUM_MAXIMUM_USES_ASC',
  MembershipInvitationsSumMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_SUM_MAXIMUM_USES_DESC',
  MembershipInvitationsSumNoteAsc = 'MEMBERSHIP_INVITATIONS_SUM_NOTE_ASC',
  MembershipInvitationsSumNoteDesc = 'MEMBERSHIP_INVITATIONS_SUM_NOTE_DESC',
  MembershipInvitationsSumValidUntilAsc = 'MEMBERSHIP_INVITATIONS_SUM_VALID_UNTIL_ASC',
  MembershipInvitationsSumValidUntilDesc = 'MEMBERSHIP_INVITATIONS_SUM_VALID_UNTIL_DESC',
  MembershipInvitationsVariancePopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationsVariancePopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationsVariancePopulationAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsVariancePopulationAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsVariancePopulationDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsVariancePopulationDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsVariancePopulationGroupIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_GROUP_ID_ASC',
  MembershipInvitationsVariancePopulationGroupIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_GROUP_ID_DESC',
  MembershipInvitationsVariancePopulationIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_ID_ASC',
  MembershipInvitationsVariancePopulationIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_ID_DESC',
  MembershipInvitationsVariancePopulationInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITED_AS_ASC',
  MembershipInvitationsVariancePopulationInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITED_AS_DESC',
  MembershipInvitationsVariancePopulationInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITED_AT_ASC',
  MembershipInvitationsVariancePopulationInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITED_AT_DESC',
  MembershipInvitationsVariancePopulationInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITED_GROUP_ID_ASC',
  MembershipInvitationsVariancePopulationInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITED_GROUP_ID_DESC',
  MembershipInvitationsVariancePopulationInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITED_USER_ID_ASC',
  MembershipInvitationsVariancePopulationInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITED_USER_ID_DESC',
  MembershipInvitationsVariancePopulationInviterIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITER_ID_ASC',
  MembershipInvitationsVariancePopulationInviterIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_INVITER_ID_DESC',
  MembershipInvitationsVariancePopulationMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_MAXIMUM_USES_ASC',
  MembershipInvitationsVariancePopulationMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_MAXIMUM_USES_DESC',
  MembershipInvitationsVariancePopulationNoteAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_NOTE_ASC',
  MembershipInvitationsVariancePopulationNoteDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_NOTE_DESC',
  MembershipInvitationsVariancePopulationValidUntilAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_VALID_UNTIL_ASC',
  MembershipInvitationsVariancePopulationValidUntilDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_POPULATION_VALID_UNTIL_DESC',
  MembershipInvitationsVarianceSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationsVarianceSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationsVarianceSampleAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsVarianceSampleAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsVarianceSampleDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsVarianceSampleDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsVarianceSampleGroupIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  MembershipInvitationsVarianceSampleGroupIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  MembershipInvitationsVarianceSampleIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_ID_ASC',
  MembershipInvitationsVarianceSampleIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_ID_DESC',
  MembershipInvitationsVarianceSampleInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITED_AS_ASC',
  MembershipInvitationsVarianceSampleInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITED_AS_DESC',
  MembershipInvitationsVarianceSampleInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITED_AT_ASC',
  MembershipInvitationsVarianceSampleInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITED_AT_DESC',
  MembershipInvitationsVarianceSampleInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsVarianceSampleInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsVarianceSampleInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITED_USER_ID_ASC',
  MembershipInvitationsVarianceSampleInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITED_USER_ID_DESC',
  MembershipInvitationsVarianceSampleInviterIdAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITER_ID_ASC',
  MembershipInvitationsVarianceSampleInviterIdDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_INVITER_ID_DESC',
  MembershipInvitationsVarianceSampleMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_MAXIMUM_USES_ASC',
  MembershipInvitationsVarianceSampleMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_MAXIMUM_USES_DESC',
  MembershipInvitationsVarianceSampleNoteAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_NOTE_ASC',
  MembershipInvitationsVarianceSampleNoteDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_NOTE_DESC',
  MembershipInvitationsVarianceSampleValidUntilAsc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_VALID_UNTIL_ASC',
  MembershipInvitationsVarianceSampleValidUntilDesc = 'MEMBERSHIP_INVITATIONS_VARIANCE_SAMPLE_VALID_UNTIL_DESC',
  MyEffectiveRoleAsc = 'MY_EFFECTIVE_ROLE_ASC',
  MyEffectiveRoleDesc = 'MY_EFFECTIVE_ROLE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  PrivateConversationsAverageAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsAverageAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsAverageAbstractAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_ABSTRACT_ASC',
  PrivateConversationsAverageAbstractDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_ABSTRACT_DESC',
  PrivateConversationsAverageCreatedAtAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_CREATED_AT_ASC',
  PrivateConversationsAverageCreatedAtDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_CREATED_AT_DESC',
  PrivateConversationsAverageCreatorIdAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_CREATOR_ID_ASC',
  PrivateConversationsAverageCreatorIdDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_CREATOR_ID_DESC',
  PrivateConversationsAverageIdAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_ID_ASC',
  PrivateConversationsAverageIdDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_ID_DESC',
  PrivateConversationsAverageIsPublicAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_IS_PUBLIC_ASC',
  PrivateConversationsAverageIsPublicDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_IS_PUBLIC_DESC',
  PrivateConversationsAverageLanguageAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_LANGUAGE_ASC',
  PrivateConversationsAverageLanguageDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_LANGUAGE_DESC',
  PrivateConversationsAveragePrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsAveragePrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsAverageTenantIdAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_TENANT_ID_ASC',
  PrivateConversationsAverageTenantIdDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_TENANT_ID_DESC',
  PrivateConversationsAverageTitleAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_TITLE_ASC',
  PrivateConversationsAverageTitleDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_TITLE_DESC',
  PrivateConversationsAverageUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_AVERAGE_UPDATED_AT_ASC',
  PrivateConversationsAverageUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_AVERAGE_UPDATED_AT_DESC',
  PrivateConversationsCountAsc = 'PRIVATE_CONVERSATIONS_COUNT_ASC',
  PrivateConversationsCountDesc = 'PRIVATE_CONVERSATIONS_COUNT_DESC',
  PrivateConversationsDistinctCountAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsDistinctCountAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsDistinctCountAbstractAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_ABSTRACT_ASC',
  PrivateConversationsDistinctCountAbstractDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_ABSTRACT_DESC',
  PrivateConversationsDistinctCountCreatedAtAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  PrivateConversationsDistinctCountCreatedAtDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  PrivateConversationsDistinctCountCreatorIdAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_CREATOR_ID_ASC',
  PrivateConversationsDistinctCountCreatorIdDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_CREATOR_ID_DESC',
  PrivateConversationsDistinctCountIdAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_ID_ASC',
  PrivateConversationsDistinctCountIdDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_ID_DESC',
  PrivateConversationsDistinctCountIsPublicAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_IS_PUBLIC_ASC',
  PrivateConversationsDistinctCountIsPublicDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_IS_PUBLIC_DESC',
  PrivateConversationsDistinctCountLanguageAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_LANGUAGE_ASC',
  PrivateConversationsDistinctCountLanguageDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_LANGUAGE_DESC',
  PrivateConversationsDistinctCountPrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsDistinctCountPrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsDistinctCountTenantIdAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_TENANT_ID_ASC',
  PrivateConversationsDistinctCountTenantIdDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_TENANT_ID_DESC',
  PrivateConversationsDistinctCountTitleAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_TITLE_ASC',
  PrivateConversationsDistinctCountTitleDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_TITLE_DESC',
  PrivateConversationsDistinctCountUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  PrivateConversationsDistinctCountUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  PrivateConversationsMaxAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_MAX_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsMaxAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_MAX_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsMaxAbstractAsc = 'PRIVATE_CONVERSATIONS_MAX_ABSTRACT_ASC',
  PrivateConversationsMaxAbstractDesc = 'PRIVATE_CONVERSATIONS_MAX_ABSTRACT_DESC',
  PrivateConversationsMaxCreatedAtAsc = 'PRIVATE_CONVERSATIONS_MAX_CREATED_AT_ASC',
  PrivateConversationsMaxCreatedAtDesc = 'PRIVATE_CONVERSATIONS_MAX_CREATED_AT_DESC',
  PrivateConversationsMaxCreatorIdAsc = 'PRIVATE_CONVERSATIONS_MAX_CREATOR_ID_ASC',
  PrivateConversationsMaxCreatorIdDesc = 'PRIVATE_CONVERSATIONS_MAX_CREATOR_ID_DESC',
  PrivateConversationsMaxIdAsc = 'PRIVATE_CONVERSATIONS_MAX_ID_ASC',
  PrivateConversationsMaxIdDesc = 'PRIVATE_CONVERSATIONS_MAX_ID_DESC',
  PrivateConversationsMaxIsPublicAsc = 'PRIVATE_CONVERSATIONS_MAX_IS_PUBLIC_ASC',
  PrivateConversationsMaxIsPublicDesc = 'PRIVATE_CONVERSATIONS_MAX_IS_PUBLIC_DESC',
  PrivateConversationsMaxLanguageAsc = 'PRIVATE_CONVERSATIONS_MAX_LANGUAGE_ASC',
  PrivateConversationsMaxLanguageDesc = 'PRIVATE_CONVERSATIONS_MAX_LANGUAGE_DESC',
  PrivateConversationsMaxPrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_MAX_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsMaxPrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_MAX_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsMaxTenantIdAsc = 'PRIVATE_CONVERSATIONS_MAX_TENANT_ID_ASC',
  PrivateConversationsMaxTenantIdDesc = 'PRIVATE_CONVERSATIONS_MAX_TENANT_ID_DESC',
  PrivateConversationsMaxTitleAsc = 'PRIVATE_CONVERSATIONS_MAX_TITLE_ASC',
  PrivateConversationsMaxTitleDesc = 'PRIVATE_CONVERSATIONS_MAX_TITLE_DESC',
  PrivateConversationsMaxUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_MAX_UPDATED_AT_ASC',
  PrivateConversationsMaxUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_MAX_UPDATED_AT_DESC',
  PrivateConversationsMinAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_MIN_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsMinAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_MIN_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsMinAbstractAsc = 'PRIVATE_CONVERSATIONS_MIN_ABSTRACT_ASC',
  PrivateConversationsMinAbstractDesc = 'PRIVATE_CONVERSATIONS_MIN_ABSTRACT_DESC',
  PrivateConversationsMinCreatedAtAsc = 'PRIVATE_CONVERSATIONS_MIN_CREATED_AT_ASC',
  PrivateConversationsMinCreatedAtDesc = 'PRIVATE_CONVERSATIONS_MIN_CREATED_AT_DESC',
  PrivateConversationsMinCreatorIdAsc = 'PRIVATE_CONVERSATIONS_MIN_CREATOR_ID_ASC',
  PrivateConversationsMinCreatorIdDesc = 'PRIVATE_CONVERSATIONS_MIN_CREATOR_ID_DESC',
  PrivateConversationsMinIdAsc = 'PRIVATE_CONVERSATIONS_MIN_ID_ASC',
  PrivateConversationsMinIdDesc = 'PRIVATE_CONVERSATIONS_MIN_ID_DESC',
  PrivateConversationsMinIsPublicAsc = 'PRIVATE_CONVERSATIONS_MIN_IS_PUBLIC_ASC',
  PrivateConversationsMinIsPublicDesc = 'PRIVATE_CONVERSATIONS_MIN_IS_PUBLIC_DESC',
  PrivateConversationsMinLanguageAsc = 'PRIVATE_CONVERSATIONS_MIN_LANGUAGE_ASC',
  PrivateConversationsMinLanguageDesc = 'PRIVATE_CONVERSATIONS_MIN_LANGUAGE_DESC',
  PrivateConversationsMinPrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_MIN_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsMinPrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_MIN_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsMinTenantIdAsc = 'PRIVATE_CONVERSATIONS_MIN_TENANT_ID_ASC',
  PrivateConversationsMinTenantIdDesc = 'PRIVATE_CONVERSATIONS_MIN_TENANT_ID_DESC',
  PrivateConversationsMinTitleAsc = 'PRIVATE_CONVERSATIONS_MIN_TITLE_ASC',
  PrivateConversationsMinTitleDesc = 'PRIVATE_CONVERSATIONS_MIN_TITLE_DESC',
  PrivateConversationsMinUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_MIN_UPDATED_AT_ASC',
  PrivateConversationsMinUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_MIN_UPDATED_AT_DESC',
  PrivateConversationsStddevPopulationAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsStddevPopulationAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsStddevPopulationAbstractAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_ABSTRACT_ASC',
  PrivateConversationsStddevPopulationAbstractDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_ABSTRACT_DESC',
  PrivateConversationsStddevPopulationCreatedAtAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  PrivateConversationsStddevPopulationCreatedAtDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  PrivateConversationsStddevPopulationCreatorIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_CREATOR_ID_ASC',
  PrivateConversationsStddevPopulationCreatorIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_CREATOR_ID_DESC',
  PrivateConversationsStddevPopulationIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_ID_ASC',
  PrivateConversationsStddevPopulationIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_ID_DESC',
  PrivateConversationsStddevPopulationIsPublicAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_IS_PUBLIC_ASC',
  PrivateConversationsStddevPopulationIsPublicDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_IS_PUBLIC_DESC',
  PrivateConversationsStddevPopulationLanguageAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_LANGUAGE_ASC',
  PrivateConversationsStddevPopulationLanguageDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_LANGUAGE_DESC',
  PrivateConversationsStddevPopulationPrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsStddevPopulationPrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsStddevPopulationTenantIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_TENANT_ID_ASC',
  PrivateConversationsStddevPopulationTenantIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_TENANT_ID_DESC',
  PrivateConversationsStddevPopulationTitleAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_TITLE_ASC',
  PrivateConversationsStddevPopulationTitleDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_TITLE_DESC',
  PrivateConversationsStddevPopulationUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  PrivateConversationsStddevPopulationUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  PrivateConversationsStddevSampleAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsStddevSampleAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsStddevSampleAbstractAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_ABSTRACT_ASC',
  PrivateConversationsStddevSampleAbstractDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_ABSTRACT_DESC',
  PrivateConversationsStddevSampleCreatedAtAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  PrivateConversationsStddevSampleCreatedAtDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  PrivateConversationsStddevSampleCreatorIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_CREATOR_ID_ASC',
  PrivateConversationsStddevSampleCreatorIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_CREATOR_ID_DESC',
  PrivateConversationsStddevSampleIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_ID_ASC',
  PrivateConversationsStddevSampleIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_ID_DESC',
  PrivateConversationsStddevSampleIsPublicAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_IS_PUBLIC_ASC',
  PrivateConversationsStddevSampleIsPublicDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_IS_PUBLIC_DESC',
  PrivateConversationsStddevSampleLanguageAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_LANGUAGE_ASC',
  PrivateConversationsStddevSampleLanguageDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_LANGUAGE_DESC',
  PrivateConversationsStddevSamplePrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsStddevSamplePrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsStddevSampleTenantIdAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_TENANT_ID_ASC',
  PrivateConversationsStddevSampleTenantIdDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_TENANT_ID_DESC',
  PrivateConversationsStddevSampleTitleAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_TITLE_ASC',
  PrivateConversationsStddevSampleTitleDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_TITLE_DESC',
  PrivateConversationsStddevSampleUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  PrivateConversationsStddevSampleUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  PrivateConversationsSumAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_SUM_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsSumAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_SUM_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsSumAbstractAsc = 'PRIVATE_CONVERSATIONS_SUM_ABSTRACT_ASC',
  PrivateConversationsSumAbstractDesc = 'PRIVATE_CONVERSATIONS_SUM_ABSTRACT_DESC',
  PrivateConversationsSumCreatedAtAsc = 'PRIVATE_CONVERSATIONS_SUM_CREATED_AT_ASC',
  PrivateConversationsSumCreatedAtDesc = 'PRIVATE_CONVERSATIONS_SUM_CREATED_AT_DESC',
  PrivateConversationsSumCreatorIdAsc = 'PRIVATE_CONVERSATIONS_SUM_CREATOR_ID_ASC',
  PrivateConversationsSumCreatorIdDesc = 'PRIVATE_CONVERSATIONS_SUM_CREATOR_ID_DESC',
  PrivateConversationsSumIdAsc = 'PRIVATE_CONVERSATIONS_SUM_ID_ASC',
  PrivateConversationsSumIdDesc = 'PRIVATE_CONVERSATIONS_SUM_ID_DESC',
  PrivateConversationsSumIsPublicAsc = 'PRIVATE_CONVERSATIONS_SUM_IS_PUBLIC_ASC',
  PrivateConversationsSumIsPublicDesc = 'PRIVATE_CONVERSATIONS_SUM_IS_PUBLIC_DESC',
  PrivateConversationsSumLanguageAsc = 'PRIVATE_CONVERSATIONS_SUM_LANGUAGE_ASC',
  PrivateConversationsSumLanguageDesc = 'PRIVATE_CONVERSATIONS_SUM_LANGUAGE_DESC',
  PrivateConversationsSumPrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_SUM_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsSumPrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_SUM_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsSumTenantIdAsc = 'PRIVATE_CONVERSATIONS_SUM_TENANT_ID_ASC',
  PrivateConversationsSumTenantIdDesc = 'PRIVATE_CONVERSATIONS_SUM_TENANT_ID_DESC',
  PrivateConversationsSumTitleAsc = 'PRIVATE_CONVERSATIONS_SUM_TITLE_ASC',
  PrivateConversationsSumTitleDesc = 'PRIVATE_CONVERSATIONS_SUM_TITLE_DESC',
  PrivateConversationsSumUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_SUM_UPDATED_AT_ASC',
  PrivateConversationsSumUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_SUM_UPDATED_AT_DESC',
  PrivateConversationsVariancePopulationAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsVariancePopulationAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsVariancePopulationAbstractAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_ABSTRACT_ASC',
  PrivateConversationsVariancePopulationAbstractDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_ABSTRACT_DESC',
  PrivateConversationsVariancePopulationCreatedAtAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  PrivateConversationsVariancePopulationCreatedAtDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  PrivateConversationsVariancePopulationCreatorIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_CREATOR_ID_ASC',
  PrivateConversationsVariancePopulationCreatorIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_CREATOR_ID_DESC',
  PrivateConversationsVariancePopulationIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_ID_ASC',
  PrivateConversationsVariancePopulationIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_ID_DESC',
  PrivateConversationsVariancePopulationIsPublicAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_IS_PUBLIC_ASC',
  PrivateConversationsVariancePopulationIsPublicDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_IS_PUBLIC_DESC',
  PrivateConversationsVariancePopulationLanguageAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_LANGUAGE_ASC',
  PrivateConversationsVariancePopulationLanguageDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_LANGUAGE_DESC',
  PrivateConversationsVariancePopulationPrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsVariancePopulationPrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsVariancePopulationTenantIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_TENANT_ID_ASC',
  PrivateConversationsVariancePopulationTenantIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_TENANT_ID_DESC',
  PrivateConversationsVariancePopulationTitleAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_TITLE_ASC',
  PrivateConversationsVariancePopulationTitleDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_TITLE_DESC',
  PrivateConversationsVariancePopulationUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  PrivateConversationsVariancePopulationUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  PrivateConversationsVarianceSampleAboutCommentableIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  PrivateConversationsVarianceSampleAboutCommentableIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  PrivateConversationsVarianceSampleAbstractAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_ABSTRACT_ASC',
  PrivateConversationsVarianceSampleAbstractDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_ABSTRACT_DESC',
  PrivateConversationsVarianceSampleCreatedAtAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PrivateConversationsVarianceSampleCreatedAtDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PrivateConversationsVarianceSampleCreatorIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_CREATOR_ID_ASC',
  PrivateConversationsVarianceSampleCreatorIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_CREATOR_ID_DESC',
  PrivateConversationsVarianceSampleIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_ID_ASC',
  PrivateConversationsVarianceSampleIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_ID_DESC',
  PrivateConversationsVarianceSampleIsPublicAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_IS_PUBLIC_ASC',
  PrivateConversationsVarianceSampleIsPublicDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_IS_PUBLIC_DESC',
  PrivateConversationsVarianceSampleLanguageAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_LANGUAGE_ASC',
  PrivateConversationsVarianceSampleLanguageDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_LANGUAGE_DESC',
  PrivateConversationsVarianceSamplePrivateToGroupIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_PRIVATE_TO_GROUP_ID_ASC',
  PrivateConversationsVarianceSamplePrivateToGroupIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_PRIVATE_TO_GROUP_ID_DESC',
  PrivateConversationsVarianceSampleTenantIdAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_TENANT_ID_ASC',
  PrivateConversationsVarianceSampleTenantIdDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_TENANT_ID_DESC',
  PrivateConversationsVarianceSampleTitleAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_TITLE_ASC',
  PrivateConversationsVarianceSampleTitleDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_TITLE_DESC',
  PrivateConversationsVarianceSampleUpdatedAtAsc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  PrivateConversationsVarianceSampleUpdatedAtDesc = 'PRIVATE_CONVERSATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TrialsByStaffIdAverageAvatarAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_AVATAR_ASC',
  TrialsByStaffIdAverageAvatarDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_AVATAR_DESC',
  TrialsByStaffIdAverageCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdAverageCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdAverageCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdAverageCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdAverageCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdAverageCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdAverageCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdAverageCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdAverageCreatedAtAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_CREATED_AT_ASC',
  TrialsByStaffIdAverageCreatedAtDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_CREATED_AT_DESC',
  TrialsByStaffIdAverageCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_CREATED_BY_ID_ASC',
  TrialsByStaffIdAverageCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_CREATED_BY_ID_DESC',
  TrialsByStaffIdAverageEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdAverageEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdAverageHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdAverageHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdAverageHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdAverageHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdAverageHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdAverageHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdAverageHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdAverageHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdAverageHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdAverageHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdAverageHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdAverageHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdAverageHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdAverageHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdAverageIdAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_ID_ASC',
  TrialsByStaffIdAverageIdDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_ID_DESC',
  TrialsByStaffIdAverageInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdAverageInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdAverageMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdAverageMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdAverageMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdAverageMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdAverageMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdAverageMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdAverageMessagesIdAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_MESSAGES_ID_ASC',
  TrialsByStaffIdAverageMessagesIdDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_MESSAGES_ID_DESC',
  TrialsByStaffIdAverageNameAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_NAME_ASC',
  TrialsByStaffIdAverageNameDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_NAME_DESC',
  TrialsByStaffIdAverageStaffIdAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_STAFF_ID_ASC',
  TrialsByStaffIdAverageStaffIdDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_STAFF_ID_DESC',
  TrialsByStaffIdAverageSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdAverageSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdAverageSubjectIdAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_SUBJECT_ID_ASC',
  TrialsByStaffIdAverageSubjectIdDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_SUBJECT_ID_DESC',
  TrialsByStaffIdAverageTenantIdAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_TENANT_ID_ASC',
  TrialsByStaffIdAverageTenantIdDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_TENANT_ID_DESC',
  TrialsByStaffIdAverageUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_AVERAGE_UPDATED_AT_ASC',
  TrialsByStaffIdAverageUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_AVERAGE_UPDATED_AT_DESC',
  TrialsByStaffIdCountAsc = 'TRIALS_BY_STAFF_ID_COUNT_ASC',
  TrialsByStaffIdCountDesc = 'TRIALS_BY_STAFF_ID_COUNT_DESC',
  TrialsByStaffIdDistinctCountAvatarAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_AVATAR_ASC',
  TrialsByStaffIdDistinctCountAvatarDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_AVATAR_DESC',
  TrialsByStaffIdDistinctCountCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdDistinctCountCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdDistinctCountCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdDistinctCountCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdDistinctCountCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdDistinctCountCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdDistinctCountCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdDistinctCountCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdDistinctCountCreatedAtAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  TrialsByStaffIdDistinctCountCreatedAtDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  TrialsByStaffIdDistinctCountCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CREATED_BY_ID_ASC',
  TrialsByStaffIdDistinctCountCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_CREATED_BY_ID_DESC',
  TrialsByStaffIdDistinctCountEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdDistinctCountEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdDistinctCountHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdDistinctCountHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdDistinctCountHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdDistinctCountHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdDistinctCountHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdDistinctCountHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdDistinctCountHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdDistinctCountHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdDistinctCountHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdDistinctCountHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdDistinctCountHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdDistinctCountHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdDistinctCountHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdDistinctCountHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdDistinctCountIdAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_ID_ASC',
  TrialsByStaffIdDistinctCountIdDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_ID_DESC',
  TrialsByStaffIdDistinctCountInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdDistinctCountInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdDistinctCountMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdDistinctCountMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdDistinctCountMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdDistinctCountMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdDistinctCountMessagesIdAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_MESSAGES_ID_ASC',
  TrialsByStaffIdDistinctCountMessagesIdDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_MESSAGES_ID_DESC',
  TrialsByStaffIdDistinctCountNameAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_NAME_ASC',
  TrialsByStaffIdDistinctCountNameDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_NAME_DESC',
  TrialsByStaffIdDistinctCountStaffIdAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_STAFF_ID_ASC',
  TrialsByStaffIdDistinctCountStaffIdDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_STAFF_ID_DESC',
  TrialsByStaffIdDistinctCountSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdDistinctCountSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdDistinctCountSubjectIdAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_SUBJECT_ID_ASC',
  TrialsByStaffIdDistinctCountSubjectIdDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_SUBJECT_ID_DESC',
  TrialsByStaffIdDistinctCountTenantIdAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  TrialsByStaffIdDistinctCountTenantIdDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  TrialsByStaffIdDistinctCountUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  TrialsByStaffIdDistinctCountUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  TrialsByStaffIdMaxAvatarAsc = 'TRIALS_BY_STAFF_ID_MAX_AVATAR_ASC',
  TrialsByStaffIdMaxAvatarDesc = 'TRIALS_BY_STAFF_ID_MAX_AVATAR_DESC',
  TrialsByStaffIdMaxCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_MAX_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdMaxCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_MAX_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdMaxCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_MAX_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdMaxCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_MAX_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdMaxCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_MAX_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdMaxCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_MAX_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdMaxCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_MAX_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdMaxCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_MAX_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdMaxCreatedAtAsc = 'TRIALS_BY_STAFF_ID_MAX_CREATED_AT_ASC',
  TrialsByStaffIdMaxCreatedAtDesc = 'TRIALS_BY_STAFF_ID_MAX_CREATED_AT_DESC',
  TrialsByStaffIdMaxCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_MAX_CREATED_BY_ID_ASC',
  TrialsByStaffIdMaxCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_MAX_CREATED_BY_ID_DESC',
  TrialsByStaffIdMaxEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_MAX_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdMaxEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_MAX_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdMaxHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdMaxHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdMaxHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdMaxHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdMaxHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdMaxHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdMaxHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdMaxHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdMaxHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdMaxHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdMaxHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdMaxHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdMaxHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_MAX_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdMaxHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_MAX_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdMaxIdAsc = 'TRIALS_BY_STAFF_ID_MAX_ID_ASC',
  TrialsByStaffIdMaxIdDesc = 'TRIALS_BY_STAFF_ID_MAX_ID_DESC',
  TrialsByStaffIdMaxInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_MAX_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdMaxInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_MAX_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdMaxMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdMaxMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdMaxMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdMaxMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdMaxMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdMaxMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdMaxMessagesIdAsc = 'TRIALS_BY_STAFF_ID_MAX_MESSAGES_ID_ASC',
  TrialsByStaffIdMaxMessagesIdDesc = 'TRIALS_BY_STAFF_ID_MAX_MESSAGES_ID_DESC',
  TrialsByStaffIdMaxNameAsc = 'TRIALS_BY_STAFF_ID_MAX_NAME_ASC',
  TrialsByStaffIdMaxNameDesc = 'TRIALS_BY_STAFF_ID_MAX_NAME_DESC',
  TrialsByStaffIdMaxStaffIdAsc = 'TRIALS_BY_STAFF_ID_MAX_STAFF_ID_ASC',
  TrialsByStaffIdMaxStaffIdDesc = 'TRIALS_BY_STAFF_ID_MAX_STAFF_ID_DESC',
  TrialsByStaffIdMaxSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_MAX_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdMaxSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_MAX_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdMaxSubjectIdAsc = 'TRIALS_BY_STAFF_ID_MAX_SUBJECT_ID_ASC',
  TrialsByStaffIdMaxSubjectIdDesc = 'TRIALS_BY_STAFF_ID_MAX_SUBJECT_ID_DESC',
  TrialsByStaffIdMaxTenantIdAsc = 'TRIALS_BY_STAFF_ID_MAX_TENANT_ID_ASC',
  TrialsByStaffIdMaxTenantIdDesc = 'TRIALS_BY_STAFF_ID_MAX_TENANT_ID_DESC',
  TrialsByStaffIdMaxUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_MAX_UPDATED_AT_ASC',
  TrialsByStaffIdMaxUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_MAX_UPDATED_AT_DESC',
  TrialsByStaffIdMinAvatarAsc = 'TRIALS_BY_STAFF_ID_MIN_AVATAR_ASC',
  TrialsByStaffIdMinAvatarDesc = 'TRIALS_BY_STAFF_ID_MIN_AVATAR_DESC',
  TrialsByStaffIdMinCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_MIN_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdMinCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_MIN_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdMinCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_MIN_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdMinCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_MIN_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdMinCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_MIN_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdMinCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_MIN_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdMinCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_MIN_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdMinCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_MIN_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdMinCreatedAtAsc = 'TRIALS_BY_STAFF_ID_MIN_CREATED_AT_ASC',
  TrialsByStaffIdMinCreatedAtDesc = 'TRIALS_BY_STAFF_ID_MIN_CREATED_AT_DESC',
  TrialsByStaffIdMinCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_MIN_CREATED_BY_ID_ASC',
  TrialsByStaffIdMinCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_MIN_CREATED_BY_ID_DESC',
  TrialsByStaffIdMinEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_MIN_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdMinEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_MIN_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdMinHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdMinHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdMinHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdMinHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdMinHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdMinHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdMinHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdMinHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdMinHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdMinHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdMinHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdMinHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdMinHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_MIN_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdMinHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_MIN_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdMinIdAsc = 'TRIALS_BY_STAFF_ID_MIN_ID_ASC',
  TrialsByStaffIdMinIdDesc = 'TRIALS_BY_STAFF_ID_MIN_ID_DESC',
  TrialsByStaffIdMinInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_MIN_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdMinInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_MIN_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdMinMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdMinMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdMinMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdMinMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdMinMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdMinMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdMinMessagesIdAsc = 'TRIALS_BY_STAFF_ID_MIN_MESSAGES_ID_ASC',
  TrialsByStaffIdMinMessagesIdDesc = 'TRIALS_BY_STAFF_ID_MIN_MESSAGES_ID_DESC',
  TrialsByStaffIdMinNameAsc = 'TRIALS_BY_STAFF_ID_MIN_NAME_ASC',
  TrialsByStaffIdMinNameDesc = 'TRIALS_BY_STAFF_ID_MIN_NAME_DESC',
  TrialsByStaffIdMinStaffIdAsc = 'TRIALS_BY_STAFF_ID_MIN_STAFF_ID_ASC',
  TrialsByStaffIdMinStaffIdDesc = 'TRIALS_BY_STAFF_ID_MIN_STAFF_ID_DESC',
  TrialsByStaffIdMinSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_MIN_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdMinSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_MIN_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdMinSubjectIdAsc = 'TRIALS_BY_STAFF_ID_MIN_SUBJECT_ID_ASC',
  TrialsByStaffIdMinSubjectIdDesc = 'TRIALS_BY_STAFF_ID_MIN_SUBJECT_ID_DESC',
  TrialsByStaffIdMinTenantIdAsc = 'TRIALS_BY_STAFF_ID_MIN_TENANT_ID_ASC',
  TrialsByStaffIdMinTenantIdDesc = 'TRIALS_BY_STAFF_ID_MIN_TENANT_ID_DESC',
  TrialsByStaffIdMinUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_MIN_UPDATED_AT_ASC',
  TrialsByStaffIdMinUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_MIN_UPDATED_AT_DESC',
  TrialsByStaffIdStddevPopulationAvatarAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_AVATAR_ASC',
  TrialsByStaffIdStddevPopulationAvatarDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_AVATAR_DESC',
  TrialsByStaffIdStddevPopulationCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdStddevPopulationCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdStddevPopulationCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdStddevPopulationCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdStddevPopulationCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdStddevPopulationCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdStddevPopulationCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdStddevPopulationCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdStddevPopulationCreatedAtAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  TrialsByStaffIdStddevPopulationCreatedAtDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  TrialsByStaffIdStddevPopulationCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CREATED_BY_ID_ASC',
  TrialsByStaffIdStddevPopulationCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_CREATED_BY_ID_DESC',
  TrialsByStaffIdStddevPopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdStddevPopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdStddevPopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdStddevPopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdStddevPopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdStddevPopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdStddevPopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdStddevPopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdStddevPopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdStddevPopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdStddevPopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdStddevPopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdStddevPopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdStddevPopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdStddevPopulationHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdStddevPopulationHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdStddevPopulationIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_ID_ASC',
  TrialsByStaffIdStddevPopulationIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_ID_DESC',
  TrialsByStaffIdStddevPopulationInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdStddevPopulationInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdStddevPopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdStddevPopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdStddevPopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdStddevPopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdStddevPopulationMessagesIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_MESSAGES_ID_ASC',
  TrialsByStaffIdStddevPopulationMessagesIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_MESSAGES_ID_DESC',
  TrialsByStaffIdStddevPopulationNameAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_NAME_ASC',
  TrialsByStaffIdStddevPopulationNameDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_NAME_DESC',
  TrialsByStaffIdStddevPopulationStaffIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_STAFF_ID_ASC',
  TrialsByStaffIdStddevPopulationStaffIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_STAFF_ID_DESC',
  TrialsByStaffIdStddevPopulationSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdStddevPopulationSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdStddevPopulationSubjectIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_SUBJECT_ID_ASC',
  TrialsByStaffIdStddevPopulationSubjectIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_SUBJECT_ID_DESC',
  TrialsByStaffIdStddevPopulationTenantIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  TrialsByStaffIdStddevPopulationTenantIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  TrialsByStaffIdStddevPopulationUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  TrialsByStaffIdStddevPopulationUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  TrialsByStaffIdStddevSampleAvatarAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_AVATAR_ASC',
  TrialsByStaffIdStddevSampleAvatarDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_AVATAR_DESC',
  TrialsByStaffIdStddevSampleCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdStddevSampleCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdStddevSampleCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdStddevSampleCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdStddevSampleCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdStddevSampleCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdStddevSampleCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdStddevSampleCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdStddevSampleCreatedAtAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  TrialsByStaffIdStddevSampleCreatedAtDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  TrialsByStaffIdStddevSampleCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CREATED_BY_ID_ASC',
  TrialsByStaffIdStddevSampleCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_CREATED_BY_ID_DESC',
  TrialsByStaffIdStddevSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdStddevSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdStddevSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdStddevSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdStddevSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdStddevSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdStddevSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdStddevSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdStddevSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdStddevSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdStddevSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdStddevSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdStddevSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdStddevSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdStddevSampleHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdStddevSampleHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdStddevSampleIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_ID_ASC',
  TrialsByStaffIdStddevSampleIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_ID_DESC',
  TrialsByStaffIdStddevSampleInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdStddevSampleInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdStddevSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdStddevSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdStddevSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdStddevSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdStddevSampleMessagesIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_MESSAGES_ID_ASC',
  TrialsByStaffIdStddevSampleMessagesIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_MESSAGES_ID_DESC',
  TrialsByStaffIdStddevSampleNameAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_NAME_ASC',
  TrialsByStaffIdStddevSampleNameDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_NAME_DESC',
  TrialsByStaffIdStddevSampleStaffIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_STAFF_ID_ASC',
  TrialsByStaffIdStddevSampleStaffIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_STAFF_ID_DESC',
  TrialsByStaffIdStddevSampleSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdStddevSampleSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdStddevSampleSubjectIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_SUBJECT_ID_ASC',
  TrialsByStaffIdStddevSampleSubjectIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_SUBJECT_ID_DESC',
  TrialsByStaffIdStddevSampleTenantIdAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  TrialsByStaffIdStddevSampleTenantIdDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  TrialsByStaffIdStddevSampleUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TrialsByStaffIdStddevSampleUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TrialsByStaffIdSumAvatarAsc = 'TRIALS_BY_STAFF_ID_SUM_AVATAR_ASC',
  TrialsByStaffIdSumAvatarDesc = 'TRIALS_BY_STAFF_ID_SUM_AVATAR_DESC',
  TrialsByStaffIdSumCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_SUM_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdSumCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_SUM_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdSumCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_SUM_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdSumCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_SUM_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdSumCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_SUM_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdSumCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_SUM_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdSumCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_SUM_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdSumCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_SUM_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdSumCreatedAtAsc = 'TRIALS_BY_STAFF_ID_SUM_CREATED_AT_ASC',
  TrialsByStaffIdSumCreatedAtDesc = 'TRIALS_BY_STAFF_ID_SUM_CREATED_AT_DESC',
  TrialsByStaffIdSumCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_SUM_CREATED_BY_ID_ASC',
  TrialsByStaffIdSumCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_SUM_CREATED_BY_ID_DESC',
  TrialsByStaffIdSumEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_SUM_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdSumEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_SUM_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdSumHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdSumHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdSumHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdSumHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdSumHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdSumHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdSumHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdSumHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdSumHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdSumHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdSumHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdSumHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdSumHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_SUM_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdSumHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_SUM_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdSumIdAsc = 'TRIALS_BY_STAFF_ID_SUM_ID_ASC',
  TrialsByStaffIdSumIdDesc = 'TRIALS_BY_STAFF_ID_SUM_ID_DESC',
  TrialsByStaffIdSumInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_SUM_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdSumInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_SUM_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdSumMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdSumMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdSumMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdSumMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdSumMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdSumMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdSumMessagesIdAsc = 'TRIALS_BY_STAFF_ID_SUM_MESSAGES_ID_ASC',
  TrialsByStaffIdSumMessagesIdDesc = 'TRIALS_BY_STAFF_ID_SUM_MESSAGES_ID_DESC',
  TrialsByStaffIdSumNameAsc = 'TRIALS_BY_STAFF_ID_SUM_NAME_ASC',
  TrialsByStaffIdSumNameDesc = 'TRIALS_BY_STAFF_ID_SUM_NAME_DESC',
  TrialsByStaffIdSumStaffIdAsc = 'TRIALS_BY_STAFF_ID_SUM_STAFF_ID_ASC',
  TrialsByStaffIdSumStaffIdDesc = 'TRIALS_BY_STAFF_ID_SUM_STAFF_ID_DESC',
  TrialsByStaffIdSumSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_SUM_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdSumSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_SUM_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdSumSubjectIdAsc = 'TRIALS_BY_STAFF_ID_SUM_SUBJECT_ID_ASC',
  TrialsByStaffIdSumSubjectIdDesc = 'TRIALS_BY_STAFF_ID_SUM_SUBJECT_ID_DESC',
  TrialsByStaffIdSumTenantIdAsc = 'TRIALS_BY_STAFF_ID_SUM_TENANT_ID_ASC',
  TrialsByStaffIdSumTenantIdDesc = 'TRIALS_BY_STAFF_ID_SUM_TENANT_ID_DESC',
  TrialsByStaffIdSumUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_SUM_UPDATED_AT_ASC',
  TrialsByStaffIdSumUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_SUM_UPDATED_AT_DESC',
  TrialsByStaffIdVariancePopulationAvatarAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_AVATAR_ASC',
  TrialsByStaffIdVariancePopulationAvatarDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_AVATAR_DESC',
  TrialsByStaffIdVariancePopulationCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdVariancePopulationCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdVariancePopulationCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdVariancePopulationCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdVariancePopulationCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdVariancePopulationCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdVariancePopulationCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdVariancePopulationCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdVariancePopulationCreatedAtAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  TrialsByStaffIdVariancePopulationCreatedAtDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  TrialsByStaffIdVariancePopulationCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CREATED_BY_ID_ASC',
  TrialsByStaffIdVariancePopulationCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_CREATED_BY_ID_DESC',
  TrialsByStaffIdVariancePopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdVariancePopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdVariancePopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdVariancePopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdVariancePopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdVariancePopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdVariancePopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdVariancePopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdVariancePopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdVariancePopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdVariancePopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdVariancePopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdVariancePopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdVariancePopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdVariancePopulationHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdVariancePopulationHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdVariancePopulationIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_ID_ASC',
  TrialsByStaffIdVariancePopulationIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_ID_DESC',
  TrialsByStaffIdVariancePopulationInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdVariancePopulationInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdVariancePopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdVariancePopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdVariancePopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdVariancePopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdVariancePopulationMessagesIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_MESSAGES_ID_ASC',
  TrialsByStaffIdVariancePopulationMessagesIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_MESSAGES_ID_DESC',
  TrialsByStaffIdVariancePopulationNameAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_NAME_ASC',
  TrialsByStaffIdVariancePopulationNameDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_NAME_DESC',
  TrialsByStaffIdVariancePopulationStaffIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_STAFF_ID_ASC',
  TrialsByStaffIdVariancePopulationStaffIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_STAFF_ID_DESC',
  TrialsByStaffIdVariancePopulationSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdVariancePopulationSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdVariancePopulationSubjectIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_SUBJECT_ID_ASC',
  TrialsByStaffIdVariancePopulationSubjectIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_SUBJECT_ID_DESC',
  TrialsByStaffIdVariancePopulationTenantIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  TrialsByStaffIdVariancePopulationTenantIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  TrialsByStaffIdVariancePopulationUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TrialsByStaffIdVariancePopulationUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TrialsByStaffIdVarianceSampleAvatarAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_AVATAR_ASC',
  TrialsByStaffIdVarianceSampleAvatarDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_AVATAR_DESC',
  TrialsByStaffIdVarianceSampleCannotUseTheInterventionAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByStaffIdVarianceSampleCannotUseTheInterventionDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByStaffIdVarianceSampleCanAccessErSinceAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByStaffIdVarianceSampleCanAccessErSinceDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByStaffIdVarianceSampleCanAccessSipSinceAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByStaffIdVarianceSampleCanAccessSipSinceDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByStaffIdVarianceSampleCanBeIncludedAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsByStaffIdVarianceSampleCanBeIncludedDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsByStaffIdVarianceSampleCreatedAtAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TrialsByStaffIdVarianceSampleCreatedAtDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TrialsByStaffIdVarianceSampleCreatedByIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CREATED_BY_ID_ASC',
  TrialsByStaffIdVarianceSampleCreatedByIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_CREATED_BY_ID_DESC',
  TrialsByStaffIdVarianceSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByStaffIdVarianceSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByStaffIdVarianceSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByStaffIdVarianceSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByStaffIdVarianceSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByStaffIdVarianceSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByStaffIdVarianceSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByStaffIdVarianceSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByStaffIdVarianceSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByStaffIdVarianceSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByStaffIdVarianceSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByStaffIdVarianceSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByStaffIdVarianceSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByStaffIdVarianceSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByStaffIdVarianceSampleHumanReadableIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsByStaffIdVarianceSampleHumanReadableIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsByStaffIdVarianceSampleIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_ID_ASC',
  TrialsByStaffIdVarianceSampleIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_ID_DESC',
  TrialsByStaffIdVarianceSampleInternalMessagesIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByStaffIdVarianceSampleInternalMessagesIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByStaffIdVarianceSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByStaffIdVarianceSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByStaffIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByStaffIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByStaffIdVarianceSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByStaffIdVarianceSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByStaffIdVarianceSampleMessagesIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_MESSAGES_ID_ASC',
  TrialsByStaffIdVarianceSampleMessagesIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_MESSAGES_ID_DESC',
  TrialsByStaffIdVarianceSampleNameAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_NAME_ASC',
  TrialsByStaffIdVarianceSampleNameDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_NAME_DESC',
  TrialsByStaffIdVarianceSampleStaffIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_STAFF_ID_ASC',
  TrialsByStaffIdVarianceSampleStaffIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_STAFF_ID_DESC',
  TrialsByStaffIdVarianceSampleSubjectsNicknameAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsByStaffIdVarianceSampleSubjectsNicknameDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsByStaffIdVarianceSampleSubjectIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_SUBJECT_ID_ASC',
  TrialsByStaffIdVarianceSampleSubjectIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_SUBJECT_ID_DESC',
  TrialsByStaffIdVarianceSampleTenantIdAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  TrialsByStaffIdVarianceSampleTenantIdDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  TrialsByStaffIdVarianceSampleUpdatedAtAsc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TrialsByStaffIdVarianceSampleUpdatedAtDesc = 'TRIALS_BY_STAFF_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type HavingBigintFilter = {
  equalTo?: InputMaybe<Scalars['BigInt']>;
  greaterThan?: InputMaybe<Scalars['BigInt']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  lessThan?: InputMaybe<Scalars['BigInt']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  notEqualTo?: InputMaybe<Scalars['BigInt']>;
};

export type HavingDatetimeFilter = {
  equalTo?: InputMaybe<Scalars['Datetime']>;
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  lessThan?: InputMaybe<Scalars['Datetime']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
};

export type HavingIntFilter = {
  equalTo?: InputMaybe<Scalars['Int']>;
  greaterThan?: InputMaybe<Scalars['Int']>;
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  lessThan?: InputMaybe<Scalars['Int']>;
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  notEqualTo?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

/** A filter to be used against InternetAddress List fields. All fields are combined with a logical ‘and.’ */
export type InternetAddressListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['InternetAddress']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['InternetAddress']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['InternetAddress']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['InternetAddress']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['InternetAddress']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['InternetAddress']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type Interval = {
  __typename?: 'Interval';
  /** A quantity of days. */
  days?: Maybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: Maybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: Maybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: Maybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: Maybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: Maybe<Scalars['Int']>;
};

/** A filter to be used against Interval fields. All fields are combined with a logical ‘and.’ */
export type IntervalFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<IntervalInput>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<IntervalInput>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<IntervalInput>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<IntervalInput>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<IntervalInput>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<IntervalInput>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<IntervalInput>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<IntervalInput>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<IntervalInput>>;
};

/** An interval of time that has passed where the smallest distinct unit is a second. */
export type IntervalInput = {
  /** A quantity of days. */
  days?: InputMaybe<Scalars['Int']>;
  /** A quantity of hours. */
  hours?: InputMaybe<Scalars['Int']>;
  /** A quantity of minutes. */
  minutes?: InputMaybe<Scalars['Int']>;
  /** A quantity of months. */
  months?: InputMaybe<Scalars['Int']>;
  /**
   * A quantity of seconds. This is the only non-integer field, as all the other
   * fields will dump their overflow into a smaller unit of time. Intervals don’t
   * have a smaller unit than seconds.
   */
  seconds?: InputMaybe<Scalars['Float']>;
  /** A quantity of years. */
  years?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']>>;
};

export type LimesurveyToken = Node & {
  __typename?: 'LimesurveyToken';
  completedAt?: Maybe<Scalars['Datetime']>;
  handedOutAt?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  startedAt?: Maybe<Scalars['Datetime']>;
  surveyId: Scalars['Int'];
  timeOfMeasurement?: Maybe<Scalars['String']>;
  token: Scalars['String'];
  /** Reads a single `Trial` that is related to this `LimesurveyToken`. */
  trial?: Maybe<Trial>;
  trialId?: Maybe<Scalars['UUID']>;
};

export type LimesurveyTokenAggregates = {
  __typename?: 'LimesurveyTokenAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<LimesurveyTokenAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LimesurveyTokenDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<LimesurveyTokenMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<LimesurveyTokenMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<LimesurveyTokenStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<LimesurveyTokenStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<LimesurveyTokenSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<LimesurveyTokenVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<LimesurveyTokenVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `LimesurveyToken` object types. */
export type LimesurveyTokenAggregatesFilter = {
  /** Mean average aggregate over matching `LimesurveyToken` objects. */
  average?: InputMaybe<LimesurveyTokenAverageAggregateFilter>;
  /** Distinct count aggregate over matching `LimesurveyToken` objects. */
  distinctCount?: InputMaybe<LimesurveyTokenDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `LimesurveyToken` object to be included within the aggregate. */
  filter?: InputMaybe<LimesurveyTokenFilter>;
  /** Maximum aggregate over matching `LimesurveyToken` objects. */
  max?: InputMaybe<LimesurveyTokenMaxAggregateFilter>;
  /** Minimum aggregate over matching `LimesurveyToken` objects. */
  min?: InputMaybe<LimesurveyTokenMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `LimesurveyToken` objects. */
  stddevPopulation?: InputMaybe<LimesurveyTokenStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `LimesurveyToken` objects. */
  stddevSample?: InputMaybe<LimesurveyTokenStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `LimesurveyToken` objects. */
  sum?: InputMaybe<LimesurveyTokenSumAggregateFilter>;
  /** Population variance aggregate over matching `LimesurveyToken` objects. */
  variancePopulation?: InputMaybe<LimesurveyTokenVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `LimesurveyToken` objects. */
  varianceSample?: InputMaybe<LimesurveyTokenVarianceSampleAggregateFilter>;
};

export type LimesurveyTokenAverageAggregateFilter = {
  surveyId?: InputMaybe<BigFloatFilter>;
};

export type LimesurveyTokenAverageAggregates = {
  __typename?: 'LimesurveyTokenAverageAggregates';
  /** Mean average of surveyId across the matching connection */
  surveyId?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `LimesurveyToken` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LimesurveyTokenCondition = {
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `handedOutAt` field. */
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `startedAt` field. */
  startedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `surveyId` field. */
  surveyId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `timeOfMeasurement` field. */
  timeOfMeasurement?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `token` field. */
  token?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `trialId` field. */
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type LimesurveyTokenDistinctCountAggregateFilter = {
  completedAt?: InputMaybe<BigIntFilter>;
  handedOutAt?: InputMaybe<BigIntFilter>;
  startedAt?: InputMaybe<BigIntFilter>;
  surveyId?: InputMaybe<BigIntFilter>;
  timeOfMeasurement?: InputMaybe<BigIntFilter>;
  token?: InputMaybe<BigIntFilter>;
  trialId?: InputMaybe<BigIntFilter>;
};

export type LimesurveyTokenDistinctCountAggregates = {
  __typename?: 'LimesurveyTokenDistinctCountAggregates';
  /** Distinct count of completedAt across the matching connection */
  completedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of handedOutAt across the matching connection */
  handedOutAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of startedAt across the matching connection */
  startedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of surveyId across the matching connection */
  surveyId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of timeOfMeasurement across the matching connection */
  timeOfMeasurement?: Maybe<Scalars['BigInt']>;
  /** Distinct count of token across the matching connection */
  token?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trialId across the matching connection */
  trialId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `LimesurveyToken` object types. All fields are combined with a logical ‘and.’ */
export type LimesurveyTokenFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LimesurveyTokenFilter>>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `handedOutAt` field. */
  handedOutAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LimesurveyTokenFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LimesurveyTokenFilter>>;
  /** Filter by the object’s `startedAt` field. */
  startedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `surveyId` field. */
  surveyId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `timeOfMeasurement` field. */
  timeOfMeasurement?: InputMaybe<StringFilter>;
  /** Filter by the object’s `token` field. */
  token?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trial` relation. */
  trial?: InputMaybe<TrialFilter>;
  /** A related `trial` exists. */
  trialExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `trialId` field. */
  trialId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `LimesurveyToken` */
export type LimesurveyTokenInput = {
  completedAt?: InputMaybe<Scalars['Datetime']>;
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  startedAt?: InputMaybe<Scalars['Datetime']>;
  surveyId: Scalars['Int'];
  timeOfMeasurement?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type LimesurveyTokenMaxAggregateFilter = {
  surveyId?: InputMaybe<IntFilter>;
};

export type LimesurveyTokenMaxAggregates = {
  __typename?: 'LimesurveyTokenMaxAggregates';
  /** Maximum of surveyId across the matching connection */
  surveyId?: Maybe<Scalars['Int']>;
};

export type LimesurveyTokenMinAggregateFilter = {
  surveyId?: InputMaybe<IntFilter>;
};

export type LimesurveyTokenMinAggregates = {
  __typename?: 'LimesurveyTokenMinAggregates';
  /** Minimum of surveyId across the matching connection */
  surveyId?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `LimesurveyToken`. Fields that are set will be updated. */
export type LimesurveyTokenPatch = {
  completedAt?: InputMaybe<Scalars['Datetime']>;
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  startedAt?: InputMaybe<Scalars['Datetime']>;
  surveyId?: InputMaybe<Scalars['Int']>;
  timeOfMeasurement?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type LimesurveyTokenStddevPopulationAggregateFilter = {
  surveyId?: InputMaybe<BigFloatFilter>;
};

export type LimesurveyTokenStddevPopulationAggregates = {
  __typename?: 'LimesurveyTokenStddevPopulationAggregates';
  /** Population standard deviation of surveyId across the matching connection */
  surveyId?: Maybe<Scalars['BigFloat']>;
};

export type LimesurveyTokenStddevSampleAggregateFilter = {
  surveyId?: InputMaybe<BigFloatFilter>;
};

export type LimesurveyTokenStddevSampleAggregates = {
  __typename?: 'LimesurveyTokenStddevSampleAggregates';
  /** Sample standard deviation of surveyId across the matching connection */
  surveyId?: Maybe<Scalars['BigFloat']>;
};

export type LimesurveyTokenSumAggregateFilter = {
  surveyId?: InputMaybe<BigIntFilter>;
};

export type LimesurveyTokenSumAggregates = {
  __typename?: 'LimesurveyTokenSumAggregates';
  /** Sum of surveyId across the matching connection */
  surveyId: Scalars['BigInt'];
};

export type LimesurveyTokenVariancePopulationAggregateFilter = {
  surveyId?: InputMaybe<BigFloatFilter>;
};

export type LimesurveyTokenVariancePopulationAggregates = {
  __typename?: 'LimesurveyTokenVariancePopulationAggregates';
  /** Population variance of surveyId across the matching connection */
  surveyId?: Maybe<Scalars['BigFloat']>;
};

export type LimesurveyTokenVarianceSampleAggregateFilter = {
  surveyId?: InputMaybe<BigFloatFilter>;
};

export type LimesurveyTokenVarianceSampleAggregates = {
  __typename?: 'LimesurveyTokenVarianceSampleAggregates';
  /** Sample variance of surveyId across the matching connection */
  surveyId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `LimesurveyToken` values. */
export type LimesurveyTokensConnection = {
  __typename?: 'LimesurveyTokensConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LimesurveyTokenAggregates>;
  /** A list of edges which contains the `LimesurveyToken` and cursor to aid in pagination. */
  edges: Array<LimesurveyTokensEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LimesurveyTokenAggregates>>;
  /** A list of `LimesurveyToken` objects. */
  nodes: Array<LimesurveyToken>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LimesurveyToken` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `LimesurveyToken` values. */
export type LimesurveyTokensConnectionGroupedAggregatesArgs = {
  groupBy: Array<LimesurveyTokensGroupBy>;
  having?: InputMaybe<LimesurveyTokensHavingInput>;
};

/** A `LimesurveyToken` edge in the connection. */
export type LimesurveyTokensEdge = {
  __typename?: 'LimesurveyTokensEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LimesurveyToken` at the end of the edge. */
  node: LimesurveyToken;
};

/** Grouping methods for `LimesurveyToken` for usage during aggregation. */
export enum LimesurveyTokensGroupBy {
  CompletedAt = 'COMPLETED_AT',
  CompletedAtTruncatedToDay = 'COMPLETED_AT_TRUNCATED_TO_DAY',
  CompletedAtTruncatedToHour = 'COMPLETED_AT_TRUNCATED_TO_HOUR',
  HandedOutAt = 'HANDED_OUT_AT',
  HandedOutAtTruncatedToDay = 'HANDED_OUT_AT_TRUNCATED_TO_DAY',
  HandedOutAtTruncatedToHour = 'HANDED_OUT_AT_TRUNCATED_TO_HOUR',
  StartedAt = 'STARTED_AT',
  StartedAtTruncatedToDay = 'STARTED_AT_TRUNCATED_TO_DAY',
  StartedAtTruncatedToHour = 'STARTED_AT_TRUNCATED_TO_HOUR',
  SurveyId = 'SURVEY_ID',
  TimeOfMeasurement = 'TIME_OF_MEASUREMENT',
  Token = 'TOKEN',
  TrialId = 'TRIAL_ID'
}

export type LimesurveyTokensHavingAverageInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

export type LimesurveyTokensHavingDistinctCountInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `LimesurveyToken` aggregates. */
export type LimesurveyTokensHavingInput = {
  AND?: InputMaybe<Array<LimesurveyTokensHavingInput>>;
  OR?: InputMaybe<Array<LimesurveyTokensHavingInput>>;
  average?: InputMaybe<LimesurveyTokensHavingAverageInput>;
  distinctCount?: InputMaybe<LimesurveyTokensHavingDistinctCountInput>;
  max?: InputMaybe<LimesurveyTokensHavingMaxInput>;
  min?: InputMaybe<LimesurveyTokensHavingMinInput>;
  stddevPopulation?: InputMaybe<LimesurveyTokensHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LimesurveyTokensHavingStddevSampleInput>;
  sum?: InputMaybe<LimesurveyTokensHavingSumInput>;
  variancePopulation?: InputMaybe<LimesurveyTokensHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LimesurveyTokensHavingVarianceSampleInput>;
};

export type LimesurveyTokensHavingMaxInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

export type LimesurveyTokensHavingMinInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

export type LimesurveyTokensHavingStddevPopulationInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

export type LimesurveyTokensHavingStddevSampleInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

export type LimesurveyTokensHavingSumInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

export type LimesurveyTokensHavingVariancePopulationInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

export type LimesurveyTokensHavingVarianceSampleInput = {
  completedAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
  startedAt?: InputMaybe<HavingDatetimeFilter>;
  surveyId?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `LimesurveyToken`. */
export enum LimesurveyTokensOrderBy {
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  HandedOutAtAsc = 'HANDED_OUT_AT_ASC',
  HandedOutAtDesc = 'HANDED_OUT_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StartedAtAsc = 'STARTED_AT_ASC',
  StartedAtDesc = 'STARTED_AT_DESC',
  SurveyIdAsc = 'SURVEY_ID_ASC',
  SurveyIdDesc = 'SURVEY_ID_DESC',
  TimeOfMeasurementAsc = 'TIME_OF_MEASUREMENT_ASC',
  TimeOfMeasurementDesc = 'TIME_OF_MEASUREMENT_DESC',
  TokenAsc = 'TOKEN_ASC',
  TokenDesc = 'TOKEN_DESC',
  TrialIdAsc = 'TRIAL_ID_ASC',
  TrialIdDesc = 'TRIAL_ID_DESC'
}

export type ListenPayload = {
  __typename?: 'ListenPayload';
  /** Our root query field type. Allows us to run any query from our subscription payload. */
  query?: Maybe<Query>;
  relatedNode?: Maybe<Node>;
  relatedNodeId?: Maybe<Scalars['ID']>;
};

export enum LogLevel {
  Debug = 'DEBUG',
  Debug2 = 'DEBUG2',
  Error = 'ERROR',
  Fatal = 'FATAL',
  Info = 'INFO',
  Log = 'LOG',
  Notice = 'NOTICE',
  Panic = 'PANIC',
  Warning = 'WARNING'
}

/** All input for the `markPostingsAsReceived` mutation. */
export type MarkPostingsAsReceivedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  postingIds: Array<InputMaybe<Scalars['UUID']>>;
};

/** The output of our `markPostingsAsReceived` mutation. */
export type MarkPostingsAsReceivedPayload = {
  __typename?: 'MarkPostingsAsReceivedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  postingReceipts?: Maybe<Array<PostingReceipt>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Membership = Node & {
  __typename?: 'Membership';
  createdAt: Scalars['Datetime'];
  flags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads a single `Group` that is related to this `Membership`. */
  group?: Maybe<Group>;
  groupId: Scalars['UUID'];
  id: Scalars['UUID'];
  isManagedExternally: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `MembershipInvitationConfirmation`. */
  membershipInvitationConfirmations: MembershipInvitationConfirmationsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  role: GroupRole;
  /** Reads a single `Group` that is related to this `Membership`. */
  subgroup?: Maybe<Group>;
  subgroupId?: Maybe<Scalars['UUID']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
  userId?: Maybe<Scalars['UUID']>;
};


export type MembershipMembershipInvitationConfirmationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationConfirmationCondition>;
  filter?: InputMaybe<MembershipInvitationConfirmationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationConfirmationsOrderBy>>;
};

export type MembershipAggregates = {
  __typename?: 'MembershipAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MembershipDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `Membership` object types. */
export type MembershipAggregatesFilter = {
  /** Distinct count aggregate over matching `Membership` objects. */
  distinctCount?: InputMaybe<MembershipDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Membership` object to be included within the aggregate. */
  filter?: InputMaybe<MembershipFilter>;
};

/**
 * A condition to be used against `Membership` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MembershipCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `flags` field. */
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isManagedExternally` field. */
  isManagedExternally?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `role` field. */
  role?: InputMaybe<GroupRole>;
  /** Checks for equality with the object’s `subgroupId` field. */
  subgroupId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

export type MembershipDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  flags?: InputMaybe<BigIntFilter>;
  groupId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isManagedExternally?: InputMaybe<BigIntFilter>;
  role?: InputMaybe<BigIntFilter>;
  subgroupId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type MembershipDistinctCountAggregates = {
  __typename?: 'MembershipDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of flags across the matching connection */
  flags?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isManagedExternally across the matching connection */
  isManagedExternally?: Maybe<Scalars['BigInt']>;
  /** Distinct count of role across the matching connection */
  role?: Maybe<Scalars['BigInt']>;
  /** Distinct count of subgroupId across the matching connection */
  subgroupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Membership` object types. All fields are combined with a logical ‘and.’ */
export type MembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MembershipFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `flags` field. */
  flags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `group` relation. */
  group?: InputMaybe<GroupFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isManagedExternally` field. */
  isManagedExternally?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `membershipInvitationConfirmations` relation. */
  membershipInvitationConfirmations?: InputMaybe<MembershipToManyMembershipInvitationConfirmationFilter>;
  /** Some related `membershipInvitationConfirmations` exist. */
  membershipInvitationConfirmationsExist?: InputMaybe<Scalars['Boolean']>;
  /** Negates the expression. */
  not?: InputMaybe<MembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MembershipFilter>>;
  /** Filter by the object’s `role` field. */
  role?: InputMaybe<GroupRoleFilter>;
  /** Filter by the object’s `subgroup` relation. */
  subgroup?: InputMaybe<GroupFilter>;
  /** A related `subgroup` exists. */
  subgroupExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `subgroupId` field. */
  subgroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `Membership` */
export type MembershipInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  isManagedExternally?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<GroupRole>;
  subgroupId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

export type MembershipInvitation = Node & {
  __typename?: 'MembershipInvitation';
  acceptedAt?: Maybe<Scalars['Datetime']>;
  alsoAppliesInSubgroups?: Maybe<Scalars['Boolean']>;
  directedToMembers?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  group?: Maybe<Group>;
  groupId: Scalars['UUID'];
  id: Scalars['UUID'];
  invitedAs: GroupRole;
  invitedAt: Scalars['Datetime'];
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  invitedGroup?: Maybe<Group>;
  invitedGroupId?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  invitedUser?: Maybe<User>;
  invitedUserId?: Maybe<Scalars['UUID']>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  inviter?: Maybe<User>;
  inviterId: Scalars['UUID'];
  maximumUses?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `MembershipInvitationConfirmation`. */
  membershipInvitationConfirmationsByInvitationId: MembershipInvitationConfirmationsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  validUntil?: Maybe<Scalars['Datetime']>;
};


export type MembershipInvitationMembershipInvitationConfirmationsByInvitationIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationConfirmationCondition>;
  filter?: InputMaybe<MembershipInvitationConfirmationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationConfirmationsOrderBy>>;
};

export type MembershipInvitationAggregates = {
  __typename?: 'MembershipInvitationAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<MembershipInvitationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MembershipInvitationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<MembershipInvitationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<MembershipInvitationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<MembershipInvitationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<MembershipInvitationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<MembershipInvitationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<MembershipInvitationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<MembershipInvitationVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `MembershipInvitation` object types. */
export type MembershipInvitationAggregatesFilter = {
  /** Mean average aggregate over matching `MembershipInvitation` objects. */
  average?: InputMaybe<MembershipInvitationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `MembershipInvitation` objects. */
  distinctCount?: InputMaybe<MembershipInvitationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `MembershipInvitation` object to be included within the aggregate. */
  filter?: InputMaybe<MembershipInvitationFilter>;
  /** Maximum aggregate over matching `MembershipInvitation` objects. */
  max?: InputMaybe<MembershipInvitationMaxAggregateFilter>;
  /** Minimum aggregate over matching `MembershipInvitation` objects. */
  min?: InputMaybe<MembershipInvitationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `MembershipInvitation` objects. */
  stddevPopulation?: InputMaybe<MembershipInvitationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `MembershipInvitation` objects. */
  stddevSample?: InputMaybe<MembershipInvitationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `MembershipInvitation` objects. */
  sum?: InputMaybe<MembershipInvitationSumAggregateFilter>;
  /** Population variance aggregate over matching `MembershipInvitation` objects. */
  variancePopulation?: InputMaybe<MembershipInvitationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `MembershipInvitation` objects. */
  varianceSample?: InputMaybe<MembershipInvitationVarianceSampleAggregateFilter>;
};

export type MembershipInvitationAverageAggregateFilter = {
  maximumUses?: InputMaybe<BigFloatFilter>;
};

export type MembershipInvitationAverageAggregates = {
  __typename?: 'MembershipInvitationAverageAggregates';
  /** Mean average of maximumUses across the matching connection */
  maximumUses?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `MembershipInvitation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type MembershipInvitationCondition = {
  /** Checks for equality with the object’s `acceptedAt` field. */
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `alsoAppliesInSubgroups` field. */
  alsoAppliesInSubgroups?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `directedToMembers` field. */
  directedToMembers?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `invitedAs` field. */
  invitedAs?: InputMaybe<GroupRole>;
  /** Checks for equality with the object’s `invitedAt` field. */
  invitedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `invitedGroupId` field. */
  invitedGroupId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `invitedUserId` field. */
  invitedUserId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `inviterId` field. */
  inviterId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `maximumUses` field. */
  maximumUses?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `note` field. */
  note?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `validUntil` field. */
  validUntil?: InputMaybe<Scalars['Datetime']>;
};

export type MembershipInvitationConfirmation = Node & {
  __typename?: 'MembershipInvitationConfirmation';
  acceptedAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** Reads a single `MembershipInvitation` that is related to this `MembershipInvitationConfirmation`. */
  invitation?: Maybe<MembershipInvitation>;
  invitationId: Scalars['UUID'];
  /** Reads a single `Membership` that is related to this `MembershipInvitationConfirmation`. */
  membership?: Maybe<Membership>;
  membershipId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type MembershipInvitationConfirmationAggregates = {
  __typename?: 'MembershipInvitationConfirmationAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MembershipInvitationConfirmationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `MembershipInvitationConfirmation` object types. */
export type MembershipInvitationConfirmationAggregatesFilter = {
  /** Distinct count aggregate over matching `MembershipInvitationConfirmation` objects. */
  distinctCount?: InputMaybe<MembershipInvitationConfirmationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `MembershipInvitationConfirmation` object to be included within the aggregate. */
  filter?: InputMaybe<MembershipInvitationConfirmationFilter>;
};

/**
 * A condition to be used against `MembershipInvitationConfirmation` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type MembershipInvitationConfirmationCondition = {
  /** Checks for equality with the object’s `acceptedAt` field. */
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `invitationId` field. */
  invitationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `membershipId` field. */
  membershipId?: InputMaybe<Scalars['UUID']>;
};

export type MembershipInvitationConfirmationDistinctCountAggregateFilter = {
  acceptedAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  invitationId?: InputMaybe<BigIntFilter>;
  membershipId?: InputMaybe<BigIntFilter>;
};

export type MembershipInvitationConfirmationDistinctCountAggregates = {
  __typename?: 'MembershipInvitationConfirmationDistinctCountAggregates';
  /** Distinct count of acceptedAt across the matching connection */
  acceptedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of invitationId across the matching connection */
  invitationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of membershipId across the matching connection */
  membershipId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MembershipInvitationConfirmation` object types. All fields are combined with a logical ‘and.’ */
export type MembershipInvitationConfirmationFilter = {
  /** Filter by the object’s `acceptedAt` field. */
  acceptedAt?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MembershipInvitationConfirmationFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `invitation` relation. */
  invitation?: InputMaybe<MembershipInvitationFilter>;
  /** Filter by the object’s `invitationId` field. */
  invitationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `membership` relation. */
  membership?: InputMaybe<MembershipFilter>;
  /** Filter by the object’s `membershipId` field. */
  membershipId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MembershipInvitationConfirmationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MembershipInvitationConfirmationFilter>>;
};

/** An input for mutations affecting `MembershipInvitationConfirmation` */
export type MembershipInvitationConfirmationInput = {
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  invitationId: Scalars['UUID'];
  membershipId: Scalars['UUID'];
};

/** Represents an update to a `MembershipInvitationConfirmation`. Fields that are set will be updated. */
export type MembershipInvitationConfirmationPatch = {
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  invitationId?: InputMaybe<Scalars['UUID']>;
  membershipId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `MembershipInvitationConfirmation` values. */
export type MembershipInvitationConfirmationsConnection = {
  __typename?: 'MembershipInvitationConfirmationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MembershipInvitationConfirmationAggregates>;
  /** A list of edges which contains the `MembershipInvitationConfirmation` and cursor to aid in pagination. */
  edges: Array<MembershipInvitationConfirmationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MembershipInvitationConfirmationAggregates>>;
  /** A list of `MembershipInvitationConfirmation` objects. */
  nodes: Array<MembershipInvitationConfirmation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MembershipInvitationConfirmation` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `MembershipInvitationConfirmation` values. */
export type MembershipInvitationConfirmationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<MembershipInvitationConfirmationsGroupBy>;
  having?: InputMaybe<MembershipInvitationConfirmationsHavingInput>;
};

/** A `MembershipInvitationConfirmation` edge in the connection. */
export type MembershipInvitationConfirmationsEdge = {
  __typename?: 'MembershipInvitationConfirmationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MembershipInvitationConfirmation` at the end of the edge. */
  node: MembershipInvitationConfirmation;
};

/** Grouping methods for `MembershipInvitationConfirmation` for usage during aggregation. */
export enum MembershipInvitationConfirmationsGroupBy {
  AcceptedAt = 'ACCEPTED_AT',
  AcceptedAtTruncatedToDay = 'ACCEPTED_AT_TRUNCATED_TO_DAY',
  AcceptedAtTruncatedToHour = 'ACCEPTED_AT_TRUNCATED_TO_HOUR',
  InvitationId = 'INVITATION_ID',
  MembershipId = 'MEMBERSHIP_ID'
}

export type MembershipInvitationConfirmationsHavingAverageInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationConfirmationsHavingDistinctCountInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `MembershipInvitationConfirmation` aggregates. */
export type MembershipInvitationConfirmationsHavingInput = {
  AND?: InputMaybe<Array<MembershipInvitationConfirmationsHavingInput>>;
  OR?: InputMaybe<Array<MembershipInvitationConfirmationsHavingInput>>;
  average?: InputMaybe<MembershipInvitationConfirmationsHavingAverageInput>;
  distinctCount?: InputMaybe<MembershipInvitationConfirmationsHavingDistinctCountInput>;
  max?: InputMaybe<MembershipInvitationConfirmationsHavingMaxInput>;
  min?: InputMaybe<MembershipInvitationConfirmationsHavingMinInput>;
  stddevPopulation?: InputMaybe<MembershipInvitationConfirmationsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MembershipInvitationConfirmationsHavingStddevSampleInput>;
  sum?: InputMaybe<MembershipInvitationConfirmationsHavingSumInput>;
  variancePopulation?: InputMaybe<MembershipInvitationConfirmationsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MembershipInvitationConfirmationsHavingVarianceSampleInput>;
};

export type MembershipInvitationConfirmationsHavingMaxInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationConfirmationsHavingMinInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationConfirmationsHavingStddevPopulationInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationConfirmationsHavingStddevSampleInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationConfirmationsHavingSumInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationConfirmationsHavingVariancePopulationInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationConfirmationsHavingVarianceSampleInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `MembershipInvitationConfirmation`. */
export enum MembershipInvitationConfirmationsOrderBy {
  AcceptedAtAsc = 'ACCEPTED_AT_ASC',
  AcceptedAtDesc = 'ACCEPTED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvitationIdAsc = 'INVITATION_ID_ASC',
  InvitationIdDesc = 'INVITATION_ID_DESC',
  MembershipIdAsc = 'MEMBERSHIP_ID_ASC',
  MembershipIdDesc = 'MEMBERSHIP_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type MembershipInvitationDistinctCountAggregateFilter = {
  acceptedAt?: InputMaybe<BigIntFilter>;
  alsoAppliesInSubgroups?: InputMaybe<BigIntFilter>;
  directedToMembers?: InputMaybe<BigIntFilter>;
  groupId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  invitedAs?: InputMaybe<BigIntFilter>;
  invitedAt?: InputMaybe<BigIntFilter>;
  invitedGroupId?: InputMaybe<BigIntFilter>;
  invitedUserId?: InputMaybe<BigIntFilter>;
  inviterId?: InputMaybe<BigIntFilter>;
  maximumUses?: InputMaybe<BigIntFilter>;
  note?: InputMaybe<BigIntFilter>;
  validUntil?: InputMaybe<BigIntFilter>;
};

export type MembershipInvitationDistinctCountAggregates = {
  __typename?: 'MembershipInvitationDistinctCountAggregates';
  /** Distinct count of acceptedAt across the matching connection */
  acceptedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of alsoAppliesInSubgroups across the matching connection */
  alsoAppliesInSubgroups?: Maybe<Scalars['BigInt']>;
  /** Distinct count of directedToMembers across the matching connection */
  directedToMembers?: Maybe<Scalars['BigInt']>;
  /** Distinct count of groupId across the matching connection */
  groupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of invitedAs across the matching connection */
  invitedAs?: Maybe<Scalars['BigInt']>;
  /** Distinct count of invitedAt across the matching connection */
  invitedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of invitedGroupId across the matching connection */
  invitedGroupId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of invitedUserId across the matching connection */
  invitedUserId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of inviterId across the matching connection */
  inviterId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of maximumUses across the matching connection */
  maximumUses?: Maybe<Scalars['BigInt']>;
  /** Distinct count of note across the matching connection */
  note?: Maybe<Scalars['BigInt']>;
  /** Distinct count of validUntil across the matching connection */
  validUntil?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `MembershipInvitation` object types. All fields are combined with a logical ‘and.’ */
export type MembershipInvitationFilter = {
  /** Filter by the object’s `acceptedAt` field. */
  acceptedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `alsoAppliesInSubgroups` field. */
  alsoAppliesInSubgroups?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MembershipInvitationFilter>>;
  /** Filter by the object’s `directedToMembers` field. */
  directedToMembers?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `group` relation. */
  group?: InputMaybe<GroupFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `invitedAs` field. */
  invitedAs?: InputMaybe<GroupRoleFilter>;
  /** Filter by the object’s `invitedAt` field. */
  invitedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `invitedGroup` relation. */
  invitedGroup?: InputMaybe<GroupFilter>;
  /** A related `invitedGroup` exists. */
  invitedGroupExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `invitedGroupId` field. */
  invitedGroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `invitedUser` relation. */
  invitedUser?: InputMaybe<UserFilter>;
  /** A related `invitedUser` exists. */
  invitedUserExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `invitedUserId` field. */
  invitedUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `inviter` relation. */
  inviter?: InputMaybe<UserFilter>;
  /** Filter by the object’s `inviterId` field. */
  inviterId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `maximumUses` field. */
  maximumUses?: InputMaybe<IntFilter>;
  /** Filter by the object’s `membershipInvitationConfirmationsByInvitationId` relation. */
  membershipInvitationConfirmationsByInvitationId?: InputMaybe<MembershipInvitationToManyMembershipInvitationConfirmationFilter>;
  /** Some related `membershipInvitationConfirmationsByInvitationId` exist. */
  membershipInvitationConfirmationsByInvitationIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Negates the expression. */
  not?: InputMaybe<MembershipInvitationFilter>;
  /** Filter by the object’s `note` field. */
  note?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MembershipInvitationFilter>>;
  /** Filter by the object’s `validUntil` field. */
  validUntil?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `MembershipInvitation` */
export type MembershipInvitationInput = {
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  alsoAppliesInSubgroups?: InputMaybe<Scalars['Boolean']>;
  directedToMembers?: InputMaybe<Scalars['Boolean']>;
  groupId: Scalars['UUID'];
  id?: InputMaybe<Scalars['UUID']>;
  invitedAs?: InputMaybe<GroupRole>;
  invitedAt?: InputMaybe<Scalars['Datetime']>;
  invitedGroupId?: InputMaybe<Scalars['UUID']>;
  invitedUserId?: InputMaybe<Scalars['UUID']>;
  inviterId: Scalars['UUID'];
  maximumUses?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  validUntil?: InputMaybe<Scalars['Datetime']>;
};

export type MembershipInvitationMaxAggregateFilter = {
  maximumUses?: InputMaybe<IntFilter>;
};

export type MembershipInvitationMaxAggregates = {
  __typename?: 'MembershipInvitationMaxAggregates';
  /** Maximum of maximumUses across the matching connection */
  maximumUses?: Maybe<Scalars['Int']>;
};

export type MembershipInvitationMinAggregateFilter = {
  maximumUses?: InputMaybe<IntFilter>;
};

export type MembershipInvitationMinAggregates = {
  __typename?: 'MembershipInvitationMinAggregates';
  /** Minimum of maximumUses across the matching connection */
  maximumUses?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `MembershipInvitation`. Fields that are set will be updated. */
export type MembershipInvitationPatch = {
  acceptedAt?: InputMaybe<Scalars['Datetime']>;
  alsoAppliesInSubgroups?: InputMaybe<Scalars['Boolean']>;
  directedToMembers?: InputMaybe<Scalars['Boolean']>;
  groupId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  invitedAs?: InputMaybe<GroupRole>;
  invitedAt?: InputMaybe<Scalars['Datetime']>;
  invitedGroupId?: InputMaybe<Scalars['UUID']>;
  invitedUserId?: InputMaybe<Scalars['UUID']>;
  inviterId?: InputMaybe<Scalars['UUID']>;
  maximumUses?: InputMaybe<Scalars['Int']>;
  note?: InputMaybe<Scalars['String']>;
  validUntil?: InputMaybe<Scalars['Datetime']>;
};

export type MembershipInvitationStddevPopulationAggregateFilter = {
  maximumUses?: InputMaybe<BigFloatFilter>;
};

export type MembershipInvitationStddevPopulationAggregates = {
  __typename?: 'MembershipInvitationStddevPopulationAggregates';
  /** Population standard deviation of maximumUses across the matching connection */
  maximumUses?: Maybe<Scalars['BigFloat']>;
};

export type MembershipInvitationStddevSampleAggregateFilter = {
  maximumUses?: InputMaybe<BigFloatFilter>;
};

export type MembershipInvitationStddevSampleAggregates = {
  __typename?: 'MembershipInvitationStddevSampleAggregates';
  /** Sample standard deviation of maximumUses across the matching connection */
  maximumUses?: Maybe<Scalars['BigFloat']>;
};

export type MembershipInvitationSumAggregateFilter = {
  maximumUses?: InputMaybe<BigIntFilter>;
};

export type MembershipInvitationSumAggregates = {
  __typename?: 'MembershipInvitationSumAggregates';
  /** Sum of maximumUses across the matching connection */
  maximumUses: Scalars['BigInt'];
};

/** A filter to be used against many `MembershipInvitationConfirmation` object types. All fields are combined with a logical ‘and.’ */
export type MembershipInvitationToManyMembershipInvitationConfirmationFilter = {
  /** Aggregates across related `MembershipInvitationConfirmation` match the filter criteria. */
  aggregates?: InputMaybe<MembershipInvitationConfirmationAggregatesFilter>;
  /** Every related `MembershipInvitationConfirmation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MembershipInvitationConfirmationFilter>;
  /** No related `MembershipInvitationConfirmation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MembershipInvitationConfirmationFilter>;
  /** Some related `MembershipInvitationConfirmation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MembershipInvitationConfirmationFilter>;
};

export type MembershipInvitationVariancePopulationAggregateFilter = {
  maximumUses?: InputMaybe<BigFloatFilter>;
};

export type MembershipInvitationVariancePopulationAggregates = {
  __typename?: 'MembershipInvitationVariancePopulationAggregates';
  /** Population variance of maximumUses across the matching connection */
  maximumUses?: Maybe<Scalars['BigFloat']>;
};

export type MembershipInvitationVarianceSampleAggregateFilter = {
  maximumUses?: InputMaybe<BigFloatFilter>;
};

export type MembershipInvitationVarianceSampleAggregates = {
  __typename?: 'MembershipInvitationVarianceSampleAggregates';
  /** Sample variance of maximumUses across the matching connection */
  maximumUses?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `MembershipInvitation` values. */
export type MembershipInvitationsConnection = {
  __typename?: 'MembershipInvitationsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MembershipInvitationAggregates>;
  /** A list of edges which contains the `MembershipInvitation` and cursor to aid in pagination. */
  edges: Array<MembershipInvitationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MembershipInvitationAggregates>>;
  /** A list of `MembershipInvitation` objects. */
  nodes: Array<MembershipInvitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MembershipInvitation` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `MembershipInvitation` values. */
export type MembershipInvitationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<MembershipInvitationsGroupBy>;
  having?: InputMaybe<MembershipInvitationsHavingInput>;
};

/** A `MembershipInvitation` edge in the connection. */
export type MembershipInvitationsEdge = {
  __typename?: 'MembershipInvitationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MembershipInvitation` at the end of the edge. */
  node: MembershipInvitation;
};

/** Grouping methods for `MembershipInvitation` for usage during aggregation. */
export enum MembershipInvitationsGroupBy {
  AcceptedAt = 'ACCEPTED_AT',
  AcceptedAtTruncatedToDay = 'ACCEPTED_AT_TRUNCATED_TO_DAY',
  AcceptedAtTruncatedToHour = 'ACCEPTED_AT_TRUNCATED_TO_HOUR',
  AlsoAppliesInSubgroups = 'ALSO_APPLIES_IN_SUBGROUPS',
  DirectedToMembers = 'DIRECTED_TO_MEMBERS',
  GroupId = 'GROUP_ID',
  InvitedAs = 'INVITED_AS',
  InvitedAt = 'INVITED_AT',
  InvitedAtTruncatedToDay = 'INVITED_AT_TRUNCATED_TO_DAY',
  InvitedAtTruncatedToHour = 'INVITED_AT_TRUNCATED_TO_HOUR',
  InvitedGroupId = 'INVITED_GROUP_ID',
  InvitedUserId = 'INVITED_USER_ID',
  InviterId = 'INVITER_ID',
  MaximumUses = 'MAXIMUM_USES',
  Note = 'NOTE',
  ValidUntil = 'VALID_UNTIL',
  ValidUntilTruncatedToDay = 'VALID_UNTIL_TRUNCATED_TO_DAY',
  ValidUntilTruncatedToHour = 'VALID_UNTIL_TRUNCATED_TO_HOUR'
}

export type MembershipInvitationsHavingAverageInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationsHavingDistinctCountInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `MembershipInvitation` aggregates. */
export type MembershipInvitationsHavingInput = {
  AND?: InputMaybe<Array<MembershipInvitationsHavingInput>>;
  OR?: InputMaybe<Array<MembershipInvitationsHavingInput>>;
  average?: InputMaybe<MembershipInvitationsHavingAverageInput>;
  distinctCount?: InputMaybe<MembershipInvitationsHavingDistinctCountInput>;
  max?: InputMaybe<MembershipInvitationsHavingMaxInput>;
  min?: InputMaybe<MembershipInvitationsHavingMinInput>;
  stddevPopulation?: InputMaybe<MembershipInvitationsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MembershipInvitationsHavingStddevSampleInput>;
  sum?: InputMaybe<MembershipInvitationsHavingSumInput>;
  variancePopulation?: InputMaybe<MembershipInvitationsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MembershipInvitationsHavingVarianceSampleInput>;
};

export type MembershipInvitationsHavingMaxInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationsHavingMinInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationsHavingStddevPopulationInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationsHavingStddevSampleInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationsHavingSumInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationsHavingVariancePopulationInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipInvitationsHavingVarianceSampleInput = {
  acceptedAt?: InputMaybe<HavingDatetimeFilter>;
  invitedAt?: InputMaybe<HavingDatetimeFilter>;
  maximumUses?: InputMaybe<HavingIntFilter>;
  validUntil?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `MembershipInvitation`. */
export enum MembershipInvitationsOrderBy {
  AcceptedAtAsc = 'ACCEPTED_AT_ASC',
  AcceptedAtDesc = 'ACCEPTED_AT_DESC',
  AlsoAppliesInSubgroupsAsc = 'ALSO_APPLIES_IN_SUBGROUPS_ASC',
  AlsoAppliesInSubgroupsDesc = 'ALSO_APPLIES_IN_SUBGROUPS_DESC',
  DirectedToMembersAsc = 'DIRECTED_TO_MEMBERS_ASC',
  DirectedToMembersDesc = 'DIRECTED_TO_MEMBERS_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvitedAsAsc = 'INVITED_AS_ASC',
  InvitedAsDesc = 'INVITED_AS_DESC',
  InvitedAtAsc = 'INVITED_AT_ASC',
  InvitedAtDesc = 'INVITED_AT_DESC',
  InvitedGroupIdAsc = 'INVITED_GROUP_ID_ASC',
  InvitedGroupIdDesc = 'INVITED_GROUP_ID_DESC',
  InvitedUserIdAsc = 'INVITED_USER_ID_ASC',
  InvitedUserIdDesc = 'INVITED_USER_ID_DESC',
  InviterIdAsc = 'INVITER_ID_ASC',
  InviterIdDesc = 'INVITER_ID_DESC',
  MaximumUsesAsc = 'MAXIMUM_USES_ASC',
  MaximumUsesDesc = 'MAXIMUM_USES_DESC',
  MembershipInvitationConfirmationsByInvitationIdAverageAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_AVERAGE_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdAverageAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_AVERAGE_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdAverageIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_AVERAGE_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdAverageIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_AVERAGE_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdAverageInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_AVERAGE_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdAverageInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_AVERAGE_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdAverageMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_AVERAGE_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdAverageMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_AVERAGE_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdCountAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_COUNT_ASC',
  MembershipInvitationConfirmationsByInvitationIdCountDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_COUNT_DESC',
  MembershipInvitationConfirmationsByInvitationIdDistinctCountAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_DISTINCT_COUNT_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdDistinctCountAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_DISTINCT_COUNT_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdDistinctCountIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_DISTINCT_COUNT_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdDistinctCountIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_DISTINCT_COUNT_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdDistinctCountInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_DISTINCT_COUNT_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdDistinctCountInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_DISTINCT_COUNT_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdDistinctCountMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_DISTINCT_COUNT_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdDistinctCountMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_DISTINCT_COUNT_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdMaxAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MAX_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdMaxAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MAX_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdMaxIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MAX_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdMaxIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MAX_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdMaxInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MAX_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdMaxInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MAX_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdMaxMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MAX_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdMaxMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MAX_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdMinAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MIN_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdMinAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MIN_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdMinIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MIN_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdMinIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MIN_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdMinInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MIN_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdMinInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MIN_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdMinMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MIN_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdMinMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_MIN_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdStddevPopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdStddevPopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdStddevPopulationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_POPULATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdStddevPopulationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_POPULATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdStddevPopulationInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_POPULATION_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdStddevPopulationInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_POPULATION_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdStddevPopulationMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_POPULATION_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdStddevPopulationMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_POPULATION_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdStddevSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdStddevSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdStddevSampleIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_SAMPLE_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdStddevSampleIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_SAMPLE_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdStddevSampleInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_SAMPLE_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdStddevSampleInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_SAMPLE_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdStddevSampleMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_SAMPLE_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdStddevSampleMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_STDDEV_SAMPLE_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdSumAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_SUM_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdSumAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_SUM_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdSumIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_SUM_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdSumIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_SUM_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdSumInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_SUM_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdSumInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_SUM_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdSumMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_SUM_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdSumMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_SUM_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdVariancePopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdVariancePopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdVariancePopulationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_POPULATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdVariancePopulationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_POPULATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdVariancePopulationInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_POPULATION_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdVariancePopulationInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_POPULATION_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdVariancePopulationMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_POPULATION_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdVariancePopulationMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_POPULATION_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdVarianceSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsByInvitationIdVarianceSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsByInvitationIdVarianceSampleIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_SAMPLE_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdVarianceSampleIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_SAMPLE_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdVarianceSampleInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_SAMPLE_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdVarianceSampleInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_SAMPLE_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsByInvitationIdVarianceSampleMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_SAMPLE_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsByInvitationIdVarianceSampleMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_BY_INVITATION_ID_VARIANCE_SAMPLE_MEMBERSHIP_ID_DESC',
  Natural = 'NATURAL',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ValidUntilAsc = 'VALID_UNTIL_ASC',
  ValidUntilDesc = 'VALID_UNTIL_DESC'
}

/** Represents an update to a `Membership`. Fields that are set will be updated. */
export type MembershipPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  flags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  groupId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  isManagedExternally?: InputMaybe<Scalars['Boolean']>;
  role?: InputMaybe<GroupRole>;
  subgroupId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against many `MembershipInvitationConfirmation` object types. All fields are combined with a logical ‘and.’ */
export type MembershipToManyMembershipInvitationConfirmationFilter = {
  /** Aggregates across related `MembershipInvitationConfirmation` match the filter criteria. */
  aggregates?: InputMaybe<MembershipInvitationConfirmationAggregatesFilter>;
  /** Every related `MembershipInvitationConfirmation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MembershipInvitationConfirmationFilter>;
  /** No related `MembershipInvitationConfirmation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MembershipInvitationConfirmationFilter>;
  /** Some related `MembershipInvitationConfirmation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MembershipInvitationConfirmationFilter>;
};

/** A connection to a list of `Membership` values. */
export type MembershipsConnection = {
  __typename?: 'MembershipsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MembershipAggregates>;
  /** A list of edges which contains the `Membership` and cursor to aid in pagination. */
  edges: Array<MembershipsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MembershipAggregates>>;
  /** A list of `Membership` objects. */
  nodes: Array<Membership>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Membership` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Membership` values. */
export type MembershipsConnectionGroupedAggregatesArgs = {
  groupBy: Array<MembershipsGroupBy>;
  having?: InputMaybe<MembershipsHavingInput>;
};

/** A `Membership` edge in the connection. */
export type MembershipsEdge = {
  __typename?: 'MembershipsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Membership` at the end of the edge. */
  node: Membership;
};

/** Grouping methods for `Membership` for usage during aggregation. */
export enum MembershipsGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  Flags = 'FLAGS',
  GroupId = 'GROUP_ID',
  IsManagedExternally = 'IS_MANAGED_EXTERNALLY',
  Role = 'ROLE',
  SubgroupId = 'SUBGROUP_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  UserId = 'USER_ID'
}

export type MembershipsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Membership` aggregates. */
export type MembershipsHavingInput = {
  AND?: InputMaybe<Array<MembershipsHavingInput>>;
  OR?: InputMaybe<Array<MembershipsHavingInput>>;
  average?: InputMaybe<MembershipsHavingAverageInput>;
  distinctCount?: InputMaybe<MembershipsHavingDistinctCountInput>;
  max?: InputMaybe<MembershipsHavingMaxInput>;
  min?: InputMaybe<MembershipsHavingMinInput>;
  stddevPopulation?: InputMaybe<MembershipsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MembershipsHavingStddevSampleInput>;
  sum?: InputMaybe<MembershipsHavingSumInput>;
  variancePopulation?: InputMaybe<MembershipsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MembershipsHavingVarianceSampleInput>;
};

export type MembershipsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MembershipsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Membership`. */
export enum MembershipsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FlagsAsc = 'FLAGS_ASC',
  FlagsDesc = 'FLAGS_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsManagedExternallyAsc = 'IS_MANAGED_EXTERNALLY_ASC',
  IsManagedExternallyDesc = 'IS_MANAGED_EXTERNALLY_DESC',
  MembershipInvitationConfirmationsAverageAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_AVERAGE_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsAverageAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_AVERAGE_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsAverageIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_AVERAGE_ID_ASC',
  MembershipInvitationConfirmationsAverageIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_AVERAGE_ID_DESC',
  MembershipInvitationConfirmationsAverageInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_AVERAGE_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsAverageInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_AVERAGE_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsAverageMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_AVERAGE_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsAverageMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_AVERAGE_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsCountAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_COUNT_ASC',
  MembershipInvitationConfirmationsCountDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_COUNT_DESC',
  MembershipInvitationConfirmationsDistinctCountAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_DISTINCT_COUNT_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsDistinctCountAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_DISTINCT_COUNT_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsDistinctCountIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_DISTINCT_COUNT_ID_ASC',
  MembershipInvitationConfirmationsDistinctCountIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_DISTINCT_COUNT_ID_DESC',
  MembershipInvitationConfirmationsDistinctCountInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_DISTINCT_COUNT_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsDistinctCountInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_DISTINCT_COUNT_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsDistinctCountMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_DISTINCT_COUNT_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsDistinctCountMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_DISTINCT_COUNT_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsMaxAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MAX_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsMaxAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MAX_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsMaxIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MAX_ID_ASC',
  MembershipInvitationConfirmationsMaxIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MAX_ID_DESC',
  MembershipInvitationConfirmationsMaxInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MAX_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsMaxInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MAX_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsMaxMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MAX_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsMaxMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MAX_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsMinAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MIN_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsMinAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MIN_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsMinIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MIN_ID_ASC',
  MembershipInvitationConfirmationsMinIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MIN_ID_DESC',
  MembershipInvitationConfirmationsMinInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MIN_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsMinInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MIN_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsMinMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MIN_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsMinMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_MIN_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsStddevPopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsStddevPopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsStddevPopulationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_POPULATION_ID_ASC',
  MembershipInvitationConfirmationsStddevPopulationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_POPULATION_ID_DESC',
  MembershipInvitationConfirmationsStddevPopulationInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_POPULATION_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsStddevPopulationInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_POPULATION_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsStddevPopulationMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_POPULATION_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsStddevPopulationMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_POPULATION_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsStddevSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsStddevSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsStddevSampleIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_SAMPLE_ID_ASC',
  MembershipInvitationConfirmationsStddevSampleIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_SAMPLE_ID_DESC',
  MembershipInvitationConfirmationsStddevSampleInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_SAMPLE_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsStddevSampleInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_SAMPLE_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsStddevSampleMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_SAMPLE_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsStddevSampleMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_STDDEV_SAMPLE_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsSumAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_SUM_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsSumAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_SUM_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsSumIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_SUM_ID_ASC',
  MembershipInvitationConfirmationsSumIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_SUM_ID_DESC',
  MembershipInvitationConfirmationsSumInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_SUM_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsSumInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_SUM_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsSumMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_SUM_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsSumMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_SUM_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsVariancePopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsVariancePopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsVariancePopulationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_POPULATION_ID_ASC',
  MembershipInvitationConfirmationsVariancePopulationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_POPULATION_ID_DESC',
  MembershipInvitationConfirmationsVariancePopulationInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_POPULATION_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsVariancePopulationInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_POPULATION_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsVariancePopulationMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_POPULATION_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsVariancePopulationMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_POPULATION_MEMBERSHIP_ID_DESC',
  MembershipInvitationConfirmationsVarianceSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationConfirmationsVarianceSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationConfirmationsVarianceSampleIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_SAMPLE_ID_ASC',
  MembershipInvitationConfirmationsVarianceSampleIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_SAMPLE_ID_DESC',
  MembershipInvitationConfirmationsVarianceSampleInvitationIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_SAMPLE_INVITATION_ID_ASC',
  MembershipInvitationConfirmationsVarianceSampleInvitationIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_SAMPLE_INVITATION_ID_DESC',
  MembershipInvitationConfirmationsVarianceSampleMembershipIdAsc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_SAMPLE_MEMBERSHIP_ID_ASC',
  MembershipInvitationConfirmationsVarianceSampleMembershipIdDesc = 'MEMBERSHIP_INVITATION_CONFIRMATIONS_VARIANCE_SAMPLE_MEMBERSHIP_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  SubgroupIdAsc = 'SUBGROUP_ID_ASC',
  SubgroupIdDesc = 'SUBGROUP_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A filter to be used against MimeType fields. All fields are combined with a logical ‘and.’ */
export type MimeTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['MimeType']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['MimeType']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['MimeType']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['MimeType']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['MimeType']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['MimeType']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['MimeType']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['MimeType']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['MimeType']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['MimeType']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['MimeType']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['MimeType']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['MimeType']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['MimeType']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['MimeType']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['MimeType']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['MimeType']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['MimeType']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['MimeType']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['MimeType']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['MimeType']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['MimeType']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  acceptMembershipInvitation?: Maybe<AcceptMembershipInvitationPayload>;
  /** Creates a single `Commentable`. */
  createCommentable?: Maybe<CreateCommentablePayload>;
  /** Creates a single `Conversation`. */
  createConversation?: Maybe<CreateConversationPayload>;
  /** Creates a single `EmaEntries2`. */
  createEmaEntries2?: Maybe<CreateEmaEntries2Payload>;
  /** Creates a single `EmaEntries3`. */
  createEmaEntries3?: Maybe<CreateEmaEntries3Payload>;
  /** Creates a single `EmaEntry`. */
  createEmaEntry?: Maybe<CreateEmaEntryPayload>;
  /** Creates a single `Event`. */
  createEvent?: Maybe<CreateEventPayload>;
  /** Creates a single `FavoritePage`. */
  createFavoritePage?: Maybe<CreateFavoritePagePayload>;
  /** Creates a single `GenericEmiExercise`. */
  createGenericEmiExercise?: Maybe<CreateGenericEmiExercisePayload>;
  /** Creates a single `Group`. */
  createGroup?: Maybe<CreateGroupPayload>;
  /** Creates a single `GroupHome`. */
  createGroupHome?: Maybe<CreateGroupHomePayload>;
  /** Creates a single `LimesurveyToken`. */
  createLimesurveyToken?: Maybe<CreateLimesurveyTokenPayload>;
  /** Creates a single `Membership`. */
  createMembership?: Maybe<CreateMembershipPayload>;
  /** Creates a single `MembershipInvitation`. */
  createMembershipInvitation?: Maybe<CreateMembershipInvitationPayload>;
  /** Creates a single `MembershipInvitationConfirmation`. */
  createMembershipInvitationConfirmation?: Maybe<CreateMembershipInvitationConfirmationPayload>;
  /** Creates a single `PostWaitRandomizationTicket`. */
  createPostWaitRandomizationTicket?: Maybe<CreatePostWaitRandomizationTicketPayload>;
  /** Creates a single `Posting`. */
  createPosting?: Maybe<CreatePostingPayload>;
  /** Creates a single `PostingReceipt`. */
  createPostingReceipt?: Maybe<CreatePostingReceiptPayload>;
  /** Creates a single `RandomizationTicket`. */
  createRandomizationTicket?: Maybe<CreateRandomizationTicketPayload>;
  /** Creates a single `RawUsageDatum`. */
  createRawUsageDatum?: Maybe<CreateRawUsageDatumPayload>;
  /** Creates a single `Tenant`. */
  createTenant?: Maybe<CreateTenantPayload>;
  /** Creates a single `Trial`. */
  createTrial?: Maybe<CreateTrialPayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `UserCredential`. */
  createUserCredential?: Maybe<CreateUserCredentialPayload>;
  /** Creates a single `UserEmailAddress`. */
  createUserEmailAddress?: Maybe<CreateUserEmailAddressPayload>;
  /** Deletes a single `Commentable` using a unique key. */
  deleteCommentable?: Maybe<DeleteCommentablePayload>;
  /** Deletes a single `Commentable` using its globally unique id. */
  deleteCommentableByNodeId?: Maybe<DeleteCommentablePayload>;
  /** Deletes a single `Conversation` using a unique key. */
  deleteConversation?: Maybe<DeleteConversationPayload>;
  /** Deletes a single `Conversation` using its globally unique id. */
  deleteConversationByNodeId?: Maybe<DeleteConversationPayload>;
  /** Deletes a single `EmaEntries2` using a unique key. */
  deleteEmaEntries2?: Maybe<DeleteEmaEntries2Payload>;
  /** Deletes a single `EmaEntries2` using its globally unique id. */
  deleteEmaEntries2ByNodeId?: Maybe<DeleteEmaEntries2Payload>;
  /** Deletes a single `EmaEntries3` using a unique key. */
  deleteEmaEntries3?: Maybe<DeleteEmaEntries3Payload>;
  /** Deletes a single `EmaEntries3` using its globally unique id. */
  deleteEmaEntries3ByNodeId?: Maybe<DeleteEmaEntries3Payload>;
  /** Deletes a single `EmaEntry` using a unique key. */
  deleteEmaEntry?: Maybe<DeleteEmaEntryPayload>;
  /** Deletes a single `EmaEntry` using its globally unique id. */
  deleteEmaEntryByNodeId?: Maybe<DeleteEmaEntryPayload>;
  /** Deletes a single `FavoritePage` using a unique key. */
  deleteFavoritePage?: Maybe<DeleteFavoritePagePayload>;
  /** Deletes a single `FavoritePage` using its globally unique id. */
  deleteFavoritePageByNodeId?: Maybe<DeleteFavoritePagePayload>;
  /** Deletes a single `GenericEmiExercise` using a unique key. */
  deleteGenericEmiExercise?: Maybe<DeleteGenericEmiExercisePayload>;
  /** Deletes a single `GenericEmiExercise` using its globally unique id. */
  deleteGenericEmiExerciseByNodeId?: Maybe<DeleteGenericEmiExercisePayload>;
  /** Deletes a single `GenericEmiExercise` using a unique key. */
  deleteGenericEmiExerciseByTrialIdAndName?: Maybe<DeleteGenericEmiExercisePayload>;
  /** Deletes a single `Group` using a unique key. */
  deleteGroup?: Maybe<DeleteGroupPayload>;
  /** Deletes a single `Group` using its globally unique id. */
  deleteGroupByNodeId?: Maybe<DeleteGroupPayload>;
  /** Deletes a single `GroupHome` using a unique key. */
  deleteGroupHome?: Maybe<DeleteGroupHomePayload>;
  /** Deletes a single `GroupHome` using its globally unique id. */
  deleteGroupHomeByNodeId?: Maybe<DeleteGroupHomePayload>;
  /** Deletes a single `LimesurveyToken` using a unique key. */
  deleteLimesurveyToken?: Maybe<DeleteLimesurveyTokenPayload>;
  /** Deletes a single `LimesurveyToken` using its globally unique id. */
  deleteLimesurveyTokenByNodeId?: Maybe<DeleteLimesurveyTokenPayload>;
  /** Deletes a single `LimesurveyToken` using a unique key. */
  deleteLimesurveyTokenByTrialIdAndTimeOfMeasurement?: Maybe<DeleteLimesurveyTokenPayload>;
  /** Deletes a single `Membership` using a unique key. */
  deleteMembership?: Maybe<DeleteMembershipPayload>;
  /** Deletes a single `Membership` using a unique key. */
  deleteMembershipByGroupIdAndSubgroupId?: Maybe<DeleteMembershipPayload>;
  /** Deletes a single `Membership` using a unique key. */
  deleteMembershipByGroupIdAndUserId?: Maybe<DeleteMembershipPayload>;
  /** Deletes a single `Membership` using its globally unique id. */
  deleteMembershipByNodeId?: Maybe<DeleteMembershipPayload>;
  /** Deletes a single `MembershipInvitation` using a unique key. */
  deleteMembershipInvitation?: Maybe<DeleteMembershipInvitationPayload>;
  /** Deletes a single `MembershipInvitation` using its globally unique id. */
  deleteMembershipInvitationByNodeId?: Maybe<DeleteMembershipInvitationPayload>;
  /** Deletes a single `MembershipInvitationConfirmation` using a unique key. */
  deleteMembershipInvitationConfirmation?: Maybe<DeleteMembershipInvitationConfirmationPayload>;
  /** Deletes a single `MembershipInvitationConfirmation` using its globally unique id. */
  deleteMembershipInvitationConfirmationByNodeId?: Maybe<DeleteMembershipInvitationConfirmationPayload>;
  /** Deletes a single `PostWaitRandomizationTicket` using a unique key. */
  deletePostWaitRandomizationTicket?: Maybe<DeletePostWaitRandomizationTicketPayload>;
  /** Deletes a single `PostWaitRandomizationTicket` using its globally unique id. */
  deletePostWaitRandomizationTicketByNodeId?: Maybe<DeletePostWaitRandomizationTicketPayload>;
  /** Deletes a single `PostWaitRandomizationTicket` using a unique key. */
  deletePostWaitRandomizationTicketByTrialId?: Maybe<DeletePostWaitRandomizationTicketPayload>;
  /** Deletes a single `Posting` using a unique key. */
  deletePosting?: Maybe<DeletePostingPayload>;
  /** Deletes a single `Posting` using its globally unique id. */
  deletePostingByNodeId?: Maybe<DeletePostingPayload>;
  /** Deletes a single `PostingReceipt` using a unique key. */
  deletePostingReceipt?: Maybe<DeletePostingReceiptPayload>;
  /** Deletes a single `PostingReceipt` using its globally unique id. */
  deletePostingReceiptByNodeId?: Maybe<DeletePostingReceiptPayload>;
  /** Deletes a single `RandomizationTicket` using a unique key. */
  deleteRandomizationTicket?: Maybe<DeleteRandomizationTicketPayload>;
  /** Deletes a single `RandomizationTicket` using its globally unique id. */
  deleteRandomizationTicketByNodeId?: Maybe<DeleteRandomizationTicketPayload>;
  /** Deletes a single `RandomizationTicket` using a unique key. */
  deleteRandomizationTicketByTrialId?: Maybe<DeleteRandomizationTicketPayload>;
  /** Deletes a single `RawUsageDatum` using a unique key. */
  deleteRawUsageDatum?: Maybe<DeleteRawUsageDatumPayload>;
  /** Deletes a single `RawUsageDatum` using its globally unique id. */
  deleteRawUsageDatumByNodeId?: Maybe<DeleteRawUsageDatumPayload>;
  /** Deletes a single `Tenant` using a unique key. */
  deleteTenant?: Maybe<DeleteTenantPayload>;
  /** Deletes a single `Tenant` using a unique key. */
  deleteTenantByName?: Maybe<DeleteTenantPayload>;
  /** Deletes a single `Tenant` using its globally unique id. */
  deleteTenantByNodeId?: Maybe<DeleteTenantPayload>;
  /** Deletes a single `Tenant` using a unique key. */
  deleteTenantByUrl?: Maybe<DeleteTenantPayload>;
  /** Deletes a single `Trial` using a unique key. */
  deleteTrial?: Maybe<DeleteTrialPayload>;
  /** Deletes a single `Trial` using a unique key. */
  deleteTrialByHumanReadableId?: Maybe<DeleteTrialPayload>;
  /** Deletes a single `Trial` using a unique key. */
  deleteTrialByName?: Maybe<DeleteTrialPayload>;
  /** Deletes a single `Trial` using its globally unique id. */
  deleteTrialByNodeId?: Maybe<DeleteTrialPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserByCredentialsId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserByEmailAddressId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserByIdAndTenantId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserByNodeId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserByUsernameAndTenantId?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserCredential` using a unique key. */
  deleteUserCredential?: Maybe<DeleteUserCredentialPayload>;
  /** Deletes a single `UserCredential` using a unique key. */
  deleteUserCredentialByExternalAuthIdAndTenantId?: Maybe<DeleteUserCredentialPayload>;
  /** Deletes a single `UserCredential` using its globally unique id. */
  deleteUserCredentialByNodeId?: Maybe<DeleteUserCredentialPayload>;
  /** Deletes a single `UserEmailAddress` using a unique key. */
  deleteUserEmailAddress?: Maybe<DeleteUserEmailAddressPayload>;
  /** Deletes a single `UserEmailAddress` using a unique key. */
  deleteUserEmailAddressByEmailAddressAndTenantId?: Maybe<DeleteUserEmailAddressPayload>;
  /** Deletes a single `UserEmailAddress` using its globally unique id. */
  deleteUserEmailAddressByNodeId?: Maybe<DeleteUserEmailAddressPayload>;
  generateGravatarHashFromEmail?: Maybe<GenerateGravatarHashFromEmailPayload>;
  generateSamplingPlan?: Maybe<GenerateSamplingPlanPayload>;
  generateSamplingPlan2?: Maybe<GenerateSamplingPlan2Payload>;
  generateSamplingPlan3?: Maybe<GenerateSamplingPlan3Payload>;
  markPostingsAsReceived?: Maybe<MarkPostingsAsReceivedPayload>;
  /** Creates one or many `PostingReceipt`. */
  mnCreatePostingReceipt?: Maybe<MnCreatePostingReceiptPayload>;
  /** Deletes one or many `PostingReceipt` a unique key via a patch. */
  mnDeletePostingReceipt?: Maybe<MnDeletePostingReceiptPayload>;
  /** Updates one or many `PostingReceipt` using a unique key and a patch. */
  mnUpdatePostingReceipt?: Maybe<MnUpdatePostingReceiptPayload>;
  pullEmaEntries2?: Maybe<PullEmaEntries2Payload>;
  pushEmaEntry?: Maybe<PushEmaEntryPayload>;
  setAvatar?: Maybe<SetAvatarPayload>;
  setEmergencyPhoneNumbers?: Maybe<SetEmergencyPhoneNumbersPayload>;
  setNickname?: Maybe<SetNicknamePayload>;
  signIn?: Maybe<SignInPayload>;
  signUpWithAccessToken?: Maybe<SignUpWithAccessTokenPayload>;
  toggleFavoriteOfRoute?: Maybe<ToggleFavoriteOfRoutePayload>;
  /** Updates a single `Commentable` using a unique key and a patch. */
  updateCommentable?: Maybe<UpdateCommentablePayload>;
  /** Updates a single `Commentable` using its globally unique id and a patch. */
  updateCommentableByNodeId?: Maybe<UpdateCommentablePayload>;
  /** Updates a single `Conversation` using a unique key and a patch. */
  updateConversation?: Maybe<UpdateConversationPayload>;
  /** Updates a single `Conversation` using its globally unique id and a patch. */
  updateConversationByNodeId?: Maybe<UpdateConversationPayload>;
  /** Updates a single `EmaEntries2` using a unique key and a patch. */
  updateEmaEntries2?: Maybe<UpdateEmaEntries2Payload>;
  /** Updates a single `EmaEntries2` using its globally unique id and a patch. */
  updateEmaEntries2ByNodeId?: Maybe<UpdateEmaEntries2Payload>;
  /** Updates a single `EmaEntries3` using a unique key and a patch. */
  updateEmaEntries3?: Maybe<UpdateEmaEntries3Payload>;
  /** Updates a single `EmaEntries3` using its globally unique id and a patch. */
  updateEmaEntries3ByNodeId?: Maybe<UpdateEmaEntries3Payload>;
  /** Updates a single `EmaEntry` using a unique key and a patch. */
  updateEmaEntry?: Maybe<UpdateEmaEntryPayload>;
  /** Updates a single `EmaEntry` using its globally unique id and a patch. */
  updateEmaEntryByNodeId?: Maybe<UpdateEmaEntryPayload>;
  /** Updates a single `FavoritePage` using a unique key and a patch. */
  updateFavoritePage?: Maybe<UpdateFavoritePagePayload>;
  /** Updates a single `FavoritePage` using its globally unique id and a patch. */
  updateFavoritePageByNodeId?: Maybe<UpdateFavoritePagePayload>;
  /** Updates a single `GenericEmiExercise` using a unique key and a patch. */
  updateGenericEmiExercise?: Maybe<UpdateGenericEmiExercisePayload>;
  /** Updates a single `GenericEmiExercise` using its globally unique id and a patch. */
  updateGenericEmiExerciseByNodeId?: Maybe<UpdateGenericEmiExercisePayload>;
  /** Updates a single `GenericEmiExercise` using a unique key and a patch. */
  updateGenericEmiExerciseByTrialIdAndName?: Maybe<UpdateGenericEmiExercisePayload>;
  /** Updates a single `Group` using a unique key and a patch. */
  updateGroup?: Maybe<UpdateGroupPayload>;
  /** Updates a single `Group` using its globally unique id and a patch. */
  updateGroupByNodeId?: Maybe<UpdateGroupPayload>;
  /** Updates a single `GroupHome` using a unique key and a patch. */
  updateGroupHome?: Maybe<UpdateGroupHomePayload>;
  /** Updates a single `GroupHome` using its globally unique id and a patch. */
  updateGroupHomeByNodeId?: Maybe<UpdateGroupHomePayload>;
  /** Updates a single `LimesurveyToken` using a unique key and a patch. */
  updateLimesurveyToken?: Maybe<UpdateLimesurveyTokenPayload>;
  /** Updates a single `LimesurveyToken` using its globally unique id and a patch. */
  updateLimesurveyTokenByNodeId?: Maybe<UpdateLimesurveyTokenPayload>;
  /** Updates a single `LimesurveyToken` using a unique key and a patch. */
  updateLimesurveyTokenByTrialIdAndTimeOfMeasurement?: Maybe<UpdateLimesurveyTokenPayload>;
  /** Updates a single `Membership` using a unique key and a patch. */
  updateMembership?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Membership` using a unique key and a patch. */
  updateMembershipByGroupIdAndSubgroupId?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Membership` using a unique key and a patch. */
  updateMembershipByGroupIdAndUserId?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `Membership` using its globally unique id and a patch. */
  updateMembershipByNodeId?: Maybe<UpdateMembershipPayload>;
  /** Updates a single `MembershipInvitation` using a unique key and a patch. */
  updateMembershipInvitation?: Maybe<UpdateMembershipInvitationPayload>;
  /** Updates a single `MembershipInvitation` using its globally unique id and a patch. */
  updateMembershipInvitationByNodeId?: Maybe<UpdateMembershipInvitationPayload>;
  /** Updates a single `MembershipInvitationConfirmation` using a unique key and a patch. */
  updateMembershipInvitationConfirmation?: Maybe<UpdateMembershipInvitationConfirmationPayload>;
  /** Updates a single `MembershipInvitationConfirmation` using its globally unique id and a patch. */
  updateMembershipInvitationConfirmationByNodeId?: Maybe<UpdateMembershipInvitationConfirmationPayload>;
  /** Updates a single `PostWaitRandomizationTicket` using a unique key and a patch. */
  updatePostWaitRandomizationTicket?: Maybe<UpdatePostWaitRandomizationTicketPayload>;
  /** Updates a single `PostWaitRandomizationTicket` using its globally unique id and a patch. */
  updatePostWaitRandomizationTicketByNodeId?: Maybe<UpdatePostWaitRandomizationTicketPayload>;
  /** Updates a single `PostWaitRandomizationTicket` using a unique key and a patch. */
  updatePostWaitRandomizationTicketByTrialId?: Maybe<UpdatePostWaitRandomizationTicketPayload>;
  /** Updates a single `Posting` using a unique key and a patch. */
  updatePosting?: Maybe<UpdatePostingPayload>;
  /** Updates a single `Posting` using its globally unique id and a patch. */
  updatePostingByNodeId?: Maybe<UpdatePostingPayload>;
  /** Updates a single `PostingReceipt` using a unique key and a patch. */
  updatePostingReceipt?: Maybe<UpdatePostingReceiptPayload>;
  /** Updates a single `PostingReceipt` using its globally unique id and a patch. */
  updatePostingReceiptByNodeId?: Maybe<UpdatePostingReceiptPayload>;
  /** Updates a single `RandomizationTicket` using a unique key and a patch. */
  updateRandomizationTicket?: Maybe<UpdateRandomizationTicketPayload>;
  /** Updates a single `RandomizationTicket` using its globally unique id and a patch. */
  updateRandomizationTicketByNodeId?: Maybe<UpdateRandomizationTicketPayload>;
  /** Updates a single `RandomizationTicket` using a unique key and a patch. */
  updateRandomizationTicketByTrialId?: Maybe<UpdateRandomizationTicketPayload>;
  /** Updates a single `RawUsageDatum` using a unique key and a patch. */
  updateRawUsageDatum?: Maybe<UpdateRawUsageDatumPayload>;
  /** Updates a single `RawUsageDatum` using its globally unique id and a patch. */
  updateRawUsageDatumByNodeId?: Maybe<UpdateRawUsageDatumPayload>;
  /** Updates a single `Tenant` using a unique key and a patch. */
  updateTenant?: Maybe<UpdateTenantPayload>;
  /** Updates a single `Tenant` using a unique key and a patch. */
  updateTenantByName?: Maybe<UpdateTenantPayload>;
  /** Updates a single `Tenant` using its globally unique id and a patch. */
  updateTenantByNodeId?: Maybe<UpdateTenantPayload>;
  /** Updates a single `Tenant` using a unique key and a patch. */
  updateTenantByUrl?: Maybe<UpdateTenantPayload>;
  /** Updates a single `Trial` using a unique key and a patch. */
  updateTrial?: Maybe<UpdateTrialPayload>;
  /** Updates a single `Trial` using a unique key and a patch. */
  updateTrialByHumanReadableId?: Maybe<UpdateTrialPayload>;
  /** Updates a single `Trial` using a unique key and a patch. */
  updateTrialByName?: Maybe<UpdateTrialPayload>;
  /** Updates a single `Trial` using its globally unique id and a patch. */
  updateTrialByNodeId?: Maybe<UpdateTrialPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByCredentialsId?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByEmailAddressId?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByIdAndTenantId?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByUsernameAndTenantId?: Maybe<UpdateUserPayload>;
  /** Updates a single `UserCredential` using a unique key and a patch. */
  updateUserCredential?: Maybe<UpdateUserCredentialPayload>;
  /** Updates a single `UserCredential` using a unique key and a patch. */
  updateUserCredentialByExternalAuthIdAndTenantId?: Maybe<UpdateUserCredentialPayload>;
  /** Updates a single `UserCredential` using its globally unique id and a patch. */
  updateUserCredentialByNodeId?: Maybe<UpdateUserCredentialPayload>;
  /** Updates a single `UserEmailAddress` using a unique key and a patch. */
  updateUserEmailAddress?: Maybe<UpdateUserEmailAddressPayload>;
  /** Updates a single `UserEmailAddress` using a unique key and a patch. */
  updateUserEmailAddressByEmailAddressAndTenantId?: Maybe<UpdateUserEmailAddressPayload>;
  /** Updates a single `UserEmailAddress` using its globally unique id and a patch. */
  updateUserEmailAddressByNodeId?: Maybe<UpdateUserEmailAddressPayload>;
  /** Upserts a single `Commentable`. */
  upsertCommentable?: Maybe<UpsertCommentablePayload>;
  /** Upserts a single `Conversation`. */
  upsertConversation?: Maybe<UpsertConversationPayload>;
  /** Upserts a single `EmaEntries2`. */
  upsertEmaEntries2?: Maybe<UpsertEmaEntries2Payload>;
  /** Upserts a single `EmaEntries3`. */
  upsertEmaEntries3?: Maybe<UpsertEmaEntries3Payload>;
  /** Upserts a single `EmaEntry`. */
  upsertEmaEntry?: Maybe<UpsertEmaEntryPayload>;
  /** Upserts a single `Event`. */
  upsertEvent?: Maybe<UpsertEventPayload>;
  /** Upserts a single `FavoritePage`. */
  upsertFavoritePage?: Maybe<UpsertFavoritePagePayload>;
  /** Upserts a single `GenericEmiExercise`. */
  upsertGenericEmiExercise?: Maybe<UpsertGenericEmiExercisePayload>;
  /** Upserts a single `Group`. */
  upsertGroup?: Maybe<UpsertGroupPayload>;
  /** Upserts a single `GroupHome`. */
  upsertGroupHome?: Maybe<UpsertGroupHomePayload>;
  /** Upserts a single `LimesurveyToken`. */
  upsertLimesurveyToken?: Maybe<UpsertLimesurveyTokenPayload>;
  /** Upserts a single `Membership`. */
  upsertMembership?: Maybe<UpsertMembershipPayload>;
  /** Upserts a single `MembershipInvitation`. */
  upsertMembershipInvitation?: Maybe<UpsertMembershipInvitationPayload>;
  /** Upserts a single `MembershipInvitationConfirmation`. */
  upsertMembershipInvitationConfirmation?: Maybe<UpsertMembershipInvitationConfirmationPayload>;
  /** Upserts a single `PostWaitRandomizationTicket`. */
  upsertPostWaitRandomizationTicket?: Maybe<UpsertPostWaitRandomizationTicketPayload>;
  /** Upserts a single `Posting`. */
  upsertPosting?: Maybe<UpsertPostingPayload>;
  /** Upserts a single `PostingReceipt`. */
  upsertPostingReceipt?: Maybe<UpsertPostingReceiptPayload>;
  /** Upserts a single `RandomizationTicket`. */
  upsertRandomizationTicket?: Maybe<UpsertRandomizationTicketPayload>;
  /** Upserts a single `RawUsageDatum`. */
  upsertRawUsageDatum?: Maybe<UpsertRawUsageDatumPayload>;
  /** Upserts a single `Tenant`. */
  upsertTenant?: Maybe<UpsertTenantPayload>;
  /** Upserts a single `Trial`. */
  upsertTrial?: Maybe<UpsertTrialPayload>;
  /** Upserts a single `User`. */
  upsertUser?: Maybe<UpsertUserPayload>;
  /** Upserts a single `UserCredential`. */
  upsertUserCredential?: Maybe<UpsertUserCredentialPayload>;
  /** Upserts a single `UserEmailAddress`. */
  upsertUserEmailAddress?: Maybe<UpsertUserEmailAddressPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAcceptMembershipInvitationArgs = {
  input: AcceptMembershipInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCommentableArgs = {
  input: CreateCommentableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateConversationArgs = {
  input: CreateConversationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmaEntries2Args = {
  input: CreateEmaEntries2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmaEntries3Args = {
  input: CreateEmaEntries3Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmaEntryArgs = {
  input: CreateEmaEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEventArgs = {
  input: CreateEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFavoritePageArgs = {
  input: CreateFavoritePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGenericEmiExerciseArgs = {
  input: CreateGenericEmiExerciseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupArgs = {
  input: CreateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGroupHomeArgs = {
  input: CreateGroupHomeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLimesurveyTokenArgs = {
  input: CreateLimesurveyTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMembershipArgs = {
  input: CreateMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMembershipInvitationArgs = {
  input: CreateMembershipInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMembershipInvitationConfirmationArgs = {
  input: CreateMembershipInvitationConfirmationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePostWaitRandomizationTicketArgs = {
  input: CreatePostWaitRandomizationTicketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePostingArgs = {
  input: CreatePostingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePostingReceiptArgs = {
  input: CreatePostingReceiptInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRandomizationTicketArgs = {
  input: CreateRandomizationTicketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRawUsageDatumArgs = {
  input: CreateRawUsageDatumInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTenantArgs = {
  input: CreateTenantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTrialArgs = {
  input: CreateTrialInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserCredentialArgs = {
  input: CreateUserCredentialInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserEmailAddressArgs = {
  input: CreateUserEmailAddressInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommentableArgs = {
  input: DeleteCommentableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCommentableByNodeIdArgs = {
  input: DeleteCommentableByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConversationArgs = {
  input: DeleteConversationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConversationByNodeIdArgs = {
  input: DeleteConversationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmaEntries2Args = {
  input: DeleteEmaEntries2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmaEntries2ByNodeIdArgs = {
  input: DeleteEmaEntries2ByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmaEntries3Args = {
  input: DeleteEmaEntries3Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmaEntries3ByNodeIdArgs = {
  input: DeleteEmaEntries3ByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmaEntryArgs = {
  input: DeleteEmaEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEmaEntryByNodeIdArgs = {
  input: DeleteEmaEntryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFavoritePageArgs = {
  input: DeleteFavoritePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFavoritePageByNodeIdArgs = {
  input: DeleteFavoritePageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenericEmiExerciseArgs = {
  input: DeleteGenericEmiExerciseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenericEmiExerciseByNodeIdArgs = {
  input: DeleteGenericEmiExerciseByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGenericEmiExerciseByTrialIdAndNameArgs = {
  input: DeleteGenericEmiExerciseByTrialIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupArgs = {
  input: DeleteGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupByNodeIdArgs = {
  input: DeleteGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupHomeArgs = {
  input: DeleteGroupHomeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGroupHomeByNodeIdArgs = {
  input: DeleteGroupHomeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLimesurveyTokenArgs = {
  input: DeleteLimesurveyTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLimesurveyTokenByNodeIdArgs = {
  input: DeleteLimesurveyTokenByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLimesurveyTokenByTrialIdAndTimeOfMeasurementArgs = {
  input: DeleteLimesurveyTokenByTrialIdAndTimeOfMeasurementInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipArgs = {
  input: DeleteMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipByGroupIdAndSubgroupIdArgs = {
  input: DeleteMembershipByGroupIdAndSubgroupIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipByGroupIdAndUserIdArgs = {
  input: DeleteMembershipByGroupIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipByNodeIdArgs = {
  input: DeleteMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipInvitationArgs = {
  input: DeleteMembershipInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipInvitationByNodeIdArgs = {
  input: DeleteMembershipInvitationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipInvitationConfirmationArgs = {
  input: DeleteMembershipInvitationConfirmationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMembershipInvitationConfirmationByNodeIdArgs = {
  input: DeleteMembershipInvitationConfirmationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostWaitRandomizationTicketArgs = {
  input: DeletePostWaitRandomizationTicketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostWaitRandomizationTicketByNodeIdArgs = {
  input: DeletePostWaitRandomizationTicketByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostWaitRandomizationTicketByTrialIdArgs = {
  input: DeletePostWaitRandomizationTicketByTrialIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostingArgs = {
  input: DeletePostingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostingByNodeIdArgs = {
  input: DeletePostingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostingReceiptArgs = {
  input: DeletePostingReceiptInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePostingReceiptByNodeIdArgs = {
  input: DeletePostingReceiptByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRandomizationTicketArgs = {
  input: DeleteRandomizationTicketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRandomizationTicketByNodeIdArgs = {
  input: DeleteRandomizationTicketByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRandomizationTicketByTrialIdArgs = {
  input: DeleteRandomizationTicketByTrialIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRawUsageDatumArgs = {
  input: DeleteRawUsageDatumInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRawUsageDatumByNodeIdArgs = {
  input: DeleteRawUsageDatumByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantArgs = {
  input: DeleteTenantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantByNameArgs = {
  input: DeleteTenantByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantByNodeIdArgs = {
  input: DeleteTenantByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTenantByUrlArgs = {
  input: DeleteTenantByUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTrialArgs = {
  input: DeleteTrialInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTrialByHumanReadableIdArgs = {
  input: DeleteTrialByHumanReadableIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTrialByNameArgs = {
  input: DeleteTrialByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteTrialByNodeIdArgs = {
  input: DeleteTrialByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByCredentialsIdArgs = {
  input: DeleteUserByCredentialsIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByEmailAddressIdArgs = {
  input: DeleteUserByEmailAddressIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByIdAndTenantIdArgs = {
  input: DeleteUserByIdAndTenantIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByNodeIdArgs = {
  input: DeleteUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByUsernameAndTenantIdArgs = {
  input: DeleteUserByUsernameAndTenantIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserCredentialArgs = {
  input: DeleteUserCredentialInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserCredentialByExternalAuthIdAndTenantIdArgs = {
  input: DeleteUserCredentialByExternalAuthIdAndTenantIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserCredentialByNodeIdArgs = {
  input: DeleteUserCredentialByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailAddressArgs = {
  input: DeleteUserEmailAddressInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailAddressByEmailAddressAndTenantIdArgs = {
  input: DeleteUserEmailAddressByEmailAddressAndTenantIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserEmailAddressByNodeIdArgs = {
  input: DeleteUserEmailAddressByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateGravatarHashFromEmailArgs = {
  input: GenerateGravatarHashFromEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateSamplingPlanArgs = {
  input: GenerateSamplingPlanInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateSamplingPlan2Args = {
  input: GenerateSamplingPlan2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateSamplingPlan3Args = {
  input: GenerateSamplingPlan3Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMarkPostingsAsReceivedArgs = {
  input: MarkPostingsAsReceivedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnCreatePostingReceiptArgs = {
  input: MnCreatePostingReceiptInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnDeletePostingReceiptArgs = {
  input: MnDeletePostingReceiptInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMnUpdatePostingReceiptArgs = {
  input: MnUpdatePostingReceiptInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPullEmaEntries2Args = {
  input: PullEmaEntries2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPushEmaEntryArgs = {
  input: PushEmaEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetAvatarArgs = {
  input: SetAvatarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetEmergencyPhoneNumbersArgs = {
  input: SetEmergencyPhoneNumbersInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetNicknameArgs = {
  input: SetNicknameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSignInArgs = {
  input: SignInInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSignUpWithAccessTokenArgs = {
  input: SignUpWithAccessTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationToggleFavoriteOfRouteArgs = {
  input: ToggleFavoriteOfRouteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommentableArgs = {
  input: UpdateCommentableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCommentableByNodeIdArgs = {
  input: UpdateCommentableByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConversationArgs = {
  input: UpdateConversationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConversationByNodeIdArgs = {
  input: UpdateConversationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmaEntries2Args = {
  input: UpdateEmaEntries2Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmaEntries2ByNodeIdArgs = {
  input: UpdateEmaEntries2ByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmaEntries3Args = {
  input: UpdateEmaEntries3Input;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmaEntries3ByNodeIdArgs = {
  input: UpdateEmaEntries3ByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmaEntryArgs = {
  input: UpdateEmaEntryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEmaEntryByNodeIdArgs = {
  input: UpdateEmaEntryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFavoritePageArgs = {
  input: UpdateFavoritePageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFavoritePageByNodeIdArgs = {
  input: UpdateFavoritePageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenericEmiExerciseArgs = {
  input: UpdateGenericEmiExerciseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenericEmiExerciseByNodeIdArgs = {
  input: UpdateGenericEmiExerciseByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGenericEmiExerciseByTrialIdAndNameArgs = {
  input: UpdateGenericEmiExerciseByTrialIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupByNodeIdArgs = {
  input: UpdateGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupHomeArgs = {
  input: UpdateGroupHomeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGroupHomeByNodeIdArgs = {
  input: UpdateGroupHomeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLimesurveyTokenArgs = {
  input: UpdateLimesurveyTokenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLimesurveyTokenByNodeIdArgs = {
  input: UpdateLimesurveyTokenByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLimesurveyTokenByTrialIdAndTimeOfMeasurementArgs = {
  input: UpdateLimesurveyTokenByTrialIdAndTimeOfMeasurementInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipArgs = {
  input: UpdateMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipByGroupIdAndSubgroupIdArgs = {
  input: UpdateMembershipByGroupIdAndSubgroupIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipByGroupIdAndUserIdArgs = {
  input: UpdateMembershipByGroupIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipByNodeIdArgs = {
  input: UpdateMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipInvitationArgs = {
  input: UpdateMembershipInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipInvitationByNodeIdArgs = {
  input: UpdateMembershipInvitationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipInvitationConfirmationArgs = {
  input: UpdateMembershipInvitationConfirmationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMembershipInvitationConfirmationByNodeIdArgs = {
  input: UpdateMembershipInvitationConfirmationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostWaitRandomizationTicketArgs = {
  input: UpdatePostWaitRandomizationTicketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostWaitRandomizationTicketByNodeIdArgs = {
  input: UpdatePostWaitRandomizationTicketByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostWaitRandomizationTicketByTrialIdArgs = {
  input: UpdatePostWaitRandomizationTicketByTrialIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostingArgs = {
  input: UpdatePostingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostingByNodeIdArgs = {
  input: UpdatePostingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostingReceiptArgs = {
  input: UpdatePostingReceiptInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePostingReceiptByNodeIdArgs = {
  input: UpdatePostingReceiptByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRandomizationTicketArgs = {
  input: UpdateRandomizationTicketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRandomizationTicketByNodeIdArgs = {
  input: UpdateRandomizationTicketByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRandomizationTicketByTrialIdArgs = {
  input: UpdateRandomizationTicketByTrialIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRawUsageDatumArgs = {
  input: UpdateRawUsageDatumInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRawUsageDatumByNodeIdArgs = {
  input: UpdateRawUsageDatumByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantArgs = {
  input: UpdateTenantInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantByNameArgs = {
  input: UpdateTenantByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantByNodeIdArgs = {
  input: UpdateTenantByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTenantByUrlArgs = {
  input: UpdateTenantByUrlInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTrialArgs = {
  input: UpdateTrialInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTrialByHumanReadableIdArgs = {
  input: UpdateTrialByHumanReadableIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTrialByNameArgs = {
  input: UpdateTrialByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTrialByNodeIdArgs = {
  input: UpdateTrialByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByCredentialsIdArgs = {
  input: UpdateUserByCredentialsIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByEmailAddressIdArgs = {
  input: UpdateUserByEmailAddressIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByIdAndTenantIdArgs = {
  input: UpdateUserByIdAndTenantIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByUsernameAndTenantIdArgs = {
  input: UpdateUserByUsernameAndTenantIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserCredentialArgs = {
  input: UpdateUserCredentialInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserCredentialByExternalAuthIdAndTenantIdArgs = {
  input: UpdateUserCredentialByExternalAuthIdAndTenantIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserCredentialByNodeIdArgs = {
  input: UpdateUserCredentialByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmailAddressArgs = {
  input: UpdateUserEmailAddressInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmailAddressByEmailAddressAndTenantIdArgs = {
  input: UpdateUserEmailAddressByEmailAddressAndTenantIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserEmailAddressByNodeIdArgs = {
  input: UpdateUserEmailAddressByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertCommentableArgs = {
  input: UpsertCommentableInput;
  where?: InputMaybe<UpsertCommentableWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertConversationArgs = {
  input: UpsertConversationInput;
  where?: InputMaybe<UpsertConversationWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEmaEntries2Args = {
  input: UpsertEmaEntries2Input;
  where?: InputMaybe<UpsertEmaEntries2Where>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEmaEntries3Args = {
  input: UpsertEmaEntries3Input;
  where?: InputMaybe<UpsertEmaEntries3Where>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEmaEntryArgs = {
  input: UpsertEmaEntryInput;
  where?: InputMaybe<UpsertEmaEntryWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertEventArgs = {
  input: UpsertEventInput;
  where?: InputMaybe<UpsertEventWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertFavoritePageArgs = {
  input: UpsertFavoritePageInput;
  where?: InputMaybe<UpsertFavoritePageWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertGenericEmiExerciseArgs = {
  input: UpsertGenericEmiExerciseInput;
  where?: InputMaybe<UpsertGenericEmiExerciseWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertGroupArgs = {
  input: UpsertGroupInput;
  where?: InputMaybe<UpsertGroupWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertGroupHomeArgs = {
  input: UpsertGroupHomeInput;
  where?: InputMaybe<UpsertGroupHomeWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertLimesurveyTokenArgs = {
  input: UpsertLimesurveyTokenInput;
  where?: InputMaybe<UpsertLimesurveyTokenWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertMembershipArgs = {
  input: UpsertMembershipInput;
  where?: InputMaybe<UpsertMembershipWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertMembershipInvitationArgs = {
  input: UpsertMembershipInvitationInput;
  where?: InputMaybe<UpsertMembershipInvitationWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertMembershipInvitationConfirmationArgs = {
  input: UpsertMembershipInvitationConfirmationInput;
  where?: InputMaybe<UpsertMembershipInvitationConfirmationWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertPostWaitRandomizationTicketArgs = {
  input: UpsertPostWaitRandomizationTicketInput;
  where?: InputMaybe<UpsertPostWaitRandomizationTicketWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertPostingArgs = {
  input: UpsertPostingInput;
  where?: InputMaybe<UpsertPostingWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertPostingReceiptArgs = {
  input: UpsertPostingReceiptInput;
  where?: InputMaybe<UpsertPostingReceiptWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertRandomizationTicketArgs = {
  input: UpsertRandomizationTicketInput;
  where?: InputMaybe<UpsertRandomizationTicketWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertRawUsageDatumArgs = {
  input: UpsertRawUsageDatumInput;
  where?: InputMaybe<UpsertRawUsageDatumWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTenantArgs = {
  input: UpsertTenantInput;
  where?: InputMaybe<UpsertTenantWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertTrialArgs = {
  input: UpsertTrialInput;
  where?: InputMaybe<UpsertTrialWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserArgs = {
  input: UpsertUserInput;
  where?: InputMaybe<UpsertUserWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserCredentialArgs = {
  input: UpsertUserCredentialInput;
  where?: InputMaybe<UpsertUserCredentialWhere>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserEmailAddressArgs = {
  input: UpsertUserEmailAddressInput;
  where?: InputMaybe<UpsertUserEmailAddressWhere>;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export type PageHit = {
  __typename?: 'PageHit';
  duration?: Maybe<Interval>;
  nthVisit?: Maybe<Scalars['BigInt']>;
  path?: Maybe<Scalars['String']>;
  queryParameters?: Maybe<Scalars['JSON']>;
  route?: Maybe<Scalars['String']>;
  trackedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Trial` that is related to this `PageHit`. */
  trial?: Maybe<Trial>;
  trialId?: Maybe<Scalars['UUID']>;
};

export type PageHitAggregates = {
  __typename?: 'PageHitAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<PageHitAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PageHitDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<PageHitMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<PageHitMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<PageHitStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<PageHitStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<PageHitSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<PageHitVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<PageHitVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `PageHit` object types. */
export type PageHitAggregatesFilter = {
  /** Mean average aggregate over matching `PageHit` objects. */
  average?: InputMaybe<PageHitAverageAggregateFilter>;
  /** Distinct count aggregate over matching `PageHit` objects. */
  distinctCount?: InputMaybe<PageHitDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `PageHit` object to be included within the aggregate. */
  filter?: InputMaybe<PageHitFilter>;
  /** Maximum aggregate over matching `PageHit` objects. */
  max?: InputMaybe<PageHitMaxAggregateFilter>;
  /** Minimum aggregate over matching `PageHit` objects. */
  min?: InputMaybe<PageHitMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `PageHit` objects. */
  stddevPopulation?: InputMaybe<PageHitStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `PageHit` objects. */
  stddevSample?: InputMaybe<PageHitStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `PageHit` objects. */
  sum?: InputMaybe<PageHitSumAggregateFilter>;
  /** Population variance aggregate over matching `PageHit` objects. */
  variancePopulation?: InputMaybe<PageHitVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `PageHit` objects. */
  varianceSample?: InputMaybe<PageHitVarianceSampleAggregateFilter>;
};

export type PageHitAverageAggregateFilter = {
  nthVisit?: InputMaybe<BigFloatFilter>;
};

export type PageHitAverageAggregates = {
  __typename?: 'PageHitAverageAggregates';
  /** Mean average of nthVisit across the matching connection */
  nthVisit?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `PageHit` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PageHitCondition = {
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<IntervalInput>;
  /** Checks for equality with the object’s `nthVisit` field. */
  nthVisit?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `path` field. */
  path?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `queryParameters` field. */
  queryParameters?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `route` field. */
  route?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `trackedAt` field. */
  trackedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `trialId` field. */
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type PageHitDistinctCountAggregateFilter = {
  duration?: InputMaybe<BigIntFilter>;
  nthVisit?: InputMaybe<BigIntFilter>;
  path?: InputMaybe<BigIntFilter>;
  queryParameters?: InputMaybe<BigIntFilter>;
  route?: InputMaybe<BigIntFilter>;
  trackedAt?: InputMaybe<BigIntFilter>;
  trialId?: InputMaybe<BigIntFilter>;
};

export type PageHitDistinctCountAggregates = {
  __typename?: 'PageHitDistinctCountAggregates';
  /** Distinct count of duration across the matching connection */
  duration?: Maybe<Scalars['BigInt']>;
  /** Distinct count of nthVisit across the matching connection */
  nthVisit?: Maybe<Scalars['BigInt']>;
  /** Distinct count of path across the matching connection */
  path?: Maybe<Scalars['BigInt']>;
  /** Distinct count of queryParameters across the matching connection */
  queryParameters?: Maybe<Scalars['BigInt']>;
  /** Distinct count of route across the matching connection */
  route?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trackedAt across the matching connection */
  trackedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trialId across the matching connection */
  trialId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `PageHit` object types. All fields are combined with a logical ‘and.’ */
export type PageHitFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PageHitFilter>>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntervalFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PageHitFilter>;
  /** Filter by the object’s `nthVisit` field. */
  nthVisit?: InputMaybe<BigIntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PageHitFilter>>;
  /** Filter by the object’s `path` field. */
  path?: InputMaybe<StringFilter>;
  /** Filter by the object’s `queryParameters` field. */
  queryParameters?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `route` field. */
  route?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trackedAt` field. */
  trackedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `trial` relation. */
  trial?: InputMaybe<TrialFilter>;
  /** A related `trial` exists. */
  trialExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `trialId` field. */
  trialId?: InputMaybe<UuidFilter>;
};

export type PageHitMaxAggregateFilter = {
  nthVisit?: InputMaybe<BigIntFilter>;
};

export type PageHitMaxAggregates = {
  __typename?: 'PageHitMaxAggregates';
  /** Maximum of nthVisit across the matching connection */
  nthVisit?: Maybe<Scalars['BigInt']>;
};

export type PageHitMinAggregateFilter = {
  nthVisit?: InputMaybe<BigIntFilter>;
};

export type PageHitMinAggregates = {
  __typename?: 'PageHitMinAggregates';
  /** Minimum of nthVisit across the matching connection */
  nthVisit?: Maybe<Scalars['BigInt']>;
};

export type PageHitStddevPopulationAggregateFilter = {
  nthVisit?: InputMaybe<BigFloatFilter>;
};

export type PageHitStddevPopulationAggregates = {
  __typename?: 'PageHitStddevPopulationAggregates';
  /** Population standard deviation of nthVisit across the matching connection */
  nthVisit?: Maybe<Scalars['BigFloat']>;
};

export type PageHitStddevSampleAggregateFilter = {
  nthVisit?: InputMaybe<BigFloatFilter>;
};

export type PageHitStddevSampleAggregates = {
  __typename?: 'PageHitStddevSampleAggregates';
  /** Sample standard deviation of nthVisit across the matching connection */
  nthVisit?: Maybe<Scalars['BigFloat']>;
};

export type PageHitSumAggregateFilter = {
  nthVisit?: InputMaybe<BigFloatFilter>;
};

export type PageHitSumAggregates = {
  __typename?: 'PageHitSumAggregates';
  /** Sum of nthVisit across the matching connection */
  nthVisit: Scalars['BigFloat'];
};

export type PageHitVariancePopulationAggregateFilter = {
  nthVisit?: InputMaybe<BigFloatFilter>;
};

export type PageHitVariancePopulationAggregates = {
  __typename?: 'PageHitVariancePopulationAggregates';
  /** Population variance of nthVisit across the matching connection */
  nthVisit?: Maybe<Scalars['BigFloat']>;
};

export type PageHitVarianceSampleAggregateFilter = {
  nthVisit?: InputMaybe<BigFloatFilter>;
};

export type PageHitVarianceSampleAggregates = {
  __typename?: 'PageHitVarianceSampleAggregates';
  /** Sample variance of nthVisit across the matching connection */
  nthVisit?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `PageHit` values. */
export type PageHitsConnection = {
  __typename?: 'PageHitsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PageHitAggregates>;
  /** A list of edges which contains the `PageHit` and cursor to aid in pagination. */
  edges: Array<PageHitsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PageHitAggregates>>;
  /** A list of `PageHit` objects. */
  nodes: Array<PageHit>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PageHit` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `PageHit` values. */
export type PageHitsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PageHitsGroupBy>;
  having?: InputMaybe<PageHitsHavingInput>;
};

/** A `PageHit` edge in the connection. */
export type PageHitsEdge = {
  __typename?: 'PageHitsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PageHit` at the end of the edge. */
  node: PageHit;
};

/** Grouping methods for `PageHit` for usage during aggregation. */
export enum PageHitsGroupBy {
  Duration = 'DURATION',
  NthVisit = 'NTH_VISIT',
  Path = 'PATH',
  QueryParameters = 'QUERY_PARAMETERS',
  Route = 'ROUTE',
  TrackedAt = 'TRACKED_AT',
  TrackedAtTruncatedToDay = 'TRACKED_AT_TRUNCATED_TO_DAY',
  TrackedAtTruncatedToHour = 'TRACKED_AT_TRUNCATED_TO_HOUR',
  TrialId = 'TRIAL_ID'
}

export type PageHitsHavingAverageInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PageHitsHavingDistinctCountInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `PageHit` aggregates. */
export type PageHitsHavingInput = {
  AND?: InputMaybe<Array<PageHitsHavingInput>>;
  OR?: InputMaybe<Array<PageHitsHavingInput>>;
  average?: InputMaybe<PageHitsHavingAverageInput>;
  distinctCount?: InputMaybe<PageHitsHavingDistinctCountInput>;
  max?: InputMaybe<PageHitsHavingMaxInput>;
  min?: InputMaybe<PageHitsHavingMinInput>;
  stddevPopulation?: InputMaybe<PageHitsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PageHitsHavingStddevSampleInput>;
  sum?: InputMaybe<PageHitsHavingSumInput>;
  variancePopulation?: InputMaybe<PageHitsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PageHitsHavingVarianceSampleInput>;
};

export type PageHitsHavingMaxInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PageHitsHavingMinInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PageHitsHavingStddevPopulationInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PageHitsHavingStddevSampleInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PageHitsHavingSumInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PageHitsHavingVariancePopulationInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PageHitsHavingVarianceSampleInput = {
  nthVisit?: InputMaybe<HavingBigintFilter>;
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `PageHit`. */
export enum PageHitsOrderBy {
  DurationAsc = 'DURATION_ASC',
  DurationDesc = 'DURATION_DESC',
  Natural = 'NATURAL',
  NthVisitAsc = 'NTH_VISIT_ASC',
  NthVisitDesc = 'NTH_VISIT_DESC',
  PathAsc = 'PATH_ASC',
  PathDesc = 'PATH_DESC',
  QueryParametersAsc = 'QUERY_PARAMETERS_ASC',
  QueryParametersDesc = 'QUERY_PARAMETERS_DESC',
  RouteAsc = 'ROUTE_ASC',
  RouteDesc = 'ROUTE_DESC',
  TrackedAtAsc = 'TRACKED_AT_ASC',
  TrackedAtDesc = 'TRACKED_AT_DESC',
  TrialIdAsc = 'TRIAL_ID_ASC',
  TrialIdDesc = 'TRIAL_ID_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
};

export type PostWaitRandomizationTicket = Node & {
  __typename?: 'PostWaitRandomizationTicket';
  blockNumber: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  handedOutAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  stratum: Scalars['String'];
  treatment: Scalars['String'];
  /** Reads a single `Trial` that is related to this `PostWaitRandomizationTicket`. */
  trial?: Maybe<Trial>;
  trialId?: Maybe<Scalars['UUID']>;
};

export type PostWaitRandomizationTicketAggregates = {
  __typename?: 'PostWaitRandomizationTicketAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<PostWaitRandomizationTicketAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PostWaitRandomizationTicketDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<PostWaitRandomizationTicketMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<PostWaitRandomizationTicketMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<PostWaitRandomizationTicketStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<PostWaitRandomizationTicketStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<PostWaitRandomizationTicketSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<PostWaitRandomizationTicketVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<PostWaitRandomizationTicketVarianceSampleAggregates>;
};

export type PostWaitRandomizationTicketAverageAggregates = {
  __typename?: 'PostWaitRandomizationTicketAverageAggregates';
  /** Mean average of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `PostWaitRandomizationTicket` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type PostWaitRandomizationTicketCondition = {
  /** Checks for equality with the object’s `blockNumber` field. */
  blockNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `handedOutAt` field. */
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `stratum` field. */
  stratum?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `treatment` field. */
  treatment?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `trialId` field. */
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type PostWaitRandomizationTicketDistinctCountAggregates = {
  __typename?: 'PostWaitRandomizationTicketDistinctCountAggregates';
  /** Distinct count of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of handedOutAt across the matching connection */
  handedOutAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stratum across the matching connection */
  stratum?: Maybe<Scalars['BigInt']>;
  /** Distinct count of treatment across the matching connection */
  treatment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trialId across the matching connection */
  trialId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `PostWaitRandomizationTicket` object types. All fields are combined with a logical ‘and.’ */
export type PostWaitRandomizationTicketFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostWaitRandomizationTicketFilter>>;
  /** Filter by the object’s `blockNumber` field. */
  blockNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `handedOutAt` field. */
  handedOutAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostWaitRandomizationTicketFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostWaitRandomizationTicketFilter>>;
  /** Filter by the object’s `stratum` field. */
  stratum?: InputMaybe<StringFilter>;
  /** Filter by the object’s `treatment` field. */
  treatment?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trial` relation. */
  trial?: InputMaybe<TrialFilter>;
  /** A related `trial` exists. */
  trialExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `trialId` field. */
  trialId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `PostWaitRandomizationTicket` */
export type PostWaitRandomizationTicketInput = {
  blockNumber: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  stratum: Scalars['String'];
  treatment: Scalars['String'];
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type PostWaitRandomizationTicketMaxAggregates = {
  __typename?: 'PostWaitRandomizationTicketMaxAggregates';
  /** Maximum of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['Int']>;
};

export type PostWaitRandomizationTicketMinAggregates = {
  __typename?: 'PostWaitRandomizationTicketMinAggregates';
  /** Minimum of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `PostWaitRandomizationTicket`. Fields that are set will be updated. */
export type PostWaitRandomizationTicketPatch = {
  blockNumber?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  stratum?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type PostWaitRandomizationTicketStddevPopulationAggregates = {
  __typename?: 'PostWaitRandomizationTicketStddevPopulationAggregates';
  /** Population standard deviation of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

export type PostWaitRandomizationTicketStddevSampleAggregates = {
  __typename?: 'PostWaitRandomizationTicketStddevSampleAggregates';
  /** Sample standard deviation of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

export type PostWaitRandomizationTicketSumAggregates = {
  __typename?: 'PostWaitRandomizationTicketSumAggregates';
  /** Sum of blockNumber across the matching connection */
  blockNumber: Scalars['BigInt'];
};

export type PostWaitRandomizationTicketVariancePopulationAggregates = {
  __typename?: 'PostWaitRandomizationTicketVariancePopulationAggregates';
  /** Population variance of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

export type PostWaitRandomizationTicketVarianceSampleAggregates = {
  __typename?: 'PostWaitRandomizationTicketVarianceSampleAggregates';
  /** Sample variance of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `PostWaitRandomizationTicket` values. */
export type PostWaitRandomizationTicketsConnection = {
  __typename?: 'PostWaitRandomizationTicketsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PostWaitRandomizationTicketAggregates>;
  /** A list of edges which contains the `PostWaitRandomizationTicket` and cursor to aid in pagination. */
  edges: Array<PostWaitRandomizationTicketsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PostWaitRandomizationTicketAggregates>>;
  /** A list of `PostWaitRandomizationTicket` objects. */
  nodes: Array<PostWaitRandomizationTicket>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostWaitRandomizationTicket` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `PostWaitRandomizationTicket` values. */
export type PostWaitRandomizationTicketsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PostWaitRandomizationTicketsGroupBy>;
  having?: InputMaybe<PostWaitRandomizationTicketsHavingInput>;
};

/** A `PostWaitRandomizationTicket` edge in the connection. */
export type PostWaitRandomizationTicketsEdge = {
  __typename?: 'PostWaitRandomizationTicketsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PostWaitRandomizationTicket` at the end of the edge. */
  node: PostWaitRandomizationTicket;
};

/** Grouping methods for `PostWaitRandomizationTicket` for usage during aggregation. */
export enum PostWaitRandomizationTicketsGroupBy {
  BlockNumber = 'BLOCK_NUMBER',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  HandedOutAt = 'HANDED_OUT_AT',
  HandedOutAtTruncatedToDay = 'HANDED_OUT_AT_TRUNCATED_TO_DAY',
  HandedOutAtTruncatedToHour = 'HANDED_OUT_AT_TRUNCATED_TO_HOUR',
  Stratum = 'STRATUM',
  Treatment = 'TREATMENT'
}

export type PostWaitRandomizationTicketsHavingAverageInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostWaitRandomizationTicketsHavingDistinctCountInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `PostWaitRandomizationTicket` aggregates. */
export type PostWaitRandomizationTicketsHavingInput = {
  AND?: InputMaybe<Array<PostWaitRandomizationTicketsHavingInput>>;
  OR?: InputMaybe<Array<PostWaitRandomizationTicketsHavingInput>>;
  average?: InputMaybe<PostWaitRandomizationTicketsHavingAverageInput>;
  distinctCount?: InputMaybe<PostWaitRandomizationTicketsHavingDistinctCountInput>;
  max?: InputMaybe<PostWaitRandomizationTicketsHavingMaxInput>;
  min?: InputMaybe<PostWaitRandomizationTicketsHavingMinInput>;
  stddevPopulation?: InputMaybe<PostWaitRandomizationTicketsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PostWaitRandomizationTicketsHavingStddevSampleInput>;
  sum?: InputMaybe<PostWaitRandomizationTicketsHavingSumInput>;
  variancePopulation?: InputMaybe<PostWaitRandomizationTicketsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PostWaitRandomizationTicketsHavingVarianceSampleInput>;
};

export type PostWaitRandomizationTicketsHavingMaxInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostWaitRandomizationTicketsHavingMinInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostWaitRandomizationTicketsHavingStddevPopulationInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostWaitRandomizationTicketsHavingStddevSampleInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostWaitRandomizationTicketsHavingSumInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostWaitRandomizationTicketsHavingVariancePopulationInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostWaitRandomizationTicketsHavingVarianceSampleInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `PostWaitRandomizationTicket`. */
export enum PostWaitRandomizationTicketsOrderBy {
  BlockNumberAsc = 'BLOCK_NUMBER_ASC',
  BlockNumberDesc = 'BLOCK_NUMBER_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  HandedOutAtAsc = 'HANDED_OUT_AT_ASC',
  HandedOutAtDesc = 'HANDED_OUT_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StratumAsc = 'STRATUM_ASC',
  StratumDesc = 'STRATUM_DESC',
  TreatmentAsc = 'TREATMENT_ASC',
  TreatmentDesc = 'TREATMENT_DESC',
  TrialIdAsc = 'TRIAL_ID_ASC',
  TrialIdDesc = 'TRIAL_ID_DESC'
}

export type Posting = Node & {
  __typename?: 'Posting';
  /** Reads a single `Commentable` that is related to this `Posting`. */
  aboutCommentable?: Maybe<Commentable>;
  aboutCommentableId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Posting` that is related to this `Posting`. */
  answeredPosting?: Maybe<Posting>;
  answeredPostingId?: Maybe<Scalars['UUID']>;
  answeredPostingWasDeleted?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Posting`. */
  answers: PostingsConnection;
  body: Scalars['String'];
  /** Reads a single `Conversation` that is related to this `Posting`. */
  conversation?: Maybe<Conversation>;
  conversationId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  fulltext?: Maybe<Scalars['String']>;
  hashtags?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['UUID'];
  isUnread?: Maybe<Scalars['Boolean']>;
  language: Scalars['String'];
  mentionedUsernames?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Reads and enables pagination through a set of `User`. */
  mentionedUsers: UsersConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PostingReceipt`. */
  receipts: PostingReceiptsConnection;
  /** Reads a single `User` that is related to this `Posting`. */
  sender?: Maybe<User>;
  senderId?: Maybe<Scalars['UUID']>;
  subject?: Maybe<Scalars['String']>;
  submittedAt?: Maybe<Scalars['Datetime']>;
  updatedAt: Scalars['Datetime'];
};


export type PostingAnswersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingCondition>;
  filter?: InputMaybe<PostingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};


export type PostingMentionedUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type PostingReceiptsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingReceiptCondition>;
  filter?: InputMaybe<PostingReceiptFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};

export type PostingAggregates = {
  __typename?: 'PostingAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PostingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `Posting` object types. */
export type PostingAggregatesFilter = {
  /** Distinct count aggregate over matching `Posting` objects. */
  distinctCount?: InputMaybe<PostingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Posting` object to be included within the aggregate. */
  filter?: InputMaybe<PostingFilter>;
};

/** A condition to be used against `Posting` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PostingCondition = {
  /** Checks for equality with the object’s `aboutCommentableId` field. */
  aboutCommentableId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `answeredPostingId` field. */
  answeredPostingId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `answeredPostingWasDeleted` field. */
  answeredPostingWasDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `body` field. */
  body?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `conversationId` field. */
  conversationId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `fulltext` field. */
  fulltext?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `hashtags` field. */
  hashtags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isUnread` field. */
  isUnread?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `language` field. */
  language?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `mentionedUsernames` field. */
  mentionedUsernames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `senderId` field. */
  senderId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `subject` field. */
  subject?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `submittedAt` field. */
  submittedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type PostingDistinctCountAggregateFilter = {
  aboutCommentableId?: InputMaybe<BigIntFilter>;
  answeredPostingId?: InputMaybe<BigIntFilter>;
  answeredPostingWasDeleted?: InputMaybe<BigIntFilter>;
  body?: InputMaybe<BigIntFilter>;
  conversationId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  hashtags?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isUnread?: InputMaybe<BooleanFilter>;
  language?: InputMaybe<BigIntFilter>;
  mentionedUsernames?: InputMaybe<BigIntFilter>;
  senderId?: InputMaybe<BigIntFilter>;
  subject?: InputMaybe<BigIntFilter>;
  submittedAt?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type PostingDistinctCountAggregates = {
  __typename?: 'PostingDistinctCountAggregates';
  /** Distinct count of aboutCommentableId across the matching connection */
  aboutCommentableId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of answeredPostingId across the matching connection */
  answeredPostingId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of answeredPostingWasDeleted across the matching connection */
  answeredPostingWasDeleted?: Maybe<Scalars['BigInt']>;
  /** Distinct count of body across the matching connection */
  body?: Maybe<Scalars['BigInt']>;
  /** Distinct count of conversationId across the matching connection */
  conversationId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  fulltext?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hashtags across the matching connection */
  hashtags?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  isUnread?: Maybe<Scalars['BigInt']>;
  /** Distinct count of language across the matching connection */
  language?: Maybe<Scalars['BigInt']>;
  /** Distinct count of mentionedUsernames across the matching connection */
  mentionedUsernames?: Maybe<Scalars['BigInt']>;
  /** Distinct count of senderId across the matching connection */
  senderId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of subject across the matching connection */
  subject?: Maybe<Scalars['BigInt']>;
  /** Distinct count of submittedAt across the matching connection */
  submittedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Posting` object types. All fields are combined with a logical ‘and.’ */
export type PostingFilter = {
  /** Filter by the object’s `aboutCommentable` relation. */
  aboutCommentable?: InputMaybe<CommentableFilter>;
  /** A related `aboutCommentable` exists. */
  aboutCommentableExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `aboutCommentableId` field. */
  aboutCommentableId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostingFilter>>;
  /** Filter by the object’s `answeredPosting` relation. */
  answeredPosting?: InputMaybe<PostingFilter>;
  /** A related `answeredPosting` exists. */
  answeredPostingExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `answeredPostingId` field. */
  answeredPostingId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `answeredPostingWasDeleted` field. */
  answeredPostingWasDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `answers` relation. */
  answers?: InputMaybe<PostingToManyPostingFilter>;
  /** Some related `answers` exist. */
  answersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `body` field. */
  body?: InputMaybe<StringFilter>;
  /** Filter by the object’s `conversation` relation. */
  conversation?: InputMaybe<ConversationFilter>;
  /** Filter by the object’s `conversationId` field. */
  conversationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `hashtags` field. */
  hashtags?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isUnread` field. */
  isUnread?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `language` field. */
  language?: InputMaybe<StringFilter>;
  /** Filter by the object’s `mentionedUsernames` field. */
  mentionedUsernames?: InputMaybe<StringListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostingFilter>>;
  /** Filter by the object’s `receipts` relation. */
  receipts?: InputMaybe<PostingToManyPostingReceiptFilter>;
  /** Some related `receipts` exist. */
  receiptsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<UserFilter>;
  /** A related `sender` exists. */
  senderExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `senderId` field. */
  senderId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `subject` field. */
  subject?: InputMaybe<StringFilter>;
  /** Filter by the object’s `submittedAt` field. */
  submittedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Posting` */
export type PostingInput = {
  aboutCommentableId?: InputMaybe<Scalars['UUID']>;
  answeredPostingId?: InputMaybe<Scalars['UUID']>;
  answeredPostingWasDeleted?: InputMaybe<Scalars['Boolean']>;
  body: Scalars['String'];
  conversationId: Scalars['UUID'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  hashtags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['UUID']>;
  language?: InputMaybe<Scalars['String']>;
  mentionedUsernames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  senderId?: InputMaybe<Scalars['UUID']>;
  subject?: InputMaybe<Scalars['String']>;
  submittedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `Posting`. Fields that are set will be updated. */
export type PostingPatch = {
  aboutCommentableId?: InputMaybe<Scalars['UUID']>;
  answeredPostingId?: InputMaybe<Scalars['UUID']>;
  answeredPostingWasDeleted?: InputMaybe<Scalars['Boolean']>;
  body?: InputMaybe<Scalars['String']>;
  conversationId?: InputMaybe<Scalars['UUID']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  hashtags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id?: InputMaybe<Scalars['UUID']>;
  language?: InputMaybe<Scalars['String']>;
  mentionedUsernames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  senderId?: InputMaybe<Scalars['UUID']>;
  subject?: InputMaybe<Scalars['String']>;
  submittedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type PostingReceipt = Node & {
  __typename?: 'PostingReceipt';
  manuallyConfirmedAt?: Maybe<Scalars['Datetime']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Posting` that is related to this `PostingReceipt`. */
  posting?: Maybe<Posting>;
  postingId: Scalars['UUID'];
  receivedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `PostingReceipt`. */
  user?: Maybe<User>;
  userId: Scalars['UUID'];
  viewedAt?: Maybe<Scalars['Datetime']>;
};

export type PostingReceiptAggregates = {
  __typename?: 'PostingReceiptAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PostingReceiptDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `PostingReceipt` object types. */
export type PostingReceiptAggregatesFilter = {
  /** Distinct count aggregate over matching `PostingReceipt` objects. */
  distinctCount?: InputMaybe<PostingReceiptDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `PostingReceipt` object to be included within the aggregate. */
  filter?: InputMaybe<PostingReceiptFilter>;
};

/**
 * A condition to be used against `PostingReceipt` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PostingReceiptCondition = {
  /** Checks for equality with the object’s `manuallyConfirmedAt` field. */
  manuallyConfirmedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `postingId` field. */
  postingId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `receivedAt` field. */
  receivedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `viewedAt` field. */
  viewedAt?: InputMaybe<Scalars['Datetime']>;
};

export type PostingReceiptDistinctCountAggregateFilter = {
  manuallyConfirmedAt?: InputMaybe<BigIntFilter>;
  postingId?: InputMaybe<BigIntFilter>;
  receivedAt?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
  viewedAt?: InputMaybe<BigIntFilter>;
};

export type PostingReceiptDistinctCountAggregates = {
  __typename?: 'PostingReceiptDistinctCountAggregates';
  /** Distinct count of manuallyConfirmedAt across the matching connection */
  manuallyConfirmedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of postingId across the matching connection */
  postingId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of receivedAt across the matching connection */
  receivedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of viewedAt across the matching connection */
  viewedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `PostingReceipt` object types. All fields are combined with a logical ‘and.’ */
export type PostingReceiptFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PostingReceiptFilter>>;
  /** Filter by the object’s `manuallyConfirmedAt` field. */
  manuallyConfirmedAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PostingReceiptFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PostingReceiptFilter>>;
  /** Filter by the object’s `posting` relation. */
  posting?: InputMaybe<PostingFilter>;
  /** Filter by the object’s `postingId` field. */
  postingId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `receivedAt` field. */
  receivedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `viewedAt` field. */
  viewedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `PostingReceipt` */
export type PostingReceiptInput = {
  manuallyConfirmedAt?: InputMaybe<Scalars['Datetime']>;
  postingId: Scalars['UUID'];
  receivedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  viewedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `PostingReceipt`. Fields that are set will be updated. */
export type PostingReceiptPatch = {
  manuallyConfirmedAt?: InputMaybe<Scalars['Datetime']>;
  postingId?: InputMaybe<Scalars['UUID']>;
  receivedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  viewedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `PostingReceipt` values. */
export type PostingReceiptsConnection = {
  __typename?: 'PostingReceiptsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PostingReceiptAggregates>;
  /** A list of edges which contains the `PostingReceipt` and cursor to aid in pagination. */
  edges: Array<PostingReceiptsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PostingReceiptAggregates>>;
  /** A list of `PostingReceipt` objects. */
  nodes: Array<PostingReceipt>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PostingReceipt` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `PostingReceipt` values. */
export type PostingReceiptsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PostingReceiptsGroupBy>;
  having?: InputMaybe<PostingReceiptsHavingInput>;
};

/** A `PostingReceipt` edge in the connection. */
export type PostingReceiptsEdge = {
  __typename?: 'PostingReceiptsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PostingReceipt` at the end of the edge. */
  node: PostingReceipt;
};

/** Grouping methods for `PostingReceipt` for usage during aggregation. */
export enum PostingReceiptsGroupBy {
  ManuallyConfirmedAt = 'MANUALLY_CONFIRMED_AT',
  ManuallyConfirmedAtTruncatedToDay = 'MANUALLY_CONFIRMED_AT_TRUNCATED_TO_DAY',
  ManuallyConfirmedAtTruncatedToHour = 'MANUALLY_CONFIRMED_AT_TRUNCATED_TO_HOUR',
  PostingId = 'POSTING_ID',
  ReceivedAt = 'RECEIVED_AT',
  ReceivedAtTruncatedToDay = 'RECEIVED_AT_TRUNCATED_TO_DAY',
  ReceivedAtTruncatedToHour = 'RECEIVED_AT_TRUNCATED_TO_HOUR',
  UserId = 'USER_ID',
  ViewedAt = 'VIEWED_AT',
  ViewedAtTruncatedToDay = 'VIEWED_AT_TRUNCATED_TO_DAY',
  ViewedAtTruncatedToHour = 'VIEWED_AT_TRUNCATED_TO_HOUR'
}

export type PostingReceiptsHavingAverageInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingReceiptsHavingDistinctCountInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `PostingReceipt` aggregates. */
export type PostingReceiptsHavingInput = {
  AND?: InputMaybe<Array<PostingReceiptsHavingInput>>;
  OR?: InputMaybe<Array<PostingReceiptsHavingInput>>;
  average?: InputMaybe<PostingReceiptsHavingAverageInput>;
  distinctCount?: InputMaybe<PostingReceiptsHavingDistinctCountInput>;
  max?: InputMaybe<PostingReceiptsHavingMaxInput>;
  min?: InputMaybe<PostingReceiptsHavingMinInput>;
  stddevPopulation?: InputMaybe<PostingReceiptsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PostingReceiptsHavingStddevSampleInput>;
  sum?: InputMaybe<PostingReceiptsHavingSumInput>;
  variancePopulation?: InputMaybe<PostingReceiptsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PostingReceiptsHavingVarianceSampleInput>;
};

export type PostingReceiptsHavingMaxInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingReceiptsHavingMinInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingReceiptsHavingStddevPopulationInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingReceiptsHavingStddevSampleInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingReceiptsHavingSumInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingReceiptsHavingVariancePopulationInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingReceiptsHavingVarianceSampleInput = {
  manuallyConfirmedAt?: InputMaybe<HavingDatetimeFilter>;
  receivedAt?: InputMaybe<HavingDatetimeFilter>;
  viewedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `PostingReceipt`. */
export enum PostingReceiptsOrderBy {
  ManuallyConfirmedAtAsc = 'MANUALLY_CONFIRMED_AT_ASC',
  ManuallyConfirmedAtDesc = 'MANUALLY_CONFIRMED_AT_DESC',
  Natural = 'NATURAL',
  PostingIdAsc = 'POSTING_ID_ASC',
  PostingIdDesc = 'POSTING_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReceivedAtAsc = 'RECEIVED_AT_ASC',
  ReceivedAtDesc = 'RECEIVED_AT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  ViewedAtAsc = 'VIEWED_AT_ASC',
  ViewedAtDesc = 'VIEWED_AT_DESC'
}

/** A filter to be used against many `Posting` object types. All fields are combined with a logical ‘and.’ */
export type PostingToManyPostingFilter = {
  /** Aggregates across related `Posting` match the filter criteria. */
  aggregates?: InputMaybe<PostingAggregatesFilter>;
  /** Every related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostingFilter>;
  /** No related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostingFilter>;
  /** Some related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostingFilter>;
};

/** A filter to be used against many `PostingReceipt` object types. All fields are combined with a logical ‘and.’ */
export type PostingToManyPostingReceiptFilter = {
  /** Aggregates across related `PostingReceipt` match the filter criteria. */
  aggregates?: InputMaybe<PostingReceiptAggregatesFilter>;
  /** Every related `PostingReceipt` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostingReceiptFilter>;
  /** No related `PostingReceipt` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostingReceiptFilter>;
  /** Some related `PostingReceipt` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostingReceiptFilter>;
};

/** A connection to a list of `Posting` values. */
export type PostingsConnection = {
  __typename?: 'PostingsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PostingAggregates>;
  /** A list of edges which contains the `Posting` and cursor to aid in pagination. */
  edges: Array<PostingsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PostingAggregates>>;
  /** A list of `Posting` objects. */
  nodes: Array<Posting>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Posting` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Posting` values. */
export type PostingsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PostingsGroupBy>;
  having?: InputMaybe<PostingsHavingInput>;
};

/** A `Posting` edge in the connection. */
export type PostingsEdge = {
  __typename?: 'PostingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Posting` at the end of the edge. */
  node: Posting;
};

/** Grouping methods for `Posting` for usage during aggregation. */
export enum PostingsGroupBy {
  AboutCommentableId = 'ABOUT_COMMENTABLE_ID',
  AnsweredPostingId = 'ANSWERED_POSTING_ID',
  AnsweredPostingWasDeleted = 'ANSWERED_POSTING_WAS_DELETED',
  Body = 'BODY',
  ConversationId = 'CONVERSATION_ID',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  Hashtags = 'HASHTAGS',
  Language = 'LANGUAGE',
  MentionedUsernames = 'MENTIONED_USERNAMES',
  SenderId = 'SENDER_ID',
  Subject = 'SUBJECT',
  SubmittedAt = 'SUBMITTED_AT',
  SubmittedAtTruncatedToDay = 'SUBMITTED_AT_TRUNCATED_TO_DAY',
  SubmittedAtTruncatedToHour = 'SUBMITTED_AT_TRUNCATED_TO_HOUR',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type PostingsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Posting` aggregates. */
export type PostingsHavingInput = {
  AND?: InputMaybe<Array<PostingsHavingInput>>;
  OR?: InputMaybe<Array<PostingsHavingInput>>;
  average?: InputMaybe<PostingsHavingAverageInput>;
  distinctCount?: InputMaybe<PostingsHavingDistinctCountInput>;
  max?: InputMaybe<PostingsHavingMaxInput>;
  min?: InputMaybe<PostingsHavingMinInput>;
  stddevPopulation?: InputMaybe<PostingsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PostingsHavingStddevSampleInput>;
  sum?: InputMaybe<PostingsHavingSumInput>;
  variancePopulation?: InputMaybe<PostingsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PostingsHavingVarianceSampleInput>;
};

export type PostingsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PostingsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  submittedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Posting`. */
export enum PostingsOrderBy {
  AboutCommentableIdAsc = 'ABOUT_COMMENTABLE_ID_ASC',
  AboutCommentableIdDesc = 'ABOUT_COMMENTABLE_ID_DESC',
  AnsweredPostingIdAsc = 'ANSWERED_POSTING_ID_ASC',
  AnsweredPostingIdDesc = 'ANSWERED_POSTING_ID_DESC',
  AnsweredPostingWasDeletedAsc = 'ANSWERED_POSTING_WAS_DELETED_ASC',
  AnsweredPostingWasDeletedDesc = 'ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersAverageAboutCommentableIdAsc = 'ANSWERS_AVERAGE_ABOUT_COMMENTABLE_ID_ASC',
  AnswersAverageAboutCommentableIdDesc = 'ANSWERS_AVERAGE_ABOUT_COMMENTABLE_ID_DESC',
  AnswersAverageAnsweredPostingIdAsc = 'ANSWERS_AVERAGE_ANSWERED_POSTING_ID_ASC',
  AnswersAverageAnsweredPostingIdDesc = 'ANSWERS_AVERAGE_ANSWERED_POSTING_ID_DESC',
  AnswersAverageAnsweredPostingWasDeletedAsc = 'ANSWERS_AVERAGE_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersAverageAnsweredPostingWasDeletedDesc = 'ANSWERS_AVERAGE_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersAverageBodyAsc = 'ANSWERS_AVERAGE_BODY_ASC',
  AnswersAverageBodyDesc = 'ANSWERS_AVERAGE_BODY_DESC',
  AnswersAverageConversationIdAsc = 'ANSWERS_AVERAGE_CONVERSATION_ID_ASC',
  AnswersAverageConversationIdDesc = 'ANSWERS_AVERAGE_CONVERSATION_ID_DESC',
  AnswersAverageCreatedAtAsc = 'ANSWERS_AVERAGE_CREATED_AT_ASC',
  AnswersAverageCreatedAtDesc = 'ANSWERS_AVERAGE_CREATED_AT_DESC',
  AnswersAverageHashtagsAsc = 'ANSWERS_AVERAGE_HASHTAGS_ASC',
  AnswersAverageHashtagsDesc = 'ANSWERS_AVERAGE_HASHTAGS_DESC',
  AnswersAverageIdAsc = 'ANSWERS_AVERAGE_ID_ASC',
  AnswersAverageIdDesc = 'ANSWERS_AVERAGE_ID_DESC',
  AnswersAverageLanguageAsc = 'ANSWERS_AVERAGE_LANGUAGE_ASC',
  AnswersAverageLanguageDesc = 'ANSWERS_AVERAGE_LANGUAGE_DESC',
  AnswersAverageMentionedUsernamesAsc = 'ANSWERS_AVERAGE_MENTIONED_USERNAMES_ASC',
  AnswersAverageMentionedUsernamesDesc = 'ANSWERS_AVERAGE_MENTIONED_USERNAMES_DESC',
  AnswersAverageSenderIdAsc = 'ANSWERS_AVERAGE_SENDER_ID_ASC',
  AnswersAverageSenderIdDesc = 'ANSWERS_AVERAGE_SENDER_ID_DESC',
  AnswersAverageSubjectAsc = 'ANSWERS_AVERAGE_SUBJECT_ASC',
  AnswersAverageSubjectDesc = 'ANSWERS_AVERAGE_SUBJECT_DESC',
  AnswersAverageSubmittedAtAsc = 'ANSWERS_AVERAGE_SUBMITTED_AT_ASC',
  AnswersAverageSubmittedAtDesc = 'ANSWERS_AVERAGE_SUBMITTED_AT_DESC',
  AnswersAverageUpdatedAtAsc = 'ANSWERS_AVERAGE_UPDATED_AT_ASC',
  AnswersAverageUpdatedAtDesc = 'ANSWERS_AVERAGE_UPDATED_AT_DESC',
  AnswersCountAsc = 'ANSWERS_COUNT_ASC',
  AnswersCountDesc = 'ANSWERS_COUNT_DESC',
  AnswersDistinctCountAboutCommentableIdAsc = 'ANSWERS_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_ASC',
  AnswersDistinctCountAboutCommentableIdDesc = 'ANSWERS_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_DESC',
  AnswersDistinctCountAnsweredPostingIdAsc = 'ANSWERS_DISTINCT_COUNT_ANSWERED_POSTING_ID_ASC',
  AnswersDistinctCountAnsweredPostingIdDesc = 'ANSWERS_DISTINCT_COUNT_ANSWERED_POSTING_ID_DESC',
  AnswersDistinctCountAnsweredPostingWasDeletedAsc = 'ANSWERS_DISTINCT_COUNT_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersDistinctCountAnsweredPostingWasDeletedDesc = 'ANSWERS_DISTINCT_COUNT_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersDistinctCountBodyAsc = 'ANSWERS_DISTINCT_COUNT_BODY_ASC',
  AnswersDistinctCountBodyDesc = 'ANSWERS_DISTINCT_COUNT_BODY_DESC',
  AnswersDistinctCountConversationIdAsc = 'ANSWERS_DISTINCT_COUNT_CONVERSATION_ID_ASC',
  AnswersDistinctCountConversationIdDesc = 'ANSWERS_DISTINCT_COUNT_CONVERSATION_ID_DESC',
  AnswersDistinctCountCreatedAtAsc = 'ANSWERS_DISTINCT_COUNT_CREATED_AT_ASC',
  AnswersDistinctCountCreatedAtDesc = 'ANSWERS_DISTINCT_COUNT_CREATED_AT_DESC',
  AnswersDistinctCountHashtagsAsc = 'ANSWERS_DISTINCT_COUNT_HASHTAGS_ASC',
  AnswersDistinctCountHashtagsDesc = 'ANSWERS_DISTINCT_COUNT_HASHTAGS_DESC',
  AnswersDistinctCountIdAsc = 'ANSWERS_DISTINCT_COUNT_ID_ASC',
  AnswersDistinctCountIdDesc = 'ANSWERS_DISTINCT_COUNT_ID_DESC',
  AnswersDistinctCountLanguageAsc = 'ANSWERS_DISTINCT_COUNT_LANGUAGE_ASC',
  AnswersDistinctCountLanguageDesc = 'ANSWERS_DISTINCT_COUNT_LANGUAGE_DESC',
  AnswersDistinctCountMentionedUsernamesAsc = 'ANSWERS_DISTINCT_COUNT_MENTIONED_USERNAMES_ASC',
  AnswersDistinctCountMentionedUsernamesDesc = 'ANSWERS_DISTINCT_COUNT_MENTIONED_USERNAMES_DESC',
  AnswersDistinctCountSenderIdAsc = 'ANSWERS_DISTINCT_COUNT_SENDER_ID_ASC',
  AnswersDistinctCountSenderIdDesc = 'ANSWERS_DISTINCT_COUNT_SENDER_ID_DESC',
  AnswersDistinctCountSubjectAsc = 'ANSWERS_DISTINCT_COUNT_SUBJECT_ASC',
  AnswersDistinctCountSubjectDesc = 'ANSWERS_DISTINCT_COUNT_SUBJECT_DESC',
  AnswersDistinctCountSubmittedAtAsc = 'ANSWERS_DISTINCT_COUNT_SUBMITTED_AT_ASC',
  AnswersDistinctCountSubmittedAtDesc = 'ANSWERS_DISTINCT_COUNT_SUBMITTED_AT_DESC',
  AnswersDistinctCountUpdatedAtAsc = 'ANSWERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  AnswersDistinctCountUpdatedAtDesc = 'ANSWERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  AnswersMaxAboutCommentableIdAsc = 'ANSWERS_MAX_ABOUT_COMMENTABLE_ID_ASC',
  AnswersMaxAboutCommentableIdDesc = 'ANSWERS_MAX_ABOUT_COMMENTABLE_ID_DESC',
  AnswersMaxAnsweredPostingIdAsc = 'ANSWERS_MAX_ANSWERED_POSTING_ID_ASC',
  AnswersMaxAnsweredPostingIdDesc = 'ANSWERS_MAX_ANSWERED_POSTING_ID_DESC',
  AnswersMaxAnsweredPostingWasDeletedAsc = 'ANSWERS_MAX_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersMaxAnsweredPostingWasDeletedDesc = 'ANSWERS_MAX_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersMaxBodyAsc = 'ANSWERS_MAX_BODY_ASC',
  AnswersMaxBodyDesc = 'ANSWERS_MAX_BODY_DESC',
  AnswersMaxConversationIdAsc = 'ANSWERS_MAX_CONVERSATION_ID_ASC',
  AnswersMaxConversationIdDesc = 'ANSWERS_MAX_CONVERSATION_ID_DESC',
  AnswersMaxCreatedAtAsc = 'ANSWERS_MAX_CREATED_AT_ASC',
  AnswersMaxCreatedAtDesc = 'ANSWERS_MAX_CREATED_AT_DESC',
  AnswersMaxHashtagsAsc = 'ANSWERS_MAX_HASHTAGS_ASC',
  AnswersMaxHashtagsDesc = 'ANSWERS_MAX_HASHTAGS_DESC',
  AnswersMaxIdAsc = 'ANSWERS_MAX_ID_ASC',
  AnswersMaxIdDesc = 'ANSWERS_MAX_ID_DESC',
  AnswersMaxLanguageAsc = 'ANSWERS_MAX_LANGUAGE_ASC',
  AnswersMaxLanguageDesc = 'ANSWERS_MAX_LANGUAGE_DESC',
  AnswersMaxMentionedUsernamesAsc = 'ANSWERS_MAX_MENTIONED_USERNAMES_ASC',
  AnswersMaxMentionedUsernamesDesc = 'ANSWERS_MAX_MENTIONED_USERNAMES_DESC',
  AnswersMaxSenderIdAsc = 'ANSWERS_MAX_SENDER_ID_ASC',
  AnswersMaxSenderIdDesc = 'ANSWERS_MAX_SENDER_ID_DESC',
  AnswersMaxSubjectAsc = 'ANSWERS_MAX_SUBJECT_ASC',
  AnswersMaxSubjectDesc = 'ANSWERS_MAX_SUBJECT_DESC',
  AnswersMaxSubmittedAtAsc = 'ANSWERS_MAX_SUBMITTED_AT_ASC',
  AnswersMaxSubmittedAtDesc = 'ANSWERS_MAX_SUBMITTED_AT_DESC',
  AnswersMaxUpdatedAtAsc = 'ANSWERS_MAX_UPDATED_AT_ASC',
  AnswersMaxUpdatedAtDesc = 'ANSWERS_MAX_UPDATED_AT_DESC',
  AnswersMinAboutCommentableIdAsc = 'ANSWERS_MIN_ABOUT_COMMENTABLE_ID_ASC',
  AnswersMinAboutCommentableIdDesc = 'ANSWERS_MIN_ABOUT_COMMENTABLE_ID_DESC',
  AnswersMinAnsweredPostingIdAsc = 'ANSWERS_MIN_ANSWERED_POSTING_ID_ASC',
  AnswersMinAnsweredPostingIdDesc = 'ANSWERS_MIN_ANSWERED_POSTING_ID_DESC',
  AnswersMinAnsweredPostingWasDeletedAsc = 'ANSWERS_MIN_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersMinAnsweredPostingWasDeletedDesc = 'ANSWERS_MIN_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersMinBodyAsc = 'ANSWERS_MIN_BODY_ASC',
  AnswersMinBodyDesc = 'ANSWERS_MIN_BODY_DESC',
  AnswersMinConversationIdAsc = 'ANSWERS_MIN_CONVERSATION_ID_ASC',
  AnswersMinConversationIdDesc = 'ANSWERS_MIN_CONVERSATION_ID_DESC',
  AnswersMinCreatedAtAsc = 'ANSWERS_MIN_CREATED_AT_ASC',
  AnswersMinCreatedAtDesc = 'ANSWERS_MIN_CREATED_AT_DESC',
  AnswersMinHashtagsAsc = 'ANSWERS_MIN_HASHTAGS_ASC',
  AnswersMinHashtagsDesc = 'ANSWERS_MIN_HASHTAGS_DESC',
  AnswersMinIdAsc = 'ANSWERS_MIN_ID_ASC',
  AnswersMinIdDesc = 'ANSWERS_MIN_ID_DESC',
  AnswersMinLanguageAsc = 'ANSWERS_MIN_LANGUAGE_ASC',
  AnswersMinLanguageDesc = 'ANSWERS_MIN_LANGUAGE_DESC',
  AnswersMinMentionedUsernamesAsc = 'ANSWERS_MIN_MENTIONED_USERNAMES_ASC',
  AnswersMinMentionedUsernamesDesc = 'ANSWERS_MIN_MENTIONED_USERNAMES_DESC',
  AnswersMinSenderIdAsc = 'ANSWERS_MIN_SENDER_ID_ASC',
  AnswersMinSenderIdDesc = 'ANSWERS_MIN_SENDER_ID_DESC',
  AnswersMinSubjectAsc = 'ANSWERS_MIN_SUBJECT_ASC',
  AnswersMinSubjectDesc = 'ANSWERS_MIN_SUBJECT_DESC',
  AnswersMinSubmittedAtAsc = 'ANSWERS_MIN_SUBMITTED_AT_ASC',
  AnswersMinSubmittedAtDesc = 'ANSWERS_MIN_SUBMITTED_AT_DESC',
  AnswersMinUpdatedAtAsc = 'ANSWERS_MIN_UPDATED_AT_ASC',
  AnswersMinUpdatedAtDesc = 'ANSWERS_MIN_UPDATED_AT_DESC',
  AnswersStddevPopulationAboutCommentableIdAsc = 'ANSWERS_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  AnswersStddevPopulationAboutCommentableIdDesc = 'ANSWERS_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  AnswersStddevPopulationAnsweredPostingIdAsc = 'ANSWERS_STDDEV_POPULATION_ANSWERED_POSTING_ID_ASC',
  AnswersStddevPopulationAnsweredPostingIdDesc = 'ANSWERS_STDDEV_POPULATION_ANSWERED_POSTING_ID_DESC',
  AnswersStddevPopulationAnsweredPostingWasDeletedAsc = 'ANSWERS_STDDEV_POPULATION_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersStddevPopulationAnsweredPostingWasDeletedDesc = 'ANSWERS_STDDEV_POPULATION_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersStddevPopulationBodyAsc = 'ANSWERS_STDDEV_POPULATION_BODY_ASC',
  AnswersStddevPopulationBodyDesc = 'ANSWERS_STDDEV_POPULATION_BODY_DESC',
  AnswersStddevPopulationConversationIdAsc = 'ANSWERS_STDDEV_POPULATION_CONVERSATION_ID_ASC',
  AnswersStddevPopulationConversationIdDesc = 'ANSWERS_STDDEV_POPULATION_CONVERSATION_ID_DESC',
  AnswersStddevPopulationCreatedAtAsc = 'ANSWERS_STDDEV_POPULATION_CREATED_AT_ASC',
  AnswersStddevPopulationCreatedAtDesc = 'ANSWERS_STDDEV_POPULATION_CREATED_AT_DESC',
  AnswersStddevPopulationHashtagsAsc = 'ANSWERS_STDDEV_POPULATION_HASHTAGS_ASC',
  AnswersStddevPopulationHashtagsDesc = 'ANSWERS_STDDEV_POPULATION_HASHTAGS_DESC',
  AnswersStddevPopulationIdAsc = 'ANSWERS_STDDEV_POPULATION_ID_ASC',
  AnswersStddevPopulationIdDesc = 'ANSWERS_STDDEV_POPULATION_ID_DESC',
  AnswersStddevPopulationLanguageAsc = 'ANSWERS_STDDEV_POPULATION_LANGUAGE_ASC',
  AnswersStddevPopulationLanguageDesc = 'ANSWERS_STDDEV_POPULATION_LANGUAGE_DESC',
  AnswersStddevPopulationMentionedUsernamesAsc = 'ANSWERS_STDDEV_POPULATION_MENTIONED_USERNAMES_ASC',
  AnswersStddevPopulationMentionedUsernamesDesc = 'ANSWERS_STDDEV_POPULATION_MENTIONED_USERNAMES_DESC',
  AnswersStddevPopulationSenderIdAsc = 'ANSWERS_STDDEV_POPULATION_SENDER_ID_ASC',
  AnswersStddevPopulationSenderIdDesc = 'ANSWERS_STDDEV_POPULATION_SENDER_ID_DESC',
  AnswersStddevPopulationSubjectAsc = 'ANSWERS_STDDEV_POPULATION_SUBJECT_ASC',
  AnswersStddevPopulationSubjectDesc = 'ANSWERS_STDDEV_POPULATION_SUBJECT_DESC',
  AnswersStddevPopulationSubmittedAtAsc = 'ANSWERS_STDDEV_POPULATION_SUBMITTED_AT_ASC',
  AnswersStddevPopulationSubmittedAtDesc = 'ANSWERS_STDDEV_POPULATION_SUBMITTED_AT_DESC',
  AnswersStddevPopulationUpdatedAtAsc = 'ANSWERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  AnswersStddevPopulationUpdatedAtDesc = 'ANSWERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  AnswersStddevSampleAboutCommentableIdAsc = 'ANSWERS_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  AnswersStddevSampleAboutCommentableIdDesc = 'ANSWERS_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  AnswersStddevSampleAnsweredPostingIdAsc = 'ANSWERS_STDDEV_SAMPLE_ANSWERED_POSTING_ID_ASC',
  AnswersStddevSampleAnsweredPostingIdDesc = 'ANSWERS_STDDEV_SAMPLE_ANSWERED_POSTING_ID_DESC',
  AnswersStddevSampleAnsweredPostingWasDeletedAsc = 'ANSWERS_STDDEV_SAMPLE_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersStddevSampleAnsweredPostingWasDeletedDesc = 'ANSWERS_STDDEV_SAMPLE_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersStddevSampleBodyAsc = 'ANSWERS_STDDEV_SAMPLE_BODY_ASC',
  AnswersStddevSampleBodyDesc = 'ANSWERS_STDDEV_SAMPLE_BODY_DESC',
  AnswersStddevSampleConversationIdAsc = 'ANSWERS_STDDEV_SAMPLE_CONVERSATION_ID_ASC',
  AnswersStddevSampleConversationIdDesc = 'ANSWERS_STDDEV_SAMPLE_CONVERSATION_ID_DESC',
  AnswersStddevSampleCreatedAtAsc = 'ANSWERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  AnswersStddevSampleCreatedAtDesc = 'ANSWERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  AnswersStddevSampleHashtagsAsc = 'ANSWERS_STDDEV_SAMPLE_HASHTAGS_ASC',
  AnswersStddevSampleHashtagsDesc = 'ANSWERS_STDDEV_SAMPLE_HASHTAGS_DESC',
  AnswersStddevSampleIdAsc = 'ANSWERS_STDDEV_SAMPLE_ID_ASC',
  AnswersStddevSampleIdDesc = 'ANSWERS_STDDEV_SAMPLE_ID_DESC',
  AnswersStddevSampleLanguageAsc = 'ANSWERS_STDDEV_SAMPLE_LANGUAGE_ASC',
  AnswersStddevSampleLanguageDesc = 'ANSWERS_STDDEV_SAMPLE_LANGUAGE_DESC',
  AnswersStddevSampleMentionedUsernamesAsc = 'ANSWERS_STDDEV_SAMPLE_MENTIONED_USERNAMES_ASC',
  AnswersStddevSampleMentionedUsernamesDesc = 'ANSWERS_STDDEV_SAMPLE_MENTIONED_USERNAMES_DESC',
  AnswersStddevSampleSenderIdAsc = 'ANSWERS_STDDEV_SAMPLE_SENDER_ID_ASC',
  AnswersStddevSampleSenderIdDesc = 'ANSWERS_STDDEV_SAMPLE_SENDER_ID_DESC',
  AnswersStddevSampleSubjectAsc = 'ANSWERS_STDDEV_SAMPLE_SUBJECT_ASC',
  AnswersStddevSampleSubjectDesc = 'ANSWERS_STDDEV_SAMPLE_SUBJECT_DESC',
  AnswersStddevSampleSubmittedAtAsc = 'ANSWERS_STDDEV_SAMPLE_SUBMITTED_AT_ASC',
  AnswersStddevSampleSubmittedAtDesc = 'ANSWERS_STDDEV_SAMPLE_SUBMITTED_AT_DESC',
  AnswersStddevSampleUpdatedAtAsc = 'ANSWERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  AnswersStddevSampleUpdatedAtDesc = 'ANSWERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  AnswersSumAboutCommentableIdAsc = 'ANSWERS_SUM_ABOUT_COMMENTABLE_ID_ASC',
  AnswersSumAboutCommentableIdDesc = 'ANSWERS_SUM_ABOUT_COMMENTABLE_ID_DESC',
  AnswersSumAnsweredPostingIdAsc = 'ANSWERS_SUM_ANSWERED_POSTING_ID_ASC',
  AnswersSumAnsweredPostingIdDesc = 'ANSWERS_SUM_ANSWERED_POSTING_ID_DESC',
  AnswersSumAnsweredPostingWasDeletedAsc = 'ANSWERS_SUM_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersSumAnsweredPostingWasDeletedDesc = 'ANSWERS_SUM_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersSumBodyAsc = 'ANSWERS_SUM_BODY_ASC',
  AnswersSumBodyDesc = 'ANSWERS_SUM_BODY_DESC',
  AnswersSumConversationIdAsc = 'ANSWERS_SUM_CONVERSATION_ID_ASC',
  AnswersSumConversationIdDesc = 'ANSWERS_SUM_CONVERSATION_ID_DESC',
  AnswersSumCreatedAtAsc = 'ANSWERS_SUM_CREATED_AT_ASC',
  AnswersSumCreatedAtDesc = 'ANSWERS_SUM_CREATED_AT_DESC',
  AnswersSumHashtagsAsc = 'ANSWERS_SUM_HASHTAGS_ASC',
  AnswersSumHashtagsDesc = 'ANSWERS_SUM_HASHTAGS_DESC',
  AnswersSumIdAsc = 'ANSWERS_SUM_ID_ASC',
  AnswersSumIdDesc = 'ANSWERS_SUM_ID_DESC',
  AnswersSumLanguageAsc = 'ANSWERS_SUM_LANGUAGE_ASC',
  AnswersSumLanguageDesc = 'ANSWERS_SUM_LANGUAGE_DESC',
  AnswersSumMentionedUsernamesAsc = 'ANSWERS_SUM_MENTIONED_USERNAMES_ASC',
  AnswersSumMentionedUsernamesDesc = 'ANSWERS_SUM_MENTIONED_USERNAMES_DESC',
  AnswersSumSenderIdAsc = 'ANSWERS_SUM_SENDER_ID_ASC',
  AnswersSumSenderIdDesc = 'ANSWERS_SUM_SENDER_ID_DESC',
  AnswersSumSubjectAsc = 'ANSWERS_SUM_SUBJECT_ASC',
  AnswersSumSubjectDesc = 'ANSWERS_SUM_SUBJECT_DESC',
  AnswersSumSubmittedAtAsc = 'ANSWERS_SUM_SUBMITTED_AT_ASC',
  AnswersSumSubmittedAtDesc = 'ANSWERS_SUM_SUBMITTED_AT_DESC',
  AnswersSumUpdatedAtAsc = 'ANSWERS_SUM_UPDATED_AT_ASC',
  AnswersSumUpdatedAtDesc = 'ANSWERS_SUM_UPDATED_AT_DESC',
  AnswersVariancePopulationAboutCommentableIdAsc = 'ANSWERS_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  AnswersVariancePopulationAboutCommentableIdDesc = 'ANSWERS_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  AnswersVariancePopulationAnsweredPostingIdAsc = 'ANSWERS_VARIANCE_POPULATION_ANSWERED_POSTING_ID_ASC',
  AnswersVariancePopulationAnsweredPostingIdDesc = 'ANSWERS_VARIANCE_POPULATION_ANSWERED_POSTING_ID_DESC',
  AnswersVariancePopulationAnsweredPostingWasDeletedAsc = 'ANSWERS_VARIANCE_POPULATION_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersVariancePopulationAnsweredPostingWasDeletedDesc = 'ANSWERS_VARIANCE_POPULATION_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersVariancePopulationBodyAsc = 'ANSWERS_VARIANCE_POPULATION_BODY_ASC',
  AnswersVariancePopulationBodyDesc = 'ANSWERS_VARIANCE_POPULATION_BODY_DESC',
  AnswersVariancePopulationConversationIdAsc = 'ANSWERS_VARIANCE_POPULATION_CONVERSATION_ID_ASC',
  AnswersVariancePopulationConversationIdDesc = 'ANSWERS_VARIANCE_POPULATION_CONVERSATION_ID_DESC',
  AnswersVariancePopulationCreatedAtAsc = 'ANSWERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  AnswersVariancePopulationCreatedAtDesc = 'ANSWERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  AnswersVariancePopulationHashtagsAsc = 'ANSWERS_VARIANCE_POPULATION_HASHTAGS_ASC',
  AnswersVariancePopulationHashtagsDesc = 'ANSWERS_VARIANCE_POPULATION_HASHTAGS_DESC',
  AnswersVariancePopulationIdAsc = 'ANSWERS_VARIANCE_POPULATION_ID_ASC',
  AnswersVariancePopulationIdDesc = 'ANSWERS_VARIANCE_POPULATION_ID_DESC',
  AnswersVariancePopulationLanguageAsc = 'ANSWERS_VARIANCE_POPULATION_LANGUAGE_ASC',
  AnswersVariancePopulationLanguageDesc = 'ANSWERS_VARIANCE_POPULATION_LANGUAGE_DESC',
  AnswersVariancePopulationMentionedUsernamesAsc = 'ANSWERS_VARIANCE_POPULATION_MENTIONED_USERNAMES_ASC',
  AnswersVariancePopulationMentionedUsernamesDesc = 'ANSWERS_VARIANCE_POPULATION_MENTIONED_USERNAMES_DESC',
  AnswersVariancePopulationSenderIdAsc = 'ANSWERS_VARIANCE_POPULATION_SENDER_ID_ASC',
  AnswersVariancePopulationSenderIdDesc = 'ANSWERS_VARIANCE_POPULATION_SENDER_ID_DESC',
  AnswersVariancePopulationSubjectAsc = 'ANSWERS_VARIANCE_POPULATION_SUBJECT_ASC',
  AnswersVariancePopulationSubjectDesc = 'ANSWERS_VARIANCE_POPULATION_SUBJECT_DESC',
  AnswersVariancePopulationSubmittedAtAsc = 'ANSWERS_VARIANCE_POPULATION_SUBMITTED_AT_ASC',
  AnswersVariancePopulationSubmittedAtDesc = 'ANSWERS_VARIANCE_POPULATION_SUBMITTED_AT_DESC',
  AnswersVariancePopulationUpdatedAtAsc = 'ANSWERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  AnswersVariancePopulationUpdatedAtDesc = 'ANSWERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  AnswersVarianceSampleAboutCommentableIdAsc = 'ANSWERS_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  AnswersVarianceSampleAboutCommentableIdDesc = 'ANSWERS_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  AnswersVarianceSampleAnsweredPostingIdAsc = 'ANSWERS_VARIANCE_SAMPLE_ANSWERED_POSTING_ID_ASC',
  AnswersVarianceSampleAnsweredPostingIdDesc = 'ANSWERS_VARIANCE_SAMPLE_ANSWERED_POSTING_ID_DESC',
  AnswersVarianceSampleAnsweredPostingWasDeletedAsc = 'ANSWERS_VARIANCE_SAMPLE_ANSWERED_POSTING_WAS_DELETED_ASC',
  AnswersVarianceSampleAnsweredPostingWasDeletedDesc = 'ANSWERS_VARIANCE_SAMPLE_ANSWERED_POSTING_WAS_DELETED_DESC',
  AnswersVarianceSampleBodyAsc = 'ANSWERS_VARIANCE_SAMPLE_BODY_ASC',
  AnswersVarianceSampleBodyDesc = 'ANSWERS_VARIANCE_SAMPLE_BODY_DESC',
  AnswersVarianceSampleConversationIdAsc = 'ANSWERS_VARIANCE_SAMPLE_CONVERSATION_ID_ASC',
  AnswersVarianceSampleConversationIdDesc = 'ANSWERS_VARIANCE_SAMPLE_CONVERSATION_ID_DESC',
  AnswersVarianceSampleCreatedAtAsc = 'ANSWERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  AnswersVarianceSampleCreatedAtDesc = 'ANSWERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  AnswersVarianceSampleHashtagsAsc = 'ANSWERS_VARIANCE_SAMPLE_HASHTAGS_ASC',
  AnswersVarianceSampleHashtagsDesc = 'ANSWERS_VARIANCE_SAMPLE_HASHTAGS_DESC',
  AnswersVarianceSampleIdAsc = 'ANSWERS_VARIANCE_SAMPLE_ID_ASC',
  AnswersVarianceSampleIdDesc = 'ANSWERS_VARIANCE_SAMPLE_ID_DESC',
  AnswersVarianceSampleLanguageAsc = 'ANSWERS_VARIANCE_SAMPLE_LANGUAGE_ASC',
  AnswersVarianceSampleLanguageDesc = 'ANSWERS_VARIANCE_SAMPLE_LANGUAGE_DESC',
  AnswersVarianceSampleMentionedUsernamesAsc = 'ANSWERS_VARIANCE_SAMPLE_MENTIONED_USERNAMES_ASC',
  AnswersVarianceSampleMentionedUsernamesDesc = 'ANSWERS_VARIANCE_SAMPLE_MENTIONED_USERNAMES_DESC',
  AnswersVarianceSampleSenderIdAsc = 'ANSWERS_VARIANCE_SAMPLE_SENDER_ID_ASC',
  AnswersVarianceSampleSenderIdDesc = 'ANSWERS_VARIANCE_SAMPLE_SENDER_ID_DESC',
  AnswersVarianceSampleSubjectAsc = 'ANSWERS_VARIANCE_SAMPLE_SUBJECT_ASC',
  AnswersVarianceSampleSubjectDesc = 'ANSWERS_VARIANCE_SAMPLE_SUBJECT_DESC',
  AnswersVarianceSampleSubmittedAtAsc = 'ANSWERS_VARIANCE_SAMPLE_SUBMITTED_AT_ASC',
  AnswersVarianceSampleSubmittedAtDesc = 'ANSWERS_VARIANCE_SAMPLE_SUBMITTED_AT_DESC',
  AnswersVarianceSampleUpdatedAtAsc = 'ANSWERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  AnswersVarianceSampleUpdatedAtDesc = 'ANSWERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  BodyAsc = 'BODY_ASC',
  BodyDesc = 'BODY_DESC',
  ConversationIdAsc = 'CONVERSATION_ID_ASC',
  ConversationIdDesc = 'CONVERSATION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  HashtagsAsc = 'HASHTAGS_ASC',
  HashtagsDesc = 'HASHTAGS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LanguageAsc = 'LANGUAGE_ASC',
  LanguageDesc = 'LANGUAGE_DESC',
  MentionedUsernamesAsc = 'MENTIONED_USERNAMES_ASC',
  MentionedUsernamesDesc = 'MENTIONED_USERNAMES_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ReceiptsAverageManuallyConfirmedAtAsc = 'RECEIPTS_AVERAGE_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsAverageManuallyConfirmedAtDesc = 'RECEIPTS_AVERAGE_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsAveragePostingIdAsc = 'RECEIPTS_AVERAGE_POSTING_ID_ASC',
  ReceiptsAveragePostingIdDesc = 'RECEIPTS_AVERAGE_POSTING_ID_DESC',
  ReceiptsAverageReceivedAtAsc = 'RECEIPTS_AVERAGE_RECEIVED_AT_ASC',
  ReceiptsAverageReceivedAtDesc = 'RECEIPTS_AVERAGE_RECEIVED_AT_DESC',
  ReceiptsAverageUserIdAsc = 'RECEIPTS_AVERAGE_USER_ID_ASC',
  ReceiptsAverageUserIdDesc = 'RECEIPTS_AVERAGE_USER_ID_DESC',
  ReceiptsAverageViewedAtAsc = 'RECEIPTS_AVERAGE_VIEWED_AT_ASC',
  ReceiptsAverageViewedAtDesc = 'RECEIPTS_AVERAGE_VIEWED_AT_DESC',
  ReceiptsCountAsc = 'RECEIPTS_COUNT_ASC',
  ReceiptsCountDesc = 'RECEIPTS_COUNT_DESC',
  ReceiptsDistinctCountManuallyConfirmedAtAsc = 'RECEIPTS_DISTINCT_COUNT_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsDistinctCountManuallyConfirmedAtDesc = 'RECEIPTS_DISTINCT_COUNT_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsDistinctCountPostingIdAsc = 'RECEIPTS_DISTINCT_COUNT_POSTING_ID_ASC',
  ReceiptsDistinctCountPostingIdDesc = 'RECEIPTS_DISTINCT_COUNT_POSTING_ID_DESC',
  ReceiptsDistinctCountReceivedAtAsc = 'RECEIPTS_DISTINCT_COUNT_RECEIVED_AT_ASC',
  ReceiptsDistinctCountReceivedAtDesc = 'RECEIPTS_DISTINCT_COUNT_RECEIVED_AT_DESC',
  ReceiptsDistinctCountUserIdAsc = 'RECEIPTS_DISTINCT_COUNT_USER_ID_ASC',
  ReceiptsDistinctCountUserIdDesc = 'RECEIPTS_DISTINCT_COUNT_USER_ID_DESC',
  ReceiptsDistinctCountViewedAtAsc = 'RECEIPTS_DISTINCT_COUNT_VIEWED_AT_ASC',
  ReceiptsDistinctCountViewedAtDesc = 'RECEIPTS_DISTINCT_COUNT_VIEWED_AT_DESC',
  ReceiptsMaxManuallyConfirmedAtAsc = 'RECEIPTS_MAX_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsMaxManuallyConfirmedAtDesc = 'RECEIPTS_MAX_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsMaxPostingIdAsc = 'RECEIPTS_MAX_POSTING_ID_ASC',
  ReceiptsMaxPostingIdDesc = 'RECEIPTS_MAX_POSTING_ID_DESC',
  ReceiptsMaxReceivedAtAsc = 'RECEIPTS_MAX_RECEIVED_AT_ASC',
  ReceiptsMaxReceivedAtDesc = 'RECEIPTS_MAX_RECEIVED_AT_DESC',
  ReceiptsMaxUserIdAsc = 'RECEIPTS_MAX_USER_ID_ASC',
  ReceiptsMaxUserIdDesc = 'RECEIPTS_MAX_USER_ID_DESC',
  ReceiptsMaxViewedAtAsc = 'RECEIPTS_MAX_VIEWED_AT_ASC',
  ReceiptsMaxViewedAtDesc = 'RECEIPTS_MAX_VIEWED_AT_DESC',
  ReceiptsMinManuallyConfirmedAtAsc = 'RECEIPTS_MIN_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsMinManuallyConfirmedAtDesc = 'RECEIPTS_MIN_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsMinPostingIdAsc = 'RECEIPTS_MIN_POSTING_ID_ASC',
  ReceiptsMinPostingIdDesc = 'RECEIPTS_MIN_POSTING_ID_DESC',
  ReceiptsMinReceivedAtAsc = 'RECEIPTS_MIN_RECEIVED_AT_ASC',
  ReceiptsMinReceivedAtDesc = 'RECEIPTS_MIN_RECEIVED_AT_DESC',
  ReceiptsMinUserIdAsc = 'RECEIPTS_MIN_USER_ID_ASC',
  ReceiptsMinUserIdDesc = 'RECEIPTS_MIN_USER_ID_DESC',
  ReceiptsMinViewedAtAsc = 'RECEIPTS_MIN_VIEWED_AT_ASC',
  ReceiptsMinViewedAtDesc = 'RECEIPTS_MIN_VIEWED_AT_DESC',
  ReceiptsStddevPopulationManuallyConfirmedAtAsc = 'RECEIPTS_STDDEV_POPULATION_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsStddevPopulationManuallyConfirmedAtDesc = 'RECEIPTS_STDDEV_POPULATION_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsStddevPopulationPostingIdAsc = 'RECEIPTS_STDDEV_POPULATION_POSTING_ID_ASC',
  ReceiptsStddevPopulationPostingIdDesc = 'RECEIPTS_STDDEV_POPULATION_POSTING_ID_DESC',
  ReceiptsStddevPopulationReceivedAtAsc = 'RECEIPTS_STDDEV_POPULATION_RECEIVED_AT_ASC',
  ReceiptsStddevPopulationReceivedAtDesc = 'RECEIPTS_STDDEV_POPULATION_RECEIVED_AT_DESC',
  ReceiptsStddevPopulationUserIdAsc = 'RECEIPTS_STDDEV_POPULATION_USER_ID_ASC',
  ReceiptsStddevPopulationUserIdDesc = 'RECEIPTS_STDDEV_POPULATION_USER_ID_DESC',
  ReceiptsStddevPopulationViewedAtAsc = 'RECEIPTS_STDDEV_POPULATION_VIEWED_AT_ASC',
  ReceiptsStddevPopulationViewedAtDesc = 'RECEIPTS_STDDEV_POPULATION_VIEWED_AT_DESC',
  ReceiptsStddevSampleManuallyConfirmedAtAsc = 'RECEIPTS_STDDEV_SAMPLE_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsStddevSampleManuallyConfirmedAtDesc = 'RECEIPTS_STDDEV_SAMPLE_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsStddevSamplePostingIdAsc = 'RECEIPTS_STDDEV_SAMPLE_POSTING_ID_ASC',
  ReceiptsStddevSamplePostingIdDesc = 'RECEIPTS_STDDEV_SAMPLE_POSTING_ID_DESC',
  ReceiptsStddevSampleReceivedAtAsc = 'RECEIPTS_STDDEV_SAMPLE_RECEIVED_AT_ASC',
  ReceiptsStddevSampleReceivedAtDesc = 'RECEIPTS_STDDEV_SAMPLE_RECEIVED_AT_DESC',
  ReceiptsStddevSampleUserIdAsc = 'RECEIPTS_STDDEV_SAMPLE_USER_ID_ASC',
  ReceiptsStddevSampleUserIdDesc = 'RECEIPTS_STDDEV_SAMPLE_USER_ID_DESC',
  ReceiptsStddevSampleViewedAtAsc = 'RECEIPTS_STDDEV_SAMPLE_VIEWED_AT_ASC',
  ReceiptsStddevSampleViewedAtDesc = 'RECEIPTS_STDDEV_SAMPLE_VIEWED_AT_DESC',
  ReceiptsSumManuallyConfirmedAtAsc = 'RECEIPTS_SUM_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsSumManuallyConfirmedAtDesc = 'RECEIPTS_SUM_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsSumPostingIdAsc = 'RECEIPTS_SUM_POSTING_ID_ASC',
  ReceiptsSumPostingIdDesc = 'RECEIPTS_SUM_POSTING_ID_DESC',
  ReceiptsSumReceivedAtAsc = 'RECEIPTS_SUM_RECEIVED_AT_ASC',
  ReceiptsSumReceivedAtDesc = 'RECEIPTS_SUM_RECEIVED_AT_DESC',
  ReceiptsSumUserIdAsc = 'RECEIPTS_SUM_USER_ID_ASC',
  ReceiptsSumUserIdDesc = 'RECEIPTS_SUM_USER_ID_DESC',
  ReceiptsSumViewedAtAsc = 'RECEIPTS_SUM_VIEWED_AT_ASC',
  ReceiptsSumViewedAtDesc = 'RECEIPTS_SUM_VIEWED_AT_DESC',
  ReceiptsVariancePopulationManuallyConfirmedAtAsc = 'RECEIPTS_VARIANCE_POPULATION_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsVariancePopulationManuallyConfirmedAtDesc = 'RECEIPTS_VARIANCE_POPULATION_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsVariancePopulationPostingIdAsc = 'RECEIPTS_VARIANCE_POPULATION_POSTING_ID_ASC',
  ReceiptsVariancePopulationPostingIdDesc = 'RECEIPTS_VARIANCE_POPULATION_POSTING_ID_DESC',
  ReceiptsVariancePopulationReceivedAtAsc = 'RECEIPTS_VARIANCE_POPULATION_RECEIVED_AT_ASC',
  ReceiptsVariancePopulationReceivedAtDesc = 'RECEIPTS_VARIANCE_POPULATION_RECEIVED_AT_DESC',
  ReceiptsVariancePopulationUserIdAsc = 'RECEIPTS_VARIANCE_POPULATION_USER_ID_ASC',
  ReceiptsVariancePopulationUserIdDesc = 'RECEIPTS_VARIANCE_POPULATION_USER_ID_DESC',
  ReceiptsVariancePopulationViewedAtAsc = 'RECEIPTS_VARIANCE_POPULATION_VIEWED_AT_ASC',
  ReceiptsVariancePopulationViewedAtDesc = 'RECEIPTS_VARIANCE_POPULATION_VIEWED_AT_DESC',
  ReceiptsVarianceSampleManuallyConfirmedAtAsc = 'RECEIPTS_VARIANCE_SAMPLE_MANUALLY_CONFIRMED_AT_ASC',
  ReceiptsVarianceSampleManuallyConfirmedAtDesc = 'RECEIPTS_VARIANCE_SAMPLE_MANUALLY_CONFIRMED_AT_DESC',
  ReceiptsVarianceSamplePostingIdAsc = 'RECEIPTS_VARIANCE_SAMPLE_POSTING_ID_ASC',
  ReceiptsVarianceSamplePostingIdDesc = 'RECEIPTS_VARIANCE_SAMPLE_POSTING_ID_DESC',
  ReceiptsVarianceSampleReceivedAtAsc = 'RECEIPTS_VARIANCE_SAMPLE_RECEIVED_AT_ASC',
  ReceiptsVarianceSampleReceivedAtDesc = 'RECEIPTS_VARIANCE_SAMPLE_RECEIVED_AT_DESC',
  ReceiptsVarianceSampleUserIdAsc = 'RECEIPTS_VARIANCE_SAMPLE_USER_ID_ASC',
  ReceiptsVarianceSampleUserIdDesc = 'RECEIPTS_VARIANCE_SAMPLE_USER_ID_DESC',
  ReceiptsVarianceSampleViewedAtAsc = 'RECEIPTS_VARIANCE_SAMPLE_VIEWED_AT_ASC',
  ReceiptsVarianceSampleViewedAtDesc = 'RECEIPTS_VARIANCE_SAMPLE_VIEWED_AT_DESC',
  SenderIdAsc = 'SENDER_ID_ASC',
  SenderIdDesc = 'SENDER_ID_DESC',
  SubjectAsc = 'SUBJECT_ASC',
  SubjectDesc = 'SUBJECT_DESC',
  SubmittedAtAsc = 'SUBMITTED_AT_ASC',
  SubmittedAtDesc = 'SUBMITTED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** All input for the `pullEmaEntries2` mutation. */
export type PullEmaEntries2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  lastId?: InputMaybe<Scalars['UUID']>;
  minUpdatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The output of our `pullEmaEntries2` mutation. */
export type PullEmaEntries2Payload = {
  __typename?: 'PullEmaEntries2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  emaEntries2S?: Maybe<Array<EmaEntries2>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `pushEmaEntry` mutation. */
export type PushEmaEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  input: EmaEntryPatch;
};

/** The output of our `pushEmaEntry` mutation. */
export type PushEmaEntryPayload = {
  __typename?: 'PushEmaEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An edge for our `EmaEntry`. May be used by Relay 1. */
  emaEntryEdge?: Maybe<EmaEntriesEdge>;
  newEntry?: Maybe<EmaEntry>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntry`. */
  subject?: Maybe<User>;
};


/** The output of our `pushEmaEntry` mutation. */
export type PushEmaEntryPayloadEmaEntryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  commentable?: Maybe<Commentable>;
  /** Reads a single `Commentable` using its globally unique `ID`. */
  commentableByNodeId?: Maybe<Commentable>;
  /** Reads and enables pagination through a set of `Commentable`. */
  commentables?: Maybe<CommentablesConnection>;
  conversation?: Maybe<Conversation>;
  /** Reads a single `Conversation` using its globally unique `ID`. */
  conversationByNodeId?: Maybe<Conversation>;
  /** Reads and enables pagination through a set of `Conversation`. */
  conversations?: Maybe<ConversationsConnection>;
  /** Reads and enables pagination through a set of `EmaEntry`. */
  emaEntries?: Maybe<EmaEntriesConnection>;
  emaEntries2?: Maybe<EmaEntries2>;
  /** Reads a single `EmaEntries2` using its globally unique `ID`. */
  emaEntries2ByNodeId?: Maybe<EmaEntries2>;
  /** Reads and enables pagination through a set of `EmaEntries2`. */
  emaEntries2s?: Maybe<EmaEntries2sConnection>;
  emaEntries3?: Maybe<EmaEntries3>;
  /** Reads a single `EmaEntries3` using its globally unique `ID`. */
  emaEntries3ByNodeId?: Maybe<EmaEntries3>;
  /** Reads and enables pagination through a set of `EmaEntries3`. */
  emaEntries3s?: Maybe<EmaEntries3sConnection>;
  emaEntry?: Maybe<EmaEntry>;
  /** Reads a single `EmaEntry` using its globally unique `ID`. */
  emaEntryByNodeId?: Maybe<EmaEntry>;
  event?: Maybe<Event>;
  /** Reads a single `Event` using its globally unique `ID`. */
  eventByNodeId?: Maybe<Event>;
  favoritePage?: Maybe<FavoritePage>;
  /** Reads a single `FavoritePage` using its globally unique `ID`. */
  favoritePageByNodeId?: Maybe<FavoritePage>;
  /** Reads and enables pagination through a set of `FavoritePage`. */
  favoritePages?: Maybe<FavoritePagesConnection>;
  genericEmiExercise?: Maybe<GenericEmiExercise>;
  /** Reads a single `GenericEmiExercise` using its globally unique `ID`. */
  genericEmiExerciseByNodeId?: Maybe<GenericEmiExercise>;
  genericEmiExerciseByTrialIdAndName?: Maybe<GenericEmiExercise>;
  /** Reads and enables pagination through a set of `GenericEmiExercise`. */
  genericEmiExercises?: Maybe<GenericEmiExercisesConnection>;
  group?: Maybe<Group>;
  /** Reads a single `Group` using its globally unique `ID`. */
  groupByNodeId?: Maybe<Group>;
  groupHome?: Maybe<GroupHome>;
  /** Reads a single `GroupHome` using its globally unique `ID`. */
  groupHomeByNodeId?: Maybe<GroupHome>;
  /** Reads and enables pagination through a set of `GroupHome`. */
  groupHomes?: Maybe<GroupHomesConnection>;
  /** Reads and enables pagination through a set of `Group`. */
  groups?: Maybe<GroupsConnection>;
  keycloakClientRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  keycloakRealmRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  limesurveyToken?: Maybe<LimesurveyToken>;
  /** Reads a single `LimesurveyToken` using its globally unique `ID`. */
  limesurveyTokenByNodeId?: Maybe<LimesurveyToken>;
  limesurveyTokenByTrialIdAndTimeOfMeasurement?: Maybe<LimesurveyToken>;
  /** Reads and enables pagination through a set of `LimesurveyToken`. */
  limesurveyTokens?: Maybe<LimesurveyTokensConnection>;
  membership?: Maybe<Membership>;
  membershipByGroupIdAndSubgroupId?: Maybe<Membership>;
  membershipByGroupIdAndUserId?: Maybe<Membership>;
  /** Reads a single `Membership` using its globally unique `ID`. */
  membershipByNodeId?: Maybe<Membership>;
  membershipInvitation?: Maybe<MembershipInvitation>;
  /** Reads a single `MembershipInvitation` using its globally unique `ID`. */
  membershipInvitationByNodeId?: Maybe<MembershipInvitation>;
  membershipInvitationConfirmation?: Maybe<MembershipInvitationConfirmation>;
  /** Reads a single `MembershipInvitationConfirmation` using its globally unique `ID`. */
  membershipInvitationConfirmationByNodeId?: Maybe<MembershipInvitationConfirmation>;
  /** Reads and enables pagination through a set of `MembershipInvitationConfirmation`. */
  membershipInvitationConfirmations?: Maybe<MembershipInvitationConfirmationsConnection>;
  /** Reads and enables pagination through a set of `MembershipInvitation`. */
  membershipInvitations?: Maybe<MembershipInvitationsConnection>;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships?: Maybe<MembershipsConnection>;
  myAccount?: Maybe<User>;
  myAccountId?: Maybe<Scalars['UUID']>;
  myCurrentTrial?: Maybe<Trial>;
  myCurrentTrialId?: Maybe<Scalars['UUID']>;
  myIssuedMembershipInvitations?: Maybe<MembershipInvitation>;
  /** Reads and enables pagination through a set of `Membership`. */
  myMemberships?: Maybe<MembershipsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  myPeers?: Maybe<UsersConnection>;
  /** Reads and enables pagination through a set of `Membership`. */
  myPeersMemberships?: Maybe<MembershipsConnection>;
  /** Reads and enables pagination through a set of `Conversation`. */
  myPrivateConversations?: Maybe<ConversationsConnection>;
  /** Reads and enables pagination through a set of `MembershipInvitation`. */
  myReceivedMembershipInvitations?: Maybe<MembershipInvitationsConnection>;
  /** @deprecated true */
  myReceivedMembershipInvitationsDeprecated?: Maybe<MembershipInvitation>;
  myTenant?: Maybe<Tenant>;
  myTenantId?: Maybe<Scalars['UUID']>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PageHit`. */
  pageHits?: Maybe<PageHitsConnection>;
  postWaitRandomizationTicket?: Maybe<PostWaitRandomizationTicket>;
  /** Reads a single `PostWaitRandomizationTicket` using its globally unique `ID`. */
  postWaitRandomizationTicketByNodeId?: Maybe<PostWaitRandomizationTicket>;
  postWaitRandomizationTicketByTrialId?: Maybe<PostWaitRandomizationTicket>;
  /** Reads and enables pagination through a set of `PostWaitRandomizationTicket`. */
  postWaitRandomizationTickets?: Maybe<PostWaitRandomizationTicketsConnection>;
  posting?: Maybe<Posting>;
  /** Reads a single `Posting` using its globally unique `ID`. */
  postingByNodeId?: Maybe<Posting>;
  postingReceipt?: Maybe<PostingReceipt>;
  /** Reads a single `PostingReceipt` using its globally unique `ID`. */
  postingReceiptByNodeId?: Maybe<PostingReceipt>;
  /** Reads and enables pagination through a set of `PostingReceipt`. */
  postingReceipts?: Maybe<PostingReceiptsConnection>;
  /** Reads and enables pagination through a set of `Posting`. */
  postings?: Maybe<PostingsConnection>;
  /** Reads and enables pagination through a set of `EmaEntry`. */
  pullEmaEntriesList?: Maybe<Array<EmaEntry>>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  randomizationTicket?: Maybe<RandomizationTicket>;
  /** Reads a single `RandomizationTicket` using its globally unique `ID`. */
  randomizationTicketByNodeId?: Maybe<RandomizationTicket>;
  randomizationTicketByTrialId?: Maybe<RandomizationTicket>;
  /** Reads and enables pagination through a set of `RandomizationTicket`. */
  randomizationTickets?: Maybe<RandomizationTicketsConnection>;
  /** Reads and enables pagination through a set of `RawUsageDatum`. */
  rawUsageData?: Maybe<RawUsageDataConnection>;
  rawUsageDatum?: Maybe<RawUsageDatum>;
  /** Reads a single `RawUsageDatum` using its globally unique `ID`. */
  rawUsageDatumByNodeId?: Maybe<RawUsageDatum>;
  tenant?: Maybe<Tenant>;
  tenantByName?: Maybe<Tenant>;
  /** Reads a single `Tenant` using its globally unique `ID`. */
  tenantByNodeId?: Maybe<Tenant>;
  tenantByUrl?: Maybe<Tenant>;
  /** Reads and enables pagination through a set of `Tenant`. */
  tenants?: Maybe<TenantsConnection>;
  trial?: Maybe<Trial>;
  trialByHumanReadableId?: Maybe<Trial>;
  trialByName?: Maybe<Trial>;
  /** Reads a single `Trial` using its globally unique `ID`. */
  trialByNodeId?: Maybe<Trial>;
  /** Reads and enables pagination through a set of `Trial`. */
  trials?: Maybe<TrialsConnection>;
  user?: Maybe<User>;
  userByCredentialsId?: Maybe<User>;
  userByEmailAddressId?: Maybe<User>;
  userByIdAndTenantId?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId?: Maybe<User>;
  userByUsernameAndTenantId?: Maybe<User>;
  userCredential?: Maybe<UserCredential>;
  userCredentialByExternalAuthIdAndTenantId?: Maybe<UserCredential>;
  /** Reads a single `UserCredential` using its globally unique `ID`. */
  userCredentialByNodeId?: Maybe<UserCredential>;
  /** Reads and enables pagination through a set of `UserCredential`. */
  userCredentials?: Maybe<UserCredentialsConnection>;
  userEmailAddress?: Maybe<UserEmailAddress>;
  userEmailAddressByEmailAddressAndTenantId?: Maybe<UserEmailAddress>;
  /** Reads a single `UserEmailAddress` using its globally unique `ID`. */
  userEmailAddressByNodeId?: Maybe<UserEmailAddress>;
  /** Reads and enables pagination through a set of `UserEmailAddress`. */
  userEmailAddresses?: Maybe<UserEmailAddressesConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCommentableArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCommentableByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCommentablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CommentableCondition>;
  filter?: InputMaybe<CommentableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CommentablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryConversationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConversationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConversationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConversationCondition>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmaEntryCondition>;
  filter?: InputMaybe<EmaEntryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmaEntriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntries2Args = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntries2ByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntries2sArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmaEntries2Condition>;
  filter?: InputMaybe<EmaEntries2Filter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmaEntries2sOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntries3Args = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntries3ByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntries3sArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmaEntries3Condition>;
  filter?: InputMaybe<EmaEntries3Filter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmaEntries3sOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntryArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEmaEntryByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoritePageArgs = {
  route: Scalars['String'];
  trialId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoritePageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFavoritePagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FavoritePageCondition>;
  filter?: InputMaybe<FavoritePageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoritePagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGenericEmiExerciseArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGenericEmiExerciseByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGenericEmiExerciseByTrialIdAndNameArgs = {
  name: Scalars['String'];
  trialId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGenericEmiExercisesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenericEmiExerciseCondition>;
  filter?: InputMaybe<GenericEmiExerciseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenericEmiExercisesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupHomeArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupHomeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupHomesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GroupHomeCondition>;
  filter?: InputMaybe<GroupHomeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupHomesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GroupCondition>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryKeycloakClientRolesArgs = {
  client?: InputMaybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLimesurveyTokenArgs = {
  surveyId: Scalars['Int'];
  token: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLimesurveyTokenByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLimesurveyTokenByTrialIdAndTimeOfMeasurementArgs = {
  timeOfMeasurement: Scalars['String'];
  trialId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLimesurveyTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LimesurveyTokenCondition>;
  filter?: InputMaybe<LimesurveyTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LimesurveyTokensOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipByGroupIdAndSubgroupIdArgs = {
  groupId: Scalars['UUID'];
  subgroupId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipByGroupIdAndUserIdArgs = {
  groupId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipInvitationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipInvitationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipInvitationConfirmationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipInvitationConfirmationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipInvitationConfirmationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationConfirmationCondition>;
  filter?: InputMaybe<MembershipInvitationConfirmationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationConfirmationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipInvitationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationCondition>;
  filter?: InputMaybe<MembershipInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyAccountArgs = {
  failIfNotFound?: InputMaybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyAccountIdArgs = {
  failIfNotFound?: InputMaybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeSupergroups?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  thatICanManage?: InputMaybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyPeersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyPeersMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeForeignGroups?: InputMaybe<Scalars['Boolean']>;
  includeSupergroups?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyPrivateConversationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConversationCondition>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeSupergroups?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyReceivedMembershipInvitationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationCondition>;
  filter?: InputMaybe<MembershipInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeGroupInvitations?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyReceivedMembershipInvitationsDeprecatedArgs = {
  includeGroupsThatICanManage?: InputMaybe<Scalars['Boolean']>;
  includeSupergroups?: InputMaybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyTenantArgs = {
  failIfNotFound?: InputMaybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMyTenantIdArgs = {
  failIfNotFound?: InputMaybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPageHitsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PageHitCondition>;
  filter?: InputMaybe<PageHitFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PageHitsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPostWaitRandomizationTicketArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostWaitRandomizationTicketByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostWaitRandomizationTicketByTrialIdArgs = {
  trialId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostWaitRandomizationTicketsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostWaitRandomizationTicketCondition>;
  filter?: InputMaybe<PostWaitRandomizationTicketFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostWaitRandomizationTicketsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPostingArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostingReceiptArgs = {
  postingId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostingReceiptByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPostingReceiptsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingReceiptCondition>;
  filter?: InputMaybe<PostingReceiptFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPostingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingCondition>;
  filter?: InputMaybe<PostingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPullEmaEntriesListArgs = {
  filter?: InputMaybe<EmaEntryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  lastId?: InputMaybe<Scalars['UUID']>;
  minUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRandomizationTicketArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRandomizationTicketByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRandomizationTicketByTrialIdArgs = {
  trialId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRandomizationTicketsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RandomizationTicketCondition>;
  filter?: InputMaybe<RandomizationTicketFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RandomizationTicketsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRawUsageDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RawUsageDatumCondition>;
  filter?: InputMaybe<RawUsageDatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RawUsageDataOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRawUsageDatumArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRawUsageDatumByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTenantArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTenantByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTenantByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTenantByUrlArgs = {
  url: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTenantsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TenantCondition>;
  filter?: InputMaybe<TenantFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TenantsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTrialArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTrialByHumanReadableIdArgs = {
  humanReadableId: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTrialByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTrialByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTrialsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByCredentialsIdArgs = {
  credentialsId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByEmailAddressIdArgs = {
  emailAddressId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByIdAndTenantIdArgs = {
  id: Scalars['UUID'];
  tenantId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByUsernameAndTenantIdArgs = {
  tenantId: Scalars['UUID'];
  username: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserCredentialArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserCredentialByExternalAuthIdAndTenantIdArgs = {
  externalAuthId: Scalars['String'];
  tenantId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserCredentialByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserCredentialsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCredentialCondition>;
  filter?: InputMaybe<UserCredentialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserCredentialsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailAddressArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailAddressByEmailAddressAndTenantIdArgs = {
  emailAddress: Scalars['String'];
  tenantId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailAddressByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserEmailAddressesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserEmailAddressCondition>;
  filter?: InputMaybe<UserEmailAddressFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserEmailAddressesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type RandomizationTicket = Node & {
  __typename?: 'RandomizationTicket';
  blockNumber: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  handedOutAt?: Maybe<Scalars['Datetime']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  stratum: Scalars['String'];
  treatment: Scalars['String'];
  /** Reads a single `Trial` that is related to this `RandomizationTicket`. */
  trial?: Maybe<Trial>;
  trialId?: Maybe<Scalars['UUID']>;
};

export type RandomizationTicketAggregates = {
  __typename?: 'RandomizationTicketAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<RandomizationTicketAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<RandomizationTicketDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<RandomizationTicketMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<RandomizationTicketMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<RandomizationTicketStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<RandomizationTicketStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<RandomizationTicketSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<RandomizationTicketVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<RandomizationTicketVarianceSampleAggregates>;
};

export type RandomizationTicketAverageAggregates = {
  __typename?: 'RandomizationTicketAverageAggregates';
  /** Mean average of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

/**
 * A condition to be used against `RandomizationTicket` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type RandomizationTicketCondition = {
  /** Checks for equality with the object’s `blockNumber` field. */
  blockNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `handedOutAt` field. */
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `stratum` field. */
  stratum?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `treatment` field. */
  treatment?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `trialId` field. */
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type RandomizationTicketDistinctCountAggregates = {
  __typename?: 'RandomizationTicketDistinctCountAggregates';
  /** Distinct count of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of handedOutAt across the matching connection */
  handedOutAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of stratum across the matching connection */
  stratum?: Maybe<Scalars['BigInt']>;
  /** Distinct count of treatment across the matching connection */
  treatment?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trialId across the matching connection */
  trialId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `RandomizationTicket` object types. All fields are combined with a logical ‘and.’ */
export type RandomizationTicketFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RandomizationTicketFilter>>;
  /** Filter by the object’s `blockNumber` field. */
  blockNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `handedOutAt` field. */
  handedOutAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RandomizationTicketFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RandomizationTicketFilter>>;
  /** Filter by the object’s `stratum` field. */
  stratum?: InputMaybe<StringFilter>;
  /** Filter by the object’s `treatment` field. */
  treatment?: InputMaybe<StringFilter>;
  /** Filter by the object’s `trial` relation. */
  trial?: InputMaybe<TrialFilter>;
  /** A related `trial` exists. */
  trialExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `trialId` field. */
  trialId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `RandomizationTicket` */
export type RandomizationTicketInput = {
  blockNumber: Scalars['Int'];
  createdAt?: InputMaybe<Scalars['Datetime']>;
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  stratum: Scalars['String'];
  treatment: Scalars['String'];
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type RandomizationTicketMaxAggregates = {
  __typename?: 'RandomizationTicketMaxAggregates';
  /** Maximum of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['Int']>;
};

export type RandomizationTicketMinAggregates = {
  __typename?: 'RandomizationTicketMinAggregates';
  /** Minimum of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['Int']>;
};

/** Represents an update to a `RandomizationTicket`. Fields that are set will be updated. */
export type RandomizationTicketPatch = {
  blockNumber?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  handedOutAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  stratum?: InputMaybe<Scalars['String']>;
  treatment?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

export type RandomizationTicketStddevPopulationAggregates = {
  __typename?: 'RandomizationTicketStddevPopulationAggregates';
  /** Population standard deviation of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

export type RandomizationTicketStddevSampleAggregates = {
  __typename?: 'RandomizationTicketStddevSampleAggregates';
  /** Sample standard deviation of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

export type RandomizationTicketSumAggregates = {
  __typename?: 'RandomizationTicketSumAggregates';
  /** Sum of blockNumber across the matching connection */
  blockNumber: Scalars['BigInt'];
};

export type RandomizationTicketVariancePopulationAggregates = {
  __typename?: 'RandomizationTicketVariancePopulationAggregates';
  /** Population variance of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

export type RandomizationTicketVarianceSampleAggregates = {
  __typename?: 'RandomizationTicketVarianceSampleAggregates';
  /** Sample variance of blockNumber across the matching connection */
  blockNumber?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `RandomizationTicket` values. */
export type RandomizationTicketsConnection = {
  __typename?: 'RandomizationTicketsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<RandomizationTicketAggregates>;
  /** A list of edges which contains the `RandomizationTicket` and cursor to aid in pagination. */
  edges: Array<RandomizationTicketsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<RandomizationTicketAggregates>>;
  /** A list of `RandomizationTicket` objects. */
  nodes: Array<RandomizationTicket>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RandomizationTicket` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `RandomizationTicket` values. */
export type RandomizationTicketsConnectionGroupedAggregatesArgs = {
  groupBy: Array<RandomizationTicketsGroupBy>;
  having?: InputMaybe<RandomizationTicketsHavingInput>;
};

/** A `RandomizationTicket` edge in the connection. */
export type RandomizationTicketsEdge = {
  __typename?: 'RandomizationTicketsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RandomizationTicket` at the end of the edge. */
  node: RandomizationTicket;
};

/** Grouping methods for `RandomizationTicket` for usage during aggregation. */
export enum RandomizationTicketsGroupBy {
  BlockNumber = 'BLOCK_NUMBER',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  HandedOutAt = 'HANDED_OUT_AT',
  HandedOutAtTruncatedToDay = 'HANDED_OUT_AT_TRUNCATED_TO_DAY',
  HandedOutAtTruncatedToHour = 'HANDED_OUT_AT_TRUNCATED_TO_HOUR',
  Stratum = 'STRATUM',
  Treatment = 'TREATMENT'
}

export type RandomizationTicketsHavingAverageInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RandomizationTicketsHavingDistinctCountInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `RandomizationTicket` aggregates. */
export type RandomizationTicketsHavingInput = {
  AND?: InputMaybe<Array<RandomizationTicketsHavingInput>>;
  OR?: InputMaybe<Array<RandomizationTicketsHavingInput>>;
  average?: InputMaybe<RandomizationTicketsHavingAverageInput>;
  distinctCount?: InputMaybe<RandomizationTicketsHavingDistinctCountInput>;
  max?: InputMaybe<RandomizationTicketsHavingMaxInput>;
  min?: InputMaybe<RandomizationTicketsHavingMinInput>;
  stddevPopulation?: InputMaybe<RandomizationTicketsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<RandomizationTicketsHavingStddevSampleInput>;
  sum?: InputMaybe<RandomizationTicketsHavingSumInput>;
  variancePopulation?: InputMaybe<RandomizationTicketsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<RandomizationTicketsHavingVarianceSampleInput>;
};

export type RandomizationTicketsHavingMaxInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RandomizationTicketsHavingMinInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RandomizationTicketsHavingStddevPopulationInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RandomizationTicketsHavingStddevSampleInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RandomizationTicketsHavingSumInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RandomizationTicketsHavingVariancePopulationInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RandomizationTicketsHavingVarianceSampleInput = {
  blockNumber?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  handedOutAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `RandomizationTicket`. */
export enum RandomizationTicketsOrderBy {
  BlockNumberAsc = 'BLOCK_NUMBER_ASC',
  BlockNumberDesc = 'BLOCK_NUMBER_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  HandedOutAtAsc = 'HANDED_OUT_AT_ASC',
  HandedOutAtDesc = 'HANDED_OUT_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StratumAsc = 'STRATUM_ASC',
  StratumDesc = 'STRATUM_DESC',
  TreatmentAsc = 'TREATMENT_ASC',
  TreatmentDesc = 'TREATMENT_DESC',
  TrialIdAsc = 'TRIAL_ID_ASC',
  TrialIdDesc = 'TRIAL_ID_DESC'
}

/** A connection to a list of `RawUsageDatum` values. */
export type RawUsageDataConnection = {
  __typename?: 'RawUsageDataConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<RawUsageDatumAggregates>;
  /** A list of edges which contains the `RawUsageDatum` and cursor to aid in pagination. */
  edges: Array<RawUsageDataEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<RawUsageDatumAggregates>>;
  /** A list of `RawUsageDatum` objects. */
  nodes: Array<RawUsageDatum>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RawUsageDatum` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `RawUsageDatum` values. */
export type RawUsageDataConnectionGroupedAggregatesArgs = {
  groupBy: Array<RawUsageDataGroupBy>;
  having?: InputMaybe<RawUsageDataHavingInput>;
};

/** A `RawUsageDatum` edge in the connection. */
export type RawUsageDataEdge = {
  __typename?: 'RawUsageDataEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RawUsageDatum` at the end of the edge. */
  node: RawUsageDatum;
};

/** Grouping methods for `RawUsageDatum` for usage during aggregation. */
export enum RawUsageDataGroupBy {
  ClientHash = 'CLIENT_HASH',
  Payload = 'PAYLOAD',
  RemoteAddress = 'REMOTE_ADDRESS',
  TrackedAt = 'TRACKED_AT',
  TrackedAtTruncatedToDay = 'TRACKED_AT_TRUNCATED_TO_DAY',
  TrackedAtTruncatedToHour = 'TRACKED_AT_TRUNCATED_TO_HOUR',
  TrialId = 'TRIAL_ID',
  Type = 'TYPE',
  UserAgent = 'USER_AGENT',
  UserId = 'USER_ID'
}

export type RawUsageDataHavingAverageInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RawUsageDataHavingDistinctCountInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `RawUsageDatum` aggregates. */
export type RawUsageDataHavingInput = {
  AND?: InputMaybe<Array<RawUsageDataHavingInput>>;
  OR?: InputMaybe<Array<RawUsageDataHavingInput>>;
  average?: InputMaybe<RawUsageDataHavingAverageInput>;
  distinctCount?: InputMaybe<RawUsageDataHavingDistinctCountInput>;
  max?: InputMaybe<RawUsageDataHavingMaxInput>;
  min?: InputMaybe<RawUsageDataHavingMinInput>;
  stddevPopulation?: InputMaybe<RawUsageDataHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<RawUsageDataHavingStddevSampleInput>;
  sum?: InputMaybe<RawUsageDataHavingSumInput>;
  variancePopulation?: InputMaybe<RawUsageDataHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<RawUsageDataHavingVarianceSampleInput>;
};

export type RawUsageDataHavingMaxInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RawUsageDataHavingMinInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RawUsageDataHavingStddevPopulationInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RawUsageDataHavingStddevSampleInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RawUsageDataHavingSumInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RawUsageDataHavingVariancePopulationInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RawUsageDataHavingVarianceSampleInput = {
  trackedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `RawUsageDatum`. */
export enum RawUsageDataOrderBy {
  ClientHashAsc = 'CLIENT_HASH_ASC',
  ClientHashDesc = 'CLIENT_HASH_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RemoteAddressAsc = 'REMOTE_ADDRESS_ASC',
  RemoteAddressDesc = 'REMOTE_ADDRESS_DESC',
  TrackedAtAsc = 'TRACKED_AT_ASC',
  TrackedAtDesc = 'TRACKED_AT_DESC',
  TrialIdAsc = 'TRIAL_ID_ASC',
  TrialIdDesc = 'TRIAL_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UserAgentAsc = 'USER_AGENT_ASC',
  UserAgentDesc = 'USER_AGENT_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type RawUsageDatum = Node & {
  __typename?: 'RawUsageDatum';
  clientHash?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  payload?: Maybe<Scalars['JSON']>;
  remoteAddress?: Maybe<Array<Maybe<Scalars['InternetAddress']>>>;
  trackedAt: Scalars['Datetime'];
  /** Reads a single `Trial` that is related to this `RawUsageDatum`. */
  trial?: Maybe<Trial>;
  trialId?: Maybe<Scalars['UUID']>;
  type: Scalars['String'];
  /** Reads a single `User` that is related to this `RawUsageDatum`. */
  user?: Maybe<User>;
  userAgent?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['UUID']>;
};

export type RawUsageDatumAggregates = {
  __typename?: 'RawUsageDatumAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<RawUsageDatumDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `RawUsageDatum` object types. */
export type RawUsageDatumAggregatesFilter = {
  /** Distinct count aggregate over matching `RawUsageDatum` objects. */
  distinctCount?: InputMaybe<RawUsageDatumDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `RawUsageDatum` object to be included within the aggregate. */
  filter?: InputMaybe<RawUsageDatumFilter>;
};

/**
 * A condition to be used against `RawUsageDatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type RawUsageDatumCondition = {
  /** Checks for equality with the object’s `clientHash` field. */
  clientHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: InputMaybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `remoteAddress` field. */
  remoteAddress?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  /** Checks for equality with the object’s `trackedAt` field. */
  trackedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `trialId` field. */
  trialId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userAgent` field. */
  userAgent?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

export type RawUsageDatumDistinctCountAggregateFilter = {
  clientHash?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  payload?: InputMaybe<BigIntFilter>;
  remoteAddress?: InputMaybe<BigIntFilter>;
  trackedAt?: InputMaybe<BigIntFilter>;
  trialId?: InputMaybe<BigIntFilter>;
  type?: InputMaybe<BigIntFilter>;
  userAgent?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type RawUsageDatumDistinctCountAggregates = {
  __typename?: 'RawUsageDatumDistinctCountAggregates';
  /** Distinct count of clientHash across the matching connection */
  clientHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of payload across the matching connection */
  payload?: Maybe<Scalars['BigInt']>;
  /** Distinct count of remoteAddress across the matching connection */
  remoteAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trackedAt across the matching connection */
  trackedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of trialId across the matching connection */
  trialId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userAgent across the matching connection */
  userAgent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `RawUsageDatum` object types. All fields are combined with a logical ‘and.’ */
export type RawUsageDatumFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RawUsageDatumFilter>>;
  /** Filter by the object’s `clientHash` field. */
  clientHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RawUsageDatumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RawUsageDatumFilter>>;
  /** Filter by the object’s `payload` field. */
  payload?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `remoteAddress` field. */
  remoteAddress?: InputMaybe<InternetAddressListFilter>;
  /** Filter by the object’s `trackedAt` field. */
  trackedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `trial` relation. */
  trial?: InputMaybe<TrialFilter>;
  /** A related `trial` exists. */
  trialExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `trialId` field. */
  trialId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userAgent` field. */
  userAgent?: InputMaybe<StringFilter>;
  /** A related `user` exists. */
  userExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `RawUsageDatum` */
export type RawUsageDatumInput = {
  clientHash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  payload?: InputMaybe<Scalars['JSON']>;
  remoteAddress?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  trackedAt?: InputMaybe<Scalars['Datetime']>;
  trialId?: InputMaybe<Scalars['UUID']>;
  type: Scalars['String'];
  userAgent?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `RawUsageDatum`. Fields that are set will be updated. */
export type RawUsageDatumPatch = {
  clientHash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  payload?: InputMaybe<Scalars['JSON']>;
  remoteAddress?: InputMaybe<Array<InputMaybe<Scalars['InternetAddress']>>>;
  trackedAt?: InputMaybe<Scalars['Datetime']>;
  trialId?: InputMaybe<Scalars['UUID']>;
  type?: InputMaybe<Scalars['String']>;
  userAgent?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the `setAvatar` mutation. */
export type SetAvatarInput = {
  avatar?: InputMaybe<AvatarIcon>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `setAvatar` mutation. */
export type SetAvatarPayload = {
  __typename?: 'SetAvatarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Commentable` that is related to this `Trial`. */
  commentableById?: Maybe<Commentable>;
  /** Reads a single `User` that is related to this `Trial`. */
  createdBy?: Maybe<User>;
  /** Reads a single `GroupHome` that is related to this `Trial`. */
  groupHomeById?: Maybe<GroupHome>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  internalMessages?: Maybe<Conversation>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  messages?: Maybe<Conversation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Trial`. */
  staff?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Trial`. */
  subject?: Maybe<User>;
  /** Reads a single `Tenant` that is related to this `Trial`. */
  tenant?: Maybe<Tenant>;
  trial?: Maybe<Trial>;
  /** An edge for our `Trial`. May be used by Relay 1. */
  trialEdge?: Maybe<TrialsEdge>;
};


/** The output of our `setAvatar` mutation. */
export type SetAvatarPayloadTrialEdgeArgs = {
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

/** All input for the `setEmergencyPhoneNumbers` mutation. */
export type SetEmergencyPhoneNumbersInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  phoneNumbers: Array<InputMaybe<EmergencyContactInput>>;
};

/** The output of our `setEmergencyPhoneNumbers` mutation. */
export type SetEmergencyPhoneNumbersPayload = {
  __typename?: 'SetEmergencyPhoneNumbersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Commentable` that is related to this `Trial`. */
  commentableById?: Maybe<Commentable>;
  /** Reads a single `User` that is related to this `Trial`. */
  createdBy?: Maybe<User>;
  /** Reads a single `GroupHome` that is related to this `Trial`. */
  groupHomeById?: Maybe<GroupHome>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  internalMessages?: Maybe<Conversation>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  messages?: Maybe<Conversation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Trial`. */
  staff?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Trial`. */
  subject?: Maybe<User>;
  /** Reads a single `Tenant` that is related to this `Trial`. */
  tenant?: Maybe<Tenant>;
  trial?: Maybe<Trial>;
  /** An edge for our `Trial`. May be used by Relay 1. */
  trialEdge?: Maybe<TrialsEdge>;
};


/** The output of our `setEmergencyPhoneNumbers` mutation. */
export type SetEmergencyPhoneNumbersPayloadTrialEdgeArgs = {
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

/** All input for the `setNickname` mutation. */
export type SetNicknameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
};

/** The output of our `setNickname` mutation. */
export type SetNicknamePayload = {
  __typename?: 'SetNicknamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Commentable` that is related to this `Trial`. */
  commentableById?: Maybe<Commentable>;
  /** Reads a single `User` that is related to this `Trial`. */
  createdBy?: Maybe<User>;
  /** Reads a single `GroupHome` that is related to this `Trial`. */
  groupHomeById?: Maybe<GroupHome>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  internalMessages?: Maybe<Conversation>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  messages?: Maybe<Conversation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Trial`. */
  staff?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Trial`. */
  subject?: Maybe<User>;
  /** Reads a single `Tenant` that is related to this `Trial`. */
  tenant?: Maybe<Tenant>;
  trial?: Maybe<Trial>;
  /** An edge for our `Trial`. May be used by Relay 1. */
  trialEdge?: Maybe<TrialsEdge>;
};


/** The output of our `setNickname` mutation. */
export type SetNicknamePayloadTrialEdgeArgs = {
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

/** All input for the `signIn` mutation. */
export type SignInInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailOrUsername: Scalars['String'];
  password: Scalars['String'];
  tenantId?: InputMaybe<Scalars['UUID']>;
};

/** The output of our `signIn` mutation. */
export type SignInPayload = {
  __typename?: 'SignInPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  result?: Maybe<SignInRecord>;
};

/** The return type of our `signIn` mutation. */
export type SignInRecord = {
  __typename?: 'SignInRecord';
  tenant?: Maybe<Tenant>;
  token?: Maybe<AuthToken>;
  user?: Maybe<User>;
};

/** All input for the `signUpWithAccessToken` mutation. */
export type SignUpWithAccessTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `signUpWithAccessToken` mutation. */
export type SignUpWithAccessTokenPayload = {
  __typename?: 'SignUpWithAccessTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  result?: Maybe<SignUpWithAccessTokenRecord>;
};

/** The return type of our `signUpWithAccessToken` mutation. */
export type SignUpWithAccessTokenRecord = {
  __typename?: 'SignUpWithAccessTokenRecord';
  tenant?: Maybe<Tenant>;
  user?: Maybe<User>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars['String']>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  listen: ListenPayload;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionListenArgs = {
  topic: Scalars['String'];
};

export type Tenant = Node & {
  __typename?: 'Tenant';
  /** Reads and enables pagination through a set of `Conversation`. */
  conversations: ConversationsConnection;
  createdAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `Group`. */
  groups: GroupsConnection;
  id: Scalars['UUID'];
  keycloakRealmUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Trial`. */
  trials: TrialsConnection;
  updatedAt: Scalars['Datetime'];
  url?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `UserCredential`. */
  userCredentials: UserCredentialsConnection;
  /** Reads and enables pagination through a set of `User`. */
  users: UsersConnection;
};


export type TenantConversationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConversationCondition>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};


export type TenantGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GroupCondition>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};


export type TenantTrialsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};


export type TenantUserCredentialsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCredentialCondition>;
  filter?: InputMaybe<UserCredentialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserCredentialsOrderBy>>;
};


export type TenantUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type TenantAggregates = {
  __typename?: 'TenantAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TenantDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A condition to be used against `Tenant` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TenantCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `keycloakRealmUrl` field. */
  keycloakRealmUrl?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `url` field. */
  url?: InputMaybe<Scalars['String']>;
};

export type TenantDistinctCountAggregates = {
  __typename?: 'TenantDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of keycloakRealmUrl across the matching connection */
  keycloakRealmUrl?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of url across the matching connection */
  url?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Tenant` object types. All fields are combined with a logical ‘and.’ */
export type TenantFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TenantFilter>>;
  /** Filter by the object’s `conversations` relation. */
  conversations?: InputMaybe<TenantToManyConversationFilter>;
  /** Some related `conversations` exist. */
  conversationsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `groups` relation. */
  groups?: InputMaybe<TenantToManyGroupFilter>;
  /** Some related `groups` exist. */
  groupsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `keycloakRealmUrl` field. */
  keycloakRealmUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TenantFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TenantFilter>>;
  /** Filter by the object’s `trials` relation. */
  trials?: InputMaybe<TenantToManyTrialFilter>;
  /** Some related `trials` exist. */
  trialsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `url` field. */
  url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userCredentials` relation. */
  userCredentials?: InputMaybe<TenantToManyUserCredentialFilter>;
  /** Some related `userCredentials` exist. */
  userCredentialsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `users` relation. */
  users?: InputMaybe<TenantToManyUserFilter>;
  /** Some related `users` exist. */
  usersExist?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `Tenant` */
export type TenantInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  keycloakRealmUrl?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `Tenant`. Fields that are set will be updated. */
export type TenantPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  keycloakRealmUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  url?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against many `Conversation` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyConversationFilter = {
  /** Aggregates across related `Conversation` match the filter criteria. */
  aggregates?: InputMaybe<ConversationAggregatesFilter>;
  /** Every related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConversationFilter>;
  /** No related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConversationFilter>;
  /** Some related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConversationFilter>;
};

/** A filter to be used against many `Group` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyGroupFilter = {
  /** Aggregates across related `Group` match the filter criteria. */
  aggregates?: InputMaybe<GroupAggregatesFilter>;
  /** Every related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<GroupFilter>;
  /** No related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<GroupFilter>;
  /** Some related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<GroupFilter>;
};

/** A filter to be used against many `Trial` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyTrialFilter = {
  /** Aggregates across related `Trial` match the filter criteria. */
  aggregates?: InputMaybe<TrialAggregatesFilter>;
  /** Every related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TrialFilter>;
  /** No related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TrialFilter>;
  /** Some related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TrialFilter>;
};

/** A filter to be used against many `UserCredential` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyUserCredentialFilter = {
  /** Aggregates across related `UserCredential` match the filter criteria. */
  aggregates?: InputMaybe<UserCredentialAggregatesFilter>;
  /** Every related `UserCredential` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserCredentialFilter>;
  /** No related `UserCredential` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserCredentialFilter>;
  /** Some related `UserCredential` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserCredentialFilter>;
};

/** A filter to be used against many `User` object types. All fields are combined with a logical ‘and.’ */
export type TenantToManyUserFilter = {
  /** Aggregates across related `User` match the filter criteria. */
  aggregates?: InputMaybe<UserAggregatesFilter>;
  /** Every related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserFilter>;
  /** No related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserFilter>;
  /** Some related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserFilter>;
};

/** A connection to a list of `Tenant` values. */
export type TenantsConnection = {
  __typename?: 'TenantsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TenantAggregates>;
  /** A list of edges which contains the `Tenant` and cursor to aid in pagination. */
  edges: Array<TenantsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TenantAggregates>>;
  /** A list of `Tenant` objects. */
  nodes: Array<Tenant>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tenant` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Tenant` values. */
export type TenantsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TenantsGroupBy>;
  having?: InputMaybe<TenantsHavingInput>;
};

/** A `Tenant` edge in the connection. */
export type TenantsEdge = {
  __typename?: 'TenantsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Tenant` at the end of the edge. */
  node: Tenant;
};

/** Grouping methods for `Tenant` for usage during aggregation. */
export enum TenantsGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  KeycloakRealmUrl = 'KEYCLOAK_REALM_URL',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type TenantsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TenantsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Tenant` aggregates. */
export type TenantsHavingInput = {
  AND?: InputMaybe<Array<TenantsHavingInput>>;
  OR?: InputMaybe<Array<TenantsHavingInput>>;
  average?: InputMaybe<TenantsHavingAverageInput>;
  distinctCount?: InputMaybe<TenantsHavingDistinctCountInput>;
  max?: InputMaybe<TenantsHavingMaxInput>;
  min?: InputMaybe<TenantsHavingMinInput>;
  stddevPopulation?: InputMaybe<TenantsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TenantsHavingStddevSampleInput>;
  sum?: InputMaybe<TenantsHavingSumInput>;
  variancePopulation?: InputMaybe<TenantsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TenantsHavingVarianceSampleInput>;
};

export type TenantsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TenantsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TenantsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TenantsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TenantsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TenantsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TenantsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Tenant`. */
export enum TenantsOrderBy {
  ConversationsAverageAboutCommentableIdAsc = 'CONVERSATIONS_AVERAGE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsAverageAboutCommentableIdDesc = 'CONVERSATIONS_AVERAGE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsAverageAbstractAsc = 'CONVERSATIONS_AVERAGE_ABSTRACT_ASC',
  ConversationsAverageAbstractDesc = 'CONVERSATIONS_AVERAGE_ABSTRACT_DESC',
  ConversationsAverageCreatedAtAsc = 'CONVERSATIONS_AVERAGE_CREATED_AT_ASC',
  ConversationsAverageCreatedAtDesc = 'CONVERSATIONS_AVERAGE_CREATED_AT_DESC',
  ConversationsAverageCreatorIdAsc = 'CONVERSATIONS_AVERAGE_CREATOR_ID_ASC',
  ConversationsAverageCreatorIdDesc = 'CONVERSATIONS_AVERAGE_CREATOR_ID_DESC',
  ConversationsAverageIdAsc = 'CONVERSATIONS_AVERAGE_ID_ASC',
  ConversationsAverageIdDesc = 'CONVERSATIONS_AVERAGE_ID_DESC',
  ConversationsAverageIsPublicAsc = 'CONVERSATIONS_AVERAGE_IS_PUBLIC_ASC',
  ConversationsAverageIsPublicDesc = 'CONVERSATIONS_AVERAGE_IS_PUBLIC_DESC',
  ConversationsAverageLanguageAsc = 'CONVERSATIONS_AVERAGE_LANGUAGE_ASC',
  ConversationsAverageLanguageDesc = 'CONVERSATIONS_AVERAGE_LANGUAGE_DESC',
  ConversationsAveragePrivateToGroupIdAsc = 'CONVERSATIONS_AVERAGE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsAveragePrivateToGroupIdDesc = 'CONVERSATIONS_AVERAGE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsAverageTenantIdAsc = 'CONVERSATIONS_AVERAGE_TENANT_ID_ASC',
  ConversationsAverageTenantIdDesc = 'CONVERSATIONS_AVERAGE_TENANT_ID_DESC',
  ConversationsAverageTitleAsc = 'CONVERSATIONS_AVERAGE_TITLE_ASC',
  ConversationsAverageTitleDesc = 'CONVERSATIONS_AVERAGE_TITLE_DESC',
  ConversationsAverageUpdatedAtAsc = 'CONVERSATIONS_AVERAGE_UPDATED_AT_ASC',
  ConversationsAverageUpdatedAtDesc = 'CONVERSATIONS_AVERAGE_UPDATED_AT_DESC',
  ConversationsCountAsc = 'CONVERSATIONS_COUNT_ASC',
  ConversationsCountDesc = 'CONVERSATIONS_COUNT_DESC',
  ConversationsDistinctCountAboutCommentableIdAsc = 'CONVERSATIONS_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsDistinctCountAboutCommentableIdDesc = 'CONVERSATIONS_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsDistinctCountAbstractAsc = 'CONVERSATIONS_DISTINCT_COUNT_ABSTRACT_ASC',
  ConversationsDistinctCountAbstractDesc = 'CONVERSATIONS_DISTINCT_COUNT_ABSTRACT_DESC',
  ConversationsDistinctCountCreatedAtAsc = 'CONVERSATIONS_DISTINCT_COUNT_CREATED_AT_ASC',
  ConversationsDistinctCountCreatedAtDesc = 'CONVERSATIONS_DISTINCT_COUNT_CREATED_AT_DESC',
  ConversationsDistinctCountCreatorIdAsc = 'CONVERSATIONS_DISTINCT_COUNT_CREATOR_ID_ASC',
  ConversationsDistinctCountCreatorIdDesc = 'CONVERSATIONS_DISTINCT_COUNT_CREATOR_ID_DESC',
  ConversationsDistinctCountIdAsc = 'CONVERSATIONS_DISTINCT_COUNT_ID_ASC',
  ConversationsDistinctCountIdDesc = 'CONVERSATIONS_DISTINCT_COUNT_ID_DESC',
  ConversationsDistinctCountIsPublicAsc = 'CONVERSATIONS_DISTINCT_COUNT_IS_PUBLIC_ASC',
  ConversationsDistinctCountIsPublicDesc = 'CONVERSATIONS_DISTINCT_COUNT_IS_PUBLIC_DESC',
  ConversationsDistinctCountLanguageAsc = 'CONVERSATIONS_DISTINCT_COUNT_LANGUAGE_ASC',
  ConversationsDistinctCountLanguageDesc = 'CONVERSATIONS_DISTINCT_COUNT_LANGUAGE_DESC',
  ConversationsDistinctCountPrivateToGroupIdAsc = 'CONVERSATIONS_DISTINCT_COUNT_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsDistinctCountPrivateToGroupIdDesc = 'CONVERSATIONS_DISTINCT_COUNT_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsDistinctCountTenantIdAsc = 'CONVERSATIONS_DISTINCT_COUNT_TENANT_ID_ASC',
  ConversationsDistinctCountTenantIdDesc = 'CONVERSATIONS_DISTINCT_COUNT_TENANT_ID_DESC',
  ConversationsDistinctCountTitleAsc = 'CONVERSATIONS_DISTINCT_COUNT_TITLE_ASC',
  ConversationsDistinctCountTitleDesc = 'CONVERSATIONS_DISTINCT_COUNT_TITLE_DESC',
  ConversationsDistinctCountUpdatedAtAsc = 'CONVERSATIONS_DISTINCT_COUNT_UPDATED_AT_ASC',
  ConversationsDistinctCountUpdatedAtDesc = 'CONVERSATIONS_DISTINCT_COUNT_UPDATED_AT_DESC',
  ConversationsMaxAboutCommentableIdAsc = 'CONVERSATIONS_MAX_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsMaxAboutCommentableIdDesc = 'CONVERSATIONS_MAX_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsMaxAbstractAsc = 'CONVERSATIONS_MAX_ABSTRACT_ASC',
  ConversationsMaxAbstractDesc = 'CONVERSATIONS_MAX_ABSTRACT_DESC',
  ConversationsMaxCreatedAtAsc = 'CONVERSATIONS_MAX_CREATED_AT_ASC',
  ConversationsMaxCreatedAtDesc = 'CONVERSATIONS_MAX_CREATED_AT_DESC',
  ConversationsMaxCreatorIdAsc = 'CONVERSATIONS_MAX_CREATOR_ID_ASC',
  ConversationsMaxCreatorIdDesc = 'CONVERSATIONS_MAX_CREATOR_ID_DESC',
  ConversationsMaxIdAsc = 'CONVERSATIONS_MAX_ID_ASC',
  ConversationsMaxIdDesc = 'CONVERSATIONS_MAX_ID_DESC',
  ConversationsMaxIsPublicAsc = 'CONVERSATIONS_MAX_IS_PUBLIC_ASC',
  ConversationsMaxIsPublicDesc = 'CONVERSATIONS_MAX_IS_PUBLIC_DESC',
  ConversationsMaxLanguageAsc = 'CONVERSATIONS_MAX_LANGUAGE_ASC',
  ConversationsMaxLanguageDesc = 'CONVERSATIONS_MAX_LANGUAGE_DESC',
  ConversationsMaxPrivateToGroupIdAsc = 'CONVERSATIONS_MAX_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsMaxPrivateToGroupIdDesc = 'CONVERSATIONS_MAX_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsMaxTenantIdAsc = 'CONVERSATIONS_MAX_TENANT_ID_ASC',
  ConversationsMaxTenantIdDesc = 'CONVERSATIONS_MAX_TENANT_ID_DESC',
  ConversationsMaxTitleAsc = 'CONVERSATIONS_MAX_TITLE_ASC',
  ConversationsMaxTitleDesc = 'CONVERSATIONS_MAX_TITLE_DESC',
  ConversationsMaxUpdatedAtAsc = 'CONVERSATIONS_MAX_UPDATED_AT_ASC',
  ConversationsMaxUpdatedAtDesc = 'CONVERSATIONS_MAX_UPDATED_AT_DESC',
  ConversationsMinAboutCommentableIdAsc = 'CONVERSATIONS_MIN_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsMinAboutCommentableIdDesc = 'CONVERSATIONS_MIN_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsMinAbstractAsc = 'CONVERSATIONS_MIN_ABSTRACT_ASC',
  ConversationsMinAbstractDesc = 'CONVERSATIONS_MIN_ABSTRACT_DESC',
  ConversationsMinCreatedAtAsc = 'CONVERSATIONS_MIN_CREATED_AT_ASC',
  ConversationsMinCreatedAtDesc = 'CONVERSATIONS_MIN_CREATED_AT_DESC',
  ConversationsMinCreatorIdAsc = 'CONVERSATIONS_MIN_CREATOR_ID_ASC',
  ConversationsMinCreatorIdDesc = 'CONVERSATIONS_MIN_CREATOR_ID_DESC',
  ConversationsMinIdAsc = 'CONVERSATIONS_MIN_ID_ASC',
  ConversationsMinIdDesc = 'CONVERSATIONS_MIN_ID_DESC',
  ConversationsMinIsPublicAsc = 'CONVERSATIONS_MIN_IS_PUBLIC_ASC',
  ConversationsMinIsPublicDesc = 'CONVERSATIONS_MIN_IS_PUBLIC_DESC',
  ConversationsMinLanguageAsc = 'CONVERSATIONS_MIN_LANGUAGE_ASC',
  ConversationsMinLanguageDesc = 'CONVERSATIONS_MIN_LANGUAGE_DESC',
  ConversationsMinPrivateToGroupIdAsc = 'CONVERSATIONS_MIN_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsMinPrivateToGroupIdDesc = 'CONVERSATIONS_MIN_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsMinTenantIdAsc = 'CONVERSATIONS_MIN_TENANT_ID_ASC',
  ConversationsMinTenantIdDesc = 'CONVERSATIONS_MIN_TENANT_ID_DESC',
  ConversationsMinTitleAsc = 'CONVERSATIONS_MIN_TITLE_ASC',
  ConversationsMinTitleDesc = 'CONVERSATIONS_MIN_TITLE_DESC',
  ConversationsMinUpdatedAtAsc = 'CONVERSATIONS_MIN_UPDATED_AT_ASC',
  ConversationsMinUpdatedAtDesc = 'CONVERSATIONS_MIN_UPDATED_AT_DESC',
  ConversationsStddevPopulationAboutCommentableIdAsc = 'CONVERSATIONS_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsStddevPopulationAboutCommentableIdDesc = 'CONVERSATIONS_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsStddevPopulationAbstractAsc = 'CONVERSATIONS_STDDEV_POPULATION_ABSTRACT_ASC',
  ConversationsStddevPopulationAbstractDesc = 'CONVERSATIONS_STDDEV_POPULATION_ABSTRACT_DESC',
  ConversationsStddevPopulationCreatedAtAsc = 'CONVERSATIONS_STDDEV_POPULATION_CREATED_AT_ASC',
  ConversationsStddevPopulationCreatedAtDesc = 'CONVERSATIONS_STDDEV_POPULATION_CREATED_AT_DESC',
  ConversationsStddevPopulationCreatorIdAsc = 'CONVERSATIONS_STDDEV_POPULATION_CREATOR_ID_ASC',
  ConversationsStddevPopulationCreatorIdDesc = 'CONVERSATIONS_STDDEV_POPULATION_CREATOR_ID_DESC',
  ConversationsStddevPopulationIdAsc = 'CONVERSATIONS_STDDEV_POPULATION_ID_ASC',
  ConversationsStddevPopulationIdDesc = 'CONVERSATIONS_STDDEV_POPULATION_ID_DESC',
  ConversationsStddevPopulationIsPublicAsc = 'CONVERSATIONS_STDDEV_POPULATION_IS_PUBLIC_ASC',
  ConversationsStddevPopulationIsPublicDesc = 'CONVERSATIONS_STDDEV_POPULATION_IS_PUBLIC_DESC',
  ConversationsStddevPopulationLanguageAsc = 'CONVERSATIONS_STDDEV_POPULATION_LANGUAGE_ASC',
  ConversationsStddevPopulationLanguageDesc = 'CONVERSATIONS_STDDEV_POPULATION_LANGUAGE_DESC',
  ConversationsStddevPopulationPrivateToGroupIdAsc = 'CONVERSATIONS_STDDEV_POPULATION_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsStddevPopulationPrivateToGroupIdDesc = 'CONVERSATIONS_STDDEV_POPULATION_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsStddevPopulationTenantIdAsc = 'CONVERSATIONS_STDDEV_POPULATION_TENANT_ID_ASC',
  ConversationsStddevPopulationTenantIdDesc = 'CONVERSATIONS_STDDEV_POPULATION_TENANT_ID_DESC',
  ConversationsStddevPopulationTitleAsc = 'CONVERSATIONS_STDDEV_POPULATION_TITLE_ASC',
  ConversationsStddevPopulationTitleDesc = 'CONVERSATIONS_STDDEV_POPULATION_TITLE_DESC',
  ConversationsStddevPopulationUpdatedAtAsc = 'CONVERSATIONS_STDDEV_POPULATION_UPDATED_AT_ASC',
  ConversationsStddevPopulationUpdatedAtDesc = 'CONVERSATIONS_STDDEV_POPULATION_UPDATED_AT_DESC',
  ConversationsStddevSampleAboutCommentableIdAsc = 'CONVERSATIONS_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsStddevSampleAboutCommentableIdDesc = 'CONVERSATIONS_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsStddevSampleAbstractAsc = 'CONVERSATIONS_STDDEV_SAMPLE_ABSTRACT_ASC',
  ConversationsStddevSampleAbstractDesc = 'CONVERSATIONS_STDDEV_SAMPLE_ABSTRACT_DESC',
  ConversationsStddevSampleCreatedAtAsc = 'CONVERSATIONS_STDDEV_SAMPLE_CREATED_AT_ASC',
  ConversationsStddevSampleCreatedAtDesc = 'CONVERSATIONS_STDDEV_SAMPLE_CREATED_AT_DESC',
  ConversationsStddevSampleCreatorIdAsc = 'CONVERSATIONS_STDDEV_SAMPLE_CREATOR_ID_ASC',
  ConversationsStddevSampleCreatorIdDesc = 'CONVERSATIONS_STDDEV_SAMPLE_CREATOR_ID_DESC',
  ConversationsStddevSampleIdAsc = 'CONVERSATIONS_STDDEV_SAMPLE_ID_ASC',
  ConversationsStddevSampleIdDesc = 'CONVERSATIONS_STDDEV_SAMPLE_ID_DESC',
  ConversationsStddevSampleIsPublicAsc = 'CONVERSATIONS_STDDEV_SAMPLE_IS_PUBLIC_ASC',
  ConversationsStddevSampleIsPublicDesc = 'CONVERSATIONS_STDDEV_SAMPLE_IS_PUBLIC_DESC',
  ConversationsStddevSampleLanguageAsc = 'CONVERSATIONS_STDDEV_SAMPLE_LANGUAGE_ASC',
  ConversationsStddevSampleLanguageDesc = 'CONVERSATIONS_STDDEV_SAMPLE_LANGUAGE_DESC',
  ConversationsStddevSamplePrivateToGroupIdAsc = 'CONVERSATIONS_STDDEV_SAMPLE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsStddevSamplePrivateToGroupIdDesc = 'CONVERSATIONS_STDDEV_SAMPLE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsStddevSampleTenantIdAsc = 'CONVERSATIONS_STDDEV_SAMPLE_TENANT_ID_ASC',
  ConversationsStddevSampleTenantIdDesc = 'CONVERSATIONS_STDDEV_SAMPLE_TENANT_ID_DESC',
  ConversationsStddevSampleTitleAsc = 'CONVERSATIONS_STDDEV_SAMPLE_TITLE_ASC',
  ConversationsStddevSampleTitleDesc = 'CONVERSATIONS_STDDEV_SAMPLE_TITLE_DESC',
  ConversationsStddevSampleUpdatedAtAsc = 'CONVERSATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ConversationsStddevSampleUpdatedAtDesc = 'CONVERSATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ConversationsSumAboutCommentableIdAsc = 'CONVERSATIONS_SUM_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsSumAboutCommentableIdDesc = 'CONVERSATIONS_SUM_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsSumAbstractAsc = 'CONVERSATIONS_SUM_ABSTRACT_ASC',
  ConversationsSumAbstractDesc = 'CONVERSATIONS_SUM_ABSTRACT_DESC',
  ConversationsSumCreatedAtAsc = 'CONVERSATIONS_SUM_CREATED_AT_ASC',
  ConversationsSumCreatedAtDesc = 'CONVERSATIONS_SUM_CREATED_AT_DESC',
  ConversationsSumCreatorIdAsc = 'CONVERSATIONS_SUM_CREATOR_ID_ASC',
  ConversationsSumCreatorIdDesc = 'CONVERSATIONS_SUM_CREATOR_ID_DESC',
  ConversationsSumIdAsc = 'CONVERSATIONS_SUM_ID_ASC',
  ConversationsSumIdDesc = 'CONVERSATIONS_SUM_ID_DESC',
  ConversationsSumIsPublicAsc = 'CONVERSATIONS_SUM_IS_PUBLIC_ASC',
  ConversationsSumIsPublicDesc = 'CONVERSATIONS_SUM_IS_PUBLIC_DESC',
  ConversationsSumLanguageAsc = 'CONVERSATIONS_SUM_LANGUAGE_ASC',
  ConversationsSumLanguageDesc = 'CONVERSATIONS_SUM_LANGUAGE_DESC',
  ConversationsSumPrivateToGroupIdAsc = 'CONVERSATIONS_SUM_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsSumPrivateToGroupIdDesc = 'CONVERSATIONS_SUM_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsSumTenantIdAsc = 'CONVERSATIONS_SUM_TENANT_ID_ASC',
  ConversationsSumTenantIdDesc = 'CONVERSATIONS_SUM_TENANT_ID_DESC',
  ConversationsSumTitleAsc = 'CONVERSATIONS_SUM_TITLE_ASC',
  ConversationsSumTitleDesc = 'CONVERSATIONS_SUM_TITLE_DESC',
  ConversationsSumUpdatedAtAsc = 'CONVERSATIONS_SUM_UPDATED_AT_ASC',
  ConversationsSumUpdatedAtDesc = 'CONVERSATIONS_SUM_UPDATED_AT_DESC',
  ConversationsVariancePopulationAboutCommentableIdAsc = 'CONVERSATIONS_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsVariancePopulationAboutCommentableIdDesc = 'CONVERSATIONS_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsVariancePopulationAbstractAsc = 'CONVERSATIONS_VARIANCE_POPULATION_ABSTRACT_ASC',
  ConversationsVariancePopulationAbstractDesc = 'CONVERSATIONS_VARIANCE_POPULATION_ABSTRACT_DESC',
  ConversationsVariancePopulationCreatedAtAsc = 'CONVERSATIONS_VARIANCE_POPULATION_CREATED_AT_ASC',
  ConversationsVariancePopulationCreatedAtDesc = 'CONVERSATIONS_VARIANCE_POPULATION_CREATED_AT_DESC',
  ConversationsVariancePopulationCreatorIdAsc = 'CONVERSATIONS_VARIANCE_POPULATION_CREATOR_ID_ASC',
  ConversationsVariancePopulationCreatorIdDesc = 'CONVERSATIONS_VARIANCE_POPULATION_CREATOR_ID_DESC',
  ConversationsVariancePopulationIdAsc = 'CONVERSATIONS_VARIANCE_POPULATION_ID_ASC',
  ConversationsVariancePopulationIdDesc = 'CONVERSATIONS_VARIANCE_POPULATION_ID_DESC',
  ConversationsVariancePopulationIsPublicAsc = 'CONVERSATIONS_VARIANCE_POPULATION_IS_PUBLIC_ASC',
  ConversationsVariancePopulationIsPublicDesc = 'CONVERSATIONS_VARIANCE_POPULATION_IS_PUBLIC_DESC',
  ConversationsVariancePopulationLanguageAsc = 'CONVERSATIONS_VARIANCE_POPULATION_LANGUAGE_ASC',
  ConversationsVariancePopulationLanguageDesc = 'CONVERSATIONS_VARIANCE_POPULATION_LANGUAGE_DESC',
  ConversationsVariancePopulationPrivateToGroupIdAsc = 'CONVERSATIONS_VARIANCE_POPULATION_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsVariancePopulationPrivateToGroupIdDesc = 'CONVERSATIONS_VARIANCE_POPULATION_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsVariancePopulationTenantIdAsc = 'CONVERSATIONS_VARIANCE_POPULATION_TENANT_ID_ASC',
  ConversationsVariancePopulationTenantIdDesc = 'CONVERSATIONS_VARIANCE_POPULATION_TENANT_ID_DESC',
  ConversationsVariancePopulationTitleAsc = 'CONVERSATIONS_VARIANCE_POPULATION_TITLE_ASC',
  ConversationsVariancePopulationTitleDesc = 'CONVERSATIONS_VARIANCE_POPULATION_TITLE_DESC',
  ConversationsVariancePopulationUpdatedAtAsc = 'CONVERSATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ConversationsVariancePopulationUpdatedAtDesc = 'CONVERSATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ConversationsVarianceSampleAboutCommentableIdAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsVarianceSampleAboutCommentableIdDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsVarianceSampleAbstractAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_ABSTRACT_ASC',
  ConversationsVarianceSampleAbstractDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_ABSTRACT_DESC',
  ConversationsVarianceSampleCreatedAtAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ConversationsVarianceSampleCreatedAtDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ConversationsVarianceSampleCreatorIdAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_CREATOR_ID_ASC',
  ConversationsVarianceSampleCreatorIdDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_CREATOR_ID_DESC',
  ConversationsVarianceSampleIdAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_ID_ASC',
  ConversationsVarianceSampleIdDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_ID_DESC',
  ConversationsVarianceSampleIsPublicAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_IS_PUBLIC_ASC',
  ConversationsVarianceSampleIsPublicDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_IS_PUBLIC_DESC',
  ConversationsVarianceSampleLanguageAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_LANGUAGE_ASC',
  ConversationsVarianceSampleLanguageDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_LANGUAGE_DESC',
  ConversationsVarianceSamplePrivateToGroupIdAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsVarianceSamplePrivateToGroupIdDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsVarianceSampleTenantIdAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_TENANT_ID_ASC',
  ConversationsVarianceSampleTenantIdDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_TENANT_ID_DESC',
  ConversationsVarianceSampleTitleAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_TITLE_ASC',
  ConversationsVarianceSampleTitleDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_TITLE_DESC',
  ConversationsVarianceSampleUpdatedAtAsc = 'CONVERSATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ConversationsVarianceSampleUpdatedAtDesc = 'CONVERSATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GroupsAverageCanJoinAsc = 'GROUPS_AVERAGE_CAN_JOIN_ASC',
  GroupsAverageCanJoinDesc = 'GROUPS_AVERAGE_CAN_JOIN_DESC',
  GroupsAverageCanManageAsc = 'GROUPS_AVERAGE_CAN_MANAGE_ASC',
  GroupsAverageCanManageDesc = 'GROUPS_AVERAGE_CAN_MANAGE_DESC',
  GroupsAverageCreatedAtAsc = 'GROUPS_AVERAGE_CREATED_AT_ASC',
  GroupsAverageCreatedAtDesc = 'GROUPS_AVERAGE_CREATED_AT_DESC',
  GroupsAverageCreatorIdAsc = 'GROUPS_AVERAGE_CREATOR_ID_ASC',
  GroupsAverageCreatorIdDesc = 'GROUPS_AVERAGE_CREATOR_ID_DESC',
  GroupsAverageExternalNamesAsc = 'GROUPS_AVERAGE_EXTERNAL_NAMES_ASC',
  GroupsAverageExternalNamesDesc = 'GROUPS_AVERAGE_EXTERNAL_NAMES_DESC',
  GroupsAverageFlagsAsc = 'GROUPS_AVERAGE_FLAGS_ASC',
  GroupsAverageFlagsDesc = 'GROUPS_AVERAGE_FLAGS_DESC',
  GroupsAverageHomeIdAsc = 'GROUPS_AVERAGE_HOME_ID_ASC',
  GroupsAverageHomeIdDesc = 'GROUPS_AVERAGE_HOME_ID_DESC',
  GroupsAverageIdAsc = 'GROUPS_AVERAGE_ID_ASC',
  GroupsAverageIdDesc = 'GROUPS_AVERAGE_ID_DESC',
  GroupsAverageNameAsc = 'GROUPS_AVERAGE_NAME_ASC',
  GroupsAverageNameDesc = 'GROUPS_AVERAGE_NAME_DESC',
  GroupsAverageTenantIdAsc = 'GROUPS_AVERAGE_TENANT_ID_ASC',
  GroupsAverageTenantIdDesc = 'GROUPS_AVERAGE_TENANT_ID_DESC',
  GroupsAverageUpdatedAtAsc = 'GROUPS_AVERAGE_UPDATED_AT_ASC',
  GroupsAverageUpdatedAtDesc = 'GROUPS_AVERAGE_UPDATED_AT_DESC',
  GroupsCountAsc = 'GROUPS_COUNT_ASC',
  GroupsCountDesc = 'GROUPS_COUNT_DESC',
  GroupsDistinctCountCanJoinAsc = 'GROUPS_DISTINCT_COUNT_CAN_JOIN_ASC',
  GroupsDistinctCountCanJoinDesc = 'GROUPS_DISTINCT_COUNT_CAN_JOIN_DESC',
  GroupsDistinctCountCanManageAsc = 'GROUPS_DISTINCT_COUNT_CAN_MANAGE_ASC',
  GroupsDistinctCountCanManageDesc = 'GROUPS_DISTINCT_COUNT_CAN_MANAGE_DESC',
  GroupsDistinctCountCreatedAtAsc = 'GROUPS_DISTINCT_COUNT_CREATED_AT_ASC',
  GroupsDistinctCountCreatedAtDesc = 'GROUPS_DISTINCT_COUNT_CREATED_AT_DESC',
  GroupsDistinctCountCreatorIdAsc = 'GROUPS_DISTINCT_COUNT_CREATOR_ID_ASC',
  GroupsDistinctCountCreatorIdDesc = 'GROUPS_DISTINCT_COUNT_CREATOR_ID_DESC',
  GroupsDistinctCountExternalNamesAsc = 'GROUPS_DISTINCT_COUNT_EXTERNAL_NAMES_ASC',
  GroupsDistinctCountExternalNamesDesc = 'GROUPS_DISTINCT_COUNT_EXTERNAL_NAMES_DESC',
  GroupsDistinctCountFlagsAsc = 'GROUPS_DISTINCT_COUNT_FLAGS_ASC',
  GroupsDistinctCountFlagsDesc = 'GROUPS_DISTINCT_COUNT_FLAGS_DESC',
  GroupsDistinctCountHomeIdAsc = 'GROUPS_DISTINCT_COUNT_HOME_ID_ASC',
  GroupsDistinctCountHomeIdDesc = 'GROUPS_DISTINCT_COUNT_HOME_ID_DESC',
  GroupsDistinctCountIdAsc = 'GROUPS_DISTINCT_COUNT_ID_ASC',
  GroupsDistinctCountIdDesc = 'GROUPS_DISTINCT_COUNT_ID_DESC',
  GroupsDistinctCountNameAsc = 'GROUPS_DISTINCT_COUNT_NAME_ASC',
  GroupsDistinctCountNameDesc = 'GROUPS_DISTINCT_COUNT_NAME_DESC',
  GroupsDistinctCountTenantIdAsc = 'GROUPS_DISTINCT_COUNT_TENANT_ID_ASC',
  GroupsDistinctCountTenantIdDesc = 'GROUPS_DISTINCT_COUNT_TENANT_ID_DESC',
  GroupsDistinctCountUpdatedAtAsc = 'GROUPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  GroupsDistinctCountUpdatedAtDesc = 'GROUPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  GroupsMaxCanJoinAsc = 'GROUPS_MAX_CAN_JOIN_ASC',
  GroupsMaxCanJoinDesc = 'GROUPS_MAX_CAN_JOIN_DESC',
  GroupsMaxCanManageAsc = 'GROUPS_MAX_CAN_MANAGE_ASC',
  GroupsMaxCanManageDesc = 'GROUPS_MAX_CAN_MANAGE_DESC',
  GroupsMaxCreatedAtAsc = 'GROUPS_MAX_CREATED_AT_ASC',
  GroupsMaxCreatedAtDesc = 'GROUPS_MAX_CREATED_AT_DESC',
  GroupsMaxCreatorIdAsc = 'GROUPS_MAX_CREATOR_ID_ASC',
  GroupsMaxCreatorIdDesc = 'GROUPS_MAX_CREATOR_ID_DESC',
  GroupsMaxExternalNamesAsc = 'GROUPS_MAX_EXTERNAL_NAMES_ASC',
  GroupsMaxExternalNamesDesc = 'GROUPS_MAX_EXTERNAL_NAMES_DESC',
  GroupsMaxFlagsAsc = 'GROUPS_MAX_FLAGS_ASC',
  GroupsMaxFlagsDesc = 'GROUPS_MAX_FLAGS_DESC',
  GroupsMaxHomeIdAsc = 'GROUPS_MAX_HOME_ID_ASC',
  GroupsMaxHomeIdDesc = 'GROUPS_MAX_HOME_ID_DESC',
  GroupsMaxIdAsc = 'GROUPS_MAX_ID_ASC',
  GroupsMaxIdDesc = 'GROUPS_MAX_ID_DESC',
  GroupsMaxNameAsc = 'GROUPS_MAX_NAME_ASC',
  GroupsMaxNameDesc = 'GROUPS_MAX_NAME_DESC',
  GroupsMaxTenantIdAsc = 'GROUPS_MAX_TENANT_ID_ASC',
  GroupsMaxTenantIdDesc = 'GROUPS_MAX_TENANT_ID_DESC',
  GroupsMaxUpdatedAtAsc = 'GROUPS_MAX_UPDATED_AT_ASC',
  GroupsMaxUpdatedAtDesc = 'GROUPS_MAX_UPDATED_AT_DESC',
  GroupsMinCanJoinAsc = 'GROUPS_MIN_CAN_JOIN_ASC',
  GroupsMinCanJoinDesc = 'GROUPS_MIN_CAN_JOIN_DESC',
  GroupsMinCanManageAsc = 'GROUPS_MIN_CAN_MANAGE_ASC',
  GroupsMinCanManageDesc = 'GROUPS_MIN_CAN_MANAGE_DESC',
  GroupsMinCreatedAtAsc = 'GROUPS_MIN_CREATED_AT_ASC',
  GroupsMinCreatedAtDesc = 'GROUPS_MIN_CREATED_AT_DESC',
  GroupsMinCreatorIdAsc = 'GROUPS_MIN_CREATOR_ID_ASC',
  GroupsMinCreatorIdDesc = 'GROUPS_MIN_CREATOR_ID_DESC',
  GroupsMinExternalNamesAsc = 'GROUPS_MIN_EXTERNAL_NAMES_ASC',
  GroupsMinExternalNamesDesc = 'GROUPS_MIN_EXTERNAL_NAMES_DESC',
  GroupsMinFlagsAsc = 'GROUPS_MIN_FLAGS_ASC',
  GroupsMinFlagsDesc = 'GROUPS_MIN_FLAGS_DESC',
  GroupsMinHomeIdAsc = 'GROUPS_MIN_HOME_ID_ASC',
  GroupsMinHomeIdDesc = 'GROUPS_MIN_HOME_ID_DESC',
  GroupsMinIdAsc = 'GROUPS_MIN_ID_ASC',
  GroupsMinIdDesc = 'GROUPS_MIN_ID_DESC',
  GroupsMinNameAsc = 'GROUPS_MIN_NAME_ASC',
  GroupsMinNameDesc = 'GROUPS_MIN_NAME_DESC',
  GroupsMinTenantIdAsc = 'GROUPS_MIN_TENANT_ID_ASC',
  GroupsMinTenantIdDesc = 'GROUPS_MIN_TENANT_ID_DESC',
  GroupsMinUpdatedAtAsc = 'GROUPS_MIN_UPDATED_AT_ASC',
  GroupsMinUpdatedAtDesc = 'GROUPS_MIN_UPDATED_AT_DESC',
  GroupsStddevPopulationCanJoinAsc = 'GROUPS_STDDEV_POPULATION_CAN_JOIN_ASC',
  GroupsStddevPopulationCanJoinDesc = 'GROUPS_STDDEV_POPULATION_CAN_JOIN_DESC',
  GroupsStddevPopulationCanManageAsc = 'GROUPS_STDDEV_POPULATION_CAN_MANAGE_ASC',
  GroupsStddevPopulationCanManageDesc = 'GROUPS_STDDEV_POPULATION_CAN_MANAGE_DESC',
  GroupsStddevPopulationCreatedAtAsc = 'GROUPS_STDDEV_POPULATION_CREATED_AT_ASC',
  GroupsStddevPopulationCreatedAtDesc = 'GROUPS_STDDEV_POPULATION_CREATED_AT_DESC',
  GroupsStddevPopulationCreatorIdAsc = 'GROUPS_STDDEV_POPULATION_CREATOR_ID_ASC',
  GroupsStddevPopulationCreatorIdDesc = 'GROUPS_STDDEV_POPULATION_CREATOR_ID_DESC',
  GroupsStddevPopulationExternalNamesAsc = 'GROUPS_STDDEV_POPULATION_EXTERNAL_NAMES_ASC',
  GroupsStddevPopulationExternalNamesDesc = 'GROUPS_STDDEV_POPULATION_EXTERNAL_NAMES_DESC',
  GroupsStddevPopulationFlagsAsc = 'GROUPS_STDDEV_POPULATION_FLAGS_ASC',
  GroupsStddevPopulationFlagsDesc = 'GROUPS_STDDEV_POPULATION_FLAGS_DESC',
  GroupsStddevPopulationHomeIdAsc = 'GROUPS_STDDEV_POPULATION_HOME_ID_ASC',
  GroupsStddevPopulationHomeIdDesc = 'GROUPS_STDDEV_POPULATION_HOME_ID_DESC',
  GroupsStddevPopulationIdAsc = 'GROUPS_STDDEV_POPULATION_ID_ASC',
  GroupsStddevPopulationIdDesc = 'GROUPS_STDDEV_POPULATION_ID_DESC',
  GroupsStddevPopulationNameAsc = 'GROUPS_STDDEV_POPULATION_NAME_ASC',
  GroupsStddevPopulationNameDesc = 'GROUPS_STDDEV_POPULATION_NAME_DESC',
  GroupsStddevPopulationTenantIdAsc = 'GROUPS_STDDEV_POPULATION_TENANT_ID_ASC',
  GroupsStddevPopulationTenantIdDesc = 'GROUPS_STDDEV_POPULATION_TENANT_ID_DESC',
  GroupsStddevPopulationUpdatedAtAsc = 'GROUPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  GroupsStddevPopulationUpdatedAtDesc = 'GROUPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  GroupsStddevSampleCanJoinAsc = 'GROUPS_STDDEV_SAMPLE_CAN_JOIN_ASC',
  GroupsStddevSampleCanJoinDesc = 'GROUPS_STDDEV_SAMPLE_CAN_JOIN_DESC',
  GroupsStddevSampleCanManageAsc = 'GROUPS_STDDEV_SAMPLE_CAN_MANAGE_ASC',
  GroupsStddevSampleCanManageDesc = 'GROUPS_STDDEV_SAMPLE_CAN_MANAGE_DESC',
  GroupsStddevSampleCreatedAtAsc = 'GROUPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  GroupsStddevSampleCreatedAtDesc = 'GROUPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  GroupsStddevSampleCreatorIdAsc = 'GROUPS_STDDEV_SAMPLE_CREATOR_ID_ASC',
  GroupsStddevSampleCreatorIdDesc = 'GROUPS_STDDEV_SAMPLE_CREATOR_ID_DESC',
  GroupsStddevSampleExternalNamesAsc = 'GROUPS_STDDEV_SAMPLE_EXTERNAL_NAMES_ASC',
  GroupsStddevSampleExternalNamesDesc = 'GROUPS_STDDEV_SAMPLE_EXTERNAL_NAMES_DESC',
  GroupsStddevSampleFlagsAsc = 'GROUPS_STDDEV_SAMPLE_FLAGS_ASC',
  GroupsStddevSampleFlagsDesc = 'GROUPS_STDDEV_SAMPLE_FLAGS_DESC',
  GroupsStddevSampleHomeIdAsc = 'GROUPS_STDDEV_SAMPLE_HOME_ID_ASC',
  GroupsStddevSampleHomeIdDesc = 'GROUPS_STDDEV_SAMPLE_HOME_ID_DESC',
  GroupsStddevSampleIdAsc = 'GROUPS_STDDEV_SAMPLE_ID_ASC',
  GroupsStddevSampleIdDesc = 'GROUPS_STDDEV_SAMPLE_ID_DESC',
  GroupsStddevSampleNameAsc = 'GROUPS_STDDEV_SAMPLE_NAME_ASC',
  GroupsStddevSampleNameDesc = 'GROUPS_STDDEV_SAMPLE_NAME_DESC',
  GroupsStddevSampleTenantIdAsc = 'GROUPS_STDDEV_SAMPLE_TENANT_ID_ASC',
  GroupsStddevSampleTenantIdDesc = 'GROUPS_STDDEV_SAMPLE_TENANT_ID_DESC',
  GroupsStddevSampleUpdatedAtAsc = 'GROUPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  GroupsStddevSampleUpdatedAtDesc = 'GROUPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  GroupsSumCanJoinAsc = 'GROUPS_SUM_CAN_JOIN_ASC',
  GroupsSumCanJoinDesc = 'GROUPS_SUM_CAN_JOIN_DESC',
  GroupsSumCanManageAsc = 'GROUPS_SUM_CAN_MANAGE_ASC',
  GroupsSumCanManageDesc = 'GROUPS_SUM_CAN_MANAGE_DESC',
  GroupsSumCreatedAtAsc = 'GROUPS_SUM_CREATED_AT_ASC',
  GroupsSumCreatedAtDesc = 'GROUPS_SUM_CREATED_AT_DESC',
  GroupsSumCreatorIdAsc = 'GROUPS_SUM_CREATOR_ID_ASC',
  GroupsSumCreatorIdDesc = 'GROUPS_SUM_CREATOR_ID_DESC',
  GroupsSumExternalNamesAsc = 'GROUPS_SUM_EXTERNAL_NAMES_ASC',
  GroupsSumExternalNamesDesc = 'GROUPS_SUM_EXTERNAL_NAMES_DESC',
  GroupsSumFlagsAsc = 'GROUPS_SUM_FLAGS_ASC',
  GroupsSumFlagsDesc = 'GROUPS_SUM_FLAGS_DESC',
  GroupsSumHomeIdAsc = 'GROUPS_SUM_HOME_ID_ASC',
  GroupsSumHomeIdDesc = 'GROUPS_SUM_HOME_ID_DESC',
  GroupsSumIdAsc = 'GROUPS_SUM_ID_ASC',
  GroupsSumIdDesc = 'GROUPS_SUM_ID_DESC',
  GroupsSumNameAsc = 'GROUPS_SUM_NAME_ASC',
  GroupsSumNameDesc = 'GROUPS_SUM_NAME_DESC',
  GroupsSumTenantIdAsc = 'GROUPS_SUM_TENANT_ID_ASC',
  GroupsSumTenantIdDesc = 'GROUPS_SUM_TENANT_ID_DESC',
  GroupsSumUpdatedAtAsc = 'GROUPS_SUM_UPDATED_AT_ASC',
  GroupsSumUpdatedAtDesc = 'GROUPS_SUM_UPDATED_AT_DESC',
  GroupsVariancePopulationCanJoinAsc = 'GROUPS_VARIANCE_POPULATION_CAN_JOIN_ASC',
  GroupsVariancePopulationCanJoinDesc = 'GROUPS_VARIANCE_POPULATION_CAN_JOIN_DESC',
  GroupsVariancePopulationCanManageAsc = 'GROUPS_VARIANCE_POPULATION_CAN_MANAGE_ASC',
  GroupsVariancePopulationCanManageDesc = 'GROUPS_VARIANCE_POPULATION_CAN_MANAGE_DESC',
  GroupsVariancePopulationCreatedAtAsc = 'GROUPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  GroupsVariancePopulationCreatedAtDesc = 'GROUPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  GroupsVariancePopulationCreatorIdAsc = 'GROUPS_VARIANCE_POPULATION_CREATOR_ID_ASC',
  GroupsVariancePopulationCreatorIdDesc = 'GROUPS_VARIANCE_POPULATION_CREATOR_ID_DESC',
  GroupsVariancePopulationExternalNamesAsc = 'GROUPS_VARIANCE_POPULATION_EXTERNAL_NAMES_ASC',
  GroupsVariancePopulationExternalNamesDesc = 'GROUPS_VARIANCE_POPULATION_EXTERNAL_NAMES_DESC',
  GroupsVariancePopulationFlagsAsc = 'GROUPS_VARIANCE_POPULATION_FLAGS_ASC',
  GroupsVariancePopulationFlagsDesc = 'GROUPS_VARIANCE_POPULATION_FLAGS_DESC',
  GroupsVariancePopulationHomeIdAsc = 'GROUPS_VARIANCE_POPULATION_HOME_ID_ASC',
  GroupsVariancePopulationHomeIdDesc = 'GROUPS_VARIANCE_POPULATION_HOME_ID_DESC',
  GroupsVariancePopulationIdAsc = 'GROUPS_VARIANCE_POPULATION_ID_ASC',
  GroupsVariancePopulationIdDesc = 'GROUPS_VARIANCE_POPULATION_ID_DESC',
  GroupsVariancePopulationNameAsc = 'GROUPS_VARIANCE_POPULATION_NAME_ASC',
  GroupsVariancePopulationNameDesc = 'GROUPS_VARIANCE_POPULATION_NAME_DESC',
  GroupsVariancePopulationTenantIdAsc = 'GROUPS_VARIANCE_POPULATION_TENANT_ID_ASC',
  GroupsVariancePopulationTenantIdDesc = 'GROUPS_VARIANCE_POPULATION_TENANT_ID_DESC',
  GroupsVariancePopulationUpdatedAtAsc = 'GROUPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  GroupsVariancePopulationUpdatedAtDesc = 'GROUPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  GroupsVarianceSampleCanJoinAsc = 'GROUPS_VARIANCE_SAMPLE_CAN_JOIN_ASC',
  GroupsVarianceSampleCanJoinDesc = 'GROUPS_VARIANCE_SAMPLE_CAN_JOIN_DESC',
  GroupsVarianceSampleCanManageAsc = 'GROUPS_VARIANCE_SAMPLE_CAN_MANAGE_ASC',
  GroupsVarianceSampleCanManageDesc = 'GROUPS_VARIANCE_SAMPLE_CAN_MANAGE_DESC',
  GroupsVarianceSampleCreatedAtAsc = 'GROUPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GroupsVarianceSampleCreatedAtDesc = 'GROUPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GroupsVarianceSampleCreatorIdAsc = 'GROUPS_VARIANCE_SAMPLE_CREATOR_ID_ASC',
  GroupsVarianceSampleCreatorIdDesc = 'GROUPS_VARIANCE_SAMPLE_CREATOR_ID_DESC',
  GroupsVarianceSampleExternalNamesAsc = 'GROUPS_VARIANCE_SAMPLE_EXTERNAL_NAMES_ASC',
  GroupsVarianceSampleExternalNamesDesc = 'GROUPS_VARIANCE_SAMPLE_EXTERNAL_NAMES_DESC',
  GroupsVarianceSampleFlagsAsc = 'GROUPS_VARIANCE_SAMPLE_FLAGS_ASC',
  GroupsVarianceSampleFlagsDesc = 'GROUPS_VARIANCE_SAMPLE_FLAGS_DESC',
  GroupsVarianceSampleHomeIdAsc = 'GROUPS_VARIANCE_SAMPLE_HOME_ID_ASC',
  GroupsVarianceSampleHomeIdDesc = 'GROUPS_VARIANCE_SAMPLE_HOME_ID_DESC',
  GroupsVarianceSampleIdAsc = 'GROUPS_VARIANCE_SAMPLE_ID_ASC',
  GroupsVarianceSampleIdDesc = 'GROUPS_VARIANCE_SAMPLE_ID_DESC',
  GroupsVarianceSampleNameAsc = 'GROUPS_VARIANCE_SAMPLE_NAME_ASC',
  GroupsVarianceSampleNameDesc = 'GROUPS_VARIANCE_SAMPLE_NAME_DESC',
  GroupsVarianceSampleTenantIdAsc = 'GROUPS_VARIANCE_SAMPLE_TENANT_ID_ASC',
  GroupsVarianceSampleTenantIdDesc = 'GROUPS_VARIANCE_SAMPLE_TENANT_ID_DESC',
  GroupsVarianceSampleUpdatedAtAsc = 'GROUPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  GroupsVarianceSampleUpdatedAtDesc = 'GROUPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  KeycloakRealmUrlAsc = 'KEYCLOAK_REALM_URL_ASC',
  KeycloakRealmUrlDesc = 'KEYCLOAK_REALM_URL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TrialsAverageAvatarAsc = 'TRIALS_AVERAGE_AVATAR_ASC',
  TrialsAverageAvatarDesc = 'TRIALS_AVERAGE_AVATAR_DESC',
  TrialsAverageCannotUseTheInterventionAsc = 'TRIALS_AVERAGE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsAverageCannotUseTheInterventionDesc = 'TRIALS_AVERAGE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsAverageCanAccessErSinceAsc = 'TRIALS_AVERAGE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsAverageCanAccessErSinceDesc = 'TRIALS_AVERAGE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsAverageCanAccessSipSinceAsc = 'TRIALS_AVERAGE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsAverageCanAccessSipSinceDesc = 'TRIALS_AVERAGE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsAverageCanBeIncludedAsc = 'TRIALS_AVERAGE_CAN_BE_INCLUDED_ASC',
  TrialsAverageCanBeIncludedDesc = 'TRIALS_AVERAGE_CAN_BE_INCLUDED_DESC',
  TrialsAverageCreatedAtAsc = 'TRIALS_AVERAGE_CREATED_AT_ASC',
  TrialsAverageCreatedAtDesc = 'TRIALS_AVERAGE_CREATED_AT_DESC',
  TrialsAverageCreatedByIdAsc = 'TRIALS_AVERAGE_CREATED_BY_ID_ASC',
  TrialsAverageCreatedByIdDesc = 'TRIALS_AVERAGE_CREATED_BY_ID_DESC',
  TrialsAverageEmergencyPhoneNumbersAsc = 'TRIALS_AVERAGE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsAverageEmergencyPhoneNumbersDesc = 'TRIALS_AVERAGE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsAverageHasAccessToASmartphoneAsc = 'TRIALS_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsAverageHasAccessToASmartphoneDesc = 'TRIALS_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsAverageHasAdequateKnowledgeOfGermanAsc = 'TRIALS_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsAverageHasAdequateKnowledgeOfGermanDesc = 'TRIALS_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsAverageHasAgeBetween_18And_25Asc = 'TRIALS_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsAverageHasAgeBetween_18And_25Desc = 'TRIALS_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsAverageHasCurrentSuicidalIdeationAsc = 'TRIALS_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsAverageHasCurrentSuicidalIdeationDesc = 'TRIALS_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsAverageHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsAverageHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsAverageHasGivenInformedConsentAtAsc = 'TRIALS_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsAverageHasGivenInformedConsentAtDesc = 'TRIALS_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsAverageHumanReadableIdAsc = 'TRIALS_AVERAGE_HUMAN_READABLE_ID_ASC',
  TrialsAverageHumanReadableIdDesc = 'TRIALS_AVERAGE_HUMAN_READABLE_ID_DESC',
  TrialsAverageIdAsc = 'TRIALS_AVERAGE_ID_ASC',
  TrialsAverageIdDesc = 'TRIALS_AVERAGE_ID_DESC',
  TrialsAverageInternalMessagesIdAsc = 'TRIALS_AVERAGE_INTERNAL_MESSAGES_ID_ASC',
  TrialsAverageInternalMessagesIdDesc = 'TRIALS_AVERAGE_INTERNAL_MESSAGES_ID_DESC',
  TrialsAverageMeetsAllInclusionCriteriaAsc = 'TRIALS_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsAverageMeetsAllInclusionCriteriaDesc = 'TRIALS_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsAverageMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsAverageMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsAverageMeetsAnyExclusionCriteriaAsc = 'TRIALS_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsAverageMeetsAnyExclusionCriteriaDesc = 'TRIALS_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsAverageMessagesIdAsc = 'TRIALS_AVERAGE_MESSAGES_ID_ASC',
  TrialsAverageMessagesIdDesc = 'TRIALS_AVERAGE_MESSAGES_ID_DESC',
  TrialsAverageNameAsc = 'TRIALS_AVERAGE_NAME_ASC',
  TrialsAverageNameDesc = 'TRIALS_AVERAGE_NAME_DESC',
  TrialsAverageStaffIdAsc = 'TRIALS_AVERAGE_STAFF_ID_ASC',
  TrialsAverageStaffIdDesc = 'TRIALS_AVERAGE_STAFF_ID_DESC',
  TrialsAverageSubjectsNicknameAsc = 'TRIALS_AVERAGE_SUBJECTS_NICKNAME_ASC',
  TrialsAverageSubjectsNicknameDesc = 'TRIALS_AVERAGE_SUBJECTS_NICKNAME_DESC',
  TrialsAverageSubjectIdAsc = 'TRIALS_AVERAGE_SUBJECT_ID_ASC',
  TrialsAverageSubjectIdDesc = 'TRIALS_AVERAGE_SUBJECT_ID_DESC',
  TrialsAverageTenantIdAsc = 'TRIALS_AVERAGE_TENANT_ID_ASC',
  TrialsAverageTenantIdDesc = 'TRIALS_AVERAGE_TENANT_ID_DESC',
  TrialsAverageUpdatedAtAsc = 'TRIALS_AVERAGE_UPDATED_AT_ASC',
  TrialsAverageUpdatedAtDesc = 'TRIALS_AVERAGE_UPDATED_AT_DESC',
  TrialsCountAsc = 'TRIALS_COUNT_ASC',
  TrialsCountDesc = 'TRIALS_COUNT_DESC',
  TrialsDistinctCountAvatarAsc = 'TRIALS_DISTINCT_COUNT_AVATAR_ASC',
  TrialsDistinctCountAvatarDesc = 'TRIALS_DISTINCT_COUNT_AVATAR_DESC',
  TrialsDistinctCountCannotUseTheInterventionAsc = 'TRIALS_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsDistinctCountCannotUseTheInterventionDesc = 'TRIALS_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsDistinctCountCanAccessErSinceAsc = 'TRIALS_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_ASC',
  TrialsDistinctCountCanAccessErSinceDesc = 'TRIALS_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_DESC',
  TrialsDistinctCountCanAccessSipSinceAsc = 'TRIALS_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsDistinctCountCanAccessSipSinceDesc = 'TRIALS_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsDistinctCountCanBeIncludedAsc = 'TRIALS_DISTINCT_COUNT_CAN_BE_INCLUDED_ASC',
  TrialsDistinctCountCanBeIncludedDesc = 'TRIALS_DISTINCT_COUNT_CAN_BE_INCLUDED_DESC',
  TrialsDistinctCountCreatedAtAsc = 'TRIALS_DISTINCT_COUNT_CREATED_AT_ASC',
  TrialsDistinctCountCreatedAtDesc = 'TRIALS_DISTINCT_COUNT_CREATED_AT_DESC',
  TrialsDistinctCountCreatedByIdAsc = 'TRIALS_DISTINCT_COUNT_CREATED_BY_ID_ASC',
  TrialsDistinctCountCreatedByIdDesc = 'TRIALS_DISTINCT_COUNT_CREATED_BY_ID_DESC',
  TrialsDistinctCountEmergencyPhoneNumbersAsc = 'TRIALS_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsDistinctCountEmergencyPhoneNumbersDesc = 'TRIALS_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsDistinctCountHasAccessToASmartphoneAsc = 'TRIALS_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsDistinctCountHasAccessToASmartphoneDesc = 'TRIALS_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsDistinctCountHasAdequateKnowledgeOfGermanAsc = 'TRIALS_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsDistinctCountHasAdequateKnowledgeOfGermanDesc = 'TRIALS_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsDistinctCountHasAgeBetween_18And_25Asc = 'TRIALS_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsDistinctCountHasAgeBetween_18And_25Desc = 'TRIALS_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsDistinctCountHasCurrentSuicidalIdeationAsc = 'TRIALS_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsDistinctCountHasCurrentSuicidalIdeationDesc = 'TRIALS_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsDistinctCountHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsDistinctCountHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsDistinctCountHasGivenInformedConsentAtAsc = 'TRIALS_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsDistinctCountHasGivenInformedConsentAtDesc = 'TRIALS_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsDistinctCountHumanReadableIdAsc = 'TRIALS_DISTINCT_COUNT_HUMAN_READABLE_ID_ASC',
  TrialsDistinctCountHumanReadableIdDesc = 'TRIALS_DISTINCT_COUNT_HUMAN_READABLE_ID_DESC',
  TrialsDistinctCountIdAsc = 'TRIALS_DISTINCT_COUNT_ID_ASC',
  TrialsDistinctCountIdDesc = 'TRIALS_DISTINCT_COUNT_ID_DESC',
  TrialsDistinctCountInternalMessagesIdAsc = 'TRIALS_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_ASC',
  TrialsDistinctCountInternalMessagesIdDesc = 'TRIALS_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_DESC',
  TrialsDistinctCountMeetsAllInclusionCriteriaAsc = 'TRIALS_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsDistinctCountMeetsAllInclusionCriteriaDesc = 'TRIALS_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsDistinctCountMeetsAnyExclusionCriteriaAsc = 'TRIALS_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsDistinctCountMeetsAnyExclusionCriteriaDesc = 'TRIALS_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsDistinctCountMessagesIdAsc = 'TRIALS_DISTINCT_COUNT_MESSAGES_ID_ASC',
  TrialsDistinctCountMessagesIdDesc = 'TRIALS_DISTINCT_COUNT_MESSAGES_ID_DESC',
  TrialsDistinctCountNameAsc = 'TRIALS_DISTINCT_COUNT_NAME_ASC',
  TrialsDistinctCountNameDesc = 'TRIALS_DISTINCT_COUNT_NAME_DESC',
  TrialsDistinctCountStaffIdAsc = 'TRIALS_DISTINCT_COUNT_STAFF_ID_ASC',
  TrialsDistinctCountStaffIdDesc = 'TRIALS_DISTINCT_COUNT_STAFF_ID_DESC',
  TrialsDistinctCountSubjectsNicknameAsc = 'TRIALS_DISTINCT_COUNT_SUBJECTS_NICKNAME_ASC',
  TrialsDistinctCountSubjectsNicknameDesc = 'TRIALS_DISTINCT_COUNT_SUBJECTS_NICKNAME_DESC',
  TrialsDistinctCountSubjectIdAsc = 'TRIALS_DISTINCT_COUNT_SUBJECT_ID_ASC',
  TrialsDistinctCountSubjectIdDesc = 'TRIALS_DISTINCT_COUNT_SUBJECT_ID_DESC',
  TrialsDistinctCountTenantIdAsc = 'TRIALS_DISTINCT_COUNT_TENANT_ID_ASC',
  TrialsDistinctCountTenantIdDesc = 'TRIALS_DISTINCT_COUNT_TENANT_ID_DESC',
  TrialsDistinctCountUpdatedAtAsc = 'TRIALS_DISTINCT_COUNT_UPDATED_AT_ASC',
  TrialsDistinctCountUpdatedAtDesc = 'TRIALS_DISTINCT_COUNT_UPDATED_AT_DESC',
  TrialsMaxAvatarAsc = 'TRIALS_MAX_AVATAR_ASC',
  TrialsMaxAvatarDesc = 'TRIALS_MAX_AVATAR_DESC',
  TrialsMaxCannotUseTheInterventionAsc = 'TRIALS_MAX_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsMaxCannotUseTheInterventionDesc = 'TRIALS_MAX_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsMaxCanAccessErSinceAsc = 'TRIALS_MAX_CAN_ACCESS_ER_SINCE_ASC',
  TrialsMaxCanAccessErSinceDesc = 'TRIALS_MAX_CAN_ACCESS_ER_SINCE_DESC',
  TrialsMaxCanAccessSipSinceAsc = 'TRIALS_MAX_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsMaxCanAccessSipSinceDesc = 'TRIALS_MAX_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsMaxCanBeIncludedAsc = 'TRIALS_MAX_CAN_BE_INCLUDED_ASC',
  TrialsMaxCanBeIncludedDesc = 'TRIALS_MAX_CAN_BE_INCLUDED_DESC',
  TrialsMaxCreatedAtAsc = 'TRIALS_MAX_CREATED_AT_ASC',
  TrialsMaxCreatedAtDesc = 'TRIALS_MAX_CREATED_AT_DESC',
  TrialsMaxCreatedByIdAsc = 'TRIALS_MAX_CREATED_BY_ID_ASC',
  TrialsMaxCreatedByIdDesc = 'TRIALS_MAX_CREATED_BY_ID_DESC',
  TrialsMaxEmergencyPhoneNumbersAsc = 'TRIALS_MAX_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsMaxEmergencyPhoneNumbersDesc = 'TRIALS_MAX_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsMaxHasAccessToASmartphoneAsc = 'TRIALS_MAX_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsMaxHasAccessToASmartphoneDesc = 'TRIALS_MAX_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsMaxHasAdequateKnowledgeOfGermanAsc = 'TRIALS_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsMaxHasAdequateKnowledgeOfGermanDesc = 'TRIALS_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsMaxHasAgeBetween_18And_25Asc = 'TRIALS_MAX_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsMaxHasAgeBetween_18And_25Desc = 'TRIALS_MAX_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsMaxHasCurrentSuicidalIdeationAsc = 'TRIALS_MAX_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsMaxHasCurrentSuicidalIdeationDesc = 'TRIALS_MAX_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsMaxHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsMaxHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsMaxHasGivenInformedConsentAtAsc = 'TRIALS_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsMaxHasGivenInformedConsentAtDesc = 'TRIALS_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsMaxHumanReadableIdAsc = 'TRIALS_MAX_HUMAN_READABLE_ID_ASC',
  TrialsMaxHumanReadableIdDesc = 'TRIALS_MAX_HUMAN_READABLE_ID_DESC',
  TrialsMaxIdAsc = 'TRIALS_MAX_ID_ASC',
  TrialsMaxIdDesc = 'TRIALS_MAX_ID_DESC',
  TrialsMaxInternalMessagesIdAsc = 'TRIALS_MAX_INTERNAL_MESSAGES_ID_ASC',
  TrialsMaxInternalMessagesIdDesc = 'TRIALS_MAX_INTERNAL_MESSAGES_ID_DESC',
  TrialsMaxMeetsAllInclusionCriteriaAsc = 'TRIALS_MAX_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsMaxMeetsAllInclusionCriteriaDesc = 'TRIALS_MAX_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsMaxMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsMaxMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsMaxMeetsAnyExclusionCriteriaAsc = 'TRIALS_MAX_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsMaxMeetsAnyExclusionCriteriaDesc = 'TRIALS_MAX_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsMaxMessagesIdAsc = 'TRIALS_MAX_MESSAGES_ID_ASC',
  TrialsMaxMessagesIdDesc = 'TRIALS_MAX_MESSAGES_ID_DESC',
  TrialsMaxNameAsc = 'TRIALS_MAX_NAME_ASC',
  TrialsMaxNameDesc = 'TRIALS_MAX_NAME_DESC',
  TrialsMaxStaffIdAsc = 'TRIALS_MAX_STAFF_ID_ASC',
  TrialsMaxStaffIdDesc = 'TRIALS_MAX_STAFF_ID_DESC',
  TrialsMaxSubjectsNicknameAsc = 'TRIALS_MAX_SUBJECTS_NICKNAME_ASC',
  TrialsMaxSubjectsNicknameDesc = 'TRIALS_MAX_SUBJECTS_NICKNAME_DESC',
  TrialsMaxSubjectIdAsc = 'TRIALS_MAX_SUBJECT_ID_ASC',
  TrialsMaxSubjectIdDesc = 'TRIALS_MAX_SUBJECT_ID_DESC',
  TrialsMaxTenantIdAsc = 'TRIALS_MAX_TENANT_ID_ASC',
  TrialsMaxTenantIdDesc = 'TRIALS_MAX_TENANT_ID_DESC',
  TrialsMaxUpdatedAtAsc = 'TRIALS_MAX_UPDATED_AT_ASC',
  TrialsMaxUpdatedAtDesc = 'TRIALS_MAX_UPDATED_AT_DESC',
  TrialsMinAvatarAsc = 'TRIALS_MIN_AVATAR_ASC',
  TrialsMinAvatarDesc = 'TRIALS_MIN_AVATAR_DESC',
  TrialsMinCannotUseTheInterventionAsc = 'TRIALS_MIN_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsMinCannotUseTheInterventionDesc = 'TRIALS_MIN_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsMinCanAccessErSinceAsc = 'TRIALS_MIN_CAN_ACCESS_ER_SINCE_ASC',
  TrialsMinCanAccessErSinceDesc = 'TRIALS_MIN_CAN_ACCESS_ER_SINCE_DESC',
  TrialsMinCanAccessSipSinceAsc = 'TRIALS_MIN_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsMinCanAccessSipSinceDesc = 'TRIALS_MIN_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsMinCanBeIncludedAsc = 'TRIALS_MIN_CAN_BE_INCLUDED_ASC',
  TrialsMinCanBeIncludedDesc = 'TRIALS_MIN_CAN_BE_INCLUDED_DESC',
  TrialsMinCreatedAtAsc = 'TRIALS_MIN_CREATED_AT_ASC',
  TrialsMinCreatedAtDesc = 'TRIALS_MIN_CREATED_AT_DESC',
  TrialsMinCreatedByIdAsc = 'TRIALS_MIN_CREATED_BY_ID_ASC',
  TrialsMinCreatedByIdDesc = 'TRIALS_MIN_CREATED_BY_ID_DESC',
  TrialsMinEmergencyPhoneNumbersAsc = 'TRIALS_MIN_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsMinEmergencyPhoneNumbersDesc = 'TRIALS_MIN_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsMinHasAccessToASmartphoneAsc = 'TRIALS_MIN_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsMinHasAccessToASmartphoneDesc = 'TRIALS_MIN_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsMinHasAdequateKnowledgeOfGermanAsc = 'TRIALS_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsMinHasAdequateKnowledgeOfGermanDesc = 'TRIALS_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsMinHasAgeBetween_18And_25Asc = 'TRIALS_MIN_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsMinHasAgeBetween_18And_25Desc = 'TRIALS_MIN_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsMinHasCurrentSuicidalIdeationAsc = 'TRIALS_MIN_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsMinHasCurrentSuicidalIdeationDesc = 'TRIALS_MIN_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsMinHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsMinHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsMinHasGivenInformedConsentAtAsc = 'TRIALS_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsMinHasGivenInformedConsentAtDesc = 'TRIALS_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsMinHumanReadableIdAsc = 'TRIALS_MIN_HUMAN_READABLE_ID_ASC',
  TrialsMinHumanReadableIdDesc = 'TRIALS_MIN_HUMAN_READABLE_ID_DESC',
  TrialsMinIdAsc = 'TRIALS_MIN_ID_ASC',
  TrialsMinIdDesc = 'TRIALS_MIN_ID_DESC',
  TrialsMinInternalMessagesIdAsc = 'TRIALS_MIN_INTERNAL_MESSAGES_ID_ASC',
  TrialsMinInternalMessagesIdDesc = 'TRIALS_MIN_INTERNAL_MESSAGES_ID_DESC',
  TrialsMinMeetsAllInclusionCriteriaAsc = 'TRIALS_MIN_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsMinMeetsAllInclusionCriteriaDesc = 'TRIALS_MIN_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsMinMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsMinMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsMinMeetsAnyExclusionCriteriaAsc = 'TRIALS_MIN_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsMinMeetsAnyExclusionCriteriaDesc = 'TRIALS_MIN_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsMinMessagesIdAsc = 'TRIALS_MIN_MESSAGES_ID_ASC',
  TrialsMinMessagesIdDesc = 'TRIALS_MIN_MESSAGES_ID_DESC',
  TrialsMinNameAsc = 'TRIALS_MIN_NAME_ASC',
  TrialsMinNameDesc = 'TRIALS_MIN_NAME_DESC',
  TrialsMinStaffIdAsc = 'TRIALS_MIN_STAFF_ID_ASC',
  TrialsMinStaffIdDesc = 'TRIALS_MIN_STAFF_ID_DESC',
  TrialsMinSubjectsNicknameAsc = 'TRIALS_MIN_SUBJECTS_NICKNAME_ASC',
  TrialsMinSubjectsNicknameDesc = 'TRIALS_MIN_SUBJECTS_NICKNAME_DESC',
  TrialsMinSubjectIdAsc = 'TRIALS_MIN_SUBJECT_ID_ASC',
  TrialsMinSubjectIdDesc = 'TRIALS_MIN_SUBJECT_ID_DESC',
  TrialsMinTenantIdAsc = 'TRIALS_MIN_TENANT_ID_ASC',
  TrialsMinTenantIdDesc = 'TRIALS_MIN_TENANT_ID_DESC',
  TrialsMinUpdatedAtAsc = 'TRIALS_MIN_UPDATED_AT_ASC',
  TrialsMinUpdatedAtDesc = 'TRIALS_MIN_UPDATED_AT_DESC',
  TrialsStddevPopulationAvatarAsc = 'TRIALS_STDDEV_POPULATION_AVATAR_ASC',
  TrialsStddevPopulationAvatarDesc = 'TRIALS_STDDEV_POPULATION_AVATAR_DESC',
  TrialsStddevPopulationCannotUseTheInterventionAsc = 'TRIALS_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsStddevPopulationCannotUseTheInterventionDesc = 'TRIALS_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsStddevPopulationCanAccessErSinceAsc = 'TRIALS_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsStddevPopulationCanAccessErSinceDesc = 'TRIALS_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsStddevPopulationCanAccessSipSinceAsc = 'TRIALS_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsStddevPopulationCanAccessSipSinceDesc = 'TRIALS_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsStddevPopulationCanBeIncludedAsc = 'TRIALS_STDDEV_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsStddevPopulationCanBeIncludedDesc = 'TRIALS_STDDEV_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsStddevPopulationCreatedAtAsc = 'TRIALS_STDDEV_POPULATION_CREATED_AT_ASC',
  TrialsStddevPopulationCreatedAtDesc = 'TRIALS_STDDEV_POPULATION_CREATED_AT_DESC',
  TrialsStddevPopulationCreatedByIdAsc = 'TRIALS_STDDEV_POPULATION_CREATED_BY_ID_ASC',
  TrialsStddevPopulationCreatedByIdDesc = 'TRIALS_STDDEV_POPULATION_CREATED_BY_ID_DESC',
  TrialsStddevPopulationEmergencyPhoneNumbersAsc = 'TRIALS_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsStddevPopulationEmergencyPhoneNumbersDesc = 'TRIALS_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsStddevPopulationHasAccessToASmartphoneAsc = 'TRIALS_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsStddevPopulationHasAccessToASmartphoneDesc = 'TRIALS_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsStddevPopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsStddevPopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsStddevPopulationHasAgeBetween_18And_25Asc = 'TRIALS_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsStddevPopulationHasAgeBetween_18And_25Desc = 'TRIALS_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsStddevPopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsStddevPopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsStddevPopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsStddevPopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsStddevPopulationHasGivenInformedConsentAtAsc = 'TRIALS_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsStddevPopulationHasGivenInformedConsentAtDesc = 'TRIALS_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsStddevPopulationHumanReadableIdAsc = 'TRIALS_STDDEV_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsStddevPopulationHumanReadableIdDesc = 'TRIALS_STDDEV_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsStddevPopulationIdAsc = 'TRIALS_STDDEV_POPULATION_ID_ASC',
  TrialsStddevPopulationIdDesc = 'TRIALS_STDDEV_POPULATION_ID_DESC',
  TrialsStddevPopulationInternalMessagesIdAsc = 'TRIALS_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsStddevPopulationInternalMessagesIdDesc = 'TRIALS_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsStddevPopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsStddevPopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsStddevPopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsStddevPopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsStddevPopulationMessagesIdAsc = 'TRIALS_STDDEV_POPULATION_MESSAGES_ID_ASC',
  TrialsStddevPopulationMessagesIdDesc = 'TRIALS_STDDEV_POPULATION_MESSAGES_ID_DESC',
  TrialsStddevPopulationNameAsc = 'TRIALS_STDDEV_POPULATION_NAME_ASC',
  TrialsStddevPopulationNameDesc = 'TRIALS_STDDEV_POPULATION_NAME_DESC',
  TrialsStddevPopulationStaffIdAsc = 'TRIALS_STDDEV_POPULATION_STAFF_ID_ASC',
  TrialsStddevPopulationStaffIdDesc = 'TRIALS_STDDEV_POPULATION_STAFF_ID_DESC',
  TrialsStddevPopulationSubjectsNicknameAsc = 'TRIALS_STDDEV_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsStddevPopulationSubjectsNicknameDesc = 'TRIALS_STDDEV_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsStddevPopulationSubjectIdAsc = 'TRIALS_STDDEV_POPULATION_SUBJECT_ID_ASC',
  TrialsStddevPopulationSubjectIdDesc = 'TRIALS_STDDEV_POPULATION_SUBJECT_ID_DESC',
  TrialsStddevPopulationTenantIdAsc = 'TRIALS_STDDEV_POPULATION_TENANT_ID_ASC',
  TrialsStddevPopulationTenantIdDesc = 'TRIALS_STDDEV_POPULATION_TENANT_ID_DESC',
  TrialsStddevPopulationUpdatedAtAsc = 'TRIALS_STDDEV_POPULATION_UPDATED_AT_ASC',
  TrialsStddevPopulationUpdatedAtDesc = 'TRIALS_STDDEV_POPULATION_UPDATED_AT_DESC',
  TrialsStddevSampleAvatarAsc = 'TRIALS_STDDEV_SAMPLE_AVATAR_ASC',
  TrialsStddevSampleAvatarDesc = 'TRIALS_STDDEV_SAMPLE_AVATAR_DESC',
  TrialsStddevSampleCannotUseTheInterventionAsc = 'TRIALS_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsStddevSampleCannotUseTheInterventionDesc = 'TRIALS_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsStddevSampleCanAccessErSinceAsc = 'TRIALS_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsStddevSampleCanAccessErSinceDesc = 'TRIALS_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsStddevSampleCanAccessSipSinceAsc = 'TRIALS_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsStddevSampleCanAccessSipSinceDesc = 'TRIALS_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsStddevSampleCanBeIncludedAsc = 'TRIALS_STDDEV_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsStddevSampleCanBeIncludedDesc = 'TRIALS_STDDEV_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsStddevSampleCreatedAtAsc = 'TRIALS_STDDEV_SAMPLE_CREATED_AT_ASC',
  TrialsStddevSampleCreatedAtDesc = 'TRIALS_STDDEV_SAMPLE_CREATED_AT_DESC',
  TrialsStddevSampleCreatedByIdAsc = 'TRIALS_STDDEV_SAMPLE_CREATED_BY_ID_ASC',
  TrialsStddevSampleCreatedByIdDesc = 'TRIALS_STDDEV_SAMPLE_CREATED_BY_ID_DESC',
  TrialsStddevSampleEmergencyPhoneNumbersAsc = 'TRIALS_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsStddevSampleEmergencyPhoneNumbersDesc = 'TRIALS_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsStddevSampleHasAccessToASmartphoneAsc = 'TRIALS_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsStddevSampleHasAccessToASmartphoneDesc = 'TRIALS_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsStddevSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsStddevSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsStddevSampleHasAgeBetween_18And_25Asc = 'TRIALS_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsStddevSampleHasAgeBetween_18And_25Desc = 'TRIALS_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsStddevSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsStddevSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsStddevSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsStddevSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsStddevSampleHasGivenInformedConsentAtAsc = 'TRIALS_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsStddevSampleHasGivenInformedConsentAtDesc = 'TRIALS_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsStddevSampleHumanReadableIdAsc = 'TRIALS_STDDEV_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsStddevSampleHumanReadableIdDesc = 'TRIALS_STDDEV_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsStddevSampleIdAsc = 'TRIALS_STDDEV_SAMPLE_ID_ASC',
  TrialsStddevSampleIdDesc = 'TRIALS_STDDEV_SAMPLE_ID_DESC',
  TrialsStddevSampleInternalMessagesIdAsc = 'TRIALS_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsStddevSampleInternalMessagesIdDesc = 'TRIALS_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsStddevSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsStddevSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsStddevSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsStddevSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsStddevSampleMessagesIdAsc = 'TRIALS_STDDEV_SAMPLE_MESSAGES_ID_ASC',
  TrialsStddevSampleMessagesIdDesc = 'TRIALS_STDDEV_SAMPLE_MESSAGES_ID_DESC',
  TrialsStddevSampleNameAsc = 'TRIALS_STDDEV_SAMPLE_NAME_ASC',
  TrialsStddevSampleNameDesc = 'TRIALS_STDDEV_SAMPLE_NAME_DESC',
  TrialsStddevSampleStaffIdAsc = 'TRIALS_STDDEV_SAMPLE_STAFF_ID_ASC',
  TrialsStddevSampleStaffIdDesc = 'TRIALS_STDDEV_SAMPLE_STAFF_ID_DESC',
  TrialsStddevSampleSubjectsNicknameAsc = 'TRIALS_STDDEV_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsStddevSampleSubjectsNicknameDesc = 'TRIALS_STDDEV_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsStddevSampleSubjectIdAsc = 'TRIALS_STDDEV_SAMPLE_SUBJECT_ID_ASC',
  TrialsStddevSampleSubjectIdDesc = 'TRIALS_STDDEV_SAMPLE_SUBJECT_ID_DESC',
  TrialsStddevSampleTenantIdAsc = 'TRIALS_STDDEV_SAMPLE_TENANT_ID_ASC',
  TrialsStddevSampleTenantIdDesc = 'TRIALS_STDDEV_SAMPLE_TENANT_ID_DESC',
  TrialsStddevSampleUpdatedAtAsc = 'TRIALS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TrialsStddevSampleUpdatedAtDesc = 'TRIALS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TrialsSumAvatarAsc = 'TRIALS_SUM_AVATAR_ASC',
  TrialsSumAvatarDesc = 'TRIALS_SUM_AVATAR_DESC',
  TrialsSumCannotUseTheInterventionAsc = 'TRIALS_SUM_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsSumCannotUseTheInterventionDesc = 'TRIALS_SUM_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsSumCanAccessErSinceAsc = 'TRIALS_SUM_CAN_ACCESS_ER_SINCE_ASC',
  TrialsSumCanAccessErSinceDesc = 'TRIALS_SUM_CAN_ACCESS_ER_SINCE_DESC',
  TrialsSumCanAccessSipSinceAsc = 'TRIALS_SUM_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsSumCanAccessSipSinceDesc = 'TRIALS_SUM_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsSumCanBeIncludedAsc = 'TRIALS_SUM_CAN_BE_INCLUDED_ASC',
  TrialsSumCanBeIncludedDesc = 'TRIALS_SUM_CAN_BE_INCLUDED_DESC',
  TrialsSumCreatedAtAsc = 'TRIALS_SUM_CREATED_AT_ASC',
  TrialsSumCreatedAtDesc = 'TRIALS_SUM_CREATED_AT_DESC',
  TrialsSumCreatedByIdAsc = 'TRIALS_SUM_CREATED_BY_ID_ASC',
  TrialsSumCreatedByIdDesc = 'TRIALS_SUM_CREATED_BY_ID_DESC',
  TrialsSumEmergencyPhoneNumbersAsc = 'TRIALS_SUM_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsSumEmergencyPhoneNumbersDesc = 'TRIALS_SUM_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsSumHasAccessToASmartphoneAsc = 'TRIALS_SUM_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsSumHasAccessToASmartphoneDesc = 'TRIALS_SUM_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsSumHasAdequateKnowledgeOfGermanAsc = 'TRIALS_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsSumHasAdequateKnowledgeOfGermanDesc = 'TRIALS_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsSumHasAgeBetween_18And_25Asc = 'TRIALS_SUM_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsSumHasAgeBetween_18And_25Desc = 'TRIALS_SUM_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsSumHasCurrentSuicidalIdeationAsc = 'TRIALS_SUM_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsSumHasCurrentSuicidalIdeationDesc = 'TRIALS_SUM_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsSumHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsSumHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsSumHasGivenInformedConsentAtAsc = 'TRIALS_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsSumHasGivenInformedConsentAtDesc = 'TRIALS_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsSumHumanReadableIdAsc = 'TRIALS_SUM_HUMAN_READABLE_ID_ASC',
  TrialsSumHumanReadableIdDesc = 'TRIALS_SUM_HUMAN_READABLE_ID_DESC',
  TrialsSumIdAsc = 'TRIALS_SUM_ID_ASC',
  TrialsSumIdDesc = 'TRIALS_SUM_ID_DESC',
  TrialsSumInternalMessagesIdAsc = 'TRIALS_SUM_INTERNAL_MESSAGES_ID_ASC',
  TrialsSumInternalMessagesIdDesc = 'TRIALS_SUM_INTERNAL_MESSAGES_ID_DESC',
  TrialsSumMeetsAllInclusionCriteriaAsc = 'TRIALS_SUM_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsSumMeetsAllInclusionCriteriaDesc = 'TRIALS_SUM_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsSumMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsSumMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsSumMeetsAnyExclusionCriteriaAsc = 'TRIALS_SUM_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsSumMeetsAnyExclusionCriteriaDesc = 'TRIALS_SUM_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsSumMessagesIdAsc = 'TRIALS_SUM_MESSAGES_ID_ASC',
  TrialsSumMessagesIdDesc = 'TRIALS_SUM_MESSAGES_ID_DESC',
  TrialsSumNameAsc = 'TRIALS_SUM_NAME_ASC',
  TrialsSumNameDesc = 'TRIALS_SUM_NAME_DESC',
  TrialsSumStaffIdAsc = 'TRIALS_SUM_STAFF_ID_ASC',
  TrialsSumStaffIdDesc = 'TRIALS_SUM_STAFF_ID_DESC',
  TrialsSumSubjectsNicknameAsc = 'TRIALS_SUM_SUBJECTS_NICKNAME_ASC',
  TrialsSumSubjectsNicknameDesc = 'TRIALS_SUM_SUBJECTS_NICKNAME_DESC',
  TrialsSumSubjectIdAsc = 'TRIALS_SUM_SUBJECT_ID_ASC',
  TrialsSumSubjectIdDesc = 'TRIALS_SUM_SUBJECT_ID_DESC',
  TrialsSumTenantIdAsc = 'TRIALS_SUM_TENANT_ID_ASC',
  TrialsSumTenantIdDesc = 'TRIALS_SUM_TENANT_ID_DESC',
  TrialsSumUpdatedAtAsc = 'TRIALS_SUM_UPDATED_AT_ASC',
  TrialsSumUpdatedAtDesc = 'TRIALS_SUM_UPDATED_AT_DESC',
  TrialsVariancePopulationAvatarAsc = 'TRIALS_VARIANCE_POPULATION_AVATAR_ASC',
  TrialsVariancePopulationAvatarDesc = 'TRIALS_VARIANCE_POPULATION_AVATAR_DESC',
  TrialsVariancePopulationCannotUseTheInterventionAsc = 'TRIALS_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsVariancePopulationCannotUseTheInterventionDesc = 'TRIALS_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsVariancePopulationCanAccessErSinceAsc = 'TRIALS_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsVariancePopulationCanAccessErSinceDesc = 'TRIALS_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsVariancePopulationCanAccessSipSinceAsc = 'TRIALS_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsVariancePopulationCanAccessSipSinceDesc = 'TRIALS_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsVariancePopulationCanBeIncludedAsc = 'TRIALS_VARIANCE_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsVariancePopulationCanBeIncludedDesc = 'TRIALS_VARIANCE_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsVariancePopulationCreatedAtAsc = 'TRIALS_VARIANCE_POPULATION_CREATED_AT_ASC',
  TrialsVariancePopulationCreatedAtDesc = 'TRIALS_VARIANCE_POPULATION_CREATED_AT_DESC',
  TrialsVariancePopulationCreatedByIdAsc = 'TRIALS_VARIANCE_POPULATION_CREATED_BY_ID_ASC',
  TrialsVariancePopulationCreatedByIdDesc = 'TRIALS_VARIANCE_POPULATION_CREATED_BY_ID_DESC',
  TrialsVariancePopulationEmergencyPhoneNumbersAsc = 'TRIALS_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsVariancePopulationEmergencyPhoneNumbersDesc = 'TRIALS_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsVariancePopulationHasAccessToASmartphoneAsc = 'TRIALS_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsVariancePopulationHasAccessToASmartphoneDesc = 'TRIALS_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsVariancePopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsVariancePopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsVariancePopulationHasAgeBetween_18And_25Asc = 'TRIALS_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsVariancePopulationHasAgeBetween_18And_25Desc = 'TRIALS_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsVariancePopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsVariancePopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsVariancePopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsVariancePopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsVariancePopulationHasGivenInformedConsentAtAsc = 'TRIALS_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsVariancePopulationHasGivenInformedConsentAtDesc = 'TRIALS_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsVariancePopulationHumanReadableIdAsc = 'TRIALS_VARIANCE_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsVariancePopulationHumanReadableIdDesc = 'TRIALS_VARIANCE_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsVariancePopulationIdAsc = 'TRIALS_VARIANCE_POPULATION_ID_ASC',
  TrialsVariancePopulationIdDesc = 'TRIALS_VARIANCE_POPULATION_ID_DESC',
  TrialsVariancePopulationInternalMessagesIdAsc = 'TRIALS_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsVariancePopulationInternalMessagesIdDesc = 'TRIALS_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsVariancePopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsVariancePopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsVariancePopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsVariancePopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsVariancePopulationMessagesIdAsc = 'TRIALS_VARIANCE_POPULATION_MESSAGES_ID_ASC',
  TrialsVariancePopulationMessagesIdDesc = 'TRIALS_VARIANCE_POPULATION_MESSAGES_ID_DESC',
  TrialsVariancePopulationNameAsc = 'TRIALS_VARIANCE_POPULATION_NAME_ASC',
  TrialsVariancePopulationNameDesc = 'TRIALS_VARIANCE_POPULATION_NAME_DESC',
  TrialsVariancePopulationStaffIdAsc = 'TRIALS_VARIANCE_POPULATION_STAFF_ID_ASC',
  TrialsVariancePopulationStaffIdDesc = 'TRIALS_VARIANCE_POPULATION_STAFF_ID_DESC',
  TrialsVariancePopulationSubjectsNicknameAsc = 'TRIALS_VARIANCE_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsVariancePopulationSubjectsNicknameDesc = 'TRIALS_VARIANCE_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsVariancePopulationSubjectIdAsc = 'TRIALS_VARIANCE_POPULATION_SUBJECT_ID_ASC',
  TrialsVariancePopulationSubjectIdDesc = 'TRIALS_VARIANCE_POPULATION_SUBJECT_ID_DESC',
  TrialsVariancePopulationTenantIdAsc = 'TRIALS_VARIANCE_POPULATION_TENANT_ID_ASC',
  TrialsVariancePopulationTenantIdDesc = 'TRIALS_VARIANCE_POPULATION_TENANT_ID_DESC',
  TrialsVariancePopulationUpdatedAtAsc = 'TRIALS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TrialsVariancePopulationUpdatedAtDesc = 'TRIALS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TrialsVarianceSampleAvatarAsc = 'TRIALS_VARIANCE_SAMPLE_AVATAR_ASC',
  TrialsVarianceSampleAvatarDesc = 'TRIALS_VARIANCE_SAMPLE_AVATAR_DESC',
  TrialsVarianceSampleCannotUseTheInterventionAsc = 'TRIALS_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsVarianceSampleCannotUseTheInterventionDesc = 'TRIALS_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsVarianceSampleCanAccessErSinceAsc = 'TRIALS_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsVarianceSampleCanAccessErSinceDesc = 'TRIALS_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsVarianceSampleCanAccessSipSinceAsc = 'TRIALS_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsVarianceSampleCanAccessSipSinceDesc = 'TRIALS_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsVarianceSampleCanBeIncludedAsc = 'TRIALS_VARIANCE_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsVarianceSampleCanBeIncludedDesc = 'TRIALS_VARIANCE_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsVarianceSampleCreatedAtAsc = 'TRIALS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TrialsVarianceSampleCreatedAtDesc = 'TRIALS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TrialsVarianceSampleCreatedByIdAsc = 'TRIALS_VARIANCE_SAMPLE_CREATED_BY_ID_ASC',
  TrialsVarianceSampleCreatedByIdDesc = 'TRIALS_VARIANCE_SAMPLE_CREATED_BY_ID_DESC',
  TrialsVarianceSampleEmergencyPhoneNumbersAsc = 'TRIALS_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsVarianceSampleEmergencyPhoneNumbersDesc = 'TRIALS_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsVarianceSampleHasAccessToASmartphoneAsc = 'TRIALS_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsVarianceSampleHasAccessToASmartphoneDesc = 'TRIALS_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsVarianceSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsVarianceSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsVarianceSampleHasAgeBetween_18And_25Asc = 'TRIALS_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsVarianceSampleHasAgeBetween_18And_25Desc = 'TRIALS_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsVarianceSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsVarianceSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsVarianceSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsVarianceSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsVarianceSampleHasGivenInformedConsentAtAsc = 'TRIALS_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsVarianceSampleHasGivenInformedConsentAtDesc = 'TRIALS_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsVarianceSampleHumanReadableIdAsc = 'TRIALS_VARIANCE_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsVarianceSampleHumanReadableIdDesc = 'TRIALS_VARIANCE_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsVarianceSampleIdAsc = 'TRIALS_VARIANCE_SAMPLE_ID_ASC',
  TrialsVarianceSampleIdDesc = 'TRIALS_VARIANCE_SAMPLE_ID_DESC',
  TrialsVarianceSampleInternalMessagesIdAsc = 'TRIALS_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsVarianceSampleInternalMessagesIdDesc = 'TRIALS_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsVarianceSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsVarianceSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsVarianceSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsVarianceSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsVarianceSampleMessagesIdAsc = 'TRIALS_VARIANCE_SAMPLE_MESSAGES_ID_ASC',
  TrialsVarianceSampleMessagesIdDesc = 'TRIALS_VARIANCE_SAMPLE_MESSAGES_ID_DESC',
  TrialsVarianceSampleNameAsc = 'TRIALS_VARIANCE_SAMPLE_NAME_ASC',
  TrialsVarianceSampleNameDesc = 'TRIALS_VARIANCE_SAMPLE_NAME_DESC',
  TrialsVarianceSampleStaffIdAsc = 'TRIALS_VARIANCE_SAMPLE_STAFF_ID_ASC',
  TrialsVarianceSampleStaffIdDesc = 'TRIALS_VARIANCE_SAMPLE_STAFF_ID_DESC',
  TrialsVarianceSampleSubjectsNicknameAsc = 'TRIALS_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsVarianceSampleSubjectsNicknameDesc = 'TRIALS_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsVarianceSampleSubjectIdAsc = 'TRIALS_VARIANCE_SAMPLE_SUBJECT_ID_ASC',
  TrialsVarianceSampleSubjectIdDesc = 'TRIALS_VARIANCE_SAMPLE_SUBJECT_ID_DESC',
  TrialsVarianceSampleTenantIdAsc = 'TRIALS_VARIANCE_SAMPLE_TENANT_ID_ASC',
  TrialsVarianceSampleTenantIdDesc = 'TRIALS_VARIANCE_SAMPLE_TENANT_ID_DESC',
  TrialsVarianceSampleUpdatedAtAsc = 'TRIALS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TrialsVarianceSampleUpdatedAtDesc = 'TRIALS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UrlAsc = 'URL_ASC',
  UrlDesc = 'URL_DESC',
  UsersAverageAboutMeAsc = 'USERS_AVERAGE_ABOUT_ME_ASC',
  UsersAverageAboutMeDesc = 'USERS_AVERAGE_ABOUT_ME_DESC',
  UsersAverageAboutMeFormatAsc = 'USERS_AVERAGE_ABOUT_ME_FORMAT_ASC',
  UsersAverageAboutMeFormatDesc = 'USERS_AVERAGE_ABOUT_ME_FORMAT_DESC',
  UsersAverageCreatedAtAsc = 'USERS_AVERAGE_CREATED_AT_ASC',
  UsersAverageCreatedAtDesc = 'USERS_AVERAGE_CREATED_AT_DESC',
  UsersAverageCredentialsIdAsc = 'USERS_AVERAGE_CREDENTIALS_ID_ASC',
  UsersAverageCredentialsIdDesc = 'USERS_AVERAGE_CREDENTIALS_ID_DESC',
  UsersAverageEmailAddressIdAsc = 'USERS_AVERAGE_EMAIL_ADDRESS_ID_ASC',
  UsersAverageEmailAddressIdDesc = 'USERS_AVERAGE_EMAIL_ADDRESS_ID_DESC',
  UsersAverageExternalRolesAsc = 'USERS_AVERAGE_EXTERNAL_ROLES_ASC',
  UsersAverageExternalRolesDesc = 'USERS_AVERAGE_EXTERNAL_ROLES_DESC',
  UsersAverageGravatarHashAsc = 'USERS_AVERAGE_GRAVATAR_HASH_ASC',
  UsersAverageGravatarHashDesc = 'USERS_AVERAGE_GRAVATAR_HASH_DESC',
  UsersAverageIdAsc = 'USERS_AVERAGE_ID_ASC',
  UsersAverageIdDesc = 'USERS_AVERAGE_ID_DESC',
  UsersAverageIsTenantAdminAsc = 'USERS_AVERAGE_IS_TENANT_ADMIN_ASC',
  UsersAverageIsTenantAdminDesc = 'USERS_AVERAGE_IS_TENANT_ADMIN_DESC',
  UsersAverageTenantIdAsc = 'USERS_AVERAGE_TENANT_ID_ASC',
  UsersAverageTenantIdDesc = 'USERS_AVERAGE_TENANT_ID_DESC',
  UsersAverageUpdatedAtAsc = 'USERS_AVERAGE_UPDATED_AT_ASC',
  UsersAverageUpdatedAtDesc = 'USERS_AVERAGE_UPDATED_AT_DESC',
  UsersAverageUsernameAsc = 'USERS_AVERAGE_USERNAME_ASC',
  UsersAverageUsernameDesc = 'USERS_AVERAGE_USERNAME_DESC',
  UsersCountAsc = 'USERS_COUNT_ASC',
  UsersCountDesc = 'USERS_COUNT_DESC',
  UsersDistinctCountAboutMeAsc = 'USERS_DISTINCT_COUNT_ABOUT_ME_ASC',
  UsersDistinctCountAboutMeDesc = 'USERS_DISTINCT_COUNT_ABOUT_ME_DESC',
  UsersDistinctCountAboutMeFormatAsc = 'USERS_DISTINCT_COUNT_ABOUT_ME_FORMAT_ASC',
  UsersDistinctCountAboutMeFormatDesc = 'USERS_DISTINCT_COUNT_ABOUT_ME_FORMAT_DESC',
  UsersDistinctCountCreatedAtAsc = 'USERS_DISTINCT_COUNT_CREATED_AT_ASC',
  UsersDistinctCountCreatedAtDesc = 'USERS_DISTINCT_COUNT_CREATED_AT_DESC',
  UsersDistinctCountCredentialsIdAsc = 'USERS_DISTINCT_COUNT_CREDENTIALS_ID_ASC',
  UsersDistinctCountCredentialsIdDesc = 'USERS_DISTINCT_COUNT_CREDENTIALS_ID_DESC',
  UsersDistinctCountEmailAddressIdAsc = 'USERS_DISTINCT_COUNT_EMAIL_ADDRESS_ID_ASC',
  UsersDistinctCountEmailAddressIdDesc = 'USERS_DISTINCT_COUNT_EMAIL_ADDRESS_ID_DESC',
  UsersDistinctCountExternalRolesAsc = 'USERS_DISTINCT_COUNT_EXTERNAL_ROLES_ASC',
  UsersDistinctCountExternalRolesDesc = 'USERS_DISTINCT_COUNT_EXTERNAL_ROLES_DESC',
  UsersDistinctCountGravatarHashAsc = 'USERS_DISTINCT_COUNT_GRAVATAR_HASH_ASC',
  UsersDistinctCountGravatarHashDesc = 'USERS_DISTINCT_COUNT_GRAVATAR_HASH_DESC',
  UsersDistinctCountIdAsc = 'USERS_DISTINCT_COUNT_ID_ASC',
  UsersDistinctCountIdDesc = 'USERS_DISTINCT_COUNT_ID_DESC',
  UsersDistinctCountIsTenantAdminAsc = 'USERS_DISTINCT_COUNT_IS_TENANT_ADMIN_ASC',
  UsersDistinctCountIsTenantAdminDesc = 'USERS_DISTINCT_COUNT_IS_TENANT_ADMIN_DESC',
  UsersDistinctCountTenantIdAsc = 'USERS_DISTINCT_COUNT_TENANT_ID_ASC',
  UsersDistinctCountTenantIdDesc = 'USERS_DISTINCT_COUNT_TENANT_ID_DESC',
  UsersDistinctCountUpdatedAtAsc = 'USERS_DISTINCT_COUNT_UPDATED_AT_ASC',
  UsersDistinctCountUpdatedAtDesc = 'USERS_DISTINCT_COUNT_UPDATED_AT_DESC',
  UsersDistinctCountUsernameAsc = 'USERS_DISTINCT_COUNT_USERNAME_ASC',
  UsersDistinctCountUsernameDesc = 'USERS_DISTINCT_COUNT_USERNAME_DESC',
  UsersMaxAboutMeAsc = 'USERS_MAX_ABOUT_ME_ASC',
  UsersMaxAboutMeDesc = 'USERS_MAX_ABOUT_ME_DESC',
  UsersMaxAboutMeFormatAsc = 'USERS_MAX_ABOUT_ME_FORMAT_ASC',
  UsersMaxAboutMeFormatDesc = 'USERS_MAX_ABOUT_ME_FORMAT_DESC',
  UsersMaxCreatedAtAsc = 'USERS_MAX_CREATED_AT_ASC',
  UsersMaxCreatedAtDesc = 'USERS_MAX_CREATED_AT_DESC',
  UsersMaxCredentialsIdAsc = 'USERS_MAX_CREDENTIALS_ID_ASC',
  UsersMaxCredentialsIdDesc = 'USERS_MAX_CREDENTIALS_ID_DESC',
  UsersMaxEmailAddressIdAsc = 'USERS_MAX_EMAIL_ADDRESS_ID_ASC',
  UsersMaxEmailAddressIdDesc = 'USERS_MAX_EMAIL_ADDRESS_ID_DESC',
  UsersMaxExternalRolesAsc = 'USERS_MAX_EXTERNAL_ROLES_ASC',
  UsersMaxExternalRolesDesc = 'USERS_MAX_EXTERNAL_ROLES_DESC',
  UsersMaxGravatarHashAsc = 'USERS_MAX_GRAVATAR_HASH_ASC',
  UsersMaxGravatarHashDesc = 'USERS_MAX_GRAVATAR_HASH_DESC',
  UsersMaxIdAsc = 'USERS_MAX_ID_ASC',
  UsersMaxIdDesc = 'USERS_MAX_ID_DESC',
  UsersMaxIsTenantAdminAsc = 'USERS_MAX_IS_TENANT_ADMIN_ASC',
  UsersMaxIsTenantAdminDesc = 'USERS_MAX_IS_TENANT_ADMIN_DESC',
  UsersMaxTenantIdAsc = 'USERS_MAX_TENANT_ID_ASC',
  UsersMaxTenantIdDesc = 'USERS_MAX_TENANT_ID_DESC',
  UsersMaxUpdatedAtAsc = 'USERS_MAX_UPDATED_AT_ASC',
  UsersMaxUpdatedAtDesc = 'USERS_MAX_UPDATED_AT_DESC',
  UsersMaxUsernameAsc = 'USERS_MAX_USERNAME_ASC',
  UsersMaxUsernameDesc = 'USERS_MAX_USERNAME_DESC',
  UsersMinAboutMeAsc = 'USERS_MIN_ABOUT_ME_ASC',
  UsersMinAboutMeDesc = 'USERS_MIN_ABOUT_ME_DESC',
  UsersMinAboutMeFormatAsc = 'USERS_MIN_ABOUT_ME_FORMAT_ASC',
  UsersMinAboutMeFormatDesc = 'USERS_MIN_ABOUT_ME_FORMAT_DESC',
  UsersMinCreatedAtAsc = 'USERS_MIN_CREATED_AT_ASC',
  UsersMinCreatedAtDesc = 'USERS_MIN_CREATED_AT_DESC',
  UsersMinCredentialsIdAsc = 'USERS_MIN_CREDENTIALS_ID_ASC',
  UsersMinCredentialsIdDesc = 'USERS_MIN_CREDENTIALS_ID_DESC',
  UsersMinEmailAddressIdAsc = 'USERS_MIN_EMAIL_ADDRESS_ID_ASC',
  UsersMinEmailAddressIdDesc = 'USERS_MIN_EMAIL_ADDRESS_ID_DESC',
  UsersMinExternalRolesAsc = 'USERS_MIN_EXTERNAL_ROLES_ASC',
  UsersMinExternalRolesDesc = 'USERS_MIN_EXTERNAL_ROLES_DESC',
  UsersMinGravatarHashAsc = 'USERS_MIN_GRAVATAR_HASH_ASC',
  UsersMinGravatarHashDesc = 'USERS_MIN_GRAVATAR_HASH_DESC',
  UsersMinIdAsc = 'USERS_MIN_ID_ASC',
  UsersMinIdDesc = 'USERS_MIN_ID_DESC',
  UsersMinIsTenantAdminAsc = 'USERS_MIN_IS_TENANT_ADMIN_ASC',
  UsersMinIsTenantAdminDesc = 'USERS_MIN_IS_TENANT_ADMIN_DESC',
  UsersMinTenantIdAsc = 'USERS_MIN_TENANT_ID_ASC',
  UsersMinTenantIdDesc = 'USERS_MIN_TENANT_ID_DESC',
  UsersMinUpdatedAtAsc = 'USERS_MIN_UPDATED_AT_ASC',
  UsersMinUpdatedAtDesc = 'USERS_MIN_UPDATED_AT_DESC',
  UsersMinUsernameAsc = 'USERS_MIN_USERNAME_ASC',
  UsersMinUsernameDesc = 'USERS_MIN_USERNAME_DESC',
  UsersStddevPopulationAboutMeAsc = 'USERS_STDDEV_POPULATION_ABOUT_ME_ASC',
  UsersStddevPopulationAboutMeDesc = 'USERS_STDDEV_POPULATION_ABOUT_ME_DESC',
  UsersStddevPopulationAboutMeFormatAsc = 'USERS_STDDEV_POPULATION_ABOUT_ME_FORMAT_ASC',
  UsersStddevPopulationAboutMeFormatDesc = 'USERS_STDDEV_POPULATION_ABOUT_ME_FORMAT_DESC',
  UsersStddevPopulationCreatedAtAsc = 'USERS_STDDEV_POPULATION_CREATED_AT_ASC',
  UsersStddevPopulationCreatedAtDesc = 'USERS_STDDEV_POPULATION_CREATED_AT_DESC',
  UsersStddevPopulationCredentialsIdAsc = 'USERS_STDDEV_POPULATION_CREDENTIALS_ID_ASC',
  UsersStddevPopulationCredentialsIdDesc = 'USERS_STDDEV_POPULATION_CREDENTIALS_ID_DESC',
  UsersStddevPopulationEmailAddressIdAsc = 'USERS_STDDEV_POPULATION_EMAIL_ADDRESS_ID_ASC',
  UsersStddevPopulationEmailAddressIdDesc = 'USERS_STDDEV_POPULATION_EMAIL_ADDRESS_ID_DESC',
  UsersStddevPopulationExternalRolesAsc = 'USERS_STDDEV_POPULATION_EXTERNAL_ROLES_ASC',
  UsersStddevPopulationExternalRolesDesc = 'USERS_STDDEV_POPULATION_EXTERNAL_ROLES_DESC',
  UsersStddevPopulationGravatarHashAsc = 'USERS_STDDEV_POPULATION_GRAVATAR_HASH_ASC',
  UsersStddevPopulationGravatarHashDesc = 'USERS_STDDEV_POPULATION_GRAVATAR_HASH_DESC',
  UsersStddevPopulationIdAsc = 'USERS_STDDEV_POPULATION_ID_ASC',
  UsersStddevPopulationIdDesc = 'USERS_STDDEV_POPULATION_ID_DESC',
  UsersStddevPopulationIsTenantAdminAsc = 'USERS_STDDEV_POPULATION_IS_TENANT_ADMIN_ASC',
  UsersStddevPopulationIsTenantAdminDesc = 'USERS_STDDEV_POPULATION_IS_TENANT_ADMIN_DESC',
  UsersStddevPopulationTenantIdAsc = 'USERS_STDDEV_POPULATION_TENANT_ID_ASC',
  UsersStddevPopulationTenantIdDesc = 'USERS_STDDEV_POPULATION_TENANT_ID_DESC',
  UsersStddevPopulationUpdatedAtAsc = 'USERS_STDDEV_POPULATION_UPDATED_AT_ASC',
  UsersStddevPopulationUpdatedAtDesc = 'USERS_STDDEV_POPULATION_UPDATED_AT_DESC',
  UsersStddevPopulationUsernameAsc = 'USERS_STDDEV_POPULATION_USERNAME_ASC',
  UsersStddevPopulationUsernameDesc = 'USERS_STDDEV_POPULATION_USERNAME_DESC',
  UsersStddevSampleAboutMeAsc = 'USERS_STDDEV_SAMPLE_ABOUT_ME_ASC',
  UsersStddevSampleAboutMeDesc = 'USERS_STDDEV_SAMPLE_ABOUT_ME_DESC',
  UsersStddevSampleAboutMeFormatAsc = 'USERS_STDDEV_SAMPLE_ABOUT_ME_FORMAT_ASC',
  UsersStddevSampleAboutMeFormatDesc = 'USERS_STDDEV_SAMPLE_ABOUT_ME_FORMAT_DESC',
  UsersStddevSampleCreatedAtAsc = 'USERS_STDDEV_SAMPLE_CREATED_AT_ASC',
  UsersStddevSampleCreatedAtDesc = 'USERS_STDDEV_SAMPLE_CREATED_AT_DESC',
  UsersStddevSampleCredentialsIdAsc = 'USERS_STDDEV_SAMPLE_CREDENTIALS_ID_ASC',
  UsersStddevSampleCredentialsIdDesc = 'USERS_STDDEV_SAMPLE_CREDENTIALS_ID_DESC',
  UsersStddevSampleEmailAddressIdAsc = 'USERS_STDDEV_SAMPLE_EMAIL_ADDRESS_ID_ASC',
  UsersStddevSampleEmailAddressIdDesc = 'USERS_STDDEV_SAMPLE_EMAIL_ADDRESS_ID_DESC',
  UsersStddevSampleExternalRolesAsc = 'USERS_STDDEV_SAMPLE_EXTERNAL_ROLES_ASC',
  UsersStddevSampleExternalRolesDesc = 'USERS_STDDEV_SAMPLE_EXTERNAL_ROLES_DESC',
  UsersStddevSampleGravatarHashAsc = 'USERS_STDDEV_SAMPLE_GRAVATAR_HASH_ASC',
  UsersStddevSampleGravatarHashDesc = 'USERS_STDDEV_SAMPLE_GRAVATAR_HASH_DESC',
  UsersStddevSampleIdAsc = 'USERS_STDDEV_SAMPLE_ID_ASC',
  UsersStddevSampleIdDesc = 'USERS_STDDEV_SAMPLE_ID_DESC',
  UsersStddevSampleIsTenantAdminAsc = 'USERS_STDDEV_SAMPLE_IS_TENANT_ADMIN_ASC',
  UsersStddevSampleIsTenantAdminDesc = 'USERS_STDDEV_SAMPLE_IS_TENANT_ADMIN_DESC',
  UsersStddevSampleTenantIdAsc = 'USERS_STDDEV_SAMPLE_TENANT_ID_ASC',
  UsersStddevSampleTenantIdDesc = 'USERS_STDDEV_SAMPLE_TENANT_ID_DESC',
  UsersStddevSampleUpdatedAtAsc = 'USERS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UsersStddevSampleUpdatedAtDesc = 'USERS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UsersStddevSampleUsernameAsc = 'USERS_STDDEV_SAMPLE_USERNAME_ASC',
  UsersStddevSampleUsernameDesc = 'USERS_STDDEV_SAMPLE_USERNAME_DESC',
  UsersSumAboutMeAsc = 'USERS_SUM_ABOUT_ME_ASC',
  UsersSumAboutMeDesc = 'USERS_SUM_ABOUT_ME_DESC',
  UsersSumAboutMeFormatAsc = 'USERS_SUM_ABOUT_ME_FORMAT_ASC',
  UsersSumAboutMeFormatDesc = 'USERS_SUM_ABOUT_ME_FORMAT_DESC',
  UsersSumCreatedAtAsc = 'USERS_SUM_CREATED_AT_ASC',
  UsersSumCreatedAtDesc = 'USERS_SUM_CREATED_AT_DESC',
  UsersSumCredentialsIdAsc = 'USERS_SUM_CREDENTIALS_ID_ASC',
  UsersSumCredentialsIdDesc = 'USERS_SUM_CREDENTIALS_ID_DESC',
  UsersSumEmailAddressIdAsc = 'USERS_SUM_EMAIL_ADDRESS_ID_ASC',
  UsersSumEmailAddressIdDesc = 'USERS_SUM_EMAIL_ADDRESS_ID_DESC',
  UsersSumExternalRolesAsc = 'USERS_SUM_EXTERNAL_ROLES_ASC',
  UsersSumExternalRolesDesc = 'USERS_SUM_EXTERNAL_ROLES_DESC',
  UsersSumGravatarHashAsc = 'USERS_SUM_GRAVATAR_HASH_ASC',
  UsersSumGravatarHashDesc = 'USERS_SUM_GRAVATAR_HASH_DESC',
  UsersSumIdAsc = 'USERS_SUM_ID_ASC',
  UsersSumIdDesc = 'USERS_SUM_ID_DESC',
  UsersSumIsTenantAdminAsc = 'USERS_SUM_IS_TENANT_ADMIN_ASC',
  UsersSumIsTenantAdminDesc = 'USERS_SUM_IS_TENANT_ADMIN_DESC',
  UsersSumTenantIdAsc = 'USERS_SUM_TENANT_ID_ASC',
  UsersSumTenantIdDesc = 'USERS_SUM_TENANT_ID_DESC',
  UsersSumUpdatedAtAsc = 'USERS_SUM_UPDATED_AT_ASC',
  UsersSumUpdatedAtDesc = 'USERS_SUM_UPDATED_AT_DESC',
  UsersSumUsernameAsc = 'USERS_SUM_USERNAME_ASC',
  UsersSumUsernameDesc = 'USERS_SUM_USERNAME_DESC',
  UsersVariancePopulationAboutMeAsc = 'USERS_VARIANCE_POPULATION_ABOUT_ME_ASC',
  UsersVariancePopulationAboutMeDesc = 'USERS_VARIANCE_POPULATION_ABOUT_ME_DESC',
  UsersVariancePopulationAboutMeFormatAsc = 'USERS_VARIANCE_POPULATION_ABOUT_ME_FORMAT_ASC',
  UsersVariancePopulationAboutMeFormatDesc = 'USERS_VARIANCE_POPULATION_ABOUT_ME_FORMAT_DESC',
  UsersVariancePopulationCreatedAtAsc = 'USERS_VARIANCE_POPULATION_CREATED_AT_ASC',
  UsersVariancePopulationCreatedAtDesc = 'USERS_VARIANCE_POPULATION_CREATED_AT_DESC',
  UsersVariancePopulationCredentialsIdAsc = 'USERS_VARIANCE_POPULATION_CREDENTIALS_ID_ASC',
  UsersVariancePopulationCredentialsIdDesc = 'USERS_VARIANCE_POPULATION_CREDENTIALS_ID_DESC',
  UsersVariancePopulationEmailAddressIdAsc = 'USERS_VARIANCE_POPULATION_EMAIL_ADDRESS_ID_ASC',
  UsersVariancePopulationEmailAddressIdDesc = 'USERS_VARIANCE_POPULATION_EMAIL_ADDRESS_ID_DESC',
  UsersVariancePopulationExternalRolesAsc = 'USERS_VARIANCE_POPULATION_EXTERNAL_ROLES_ASC',
  UsersVariancePopulationExternalRolesDesc = 'USERS_VARIANCE_POPULATION_EXTERNAL_ROLES_DESC',
  UsersVariancePopulationGravatarHashAsc = 'USERS_VARIANCE_POPULATION_GRAVATAR_HASH_ASC',
  UsersVariancePopulationGravatarHashDesc = 'USERS_VARIANCE_POPULATION_GRAVATAR_HASH_DESC',
  UsersVariancePopulationIdAsc = 'USERS_VARIANCE_POPULATION_ID_ASC',
  UsersVariancePopulationIdDesc = 'USERS_VARIANCE_POPULATION_ID_DESC',
  UsersVariancePopulationIsTenantAdminAsc = 'USERS_VARIANCE_POPULATION_IS_TENANT_ADMIN_ASC',
  UsersVariancePopulationIsTenantAdminDesc = 'USERS_VARIANCE_POPULATION_IS_TENANT_ADMIN_DESC',
  UsersVariancePopulationTenantIdAsc = 'USERS_VARIANCE_POPULATION_TENANT_ID_ASC',
  UsersVariancePopulationTenantIdDesc = 'USERS_VARIANCE_POPULATION_TENANT_ID_DESC',
  UsersVariancePopulationUpdatedAtAsc = 'USERS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UsersVariancePopulationUpdatedAtDesc = 'USERS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UsersVariancePopulationUsernameAsc = 'USERS_VARIANCE_POPULATION_USERNAME_ASC',
  UsersVariancePopulationUsernameDesc = 'USERS_VARIANCE_POPULATION_USERNAME_DESC',
  UsersVarianceSampleAboutMeAsc = 'USERS_VARIANCE_SAMPLE_ABOUT_ME_ASC',
  UsersVarianceSampleAboutMeDesc = 'USERS_VARIANCE_SAMPLE_ABOUT_ME_DESC',
  UsersVarianceSampleAboutMeFormatAsc = 'USERS_VARIANCE_SAMPLE_ABOUT_ME_FORMAT_ASC',
  UsersVarianceSampleAboutMeFormatDesc = 'USERS_VARIANCE_SAMPLE_ABOUT_ME_FORMAT_DESC',
  UsersVarianceSampleCreatedAtAsc = 'USERS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UsersVarianceSampleCreatedAtDesc = 'USERS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UsersVarianceSampleCredentialsIdAsc = 'USERS_VARIANCE_SAMPLE_CREDENTIALS_ID_ASC',
  UsersVarianceSampleCredentialsIdDesc = 'USERS_VARIANCE_SAMPLE_CREDENTIALS_ID_DESC',
  UsersVarianceSampleEmailAddressIdAsc = 'USERS_VARIANCE_SAMPLE_EMAIL_ADDRESS_ID_ASC',
  UsersVarianceSampleEmailAddressIdDesc = 'USERS_VARIANCE_SAMPLE_EMAIL_ADDRESS_ID_DESC',
  UsersVarianceSampleExternalRolesAsc = 'USERS_VARIANCE_SAMPLE_EXTERNAL_ROLES_ASC',
  UsersVarianceSampleExternalRolesDesc = 'USERS_VARIANCE_SAMPLE_EXTERNAL_ROLES_DESC',
  UsersVarianceSampleGravatarHashAsc = 'USERS_VARIANCE_SAMPLE_GRAVATAR_HASH_ASC',
  UsersVarianceSampleGravatarHashDesc = 'USERS_VARIANCE_SAMPLE_GRAVATAR_HASH_DESC',
  UsersVarianceSampleIdAsc = 'USERS_VARIANCE_SAMPLE_ID_ASC',
  UsersVarianceSampleIdDesc = 'USERS_VARIANCE_SAMPLE_ID_DESC',
  UsersVarianceSampleIsTenantAdminAsc = 'USERS_VARIANCE_SAMPLE_IS_TENANT_ADMIN_ASC',
  UsersVarianceSampleIsTenantAdminDesc = 'USERS_VARIANCE_SAMPLE_IS_TENANT_ADMIN_DESC',
  UsersVarianceSampleTenantIdAsc = 'USERS_VARIANCE_SAMPLE_TENANT_ID_ASC',
  UsersVarianceSampleTenantIdDesc = 'USERS_VARIANCE_SAMPLE_TENANT_ID_DESC',
  UsersVarianceSampleUpdatedAtAsc = 'USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UsersVarianceSampleUpdatedAtDesc = 'USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UsersVarianceSampleUsernameAsc = 'USERS_VARIANCE_SAMPLE_USERNAME_ASC',
  UsersVarianceSampleUsernameDesc = 'USERS_VARIANCE_SAMPLE_USERNAME_DESC',
  UserCredentialsAverageCreatedAtAsc = 'USER_CREDENTIALS_AVERAGE_CREATED_AT_ASC',
  UserCredentialsAverageCreatedAtDesc = 'USER_CREDENTIALS_AVERAGE_CREATED_AT_DESC',
  UserCredentialsAverageEncryptedPasswordAsc = 'USER_CREDENTIALS_AVERAGE_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsAverageEncryptedPasswordDesc = 'USER_CREDENTIALS_AVERAGE_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsAverageExternalAuthIdAsc = 'USER_CREDENTIALS_AVERAGE_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsAverageExternalAuthIdDesc = 'USER_CREDENTIALS_AVERAGE_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsAverageIdAsc = 'USER_CREDENTIALS_AVERAGE_ID_ASC',
  UserCredentialsAverageIdDesc = 'USER_CREDENTIALS_AVERAGE_ID_DESC',
  UserCredentialsAverageTenantIdAsc = 'USER_CREDENTIALS_AVERAGE_TENANT_ID_ASC',
  UserCredentialsAverageTenantIdDesc = 'USER_CREDENTIALS_AVERAGE_TENANT_ID_DESC',
  UserCredentialsAverageUpdatedAtAsc = 'USER_CREDENTIALS_AVERAGE_UPDATED_AT_ASC',
  UserCredentialsAverageUpdatedAtDesc = 'USER_CREDENTIALS_AVERAGE_UPDATED_AT_DESC',
  UserCredentialsCountAsc = 'USER_CREDENTIALS_COUNT_ASC',
  UserCredentialsCountDesc = 'USER_CREDENTIALS_COUNT_DESC',
  UserCredentialsDistinctCountCreatedAtAsc = 'USER_CREDENTIALS_DISTINCT_COUNT_CREATED_AT_ASC',
  UserCredentialsDistinctCountCreatedAtDesc = 'USER_CREDENTIALS_DISTINCT_COUNT_CREATED_AT_DESC',
  UserCredentialsDistinctCountEncryptedPasswordAsc = 'USER_CREDENTIALS_DISTINCT_COUNT_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsDistinctCountEncryptedPasswordDesc = 'USER_CREDENTIALS_DISTINCT_COUNT_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsDistinctCountExternalAuthIdAsc = 'USER_CREDENTIALS_DISTINCT_COUNT_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsDistinctCountExternalAuthIdDesc = 'USER_CREDENTIALS_DISTINCT_COUNT_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsDistinctCountIdAsc = 'USER_CREDENTIALS_DISTINCT_COUNT_ID_ASC',
  UserCredentialsDistinctCountIdDesc = 'USER_CREDENTIALS_DISTINCT_COUNT_ID_DESC',
  UserCredentialsDistinctCountTenantIdAsc = 'USER_CREDENTIALS_DISTINCT_COUNT_TENANT_ID_ASC',
  UserCredentialsDistinctCountTenantIdDesc = 'USER_CREDENTIALS_DISTINCT_COUNT_TENANT_ID_DESC',
  UserCredentialsDistinctCountUpdatedAtAsc = 'USER_CREDENTIALS_DISTINCT_COUNT_UPDATED_AT_ASC',
  UserCredentialsDistinctCountUpdatedAtDesc = 'USER_CREDENTIALS_DISTINCT_COUNT_UPDATED_AT_DESC',
  UserCredentialsMaxCreatedAtAsc = 'USER_CREDENTIALS_MAX_CREATED_AT_ASC',
  UserCredentialsMaxCreatedAtDesc = 'USER_CREDENTIALS_MAX_CREATED_AT_DESC',
  UserCredentialsMaxEncryptedPasswordAsc = 'USER_CREDENTIALS_MAX_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsMaxEncryptedPasswordDesc = 'USER_CREDENTIALS_MAX_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsMaxExternalAuthIdAsc = 'USER_CREDENTIALS_MAX_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsMaxExternalAuthIdDesc = 'USER_CREDENTIALS_MAX_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsMaxIdAsc = 'USER_CREDENTIALS_MAX_ID_ASC',
  UserCredentialsMaxIdDesc = 'USER_CREDENTIALS_MAX_ID_DESC',
  UserCredentialsMaxTenantIdAsc = 'USER_CREDENTIALS_MAX_TENANT_ID_ASC',
  UserCredentialsMaxTenantIdDesc = 'USER_CREDENTIALS_MAX_TENANT_ID_DESC',
  UserCredentialsMaxUpdatedAtAsc = 'USER_CREDENTIALS_MAX_UPDATED_AT_ASC',
  UserCredentialsMaxUpdatedAtDesc = 'USER_CREDENTIALS_MAX_UPDATED_AT_DESC',
  UserCredentialsMinCreatedAtAsc = 'USER_CREDENTIALS_MIN_CREATED_AT_ASC',
  UserCredentialsMinCreatedAtDesc = 'USER_CREDENTIALS_MIN_CREATED_AT_DESC',
  UserCredentialsMinEncryptedPasswordAsc = 'USER_CREDENTIALS_MIN_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsMinEncryptedPasswordDesc = 'USER_CREDENTIALS_MIN_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsMinExternalAuthIdAsc = 'USER_CREDENTIALS_MIN_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsMinExternalAuthIdDesc = 'USER_CREDENTIALS_MIN_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsMinIdAsc = 'USER_CREDENTIALS_MIN_ID_ASC',
  UserCredentialsMinIdDesc = 'USER_CREDENTIALS_MIN_ID_DESC',
  UserCredentialsMinTenantIdAsc = 'USER_CREDENTIALS_MIN_TENANT_ID_ASC',
  UserCredentialsMinTenantIdDesc = 'USER_CREDENTIALS_MIN_TENANT_ID_DESC',
  UserCredentialsMinUpdatedAtAsc = 'USER_CREDENTIALS_MIN_UPDATED_AT_ASC',
  UserCredentialsMinUpdatedAtDesc = 'USER_CREDENTIALS_MIN_UPDATED_AT_DESC',
  UserCredentialsStddevPopulationCreatedAtAsc = 'USER_CREDENTIALS_STDDEV_POPULATION_CREATED_AT_ASC',
  UserCredentialsStddevPopulationCreatedAtDesc = 'USER_CREDENTIALS_STDDEV_POPULATION_CREATED_AT_DESC',
  UserCredentialsStddevPopulationEncryptedPasswordAsc = 'USER_CREDENTIALS_STDDEV_POPULATION_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsStddevPopulationEncryptedPasswordDesc = 'USER_CREDENTIALS_STDDEV_POPULATION_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsStddevPopulationExternalAuthIdAsc = 'USER_CREDENTIALS_STDDEV_POPULATION_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsStddevPopulationExternalAuthIdDesc = 'USER_CREDENTIALS_STDDEV_POPULATION_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsStddevPopulationIdAsc = 'USER_CREDENTIALS_STDDEV_POPULATION_ID_ASC',
  UserCredentialsStddevPopulationIdDesc = 'USER_CREDENTIALS_STDDEV_POPULATION_ID_DESC',
  UserCredentialsStddevPopulationTenantIdAsc = 'USER_CREDENTIALS_STDDEV_POPULATION_TENANT_ID_ASC',
  UserCredentialsStddevPopulationTenantIdDesc = 'USER_CREDENTIALS_STDDEV_POPULATION_TENANT_ID_DESC',
  UserCredentialsStddevPopulationUpdatedAtAsc = 'USER_CREDENTIALS_STDDEV_POPULATION_UPDATED_AT_ASC',
  UserCredentialsStddevPopulationUpdatedAtDesc = 'USER_CREDENTIALS_STDDEV_POPULATION_UPDATED_AT_DESC',
  UserCredentialsStddevSampleCreatedAtAsc = 'USER_CREDENTIALS_STDDEV_SAMPLE_CREATED_AT_ASC',
  UserCredentialsStddevSampleCreatedAtDesc = 'USER_CREDENTIALS_STDDEV_SAMPLE_CREATED_AT_DESC',
  UserCredentialsStddevSampleEncryptedPasswordAsc = 'USER_CREDENTIALS_STDDEV_SAMPLE_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsStddevSampleEncryptedPasswordDesc = 'USER_CREDENTIALS_STDDEV_SAMPLE_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsStddevSampleExternalAuthIdAsc = 'USER_CREDENTIALS_STDDEV_SAMPLE_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsStddevSampleExternalAuthIdDesc = 'USER_CREDENTIALS_STDDEV_SAMPLE_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsStddevSampleIdAsc = 'USER_CREDENTIALS_STDDEV_SAMPLE_ID_ASC',
  UserCredentialsStddevSampleIdDesc = 'USER_CREDENTIALS_STDDEV_SAMPLE_ID_DESC',
  UserCredentialsStddevSampleTenantIdAsc = 'USER_CREDENTIALS_STDDEV_SAMPLE_TENANT_ID_ASC',
  UserCredentialsStddevSampleTenantIdDesc = 'USER_CREDENTIALS_STDDEV_SAMPLE_TENANT_ID_DESC',
  UserCredentialsStddevSampleUpdatedAtAsc = 'USER_CREDENTIALS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  UserCredentialsStddevSampleUpdatedAtDesc = 'USER_CREDENTIALS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  UserCredentialsSumCreatedAtAsc = 'USER_CREDENTIALS_SUM_CREATED_AT_ASC',
  UserCredentialsSumCreatedAtDesc = 'USER_CREDENTIALS_SUM_CREATED_AT_DESC',
  UserCredentialsSumEncryptedPasswordAsc = 'USER_CREDENTIALS_SUM_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsSumEncryptedPasswordDesc = 'USER_CREDENTIALS_SUM_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsSumExternalAuthIdAsc = 'USER_CREDENTIALS_SUM_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsSumExternalAuthIdDesc = 'USER_CREDENTIALS_SUM_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsSumIdAsc = 'USER_CREDENTIALS_SUM_ID_ASC',
  UserCredentialsSumIdDesc = 'USER_CREDENTIALS_SUM_ID_DESC',
  UserCredentialsSumTenantIdAsc = 'USER_CREDENTIALS_SUM_TENANT_ID_ASC',
  UserCredentialsSumTenantIdDesc = 'USER_CREDENTIALS_SUM_TENANT_ID_DESC',
  UserCredentialsSumUpdatedAtAsc = 'USER_CREDENTIALS_SUM_UPDATED_AT_ASC',
  UserCredentialsSumUpdatedAtDesc = 'USER_CREDENTIALS_SUM_UPDATED_AT_DESC',
  UserCredentialsVariancePopulationCreatedAtAsc = 'USER_CREDENTIALS_VARIANCE_POPULATION_CREATED_AT_ASC',
  UserCredentialsVariancePopulationCreatedAtDesc = 'USER_CREDENTIALS_VARIANCE_POPULATION_CREATED_AT_DESC',
  UserCredentialsVariancePopulationEncryptedPasswordAsc = 'USER_CREDENTIALS_VARIANCE_POPULATION_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsVariancePopulationEncryptedPasswordDesc = 'USER_CREDENTIALS_VARIANCE_POPULATION_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsVariancePopulationExternalAuthIdAsc = 'USER_CREDENTIALS_VARIANCE_POPULATION_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsVariancePopulationExternalAuthIdDesc = 'USER_CREDENTIALS_VARIANCE_POPULATION_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsVariancePopulationIdAsc = 'USER_CREDENTIALS_VARIANCE_POPULATION_ID_ASC',
  UserCredentialsVariancePopulationIdDesc = 'USER_CREDENTIALS_VARIANCE_POPULATION_ID_DESC',
  UserCredentialsVariancePopulationTenantIdAsc = 'USER_CREDENTIALS_VARIANCE_POPULATION_TENANT_ID_ASC',
  UserCredentialsVariancePopulationTenantIdDesc = 'USER_CREDENTIALS_VARIANCE_POPULATION_TENANT_ID_DESC',
  UserCredentialsVariancePopulationUpdatedAtAsc = 'USER_CREDENTIALS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  UserCredentialsVariancePopulationUpdatedAtDesc = 'USER_CREDENTIALS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  UserCredentialsVarianceSampleCreatedAtAsc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  UserCredentialsVarianceSampleCreatedAtDesc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  UserCredentialsVarianceSampleEncryptedPasswordAsc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_ENCRYPTED_PASSWORD_ASC',
  UserCredentialsVarianceSampleEncryptedPasswordDesc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_ENCRYPTED_PASSWORD_DESC',
  UserCredentialsVarianceSampleExternalAuthIdAsc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_EXTERNAL_AUTH_ID_ASC',
  UserCredentialsVarianceSampleExternalAuthIdDesc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_EXTERNAL_AUTH_ID_DESC',
  UserCredentialsVarianceSampleIdAsc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_ID_ASC',
  UserCredentialsVarianceSampleIdDesc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_ID_DESC',
  UserCredentialsVarianceSampleTenantIdAsc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_TENANT_ID_ASC',
  UserCredentialsVarianceSampleTenantIdDesc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_TENANT_ID_DESC',
  UserCredentialsVarianceSampleUpdatedAtAsc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  UserCredentialsVarianceSampleUpdatedAtDesc = 'USER_CREDENTIALS_VARIANCE_SAMPLE_UPDATED_AT_DESC'
}

/** A filter to be used against Time fields. All fields are combined with a logical ‘and.’ */
export type TimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Time']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Time']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Time']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Time']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Time']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Time']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Time']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Time']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Time']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Time']>>;
};

/** All input for the `toggleFavoriteOfRoute` mutation. */
export type ToggleFavoriteOfRouteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  route?: InputMaybe<Scalars['String']>;
};

/** The output of our `toggleFavoriteOfRoute` mutation. */
export type ToggleFavoriteOfRoutePayload = {
  __typename?: 'ToggleFavoriteOfRoutePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<FavoritePage>;
  /** An edge for our `FavoritePage`. May be used by Relay 1. */
  favoritePageEdge?: Maybe<FavoritePagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `FavoritePage`. */
  trial?: Maybe<Trial>;
};


/** The output of our `toggleFavoriteOfRoute` mutation. */
export type ToggleFavoriteOfRoutePayloadFavoritePageEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritePagesOrderBy>>;
};

export type Trial = Node & {
  __typename?: 'Trial';
  avatar?: Maybe<AvatarIcon>;
  canAccessErSince?: Maybe<Scalars['Datetime']>;
  canAccessSipSince?: Maybe<Scalars['Datetime']>;
  canBeIncluded?: Maybe<Scalars['Boolean']>;
  cannotUseTheIntervention: Scalars['Boolean'];
  /** Reads a single `Commentable` that is related to this `Trial`. */
  commentableById?: Maybe<Commentable>;
  createdAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Trial`. */
  createdBy?: Maybe<User>;
  createdById?: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `EmaEntries3`. */
  emaEntries3s: EmaEntries3sConnection;
  emergencyPhoneNumbers: Array<Maybe<EmergencyContact>>;
  /** Reads and enables pagination through a set of `FavoritePage`. */
  favoritePages: FavoritePagesConnection;
  /** Reads and enables pagination through a set of `GenericEmiExercise`. */
  genericEmiExercises: GenericEmiExercisesConnection;
  /** Reads a single `GroupHome` that is related to this `Trial`. */
  groupHomeById?: Maybe<GroupHome>;
  hasAccessToASmartphone: Scalars['Boolean'];
  hasAdequateKnowledgeOfGerman: Scalars['Boolean'];
  hasAgeBetween18And25: Scalars['Boolean'];
  hasCurrentSuicidalIdeation: Scalars['Boolean'];
  hasFacedAdverseChildhoodExperiences: Scalars['Boolean'];
  hasGivenInformedConsentAt?: Maybe<Scalars['Date']>;
  humanReadableId: Scalars['BigInt'];
  id: Scalars['UUID'];
  /** Reads a single `Conversation` that is related to this `Trial`. */
  internalMessages?: Maybe<Conversation>;
  internalMessagesId: Scalars['UUID'];
  lastResumableRoute?: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `LimesurveyToken`. */
  limesurveyTokens: LimesurveyTokensConnection;
  meetsAllInclusionCriteria?: Maybe<Scalars['Boolean']>;
  meetsAllInclusionCriteriaExceptInformedConsent?: Maybe<Scalars['Boolean']>;
  meetsAnyExclusionCriteria?: Maybe<Scalars['Boolean']>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  messages?: Maybe<Conversation>;
  messagesId: Scalars['UUID'];
  name?: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PageHit`. */
  pageHits: PageHitsConnection;
  /** Reads a single `PostWaitRandomizationTicket` that is related to this `Trial`. */
  postWaitRandomizationTicket?: Maybe<PostWaitRandomizationTicket>;
  /**
   * Reads and enables pagination through a set of `PostWaitRandomizationTicket`.
   * @deprecated Please use postWaitRandomizationTicket instead
   */
  postWaitRandomizationTickets: PostWaitRandomizationTicketsConnection;
  /** Reads a single `RandomizationTicket` that is related to this `Trial`. */
  randomizationTicket?: Maybe<RandomizationTicket>;
  /**
   * Reads and enables pagination through a set of `RandomizationTicket`.
   * @deprecated Please use randomizationTicket instead
   */
  randomizationTickets: RandomizationTicketsConnection;
  /** Reads and enables pagination through a set of `RawUsageDatum`. */
  rawUsageData: RawUsageDataConnection;
  /** Reads a single `Group` that is related to this `Trial`. */
  staff?: Maybe<Group>;
  staffId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `Trial`. */
  subject?: Maybe<User>;
  subjectId: Scalars['UUID'];
  subjectsNickname?: Maybe<Scalars['String']>;
  /** Reads a single `Tenant` that is related to this `Trial`. */
  tenant?: Maybe<Tenant>;
  tenantId: Scalars['UUID'];
  updatedAt: Scalars['Datetime'];
};


export type TrialEmaEntries3sArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmaEntries3Condition>;
  filter?: InputMaybe<EmaEntries3Filter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmaEntries3sOrderBy>>;
};


export type TrialFavoritePagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FavoritePageCondition>;
  filter?: InputMaybe<FavoritePageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FavoritePagesOrderBy>>;
};


export type TrialGenericEmiExercisesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GenericEmiExerciseCondition>;
  filter?: InputMaybe<GenericEmiExerciseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GenericEmiExercisesOrderBy>>;
};


export type TrialLimesurveyTokensArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<LimesurveyTokenCondition>;
  filter?: InputMaybe<LimesurveyTokenFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<LimesurveyTokensOrderBy>>;
};


export type TrialPageHitsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PageHitCondition>;
  filter?: InputMaybe<PageHitFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PageHitsOrderBy>>;
};


export type TrialPostWaitRandomizationTicketsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostWaitRandomizationTicketCondition>;
  filter?: InputMaybe<PostWaitRandomizationTicketFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostWaitRandomizationTicketsOrderBy>>;
};


export type TrialRandomizationTicketsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RandomizationTicketCondition>;
  filter?: InputMaybe<RandomizationTicketFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RandomizationTicketsOrderBy>>;
};


export type TrialRawUsageDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RawUsageDatumCondition>;
  filter?: InputMaybe<RawUsageDatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RawUsageDataOrderBy>>;
};

export type TrialAggregates = {
  __typename?: 'TrialAggregates';
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<TrialAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<TrialDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<TrialMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<TrialMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<TrialStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<TrialStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<TrialSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<TrialVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<TrialVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `Trial` object types. */
export type TrialAggregatesFilter = {
  /** Mean average aggregate over matching `Trial` objects. */
  average?: InputMaybe<TrialAverageAggregateFilter>;
  /** Distinct count aggregate over matching `Trial` objects. */
  distinctCount?: InputMaybe<TrialDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Trial` object to be included within the aggregate. */
  filter?: InputMaybe<TrialFilter>;
  /** Maximum aggregate over matching `Trial` objects. */
  max?: InputMaybe<TrialMaxAggregateFilter>;
  /** Minimum aggregate over matching `Trial` objects. */
  min?: InputMaybe<TrialMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `Trial` objects. */
  stddevPopulation?: InputMaybe<TrialStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `Trial` objects. */
  stddevSample?: InputMaybe<TrialStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `Trial` objects. */
  sum?: InputMaybe<TrialSumAggregateFilter>;
  /** Population variance aggregate over matching `Trial` objects. */
  variancePopulation?: InputMaybe<TrialVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `Trial` objects. */
  varianceSample?: InputMaybe<TrialVarianceSampleAggregateFilter>;
};

export type TrialAverageAggregateFilter = {
  humanReadableId?: InputMaybe<BigFloatFilter>;
};

export type TrialAverageAggregates = {
  __typename?: 'TrialAverageAggregates';
  /** Mean average of humanReadableId across the matching connection */
  humanReadableId?: Maybe<Scalars['BigFloat']>;
};

/** A condition to be used against `Trial` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TrialCondition = {
  /** Checks for equality with the object’s `avatar` field. */
  avatar?: InputMaybe<AvatarIcon>;
  /** Checks for equality with the object’s `canAccessErSince` field. */
  canAccessErSince?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `canAccessSipSince` field. */
  canAccessSipSince?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `canBeIncluded` field. */
  canBeIncluded?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `cannotUseTheIntervention` field. */
  cannotUseTheIntervention?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdById` field. */
  createdById?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `emergencyPhoneNumbers` field. */
  emergencyPhoneNumbers?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  /** Checks for equality with the object’s `hasAccessToASmartphone` field. */
  hasAccessToASmartphone?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `hasAdequateKnowledgeOfGerman` field. */
  hasAdequateKnowledgeOfGerman?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `hasAgeBetween18And25` field. */
  hasAgeBetween18And25?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `hasCurrentSuicidalIdeation` field. */
  hasCurrentSuicidalIdeation?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `hasFacedAdverseChildhoodExperiences` field. */
  hasFacedAdverseChildhoodExperiences?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `hasGivenInformedConsentAt` field. */
  hasGivenInformedConsentAt?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `humanReadableId` field. */
  humanReadableId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `internalMessagesId` field. */
  internalMessagesId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `meetsAllInclusionCriteria` field. */
  meetsAllInclusionCriteria?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meetsAllInclusionCriteriaExceptInformedConsent` field. */
  meetsAllInclusionCriteriaExceptInformedConsent?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `meetsAnyExclusionCriteria` field. */
  meetsAnyExclusionCriteria?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `messagesId` field. */
  messagesId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `staffId` field. */
  staffId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `subjectId` field. */
  subjectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `subjectsNickname` field. */
  subjectsNickname?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type TrialDistinctCountAggregateFilter = {
  avatar?: InputMaybe<BigIntFilter>;
  canAccessErSince?: InputMaybe<BigIntFilter>;
  canAccessSipSince?: InputMaybe<BigIntFilter>;
  canBeIncluded?: InputMaybe<BigIntFilter>;
  cannotUseTheIntervention?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdById?: InputMaybe<BigIntFilter>;
  emergencyPhoneNumbers?: InputMaybe<BigIntFilter>;
  hasAccessToASmartphone?: InputMaybe<BigIntFilter>;
  hasAdequateKnowledgeOfGerman?: InputMaybe<BigIntFilter>;
  hasAgeBetween18And25?: InputMaybe<BigIntFilter>;
  hasCurrentSuicidalIdeation?: InputMaybe<BigIntFilter>;
  hasFacedAdverseChildhoodExperiences?: InputMaybe<BigIntFilter>;
  hasGivenInformedConsentAt?: InputMaybe<BigIntFilter>;
  humanReadableId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  internalMessagesId?: InputMaybe<BigIntFilter>;
  lastResumableRoute?: InputMaybe<JsonFilter>;
  meetsAllInclusionCriteria?: InputMaybe<BigIntFilter>;
  meetsAllInclusionCriteriaExceptInformedConsent?: InputMaybe<BigIntFilter>;
  meetsAnyExclusionCriteria?: InputMaybe<BigIntFilter>;
  messagesId?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  staffId?: InputMaybe<BigIntFilter>;
  subjectId?: InputMaybe<BigIntFilter>;
  subjectsNickname?: InputMaybe<BigIntFilter>;
  tenantId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type TrialDistinctCountAggregates = {
  __typename?: 'TrialDistinctCountAggregates';
  /** Distinct count of avatar across the matching connection */
  avatar?: Maybe<Scalars['BigInt']>;
  /** Distinct count of canAccessErSince across the matching connection */
  canAccessErSince?: Maybe<Scalars['BigInt']>;
  /** Distinct count of canAccessSipSince across the matching connection */
  canAccessSipSince?: Maybe<Scalars['BigInt']>;
  /** Distinct count of canBeIncluded across the matching connection */
  canBeIncluded?: Maybe<Scalars['BigInt']>;
  /** Distinct count of cannotUseTheIntervention across the matching connection */
  cannotUseTheIntervention?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdById across the matching connection */
  createdById?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emergencyPhoneNumbers across the matching connection */
  emergencyPhoneNumbers?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hasAccessToASmartphone across the matching connection */
  hasAccessToASmartphone?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hasAdequateKnowledgeOfGerman across the matching connection */
  hasAdequateKnowledgeOfGerman?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hasAgeBetween18And25 across the matching connection */
  hasAgeBetween18And25?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hasCurrentSuicidalIdeation across the matching connection */
  hasCurrentSuicidalIdeation?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hasFacedAdverseChildhoodExperiences across the matching connection */
  hasFacedAdverseChildhoodExperiences?: Maybe<Scalars['BigInt']>;
  /** Distinct count of hasGivenInformedConsentAt across the matching connection */
  hasGivenInformedConsentAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of humanReadableId across the matching connection */
  humanReadableId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of internalMessagesId across the matching connection */
  internalMessagesId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of this field across the matching connection. */
  lastResumableRoute?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meetsAllInclusionCriteria across the matching connection */
  meetsAllInclusionCriteria?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meetsAllInclusionCriteriaExceptInformedConsent across the matching connection */
  meetsAllInclusionCriteriaExceptInformedConsent?: Maybe<Scalars['BigInt']>;
  /** Distinct count of meetsAnyExclusionCriteria across the matching connection */
  meetsAnyExclusionCriteria?: Maybe<Scalars['BigInt']>;
  /** Distinct count of messagesId across the matching connection */
  messagesId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars['BigInt']>;
  /** Distinct count of staffId across the matching connection */
  staffId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of subjectId across the matching connection */
  subjectId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of subjectsNickname across the matching connection */
  subjectsNickname?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenantId across the matching connection */
  tenantId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `Trial` object types. All fields are combined with a logical ‘and.’ */
export type TrialFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TrialFilter>>;
  /** Filter by the object’s `avatar` field. */
  avatar?: InputMaybe<AvatarIconFilter>;
  /** Filter by the object’s `canAccessErSince` field. */
  canAccessErSince?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `canAccessSipSince` field. */
  canAccessSipSince?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `canBeIncluded` field. */
  canBeIncluded?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `cannotUseTheIntervention` field. */
  cannotUseTheIntervention?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `commentableById` relation. */
  commentableById?: InputMaybe<CommentableFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` relation. */
  createdBy?: InputMaybe<UserFilter>;
  /** A related `createdBy` exists. */
  createdByExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `createdById` field. */
  createdById?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `emaEntries3s` relation. */
  emaEntries3s?: InputMaybe<TrialToManyEmaEntries3Filter>;
  /** Some related `emaEntries3s` exist. */
  emaEntries3sExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `favoritePages` relation. */
  favoritePages?: InputMaybe<TrialToManyFavoritePageFilter>;
  /** Some related `favoritePages` exist. */
  favoritePagesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `genericEmiExercises` relation. */
  genericEmiExercises?: InputMaybe<TrialToManyGenericEmiExerciseFilter>;
  /** Some related `genericEmiExercises` exist. */
  genericEmiExercisesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `groupHomeById` relation. */
  groupHomeById?: InputMaybe<GroupHomeFilter>;
  /** Filter by the object’s `hasAccessToASmartphone` field. */
  hasAccessToASmartphone?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasAdequateKnowledgeOfGerman` field. */
  hasAdequateKnowledgeOfGerman?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasAgeBetween18And25` field. */
  hasAgeBetween18And25?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasCurrentSuicidalIdeation` field. */
  hasCurrentSuicidalIdeation?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasFacedAdverseChildhoodExperiences` field. */
  hasFacedAdverseChildhoodExperiences?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `hasGivenInformedConsentAt` field. */
  hasGivenInformedConsentAt?: InputMaybe<DateFilter>;
  /** Filter by the object’s `humanReadableId` field. */
  humanReadableId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `internalMessages` relation. */
  internalMessages?: InputMaybe<ConversationFilter>;
  /** Filter by the object’s `internalMessagesId` field. */
  internalMessagesId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lastResumableRoute` field. */
  lastResumableRoute?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `limesurveyTokens` relation. */
  limesurveyTokens?: InputMaybe<TrialToManyLimesurveyTokenFilter>;
  /** Some related `limesurveyTokens` exist. */
  limesurveyTokensExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `meetsAllInclusionCriteria` field. */
  meetsAllInclusionCriteria?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meetsAllInclusionCriteriaExceptInformedConsent` field. */
  meetsAllInclusionCriteriaExceptInformedConsent?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `meetsAnyExclusionCriteria` field. */
  meetsAnyExclusionCriteria?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messages` relation. */
  messages?: InputMaybe<ConversationFilter>;
  /** Filter by the object’s `messagesId` field. */
  messagesId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TrialFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TrialFilter>>;
  /** Filter by the object’s `pageHits` relation. */
  pageHits?: InputMaybe<TrialToManyPageHitFilter>;
  /** Some related `pageHits` exist. */
  pageHitsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `postWaitRandomizationTicket` relation. */
  postWaitRandomizationTicket?: InputMaybe<PostWaitRandomizationTicketFilter>;
  /** A related `postWaitRandomizationTicket` exists. */
  postWaitRandomizationTicketExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `randomizationTicket` relation. */
  randomizationTicket?: InputMaybe<RandomizationTicketFilter>;
  /** A related `randomizationTicket` exists. */
  randomizationTicketExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `rawUsageData` relation. */
  rawUsageData?: InputMaybe<TrialToManyRawUsageDatumFilter>;
  /** Some related `rawUsageData` exist. */
  rawUsageDataExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `staff` relation. */
  staff?: InputMaybe<GroupFilter>;
  /** Filter by the object’s `staffId` field. */
  staffId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `subject` relation. */
  subject?: InputMaybe<UserFilter>;
  /** Filter by the object’s `subjectId` field. */
  subjectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `subjectsNickname` field. */
  subjectsNickname?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tenant` relation. */
  tenant?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Trial` */
export type TrialInput = {
  avatar?: InputMaybe<AvatarIcon>;
  canAccessErSince?: InputMaybe<Scalars['Datetime']>;
  canAccessSipSince?: InputMaybe<Scalars['Datetime']>;
  canBeIncluded?: InputMaybe<Scalars['Boolean']>;
  cannotUseTheIntervention?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdById?: InputMaybe<Scalars['UUID']>;
  emergencyPhoneNumbers?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  hasAccessToASmartphone?: InputMaybe<Scalars['Boolean']>;
  hasAdequateKnowledgeOfGerman?: InputMaybe<Scalars['Boolean']>;
  hasAgeBetween18And25?: InputMaybe<Scalars['Boolean']>;
  hasCurrentSuicidalIdeation?: InputMaybe<Scalars['Boolean']>;
  hasFacedAdverseChildhoodExperiences?: InputMaybe<Scalars['Boolean']>;
  hasGivenInformedConsentAt?: InputMaybe<Scalars['Date']>;
  humanReadableId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['UUID']>;
  internalMessagesId?: InputMaybe<Scalars['UUID']>;
  meetsAllInclusionCriteria?: InputMaybe<Scalars['Boolean']>;
  meetsAllInclusionCriteriaExceptInformedConsent?: InputMaybe<Scalars['Boolean']>;
  meetsAnyExclusionCriteria?: InputMaybe<Scalars['Boolean']>;
  messagesId?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['UUID']>;
  subjectId: Scalars['UUID'];
  subjectsNickname?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type TrialMaxAggregateFilter = {
  humanReadableId?: InputMaybe<BigIntFilter>;
};

export type TrialMaxAggregates = {
  __typename?: 'TrialMaxAggregates';
  /** Maximum of humanReadableId across the matching connection */
  humanReadableId?: Maybe<Scalars['BigInt']>;
};

export type TrialMinAggregateFilter = {
  humanReadableId?: InputMaybe<BigIntFilter>;
};

export type TrialMinAggregates = {
  __typename?: 'TrialMinAggregates';
  /** Minimum of humanReadableId across the matching connection */
  humanReadableId?: Maybe<Scalars['BigInt']>;
};

/** Represents an update to a `Trial`. Fields that are set will be updated. */
export type TrialPatch = {
  avatar?: InputMaybe<AvatarIcon>;
  canAccessErSince?: InputMaybe<Scalars['Datetime']>;
  canAccessSipSince?: InputMaybe<Scalars['Datetime']>;
  canBeIncluded?: InputMaybe<Scalars['Boolean']>;
  cannotUseTheIntervention?: InputMaybe<Scalars['Boolean']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdById?: InputMaybe<Scalars['UUID']>;
  emergencyPhoneNumbers?: InputMaybe<Array<InputMaybe<EmergencyContactInput>>>;
  hasAccessToASmartphone?: InputMaybe<Scalars['Boolean']>;
  hasAdequateKnowledgeOfGerman?: InputMaybe<Scalars['Boolean']>;
  hasAgeBetween18And25?: InputMaybe<Scalars['Boolean']>;
  hasCurrentSuicidalIdeation?: InputMaybe<Scalars['Boolean']>;
  hasFacedAdverseChildhoodExperiences?: InputMaybe<Scalars['Boolean']>;
  hasGivenInformedConsentAt?: InputMaybe<Scalars['Date']>;
  humanReadableId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['UUID']>;
  internalMessagesId?: InputMaybe<Scalars['UUID']>;
  meetsAllInclusionCriteria?: InputMaybe<Scalars['Boolean']>;
  meetsAllInclusionCriteriaExceptInformedConsent?: InputMaybe<Scalars['Boolean']>;
  meetsAnyExclusionCriteria?: InputMaybe<Scalars['Boolean']>;
  messagesId?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  staffId?: InputMaybe<Scalars['UUID']>;
  subjectId?: InputMaybe<Scalars['UUID']>;
  subjectsNickname?: InputMaybe<Scalars['String']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type TrialStddevPopulationAggregateFilter = {
  humanReadableId?: InputMaybe<BigFloatFilter>;
};

export type TrialStddevPopulationAggregates = {
  __typename?: 'TrialStddevPopulationAggregates';
  /** Population standard deviation of humanReadableId across the matching connection */
  humanReadableId?: Maybe<Scalars['BigFloat']>;
};

export type TrialStddevSampleAggregateFilter = {
  humanReadableId?: InputMaybe<BigFloatFilter>;
};

export type TrialStddevSampleAggregates = {
  __typename?: 'TrialStddevSampleAggregates';
  /** Sample standard deviation of humanReadableId across the matching connection */
  humanReadableId?: Maybe<Scalars['BigFloat']>;
};

export type TrialSumAggregateFilter = {
  humanReadableId?: InputMaybe<BigFloatFilter>;
};

export type TrialSumAggregates = {
  __typename?: 'TrialSumAggregates';
  /** Sum of humanReadableId across the matching connection */
  humanReadableId: Scalars['BigFloat'];
};

/** A filter to be used against many `EmaEntries3` object types. All fields are combined with a logical ‘and.’ */
export type TrialToManyEmaEntries3Filter = {
  /** Aggregates across related `EmaEntries3` match the filter criteria. */
  aggregates?: InputMaybe<EmaEntries3AggregatesFilter>;
  /** Every related `EmaEntries3` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<EmaEntries3Filter>;
  /** No related `EmaEntries3` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<EmaEntries3Filter>;
  /** Some related `EmaEntries3` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<EmaEntries3Filter>;
};

/** A filter to be used against many `FavoritePage` object types. All fields are combined with a logical ‘and.’ */
export type TrialToManyFavoritePageFilter = {
  /** Aggregates across related `FavoritePage` match the filter criteria. */
  aggregates?: InputMaybe<FavoritePageAggregatesFilter>;
  /** Every related `FavoritePage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FavoritePageFilter>;
  /** No related `FavoritePage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FavoritePageFilter>;
  /** Some related `FavoritePage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FavoritePageFilter>;
};

/** A filter to be used against many `GenericEmiExercise` object types. All fields are combined with a logical ‘and.’ */
export type TrialToManyGenericEmiExerciseFilter = {
  /** Aggregates across related `GenericEmiExercise` match the filter criteria. */
  aggregates?: InputMaybe<GenericEmiExerciseAggregatesFilter>;
  /** Every related `GenericEmiExercise` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<GenericEmiExerciseFilter>;
  /** No related `GenericEmiExercise` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<GenericEmiExerciseFilter>;
  /** Some related `GenericEmiExercise` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<GenericEmiExerciseFilter>;
};

/** A filter to be used against many `LimesurveyToken` object types. All fields are combined with a logical ‘and.’ */
export type TrialToManyLimesurveyTokenFilter = {
  /** Aggregates across related `LimesurveyToken` match the filter criteria. */
  aggregates?: InputMaybe<LimesurveyTokenAggregatesFilter>;
  /** Every related `LimesurveyToken` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<LimesurveyTokenFilter>;
  /** No related `LimesurveyToken` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<LimesurveyTokenFilter>;
  /** Some related `LimesurveyToken` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<LimesurveyTokenFilter>;
};

/** A filter to be used against many `PageHit` object types. All fields are combined with a logical ‘and.’ */
export type TrialToManyPageHitFilter = {
  /** Aggregates across related `PageHit` match the filter criteria. */
  aggregates?: InputMaybe<PageHitAggregatesFilter>;
  /** Every related `PageHit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PageHitFilter>;
  /** No related `PageHit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PageHitFilter>;
  /** Some related `PageHit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PageHitFilter>;
};

/** A filter to be used against many `RawUsageDatum` object types. All fields are combined with a logical ‘and.’ */
export type TrialToManyRawUsageDatumFilter = {
  /** Aggregates across related `RawUsageDatum` match the filter criteria. */
  aggregates?: InputMaybe<RawUsageDatumAggregatesFilter>;
  /** Every related `RawUsageDatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RawUsageDatumFilter>;
  /** No related `RawUsageDatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RawUsageDatumFilter>;
  /** Some related `RawUsageDatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RawUsageDatumFilter>;
};

export type TrialVariancePopulationAggregateFilter = {
  humanReadableId?: InputMaybe<BigFloatFilter>;
};

export type TrialVariancePopulationAggregates = {
  __typename?: 'TrialVariancePopulationAggregates';
  /** Population variance of humanReadableId across the matching connection */
  humanReadableId?: Maybe<Scalars['BigFloat']>;
};

export type TrialVarianceSampleAggregateFilter = {
  humanReadableId?: InputMaybe<BigFloatFilter>;
};

export type TrialVarianceSampleAggregates = {
  __typename?: 'TrialVarianceSampleAggregates';
  /** Sample variance of humanReadableId across the matching connection */
  humanReadableId?: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `Trial` values. */
export type TrialsConnection = {
  __typename?: 'TrialsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<TrialAggregates>;
  /** A list of edges which contains the `Trial` and cursor to aid in pagination. */
  edges: Array<TrialsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<TrialAggregates>>;
  /** A list of `Trial` objects. */
  nodes: Array<Trial>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Trial` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `Trial` values. */
export type TrialsConnectionGroupedAggregatesArgs = {
  groupBy: Array<TrialsGroupBy>;
  having?: InputMaybe<TrialsHavingInput>;
};

/** A `Trial` edge in the connection. */
export type TrialsEdge = {
  __typename?: 'TrialsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Trial` at the end of the edge. */
  node: Trial;
};

/** Grouping methods for `Trial` for usage during aggregation. */
export enum TrialsGroupBy {
  Avatar = 'AVATAR',
  CannotUseTheIntervention = 'CANNOT_USE_THE_INTERVENTION',
  CanAccessErSince = 'CAN_ACCESS_ER_SINCE',
  CanAccessErSinceTruncatedToDay = 'CAN_ACCESS_ER_SINCE_TRUNCATED_TO_DAY',
  CanAccessErSinceTruncatedToHour = 'CAN_ACCESS_ER_SINCE_TRUNCATED_TO_HOUR',
  CanAccessSipSince = 'CAN_ACCESS_SIP_SINCE',
  CanAccessSipSinceTruncatedToDay = 'CAN_ACCESS_SIP_SINCE_TRUNCATED_TO_DAY',
  CanAccessSipSinceTruncatedToHour = 'CAN_ACCESS_SIP_SINCE_TRUNCATED_TO_HOUR',
  CanBeIncluded = 'CAN_BE_INCLUDED',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  CreatedById = 'CREATED_BY_ID',
  EmergencyPhoneNumbers = 'EMERGENCY_PHONE_NUMBERS',
  HasAccessToASmartphone = 'HAS_ACCESS_TO_A_SMARTPHONE',
  HasAdequateKnowledgeOfGerman = 'HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN',
  HasAgeBetween_18And_25 = 'HAS_AGE_BETWEEN_18_AND_25',
  HasCurrentSuicidalIdeation = 'HAS_CURRENT_SUICIDAL_IDEATION',
  HasFacedAdverseChildhoodExperiences = 'HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES',
  HasGivenInformedConsentAt = 'HAS_GIVEN_INFORMED_CONSENT_AT',
  InternalMessagesId = 'INTERNAL_MESSAGES_ID',
  MeetsAllInclusionCriteria = 'MEETS_ALL_INCLUSION_CRITERIA',
  MeetsAllInclusionCriteriaExceptInformedConsent = 'MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT',
  MeetsAnyExclusionCriteria = 'MEETS_ANY_EXCLUSION_CRITERIA',
  MessagesId = 'MESSAGES_ID',
  StaffId = 'STAFF_ID',
  SubjectsNickname = 'SUBJECTS_NICKNAME',
  SubjectId = 'SUBJECT_ID',
  TenantId = 'TENANT_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type TrialsHavingAverageInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TrialsHavingDistinctCountInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Trial` aggregates. */
export type TrialsHavingInput = {
  AND?: InputMaybe<Array<TrialsHavingInput>>;
  OR?: InputMaybe<Array<TrialsHavingInput>>;
  average?: InputMaybe<TrialsHavingAverageInput>;
  distinctCount?: InputMaybe<TrialsHavingDistinctCountInput>;
  max?: InputMaybe<TrialsHavingMaxInput>;
  min?: InputMaybe<TrialsHavingMinInput>;
  stddevPopulation?: InputMaybe<TrialsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<TrialsHavingStddevSampleInput>;
  sum?: InputMaybe<TrialsHavingSumInput>;
  variancePopulation?: InputMaybe<TrialsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<TrialsHavingVarianceSampleInput>;
};

export type TrialsHavingMaxInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TrialsHavingMinInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TrialsHavingStddevPopulationInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TrialsHavingStddevSampleInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TrialsHavingSumInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TrialsHavingVariancePopulationInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type TrialsHavingVarianceSampleInput = {
  canAccessErSince?: InputMaybe<HavingDatetimeFilter>;
  canAccessSipSince?: InputMaybe<HavingDatetimeFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  hasGivenInformedConsentAt?: InputMaybe<HavingDatetimeFilter>;
  humanReadableId?: InputMaybe<HavingBigintFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `Trial`. */
export enum TrialsOrderBy {
  AvatarAsc = 'AVATAR_ASC',
  AvatarDesc = 'AVATAR_DESC',
  CannotUseTheInterventionAsc = 'CANNOT_USE_THE_INTERVENTION_ASC',
  CannotUseTheInterventionDesc = 'CANNOT_USE_THE_INTERVENTION_DESC',
  CanAccessErSinceAsc = 'CAN_ACCESS_ER_SINCE_ASC',
  CanAccessErSinceDesc = 'CAN_ACCESS_ER_SINCE_DESC',
  CanAccessSipSinceAsc = 'CAN_ACCESS_SIP_SINCE_ASC',
  CanAccessSipSinceDesc = 'CAN_ACCESS_SIP_SINCE_DESC',
  CanBeIncludedAsc = 'CAN_BE_INCLUDED_ASC',
  CanBeIncludedDesc = 'CAN_BE_INCLUDED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByIdAsc = 'CREATED_BY_ID_ASC',
  CreatedByIdDesc = 'CREATED_BY_ID_DESC',
  EmaEntries3SAverageAngryAsc = 'EMA_ENTRIES3S_AVERAGE_ANGRY_ASC',
  EmaEntries3SAverageAngryDesc = 'EMA_ENTRIES3S_AVERAGE_ANGRY_DESC',
  EmaEntries3SAverageAnxiousAsc = 'EMA_ENTRIES3S_AVERAGE_ANXIOUS_ASC',
  EmaEntries3SAverageAnxiousDesc = 'EMA_ENTRIES3S_AVERAGE_ANXIOUS_DESC',
  EmaEntries3SAverageAnxiousDuringContactAsc = 'EMA_ENTRIES3S_AVERAGE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SAverageAnxiousDuringContactDesc = 'EMA_ENTRIES3S_AVERAGE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SAverageAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_AVERAGE_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SAverageAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_AVERAGE_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SAverageAvoidedSocialContactAsc = 'EMA_ENTRIES3S_AVERAGE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SAverageAvoidedSocialContactDesc = 'EMA_ENTRIES3S_AVERAGE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SAverageBurdensAsc = 'EMA_ENTRIES3S_AVERAGE_BURDENS_ASC',
  EmaEntries3SAverageBurdensDesc = 'EMA_ENTRIES3S_AVERAGE_BURDENS_DESC',
  EmaEntries3SAverageComfortableDuringContactAsc = 'EMA_ENTRIES3S_AVERAGE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SAverageComfortableDuringContactDesc = 'EMA_ENTRIES3S_AVERAGE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SAverageCompletedAtAsc = 'EMA_ENTRIES3S_AVERAGE_COMPLETED_AT_ASC',
  EmaEntries3SAverageCompletedAtDesc = 'EMA_ENTRIES3S_AVERAGE_COMPLETED_AT_DESC',
  EmaEntries3SAverageCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_AVERAGE_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SAverageCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_AVERAGE_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SAverageCreatedAtAsc = 'EMA_ENTRIES3S_AVERAGE_CREATED_AT_ASC',
  EmaEntries3SAverageCreatedAtDesc = 'EMA_ENTRIES3S_AVERAGE_CREATED_AT_DESC',
  EmaEntries3SAverageDeletedAsc = 'EMA_ENTRIES3S_AVERAGE_DELETED_ASC',
  EmaEntries3SAverageDeletedDesc = 'EMA_ENTRIES3S_AVERAGE_DELETED_DESC',
  EmaEntries3SAverageDeterminedAsc = 'EMA_ENTRIES3S_AVERAGE_DETERMINED_ASC',
  EmaEntries3SAverageDeterminedDesc = 'EMA_ENTRIES3S_AVERAGE_DETERMINED_DESC',
  EmaEntries3SAverageEnergeticAsc = 'EMA_ENTRIES3S_AVERAGE_ENERGETIC_ASC',
  EmaEntries3SAverageEnergeticDesc = 'EMA_ENTRIES3S_AVERAGE_ENERGETIC_DESC',
  EmaEntries3SAverageEnthusiasticAsc = 'EMA_ENTRIES3S_AVERAGE_ENTHUSIASTIC_ASC',
  EmaEntries3SAverageEnthusiasticDesc = 'EMA_ENTRIES3S_AVERAGE_ENTHUSIASTIC_DESC',
  EmaEntries3SAverageExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_AVERAGE_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SAverageExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_AVERAGE_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SAverageExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_AVERAGE_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SAverageExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_AVERAGE_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SAverageGuiltyAsc = 'EMA_ENTRIES3S_AVERAGE_GUILTY_ASC',
  EmaEntries3SAverageGuiltyDesc = 'EMA_ENTRIES3S_AVERAGE_GUILTY_DESC',
  EmaEntries3SAverageHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_AVERAGE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SAverageHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_AVERAGE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SAverageHappyAsc = 'EMA_ENTRIES3S_AVERAGE_HAPPY_ASC',
  EmaEntries3SAverageHappyDesc = 'EMA_ENTRIES3S_AVERAGE_HAPPY_DESC',
  EmaEntries3SAverageHappyDuringContactAsc = 'EMA_ENTRIES3S_AVERAGE_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SAverageHappyDuringContactDesc = 'EMA_ENTRIES3S_AVERAGE_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SAverageHelplessAsc = 'EMA_ENTRIES3S_AVERAGE_HELPLESS_ASC',
  EmaEntries3SAverageHelplessDesc = 'EMA_ENTRIES3S_AVERAGE_HELPLESS_DESC',
  EmaEntries3SAverageIdAsc = 'EMA_ENTRIES3S_AVERAGE_ID_ASC',
  EmaEntries3SAverageIdDesc = 'EMA_ENTRIES3S_AVERAGE_ID_DESC',
  EmaEntries3SAverageInspiredDuringContactAsc = 'EMA_ENTRIES3S_AVERAGE_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SAverageInspiredDuringContactDesc = 'EMA_ENTRIES3S_AVERAGE_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SAverageLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_AVERAGE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SAverageLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_AVERAGE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SAverageLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_AVERAGE_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SAverageLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_AVERAGE_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SAverageLonelyAsc = 'EMA_ENTRIES3S_AVERAGE_LONELY_ASC',
  EmaEntries3SAverageLonelyDesc = 'EMA_ENTRIES3S_AVERAGE_LONELY_DESC',
  EmaEntries3SAverageLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_AVERAGE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SAverageLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_AVERAGE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SAverageNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_AVERAGE_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SAverageNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_AVERAGE_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SAveragePlannedHowToProceedAsc = 'EMA_ENTRIES3S_AVERAGE_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SAveragePlannedHowToProceedDesc = 'EMA_ENTRIES3S_AVERAGE_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SAverageRelaxedAsc = 'EMA_ENTRIES3S_AVERAGE_RELAXED_ASC',
  EmaEntries3SAverageRelaxedDesc = 'EMA_ENTRIES3S_AVERAGE_RELAXED_DESC',
  EmaEntries3SAverageSadAsc = 'EMA_ENTRIES3S_AVERAGE_SAD_ASC',
  EmaEntries3SAverageSadDesc = 'EMA_ENTRIES3S_AVERAGE_SAD_DESC',
  EmaEntries3SAverageSatisfiedAsc = 'EMA_ENTRIES3S_AVERAGE_SATISFIED_ASC',
  EmaEntries3SAverageSatisfiedDesc = 'EMA_ENTRIES3S_AVERAGE_SATISFIED_DESC',
  EmaEntries3SAverageScheduledAtAsc = 'EMA_ENTRIES3S_AVERAGE_SCHEDULED_AT_ASC',
  EmaEntries3SAverageScheduledAtDesc = 'EMA_ENTRIES3S_AVERAGE_SCHEDULED_AT_DESC',
  EmaEntries3SAverageSmsReceivedAtAsc = 'EMA_ENTRIES3S_AVERAGE_SMS_RECEIVED_AT_ASC',
  EmaEntries3SAverageSmsReceivedAtDesc = 'EMA_ENTRIES3S_AVERAGE_SMS_RECEIVED_AT_DESC',
  EmaEntries3SAverageSocialDistressAsc = 'EMA_ENTRIES3S_AVERAGE_SOCIAL_DISTRESS_ASC',
  EmaEntries3SAverageSocialDistressDesc = 'EMA_ENTRIES3S_AVERAGE_SOCIAL_DISTRESS_DESC',
  EmaEntries3SAverageStartedAtAsc = 'EMA_ENTRIES3S_AVERAGE_STARTED_AT_ASC',
  EmaEntries3SAverageStartedAtDesc = 'EMA_ENTRIES3S_AVERAGE_STARTED_AT_DESC',
  EmaEntries3SAverageStrainAsc = 'EMA_ENTRIES3S_AVERAGE_STRAIN_ASC',
  EmaEntries3SAverageStrainDesc = 'EMA_ENTRIES3S_AVERAGE_STRAIN_DESC',
  EmaEntries3SAverageStrengthAsc = 'EMA_ENTRIES3S_AVERAGE_STRENGTH_ASC',
  EmaEntries3SAverageStrengthDesc = 'EMA_ENTRIES3S_AVERAGE_STRENGTH_DESC',
  EmaEntries3SAverageStressedAsc = 'EMA_ENTRIES3S_AVERAGE_STRESSED_ASC',
  EmaEntries3SAverageStressedDesc = 'EMA_ENTRIES3S_AVERAGE_STRESSED_DESC',
  EmaEntries3SAverageTenseDuringContactAsc = 'EMA_ENTRIES3S_AVERAGE_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SAverageTenseDuringContactDesc = 'EMA_ENTRIES3S_AVERAGE_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SAverageTestyAsc = 'EMA_ENTRIES3S_AVERAGE_TESTY_ASC',
  EmaEntries3SAverageTestyDesc = 'EMA_ENTRIES3S_AVERAGE_TESTY_DESC',
  EmaEntries3SAverageTestyDuringContactAsc = 'EMA_ENTRIES3S_AVERAGE_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SAverageTestyDuringContactDesc = 'EMA_ENTRIES3S_AVERAGE_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SAverageTrialIdAsc = 'EMA_ENTRIES3S_AVERAGE_TRIAL_ID_ASC',
  EmaEntries3SAverageTrialIdDesc = 'EMA_ENTRIES3S_AVERAGE_TRIAL_ID_DESC',
  EmaEntries3SAverageTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_AVERAGE_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SAverageTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_AVERAGE_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SAverageTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_AVERAGE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SAverageTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_AVERAGE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SAverageTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_AVERAGE_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SAverageTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_AVERAGE_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SAverageTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_AVERAGE_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SAverageTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_AVERAGE_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SAverageUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_AVERAGE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SAverageUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_AVERAGE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SAverageUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_AVERAGE_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SAverageUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_AVERAGE_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SAverageUpdatedAtAsc = 'EMA_ENTRIES3S_AVERAGE_UPDATED_AT_ASC',
  EmaEntries3SAverageUpdatedAtDesc = 'EMA_ENTRIES3S_AVERAGE_UPDATED_AT_DESC',
  EmaEntries3SAverageWaveAsc = 'EMA_ENTRIES3S_AVERAGE_WAVE_ASC',
  EmaEntries3SAverageWaveDesc = 'EMA_ENTRIES3S_AVERAGE_WAVE_DESC',
  EmaEntries3SAverageWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_AVERAGE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SAverageWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_AVERAGE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries3SCountAsc = 'EMA_ENTRIES3S_COUNT_ASC',
  EmaEntries3SCountDesc = 'EMA_ENTRIES3S_COUNT_DESC',
  EmaEntries3SDistinctCountAngryAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ANGRY_ASC',
  EmaEntries3SDistinctCountAngryDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ANGRY_DESC',
  EmaEntries3SDistinctCountAnxiousAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ANXIOUS_ASC',
  EmaEntries3SDistinctCountAnxiousDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ANXIOUS_DESC',
  EmaEntries3SDistinctCountAnxiousDuringContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SDistinctCountAnxiousDuringContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SDistinctCountAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SDistinctCountAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SDistinctCountAvoidedSocialContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SDistinctCountAvoidedSocialContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SDistinctCountBurdensAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_BURDENS_ASC',
  EmaEntries3SDistinctCountBurdensDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_BURDENS_DESC',
  EmaEntries3SDistinctCountComfortableDuringContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SDistinctCountComfortableDuringContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SDistinctCountCompletedAtAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_COMPLETED_AT_ASC',
  EmaEntries3SDistinctCountCompletedAtDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_COMPLETED_AT_DESC',
  EmaEntries3SDistinctCountCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SDistinctCountCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SDistinctCountCreatedAtAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_CREATED_AT_ASC',
  EmaEntries3SDistinctCountCreatedAtDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_CREATED_AT_DESC',
  EmaEntries3SDistinctCountDeletedAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_DELETED_ASC',
  EmaEntries3SDistinctCountDeletedDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_DELETED_DESC',
  EmaEntries3SDistinctCountDeterminedAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_DETERMINED_ASC',
  EmaEntries3SDistinctCountDeterminedDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_DETERMINED_DESC',
  EmaEntries3SDistinctCountEnergeticAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ENERGETIC_ASC',
  EmaEntries3SDistinctCountEnergeticDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ENERGETIC_DESC',
  EmaEntries3SDistinctCountEnthusiasticAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ENTHUSIASTIC_ASC',
  EmaEntries3SDistinctCountEnthusiasticDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ENTHUSIASTIC_DESC',
  EmaEntries3SDistinctCountExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SDistinctCountExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SDistinctCountExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SDistinctCountExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SDistinctCountGuiltyAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_GUILTY_ASC',
  EmaEntries3SDistinctCountGuiltyDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_GUILTY_DESC',
  EmaEntries3SDistinctCountHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SDistinctCountHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SDistinctCountHappyAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_HAPPY_ASC',
  EmaEntries3SDistinctCountHappyDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_HAPPY_DESC',
  EmaEntries3SDistinctCountHappyDuringContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SDistinctCountHappyDuringContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SDistinctCountHelplessAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_HELPLESS_ASC',
  EmaEntries3SDistinctCountHelplessDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_HELPLESS_DESC',
  EmaEntries3SDistinctCountIdAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ID_ASC',
  EmaEntries3SDistinctCountIdDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_ID_DESC',
  EmaEntries3SDistinctCountInspiredDuringContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SDistinctCountInspiredDuringContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SDistinctCountLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SDistinctCountLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SDistinctCountLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SDistinctCountLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SDistinctCountLonelyAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_LONELY_ASC',
  EmaEntries3SDistinctCountLonelyDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_LONELY_DESC',
  EmaEntries3SDistinctCountLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SDistinctCountLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SDistinctCountNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SDistinctCountNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SDistinctCountPlannedHowToProceedAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SDistinctCountPlannedHowToProceedDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SDistinctCountRelaxedAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_RELAXED_ASC',
  EmaEntries3SDistinctCountRelaxedDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_RELAXED_DESC',
  EmaEntries3SDistinctCountSadAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SAD_ASC',
  EmaEntries3SDistinctCountSadDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SAD_DESC',
  EmaEntries3SDistinctCountSatisfiedAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SATISFIED_ASC',
  EmaEntries3SDistinctCountSatisfiedDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SATISFIED_DESC',
  EmaEntries3SDistinctCountScheduledAtAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SCHEDULED_AT_ASC',
  EmaEntries3SDistinctCountScheduledAtDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SCHEDULED_AT_DESC',
  EmaEntries3SDistinctCountSmsReceivedAtAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SMS_RECEIVED_AT_ASC',
  EmaEntries3SDistinctCountSmsReceivedAtDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SMS_RECEIVED_AT_DESC',
  EmaEntries3SDistinctCountSocialDistressAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SOCIAL_DISTRESS_ASC',
  EmaEntries3SDistinctCountSocialDistressDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_SOCIAL_DISTRESS_DESC',
  EmaEntries3SDistinctCountStartedAtAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_STARTED_AT_ASC',
  EmaEntries3SDistinctCountStartedAtDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_STARTED_AT_DESC',
  EmaEntries3SDistinctCountStrainAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_STRAIN_ASC',
  EmaEntries3SDistinctCountStrainDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_STRAIN_DESC',
  EmaEntries3SDistinctCountStrengthAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_STRENGTH_ASC',
  EmaEntries3SDistinctCountStrengthDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_STRENGTH_DESC',
  EmaEntries3SDistinctCountStressedAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_STRESSED_ASC',
  EmaEntries3SDistinctCountStressedDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_STRESSED_DESC',
  EmaEntries3SDistinctCountTenseDuringContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SDistinctCountTenseDuringContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SDistinctCountTestyAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TESTY_ASC',
  EmaEntries3SDistinctCountTestyDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TESTY_DESC',
  EmaEntries3SDistinctCountTestyDuringContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SDistinctCountTestyDuringContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SDistinctCountTrialIdAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TRIAL_ID_ASC',
  EmaEntries3SDistinctCountTrialIdDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TRIAL_ID_DESC',
  EmaEntries3SDistinctCountTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SDistinctCountTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SDistinctCountTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SDistinctCountTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SDistinctCountTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SDistinctCountTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SDistinctCountTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SDistinctCountTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SDistinctCountUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SDistinctCountUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SDistinctCountUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SDistinctCountUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SDistinctCountUpdatedAtAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_UPDATED_AT_ASC',
  EmaEntries3SDistinctCountUpdatedAtDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_UPDATED_AT_DESC',
  EmaEntries3SDistinctCountWaveAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_WAVE_ASC',
  EmaEntries3SDistinctCountWaveDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_WAVE_DESC',
  EmaEntries3SDistinctCountWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_DISTINCT_COUNT_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SDistinctCountWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_DISTINCT_COUNT_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries3SMaxAngryAsc = 'EMA_ENTRIES3S_MAX_ANGRY_ASC',
  EmaEntries3SMaxAngryDesc = 'EMA_ENTRIES3S_MAX_ANGRY_DESC',
  EmaEntries3SMaxAnxiousAsc = 'EMA_ENTRIES3S_MAX_ANXIOUS_ASC',
  EmaEntries3SMaxAnxiousDesc = 'EMA_ENTRIES3S_MAX_ANXIOUS_DESC',
  EmaEntries3SMaxAnxiousDuringContactAsc = 'EMA_ENTRIES3S_MAX_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SMaxAnxiousDuringContactDesc = 'EMA_ENTRIES3S_MAX_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SMaxAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_MAX_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SMaxAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_MAX_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SMaxAvoidedSocialContactAsc = 'EMA_ENTRIES3S_MAX_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SMaxAvoidedSocialContactDesc = 'EMA_ENTRIES3S_MAX_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SMaxBurdensAsc = 'EMA_ENTRIES3S_MAX_BURDENS_ASC',
  EmaEntries3SMaxBurdensDesc = 'EMA_ENTRIES3S_MAX_BURDENS_DESC',
  EmaEntries3SMaxComfortableDuringContactAsc = 'EMA_ENTRIES3S_MAX_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SMaxComfortableDuringContactDesc = 'EMA_ENTRIES3S_MAX_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SMaxCompletedAtAsc = 'EMA_ENTRIES3S_MAX_COMPLETED_AT_ASC',
  EmaEntries3SMaxCompletedAtDesc = 'EMA_ENTRIES3S_MAX_COMPLETED_AT_DESC',
  EmaEntries3SMaxCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_MAX_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SMaxCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_MAX_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SMaxCreatedAtAsc = 'EMA_ENTRIES3S_MAX_CREATED_AT_ASC',
  EmaEntries3SMaxCreatedAtDesc = 'EMA_ENTRIES3S_MAX_CREATED_AT_DESC',
  EmaEntries3SMaxDeletedAsc = 'EMA_ENTRIES3S_MAX_DELETED_ASC',
  EmaEntries3SMaxDeletedDesc = 'EMA_ENTRIES3S_MAX_DELETED_DESC',
  EmaEntries3SMaxDeterminedAsc = 'EMA_ENTRIES3S_MAX_DETERMINED_ASC',
  EmaEntries3SMaxDeterminedDesc = 'EMA_ENTRIES3S_MAX_DETERMINED_DESC',
  EmaEntries3SMaxEnergeticAsc = 'EMA_ENTRIES3S_MAX_ENERGETIC_ASC',
  EmaEntries3SMaxEnergeticDesc = 'EMA_ENTRIES3S_MAX_ENERGETIC_DESC',
  EmaEntries3SMaxEnthusiasticAsc = 'EMA_ENTRIES3S_MAX_ENTHUSIASTIC_ASC',
  EmaEntries3SMaxEnthusiasticDesc = 'EMA_ENTRIES3S_MAX_ENTHUSIASTIC_DESC',
  EmaEntries3SMaxExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_MAX_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SMaxExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_MAX_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SMaxExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_MAX_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SMaxExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_MAX_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SMaxGuiltyAsc = 'EMA_ENTRIES3S_MAX_GUILTY_ASC',
  EmaEntries3SMaxGuiltyDesc = 'EMA_ENTRIES3S_MAX_GUILTY_DESC',
  EmaEntries3SMaxHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_MAX_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SMaxHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_MAX_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SMaxHappyAsc = 'EMA_ENTRIES3S_MAX_HAPPY_ASC',
  EmaEntries3SMaxHappyDesc = 'EMA_ENTRIES3S_MAX_HAPPY_DESC',
  EmaEntries3SMaxHappyDuringContactAsc = 'EMA_ENTRIES3S_MAX_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SMaxHappyDuringContactDesc = 'EMA_ENTRIES3S_MAX_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SMaxHelplessAsc = 'EMA_ENTRIES3S_MAX_HELPLESS_ASC',
  EmaEntries3SMaxHelplessDesc = 'EMA_ENTRIES3S_MAX_HELPLESS_DESC',
  EmaEntries3SMaxIdAsc = 'EMA_ENTRIES3S_MAX_ID_ASC',
  EmaEntries3SMaxIdDesc = 'EMA_ENTRIES3S_MAX_ID_DESC',
  EmaEntries3SMaxInspiredDuringContactAsc = 'EMA_ENTRIES3S_MAX_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SMaxInspiredDuringContactDesc = 'EMA_ENTRIES3S_MAX_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SMaxLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_MAX_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SMaxLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_MAX_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SMaxLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_MAX_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SMaxLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_MAX_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SMaxLonelyAsc = 'EMA_ENTRIES3S_MAX_LONELY_ASC',
  EmaEntries3SMaxLonelyDesc = 'EMA_ENTRIES3S_MAX_LONELY_DESC',
  EmaEntries3SMaxLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_MAX_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SMaxLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_MAX_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SMaxNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_MAX_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SMaxNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_MAX_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SMaxPlannedHowToProceedAsc = 'EMA_ENTRIES3S_MAX_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SMaxPlannedHowToProceedDesc = 'EMA_ENTRIES3S_MAX_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SMaxRelaxedAsc = 'EMA_ENTRIES3S_MAX_RELAXED_ASC',
  EmaEntries3SMaxRelaxedDesc = 'EMA_ENTRIES3S_MAX_RELAXED_DESC',
  EmaEntries3SMaxSadAsc = 'EMA_ENTRIES3S_MAX_SAD_ASC',
  EmaEntries3SMaxSadDesc = 'EMA_ENTRIES3S_MAX_SAD_DESC',
  EmaEntries3SMaxSatisfiedAsc = 'EMA_ENTRIES3S_MAX_SATISFIED_ASC',
  EmaEntries3SMaxSatisfiedDesc = 'EMA_ENTRIES3S_MAX_SATISFIED_DESC',
  EmaEntries3SMaxScheduledAtAsc = 'EMA_ENTRIES3S_MAX_SCHEDULED_AT_ASC',
  EmaEntries3SMaxScheduledAtDesc = 'EMA_ENTRIES3S_MAX_SCHEDULED_AT_DESC',
  EmaEntries3SMaxSmsReceivedAtAsc = 'EMA_ENTRIES3S_MAX_SMS_RECEIVED_AT_ASC',
  EmaEntries3SMaxSmsReceivedAtDesc = 'EMA_ENTRIES3S_MAX_SMS_RECEIVED_AT_DESC',
  EmaEntries3SMaxSocialDistressAsc = 'EMA_ENTRIES3S_MAX_SOCIAL_DISTRESS_ASC',
  EmaEntries3SMaxSocialDistressDesc = 'EMA_ENTRIES3S_MAX_SOCIAL_DISTRESS_DESC',
  EmaEntries3SMaxStartedAtAsc = 'EMA_ENTRIES3S_MAX_STARTED_AT_ASC',
  EmaEntries3SMaxStartedAtDesc = 'EMA_ENTRIES3S_MAX_STARTED_AT_DESC',
  EmaEntries3SMaxStrainAsc = 'EMA_ENTRIES3S_MAX_STRAIN_ASC',
  EmaEntries3SMaxStrainDesc = 'EMA_ENTRIES3S_MAX_STRAIN_DESC',
  EmaEntries3SMaxStrengthAsc = 'EMA_ENTRIES3S_MAX_STRENGTH_ASC',
  EmaEntries3SMaxStrengthDesc = 'EMA_ENTRIES3S_MAX_STRENGTH_DESC',
  EmaEntries3SMaxStressedAsc = 'EMA_ENTRIES3S_MAX_STRESSED_ASC',
  EmaEntries3SMaxStressedDesc = 'EMA_ENTRIES3S_MAX_STRESSED_DESC',
  EmaEntries3SMaxTenseDuringContactAsc = 'EMA_ENTRIES3S_MAX_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SMaxTenseDuringContactDesc = 'EMA_ENTRIES3S_MAX_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SMaxTestyAsc = 'EMA_ENTRIES3S_MAX_TESTY_ASC',
  EmaEntries3SMaxTestyDesc = 'EMA_ENTRIES3S_MAX_TESTY_DESC',
  EmaEntries3SMaxTestyDuringContactAsc = 'EMA_ENTRIES3S_MAX_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SMaxTestyDuringContactDesc = 'EMA_ENTRIES3S_MAX_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SMaxTrialIdAsc = 'EMA_ENTRIES3S_MAX_TRIAL_ID_ASC',
  EmaEntries3SMaxTrialIdDesc = 'EMA_ENTRIES3S_MAX_TRIAL_ID_DESC',
  EmaEntries3SMaxTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_MAX_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SMaxTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_MAX_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SMaxTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_MAX_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SMaxTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_MAX_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SMaxTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_MAX_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SMaxTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_MAX_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SMaxTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_MAX_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SMaxTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_MAX_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SMaxUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_MAX_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SMaxUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_MAX_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SMaxUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_MAX_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SMaxUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_MAX_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SMaxUpdatedAtAsc = 'EMA_ENTRIES3S_MAX_UPDATED_AT_ASC',
  EmaEntries3SMaxUpdatedAtDesc = 'EMA_ENTRIES3S_MAX_UPDATED_AT_DESC',
  EmaEntries3SMaxWaveAsc = 'EMA_ENTRIES3S_MAX_WAVE_ASC',
  EmaEntries3SMaxWaveDesc = 'EMA_ENTRIES3S_MAX_WAVE_DESC',
  EmaEntries3SMaxWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_MAX_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SMaxWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_MAX_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries3SMinAngryAsc = 'EMA_ENTRIES3S_MIN_ANGRY_ASC',
  EmaEntries3SMinAngryDesc = 'EMA_ENTRIES3S_MIN_ANGRY_DESC',
  EmaEntries3SMinAnxiousAsc = 'EMA_ENTRIES3S_MIN_ANXIOUS_ASC',
  EmaEntries3SMinAnxiousDesc = 'EMA_ENTRIES3S_MIN_ANXIOUS_DESC',
  EmaEntries3SMinAnxiousDuringContactAsc = 'EMA_ENTRIES3S_MIN_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SMinAnxiousDuringContactDesc = 'EMA_ENTRIES3S_MIN_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SMinAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_MIN_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SMinAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_MIN_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SMinAvoidedSocialContactAsc = 'EMA_ENTRIES3S_MIN_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SMinAvoidedSocialContactDesc = 'EMA_ENTRIES3S_MIN_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SMinBurdensAsc = 'EMA_ENTRIES3S_MIN_BURDENS_ASC',
  EmaEntries3SMinBurdensDesc = 'EMA_ENTRIES3S_MIN_BURDENS_DESC',
  EmaEntries3SMinComfortableDuringContactAsc = 'EMA_ENTRIES3S_MIN_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SMinComfortableDuringContactDesc = 'EMA_ENTRIES3S_MIN_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SMinCompletedAtAsc = 'EMA_ENTRIES3S_MIN_COMPLETED_AT_ASC',
  EmaEntries3SMinCompletedAtDesc = 'EMA_ENTRIES3S_MIN_COMPLETED_AT_DESC',
  EmaEntries3SMinCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_MIN_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SMinCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_MIN_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SMinCreatedAtAsc = 'EMA_ENTRIES3S_MIN_CREATED_AT_ASC',
  EmaEntries3SMinCreatedAtDesc = 'EMA_ENTRIES3S_MIN_CREATED_AT_DESC',
  EmaEntries3SMinDeletedAsc = 'EMA_ENTRIES3S_MIN_DELETED_ASC',
  EmaEntries3SMinDeletedDesc = 'EMA_ENTRIES3S_MIN_DELETED_DESC',
  EmaEntries3SMinDeterminedAsc = 'EMA_ENTRIES3S_MIN_DETERMINED_ASC',
  EmaEntries3SMinDeterminedDesc = 'EMA_ENTRIES3S_MIN_DETERMINED_DESC',
  EmaEntries3SMinEnergeticAsc = 'EMA_ENTRIES3S_MIN_ENERGETIC_ASC',
  EmaEntries3SMinEnergeticDesc = 'EMA_ENTRIES3S_MIN_ENERGETIC_DESC',
  EmaEntries3SMinEnthusiasticAsc = 'EMA_ENTRIES3S_MIN_ENTHUSIASTIC_ASC',
  EmaEntries3SMinEnthusiasticDesc = 'EMA_ENTRIES3S_MIN_ENTHUSIASTIC_DESC',
  EmaEntries3SMinExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_MIN_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SMinExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_MIN_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SMinExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_MIN_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SMinExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_MIN_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SMinGuiltyAsc = 'EMA_ENTRIES3S_MIN_GUILTY_ASC',
  EmaEntries3SMinGuiltyDesc = 'EMA_ENTRIES3S_MIN_GUILTY_DESC',
  EmaEntries3SMinHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_MIN_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SMinHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_MIN_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SMinHappyAsc = 'EMA_ENTRIES3S_MIN_HAPPY_ASC',
  EmaEntries3SMinHappyDesc = 'EMA_ENTRIES3S_MIN_HAPPY_DESC',
  EmaEntries3SMinHappyDuringContactAsc = 'EMA_ENTRIES3S_MIN_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SMinHappyDuringContactDesc = 'EMA_ENTRIES3S_MIN_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SMinHelplessAsc = 'EMA_ENTRIES3S_MIN_HELPLESS_ASC',
  EmaEntries3SMinHelplessDesc = 'EMA_ENTRIES3S_MIN_HELPLESS_DESC',
  EmaEntries3SMinIdAsc = 'EMA_ENTRIES3S_MIN_ID_ASC',
  EmaEntries3SMinIdDesc = 'EMA_ENTRIES3S_MIN_ID_DESC',
  EmaEntries3SMinInspiredDuringContactAsc = 'EMA_ENTRIES3S_MIN_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SMinInspiredDuringContactDesc = 'EMA_ENTRIES3S_MIN_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SMinLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_MIN_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SMinLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_MIN_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SMinLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_MIN_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SMinLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_MIN_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SMinLonelyAsc = 'EMA_ENTRIES3S_MIN_LONELY_ASC',
  EmaEntries3SMinLonelyDesc = 'EMA_ENTRIES3S_MIN_LONELY_DESC',
  EmaEntries3SMinLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_MIN_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SMinLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_MIN_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SMinNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_MIN_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SMinNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_MIN_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SMinPlannedHowToProceedAsc = 'EMA_ENTRIES3S_MIN_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SMinPlannedHowToProceedDesc = 'EMA_ENTRIES3S_MIN_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SMinRelaxedAsc = 'EMA_ENTRIES3S_MIN_RELAXED_ASC',
  EmaEntries3SMinRelaxedDesc = 'EMA_ENTRIES3S_MIN_RELAXED_DESC',
  EmaEntries3SMinSadAsc = 'EMA_ENTRIES3S_MIN_SAD_ASC',
  EmaEntries3SMinSadDesc = 'EMA_ENTRIES3S_MIN_SAD_DESC',
  EmaEntries3SMinSatisfiedAsc = 'EMA_ENTRIES3S_MIN_SATISFIED_ASC',
  EmaEntries3SMinSatisfiedDesc = 'EMA_ENTRIES3S_MIN_SATISFIED_DESC',
  EmaEntries3SMinScheduledAtAsc = 'EMA_ENTRIES3S_MIN_SCHEDULED_AT_ASC',
  EmaEntries3SMinScheduledAtDesc = 'EMA_ENTRIES3S_MIN_SCHEDULED_AT_DESC',
  EmaEntries3SMinSmsReceivedAtAsc = 'EMA_ENTRIES3S_MIN_SMS_RECEIVED_AT_ASC',
  EmaEntries3SMinSmsReceivedAtDesc = 'EMA_ENTRIES3S_MIN_SMS_RECEIVED_AT_DESC',
  EmaEntries3SMinSocialDistressAsc = 'EMA_ENTRIES3S_MIN_SOCIAL_DISTRESS_ASC',
  EmaEntries3SMinSocialDistressDesc = 'EMA_ENTRIES3S_MIN_SOCIAL_DISTRESS_DESC',
  EmaEntries3SMinStartedAtAsc = 'EMA_ENTRIES3S_MIN_STARTED_AT_ASC',
  EmaEntries3SMinStartedAtDesc = 'EMA_ENTRIES3S_MIN_STARTED_AT_DESC',
  EmaEntries3SMinStrainAsc = 'EMA_ENTRIES3S_MIN_STRAIN_ASC',
  EmaEntries3SMinStrainDesc = 'EMA_ENTRIES3S_MIN_STRAIN_DESC',
  EmaEntries3SMinStrengthAsc = 'EMA_ENTRIES3S_MIN_STRENGTH_ASC',
  EmaEntries3SMinStrengthDesc = 'EMA_ENTRIES3S_MIN_STRENGTH_DESC',
  EmaEntries3SMinStressedAsc = 'EMA_ENTRIES3S_MIN_STRESSED_ASC',
  EmaEntries3SMinStressedDesc = 'EMA_ENTRIES3S_MIN_STRESSED_DESC',
  EmaEntries3SMinTenseDuringContactAsc = 'EMA_ENTRIES3S_MIN_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SMinTenseDuringContactDesc = 'EMA_ENTRIES3S_MIN_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SMinTestyAsc = 'EMA_ENTRIES3S_MIN_TESTY_ASC',
  EmaEntries3SMinTestyDesc = 'EMA_ENTRIES3S_MIN_TESTY_DESC',
  EmaEntries3SMinTestyDuringContactAsc = 'EMA_ENTRIES3S_MIN_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SMinTestyDuringContactDesc = 'EMA_ENTRIES3S_MIN_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SMinTrialIdAsc = 'EMA_ENTRIES3S_MIN_TRIAL_ID_ASC',
  EmaEntries3SMinTrialIdDesc = 'EMA_ENTRIES3S_MIN_TRIAL_ID_DESC',
  EmaEntries3SMinTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_MIN_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SMinTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_MIN_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SMinTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_MIN_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SMinTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_MIN_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SMinTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_MIN_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SMinTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_MIN_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SMinTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_MIN_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SMinTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_MIN_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SMinUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_MIN_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SMinUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_MIN_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SMinUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_MIN_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SMinUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_MIN_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SMinUpdatedAtAsc = 'EMA_ENTRIES3S_MIN_UPDATED_AT_ASC',
  EmaEntries3SMinUpdatedAtDesc = 'EMA_ENTRIES3S_MIN_UPDATED_AT_DESC',
  EmaEntries3SMinWaveAsc = 'EMA_ENTRIES3S_MIN_WAVE_ASC',
  EmaEntries3SMinWaveDesc = 'EMA_ENTRIES3S_MIN_WAVE_DESC',
  EmaEntries3SMinWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_MIN_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SMinWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_MIN_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries3SStddevPopulationAngryAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ANGRY_ASC',
  EmaEntries3SStddevPopulationAngryDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ANGRY_DESC',
  EmaEntries3SStddevPopulationAnxiousAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ANXIOUS_ASC',
  EmaEntries3SStddevPopulationAnxiousDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ANXIOUS_DESC',
  EmaEntries3SStddevPopulationAnxiousDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SStddevPopulationAnxiousDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SStddevPopulationAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SStddevPopulationAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SStddevPopulationAvoidedSocialContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SStddevPopulationAvoidedSocialContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SStddevPopulationBurdensAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_BURDENS_ASC',
  EmaEntries3SStddevPopulationBurdensDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_BURDENS_DESC',
  EmaEntries3SStddevPopulationComfortableDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SStddevPopulationComfortableDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SStddevPopulationCompletedAtAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_COMPLETED_AT_ASC',
  EmaEntries3SStddevPopulationCompletedAtDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_COMPLETED_AT_DESC',
  EmaEntries3SStddevPopulationCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SStddevPopulationCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SStddevPopulationCreatedAtAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_CREATED_AT_ASC',
  EmaEntries3SStddevPopulationCreatedAtDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_CREATED_AT_DESC',
  EmaEntries3SStddevPopulationDeletedAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_DELETED_ASC',
  EmaEntries3SStddevPopulationDeletedDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_DELETED_DESC',
  EmaEntries3SStddevPopulationDeterminedAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_DETERMINED_ASC',
  EmaEntries3SStddevPopulationDeterminedDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_DETERMINED_DESC',
  EmaEntries3SStddevPopulationEnergeticAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ENERGETIC_ASC',
  EmaEntries3SStddevPopulationEnergeticDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ENERGETIC_DESC',
  EmaEntries3SStddevPopulationEnthusiasticAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ENTHUSIASTIC_ASC',
  EmaEntries3SStddevPopulationEnthusiasticDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ENTHUSIASTIC_DESC',
  EmaEntries3SStddevPopulationExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SStddevPopulationExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SStddevPopulationExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SStddevPopulationExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SStddevPopulationGuiltyAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_GUILTY_ASC',
  EmaEntries3SStddevPopulationGuiltyDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_GUILTY_DESC',
  EmaEntries3SStddevPopulationHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SStddevPopulationHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SStddevPopulationHappyAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_HAPPY_ASC',
  EmaEntries3SStddevPopulationHappyDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_HAPPY_DESC',
  EmaEntries3SStddevPopulationHappyDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SStddevPopulationHappyDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SStddevPopulationHelplessAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_HELPLESS_ASC',
  EmaEntries3SStddevPopulationHelplessDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_HELPLESS_DESC',
  EmaEntries3SStddevPopulationIdAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ID_ASC',
  EmaEntries3SStddevPopulationIdDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_ID_DESC',
  EmaEntries3SStddevPopulationInspiredDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SStddevPopulationInspiredDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SStddevPopulationLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SStddevPopulationLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SStddevPopulationLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SStddevPopulationLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SStddevPopulationLonelyAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_LONELY_ASC',
  EmaEntries3SStddevPopulationLonelyDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_LONELY_DESC',
  EmaEntries3SStddevPopulationLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SStddevPopulationLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SStddevPopulationNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SStddevPopulationNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SStddevPopulationPlannedHowToProceedAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SStddevPopulationPlannedHowToProceedDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SStddevPopulationRelaxedAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_RELAXED_ASC',
  EmaEntries3SStddevPopulationRelaxedDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_RELAXED_DESC',
  EmaEntries3SStddevPopulationSadAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SAD_ASC',
  EmaEntries3SStddevPopulationSadDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SAD_DESC',
  EmaEntries3SStddevPopulationSatisfiedAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SATISFIED_ASC',
  EmaEntries3SStddevPopulationSatisfiedDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SATISFIED_DESC',
  EmaEntries3SStddevPopulationScheduledAtAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SCHEDULED_AT_ASC',
  EmaEntries3SStddevPopulationScheduledAtDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SCHEDULED_AT_DESC',
  EmaEntries3SStddevPopulationSmsReceivedAtAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SMS_RECEIVED_AT_ASC',
  EmaEntries3SStddevPopulationSmsReceivedAtDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SMS_RECEIVED_AT_DESC',
  EmaEntries3SStddevPopulationSocialDistressAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SOCIAL_DISTRESS_ASC',
  EmaEntries3SStddevPopulationSocialDistressDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_SOCIAL_DISTRESS_DESC',
  EmaEntries3SStddevPopulationStartedAtAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_STARTED_AT_ASC',
  EmaEntries3SStddevPopulationStartedAtDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_STARTED_AT_DESC',
  EmaEntries3SStddevPopulationStrainAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_STRAIN_ASC',
  EmaEntries3SStddevPopulationStrainDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_STRAIN_DESC',
  EmaEntries3SStddevPopulationStrengthAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_STRENGTH_ASC',
  EmaEntries3SStddevPopulationStrengthDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_STRENGTH_DESC',
  EmaEntries3SStddevPopulationStressedAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_STRESSED_ASC',
  EmaEntries3SStddevPopulationStressedDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_STRESSED_DESC',
  EmaEntries3SStddevPopulationTenseDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SStddevPopulationTenseDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SStddevPopulationTestyAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TESTY_ASC',
  EmaEntries3SStddevPopulationTestyDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TESTY_DESC',
  EmaEntries3SStddevPopulationTestyDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SStddevPopulationTestyDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SStddevPopulationTrialIdAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TRIAL_ID_ASC',
  EmaEntries3SStddevPopulationTrialIdDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TRIAL_ID_DESC',
  EmaEntries3SStddevPopulationTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SStddevPopulationTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SStddevPopulationTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SStddevPopulationTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SStddevPopulationTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SStddevPopulationTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SStddevPopulationTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SStddevPopulationTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SStddevPopulationUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SStddevPopulationUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SStddevPopulationUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SStddevPopulationUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SStddevPopulationUpdatedAtAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_UPDATED_AT_ASC',
  EmaEntries3SStddevPopulationUpdatedAtDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_UPDATED_AT_DESC',
  EmaEntries3SStddevPopulationWaveAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_WAVE_ASC',
  EmaEntries3SStddevPopulationWaveDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_WAVE_DESC',
  EmaEntries3SStddevPopulationWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_STDDEV_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SStddevPopulationWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_STDDEV_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries3SStddevSampleAngryAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ANGRY_ASC',
  EmaEntries3SStddevSampleAngryDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ANGRY_DESC',
  EmaEntries3SStddevSampleAnxiousAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ANXIOUS_ASC',
  EmaEntries3SStddevSampleAnxiousDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ANXIOUS_DESC',
  EmaEntries3SStddevSampleAnxiousDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SStddevSampleAnxiousDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SStddevSampleAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SStddevSampleAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SStddevSampleAvoidedSocialContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SStddevSampleAvoidedSocialContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SStddevSampleBurdensAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_BURDENS_ASC',
  EmaEntries3SStddevSampleBurdensDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_BURDENS_DESC',
  EmaEntries3SStddevSampleComfortableDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SStddevSampleComfortableDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SStddevSampleCompletedAtAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  EmaEntries3SStddevSampleCompletedAtDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  EmaEntries3SStddevSampleCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SStddevSampleCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SStddevSampleCreatedAtAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_CREATED_AT_ASC',
  EmaEntries3SStddevSampleCreatedAtDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_CREATED_AT_DESC',
  EmaEntries3SStddevSampleDeletedAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_DELETED_ASC',
  EmaEntries3SStddevSampleDeletedDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_DELETED_DESC',
  EmaEntries3SStddevSampleDeterminedAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_DETERMINED_ASC',
  EmaEntries3SStddevSampleDeterminedDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_DETERMINED_DESC',
  EmaEntries3SStddevSampleEnergeticAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ENERGETIC_ASC',
  EmaEntries3SStddevSampleEnergeticDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ENERGETIC_DESC',
  EmaEntries3SStddevSampleEnthusiasticAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ENTHUSIASTIC_ASC',
  EmaEntries3SStddevSampleEnthusiasticDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ENTHUSIASTIC_DESC',
  EmaEntries3SStddevSampleExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SStddevSampleExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SStddevSampleExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SStddevSampleExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SStddevSampleGuiltyAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_GUILTY_ASC',
  EmaEntries3SStddevSampleGuiltyDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_GUILTY_DESC',
  EmaEntries3SStddevSampleHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SStddevSampleHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SStddevSampleHappyAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_HAPPY_ASC',
  EmaEntries3SStddevSampleHappyDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_HAPPY_DESC',
  EmaEntries3SStddevSampleHappyDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SStddevSampleHappyDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SStddevSampleHelplessAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_HELPLESS_ASC',
  EmaEntries3SStddevSampleHelplessDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_HELPLESS_DESC',
  EmaEntries3SStddevSampleIdAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ID_ASC',
  EmaEntries3SStddevSampleIdDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_ID_DESC',
  EmaEntries3SStddevSampleInspiredDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SStddevSampleInspiredDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SStddevSampleLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SStddevSampleLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SStddevSampleLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SStddevSampleLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SStddevSampleLonelyAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_LONELY_ASC',
  EmaEntries3SStddevSampleLonelyDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_LONELY_DESC',
  EmaEntries3SStddevSampleLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SStddevSampleLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SStddevSampleNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SStddevSampleNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SStddevSamplePlannedHowToProceedAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SStddevSamplePlannedHowToProceedDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SStddevSampleRelaxedAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_RELAXED_ASC',
  EmaEntries3SStddevSampleRelaxedDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_RELAXED_DESC',
  EmaEntries3SStddevSampleSadAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SAD_ASC',
  EmaEntries3SStddevSampleSadDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SAD_DESC',
  EmaEntries3SStddevSampleSatisfiedAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SATISFIED_ASC',
  EmaEntries3SStddevSampleSatisfiedDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SATISFIED_DESC',
  EmaEntries3SStddevSampleScheduledAtAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SCHEDULED_AT_ASC',
  EmaEntries3SStddevSampleScheduledAtDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SCHEDULED_AT_DESC',
  EmaEntries3SStddevSampleSmsReceivedAtAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SMS_RECEIVED_AT_ASC',
  EmaEntries3SStddevSampleSmsReceivedAtDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SMS_RECEIVED_AT_DESC',
  EmaEntries3SStddevSampleSocialDistressAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SOCIAL_DISTRESS_ASC',
  EmaEntries3SStddevSampleSocialDistressDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_SOCIAL_DISTRESS_DESC',
  EmaEntries3SStddevSampleStartedAtAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_STARTED_AT_ASC',
  EmaEntries3SStddevSampleStartedAtDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_STARTED_AT_DESC',
  EmaEntries3SStddevSampleStrainAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_STRAIN_ASC',
  EmaEntries3SStddevSampleStrainDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_STRAIN_DESC',
  EmaEntries3SStddevSampleStrengthAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_STRENGTH_ASC',
  EmaEntries3SStddevSampleStrengthDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_STRENGTH_DESC',
  EmaEntries3SStddevSampleStressedAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_STRESSED_ASC',
  EmaEntries3SStddevSampleStressedDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_STRESSED_DESC',
  EmaEntries3SStddevSampleTenseDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SStddevSampleTenseDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SStddevSampleTestyAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TESTY_ASC',
  EmaEntries3SStddevSampleTestyDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TESTY_DESC',
  EmaEntries3SStddevSampleTestyDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SStddevSampleTestyDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SStddevSampleTrialIdAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TRIAL_ID_ASC',
  EmaEntries3SStddevSampleTrialIdDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TRIAL_ID_DESC',
  EmaEntries3SStddevSampleTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SStddevSampleTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SStddevSampleTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SStddevSampleTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SStddevSampleTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SStddevSampleTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SStddevSampleTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SStddevSampleTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SStddevSampleUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SStddevSampleUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SStddevSampleUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SStddevSampleUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SStddevSampleUpdatedAtAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EmaEntries3SStddevSampleUpdatedAtDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EmaEntries3SStddevSampleWaveAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_WAVE_ASC',
  EmaEntries3SStddevSampleWaveDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_WAVE_DESC',
  EmaEntries3SStddevSampleWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SStddevSampleWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_STDDEV_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries3SSumAngryAsc = 'EMA_ENTRIES3S_SUM_ANGRY_ASC',
  EmaEntries3SSumAngryDesc = 'EMA_ENTRIES3S_SUM_ANGRY_DESC',
  EmaEntries3SSumAnxiousAsc = 'EMA_ENTRIES3S_SUM_ANXIOUS_ASC',
  EmaEntries3SSumAnxiousDesc = 'EMA_ENTRIES3S_SUM_ANXIOUS_DESC',
  EmaEntries3SSumAnxiousDuringContactAsc = 'EMA_ENTRIES3S_SUM_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SSumAnxiousDuringContactDesc = 'EMA_ENTRIES3S_SUM_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SSumAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_SUM_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SSumAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_SUM_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SSumAvoidedSocialContactAsc = 'EMA_ENTRIES3S_SUM_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SSumAvoidedSocialContactDesc = 'EMA_ENTRIES3S_SUM_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SSumBurdensAsc = 'EMA_ENTRIES3S_SUM_BURDENS_ASC',
  EmaEntries3SSumBurdensDesc = 'EMA_ENTRIES3S_SUM_BURDENS_DESC',
  EmaEntries3SSumComfortableDuringContactAsc = 'EMA_ENTRIES3S_SUM_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SSumComfortableDuringContactDesc = 'EMA_ENTRIES3S_SUM_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SSumCompletedAtAsc = 'EMA_ENTRIES3S_SUM_COMPLETED_AT_ASC',
  EmaEntries3SSumCompletedAtDesc = 'EMA_ENTRIES3S_SUM_COMPLETED_AT_DESC',
  EmaEntries3SSumCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_SUM_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SSumCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_SUM_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SSumCreatedAtAsc = 'EMA_ENTRIES3S_SUM_CREATED_AT_ASC',
  EmaEntries3SSumCreatedAtDesc = 'EMA_ENTRIES3S_SUM_CREATED_AT_DESC',
  EmaEntries3SSumDeletedAsc = 'EMA_ENTRIES3S_SUM_DELETED_ASC',
  EmaEntries3SSumDeletedDesc = 'EMA_ENTRIES3S_SUM_DELETED_DESC',
  EmaEntries3SSumDeterminedAsc = 'EMA_ENTRIES3S_SUM_DETERMINED_ASC',
  EmaEntries3SSumDeterminedDesc = 'EMA_ENTRIES3S_SUM_DETERMINED_DESC',
  EmaEntries3SSumEnergeticAsc = 'EMA_ENTRIES3S_SUM_ENERGETIC_ASC',
  EmaEntries3SSumEnergeticDesc = 'EMA_ENTRIES3S_SUM_ENERGETIC_DESC',
  EmaEntries3SSumEnthusiasticAsc = 'EMA_ENTRIES3S_SUM_ENTHUSIASTIC_ASC',
  EmaEntries3SSumEnthusiasticDesc = 'EMA_ENTRIES3S_SUM_ENTHUSIASTIC_DESC',
  EmaEntries3SSumExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_SUM_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SSumExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_SUM_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SSumExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_SUM_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SSumExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_SUM_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SSumGuiltyAsc = 'EMA_ENTRIES3S_SUM_GUILTY_ASC',
  EmaEntries3SSumGuiltyDesc = 'EMA_ENTRIES3S_SUM_GUILTY_DESC',
  EmaEntries3SSumHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_SUM_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SSumHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_SUM_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SSumHappyAsc = 'EMA_ENTRIES3S_SUM_HAPPY_ASC',
  EmaEntries3SSumHappyDesc = 'EMA_ENTRIES3S_SUM_HAPPY_DESC',
  EmaEntries3SSumHappyDuringContactAsc = 'EMA_ENTRIES3S_SUM_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SSumHappyDuringContactDesc = 'EMA_ENTRIES3S_SUM_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SSumHelplessAsc = 'EMA_ENTRIES3S_SUM_HELPLESS_ASC',
  EmaEntries3SSumHelplessDesc = 'EMA_ENTRIES3S_SUM_HELPLESS_DESC',
  EmaEntries3SSumIdAsc = 'EMA_ENTRIES3S_SUM_ID_ASC',
  EmaEntries3SSumIdDesc = 'EMA_ENTRIES3S_SUM_ID_DESC',
  EmaEntries3SSumInspiredDuringContactAsc = 'EMA_ENTRIES3S_SUM_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SSumInspiredDuringContactDesc = 'EMA_ENTRIES3S_SUM_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SSumLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_SUM_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SSumLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_SUM_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SSumLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_SUM_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SSumLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_SUM_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SSumLonelyAsc = 'EMA_ENTRIES3S_SUM_LONELY_ASC',
  EmaEntries3SSumLonelyDesc = 'EMA_ENTRIES3S_SUM_LONELY_DESC',
  EmaEntries3SSumLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_SUM_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SSumLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_SUM_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SSumNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_SUM_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SSumNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_SUM_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SSumPlannedHowToProceedAsc = 'EMA_ENTRIES3S_SUM_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SSumPlannedHowToProceedDesc = 'EMA_ENTRIES3S_SUM_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SSumRelaxedAsc = 'EMA_ENTRIES3S_SUM_RELAXED_ASC',
  EmaEntries3SSumRelaxedDesc = 'EMA_ENTRIES3S_SUM_RELAXED_DESC',
  EmaEntries3SSumSadAsc = 'EMA_ENTRIES3S_SUM_SAD_ASC',
  EmaEntries3SSumSadDesc = 'EMA_ENTRIES3S_SUM_SAD_DESC',
  EmaEntries3SSumSatisfiedAsc = 'EMA_ENTRIES3S_SUM_SATISFIED_ASC',
  EmaEntries3SSumSatisfiedDesc = 'EMA_ENTRIES3S_SUM_SATISFIED_DESC',
  EmaEntries3SSumScheduledAtAsc = 'EMA_ENTRIES3S_SUM_SCHEDULED_AT_ASC',
  EmaEntries3SSumScheduledAtDesc = 'EMA_ENTRIES3S_SUM_SCHEDULED_AT_DESC',
  EmaEntries3SSumSmsReceivedAtAsc = 'EMA_ENTRIES3S_SUM_SMS_RECEIVED_AT_ASC',
  EmaEntries3SSumSmsReceivedAtDesc = 'EMA_ENTRIES3S_SUM_SMS_RECEIVED_AT_DESC',
  EmaEntries3SSumSocialDistressAsc = 'EMA_ENTRIES3S_SUM_SOCIAL_DISTRESS_ASC',
  EmaEntries3SSumSocialDistressDesc = 'EMA_ENTRIES3S_SUM_SOCIAL_DISTRESS_DESC',
  EmaEntries3SSumStartedAtAsc = 'EMA_ENTRIES3S_SUM_STARTED_AT_ASC',
  EmaEntries3SSumStartedAtDesc = 'EMA_ENTRIES3S_SUM_STARTED_AT_DESC',
  EmaEntries3SSumStrainAsc = 'EMA_ENTRIES3S_SUM_STRAIN_ASC',
  EmaEntries3SSumStrainDesc = 'EMA_ENTRIES3S_SUM_STRAIN_DESC',
  EmaEntries3SSumStrengthAsc = 'EMA_ENTRIES3S_SUM_STRENGTH_ASC',
  EmaEntries3SSumStrengthDesc = 'EMA_ENTRIES3S_SUM_STRENGTH_DESC',
  EmaEntries3SSumStressedAsc = 'EMA_ENTRIES3S_SUM_STRESSED_ASC',
  EmaEntries3SSumStressedDesc = 'EMA_ENTRIES3S_SUM_STRESSED_DESC',
  EmaEntries3SSumTenseDuringContactAsc = 'EMA_ENTRIES3S_SUM_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SSumTenseDuringContactDesc = 'EMA_ENTRIES3S_SUM_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SSumTestyAsc = 'EMA_ENTRIES3S_SUM_TESTY_ASC',
  EmaEntries3SSumTestyDesc = 'EMA_ENTRIES3S_SUM_TESTY_DESC',
  EmaEntries3SSumTestyDuringContactAsc = 'EMA_ENTRIES3S_SUM_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SSumTestyDuringContactDesc = 'EMA_ENTRIES3S_SUM_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SSumTrialIdAsc = 'EMA_ENTRIES3S_SUM_TRIAL_ID_ASC',
  EmaEntries3SSumTrialIdDesc = 'EMA_ENTRIES3S_SUM_TRIAL_ID_DESC',
  EmaEntries3SSumTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_SUM_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SSumTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_SUM_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SSumTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_SUM_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SSumTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_SUM_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SSumTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_SUM_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SSumTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_SUM_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SSumTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_SUM_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SSumTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_SUM_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SSumUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_SUM_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SSumUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_SUM_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SSumUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_SUM_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SSumUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_SUM_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SSumUpdatedAtAsc = 'EMA_ENTRIES3S_SUM_UPDATED_AT_ASC',
  EmaEntries3SSumUpdatedAtDesc = 'EMA_ENTRIES3S_SUM_UPDATED_AT_DESC',
  EmaEntries3SSumWaveAsc = 'EMA_ENTRIES3S_SUM_WAVE_ASC',
  EmaEntries3SSumWaveDesc = 'EMA_ENTRIES3S_SUM_WAVE_DESC',
  EmaEntries3SSumWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_SUM_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SSumWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_SUM_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries3SVariancePopulationAngryAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ANGRY_ASC',
  EmaEntries3SVariancePopulationAngryDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ANGRY_DESC',
  EmaEntries3SVariancePopulationAnxiousAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ANXIOUS_ASC',
  EmaEntries3SVariancePopulationAnxiousDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ANXIOUS_DESC',
  EmaEntries3SVariancePopulationAnxiousDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SVariancePopulationAnxiousDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SVariancePopulationAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SVariancePopulationAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SVariancePopulationAvoidedSocialContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SVariancePopulationAvoidedSocialContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SVariancePopulationBurdensAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_BURDENS_ASC',
  EmaEntries3SVariancePopulationBurdensDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_BURDENS_DESC',
  EmaEntries3SVariancePopulationComfortableDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SVariancePopulationComfortableDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SVariancePopulationCompletedAtAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  EmaEntries3SVariancePopulationCompletedAtDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  EmaEntries3SVariancePopulationCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SVariancePopulationCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SVariancePopulationCreatedAtAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_CREATED_AT_ASC',
  EmaEntries3SVariancePopulationCreatedAtDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_CREATED_AT_DESC',
  EmaEntries3SVariancePopulationDeletedAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_DELETED_ASC',
  EmaEntries3SVariancePopulationDeletedDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_DELETED_DESC',
  EmaEntries3SVariancePopulationDeterminedAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_DETERMINED_ASC',
  EmaEntries3SVariancePopulationDeterminedDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_DETERMINED_DESC',
  EmaEntries3SVariancePopulationEnergeticAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ENERGETIC_ASC',
  EmaEntries3SVariancePopulationEnergeticDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ENERGETIC_DESC',
  EmaEntries3SVariancePopulationEnthusiasticAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ENTHUSIASTIC_ASC',
  EmaEntries3SVariancePopulationEnthusiasticDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ENTHUSIASTIC_DESC',
  EmaEntries3SVariancePopulationExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SVariancePopulationExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SVariancePopulationExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SVariancePopulationExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SVariancePopulationGuiltyAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_GUILTY_ASC',
  EmaEntries3SVariancePopulationGuiltyDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_GUILTY_DESC',
  EmaEntries3SVariancePopulationHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SVariancePopulationHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SVariancePopulationHappyAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_HAPPY_ASC',
  EmaEntries3SVariancePopulationHappyDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_HAPPY_DESC',
  EmaEntries3SVariancePopulationHappyDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SVariancePopulationHappyDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SVariancePopulationHelplessAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_HELPLESS_ASC',
  EmaEntries3SVariancePopulationHelplessDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_HELPLESS_DESC',
  EmaEntries3SVariancePopulationIdAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ID_ASC',
  EmaEntries3SVariancePopulationIdDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_ID_DESC',
  EmaEntries3SVariancePopulationInspiredDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SVariancePopulationInspiredDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SVariancePopulationLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SVariancePopulationLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SVariancePopulationLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SVariancePopulationLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SVariancePopulationLonelyAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_LONELY_ASC',
  EmaEntries3SVariancePopulationLonelyDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_LONELY_DESC',
  EmaEntries3SVariancePopulationLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SVariancePopulationLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SVariancePopulationNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SVariancePopulationNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SVariancePopulationPlannedHowToProceedAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SVariancePopulationPlannedHowToProceedDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SVariancePopulationRelaxedAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_RELAXED_ASC',
  EmaEntries3SVariancePopulationRelaxedDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_RELAXED_DESC',
  EmaEntries3SVariancePopulationSadAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SAD_ASC',
  EmaEntries3SVariancePopulationSadDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SAD_DESC',
  EmaEntries3SVariancePopulationSatisfiedAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SATISFIED_ASC',
  EmaEntries3SVariancePopulationSatisfiedDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SATISFIED_DESC',
  EmaEntries3SVariancePopulationScheduledAtAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SCHEDULED_AT_ASC',
  EmaEntries3SVariancePopulationScheduledAtDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SCHEDULED_AT_DESC',
  EmaEntries3SVariancePopulationSmsReceivedAtAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SMS_RECEIVED_AT_ASC',
  EmaEntries3SVariancePopulationSmsReceivedAtDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SMS_RECEIVED_AT_DESC',
  EmaEntries3SVariancePopulationSocialDistressAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SOCIAL_DISTRESS_ASC',
  EmaEntries3SVariancePopulationSocialDistressDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_SOCIAL_DISTRESS_DESC',
  EmaEntries3SVariancePopulationStartedAtAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_STARTED_AT_ASC',
  EmaEntries3SVariancePopulationStartedAtDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_STARTED_AT_DESC',
  EmaEntries3SVariancePopulationStrainAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_STRAIN_ASC',
  EmaEntries3SVariancePopulationStrainDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_STRAIN_DESC',
  EmaEntries3SVariancePopulationStrengthAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_STRENGTH_ASC',
  EmaEntries3SVariancePopulationStrengthDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_STRENGTH_DESC',
  EmaEntries3SVariancePopulationStressedAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_STRESSED_ASC',
  EmaEntries3SVariancePopulationStressedDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_STRESSED_DESC',
  EmaEntries3SVariancePopulationTenseDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SVariancePopulationTenseDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SVariancePopulationTestyAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TESTY_ASC',
  EmaEntries3SVariancePopulationTestyDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TESTY_DESC',
  EmaEntries3SVariancePopulationTestyDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SVariancePopulationTestyDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SVariancePopulationTrialIdAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TRIAL_ID_ASC',
  EmaEntries3SVariancePopulationTrialIdDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TRIAL_ID_DESC',
  EmaEntries3SVariancePopulationTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SVariancePopulationTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SVariancePopulationTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SVariancePopulationTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SVariancePopulationTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SVariancePopulationTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SVariancePopulationTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SVariancePopulationTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SVariancePopulationUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SVariancePopulationUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SVariancePopulationUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SVariancePopulationUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SVariancePopulationUpdatedAtAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EmaEntries3SVariancePopulationUpdatedAtDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_UPDATED_AT_DESC',
  EmaEntries3SVariancePopulationWaveAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_WAVE_ASC',
  EmaEntries3SVariancePopulationWaveDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_WAVE_DESC',
  EmaEntries3SVariancePopulationWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SVariancePopulationWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_VARIANCE_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries3SVarianceSampleAngryAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ANGRY_ASC',
  EmaEntries3SVarianceSampleAngryDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ANGRY_DESC',
  EmaEntries3SVarianceSampleAnxiousAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ANXIOUS_ASC',
  EmaEntries3SVarianceSampleAnxiousDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ANXIOUS_DESC',
  EmaEntries3SVarianceSampleAnxiousDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries3SVarianceSampleAnxiousDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries3SVarianceSampleAtThatMomentIFeltMoreComfortableAloneAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_ASC',
  EmaEntries3SVarianceSampleAtThatMomentIFeltMoreComfortableAloneDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_AT_THAT_MOMENT_I_FELT_MORE_COMFORTABLE_ALONE_DESC',
  EmaEntries3SVarianceSampleAvoidedSocialContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries3SVarianceSampleAvoidedSocialContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries3SVarianceSampleBurdensAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_BURDENS_ASC',
  EmaEntries3SVarianceSampleBurdensDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_BURDENS_DESC',
  EmaEntries3SVarianceSampleComfortableDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries3SVarianceSampleComfortableDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries3SVarianceSampleCompletedAtAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  EmaEntries3SVarianceSampleCompletedAtDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  EmaEntries3SVarianceSampleCouldResolveBurdensInAdvanceAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_COULD_RESOLVE_BURDENS_IN_ADVANCE_ASC',
  EmaEntries3SVarianceSampleCouldResolveBurdensInAdvanceDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_COULD_RESOLVE_BURDENS_IN_ADVANCE_DESC',
  EmaEntries3SVarianceSampleCreatedAtAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EmaEntries3SVarianceSampleCreatedAtDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EmaEntries3SVarianceSampleDeletedAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_DELETED_ASC',
  EmaEntries3SVarianceSampleDeletedDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_DELETED_DESC',
  EmaEntries3SVarianceSampleDeterminedAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_DETERMINED_ASC',
  EmaEntries3SVarianceSampleDeterminedDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_DETERMINED_DESC',
  EmaEntries3SVarianceSampleEnergeticAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ENERGETIC_ASC',
  EmaEntries3SVarianceSampleEnergeticDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ENERGETIC_DESC',
  EmaEntries3SVarianceSampleEnthusiasticAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ENTHUSIASTIC_ASC',
  EmaEntries3SVarianceSampleEnthusiasticDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ENTHUSIASTIC_DESC',
  EmaEntries3SVarianceSampleExpectedBurdenThatDidNotOccurAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_ASC',
  EmaEntries3SVarianceSampleExpectedBurdenThatDidNotOccurDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_EXPECTED_BURDEN_THAT_DID_NOT_OCCUR_DESC',
  EmaEntries3SVarianceSampleExpectBurdenInTheNearFutureAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_ASC',
  EmaEntries3SVarianceSampleExpectBurdenInTheNearFutureDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_EXPECT_BURDEN_IN_THE_NEAR_FUTURE_DESC',
  EmaEntries3SVarianceSampleGuiltyAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_GUILTY_ASC',
  EmaEntries3SVarianceSampleGuiltyDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_GUILTY_DESC',
  EmaEntries3SVarianceSampleHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries3SVarianceSampleHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries3SVarianceSampleHappyAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_HAPPY_ASC',
  EmaEntries3SVarianceSampleHappyDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_HAPPY_DESC',
  EmaEntries3SVarianceSampleHappyDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_HAPPY_DURING_CONTACT_ASC',
  EmaEntries3SVarianceSampleHappyDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_HAPPY_DURING_CONTACT_DESC',
  EmaEntries3SVarianceSampleHelplessAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_HELPLESS_ASC',
  EmaEntries3SVarianceSampleHelplessDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_HELPLESS_DESC',
  EmaEntries3SVarianceSampleIdAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ID_ASC',
  EmaEntries3SVarianceSampleIdDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_ID_DESC',
  EmaEntries3SVarianceSampleInspiredDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_INSPIRED_DURING_CONTACT_ASC',
  EmaEntries3SVarianceSampleInspiredDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_INSPIRED_DURING_CONTACT_DESC',
  EmaEntries3SVarianceSampleLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries3SVarianceSampleLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries3SVarianceSampleLackOfSocialContactForExternalReasonsAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SVarianceSampleLackOfSocialContactForExternalReasonsDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_LACK_OF_SOCIAL_CONTACT_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SVarianceSampleLonelyAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_LONELY_ASC',
  EmaEntries3SVarianceSampleLonelyDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_LONELY_DESC',
  EmaEntries3SVarianceSampleLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries3SVarianceSampleLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries3SVarianceSampleNoBurdenForExternalReasonsAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_NO_BURDEN_FOR_EXTERNAL_REASONS_ASC',
  EmaEntries3SVarianceSampleNoBurdenForExternalReasonsDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_NO_BURDEN_FOR_EXTERNAL_REASONS_DESC',
  EmaEntries3SVarianceSamplePlannedHowToProceedAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries3SVarianceSamplePlannedHowToProceedDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries3SVarianceSampleRelaxedAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_RELAXED_ASC',
  EmaEntries3SVarianceSampleRelaxedDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_RELAXED_DESC',
  EmaEntries3SVarianceSampleSadAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SAD_ASC',
  EmaEntries3SVarianceSampleSadDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SAD_DESC',
  EmaEntries3SVarianceSampleSatisfiedAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SATISFIED_ASC',
  EmaEntries3SVarianceSampleSatisfiedDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SATISFIED_DESC',
  EmaEntries3SVarianceSampleScheduledAtAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SCHEDULED_AT_ASC',
  EmaEntries3SVarianceSampleScheduledAtDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SCHEDULED_AT_DESC',
  EmaEntries3SVarianceSampleSmsReceivedAtAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SMS_RECEIVED_AT_ASC',
  EmaEntries3SVarianceSampleSmsReceivedAtDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SMS_RECEIVED_AT_DESC',
  EmaEntries3SVarianceSampleSocialDistressAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SOCIAL_DISTRESS_ASC',
  EmaEntries3SVarianceSampleSocialDistressDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_SOCIAL_DISTRESS_DESC',
  EmaEntries3SVarianceSampleStartedAtAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_STARTED_AT_ASC',
  EmaEntries3SVarianceSampleStartedAtDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_STARTED_AT_DESC',
  EmaEntries3SVarianceSampleStrainAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_STRAIN_ASC',
  EmaEntries3SVarianceSampleStrainDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_STRAIN_DESC',
  EmaEntries3SVarianceSampleStrengthAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_STRENGTH_ASC',
  EmaEntries3SVarianceSampleStrengthDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_STRENGTH_DESC',
  EmaEntries3SVarianceSampleStressedAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_STRESSED_ASC',
  EmaEntries3SVarianceSampleStressedDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_STRESSED_DESC',
  EmaEntries3SVarianceSampleTenseDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TENSE_DURING_CONTACT_ASC',
  EmaEntries3SVarianceSampleTenseDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TENSE_DURING_CONTACT_DESC',
  EmaEntries3SVarianceSampleTestyAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TESTY_ASC',
  EmaEntries3SVarianceSampleTestyDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TESTY_DESC',
  EmaEntries3SVarianceSampleTestyDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TESTY_DURING_CONTACT_ASC',
  EmaEntries3SVarianceSampleTestyDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TESTY_DURING_CONTACT_DESC',
  EmaEntries3SVarianceSampleTrialIdAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TRIAL_ID_ASC',
  EmaEntries3SVarianceSampleTrialIdDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TRIAL_ID_DESC',
  EmaEntries3SVarianceSampleTriedToCalmMyselfDownAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries3SVarianceSampleTriedToCalmMyselfDownDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries3SVarianceSampleTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries3SVarianceSampleTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries3SVarianceSampleTriedToThinkDifferentlyAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntries3SVarianceSampleTriedToThinkDifferentlyDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntries3SVarianceSampleTypicalThatNoBurdenHaveOccuredAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_ASC',
  EmaEntries3SVarianceSampleTypicalThatNoBurdenHaveOccuredDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_TYPICAL_THAT_NO_BURDEN_HAVE_OCCURED_DESC',
  EmaEntries3SVarianceSampleUnconfidentDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries3SVarianceSampleUnconfidentDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries3SVarianceSampleUnderstoodDuringContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_UNDERSTOOD_DURING_CONTACT_ASC',
  EmaEntries3SVarianceSampleUnderstoodDuringContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_UNDERSTOOD_DURING_CONTACT_DESC',
  EmaEntries3SVarianceSampleUpdatedAtAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EmaEntries3SVarianceSampleUpdatedAtDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EmaEntries3SVarianceSampleWaveAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_WAVE_ASC',
  EmaEntries3SVarianceSampleWaveDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_WAVE_DESC',
  EmaEntries3SVarianceSampleWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries3SVarianceSampleWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES3S_VARIANCE_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmergencyPhoneNumbersAsc = 'EMERGENCY_PHONE_NUMBERS_ASC',
  EmergencyPhoneNumbersDesc = 'EMERGENCY_PHONE_NUMBERS_DESC',
  FavoritePagesAverageAddedAtAsc = 'FAVORITE_PAGES_AVERAGE_ADDED_AT_ASC',
  FavoritePagesAverageAddedAtDesc = 'FAVORITE_PAGES_AVERAGE_ADDED_AT_DESC',
  FavoritePagesAverageRouteAsc = 'FAVORITE_PAGES_AVERAGE_ROUTE_ASC',
  FavoritePagesAverageRouteDesc = 'FAVORITE_PAGES_AVERAGE_ROUTE_DESC',
  FavoritePagesAverageTrialIdAsc = 'FAVORITE_PAGES_AVERAGE_TRIAL_ID_ASC',
  FavoritePagesAverageTrialIdDesc = 'FAVORITE_PAGES_AVERAGE_TRIAL_ID_DESC',
  FavoritePagesCountAsc = 'FAVORITE_PAGES_COUNT_ASC',
  FavoritePagesCountDesc = 'FAVORITE_PAGES_COUNT_DESC',
  FavoritePagesDistinctCountAddedAtAsc = 'FAVORITE_PAGES_DISTINCT_COUNT_ADDED_AT_ASC',
  FavoritePagesDistinctCountAddedAtDesc = 'FAVORITE_PAGES_DISTINCT_COUNT_ADDED_AT_DESC',
  FavoritePagesDistinctCountRouteAsc = 'FAVORITE_PAGES_DISTINCT_COUNT_ROUTE_ASC',
  FavoritePagesDistinctCountRouteDesc = 'FAVORITE_PAGES_DISTINCT_COUNT_ROUTE_DESC',
  FavoritePagesDistinctCountTrialIdAsc = 'FAVORITE_PAGES_DISTINCT_COUNT_TRIAL_ID_ASC',
  FavoritePagesDistinctCountTrialIdDesc = 'FAVORITE_PAGES_DISTINCT_COUNT_TRIAL_ID_DESC',
  FavoritePagesMaxAddedAtAsc = 'FAVORITE_PAGES_MAX_ADDED_AT_ASC',
  FavoritePagesMaxAddedAtDesc = 'FAVORITE_PAGES_MAX_ADDED_AT_DESC',
  FavoritePagesMaxRouteAsc = 'FAVORITE_PAGES_MAX_ROUTE_ASC',
  FavoritePagesMaxRouteDesc = 'FAVORITE_PAGES_MAX_ROUTE_DESC',
  FavoritePagesMaxTrialIdAsc = 'FAVORITE_PAGES_MAX_TRIAL_ID_ASC',
  FavoritePagesMaxTrialIdDesc = 'FAVORITE_PAGES_MAX_TRIAL_ID_DESC',
  FavoritePagesMinAddedAtAsc = 'FAVORITE_PAGES_MIN_ADDED_AT_ASC',
  FavoritePagesMinAddedAtDesc = 'FAVORITE_PAGES_MIN_ADDED_AT_DESC',
  FavoritePagesMinRouteAsc = 'FAVORITE_PAGES_MIN_ROUTE_ASC',
  FavoritePagesMinRouteDesc = 'FAVORITE_PAGES_MIN_ROUTE_DESC',
  FavoritePagesMinTrialIdAsc = 'FAVORITE_PAGES_MIN_TRIAL_ID_ASC',
  FavoritePagesMinTrialIdDesc = 'FAVORITE_PAGES_MIN_TRIAL_ID_DESC',
  FavoritePagesStddevPopulationAddedAtAsc = 'FAVORITE_PAGES_STDDEV_POPULATION_ADDED_AT_ASC',
  FavoritePagesStddevPopulationAddedAtDesc = 'FAVORITE_PAGES_STDDEV_POPULATION_ADDED_AT_DESC',
  FavoritePagesStddevPopulationRouteAsc = 'FAVORITE_PAGES_STDDEV_POPULATION_ROUTE_ASC',
  FavoritePagesStddevPopulationRouteDesc = 'FAVORITE_PAGES_STDDEV_POPULATION_ROUTE_DESC',
  FavoritePagesStddevPopulationTrialIdAsc = 'FAVORITE_PAGES_STDDEV_POPULATION_TRIAL_ID_ASC',
  FavoritePagesStddevPopulationTrialIdDesc = 'FAVORITE_PAGES_STDDEV_POPULATION_TRIAL_ID_DESC',
  FavoritePagesStddevSampleAddedAtAsc = 'FAVORITE_PAGES_STDDEV_SAMPLE_ADDED_AT_ASC',
  FavoritePagesStddevSampleAddedAtDesc = 'FAVORITE_PAGES_STDDEV_SAMPLE_ADDED_AT_DESC',
  FavoritePagesStddevSampleRouteAsc = 'FAVORITE_PAGES_STDDEV_SAMPLE_ROUTE_ASC',
  FavoritePagesStddevSampleRouteDesc = 'FAVORITE_PAGES_STDDEV_SAMPLE_ROUTE_DESC',
  FavoritePagesStddevSampleTrialIdAsc = 'FAVORITE_PAGES_STDDEV_SAMPLE_TRIAL_ID_ASC',
  FavoritePagesStddevSampleTrialIdDesc = 'FAVORITE_PAGES_STDDEV_SAMPLE_TRIAL_ID_DESC',
  FavoritePagesSumAddedAtAsc = 'FAVORITE_PAGES_SUM_ADDED_AT_ASC',
  FavoritePagesSumAddedAtDesc = 'FAVORITE_PAGES_SUM_ADDED_AT_DESC',
  FavoritePagesSumRouteAsc = 'FAVORITE_PAGES_SUM_ROUTE_ASC',
  FavoritePagesSumRouteDesc = 'FAVORITE_PAGES_SUM_ROUTE_DESC',
  FavoritePagesSumTrialIdAsc = 'FAVORITE_PAGES_SUM_TRIAL_ID_ASC',
  FavoritePagesSumTrialIdDesc = 'FAVORITE_PAGES_SUM_TRIAL_ID_DESC',
  FavoritePagesVariancePopulationAddedAtAsc = 'FAVORITE_PAGES_VARIANCE_POPULATION_ADDED_AT_ASC',
  FavoritePagesVariancePopulationAddedAtDesc = 'FAVORITE_PAGES_VARIANCE_POPULATION_ADDED_AT_DESC',
  FavoritePagesVariancePopulationRouteAsc = 'FAVORITE_PAGES_VARIANCE_POPULATION_ROUTE_ASC',
  FavoritePagesVariancePopulationRouteDesc = 'FAVORITE_PAGES_VARIANCE_POPULATION_ROUTE_DESC',
  FavoritePagesVariancePopulationTrialIdAsc = 'FAVORITE_PAGES_VARIANCE_POPULATION_TRIAL_ID_ASC',
  FavoritePagesVariancePopulationTrialIdDesc = 'FAVORITE_PAGES_VARIANCE_POPULATION_TRIAL_ID_DESC',
  FavoritePagesVarianceSampleAddedAtAsc = 'FAVORITE_PAGES_VARIANCE_SAMPLE_ADDED_AT_ASC',
  FavoritePagesVarianceSampleAddedAtDesc = 'FAVORITE_PAGES_VARIANCE_SAMPLE_ADDED_AT_DESC',
  FavoritePagesVarianceSampleRouteAsc = 'FAVORITE_PAGES_VARIANCE_SAMPLE_ROUTE_ASC',
  FavoritePagesVarianceSampleRouteDesc = 'FAVORITE_PAGES_VARIANCE_SAMPLE_ROUTE_DESC',
  FavoritePagesVarianceSampleTrialIdAsc = 'FAVORITE_PAGES_VARIANCE_SAMPLE_TRIAL_ID_ASC',
  FavoritePagesVarianceSampleTrialIdDesc = 'FAVORITE_PAGES_VARIANCE_SAMPLE_TRIAL_ID_DESC',
  GenericEmiExercisesAverageBodyAsc = 'GENERIC_EMI_EXERCISES_AVERAGE_BODY_ASC',
  GenericEmiExercisesAverageBodyDesc = 'GENERIC_EMI_EXERCISES_AVERAGE_BODY_DESC',
  GenericEmiExercisesAverageCreatedAtAsc = 'GENERIC_EMI_EXERCISES_AVERAGE_CREATED_AT_ASC',
  GenericEmiExercisesAverageCreatedAtDesc = 'GENERIC_EMI_EXERCISES_AVERAGE_CREATED_AT_DESC',
  GenericEmiExercisesAverageIdAsc = 'GENERIC_EMI_EXERCISES_AVERAGE_ID_ASC',
  GenericEmiExercisesAverageIdDesc = 'GENERIC_EMI_EXERCISES_AVERAGE_ID_DESC',
  GenericEmiExercisesAverageNameAsc = 'GENERIC_EMI_EXERCISES_AVERAGE_NAME_ASC',
  GenericEmiExercisesAverageNameDesc = 'GENERIC_EMI_EXERCISES_AVERAGE_NAME_DESC',
  GenericEmiExercisesAverageTrialIdAsc = 'GENERIC_EMI_EXERCISES_AVERAGE_TRIAL_ID_ASC',
  GenericEmiExercisesAverageTrialIdDesc = 'GENERIC_EMI_EXERCISES_AVERAGE_TRIAL_ID_DESC',
  GenericEmiExercisesAverageTypeAsc = 'GENERIC_EMI_EXERCISES_AVERAGE_TYPE_ASC',
  GenericEmiExercisesAverageTypeDesc = 'GENERIC_EMI_EXERCISES_AVERAGE_TYPE_DESC',
  GenericEmiExercisesAverageUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_AVERAGE_UPDATED_AT_ASC',
  GenericEmiExercisesAverageUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_AVERAGE_UPDATED_AT_DESC',
  GenericEmiExercisesCountAsc = 'GENERIC_EMI_EXERCISES_COUNT_ASC',
  GenericEmiExercisesCountDesc = 'GENERIC_EMI_EXERCISES_COUNT_DESC',
  GenericEmiExercisesDistinctCountBodyAsc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_BODY_ASC',
  GenericEmiExercisesDistinctCountBodyDesc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_BODY_DESC',
  GenericEmiExercisesDistinctCountCreatedAtAsc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_CREATED_AT_ASC',
  GenericEmiExercisesDistinctCountCreatedAtDesc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_CREATED_AT_DESC',
  GenericEmiExercisesDistinctCountIdAsc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_ID_ASC',
  GenericEmiExercisesDistinctCountIdDesc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_ID_DESC',
  GenericEmiExercisesDistinctCountNameAsc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_NAME_ASC',
  GenericEmiExercisesDistinctCountNameDesc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_NAME_DESC',
  GenericEmiExercisesDistinctCountTrialIdAsc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_TRIAL_ID_ASC',
  GenericEmiExercisesDistinctCountTrialIdDesc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_TRIAL_ID_DESC',
  GenericEmiExercisesDistinctCountTypeAsc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_TYPE_ASC',
  GenericEmiExercisesDistinctCountTypeDesc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_TYPE_DESC',
  GenericEmiExercisesDistinctCountUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_UPDATED_AT_ASC',
  GenericEmiExercisesDistinctCountUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_DISTINCT_COUNT_UPDATED_AT_DESC',
  GenericEmiExercisesMaxBodyAsc = 'GENERIC_EMI_EXERCISES_MAX_BODY_ASC',
  GenericEmiExercisesMaxBodyDesc = 'GENERIC_EMI_EXERCISES_MAX_BODY_DESC',
  GenericEmiExercisesMaxCreatedAtAsc = 'GENERIC_EMI_EXERCISES_MAX_CREATED_AT_ASC',
  GenericEmiExercisesMaxCreatedAtDesc = 'GENERIC_EMI_EXERCISES_MAX_CREATED_AT_DESC',
  GenericEmiExercisesMaxIdAsc = 'GENERIC_EMI_EXERCISES_MAX_ID_ASC',
  GenericEmiExercisesMaxIdDesc = 'GENERIC_EMI_EXERCISES_MAX_ID_DESC',
  GenericEmiExercisesMaxNameAsc = 'GENERIC_EMI_EXERCISES_MAX_NAME_ASC',
  GenericEmiExercisesMaxNameDesc = 'GENERIC_EMI_EXERCISES_MAX_NAME_DESC',
  GenericEmiExercisesMaxTrialIdAsc = 'GENERIC_EMI_EXERCISES_MAX_TRIAL_ID_ASC',
  GenericEmiExercisesMaxTrialIdDesc = 'GENERIC_EMI_EXERCISES_MAX_TRIAL_ID_DESC',
  GenericEmiExercisesMaxTypeAsc = 'GENERIC_EMI_EXERCISES_MAX_TYPE_ASC',
  GenericEmiExercisesMaxTypeDesc = 'GENERIC_EMI_EXERCISES_MAX_TYPE_DESC',
  GenericEmiExercisesMaxUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_MAX_UPDATED_AT_ASC',
  GenericEmiExercisesMaxUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_MAX_UPDATED_AT_DESC',
  GenericEmiExercisesMinBodyAsc = 'GENERIC_EMI_EXERCISES_MIN_BODY_ASC',
  GenericEmiExercisesMinBodyDesc = 'GENERIC_EMI_EXERCISES_MIN_BODY_DESC',
  GenericEmiExercisesMinCreatedAtAsc = 'GENERIC_EMI_EXERCISES_MIN_CREATED_AT_ASC',
  GenericEmiExercisesMinCreatedAtDesc = 'GENERIC_EMI_EXERCISES_MIN_CREATED_AT_DESC',
  GenericEmiExercisesMinIdAsc = 'GENERIC_EMI_EXERCISES_MIN_ID_ASC',
  GenericEmiExercisesMinIdDesc = 'GENERIC_EMI_EXERCISES_MIN_ID_DESC',
  GenericEmiExercisesMinNameAsc = 'GENERIC_EMI_EXERCISES_MIN_NAME_ASC',
  GenericEmiExercisesMinNameDesc = 'GENERIC_EMI_EXERCISES_MIN_NAME_DESC',
  GenericEmiExercisesMinTrialIdAsc = 'GENERIC_EMI_EXERCISES_MIN_TRIAL_ID_ASC',
  GenericEmiExercisesMinTrialIdDesc = 'GENERIC_EMI_EXERCISES_MIN_TRIAL_ID_DESC',
  GenericEmiExercisesMinTypeAsc = 'GENERIC_EMI_EXERCISES_MIN_TYPE_ASC',
  GenericEmiExercisesMinTypeDesc = 'GENERIC_EMI_EXERCISES_MIN_TYPE_DESC',
  GenericEmiExercisesMinUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_MIN_UPDATED_AT_ASC',
  GenericEmiExercisesMinUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_MIN_UPDATED_AT_DESC',
  GenericEmiExercisesStddevPopulationBodyAsc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_BODY_ASC',
  GenericEmiExercisesStddevPopulationBodyDesc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_BODY_DESC',
  GenericEmiExercisesStddevPopulationCreatedAtAsc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_CREATED_AT_ASC',
  GenericEmiExercisesStddevPopulationCreatedAtDesc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_CREATED_AT_DESC',
  GenericEmiExercisesStddevPopulationIdAsc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_ID_ASC',
  GenericEmiExercisesStddevPopulationIdDesc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_ID_DESC',
  GenericEmiExercisesStddevPopulationNameAsc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_NAME_ASC',
  GenericEmiExercisesStddevPopulationNameDesc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_NAME_DESC',
  GenericEmiExercisesStddevPopulationTrialIdAsc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_TRIAL_ID_ASC',
  GenericEmiExercisesStddevPopulationTrialIdDesc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_TRIAL_ID_DESC',
  GenericEmiExercisesStddevPopulationTypeAsc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_TYPE_ASC',
  GenericEmiExercisesStddevPopulationTypeDesc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_TYPE_DESC',
  GenericEmiExercisesStddevPopulationUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_UPDATED_AT_ASC',
  GenericEmiExercisesStddevPopulationUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_STDDEV_POPULATION_UPDATED_AT_DESC',
  GenericEmiExercisesStddevSampleBodyAsc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_BODY_ASC',
  GenericEmiExercisesStddevSampleBodyDesc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_BODY_DESC',
  GenericEmiExercisesStddevSampleCreatedAtAsc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_CREATED_AT_ASC',
  GenericEmiExercisesStddevSampleCreatedAtDesc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_CREATED_AT_DESC',
  GenericEmiExercisesStddevSampleIdAsc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_ID_ASC',
  GenericEmiExercisesStddevSampleIdDesc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_ID_DESC',
  GenericEmiExercisesStddevSampleNameAsc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_NAME_ASC',
  GenericEmiExercisesStddevSampleNameDesc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_NAME_DESC',
  GenericEmiExercisesStddevSampleTrialIdAsc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_TRIAL_ID_ASC',
  GenericEmiExercisesStddevSampleTrialIdDesc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_TRIAL_ID_DESC',
  GenericEmiExercisesStddevSampleTypeAsc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_TYPE_ASC',
  GenericEmiExercisesStddevSampleTypeDesc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_TYPE_DESC',
  GenericEmiExercisesStddevSampleUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_UPDATED_AT_ASC',
  GenericEmiExercisesStddevSampleUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_STDDEV_SAMPLE_UPDATED_AT_DESC',
  GenericEmiExercisesSumBodyAsc = 'GENERIC_EMI_EXERCISES_SUM_BODY_ASC',
  GenericEmiExercisesSumBodyDesc = 'GENERIC_EMI_EXERCISES_SUM_BODY_DESC',
  GenericEmiExercisesSumCreatedAtAsc = 'GENERIC_EMI_EXERCISES_SUM_CREATED_AT_ASC',
  GenericEmiExercisesSumCreatedAtDesc = 'GENERIC_EMI_EXERCISES_SUM_CREATED_AT_DESC',
  GenericEmiExercisesSumIdAsc = 'GENERIC_EMI_EXERCISES_SUM_ID_ASC',
  GenericEmiExercisesSumIdDesc = 'GENERIC_EMI_EXERCISES_SUM_ID_DESC',
  GenericEmiExercisesSumNameAsc = 'GENERIC_EMI_EXERCISES_SUM_NAME_ASC',
  GenericEmiExercisesSumNameDesc = 'GENERIC_EMI_EXERCISES_SUM_NAME_DESC',
  GenericEmiExercisesSumTrialIdAsc = 'GENERIC_EMI_EXERCISES_SUM_TRIAL_ID_ASC',
  GenericEmiExercisesSumTrialIdDesc = 'GENERIC_EMI_EXERCISES_SUM_TRIAL_ID_DESC',
  GenericEmiExercisesSumTypeAsc = 'GENERIC_EMI_EXERCISES_SUM_TYPE_ASC',
  GenericEmiExercisesSumTypeDesc = 'GENERIC_EMI_EXERCISES_SUM_TYPE_DESC',
  GenericEmiExercisesSumUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_SUM_UPDATED_AT_ASC',
  GenericEmiExercisesSumUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_SUM_UPDATED_AT_DESC',
  GenericEmiExercisesVariancePopulationBodyAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_BODY_ASC',
  GenericEmiExercisesVariancePopulationBodyDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_BODY_DESC',
  GenericEmiExercisesVariancePopulationCreatedAtAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_CREATED_AT_ASC',
  GenericEmiExercisesVariancePopulationCreatedAtDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_CREATED_AT_DESC',
  GenericEmiExercisesVariancePopulationIdAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_ID_ASC',
  GenericEmiExercisesVariancePopulationIdDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_ID_DESC',
  GenericEmiExercisesVariancePopulationNameAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_NAME_ASC',
  GenericEmiExercisesVariancePopulationNameDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_NAME_DESC',
  GenericEmiExercisesVariancePopulationTrialIdAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_TRIAL_ID_ASC',
  GenericEmiExercisesVariancePopulationTrialIdDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_TRIAL_ID_DESC',
  GenericEmiExercisesVariancePopulationTypeAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_TYPE_ASC',
  GenericEmiExercisesVariancePopulationTypeDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_TYPE_DESC',
  GenericEmiExercisesVariancePopulationUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_UPDATED_AT_ASC',
  GenericEmiExercisesVariancePopulationUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_POPULATION_UPDATED_AT_DESC',
  GenericEmiExercisesVarianceSampleBodyAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_BODY_ASC',
  GenericEmiExercisesVarianceSampleBodyDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_BODY_DESC',
  GenericEmiExercisesVarianceSampleCreatedAtAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GenericEmiExercisesVarianceSampleCreatedAtDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GenericEmiExercisesVarianceSampleIdAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_ID_ASC',
  GenericEmiExercisesVarianceSampleIdDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_ID_DESC',
  GenericEmiExercisesVarianceSampleNameAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_NAME_ASC',
  GenericEmiExercisesVarianceSampleNameDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_NAME_DESC',
  GenericEmiExercisesVarianceSampleTrialIdAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_TRIAL_ID_ASC',
  GenericEmiExercisesVarianceSampleTrialIdDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_TRIAL_ID_DESC',
  GenericEmiExercisesVarianceSampleTypeAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_TYPE_ASC',
  GenericEmiExercisesVarianceSampleTypeDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_TYPE_DESC',
  GenericEmiExercisesVarianceSampleUpdatedAtAsc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  GenericEmiExercisesVarianceSampleUpdatedAtDesc = 'GENERIC_EMI_EXERCISES_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  HasAccessToASmartphoneAsc = 'HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  HasAccessToASmartphoneDesc = 'HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  HasAdequateKnowledgeOfGermanAsc = 'HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  HasAdequateKnowledgeOfGermanDesc = 'HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  HasAgeBetween_18And_25Asc = 'HAS_AGE_BETWEEN_18_AND_25_ASC',
  HasAgeBetween_18And_25Desc = 'HAS_AGE_BETWEEN_18_AND_25_DESC',
  HasCurrentSuicidalIdeationAsc = 'HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  HasCurrentSuicidalIdeationDesc = 'HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  HasFacedAdverseChildhoodExperiencesAsc = 'HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  HasFacedAdverseChildhoodExperiencesDesc = 'HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  HasGivenInformedConsentAtAsc = 'HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  HasGivenInformedConsentAtDesc = 'HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  HumanReadableIdAsc = 'HUMAN_READABLE_ID_ASC',
  HumanReadableIdDesc = 'HUMAN_READABLE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalMessagesIdAsc = 'INTERNAL_MESSAGES_ID_ASC',
  InternalMessagesIdDesc = 'INTERNAL_MESSAGES_ID_DESC',
  LimesurveyTokensAverageCompletedAtAsc = 'LIMESURVEY_TOKENS_AVERAGE_COMPLETED_AT_ASC',
  LimesurveyTokensAverageCompletedAtDesc = 'LIMESURVEY_TOKENS_AVERAGE_COMPLETED_AT_DESC',
  LimesurveyTokensAverageHandedOutAtAsc = 'LIMESURVEY_TOKENS_AVERAGE_HANDED_OUT_AT_ASC',
  LimesurveyTokensAverageHandedOutAtDesc = 'LIMESURVEY_TOKENS_AVERAGE_HANDED_OUT_AT_DESC',
  LimesurveyTokensAverageStartedAtAsc = 'LIMESURVEY_TOKENS_AVERAGE_STARTED_AT_ASC',
  LimesurveyTokensAverageStartedAtDesc = 'LIMESURVEY_TOKENS_AVERAGE_STARTED_AT_DESC',
  LimesurveyTokensAverageSurveyIdAsc = 'LIMESURVEY_TOKENS_AVERAGE_SURVEY_ID_ASC',
  LimesurveyTokensAverageSurveyIdDesc = 'LIMESURVEY_TOKENS_AVERAGE_SURVEY_ID_DESC',
  LimesurveyTokensAverageTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_AVERAGE_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensAverageTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_AVERAGE_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensAverageTokenAsc = 'LIMESURVEY_TOKENS_AVERAGE_TOKEN_ASC',
  LimesurveyTokensAverageTokenDesc = 'LIMESURVEY_TOKENS_AVERAGE_TOKEN_DESC',
  LimesurveyTokensAverageTrialIdAsc = 'LIMESURVEY_TOKENS_AVERAGE_TRIAL_ID_ASC',
  LimesurveyTokensAverageTrialIdDesc = 'LIMESURVEY_TOKENS_AVERAGE_TRIAL_ID_DESC',
  LimesurveyTokensCountAsc = 'LIMESURVEY_TOKENS_COUNT_ASC',
  LimesurveyTokensCountDesc = 'LIMESURVEY_TOKENS_COUNT_DESC',
  LimesurveyTokensDistinctCountCompletedAtAsc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_COMPLETED_AT_ASC',
  LimesurveyTokensDistinctCountCompletedAtDesc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_COMPLETED_AT_DESC',
  LimesurveyTokensDistinctCountHandedOutAtAsc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_HANDED_OUT_AT_ASC',
  LimesurveyTokensDistinctCountHandedOutAtDesc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_HANDED_OUT_AT_DESC',
  LimesurveyTokensDistinctCountStartedAtAsc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_STARTED_AT_ASC',
  LimesurveyTokensDistinctCountStartedAtDesc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_STARTED_AT_DESC',
  LimesurveyTokensDistinctCountSurveyIdAsc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_SURVEY_ID_ASC',
  LimesurveyTokensDistinctCountSurveyIdDesc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_SURVEY_ID_DESC',
  LimesurveyTokensDistinctCountTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensDistinctCountTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensDistinctCountTokenAsc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_TOKEN_ASC',
  LimesurveyTokensDistinctCountTokenDesc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_TOKEN_DESC',
  LimesurveyTokensDistinctCountTrialIdAsc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_TRIAL_ID_ASC',
  LimesurveyTokensDistinctCountTrialIdDesc = 'LIMESURVEY_TOKENS_DISTINCT_COUNT_TRIAL_ID_DESC',
  LimesurveyTokensMaxCompletedAtAsc = 'LIMESURVEY_TOKENS_MAX_COMPLETED_AT_ASC',
  LimesurveyTokensMaxCompletedAtDesc = 'LIMESURVEY_TOKENS_MAX_COMPLETED_AT_DESC',
  LimesurveyTokensMaxHandedOutAtAsc = 'LIMESURVEY_TOKENS_MAX_HANDED_OUT_AT_ASC',
  LimesurveyTokensMaxHandedOutAtDesc = 'LIMESURVEY_TOKENS_MAX_HANDED_OUT_AT_DESC',
  LimesurveyTokensMaxStartedAtAsc = 'LIMESURVEY_TOKENS_MAX_STARTED_AT_ASC',
  LimesurveyTokensMaxStartedAtDesc = 'LIMESURVEY_TOKENS_MAX_STARTED_AT_DESC',
  LimesurveyTokensMaxSurveyIdAsc = 'LIMESURVEY_TOKENS_MAX_SURVEY_ID_ASC',
  LimesurveyTokensMaxSurveyIdDesc = 'LIMESURVEY_TOKENS_MAX_SURVEY_ID_DESC',
  LimesurveyTokensMaxTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_MAX_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensMaxTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_MAX_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensMaxTokenAsc = 'LIMESURVEY_TOKENS_MAX_TOKEN_ASC',
  LimesurveyTokensMaxTokenDesc = 'LIMESURVEY_TOKENS_MAX_TOKEN_DESC',
  LimesurveyTokensMaxTrialIdAsc = 'LIMESURVEY_TOKENS_MAX_TRIAL_ID_ASC',
  LimesurveyTokensMaxTrialIdDesc = 'LIMESURVEY_TOKENS_MAX_TRIAL_ID_DESC',
  LimesurveyTokensMinCompletedAtAsc = 'LIMESURVEY_TOKENS_MIN_COMPLETED_AT_ASC',
  LimesurveyTokensMinCompletedAtDesc = 'LIMESURVEY_TOKENS_MIN_COMPLETED_AT_DESC',
  LimesurveyTokensMinHandedOutAtAsc = 'LIMESURVEY_TOKENS_MIN_HANDED_OUT_AT_ASC',
  LimesurveyTokensMinHandedOutAtDesc = 'LIMESURVEY_TOKENS_MIN_HANDED_OUT_AT_DESC',
  LimesurveyTokensMinStartedAtAsc = 'LIMESURVEY_TOKENS_MIN_STARTED_AT_ASC',
  LimesurveyTokensMinStartedAtDesc = 'LIMESURVEY_TOKENS_MIN_STARTED_AT_DESC',
  LimesurveyTokensMinSurveyIdAsc = 'LIMESURVEY_TOKENS_MIN_SURVEY_ID_ASC',
  LimesurveyTokensMinSurveyIdDesc = 'LIMESURVEY_TOKENS_MIN_SURVEY_ID_DESC',
  LimesurveyTokensMinTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_MIN_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensMinTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_MIN_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensMinTokenAsc = 'LIMESURVEY_TOKENS_MIN_TOKEN_ASC',
  LimesurveyTokensMinTokenDesc = 'LIMESURVEY_TOKENS_MIN_TOKEN_DESC',
  LimesurveyTokensMinTrialIdAsc = 'LIMESURVEY_TOKENS_MIN_TRIAL_ID_ASC',
  LimesurveyTokensMinTrialIdDesc = 'LIMESURVEY_TOKENS_MIN_TRIAL_ID_DESC',
  LimesurveyTokensStddevPopulationCompletedAtAsc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_COMPLETED_AT_ASC',
  LimesurveyTokensStddevPopulationCompletedAtDesc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_COMPLETED_AT_DESC',
  LimesurveyTokensStddevPopulationHandedOutAtAsc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_HANDED_OUT_AT_ASC',
  LimesurveyTokensStddevPopulationHandedOutAtDesc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_HANDED_OUT_AT_DESC',
  LimesurveyTokensStddevPopulationStartedAtAsc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_STARTED_AT_ASC',
  LimesurveyTokensStddevPopulationStartedAtDesc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_STARTED_AT_DESC',
  LimesurveyTokensStddevPopulationSurveyIdAsc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_SURVEY_ID_ASC',
  LimesurveyTokensStddevPopulationSurveyIdDesc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_SURVEY_ID_DESC',
  LimesurveyTokensStddevPopulationTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensStddevPopulationTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensStddevPopulationTokenAsc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_TOKEN_ASC',
  LimesurveyTokensStddevPopulationTokenDesc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_TOKEN_DESC',
  LimesurveyTokensStddevPopulationTrialIdAsc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_TRIAL_ID_ASC',
  LimesurveyTokensStddevPopulationTrialIdDesc = 'LIMESURVEY_TOKENS_STDDEV_POPULATION_TRIAL_ID_DESC',
  LimesurveyTokensStddevSampleCompletedAtAsc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  LimesurveyTokensStddevSampleCompletedAtDesc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  LimesurveyTokensStddevSampleHandedOutAtAsc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_HANDED_OUT_AT_ASC',
  LimesurveyTokensStddevSampleHandedOutAtDesc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_HANDED_OUT_AT_DESC',
  LimesurveyTokensStddevSampleStartedAtAsc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_STARTED_AT_ASC',
  LimesurveyTokensStddevSampleStartedAtDesc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_STARTED_AT_DESC',
  LimesurveyTokensStddevSampleSurveyIdAsc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_SURVEY_ID_ASC',
  LimesurveyTokensStddevSampleSurveyIdDesc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_SURVEY_ID_DESC',
  LimesurveyTokensStddevSampleTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensStddevSampleTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensStddevSampleTokenAsc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_TOKEN_ASC',
  LimesurveyTokensStddevSampleTokenDesc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_TOKEN_DESC',
  LimesurveyTokensStddevSampleTrialIdAsc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_TRIAL_ID_ASC',
  LimesurveyTokensStddevSampleTrialIdDesc = 'LIMESURVEY_TOKENS_STDDEV_SAMPLE_TRIAL_ID_DESC',
  LimesurveyTokensSumCompletedAtAsc = 'LIMESURVEY_TOKENS_SUM_COMPLETED_AT_ASC',
  LimesurveyTokensSumCompletedAtDesc = 'LIMESURVEY_TOKENS_SUM_COMPLETED_AT_DESC',
  LimesurveyTokensSumHandedOutAtAsc = 'LIMESURVEY_TOKENS_SUM_HANDED_OUT_AT_ASC',
  LimesurveyTokensSumHandedOutAtDesc = 'LIMESURVEY_TOKENS_SUM_HANDED_OUT_AT_DESC',
  LimesurveyTokensSumStartedAtAsc = 'LIMESURVEY_TOKENS_SUM_STARTED_AT_ASC',
  LimesurveyTokensSumStartedAtDesc = 'LIMESURVEY_TOKENS_SUM_STARTED_AT_DESC',
  LimesurveyTokensSumSurveyIdAsc = 'LIMESURVEY_TOKENS_SUM_SURVEY_ID_ASC',
  LimesurveyTokensSumSurveyIdDesc = 'LIMESURVEY_TOKENS_SUM_SURVEY_ID_DESC',
  LimesurveyTokensSumTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_SUM_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensSumTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_SUM_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensSumTokenAsc = 'LIMESURVEY_TOKENS_SUM_TOKEN_ASC',
  LimesurveyTokensSumTokenDesc = 'LIMESURVEY_TOKENS_SUM_TOKEN_DESC',
  LimesurveyTokensSumTrialIdAsc = 'LIMESURVEY_TOKENS_SUM_TRIAL_ID_ASC',
  LimesurveyTokensSumTrialIdDesc = 'LIMESURVEY_TOKENS_SUM_TRIAL_ID_DESC',
  LimesurveyTokensVariancePopulationCompletedAtAsc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  LimesurveyTokensVariancePopulationCompletedAtDesc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  LimesurveyTokensVariancePopulationHandedOutAtAsc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_HANDED_OUT_AT_ASC',
  LimesurveyTokensVariancePopulationHandedOutAtDesc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_HANDED_OUT_AT_DESC',
  LimesurveyTokensVariancePopulationStartedAtAsc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_STARTED_AT_ASC',
  LimesurveyTokensVariancePopulationStartedAtDesc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_STARTED_AT_DESC',
  LimesurveyTokensVariancePopulationSurveyIdAsc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_SURVEY_ID_ASC',
  LimesurveyTokensVariancePopulationSurveyIdDesc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_SURVEY_ID_DESC',
  LimesurveyTokensVariancePopulationTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensVariancePopulationTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensVariancePopulationTokenAsc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_TOKEN_ASC',
  LimesurveyTokensVariancePopulationTokenDesc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_TOKEN_DESC',
  LimesurveyTokensVariancePopulationTrialIdAsc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_TRIAL_ID_ASC',
  LimesurveyTokensVariancePopulationTrialIdDesc = 'LIMESURVEY_TOKENS_VARIANCE_POPULATION_TRIAL_ID_DESC',
  LimesurveyTokensVarianceSampleCompletedAtAsc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  LimesurveyTokensVarianceSampleCompletedAtDesc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  LimesurveyTokensVarianceSampleHandedOutAtAsc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_HANDED_OUT_AT_ASC',
  LimesurveyTokensVarianceSampleHandedOutAtDesc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_HANDED_OUT_AT_DESC',
  LimesurveyTokensVarianceSampleStartedAtAsc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_STARTED_AT_ASC',
  LimesurveyTokensVarianceSampleStartedAtDesc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_STARTED_AT_DESC',
  LimesurveyTokensVarianceSampleSurveyIdAsc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_SURVEY_ID_ASC',
  LimesurveyTokensVarianceSampleSurveyIdDesc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_SURVEY_ID_DESC',
  LimesurveyTokensVarianceSampleTimeOfMeasurementAsc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_TIME_OF_MEASUREMENT_ASC',
  LimesurveyTokensVarianceSampleTimeOfMeasurementDesc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_TIME_OF_MEASUREMENT_DESC',
  LimesurveyTokensVarianceSampleTokenAsc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_TOKEN_ASC',
  LimesurveyTokensVarianceSampleTokenDesc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_TOKEN_DESC',
  LimesurveyTokensVarianceSampleTrialIdAsc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_TRIAL_ID_ASC',
  LimesurveyTokensVarianceSampleTrialIdDesc = 'LIMESURVEY_TOKENS_VARIANCE_SAMPLE_TRIAL_ID_DESC',
  MeetsAllInclusionCriteriaAsc = 'MEETS_ALL_INCLUSION_CRITERIA_ASC',
  MeetsAllInclusionCriteriaDesc = 'MEETS_ALL_INCLUSION_CRITERIA_DESC',
  MeetsAllInclusionCriteriaExceptInformedConsentAsc = 'MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  MeetsAllInclusionCriteriaExceptInformedConsentDesc = 'MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  MeetsAnyExclusionCriteriaAsc = 'MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  MeetsAnyExclusionCriteriaDesc = 'MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  MessagesIdAsc = 'MESSAGES_ID_ASC',
  MessagesIdDesc = 'MESSAGES_ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PageHitsAverageDurationAsc = 'PAGE_HITS_AVERAGE_DURATION_ASC',
  PageHitsAverageDurationDesc = 'PAGE_HITS_AVERAGE_DURATION_DESC',
  PageHitsAverageNthVisitAsc = 'PAGE_HITS_AVERAGE_NTH_VISIT_ASC',
  PageHitsAverageNthVisitDesc = 'PAGE_HITS_AVERAGE_NTH_VISIT_DESC',
  PageHitsAveragePathAsc = 'PAGE_HITS_AVERAGE_PATH_ASC',
  PageHitsAveragePathDesc = 'PAGE_HITS_AVERAGE_PATH_DESC',
  PageHitsAverageQueryParametersAsc = 'PAGE_HITS_AVERAGE_QUERY_PARAMETERS_ASC',
  PageHitsAverageQueryParametersDesc = 'PAGE_HITS_AVERAGE_QUERY_PARAMETERS_DESC',
  PageHitsAverageRouteAsc = 'PAGE_HITS_AVERAGE_ROUTE_ASC',
  PageHitsAverageRouteDesc = 'PAGE_HITS_AVERAGE_ROUTE_DESC',
  PageHitsAverageTrackedAtAsc = 'PAGE_HITS_AVERAGE_TRACKED_AT_ASC',
  PageHitsAverageTrackedAtDesc = 'PAGE_HITS_AVERAGE_TRACKED_AT_DESC',
  PageHitsAverageTrialIdAsc = 'PAGE_HITS_AVERAGE_TRIAL_ID_ASC',
  PageHitsAverageTrialIdDesc = 'PAGE_HITS_AVERAGE_TRIAL_ID_DESC',
  PageHitsCountAsc = 'PAGE_HITS_COUNT_ASC',
  PageHitsCountDesc = 'PAGE_HITS_COUNT_DESC',
  PageHitsDistinctCountDurationAsc = 'PAGE_HITS_DISTINCT_COUNT_DURATION_ASC',
  PageHitsDistinctCountDurationDesc = 'PAGE_HITS_DISTINCT_COUNT_DURATION_DESC',
  PageHitsDistinctCountNthVisitAsc = 'PAGE_HITS_DISTINCT_COUNT_NTH_VISIT_ASC',
  PageHitsDistinctCountNthVisitDesc = 'PAGE_HITS_DISTINCT_COUNT_NTH_VISIT_DESC',
  PageHitsDistinctCountPathAsc = 'PAGE_HITS_DISTINCT_COUNT_PATH_ASC',
  PageHitsDistinctCountPathDesc = 'PAGE_HITS_DISTINCT_COUNT_PATH_DESC',
  PageHitsDistinctCountQueryParametersAsc = 'PAGE_HITS_DISTINCT_COUNT_QUERY_PARAMETERS_ASC',
  PageHitsDistinctCountQueryParametersDesc = 'PAGE_HITS_DISTINCT_COUNT_QUERY_PARAMETERS_DESC',
  PageHitsDistinctCountRouteAsc = 'PAGE_HITS_DISTINCT_COUNT_ROUTE_ASC',
  PageHitsDistinctCountRouteDesc = 'PAGE_HITS_DISTINCT_COUNT_ROUTE_DESC',
  PageHitsDistinctCountTrackedAtAsc = 'PAGE_HITS_DISTINCT_COUNT_TRACKED_AT_ASC',
  PageHitsDistinctCountTrackedAtDesc = 'PAGE_HITS_DISTINCT_COUNT_TRACKED_AT_DESC',
  PageHitsDistinctCountTrialIdAsc = 'PAGE_HITS_DISTINCT_COUNT_TRIAL_ID_ASC',
  PageHitsDistinctCountTrialIdDesc = 'PAGE_HITS_DISTINCT_COUNT_TRIAL_ID_DESC',
  PageHitsMaxDurationAsc = 'PAGE_HITS_MAX_DURATION_ASC',
  PageHitsMaxDurationDesc = 'PAGE_HITS_MAX_DURATION_DESC',
  PageHitsMaxNthVisitAsc = 'PAGE_HITS_MAX_NTH_VISIT_ASC',
  PageHitsMaxNthVisitDesc = 'PAGE_HITS_MAX_NTH_VISIT_DESC',
  PageHitsMaxPathAsc = 'PAGE_HITS_MAX_PATH_ASC',
  PageHitsMaxPathDesc = 'PAGE_HITS_MAX_PATH_DESC',
  PageHitsMaxQueryParametersAsc = 'PAGE_HITS_MAX_QUERY_PARAMETERS_ASC',
  PageHitsMaxQueryParametersDesc = 'PAGE_HITS_MAX_QUERY_PARAMETERS_DESC',
  PageHitsMaxRouteAsc = 'PAGE_HITS_MAX_ROUTE_ASC',
  PageHitsMaxRouteDesc = 'PAGE_HITS_MAX_ROUTE_DESC',
  PageHitsMaxTrackedAtAsc = 'PAGE_HITS_MAX_TRACKED_AT_ASC',
  PageHitsMaxTrackedAtDesc = 'PAGE_HITS_MAX_TRACKED_AT_DESC',
  PageHitsMaxTrialIdAsc = 'PAGE_HITS_MAX_TRIAL_ID_ASC',
  PageHitsMaxTrialIdDesc = 'PAGE_HITS_MAX_TRIAL_ID_DESC',
  PageHitsMinDurationAsc = 'PAGE_HITS_MIN_DURATION_ASC',
  PageHitsMinDurationDesc = 'PAGE_HITS_MIN_DURATION_DESC',
  PageHitsMinNthVisitAsc = 'PAGE_HITS_MIN_NTH_VISIT_ASC',
  PageHitsMinNthVisitDesc = 'PAGE_HITS_MIN_NTH_VISIT_DESC',
  PageHitsMinPathAsc = 'PAGE_HITS_MIN_PATH_ASC',
  PageHitsMinPathDesc = 'PAGE_HITS_MIN_PATH_DESC',
  PageHitsMinQueryParametersAsc = 'PAGE_HITS_MIN_QUERY_PARAMETERS_ASC',
  PageHitsMinQueryParametersDesc = 'PAGE_HITS_MIN_QUERY_PARAMETERS_DESC',
  PageHitsMinRouteAsc = 'PAGE_HITS_MIN_ROUTE_ASC',
  PageHitsMinRouteDesc = 'PAGE_HITS_MIN_ROUTE_DESC',
  PageHitsMinTrackedAtAsc = 'PAGE_HITS_MIN_TRACKED_AT_ASC',
  PageHitsMinTrackedAtDesc = 'PAGE_HITS_MIN_TRACKED_AT_DESC',
  PageHitsMinTrialIdAsc = 'PAGE_HITS_MIN_TRIAL_ID_ASC',
  PageHitsMinTrialIdDesc = 'PAGE_HITS_MIN_TRIAL_ID_DESC',
  PageHitsStddevPopulationDurationAsc = 'PAGE_HITS_STDDEV_POPULATION_DURATION_ASC',
  PageHitsStddevPopulationDurationDesc = 'PAGE_HITS_STDDEV_POPULATION_DURATION_DESC',
  PageHitsStddevPopulationNthVisitAsc = 'PAGE_HITS_STDDEV_POPULATION_NTH_VISIT_ASC',
  PageHitsStddevPopulationNthVisitDesc = 'PAGE_HITS_STDDEV_POPULATION_NTH_VISIT_DESC',
  PageHitsStddevPopulationPathAsc = 'PAGE_HITS_STDDEV_POPULATION_PATH_ASC',
  PageHitsStddevPopulationPathDesc = 'PAGE_HITS_STDDEV_POPULATION_PATH_DESC',
  PageHitsStddevPopulationQueryParametersAsc = 'PAGE_HITS_STDDEV_POPULATION_QUERY_PARAMETERS_ASC',
  PageHitsStddevPopulationQueryParametersDesc = 'PAGE_HITS_STDDEV_POPULATION_QUERY_PARAMETERS_DESC',
  PageHitsStddevPopulationRouteAsc = 'PAGE_HITS_STDDEV_POPULATION_ROUTE_ASC',
  PageHitsStddevPopulationRouteDesc = 'PAGE_HITS_STDDEV_POPULATION_ROUTE_DESC',
  PageHitsStddevPopulationTrackedAtAsc = 'PAGE_HITS_STDDEV_POPULATION_TRACKED_AT_ASC',
  PageHitsStddevPopulationTrackedAtDesc = 'PAGE_HITS_STDDEV_POPULATION_TRACKED_AT_DESC',
  PageHitsStddevPopulationTrialIdAsc = 'PAGE_HITS_STDDEV_POPULATION_TRIAL_ID_ASC',
  PageHitsStddevPopulationTrialIdDesc = 'PAGE_HITS_STDDEV_POPULATION_TRIAL_ID_DESC',
  PageHitsStddevSampleDurationAsc = 'PAGE_HITS_STDDEV_SAMPLE_DURATION_ASC',
  PageHitsStddevSampleDurationDesc = 'PAGE_HITS_STDDEV_SAMPLE_DURATION_DESC',
  PageHitsStddevSampleNthVisitAsc = 'PAGE_HITS_STDDEV_SAMPLE_NTH_VISIT_ASC',
  PageHitsStddevSampleNthVisitDesc = 'PAGE_HITS_STDDEV_SAMPLE_NTH_VISIT_DESC',
  PageHitsStddevSamplePathAsc = 'PAGE_HITS_STDDEV_SAMPLE_PATH_ASC',
  PageHitsStddevSamplePathDesc = 'PAGE_HITS_STDDEV_SAMPLE_PATH_DESC',
  PageHitsStddevSampleQueryParametersAsc = 'PAGE_HITS_STDDEV_SAMPLE_QUERY_PARAMETERS_ASC',
  PageHitsStddevSampleQueryParametersDesc = 'PAGE_HITS_STDDEV_SAMPLE_QUERY_PARAMETERS_DESC',
  PageHitsStddevSampleRouteAsc = 'PAGE_HITS_STDDEV_SAMPLE_ROUTE_ASC',
  PageHitsStddevSampleRouteDesc = 'PAGE_HITS_STDDEV_SAMPLE_ROUTE_DESC',
  PageHitsStddevSampleTrackedAtAsc = 'PAGE_HITS_STDDEV_SAMPLE_TRACKED_AT_ASC',
  PageHitsStddevSampleTrackedAtDesc = 'PAGE_HITS_STDDEV_SAMPLE_TRACKED_AT_DESC',
  PageHitsStddevSampleTrialIdAsc = 'PAGE_HITS_STDDEV_SAMPLE_TRIAL_ID_ASC',
  PageHitsStddevSampleTrialIdDesc = 'PAGE_HITS_STDDEV_SAMPLE_TRIAL_ID_DESC',
  PageHitsSumDurationAsc = 'PAGE_HITS_SUM_DURATION_ASC',
  PageHitsSumDurationDesc = 'PAGE_HITS_SUM_DURATION_DESC',
  PageHitsSumNthVisitAsc = 'PAGE_HITS_SUM_NTH_VISIT_ASC',
  PageHitsSumNthVisitDesc = 'PAGE_HITS_SUM_NTH_VISIT_DESC',
  PageHitsSumPathAsc = 'PAGE_HITS_SUM_PATH_ASC',
  PageHitsSumPathDesc = 'PAGE_HITS_SUM_PATH_DESC',
  PageHitsSumQueryParametersAsc = 'PAGE_HITS_SUM_QUERY_PARAMETERS_ASC',
  PageHitsSumQueryParametersDesc = 'PAGE_HITS_SUM_QUERY_PARAMETERS_DESC',
  PageHitsSumRouteAsc = 'PAGE_HITS_SUM_ROUTE_ASC',
  PageHitsSumRouteDesc = 'PAGE_HITS_SUM_ROUTE_DESC',
  PageHitsSumTrackedAtAsc = 'PAGE_HITS_SUM_TRACKED_AT_ASC',
  PageHitsSumTrackedAtDesc = 'PAGE_HITS_SUM_TRACKED_AT_DESC',
  PageHitsSumTrialIdAsc = 'PAGE_HITS_SUM_TRIAL_ID_ASC',
  PageHitsSumTrialIdDesc = 'PAGE_HITS_SUM_TRIAL_ID_DESC',
  PageHitsVariancePopulationDurationAsc = 'PAGE_HITS_VARIANCE_POPULATION_DURATION_ASC',
  PageHitsVariancePopulationDurationDesc = 'PAGE_HITS_VARIANCE_POPULATION_DURATION_DESC',
  PageHitsVariancePopulationNthVisitAsc = 'PAGE_HITS_VARIANCE_POPULATION_NTH_VISIT_ASC',
  PageHitsVariancePopulationNthVisitDesc = 'PAGE_HITS_VARIANCE_POPULATION_NTH_VISIT_DESC',
  PageHitsVariancePopulationPathAsc = 'PAGE_HITS_VARIANCE_POPULATION_PATH_ASC',
  PageHitsVariancePopulationPathDesc = 'PAGE_HITS_VARIANCE_POPULATION_PATH_DESC',
  PageHitsVariancePopulationQueryParametersAsc = 'PAGE_HITS_VARIANCE_POPULATION_QUERY_PARAMETERS_ASC',
  PageHitsVariancePopulationQueryParametersDesc = 'PAGE_HITS_VARIANCE_POPULATION_QUERY_PARAMETERS_DESC',
  PageHitsVariancePopulationRouteAsc = 'PAGE_HITS_VARIANCE_POPULATION_ROUTE_ASC',
  PageHitsVariancePopulationRouteDesc = 'PAGE_HITS_VARIANCE_POPULATION_ROUTE_DESC',
  PageHitsVariancePopulationTrackedAtAsc = 'PAGE_HITS_VARIANCE_POPULATION_TRACKED_AT_ASC',
  PageHitsVariancePopulationTrackedAtDesc = 'PAGE_HITS_VARIANCE_POPULATION_TRACKED_AT_DESC',
  PageHitsVariancePopulationTrialIdAsc = 'PAGE_HITS_VARIANCE_POPULATION_TRIAL_ID_ASC',
  PageHitsVariancePopulationTrialIdDesc = 'PAGE_HITS_VARIANCE_POPULATION_TRIAL_ID_DESC',
  PageHitsVarianceSampleDurationAsc = 'PAGE_HITS_VARIANCE_SAMPLE_DURATION_ASC',
  PageHitsVarianceSampleDurationDesc = 'PAGE_HITS_VARIANCE_SAMPLE_DURATION_DESC',
  PageHitsVarianceSampleNthVisitAsc = 'PAGE_HITS_VARIANCE_SAMPLE_NTH_VISIT_ASC',
  PageHitsVarianceSampleNthVisitDesc = 'PAGE_HITS_VARIANCE_SAMPLE_NTH_VISIT_DESC',
  PageHitsVarianceSamplePathAsc = 'PAGE_HITS_VARIANCE_SAMPLE_PATH_ASC',
  PageHitsVarianceSamplePathDesc = 'PAGE_HITS_VARIANCE_SAMPLE_PATH_DESC',
  PageHitsVarianceSampleQueryParametersAsc = 'PAGE_HITS_VARIANCE_SAMPLE_QUERY_PARAMETERS_ASC',
  PageHitsVarianceSampleQueryParametersDesc = 'PAGE_HITS_VARIANCE_SAMPLE_QUERY_PARAMETERS_DESC',
  PageHitsVarianceSampleRouteAsc = 'PAGE_HITS_VARIANCE_SAMPLE_ROUTE_ASC',
  PageHitsVarianceSampleRouteDesc = 'PAGE_HITS_VARIANCE_SAMPLE_ROUTE_DESC',
  PageHitsVarianceSampleTrackedAtAsc = 'PAGE_HITS_VARIANCE_SAMPLE_TRACKED_AT_ASC',
  PageHitsVarianceSampleTrackedAtDesc = 'PAGE_HITS_VARIANCE_SAMPLE_TRACKED_AT_DESC',
  PageHitsVarianceSampleTrialIdAsc = 'PAGE_HITS_VARIANCE_SAMPLE_TRIAL_ID_ASC',
  PageHitsVarianceSampleTrialIdDesc = 'PAGE_HITS_VARIANCE_SAMPLE_TRIAL_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RawUsageDataAverageClientHashAsc = 'RAW_USAGE_DATA_AVERAGE_CLIENT_HASH_ASC',
  RawUsageDataAverageClientHashDesc = 'RAW_USAGE_DATA_AVERAGE_CLIENT_HASH_DESC',
  RawUsageDataAverageIdAsc = 'RAW_USAGE_DATA_AVERAGE_ID_ASC',
  RawUsageDataAverageIdDesc = 'RAW_USAGE_DATA_AVERAGE_ID_DESC',
  RawUsageDataAveragePayloadAsc = 'RAW_USAGE_DATA_AVERAGE_PAYLOAD_ASC',
  RawUsageDataAveragePayloadDesc = 'RAW_USAGE_DATA_AVERAGE_PAYLOAD_DESC',
  RawUsageDataAverageRemoteAddressAsc = 'RAW_USAGE_DATA_AVERAGE_REMOTE_ADDRESS_ASC',
  RawUsageDataAverageRemoteAddressDesc = 'RAW_USAGE_DATA_AVERAGE_REMOTE_ADDRESS_DESC',
  RawUsageDataAverageTrackedAtAsc = 'RAW_USAGE_DATA_AVERAGE_TRACKED_AT_ASC',
  RawUsageDataAverageTrackedAtDesc = 'RAW_USAGE_DATA_AVERAGE_TRACKED_AT_DESC',
  RawUsageDataAverageTrialIdAsc = 'RAW_USAGE_DATA_AVERAGE_TRIAL_ID_ASC',
  RawUsageDataAverageTrialIdDesc = 'RAW_USAGE_DATA_AVERAGE_TRIAL_ID_DESC',
  RawUsageDataAverageTypeAsc = 'RAW_USAGE_DATA_AVERAGE_TYPE_ASC',
  RawUsageDataAverageTypeDesc = 'RAW_USAGE_DATA_AVERAGE_TYPE_DESC',
  RawUsageDataAverageUserAgentAsc = 'RAW_USAGE_DATA_AVERAGE_USER_AGENT_ASC',
  RawUsageDataAverageUserAgentDesc = 'RAW_USAGE_DATA_AVERAGE_USER_AGENT_DESC',
  RawUsageDataAverageUserIdAsc = 'RAW_USAGE_DATA_AVERAGE_USER_ID_ASC',
  RawUsageDataAverageUserIdDesc = 'RAW_USAGE_DATA_AVERAGE_USER_ID_DESC',
  RawUsageDataCountAsc = 'RAW_USAGE_DATA_COUNT_ASC',
  RawUsageDataCountDesc = 'RAW_USAGE_DATA_COUNT_DESC',
  RawUsageDataDistinctCountClientHashAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_CLIENT_HASH_ASC',
  RawUsageDataDistinctCountClientHashDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_CLIENT_HASH_DESC',
  RawUsageDataDistinctCountIdAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_ID_ASC',
  RawUsageDataDistinctCountIdDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_ID_DESC',
  RawUsageDataDistinctCountPayloadAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_PAYLOAD_ASC',
  RawUsageDataDistinctCountPayloadDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_PAYLOAD_DESC',
  RawUsageDataDistinctCountRemoteAddressAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_REMOTE_ADDRESS_ASC',
  RawUsageDataDistinctCountRemoteAddressDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_REMOTE_ADDRESS_DESC',
  RawUsageDataDistinctCountTrackedAtAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TRACKED_AT_ASC',
  RawUsageDataDistinctCountTrackedAtDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TRACKED_AT_DESC',
  RawUsageDataDistinctCountTrialIdAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TRIAL_ID_ASC',
  RawUsageDataDistinctCountTrialIdDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TRIAL_ID_DESC',
  RawUsageDataDistinctCountTypeAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TYPE_ASC',
  RawUsageDataDistinctCountTypeDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TYPE_DESC',
  RawUsageDataDistinctCountUserAgentAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_USER_AGENT_ASC',
  RawUsageDataDistinctCountUserAgentDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_USER_AGENT_DESC',
  RawUsageDataDistinctCountUserIdAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_USER_ID_ASC',
  RawUsageDataDistinctCountUserIdDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_USER_ID_DESC',
  RawUsageDataMaxClientHashAsc = 'RAW_USAGE_DATA_MAX_CLIENT_HASH_ASC',
  RawUsageDataMaxClientHashDesc = 'RAW_USAGE_DATA_MAX_CLIENT_HASH_DESC',
  RawUsageDataMaxIdAsc = 'RAW_USAGE_DATA_MAX_ID_ASC',
  RawUsageDataMaxIdDesc = 'RAW_USAGE_DATA_MAX_ID_DESC',
  RawUsageDataMaxPayloadAsc = 'RAW_USAGE_DATA_MAX_PAYLOAD_ASC',
  RawUsageDataMaxPayloadDesc = 'RAW_USAGE_DATA_MAX_PAYLOAD_DESC',
  RawUsageDataMaxRemoteAddressAsc = 'RAW_USAGE_DATA_MAX_REMOTE_ADDRESS_ASC',
  RawUsageDataMaxRemoteAddressDesc = 'RAW_USAGE_DATA_MAX_REMOTE_ADDRESS_DESC',
  RawUsageDataMaxTrackedAtAsc = 'RAW_USAGE_DATA_MAX_TRACKED_AT_ASC',
  RawUsageDataMaxTrackedAtDesc = 'RAW_USAGE_DATA_MAX_TRACKED_AT_DESC',
  RawUsageDataMaxTrialIdAsc = 'RAW_USAGE_DATA_MAX_TRIAL_ID_ASC',
  RawUsageDataMaxTrialIdDesc = 'RAW_USAGE_DATA_MAX_TRIAL_ID_DESC',
  RawUsageDataMaxTypeAsc = 'RAW_USAGE_DATA_MAX_TYPE_ASC',
  RawUsageDataMaxTypeDesc = 'RAW_USAGE_DATA_MAX_TYPE_DESC',
  RawUsageDataMaxUserAgentAsc = 'RAW_USAGE_DATA_MAX_USER_AGENT_ASC',
  RawUsageDataMaxUserAgentDesc = 'RAW_USAGE_DATA_MAX_USER_AGENT_DESC',
  RawUsageDataMaxUserIdAsc = 'RAW_USAGE_DATA_MAX_USER_ID_ASC',
  RawUsageDataMaxUserIdDesc = 'RAW_USAGE_DATA_MAX_USER_ID_DESC',
  RawUsageDataMinClientHashAsc = 'RAW_USAGE_DATA_MIN_CLIENT_HASH_ASC',
  RawUsageDataMinClientHashDesc = 'RAW_USAGE_DATA_MIN_CLIENT_HASH_DESC',
  RawUsageDataMinIdAsc = 'RAW_USAGE_DATA_MIN_ID_ASC',
  RawUsageDataMinIdDesc = 'RAW_USAGE_DATA_MIN_ID_DESC',
  RawUsageDataMinPayloadAsc = 'RAW_USAGE_DATA_MIN_PAYLOAD_ASC',
  RawUsageDataMinPayloadDesc = 'RAW_USAGE_DATA_MIN_PAYLOAD_DESC',
  RawUsageDataMinRemoteAddressAsc = 'RAW_USAGE_DATA_MIN_REMOTE_ADDRESS_ASC',
  RawUsageDataMinRemoteAddressDesc = 'RAW_USAGE_DATA_MIN_REMOTE_ADDRESS_DESC',
  RawUsageDataMinTrackedAtAsc = 'RAW_USAGE_DATA_MIN_TRACKED_AT_ASC',
  RawUsageDataMinTrackedAtDesc = 'RAW_USAGE_DATA_MIN_TRACKED_AT_DESC',
  RawUsageDataMinTrialIdAsc = 'RAW_USAGE_DATA_MIN_TRIAL_ID_ASC',
  RawUsageDataMinTrialIdDesc = 'RAW_USAGE_DATA_MIN_TRIAL_ID_DESC',
  RawUsageDataMinTypeAsc = 'RAW_USAGE_DATA_MIN_TYPE_ASC',
  RawUsageDataMinTypeDesc = 'RAW_USAGE_DATA_MIN_TYPE_DESC',
  RawUsageDataMinUserAgentAsc = 'RAW_USAGE_DATA_MIN_USER_AGENT_ASC',
  RawUsageDataMinUserAgentDesc = 'RAW_USAGE_DATA_MIN_USER_AGENT_DESC',
  RawUsageDataMinUserIdAsc = 'RAW_USAGE_DATA_MIN_USER_ID_ASC',
  RawUsageDataMinUserIdDesc = 'RAW_USAGE_DATA_MIN_USER_ID_DESC',
  RawUsageDataStddevPopulationClientHashAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_CLIENT_HASH_ASC',
  RawUsageDataStddevPopulationClientHashDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_CLIENT_HASH_DESC',
  RawUsageDataStddevPopulationIdAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_ID_ASC',
  RawUsageDataStddevPopulationIdDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_ID_DESC',
  RawUsageDataStddevPopulationPayloadAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_PAYLOAD_ASC',
  RawUsageDataStddevPopulationPayloadDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_PAYLOAD_DESC',
  RawUsageDataStddevPopulationRemoteAddressAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_REMOTE_ADDRESS_ASC',
  RawUsageDataStddevPopulationRemoteAddressDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_REMOTE_ADDRESS_DESC',
  RawUsageDataStddevPopulationTrackedAtAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TRACKED_AT_ASC',
  RawUsageDataStddevPopulationTrackedAtDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TRACKED_AT_DESC',
  RawUsageDataStddevPopulationTrialIdAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TRIAL_ID_ASC',
  RawUsageDataStddevPopulationTrialIdDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TRIAL_ID_DESC',
  RawUsageDataStddevPopulationTypeAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TYPE_ASC',
  RawUsageDataStddevPopulationTypeDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TYPE_DESC',
  RawUsageDataStddevPopulationUserAgentAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_USER_AGENT_ASC',
  RawUsageDataStddevPopulationUserAgentDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_USER_AGENT_DESC',
  RawUsageDataStddevPopulationUserIdAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_USER_ID_ASC',
  RawUsageDataStddevPopulationUserIdDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_USER_ID_DESC',
  RawUsageDataStddevSampleClientHashAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_CLIENT_HASH_ASC',
  RawUsageDataStddevSampleClientHashDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_CLIENT_HASH_DESC',
  RawUsageDataStddevSampleIdAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_ID_ASC',
  RawUsageDataStddevSampleIdDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_ID_DESC',
  RawUsageDataStddevSamplePayloadAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_PAYLOAD_ASC',
  RawUsageDataStddevSamplePayloadDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_PAYLOAD_DESC',
  RawUsageDataStddevSampleRemoteAddressAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_REMOTE_ADDRESS_ASC',
  RawUsageDataStddevSampleRemoteAddressDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_REMOTE_ADDRESS_DESC',
  RawUsageDataStddevSampleTrackedAtAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TRACKED_AT_ASC',
  RawUsageDataStddevSampleTrackedAtDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TRACKED_AT_DESC',
  RawUsageDataStddevSampleTrialIdAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TRIAL_ID_ASC',
  RawUsageDataStddevSampleTrialIdDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TRIAL_ID_DESC',
  RawUsageDataStddevSampleTypeAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TYPE_ASC',
  RawUsageDataStddevSampleTypeDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TYPE_DESC',
  RawUsageDataStddevSampleUserAgentAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_USER_AGENT_ASC',
  RawUsageDataStddevSampleUserAgentDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_USER_AGENT_DESC',
  RawUsageDataStddevSampleUserIdAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_USER_ID_ASC',
  RawUsageDataStddevSampleUserIdDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_USER_ID_DESC',
  RawUsageDataSumClientHashAsc = 'RAW_USAGE_DATA_SUM_CLIENT_HASH_ASC',
  RawUsageDataSumClientHashDesc = 'RAW_USAGE_DATA_SUM_CLIENT_HASH_DESC',
  RawUsageDataSumIdAsc = 'RAW_USAGE_DATA_SUM_ID_ASC',
  RawUsageDataSumIdDesc = 'RAW_USAGE_DATA_SUM_ID_DESC',
  RawUsageDataSumPayloadAsc = 'RAW_USAGE_DATA_SUM_PAYLOAD_ASC',
  RawUsageDataSumPayloadDesc = 'RAW_USAGE_DATA_SUM_PAYLOAD_DESC',
  RawUsageDataSumRemoteAddressAsc = 'RAW_USAGE_DATA_SUM_REMOTE_ADDRESS_ASC',
  RawUsageDataSumRemoteAddressDesc = 'RAW_USAGE_DATA_SUM_REMOTE_ADDRESS_DESC',
  RawUsageDataSumTrackedAtAsc = 'RAW_USAGE_DATA_SUM_TRACKED_AT_ASC',
  RawUsageDataSumTrackedAtDesc = 'RAW_USAGE_DATA_SUM_TRACKED_AT_DESC',
  RawUsageDataSumTrialIdAsc = 'RAW_USAGE_DATA_SUM_TRIAL_ID_ASC',
  RawUsageDataSumTrialIdDesc = 'RAW_USAGE_DATA_SUM_TRIAL_ID_DESC',
  RawUsageDataSumTypeAsc = 'RAW_USAGE_DATA_SUM_TYPE_ASC',
  RawUsageDataSumTypeDesc = 'RAW_USAGE_DATA_SUM_TYPE_DESC',
  RawUsageDataSumUserAgentAsc = 'RAW_USAGE_DATA_SUM_USER_AGENT_ASC',
  RawUsageDataSumUserAgentDesc = 'RAW_USAGE_DATA_SUM_USER_AGENT_DESC',
  RawUsageDataSumUserIdAsc = 'RAW_USAGE_DATA_SUM_USER_ID_ASC',
  RawUsageDataSumUserIdDesc = 'RAW_USAGE_DATA_SUM_USER_ID_DESC',
  RawUsageDataVariancePopulationClientHashAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_CLIENT_HASH_ASC',
  RawUsageDataVariancePopulationClientHashDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_CLIENT_HASH_DESC',
  RawUsageDataVariancePopulationIdAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_ID_ASC',
  RawUsageDataVariancePopulationIdDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_ID_DESC',
  RawUsageDataVariancePopulationPayloadAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_PAYLOAD_ASC',
  RawUsageDataVariancePopulationPayloadDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_PAYLOAD_DESC',
  RawUsageDataVariancePopulationRemoteAddressAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_REMOTE_ADDRESS_ASC',
  RawUsageDataVariancePopulationRemoteAddressDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_REMOTE_ADDRESS_DESC',
  RawUsageDataVariancePopulationTrackedAtAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TRACKED_AT_ASC',
  RawUsageDataVariancePopulationTrackedAtDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TRACKED_AT_DESC',
  RawUsageDataVariancePopulationTrialIdAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TRIAL_ID_ASC',
  RawUsageDataVariancePopulationTrialIdDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TRIAL_ID_DESC',
  RawUsageDataVariancePopulationTypeAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TYPE_ASC',
  RawUsageDataVariancePopulationTypeDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TYPE_DESC',
  RawUsageDataVariancePopulationUserAgentAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_USER_AGENT_ASC',
  RawUsageDataVariancePopulationUserAgentDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_USER_AGENT_DESC',
  RawUsageDataVariancePopulationUserIdAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_USER_ID_ASC',
  RawUsageDataVariancePopulationUserIdDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_USER_ID_DESC',
  RawUsageDataVarianceSampleClientHashAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_CLIENT_HASH_ASC',
  RawUsageDataVarianceSampleClientHashDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_CLIENT_HASH_DESC',
  RawUsageDataVarianceSampleIdAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_ID_ASC',
  RawUsageDataVarianceSampleIdDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_ID_DESC',
  RawUsageDataVarianceSamplePayloadAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_PAYLOAD_ASC',
  RawUsageDataVarianceSamplePayloadDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_PAYLOAD_DESC',
  RawUsageDataVarianceSampleRemoteAddressAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_REMOTE_ADDRESS_ASC',
  RawUsageDataVarianceSampleRemoteAddressDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_REMOTE_ADDRESS_DESC',
  RawUsageDataVarianceSampleTrackedAtAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TRACKED_AT_ASC',
  RawUsageDataVarianceSampleTrackedAtDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TRACKED_AT_DESC',
  RawUsageDataVarianceSampleTrialIdAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TRIAL_ID_ASC',
  RawUsageDataVarianceSampleTrialIdDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TRIAL_ID_DESC',
  RawUsageDataVarianceSampleTypeAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TYPE_ASC',
  RawUsageDataVarianceSampleTypeDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TYPE_DESC',
  RawUsageDataVarianceSampleUserAgentAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_USER_AGENT_ASC',
  RawUsageDataVarianceSampleUserAgentDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_USER_AGENT_DESC',
  RawUsageDataVarianceSampleUserIdAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_USER_ID_ASC',
  RawUsageDataVarianceSampleUserIdDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_USER_ID_DESC',
  StaffIdAsc = 'STAFF_ID_ASC',
  StaffIdDesc = 'STAFF_ID_DESC',
  SubjectsNicknameAsc = 'SUBJECTS_NICKNAME_ASC',
  SubjectsNicknameDesc = 'SUBJECTS_NICKNAME_DESC',
  SubjectIdAsc = 'SUBJECT_ID_ASC',
  SubjectIdDesc = 'SUBJECT_ID_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

/** All input for the `updateCommentableByNodeId` mutation. */
export type UpdateCommentableByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Commentable` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Commentable` being updated. */
  patch: CommentablePatch;
};

/** All input for the `updateCommentable` mutation. */
export type UpdateCommentableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Commentable` being updated. */
  patch: CommentablePatch;
};

/** The output of our update `Commentable` mutation. */
export type UpdateCommentablePayload = {
  __typename?: 'UpdateCommentablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Commentable` that was updated by this mutation. */
  commentable?: Maybe<Commentable>;
  /** An edge for our `Commentable`. May be used by Relay 1. */
  commentableEdge?: Maybe<CommentablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `Commentable` mutation. */
export type UpdateCommentablePayloadCommentableEdgeArgs = {
  orderBy?: InputMaybe<Array<CommentablesOrderBy>>;
};

/** All input for the `updateConversationByNodeId` mutation. */
export type UpdateConversationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Conversation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Conversation` being updated. */
  patch: ConversationPatch;
};

/** All input for the `updateConversation` mutation. */
export type UpdateConversationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Conversation` being updated. */
  patch: ConversationPatch;
};

/** The output of our update `Conversation` mutation. */
export type UpdateConversationPayload = {
  __typename?: 'UpdateConversationPayload';
  /** Reads a single `Commentable` that is related to this `Conversation`. */
  aboutCommentable?: Maybe<Commentable>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Conversation` that was updated by this mutation. */
  conversation?: Maybe<Conversation>;
  /** An edge for our `Conversation`. May be used by Relay 1. */
  conversationEdge?: Maybe<ConversationsEdge>;
  /** Reads a single `User` that is related to this `Conversation`. */
  creator?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Conversation`. */
  privateGroup?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Conversation`. */
  tenant?: Maybe<Tenant>;
};


/** The output of our update `Conversation` mutation. */
export type UpdateConversationPayloadConversationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};

/** All input for the `updateEmaEntries2ByNodeId` mutation. */
export type UpdateEmaEntries2ByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmaEntries2` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EmaEntries2` being updated. */
  patch: EmaEntries2Patch;
};

/** All input for the `updateEmaEntries2` mutation. */
export type UpdateEmaEntries2Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `EmaEntries2` being updated. */
  patch: EmaEntries2Patch;
};

/** The output of our update `EmaEntries2` mutation. */
export type UpdateEmaEntries2Payload = {
  __typename?: 'UpdateEmaEntries2Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntries2` that was updated by this mutation. */
  emaEntries2?: Maybe<EmaEntries2>;
  /** An edge for our `EmaEntries2`. May be used by Relay 1. */
  emaEntries2Edge?: Maybe<EmaEntries2sEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntries2`. */
  subject?: Maybe<User>;
};


/** The output of our update `EmaEntries2` mutation. */
export type UpdateEmaEntries2PayloadEmaEntries2EdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntries2sOrderBy>>;
};

/** All input for the `updateEmaEntries3ByNodeId` mutation. */
export type UpdateEmaEntries3ByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmaEntries3` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EmaEntries3` being updated. */
  patch: EmaEntries3Patch;
};

/** All input for the `updateEmaEntries3` mutation. */
export type UpdateEmaEntries3Input = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `EmaEntries3` being updated. */
  patch: EmaEntries3Patch;
};

/** The output of our update `EmaEntries3` mutation. */
export type UpdateEmaEntries3Payload = {
  __typename?: 'UpdateEmaEntries3Payload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntries3` that was updated by this mutation. */
  emaEntries3?: Maybe<EmaEntries3>;
  /** An edge for our `EmaEntries3`. May be used by Relay 1. */
  emaEntries3Edge?: Maybe<EmaEntries3sEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `EmaEntries3`. */
  trial?: Maybe<Trial>;
};


/** The output of our update `EmaEntries3` mutation. */
export type UpdateEmaEntries3PayloadEmaEntries3EdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntries3sOrderBy>>;
};

/** All input for the `updateEmaEntryByNodeId` mutation. */
export type UpdateEmaEntryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EmaEntry` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EmaEntry` being updated. */
  patch: EmaEntryPatch;
};

/** All input for the `updateEmaEntry` mutation. */
export type UpdateEmaEntryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `EmaEntry` being updated. */
  patch: EmaEntryPatch;
};

/** The output of our update `EmaEntry` mutation. */
export type UpdateEmaEntryPayload = {
  __typename?: 'UpdateEmaEntryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntry` that was updated by this mutation. */
  emaEntry?: Maybe<EmaEntry>;
  /** An edge for our `EmaEntry`. May be used by Relay 1. */
  emaEntryEdge?: Maybe<EmaEntriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntry`. */
  subject?: Maybe<User>;
};


/** The output of our update `EmaEntry` mutation. */
export type UpdateEmaEntryPayloadEmaEntryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntriesOrderBy>>;
};

/** All input for the `updateFavoritePageByNodeId` mutation. */
export type UpdateFavoritePageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FavoritePage` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FavoritePage` being updated. */
  patch: FavoritePagePatch;
};

/** All input for the `updateFavoritePage` mutation. */
export type UpdateFavoritePageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `FavoritePage` being updated. */
  patch: FavoritePagePatch;
  route: Scalars['String'];
  trialId: Scalars['UUID'];
};

/** The output of our update `FavoritePage` mutation. */
export type UpdateFavoritePagePayload = {
  __typename?: 'UpdateFavoritePagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FavoritePage` that was updated by this mutation. */
  favoritePage?: Maybe<FavoritePage>;
  /** An edge for our `FavoritePage`. May be used by Relay 1. */
  favoritePageEdge?: Maybe<FavoritePagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `FavoritePage`. */
  trial?: Maybe<Trial>;
};


/** The output of our update `FavoritePage` mutation. */
export type UpdateFavoritePagePayloadFavoritePageEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritePagesOrderBy>>;
};

/** All input for the `updateGenericEmiExerciseByNodeId` mutation. */
export type UpdateGenericEmiExerciseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GenericEmiExercise` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GenericEmiExercise` being updated. */
  patch: GenericEmiExercisePatch;
};

/** All input for the `updateGenericEmiExerciseByTrialIdAndName` mutation. */
export type UpdateGenericEmiExerciseByTrialIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `GenericEmiExercise` being updated. */
  patch: GenericEmiExercisePatch;
  trialId: Scalars['UUID'];
};

/** All input for the `updateGenericEmiExercise` mutation. */
export type UpdateGenericEmiExerciseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `GenericEmiExercise` being updated. */
  patch: GenericEmiExercisePatch;
};

/** The output of our update `GenericEmiExercise` mutation. */
export type UpdateGenericEmiExercisePayload = {
  __typename?: 'UpdateGenericEmiExercisePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GenericEmiExercise` that was updated by this mutation. */
  genericEmiExercise?: Maybe<GenericEmiExercise>;
  /** An edge for our `GenericEmiExercise`. May be used by Relay 1. */
  genericEmiExerciseEdge?: Maybe<GenericEmiExercisesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `GenericEmiExercise`. */
  trial?: Maybe<Trial>;
};


/** The output of our update `GenericEmiExercise` mutation. */
export type UpdateGenericEmiExercisePayloadGenericEmiExerciseEdgeArgs = {
  orderBy?: InputMaybe<Array<GenericEmiExercisesOrderBy>>;
};

/** All input for the `updateGroupByNodeId` mutation. */
export type UpdateGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Group` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
};

/** All input for the `updateGroupHomeByNodeId` mutation. */
export type UpdateGroupHomeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GroupHome` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GroupHome` being updated. */
  patch: GroupHomePatch;
};

/** All input for the `updateGroupHome` mutation. */
export type UpdateGroupHomeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `GroupHome` being updated. */
  patch: GroupHomePatch;
};

/** The output of our update `GroupHome` mutation. */
export type UpdateGroupHomePayload = {
  __typename?: 'UpdateGroupHomePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupHome` that was updated by this mutation. */
  groupHome?: Maybe<GroupHome>;
  /** An edge for our `GroupHome`. May be used by Relay 1. */
  groupHomeEdge?: Maybe<GroupHomesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `GroupHome` mutation. */
export type UpdateGroupHomePayloadGroupHomeEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupHomesOrderBy>>;
};

/** All input for the `updateGroup` mutation. */
export type UpdateGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Group` being updated. */
  patch: GroupPatch;
};

/** The output of our update `Group` mutation. */
export type UpdateGroupPayload = {
  __typename?: 'UpdateGroupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Group`. */
  creator?: Maybe<User>;
  /** The `Group` that was updated by this mutation. */
  group?: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
  /** Reads a single `GroupHome` that is related to this `Group`. */
  home?: Maybe<GroupHome>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Group`. */
  tenant?: Maybe<Tenant>;
};


/** The output of our update `Group` mutation. */
export type UpdateGroupPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** All input for the `updateLimesurveyTokenByNodeId` mutation. */
export type UpdateLimesurveyTokenByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `LimesurveyToken` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `LimesurveyToken` being updated. */
  patch: LimesurveyTokenPatch;
};

/** All input for the `updateLimesurveyTokenByTrialIdAndTimeOfMeasurement` mutation. */
export type UpdateLimesurveyTokenByTrialIdAndTimeOfMeasurementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LimesurveyToken` being updated. */
  patch: LimesurveyTokenPatch;
  timeOfMeasurement: Scalars['String'];
  trialId: Scalars['UUID'];
};

/** All input for the `updateLimesurveyToken` mutation. */
export type UpdateLimesurveyTokenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `LimesurveyToken` being updated. */
  patch: LimesurveyTokenPatch;
  surveyId: Scalars['Int'];
  token: Scalars['String'];
};

/** The output of our update `LimesurveyToken` mutation. */
export type UpdateLimesurveyTokenPayload = {
  __typename?: 'UpdateLimesurveyTokenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LimesurveyToken` that was updated by this mutation. */
  limesurveyToken?: Maybe<LimesurveyToken>;
  /** An edge for our `LimesurveyToken`. May be used by Relay 1. */
  limesurveyTokenEdge?: Maybe<LimesurveyTokensEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `LimesurveyToken`. */
  trial?: Maybe<Trial>;
};


/** The output of our update `LimesurveyToken` mutation. */
export type UpdateLimesurveyTokenPayloadLimesurveyTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<LimesurveyTokensOrderBy>>;
};

/** All input for the `updateMembershipByGroupIdAndSubgroupId` mutation. */
export type UpdateMembershipByGroupIdAndSubgroupIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
  subgroupId: Scalars['UUID'];
};

/** All input for the `updateMembershipByGroupIdAndUserId` mutation. */
export type UpdateMembershipByGroupIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  groupId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
  userId: Scalars['UUID'];
};

/** All input for the `updateMembershipByNodeId` mutation. */
export type UpdateMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Membership` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
};

/** All input for the `updateMembership` mutation. */
export type UpdateMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Membership` being updated. */
  patch: MembershipPatch;
};

/** All input for the `updateMembershipInvitationByNodeId` mutation. */
export type UpdateMembershipInvitationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MembershipInvitation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MembershipInvitation` being updated. */
  patch: MembershipInvitationPatch;
};

/** All input for the `updateMembershipInvitationConfirmationByNodeId` mutation. */
export type UpdateMembershipInvitationConfirmationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MembershipInvitationConfirmation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MembershipInvitationConfirmation` being updated. */
  patch: MembershipInvitationConfirmationPatch;
};

/** All input for the `updateMembershipInvitationConfirmation` mutation. */
export type UpdateMembershipInvitationConfirmationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `MembershipInvitationConfirmation` being updated. */
  patch: MembershipInvitationConfirmationPatch;
};

/** The output of our update `MembershipInvitationConfirmation` mutation. */
export type UpdateMembershipInvitationConfirmationPayload = {
  __typename?: 'UpdateMembershipInvitationConfirmationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `MembershipInvitation` that is related to this `MembershipInvitationConfirmation`. */
  invitation?: Maybe<MembershipInvitation>;
  /** Reads a single `Membership` that is related to this `MembershipInvitationConfirmation`. */
  membership?: Maybe<Membership>;
  /** The `MembershipInvitationConfirmation` that was updated by this mutation. */
  membershipInvitationConfirmation?: Maybe<MembershipInvitationConfirmation>;
  /** An edge for our `MembershipInvitationConfirmation`. May be used by Relay 1. */
  membershipInvitationConfirmationEdge?: Maybe<MembershipInvitationConfirmationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `MembershipInvitationConfirmation` mutation. */
export type UpdateMembershipInvitationConfirmationPayloadMembershipInvitationConfirmationEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipInvitationConfirmationsOrderBy>>;
};

/** All input for the `updateMembershipInvitation` mutation. */
export type UpdateMembershipInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `MembershipInvitation` being updated. */
  patch: MembershipInvitationPatch;
};

/** The output of our update `MembershipInvitation` mutation. */
export type UpdateMembershipInvitationPayload = {
  __typename?: 'UpdateMembershipInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  group?: Maybe<Group>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  invitedGroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  invitedUser?: Maybe<User>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  inviter?: Maybe<User>;
  /** The `MembershipInvitation` that was updated by this mutation. */
  membershipInvitation?: Maybe<MembershipInvitation>;
  /** An edge for our `MembershipInvitation`. May be used by Relay 1. */
  membershipInvitationEdge?: Maybe<MembershipInvitationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our update `MembershipInvitation` mutation. */
export type UpdateMembershipInvitationPayloadMembershipInvitationEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};

/** The output of our update `Membership` mutation. */
export type UpdateMembershipPayload = {
  __typename?: 'UpdateMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Group` that is related to this `Membership`. */
  group?: Maybe<Group>;
  /** The `Membership` that was updated by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Membership`. */
  subgroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our update `Membership` mutation. */
export type UpdateMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** All input for the `updatePostWaitRandomizationTicketByNodeId` mutation. */
export type UpdatePostWaitRandomizationTicketByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PostWaitRandomizationTicket` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PostWaitRandomizationTicket` being updated. */
  patch: PostWaitRandomizationTicketPatch;
};

/** All input for the `updatePostWaitRandomizationTicketByTrialId` mutation. */
export type UpdatePostWaitRandomizationTicketByTrialIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PostWaitRandomizationTicket` being updated. */
  patch: PostWaitRandomizationTicketPatch;
  trialId: Scalars['UUID'];
};

/** All input for the `updatePostWaitRandomizationTicket` mutation. */
export type UpdatePostWaitRandomizationTicketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `PostWaitRandomizationTicket` being updated. */
  patch: PostWaitRandomizationTicketPatch;
};

/** The output of our update `PostWaitRandomizationTicket` mutation. */
export type UpdatePostWaitRandomizationTicketPayload = {
  __typename?: 'UpdatePostWaitRandomizationTicketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PostWaitRandomizationTicket` that was updated by this mutation. */
  postWaitRandomizationTicket?: Maybe<PostWaitRandomizationTicket>;
  /** An edge for our `PostWaitRandomizationTicket`. May be used by Relay 1. */
  postWaitRandomizationTicketEdge?: Maybe<PostWaitRandomizationTicketsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `PostWaitRandomizationTicket`. */
  trial?: Maybe<Trial>;
};


/** The output of our update `PostWaitRandomizationTicket` mutation. */
export type UpdatePostWaitRandomizationTicketPayloadPostWaitRandomizationTicketEdgeArgs = {
  orderBy?: InputMaybe<Array<PostWaitRandomizationTicketsOrderBy>>;
};

/** All input for the `updatePostingByNodeId` mutation. */
export type UpdatePostingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Posting` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Posting` being updated. */
  patch: PostingPatch;
};

/** All input for the `updatePosting` mutation. */
export type UpdatePostingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Posting` being updated. */
  patch: PostingPatch;
};

/** The output of our update `Posting` mutation. */
export type UpdatePostingPayload = {
  __typename?: 'UpdatePostingPayload';
  /** Reads a single `Commentable` that is related to this `Posting`. */
  aboutCommentable?: Maybe<Commentable>;
  /** Reads a single `Posting` that is related to this `Posting`. */
  answeredPosting?: Maybe<Posting>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Conversation` that is related to this `Posting`. */
  conversation?: Maybe<Conversation>;
  /** The `Posting` that was updated by this mutation. */
  posting?: Maybe<Posting>;
  /** An edge for our `Posting`. May be used by Relay 1. */
  postingEdge?: Maybe<PostingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Posting`. */
  sender?: Maybe<User>;
};


/** The output of our update `Posting` mutation. */
export type UpdatePostingPayloadPostingEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};

/** All input for the `updatePostingReceiptByNodeId` mutation. */
export type UpdatePostingReceiptByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PostingReceipt` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PostingReceipt` being updated. */
  patch: PostingReceiptPatch;
};

/** All input for the `updatePostingReceipt` mutation. */
export type UpdatePostingReceiptInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PostingReceipt` being updated. */
  patch: PostingReceiptPatch;
  postingId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The output of our update `PostingReceipt` mutation. */
export type UpdatePostingReceiptPayload = {
  __typename?: 'UpdatePostingReceiptPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Posting` that is related to this `PostingReceipt`. */
  posting?: Maybe<Posting>;
  /** The `PostingReceipt` that was updated by this mutation. */
  postingReceipt?: Maybe<PostingReceipt>;
  /** An edge for our `PostingReceipt`. May be used by Relay 1. */
  postingReceiptEdge?: Maybe<PostingReceiptsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostingReceipt`. */
  user?: Maybe<User>;
};


/** The output of our update `PostingReceipt` mutation. */
export type UpdatePostingReceiptPayloadPostingReceiptEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};

/** All input for the `updateRandomizationTicketByNodeId` mutation. */
export type UpdateRandomizationTicketByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RandomizationTicket` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `RandomizationTicket` being updated. */
  patch: RandomizationTicketPatch;
};

/** All input for the `updateRandomizationTicketByTrialId` mutation. */
export type UpdateRandomizationTicketByTrialIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `RandomizationTicket` being updated. */
  patch: RandomizationTicketPatch;
  trialId: Scalars['UUID'];
};

/** All input for the `updateRandomizationTicket` mutation. */
export type UpdateRandomizationTicketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `RandomizationTicket` being updated. */
  patch: RandomizationTicketPatch;
};

/** The output of our update `RandomizationTicket` mutation. */
export type UpdateRandomizationTicketPayload = {
  __typename?: 'UpdateRandomizationTicketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RandomizationTicket` that was updated by this mutation. */
  randomizationTicket?: Maybe<RandomizationTicket>;
  /** An edge for our `RandomizationTicket`. May be used by Relay 1. */
  randomizationTicketEdge?: Maybe<RandomizationTicketsEdge>;
  /** Reads a single `Trial` that is related to this `RandomizationTicket`. */
  trial?: Maybe<Trial>;
};


/** The output of our update `RandomizationTicket` mutation. */
export type UpdateRandomizationTicketPayloadRandomizationTicketEdgeArgs = {
  orderBy?: InputMaybe<Array<RandomizationTicketsOrderBy>>;
};

/** All input for the `updateRawUsageDatumByNodeId` mutation. */
export type UpdateRawUsageDatumByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `RawUsageDatum` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `RawUsageDatum` being updated. */
  patch: RawUsageDatumPatch;
};

/** All input for the `updateRawUsageDatum` mutation. */
export type UpdateRawUsageDatumInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `RawUsageDatum` being updated. */
  patch: RawUsageDatumPatch;
};

/** The output of our update `RawUsageDatum` mutation. */
export type UpdateRawUsageDatumPayload = {
  __typename?: 'UpdateRawUsageDatumPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RawUsageDatum` that was updated by this mutation. */
  rawUsageDatum?: Maybe<RawUsageDatum>;
  /** An edge for our `RawUsageDatum`. May be used by Relay 1. */
  rawUsageDatumEdge?: Maybe<RawUsageDataEdge>;
  /** Reads a single `Trial` that is related to this `RawUsageDatum`. */
  trial?: Maybe<Trial>;
  /** Reads a single `User` that is related to this `RawUsageDatum`. */
  user?: Maybe<User>;
};


/** The output of our update `RawUsageDatum` mutation. */
export type UpdateRawUsageDatumPayloadRawUsageDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<RawUsageDataOrderBy>>;
};

/** All input for the `updateTenantByName` mutation. */
export type UpdateTenantByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `Tenant` being updated. */
  patch: TenantPatch;
};

/** All input for the `updateTenantByNodeId` mutation. */
export type UpdateTenantByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Tenant` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Tenant` being updated. */
  patch: TenantPatch;
};

/** All input for the `updateTenantByUrl` mutation. */
export type UpdateTenantByUrlInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Tenant` being updated. */
  patch: TenantPatch;
  url: Scalars['String'];
};

/** All input for the `updateTenant` mutation. */
export type UpdateTenantInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Tenant` being updated. */
  patch: TenantPatch;
};

/** The output of our update `Tenant` mutation. */
export type UpdateTenantPayload = {
  __typename?: 'UpdateTenantPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Tenant` that was updated by this mutation. */
  tenant?: Maybe<Tenant>;
  /** An edge for our `Tenant`. May be used by Relay 1. */
  tenantEdge?: Maybe<TenantsEdge>;
};


/** The output of our update `Tenant` mutation. */
export type UpdateTenantPayloadTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantsOrderBy>>;
};

/** All input for the `updateTrialByHumanReadableId` mutation. */
export type UpdateTrialByHumanReadableIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  humanReadableId: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `Trial` being updated. */
  patch: TrialPatch;
};

/** All input for the `updateTrialByName` mutation. */
export type UpdateTrialByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `Trial` being updated. */
  patch: TrialPatch;
};

/** All input for the `updateTrialByNodeId` mutation. */
export type UpdateTrialByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Trial` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Trial` being updated. */
  patch: TrialPatch;
};

/** All input for the `updateTrial` mutation. */
export type UpdateTrialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Trial` being updated. */
  patch: TrialPatch;
};

/** The output of our update `Trial` mutation. */
export type UpdateTrialPayload = {
  __typename?: 'UpdateTrialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Commentable` that is related to this `Trial`. */
  commentableById?: Maybe<Commentable>;
  /** Reads a single `User` that is related to this `Trial`. */
  createdBy?: Maybe<User>;
  /** Reads a single `GroupHome` that is related to this `Trial`. */
  groupHomeById?: Maybe<GroupHome>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  internalMessages?: Maybe<Conversation>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  messages?: Maybe<Conversation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Trial`. */
  staff?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Trial`. */
  subject?: Maybe<User>;
  /** Reads a single `Tenant` that is related to this `Trial`. */
  tenant?: Maybe<Tenant>;
  /** The `Trial` that was updated by this mutation. */
  trial?: Maybe<Trial>;
  /** An edge for our `Trial`. May be used by Relay 1. */
  trialEdge?: Maybe<TrialsEdge>;
};


/** The output of our update `Trial` mutation. */
export type UpdateTrialPayloadTrialEdgeArgs = {
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

/** All input for the `updateUserByCredentialsId` mutation. */
export type UpdateUserByCredentialsIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  credentialsId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserByEmailAddressId` mutation. */
export type UpdateUserByEmailAddressIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddressId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserByIdAndTenantId` mutation. */
export type UpdateUserByIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
  tenantId: Scalars['UUID'];
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserByUsernameAndTenantId` mutation. */
export type UpdateUserByUsernameAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
  tenantId: Scalars['UUID'];
  username: Scalars['String'];
};

/** All input for the `updateUserCredentialByExternalAuthIdAndTenantId` mutation. */
export type UpdateUserCredentialByExternalAuthIdAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalAuthId: Scalars['String'];
  /** An object where the defined keys will be set on the `UserCredential` being updated. */
  patch: UserCredentialPatch;
  tenantId: Scalars['UUID'];
};

/** All input for the `updateUserCredentialByNodeId` mutation. */
export type UpdateUserCredentialByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserCredential` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserCredential` being updated. */
  patch: UserCredentialPatch;
};

/** All input for the `updateUserCredential` mutation. */
export type UpdateUserCredentialInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `UserCredential` being updated. */
  patch: UserCredentialPatch;
};

/** The output of our update `UserCredential` mutation. */
export type UpdateUserCredentialPayload = {
  __typename?: 'UpdateUserCredentialPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `UserCredential`. */
  tenant?: Maybe<Tenant>;
  /** The `UserCredential` that was updated by this mutation. */
  userCredential?: Maybe<UserCredential>;
  /** An edge for our `UserCredential`. May be used by Relay 1. */
  userCredentialEdge?: Maybe<UserCredentialsEdge>;
};


/** The output of our update `UserCredential` mutation. */
export type UpdateUserCredentialPayloadUserCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<UserCredentialsOrderBy>>;
};

/** All input for the `updateUserEmailAddressByEmailAddressAndTenantId` mutation. */
export type UpdateUserEmailAddressByEmailAddressAndTenantIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  /** An object where the defined keys will be set on the `UserEmailAddress` being updated. */
  patch: UserEmailAddressPatch;
  tenantId: Scalars['UUID'];
};

/** All input for the `updateUserEmailAddressByNodeId` mutation. */
export type UpdateUserEmailAddressByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserEmailAddress` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserEmailAddress` being updated. */
  patch: UserEmailAddressPatch;
};

/** All input for the `updateUserEmailAddress` mutation. */
export type UpdateUserEmailAddressInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `UserEmailAddress` being updated. */
  patch: UserEmailAddressPatch;
};

/** The output of our update `UserEmailAddress` mutation. */
export type UpdateUserEmailAddressPayload = {
  __typename?: 'UpdateUserEmailAddressPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserEmailAddress` that was updated by this mutation. */
  userEmailAddress?: Maybe<UserEmailAddress>;
  /** An edge for our `UserEmailAddress`. May be used by Relay 1. */
  userEmailAddressEdge?: Maybe<UserEmailAddressesEdge>;
};


/** The output of our update `UserEmailAddress` mutation. */
export type UpdateUserEmailAddressPayloadUserEmailAddressEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmailAddressesOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `UserCredential` that is related to this `User`. */
  credentials?: Maybe<UserCredential>;
  /** Reads a single `UserEmailAddress` that is related to this `User`. */
  emailAddress?: Maybe<UserEmailAddress>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `User`. */
  tenant?: Maybe<Tenant>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the upsert `Commentable` mutation. */
export type UpsertCommentableInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Commentable` to be upserted by this mutation. */
  commentable: CommentableInput;
};

/** The output of our upsert `Commentable` mutation. */
export type UpsertCommentablePayload = {
  __typename?: 'UpsertCommentablePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Commentable` that was upserted by this mutation. */
  commentable?: Maybe<Commentable>;
  /** An edge for our `Commentable`. May be used by Relay 1. */
  commentableEdge?: Maybe<CommentablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `Commentable` mutation. */
export type UpsertCommentablePayloadCommentableEdgeArgs = {
  orderBy?: InputMaybe<Array<CommentablesOrderBy>>;
};

/** Where conditions for the upsert `Commentable` mutation. */
export type UpsertCommentableWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `Conversation` mutation. */
export type UpsertConversationInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Conversation` to be upserted by this mutation. */
  conversation: ConversationInput;
};

/** The output of our upsert `Conversation` mutation. */
export type UpsertConversationPayload = {
  __typename?: 'UpsertConversationPayload';
  /** Reads a single `Commentable` that is related to this `Conversation`. */
  aboutCommentable?: Maybe<Commentable>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Conversation` that was upserted by this mutation. */
  conversation?: Maybe<Conversation>;
  /** An edge for our `Conversation`. May be used by Relay 1. */
  conversationEdge?: Maybe<ConversationsEdge>;
  /** Reads a single `User` that is related to this `Conversation`. */
  creator?: Maybe<User>;
  /** Reads a single `Group` that is related to this `Conversation`. */
  privateGroup?: Maybe<Group>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Conversation`. */
  tenant?: Maybe<Tenant>;
};


/** The output of our upsert `Conversation` mutation. */
export type UpsertConversationPayloadConversationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};

/** Where conditions for the upsert `Conversation` mutation. */
export type UpsertConversationWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `EmaEntries2` mutation. */
export type UpsertEmaEntries2Input = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EmaEntries2` to be upserted by this mutation. */
  emaEntries2: EmaEntries2Input;
};

/** The output of our upsert `EmaEntries2` mutation. */
export type UpsertEmaEntries2Payload = {
  __typename?: 'UpsertEmaEntries2Payload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntries2` that was upserted by this mutation. */
  emaEntries2?: Maybe<EmaEntries2>;
  /** An edge for our `EmaEntries2`. May be used by Relay 1. */
  emaEntries2Edge?: Maybe<EmaEntries2sEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntries2`. */
  subject?: Maybe<User>;
};


/** The output of our upsert `EmaEntries2` mutation. */
export type UpsertEmaEntries2PayloadEmaEntries2EdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntries2sOrderBy>>;
};

/** Where conditions for the upsert `EmaEntries2` mutation. */
export type UpsertEmaEntries2Where = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `EmaEntries3` mutation. */
export type UpsertEmaEntries3Input = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EmaEntries3` to be upserted by this mutation. */
  emaEntries3: EmaEntries3Input;
};

/** The output of our upsert `EmaEntries3` mutation. */
export type UpsertEmaEntries3Payload = {
  __typename?: 'UpsertEmaEntries3Payload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntries3` that was upserted by this mutation. */
  emaEntries3?: Maybe<EmaEntries3>;
  /** An edge for our `EmaEntries3`. May be used by Relay 1. */
  emaEntries3Edge?: Maybe<EmaEntries3sEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `EmaEntries3`. */
  trial?: Maybe<Trial>;
};


/** The output of our upsert `EmaEntries3` mutation. */
export type UpsertEmaEntries3PayloadEmaEntries3EdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntries3sOrderBy>>;
};

/** Where conditions for the upsert `EmaEntries3` mutation. */
export type UpsertEmaEntries3Where = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `EmaEntry` mutation. */
export type UpsertEmaEntryInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EmaEntry` to be upserted by this mutation. */
  emaEntry: EmaEntryInput;
};

/** The output of our upsert `EmaEntry` mutation. */
export type UpsertEmaEntryPayload = {
  __typename?: 'UpsertEmaEntryPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `EmaEntry` that was upserted by this mutation. */
  emaEntry?: Maybe<EmaEntry>;
  /** An edge for our `EmaEntry`. May be used by Relay 1. */
  emaEntryEdge?: Maybe<EmaEntriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `EmaEntry`. */
  subject?: Maybe<User>;
};


/** The output of our upsert `EmaEntry` mutation. */
export type UpsertEmaEntryPayloadEmaEntryEdgeArgs = {
  orderBy?: InputMaybe<Array<EmaEntriesOrderBy>>;
};

/** Where conditions for the upsert `EmaEntry` mutation. */
export type UpsertEmaEntryWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `Event` mutation. */
export type UpsertEventInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Event` to be upserted by this mutation. */
  event: EventInput;
};

/** The output of our upsert `Event` mutation. */
export type UpsertEventPayload = {
  __typename?: 'UpsertEventPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Event` that was upserted by this mutation. */
  event?: Maybe<Event>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** Where conditions for the upsert `Event` mutation. */
export type UpsertEventWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `FavoritePage` mutation. */
export type UpsertFavoritePageInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FavoritePage` to be upserted by this mutation. */
  favoritePage: FavoritePageInput;
};

/** The output of our upsert `FavoritePage` mutation. */
export type UpsertFavoritePagePayload = {
  __typename?: 'UpsertFavoritePagePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `FavoritePage` that was upserted by this mutation. */
  favoritePage?: Maybe<FavoritePage>;
  /** An edge for our `FavoritePage`. May be used by Relay 1. */
  favoritePageEdge?: Maybe<FavoritePagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `FavoritePage`. */
  trial?: Maybe<Trial>;
};


/** The output of our upsert `FavoritePage` mutation. */
export type UpsertFavoritePagePayloadFavoritePageEdgeArgs = {
  orderBy?: InputMaybe<Array<FavoritePagesOrderBy>>;
};

/** Where conditions for the upsert `FavoritePage` mutation. */
export type UpsertFavoritePageWhere = {
  route?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `GenericEmiExercise` mutation. */
export type UpsertGenericEmiExerciseInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `GenericEmiExercise` to be upserted by this mutation. */
  genericEmiExercise: GenericEmiExerciseInput;
};

/** The output of our upsert `GenericEmiExercise` mutation. */
export type UpsertGenericEmiExercisePayload = {
  __typename?: 'UpsertGenericEmiExercisePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GenericEmiExercise` that was upserted by this mutation. */
  genericEmiExercise?: Maybe<GenericEmiExercise>;
  /** An edge for our `GenericEmiExercise`. May be used by Relay 1. */
  genericEmiExerciseEdge?: Maybe<GenericEmiExercisesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `GenericEmiExercise`. */
  trial?: Maybe<Trial>;
};


/** The output of our upsert `GenericEmiExercise` mutation. */
export type UpsertGenericEmiExercisePayloadGenericEmiExerciseEdgeArgs = {
  orderBy?: InputMaybe<Array<GenericEmiExercisesOrderBy>>;
};

/** Where conditions for the upsert `GenericEmiExercise` mutation. */
export type UpsertGenericEmiExerciseWhere = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `GroupHome` mutation. */
export type UpsertGroupHomeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `GroupHome` to be upserted by this mutation. */
  groupHome: GroupHomeInput;
};

/** The output of our upsert `GroupHome` mutation. */
export type UpsertGroupHomePayload = {
  __typename?: 'UpsertGroupHomePayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `GroupHome` that was upserted by this mutation. */
  groupHome?: Maybe<GroupHome>;
  /** An edge for our `GroupHome`. May be used by Relay 1. */
  groupHomeEdge?: Maybe<GroupHomesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `GroupHome` mutation. */
export type UpsertGroupHomePayloadGroupHomeEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupHomesOrderBy>>;
};

/** Where conditions for the upsert `GroupHome` mutation. */
export type UpsertGroupHomeWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `Group` mutation. */
export type UpsertGroupInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Group` to be upserted by this mutation. */
  group: GroupInput;
};

/** The output of our upsert `Group` mutation. */
export type UpsertGroupPayload = {
  __typename?: 'UpsertGroupPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Group`. */
  creator?: Maybe<User>;
  /** The `Group` that was upserted by this mutation. */
  group?: Maybe<Group>;
  /** An edge for our `Group`. May be used by Relay 1. */
  groupEdge?: Maybe<GroupsEdge>;
  /** Reads a single `GroupHome` that is related to this `Group`. */
  home?: Maybe<GroupHome>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `Group`. */
  tenant?: Maybe<Tenant>;
};


/** The output of our upsert `Group` mutation. */
export type UpsertGroupPayloadGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};

/** Where conditions for the upsert `Group` mutation. */
export type UpsertGroupWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `LimesurveyToken` mutation. */
export type UpsertLimesurveyTokenInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `LimesurveyToken` to be upserted by this mutation. */
  limesurveyToken: LimesurveyTokenInput;
};

/** The output of our upsert `LimesurveyToken` mutation. */
export type UpsertLimesurveyTokenPayload = {
  __typename?: 'UpsertLimesurveyTokenPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `LimesurveyToken` that was upserted by this mutation. */
  limesurveyToken?: Maybe<LimesurveyToken>;
  /** An edge for our `LimesurveyToken`. May be used by Relay 1. */
  limesurveyTokenEdge?: Maybe<LimesurveyTokensEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `LimesurveyToken`. */
  trial?: Maybe<Trial>;
};


/** The output of our upsert `LimesurveyToken` mutation. */
export type UpsertLimesurveyTokenPayloadLimesurveyTokenEdgeArgs = {
  orderBy?: InputMaybe<Array<LimesurveyTokensOrderBy>>;
};

/** Where conditions for the upsert `LimesurveyToken` mutation. */
export type UpsertLimesurveyTokenWhere = {
  surveyId?: InputMaybe<Scalars['Int']>;
  timeOfMeasurement?: InputMaybe<Scalars['String']>;
  token?: InputMaybe<Scalars['String']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `Membership` mutation. */
export type UpsertMembershipInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Membership` to be upserted by this mutation. */
  membership: MembershipInput;
};

/** All input for the upsert `MembershipInvitationConfirmation` mutation. */
export type UpsertMembershipInvitationConfirmationInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MembershipInvitationConfirmation` to be upserted by this mutation. */
  membershipInvitationConfirmation: MembershipInvitationConfirmationInput;
};

/** The output of our upsert `MembershipInvitationConfirmation` mutation. */
export type UpsertMembershipInvitationConfirmationPayload = {
  __typename?: 'UpsertMembershipInvitationConfirmationPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `MembershipInvitation` that is related to this `MembershipInvitationConfirmation`. */
  invitation?: Maybe<MembershipInvitation>;
  /** Reads a single `Membership` that is related to this `MembershipInvitationConfirmation`. */
  membership?: Maybe<Membership>;
  /** The `MembershipInvitationConfirmation` that was upserted by this mutation. */
  membershipInvitationConfirmation?: Maybe<MembershipInvitationConfirmation>;
  /** An edge for our `MembershipInvitationConfirmation`. May be used by Relay 1. */
  membershipInvitationConfirmationEdge?: Maybe<MembershipInvitationConfirmationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `MembershipInvitationConfirmation` mutation. */
export type UpsertMembershipInvitationConfirmationPayloadMembershipInvitationConfirmationEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipInvitationConfirmationsOrderBy>>;
};

/** Where conditions for the upsert `MembershipInvitationConfirmation` mutation. */
export type UpsertMembershipInvitationConfirmationWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `MembershipInvitation` mutation. */
export type UpsertMembershipInvitationInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MembershipInvitation` to be upserted by this mutation. */
  membershipInvitation: MembershipInvitationInput;
};

/** The output of our upsert `MembershipInvitation` mutation. */
export type UpsertMembershipInvitationPayload = {
  __typename?: 'UpsertMembershipInvitationPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  group?: Maybe<Group>;
  /** Reads a single `Group` that is related to this `MembershipInvitation`. */
  invitedGroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  invitedUser?: Maybe<User>;
  /** Reads a single `User` that is related to this `MembershipInvitation`. */
  inviter?: Maybe<User>;
  /** The `MembershipInvitation` that was upserted by this mutation. */
  membershipInvitation?: Maybe<MembershipInvitation>;
  /** An edge for our `MembershipInvitation`. May be used by Relay 1. */
  membershipInvitationEdge?: Maybe<MembershipInvitationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};


/** The output of our upsert `MembershipInvitation` mutation. */
export type UpsertMembershipInvitationPayloadMembershipInvitationEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};

/** Where conditions for the upsert `MembershipInvitation` mutation. */
export type UpsertMembershipInvitationWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** The output of our upsert `Membership` mutation. */
export type UpsertMembershipPayload = {
  __typename?: 'UpsertMembershipPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Group` that is related to this `Membership`. */
  group?: Maybe<Group>;
  /** The `Membership` that was upserted by this mutation. */
  membership?: Maybe<Membership>;
  /** An edge for our `Membership`. May be used by Relay 1. */
  membershipEdge?: Maybe<MembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Membership`. */
  subgroup?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Membership`. */
  user?: Maybe<User>;
};


/** The output of our upsert `Membership` mutation. */
export type UpsertMembershipPayloadMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};

/** Where conditions for the upsert `Membership` mutation. */
export type UpsertMembershipWhere = {
  groupId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  subgroupId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `PostWaitRandomizationTicket` mutation. */
export type UpsertPostWaitRandomizationTicketInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PostWaitRandomizationTicket` to be upserted by this mutation. */
  postWaitRandomizationTicket: PostWaitRandomizationTicketInput;
};

/** The output of our upsert `PostWaitRandomizationTicket` mutation. */
export type UpsertPostWaitRandomizationTicketPayload = {
  __typename?: 'UpsertPostWaitRandomizationTicketPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PostWaitRandomizationTicket` that was upserted by this mutation. */
  postWaitRandomizationTicket?: Maybe<PostWaitRandomizationTicket>;
  /** An edge for our `PostWaitRandomizationTicket`. May be used by Relay 1. */
  postWaitRandomizationTicketEdge?: Maybe<PostWaitRandomizationTicketsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Trial` that is related to this `PostWaitRandomizationTicket`. */
  trial?: Maybe<Trial>;
};


/** The output of our upsert `PostWaitRandomizationTicket` mutation. */
export type UpsertPostWaitRandomizationTicketPayloadPostWaitRandomizationTicketEdgeArgs = {
  orderBy?: InputMaybe<Array<PostWaitRandomizationTicketsOrderBy>>;
};

/** Where conditions for the upsert `PostWaitRandomizationTicket` mutation. */
export type UpsertPostWaitRandomizationTicketWhere = {
  id?: InputMaybe<Scalars['UUID']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `Posting` mutation. */
export type UpsertPostingInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Posting` to be upserted by this mutation. */
  posting: PostingInput;
};

/** The output of our upsert `Posting` mutation. */
export type UpsertPostingPayload = {
  __typename?: 'UpsertPostingPayload';
  /** Reads a single `Commentable` that is related to this `Posting`. */
  aboutCommentable?: Maybe<Commentable>;
  /** Reads a single `Posting` that is related to this `Posting`. */
  answeredPosting?: Maybe<Posting>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Conversation` that is related to this `Posting`. */
  conversation?: Maybe<Conversation>;
  /** The `Posting` that was upserted by this mutation. */
  posting?: Maybe<Posting>;
  /** An edge for our `Posting`. May be used by Relay 1. */
  postingEdge?: Maybe<PostingsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Posting`. */
  sender?: Maybe<User>;
};


/** The output of our upsert `Posting` mutation. */
export type UpsertPostingPayloadPostingEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};

/** All input for the upsert `PostingReceipt` mutation. */
export type UpsertPostingReceiptInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `PostingReceipt` to be upserted by this mutation. */
  postingReceipt: PostingReceiptInput;
};

/** The output of our upsert `PostingReceipt` mutation. */
export type UpsertPostingReceiptPayload = {
  __typename?: 'UpsertPostingReceiptPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Posting` that is related to this `PostingReceipt`. */
  posting?: Maybe<Posting>;
  /** The `PostingReceipt` that was upserted by this mutation. */
  postingReceipt?: Maybe<PostingReceipt>;
  /** An edge for our `PostingReceipt`. May be used by Relay 1. */
  postingReceiptEdge?: Maybe<PostingReceiptsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostingReceipt`. */
  user?: Maybe<User>;
};


/** The output of our upsert `PostingReceipt` mutation. */
export type UpsertPostingReceiptPayloadPostingReceiptEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};

/** Where conditions for the upsert `PostingReceipt` mutation. */
export type UpsertPostingReceiptWhere = {
  postingId?: InputMaybe<Scalars['UUID']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Where conditions for the upsert `Posting` mutation. */
export type UpsertPostingWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `RandomizationTicket` mutation. */
export type UpsertRandomizationTicketInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RandomizationTicket` to be upserted by this mutation. */
  randomizationTicket: RandomizationTicketInput;
};

/** The output of our upsert `RandomizationTicket` mutation. */
export type UpsertRandomizationTicketPayload = {
  __typename?: 'UpsertRandomizationTicketPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RandomizationTicket` that was upserted by this mutation. */
  randomizationTicket?: Maybe<RandomizationTicket>;
  /** An edge for our `RandomizationTicket`. May be used by Relay 1. */
  randomizationTicketEdge?: Maybe<RandomizationTicketsEdge>;
  /** Reads a single `Trial` that is related to this `RandomizationTicket`. */
  trial?: Maybe<Trial>;
};


/** The output of our upsert `RandomizationTicket` mutation. */
export type UpsertRandomizationTicketPayloadRandomizationTicketEdgeArgs = {
  orderBy?: InputMaybe<Array<RandomizationTicketsOrderBy>>;
};

/** Where conditions for the upsert `RandomizationTicket` mutation. */
export type UpsertRandomizationTicketWhere = {
  id?: InputMaybe<Scalars['UUID']>;
  trialId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `RawUsageDatum` mutation. */
export type UpsertRawUsageDatumInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `RawUsageDatum` to be upserted by this mutation. */
  rawUsageDatum: RawUsageDatumInput;
};

/** The output of our upsert `RawUsageDatum` mutation. */
export type UpsertRawUsageDatumPayload = {
  __typename?: 'UpsertRawUsageDatumPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `RawUsageDatum` that was upserted by this mutation. */
  rawUsageDatum?: Maybe<RawUsageDatum>;
  /** An edge for our `RawUsageDatum`. May be used by Relay 1. */
  rawUsageDatumEdge?: Maybe<RawUsageDataEdge>;
  /** Reads a single `Trial` that is related to this `RawUsageDatum`. */
  trial?: Maybe<Trial>;
  /** Reads a single `User` that is related to this `RawUsageDatum`. */
  user?: Maybe<User>;
};


/** The output of our upsert `RawUsageDatum` mutation. */
export type UpsertRawUsageDatumPayloadRawUsageDatumEdgeArgs = {
  orderBy?: InputMaybe<Array<RawUsageDataOrderBy>>;
};

/** Where conditions for the upsert `RawUsageDatum` mutation. */
export type UpsertRawUsageDatumWhere = {
  id?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `Tenant` mutation. */
export type UpsertTenantInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Tenant` to be upserted by this mutation. */
  tenant: TenantInput;
};

/** The output of our upsert `Tenant` mutation. */
export type UpsertTenantPayload = {
  __typename?: 'UpsertTenantPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Tenant` that was upserted by this mutation. */
  tenant?: Maybe<Tenant>;
  /** An edge for our `Tenant`. May be used by Relay 1. */
  tenantEdge?: Maybe<TenantsEdge>;
};


/** The output of our upsert `Tenant` mutation. */
export type UpsertTenantPayloadTenantEdgeArgs = {
  orderBy?: InputMaybe<Array<TenantsOrderBy>>;
};

/** Where conditions for the upsert `Tenant` mutation. */
export type UpsertTenantWhere = {
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `Trial` mutation. */
export type UpsertTrialInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Trial` to be upserted by this mutation. */
  trial: TrialInput;
};

/** The output of our upsert `Trial` mutation. */
export type UpsertTrialPayload = {
  __typename?: 'UpsertTrialPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Commentable` that is related to this `Trial`. */
  commentableById?: Maybe<Commentable>;
  /** Reads a single `User` that is related to this `Trial`. */
  createdBy?: Maybe<User>;
  /** Reads a single `GroupHome` that is related to this `Trial`. */
  groupHomeById?: Maybe<GroupHome>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  internalMessages?: Maybe<Conversation>;
  /** Reads a single `Conversation` that is related to this `Trial`. */
  messages?: Maybe<Conversation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Group` that is related to this `Trial`. */
  staff?: Maybe<Group>;
  /** Reads a single `User` that is related to this `Trial`. */
  subject?: Maybe<User>;
  /** Reads a single `Tenant` that is related to this `Trial`. */
  tenant?: Maybe<Tenant>;
  /** The `Trial` that was upserted by this mutation. */
  trial?: Maybe<Trial>;
  /** An edge for our `Trial`. May be used by Relay 1. */
  trialEdge?: Maybe<TrialsEdge>;
};


/** The output of our upsert `Trial` mutation. */
export type UpsertTrialPayloadTrialEdgeArgs = {
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

/** Where conditions for the upsert `Trial` mutation. */
export type UpsertTrialWhere = {
  humanReadableId?: InputMaybe<Scalars['BigInt']>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
};

/** All input for the upsert `UserCredential` mutation. */
export type UpsertUserCredentialInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserCredential` to be upserted by this mutation. */
  userCredential: UserCredentialInput;
};

/** The output of our upsert `UserCredential` mutation. */
export type UpsertUserCredentialPayload = {
  __typename?: 'UpsertUserCredentialPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `UserCredential`. */
  tenant?: Maybe<Tenant>;
  /** The `UserCredential` that was upserted by this mutation. */
  userCredential?: Maybe<UserCredential>;
  /** An edge for our `UserCredential`. May be used by Relay 1. */
  userCredentialEdge?: Maybe<UserCredentialsEdge>;
};


/** The output of our upsert `UserCredential` mutation. */
export type UpsertUserCredentialPayloadUserCredentialEdgeArgs = {
  orderBy?: InputMaybe<Array<UserCredentialsOrderBy>>;
};

/** Where conditions for the upsert `UserCredential` mutation. */
export type UpsertUserCredentialWhere = {
  externalAuthId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `UserEmailAddress` mutation. */
export type UpsertUserEmailAddressInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserEmailAddress` to be upserted by this mutation. */
  userEmailAddress: UserEmailAddressInput;
};

/** The output of our upsert `UserEmailAddress` mutation. */
export type UpsertUserEmailAddressPayload = {
  __typename?: 'UpsertUserEmailAddressPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserEmailAddress` that was upserted by this mutation. */
  userEmailAddress?: Maybe<UserEmailAddress>;
  /** An edge for our `UserEmailAddress`. May be used by Relay 1. */
  userEmailAddressEdge?: Maybe<UserEmailAddressesEdge>;
};


/** The output of our upsert `UserEmailAddress` mutation. */
export type UpsertUserEmailAddressPayloadUserEmailAddressEdgeArgs = {
  orderBy?: InputMaybe<Array<UserEmailAddressesOrderBy>>;
};

/** Where conditions for the upsert `UserEmailAddress` mutation. */
export type UpsertUserEmailAddressWhere = {
  emailAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
};

/** All input for the upsert `User` mutation. */
export type UpsertUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `User` to be upserted by this mutation. */
  user: UserInput;
};

/** The output of our upsert `User` mutation. */
export type UpsertUserPayload = {
  __typename?: 'UpsertUserPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `UserCredential` that is related to this `User`. */
  credentials?: Maybe<UserCredential>;
  /** Reads a single `UserEmailAddress` that is related to this `User`. */
  emailAddress?: Maybe<UserEmailAddress>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tenant` that is related to this `User`. */
  tenant?: Maybe<Tenant>;
  /** The `User` that was upserted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our upsert `User` mutation. */
export type UpsertUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** Where conditions for the upsert `User` mutation. */
export type UpsertUserWhere = {
  credentialsId?: InputMaybe<Scalars['UUID']>;
  emailAddressId?: InputMaybe<Scalars['UUID']>;
  id?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  username?: InputMaybe<Scalars['String']>;
};

export type User = Node & {
  __typename?: 'User';
  aboutMe?: Maybe<Scalars['String']>;
  aboutMeFormat?: Maybe<Scalars['MimeType']>;
  /** Reads and enables pagination through a set of `Group`. */
  commonGroups: GroupsConnection;
  /** Reads and enables pagination through a set of `Conversation`. */
  conversationsByCreatorId: ConversationsConnection;
  createdAt: Scalars['Datetime'];
  /** Reads a single `UserCredential` that is related to this `User`. */
  credentials?: Maybe<UserCredential>;
  credentialsId?: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `EmaEntries2`. */
  emaEntries2SBySubjectId: EmaEntries2sConnection;
  /** Reads and enables pagination through a set of `EmaEntry`. */
  emaEntriesBySubjectId: EmaEntriesConnection;
  /** Reads a single `UserEmailAddress` that is related to this `User`. */
  emailAddress?: Maybe<UserEmailAddress>;
  emailAddressId?: Maybe<Scalars['UUID']>;
  externalRoles?: Maybe<Array<Maybe<Scalars['String']>>>;
  gravatarHash?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Group`. */
  groupsByCreatorId: GroupsConnection;
  id: Scalars['UUID'];
  isTenantAdmin?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `MembershipInvitation`. */
  membershipInvitationsByInvitedUserId: MembershipInvitationsConnection;
  /** Reads and enables pagination through a set of `MembershipInvitation`. */
  membershipInvitationsByInviterId: MembershipInvitationsConnection;
  /** Reads and enables pagination through a set of `Membership`. */
  memberships: MembershipsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `PostingReceipt`. */
  postingReceipts: PostingReceiptsConnection;
  /** Reads and enables pagination through a set of `Posting`. */
  postingsBySenderId: PostingsConnection;
  /** Reads and enables pagination through a set of `RawUsageDatum`. */
  rawUsageData: RawUsageDataConnection;
  /** Reads a single `Tenant` that is related to this `User`. */
  tenant?: Maybe<Tenant>;
  tenantId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `Trial`. */
  trialsByCreatedById: TrialsConnection;
  /** Reads and enables pagination through a set of `Trial`. */
  trialsBySubjectId: TrialsConnection;
  updatedAt: Scalars['Datetime'];
  username: Scalars['String'];
};


export type UserCommonGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GroupCondition>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};


export type UserConversationsByCreatorIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConversationCondition>;
  filter?: InputMaybe<ConversationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConversationsOrderBy>>;
};


export type UserEmaEntries2SBySubjectIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmaEntries2Condition>;
  filter?: InputMaybe<EmaEntries2Filter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmaEntries2sOrderBy>>;
};


export type UserEmaEntriesBySubjectIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EmaEntryCondition>;
  filter?: InputMaybe<EmaEntryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EmaEntriesOrderBy>>;
};


export type UserGroupsByCreatorIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GroupCondition>;
  filter?: InputMaybe<GroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GroupsOrderBy>>;
};


export type UserMembershipInvitationsByInvitedUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationCondition>;
  filter?: InputMaybe<MembershipInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};


export type UserMembershipInvitationsByInviterIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipInvitationCondition>;
  filter?: InputMaybe<MembershipInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipInvitationsOrderBy>>;
};


export type UserMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MembershipCondition>;
  filter?: InputMaybe<MembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MembershipsOrderBy>>;
};


export type UserPostingReceiptsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingReceiptCondition>;
  filter?: InputMaybe<PostingReceiptFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};


export type UserPostingsBySenderIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<PostingCondition>;
  filter?: InputMaybe<PostingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<PostingsOrderBy>>;
};


export type UserRawUsageDataArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<RawUsageDatumCondition>;
  filter?: InputMaybe<RawUsageDatumFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<RawUsageDataOrderBy>>;
};


export type UserTrialsByCreatedByIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};


export type UserTrialsBySubjectIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<TrialCondition>;
  filter?: InputMaybe<TrialFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<TrialsOrderBy>>;
};

export type UserAggregates = {
  __typename?: 'UserAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `User` object types. */
export type UserAggregatesFilter = {
  /** Distinct count aggregate over matching `User` objects. */
  distinctCount?: InputMaybe<UserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `User` object to be included within the aggregate. */
  filter?: InputMaybe<UserFilter>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `aboutMe` field. */
  aboutMe?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `aboutMeFormat` field. */
  aboutMeFormat?: InputMaybe<Scalars['MimeType']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `credentialsId` field. */
  credentialsId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `emailAddressId` field. */
  emailAddressId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `externalRoles` field. */
  externalRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `gravatarHash` field. */
  gravatarHash?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isTenantAdmin` field. */
  isTenantAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars['String']>;
};

export type UserCredential = Node & {
  __typename?: 'UserCredential';
  createdAt: Scalars['Datetime'];
  encryptedPassword?: Maybe<Scalars['String']>;
  externalAuthId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Tenant` that is related to this `UserCredential`. */
  tenant?: Maybe<Tenant>;
  tenantId: Scalars['UUID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `UserCredential`. */
  user?: Maybe<User>;
  /**
   * Reads and enables pagination through a set of `User`.
   * @deprecated Please use user instead
   */
  users: UsersConnection;
};


export type UserCredentialUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type UserCredentialAggregates = {
  __typename?: 'UserCredentialAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserCredentialDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/** A filter to be used against aggregates of `UserCredential` object types. */
export type UserCredentialAggregatesFilter = {
  /** Distinct count aggregate over matching `UserCredential` objects. */
  distinctCount?: InputMaybe<UserCredentialDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `UserCredential` object to be included within the aggregate. */
  filter?: InputMaybe<UserCredentialFilter>;
};

/**
 * A condition to be used against `UserCredential` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserCredentialCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `encryptedPassword` field. */
  encryptedPassword?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `externalAuthId` field. */
  externalAuthId?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type UserCredentialDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  encryptedPassword?: InputMaybe<BigIntFilter>;
  externalAuthId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  tenantId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type UserCredentialDistinctCountAggregates = {
  __typename?: 'UserCredentialDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of encryptedPassword across the matching connection */
  encryptedPassword?: Maybe<Scalars['BigInt']>;
  /** Distinct count of externalAuthId across the matching connection */
  externalAuthId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenantId across the matching connection */
  tenantId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UserCredential` object types. All fields are combined with a logical ‘and.’ */
export type UserCredentialFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserCredentialFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `encryptedPassword` field. */
  encryptedPassword?: InputMaybe<StringFilter>;
  /** Filter by the object’s `externalAuthId` field. */
  externalAuthId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserCredentialFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserCredentialFilter>>;
  /** Filter by the object’s `tenant` relation. */
  tenant?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `UserCredential` */
export type UserCredentialInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  encryptedPassword?: InputMaybe<Scalars['String']>;
  externalAuthId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `UserCredential`. Fields that are set will be updated. */
export type UserCredentialPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  encryptedPassword?: InputMaybe<Scalars['String']>;
  externalAuthId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `UserCredential` values. */
export type UserCredentialsConnection = {
  __typename?: 'UserCredentialsConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserCredentialAggregates>;
  /** A list of edges which contains the `UserCredential` and cursor to aid in pagination. */
  edges: Array<UserCredentialsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserCredentialAggregates>>;
  /** A list of `UserCredential` objects. */
  nodes: Array<UserCredential>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserCredential` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `UserCredential` values. */
export type UserCredentialsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserCredentialsGroupBy>;
  having?: InputMaybe<UserCredentialsHavingInput>;
};

/** A `UserCredential` edge in the connection. */
export type UserCredentialsEdge = {
  __typename?: 'UserCredentialsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserCredential` at the end of the edge. */
  node: UserCredential;
};

/** Grouping methods for `UserCredential` for usage during aggregation. */
export enum UserCredentialsGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  EncryptedPassword = 'ENCRYPTED_PASSWORD',
  ExternalAuthId = 'EXTERNAL_AUTH_ID',
  TenantId = 'TENANT_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type UserCredentialsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserCredentialsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UserCredential` aggregates. */
export type UserCredentialsHavingInput = {
  AND?: InputMaybe<Array<UserCredentialsHavingInput>>;
  OR?: InputMaybe<Array<UserCredentialsHavingInput>>;
  average?: InputMaybe<UserCredentialsHavingAverageInput>;
  distinctCount?: InputMaybe<UserCredentialsHavingDistinctCountInput>;
  max?: InputMaybe<UserCredentialsHavingMaxInput>;
  min?: InputMaybe<UserCredentialsHavingMinInput>;
  stddevPopulation?: InputMaybe<UserCredentialsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserCredentialsHavingStddevSampleInput>;
  sum?: InputMaybe<UserCredentialsHavingSumInput>;
  variancePopulation?: InputMaybe<UserCredentialsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserCredentialsHavingVarianceSampleInput>;
};

export type UserCredentialsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserCredentialsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserCredentialsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserCredentialsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserCredentialsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserCredentialsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserCredentialsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `UserCredential`. */
export enum UserCredentialsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EncryptedPasswordAsc = 'ENCRYPTED_PASSWORD_ASC',
  EncryptedPasswordDesc = 'ENCRYPTED_PASSWORD_DESC',
  ExternalAuthIdAsc = 'EXTERNAL_AUTH_ID_ASC',
  ExternalAuthIdDesc = 'EXTERNAL_AUTH_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type UserDistinctCountAggregateFilter = {
  aboutMe?: InputMaybe<BigIntFilter>;
  aboutMeFormat?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  credentialsId?: InputMaybe<BigIntFilter>;
  emailAddressId?: InputMaybe<BigIntFilter>;
  externalRoles?: InputMaybe<BigIntFilter>;
  gravatarHash?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isTenantAdmin?: InputMaybe<BigIntFilter>;
  tenantId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  username?: InputMaybe<BigIntFilter>;
};

export type UserDistinctCountAggregates = {
  __typename?: 'UserDistinctCountAggregates';
  /** Distinct count of aboutMe across the matching connection */
  aboutMe?: Maybe<Scalars['BigInt']>;
  /** Distinct count of aboutMeFormat across the matching connection */
  aboutMeFormat?: Maybe<Scalars['BigInt']>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of credentialsId across the matching connection */
  credentialsId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emailAddressId across the matching connection */
  emailAddressId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of externalRoles across the matching connection */
  externalRoles?: Maybe<Scalars['BigInt']>;
  /** Distinct count of gravatarHash across the matching connection */
  gravatarHash?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of isTenantAdmin across the matching connection */
  isTenantAdmin?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenantId across the matching connection */
  tenantId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of username across the matching connection */
  username?: Maybe<Scalars['BigInt']>;
};

export type UserEmailAddress = Node & {
  __typename?: 'UserEmailAddress';
  createdAt: Scalars['Datetime'];
  emailAddress: Scalars['String'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  tenantId: Scalars['UUID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `UserEmailAddress`. */
  user?: Maybe<User>;
  /**
   * Reads and enables pagination through a set of `User`.
   * @deprecated Please use user instead
   */
  users: UsersConnection;
};


export type UserEmailAddressUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type UserEmailAddressAggregates = {
  __typename?: 'UserEmailAddressAggregates';
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserEmailAddressDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars['String']>>;
};

/**
 * A condition to be used against `UserEmailAddress` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserEmailAddressCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `emailAddress` field. */
  emailAddress?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `tenantId` field. */
  tenantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

export type UserEmailAddressDistinctCountAggregates = {
  __typename?: 'UserEmailAddressDistinctCountAggregates';
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars['BigInt']>;
  /** Distinct count of emailAddress across the matching connection */
  emailAddress?: Maybe<Scalars['BigInt']>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars['BigInt']>;
  /** Distinct count of tenantId across the matching connection */
  tenantId?: Maybe<Scalars['BigInt']>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `UserEmailAddress` object types. All fields are combined with a logical ‘and.’ */
export type UserEmailAddressFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserEmailAddressFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emailAddress` field. */
  emailAddress?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserEmailAddressFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserEmailAddressFilter>>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `UserEmailAddress` */
export type UserEmailAddressInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  emailAddress: Scalars['String'];
  id?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Represents an update to a `UserEmailAddress`. Fields that are set will be updated. */
export type UserEmailAddressPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `UserEmailAddress` values. */
export type UserEmailAddressesConnection = {
  __typename?: 'UserEmailAddressesConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserEmailAddressAggregates>;
  /** A list of edges which contains the `UserEmailAddress` and cursor to aid in pagination. */
  edges: Array<UserEmailAddressesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserEmailAddressAggregates>>;
  /** A list of `UserEmailAddress` objects. */
  nodes: Array<UserEmailAddress>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserEmailAddress` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `UserEmailAddress` values. */
export type UserEmailAddressesConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserEmailAddressesGroupBy>;
  having?: InputMaybe<UserEmailAddressesHavingInput>;
};

/** A `UserEmailAddress` edge in the connection. */
export type UserEmailAddressesEdge = {
  __typename?: 'UserEmailAddressesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserEmailAddress` at the end of the edge. */
  node: UserEmailAddress;
};

/** Grouping methods for `UserEmailAddress` for usage during aggregation. */
export enum UserEmailAddressesGroupBy {
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  EmailAddress = 'EMAIL_ADDRESS',
  TenantId = 'TENANT_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR'
}

export type UserEmailAddressesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserEmailAddressesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UserEmailAddress` aggregates. */
export type UserEmailAddressesHavingInput = {
  AND?: InputMaybe<Array<UserEmailAddressesHavingInput>>;
  OR?: InputMaybe<Array<UserEmailAddressesHavingInput>>;
  average?: InputMaybe<UserEmailAddressesHavingAverageInput>;
  distinctCount?: InputMaybe<UserEmailAddressesHavingDistinctCountInput>;
  max?: InputMaybe<UserEmailAddressesHavingMaxInput>;
  min?: InputMaybe<UserEmailAddressesHavingMinInput>;
  stddevPopulation?: InputMaybe<UserEmailAddressesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserEmailAddressesHavingStddevSampleInput>;
  sum?: InputMaybe<UserEmailAddressesHavingSumInput>;
  variancePopulation?: InputMaybe<UserEmailAddressesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserEmailAddressesHavingVarianceSampleInput>;
};

export type UserEmailAddressesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserEmailAddressesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserEmailAddressesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserEmailAddressesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserEmailAddressesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserEmailAddressesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserEmailAddressesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `UserEmailAddress`. */
export enum UserEmailAddressesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAddressAsc = 'EMAIL_ADDRESS_ASC',
  EmailAddressDesc = 'EMAIL_ADDRESS_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `aboutMe` field. */
  aboutMe?: InputMaybe<StringFilter>;
  /** Filter by the object’s `aboutMeFormat` field. */
  aboutMeFormat?: InputMaybe<MimeTypeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `conversationsByCreatorId` relation. */
  conversationsByCreatorId?: InputMaybe<UserToManyConversationFilter>;
  /** Some related `conversationsByCreatorId` exist. */
  conversationsByCreatorIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `credentials` relation. */
  credentials?: InputMaybe<UserCredentialFilter>;
  /** A related `credentials` exists. */
  credentialsExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `credentialsId` field. */
  credentialsId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `emaEntries2SBySubjectId` relation. */
  emaEntries2SBySubjectId?: InputMaybe<UserToManyEmaEntries2Filter>;
  /** Some related `emaEntries2SBySubjectId` exist. */
  emaEntries2SBySubjectIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `emaEntriesBySubjectId` relation. */
  emaEntriesBySubjectId?: InputMaybe<UserToManyEmaEntryFilter>;
  /** Some related `emaEntriesBySubjectId` exist. */
  emaEntriesBySubjectIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `emailAddress` relation. */
  emailAddress?: InputMaybe<UserEmailAddressFilter>;
  /** A related `emailAddress` exists. */
  emailAddressExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `emailAddressId` field. */
  emailAddressId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `externalRoles` field. */
  externalRoles?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `gravatarHash` field. */
  gravatarHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `groupsByCreatorId` relation. */
  groupsByCreatorId?: InputMaybe<UserToManyGroupFilter>;
  /** Some related `groupsByCreatorId` exist. */
  groupsByCreatorIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isTenantAdmin` field. */
  isTenantAdmin?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `membershipInvitationsByInvitedUserId` relation. */
  membershipInvitationsByInvitedUserId?: InputMaybe<UserToManyMembershipInvitationFilter>;
  /** Some related `membershipInvitationsByInvitedUserId` exist. */
  membershipInvitationsByInvitedUserIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `membershipInvitationsByInviterId` relation. */
  membershipInvitationsByInviterId?: InputMaybe<UserToManyMembershipInvitationFilter>;
  /** Some related `membershipInvitationsByInviterId` exist. */
  membershipInvitationsByInviterIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `memberships` relation. */
  memberships?: InputMaybe<UserToManyMembershipFilter>;
  /** Some related `memberships` exist. */
  membershipsExist?: InputMaybe<Scalars['Boolean']>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `postingReceipts` relation. */
  postingReceipts?: InputMaybe<UserToManyPostingReceiptFilter>;
  /** Some related `postingReceipts` exist. */
  postingReceiptsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `postingsBySenderId` relation. */
  postingsBySenderId?: InputMaybe<UserToManyPostingFilter>;
  /** Some related `postingsBySenderId` exist. */
  postingsBySenderIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `rawUsageData` relation. */
  rawUsageData?: InputMaybe<UserToManyRawUsageDatumFilter>;
  /** Some related `rawUsageData` exist. */
  rawUsageDataExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `tenant` relation. */
  tenant?: InputMaybe<TenantFilter>;
  /** Filter by the object’s `tenantId` field. */
  tenantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `trialsByCreatedById` relation. */
  trialsByCreatedById?: InputMaybe<UserToManyTrialFilter>;
  /** Some related `trialsByCreatedById` exist. */
  trialsByCreatedByIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `trialsBySubjectId` relation. */
  trialsBySubjectId?: InputMaybe<UserToManyTrialFilter>;
  /** Some related `trialsBySubjectId` exist. */
  trialsBySubjectIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutMeFormat?: InputMaybe<Scalars['MimeType']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  credentialsId?: InputMaybe<Scalars['UUID']>;
  emailAddressId?: InputMaybe<Scalars['UUID']>;
  externalRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gravatarHash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isTenantAdmin?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  username: Scalars['String'];
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  aboutMe?: InputMaybe<Scalars['String']>;
  aboutMeFormat?: InputMaybe<Scalars['MimeType']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  credentialsId?: InputMaybe<Scalars['UUID']>;
  emailAddressId?: InputMaybe<Scalars['UUID']>;
  externalRoles?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  gravatarHash?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isTenantAdmin?: InputMaybe<Scalars['Boolean']>;
  tenantId?: InputMaybe<Scalars['UUID']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  username?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against many `Conversation` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyConversationFilter = {
  /** Aggregates across related `Conversation` match the filter criteria. */
  aggregates?: InputMaybe<ConversationAggregatesFilter>;
  /** Every related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConversationFilter>;
  /** No related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConversationFilter>;
  /** Some related `Conversation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConversationFilter>;
};

/** A filter to be used against many `EmaEntries2` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyEmaEntries2Filter = {
  /** Aggregates across related `EmaEntries2` match the filter criteria. */
  aggregates?: InputMaybe<EmaEntries2AggregatesFilter>;
  /** Every related `EmaEntries2` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<EmaEntries2Filter>;
  /** No related `EmaEntries2` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<EmaEntries2Filter>;
  /** Some related `EmaEntries2` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<EmaEntries2Filter>;
};

/** A filter to be used against many `EmaEntry` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyEmaEntryFilter = {
  /** Aggregates across related `EmaEntry` match the filter criteria. */
  aggregates?: InputMaybe<EmaEntryAggregatesFilter>;
  /** Every related `EmaEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<EmaEntryFilter>;
  /** No related `EmaEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<EmaEntryFilter>;
  /** Some related `EmaEntry` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<EmaEntryFilter>;
};

/** A filter to be used against many `Group` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyGroupFilter = {
  /** Aggregates across related `Group` match the filter criteria. */
  aggregates?: InputMaybe<GroupAggregatesFilter>;
  /** Every related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<GroupFilter>;
  /** No related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<GroupFilter>;
  /** Some related `Group` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<GroupFilter>;
};

/** A filter to be used against many `Membership` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyMembershipFilter = {
  /** Aggregates across related `Membership` match the filter criteria. */
  aggregates?: InputMaybe<MembershipAggregatesFilter>;
  /** Every related `Membership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MembershipFilter>;
  /** No related `Membership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MembershipFilter>;
  /** Some related `Membership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MembershipFilter>;
};

/** A filter to be used against many `MembershipInvitation` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyMembershipInvitationFilter = {
  /** Aggregates across related `MembershipInvitation` match the filter criteria. */
  aggregates?: InputMaybe<MembershipInvitationAggregatesFilter>;
  /** Every related `MembershipInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MembershipInvitationFilter>;
  /** No related `MembershipInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MembershipInvitationFilter>;
  /** Some related `MembershipInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MembershipInvitationFilter>;
};

/** A filter to be used against many `Posting` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyPostingFilter = {
  /** Aggregates across related `Posting` match the filter criteria. */
  aggregates?: InputMaybe<PostingAggregatesFilter>;
  /** Every related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostingFilter>;
  /** No related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostingFilter>;
  /** Some related `Posting` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostingFilter>;
};

/** A filter to be used against many `PostingReceipt` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyPostingReceiptFilter = {
  /** Aggregates across related `PostingReceipt` match the filter criteria. */
  aggregates?: InputMaybe<PostingReceiptAggregatesFilter>;
  /** Every related `PostingReceipt` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PostingReceiptFilter>;
  /** No related `PostingReceipt` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PostingReceiptFilter>;
  /** Some related `PostingReceipt` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PostingReceiptFilter>;
};

/** A filter to be used against many `RawUsageDatum` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyRawUsageDatumFilter = {
  /** Aggregates across related `RawUsageDatum` match the filter criteria. */
  aggregates?: InputMaybe<RawUsageDatumAggregatesFilter>;
  /** Every related `RawUsageDatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<RawUsageDatumFilter>;
  /** No related `RawUsageDatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<RawUsageDatumFilter>;
  /** Some related `RawUsageDatum` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<RawUsageDatumFilter>;
};

/** A filter to be used against many `Trial` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyTrialFilter = {
  /** Aggregates across related `Trial` match the filter criteria. */
  aggregates?: InputMaybe<TrialAggregatesFilter>;
  /** Every related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<TrialFilter>;
  /** No related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<TrialFilter>;
  /** Some related `Trial` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<TrialFilter>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};


/** A connection to a list of `User` values. */
export type UsersConnectionGroupedAggregatesArgs = {
  groupBy: Array<UsersGroupBy>;
  having?: InputMaybe<UsersHavingInput>;
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Grouping methods for `User` for usage during aggregation. */
export enum UsersGroupBy {
  AboutMe = 'ABOUT_ME',
  AboutMeFormat = 'ABOUT_ME_FORMAT',
  CreatedAt = 'CREATED_AT',
  CreatedAtTruncatedToDay = 'CREATED_AT_TRUNCATED_TO_DAY',
  CreatedAtTruncatedToHour = 'CREATED_AT_TRUNCATED_TO_HOUR',
  ExternalRoles = 'EXTERNAL_ROLES',
  GravatarHash = 'GRAVATAR_HASH',
  IsTenantAdmin = 'IS_TENANT_ADMIN',
  TenantId = 'TENANT_ID',
  UpdatedAt = 'UPDATED_AT',
  UpdatedAtTruncatedToDay = 'UPDATED_AT_TRUNCATED_TO_DAY',
  UpdatedAtTruncatedToHour = 'UPDATED_AT_TRUNCATED_TO_HOUR',
  Username = 'USERNAME'
}

export type UsersHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `User` aggregates. */
export type UsersHavingInput = {
  AND?: InputMaybe<Array<UsersHavingInput>>;
  OR?: InputMaybe<Array<UsersHavingInput>>;
  average?: InputMaybe<UsersHavingAverageInput>;
  distinctCount?: InputMaybe<UsersHavingDistinctCountInput>;
  max?: InputMaybe<UsersHavingMaxInput>;
  min?: InputMaybe<UsersHavingMinInput>;
  stddevPopulation?: InputMaybe<UsersHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UsersHavingStddevSampleInput>;
  sum?: InputMaybe<UsersHavingSumInput>;
  variancePopulation?: InputMaybe<UsersHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UsersHavingVarianceSampleInput>;
};

export type UsersHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UsersHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  AboutMeAsc = 'ABOUT_ME_ASC',
  AboutMeDesc = 'ABOUT_ME_DESC',
  AboutMeFormatAsc = 'ABOUT_ME_FORMAT_ASC',
  AboutMeFormatDesc = 'ABOUT_ME_FORMAT_DESC',
  ConversationsByCreatorIdAverageAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdAverageAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdAverageAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_ABSTRACT_ASC',
  ConversationsByCreatorIdAverageAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_ABSTRACT_DESC',
  ConversationsByCreatorIdAverageCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_CREATED_AT_ASC',
  ConversationsByCreatorIdAverageCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_CREATED_AT_DESC',
  ConversationsByCreatorIdAverageCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_CREATOR_ID_ASC',
  ConversationsByCreatorIdAverageCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_CREATOR_ID_DESC',
  ConversationsByCreatorIdAverageIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_ID_ASC',
  ConversationsByCreatorIdAverageIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_ID_DESC',
  ConversationsByCreatorIdAverageIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_IS_PUBLIC_ASC',
  ConversationsByCreatorIdAverageIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_IS_PUBLIC_DESC',
  ConversationsByCreatorIdAverageLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_LANGUAGE_ASC',
  ConversationsByCreatorIdAverageLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_LANGUAGE_DESC',
  ConversationsByCreatorIdAveragePrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdAveragePrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdAverageTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_TENANT_ID_ASC',
  ConversationsByCreatorIdAverageTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_TENANT_ID_DESC',
  ConversationsByCreatorIdAverageTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_TITLE_ASC',
  ConversationsByCreatorIdAverageTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_TITLE_DESC',
  ConversationsByCreatorIdAverageUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_UPDATED_AT_ASC',
  ConversationsByCreatorIdAverageUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_AVERAGE_UPDATED_AT_DESC',
  ConversationsByCreatorIdCountAsc = 'CONVERSATIONS_BY_CREATOR_ID_COUNT_ASC',
  ConversationsByCreatorIdCountDesc = 'CONVERSATIONS_BY_CREATOR_ID_COUNT_DESC',
  ConversationsByCreatorIdDistinctCountAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdDistinctCountAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdDistinctCountAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_ABSTRACT_ASC',
  ConversationsByCreatorIdDistinctCountAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_ABSTRACT_DESC',
  ConversationsByCreatorIdDistinctCountCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  ConversationsByCreatorIdDistinctCountCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  ConversationsByCreatorIdDistinctCountCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_CREATOR_ID_ASC',
  ConversationsByCreatorIdDistinctCountCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_CREATOR_ID_DESC',
  ConversationsByCreatorIdDistinctCountIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_ID_ASC',
  ConversationsByCreatorIdDistinctCountIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_ID_DESC',
  ConversationsByCreatorIdDistinctCountIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_IS_PUBLIC_ASC',
  ConversationsByCreatorIdDistinctCountIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_IS_PUBLIC_DESC',
  ConversationsByCreatorIdDistinctCountLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_LANGUAGE_ASC',
  ConversationsByCreatorIdDistinctCountLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_LANGUAGE_DESC',
  ConversationsByCreatorIdDistinctCountPrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdDistinctCountPrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdDistinctCountTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  ConversationsByCreatorIdDistinctCountTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  ConversationsByCreatorIdDistinctCountTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_TITLE_ASC',
  ConversationsByCreatorIdDistinctCountTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_TITLE_DESC',
  ConversationsByCreatorIdDistinctCountUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  ConversationsByCreatorIdDistinctCountUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  ConversationsByCreatorIdMaxAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdMaxAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdMaxAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_ABSTRACT_ASC',
  ConversationsByCreatorIdMaxAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_ABSTRACT_DESC',
  ConversationsByCreatorIdMaxCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_CREATED_AT_ASC',
  ConversationsByCreatorIdMaxCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_CREATED_AT_DESC',
  ConversationsByCreatorIdMaxCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_CREATOR_ID_ASC',
  ConversationsByCreatorIdMaxCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_CREATOR_ID_DESC',
  ConversationsByCreatorIdMaxIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_ID_ASC',
  ConversationsByCreatorIdMaxIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_ID_DESC',
  ConversationsByCreatorIdMaxIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_IS_PUBLIC_ASC',
  ConversationsByCreatorIdMaxIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_IS_PUBLIC_DESC',
  ConversationsByCreatorIdMaxLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_LANGUAGE_ASC',
  ConversationsByCreatorIdMaxLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_LANGUAGE_DESC',
  ConversationsByCreatorIdMaxPrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdMaxPrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdMaxTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_TENANT_ID_ASC',
  ConversationsByCreatorIdMaxTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_TENANT_ID_DESC',
  ConversationsByCreatorIdMaxTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_TITLE_ASC',
  ConversationsByCreatorIdMaxTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_TITLE_DESC',
  ConversationsByCreatorIdMaxUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_UPDATED_AT_ASC',
  ConversationsByCreatorIdMaxUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_MAX_UPDATED_AT_DESC',
  ConversationsByCreatorIdMinAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdMinAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdMinAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_ABSTRACT_ASC',
  ConversationsByCreatorIdMinAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_ABSTRACT_DESC',
  ConversationsByCreatorIdMinCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_CREATED_AT_ASC',
  ConversationsByCreatorIdMinCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_CREATED_AT_DESC',
  ConversationsByCreatorIdMinCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_CREATOR_ID_ASC',
  ConversationsByCreatorIdMinCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_CREATOR_ID_DESC',
  ConversationsByCreatorIdMinIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_ID_ASC',
  ConversationsByCreatorIdMinIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_ID_DESC',
  ConversationsByCreatorIdMinIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_IS_PUBLIC_ASC',
  ConversationsByCreatorIdMinIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_IS_PUBLIC_DESC',
  ConversationsByCreatorIdMinLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_LANGUAGE_ASC',
  ConversationsByCreatorIdMinLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_LANGUAGE_DESC',
  ConversationsByCreatorIdMinPrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdMinPrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdMinTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_TENANT_ID_ASC',
  ConversationsByCreatorIdMinTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_TENANT_ID_DESC',
  ConversationsByCreatorIdMinTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_TITLE_ASC',
  ConversationsByCreatorIdMinTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_TITLE_DESC',
  ConversationsByCreatorIdMinUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_UPDATED_AT_ASC',
  ConversationsByCreatorIdMinUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_MIN_UPDATED_AT_DESC',
  ConversationsByCreatorIdStddevPopulationAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdStddevPopulationAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdStddevPopulationAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_ABSTRACT_ASC',
  ConversationsByCreatorIdStddevPopulationAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_ABSTRACT_DESC',
  ConversationsByCreatorIdStddevPopulationCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  ConversationsByCreatorIdStddevPopulationCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  ConversationsByCreatorIdStddevPopulationCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_CREATOR_ID_ASC',
  ConversationsByCreatorIdStddevPopulationCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_CREATOR_ID_DESC',
  ConversationsByCreatorIdStddevPopulationIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_ID_ASC',
  ConversationsByCreatorIdStddevPopulationIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_ID_DESC',
  ConversationsByCreatorIdStddevPopulationIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_IS_PUBLIC_ASC',
  ConversationsByCreatorIdStddevPopulationIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_IS_PUBLIC_DESC',
  ConversationsByCreatorIdStddevPopulationLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_LANGUAGE_ASC',
  ConversationsByCreatorIdStddevPopulationLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_LANGUAGE_DESC',
  ConversationsByCreatorIdStddevPopulationPrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdStddevPopulationPrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdStddevPopulationTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  ConversationsByCreatorIdStddevPopulationTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  ConversationsByCreatorIdStddevPopulationTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_TITLE_ASC',
  ConversationsByCreatorIdStddevPopulationTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_TITLE_DESC',
  ConversationsByCreatorIdStddevPopulationUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  ConversationsByCreatorIdStddevPopulationUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  ConversationsByCreatorIdStddevSampleAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdStddevSampleAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdStddevSampleAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_ABSTRACT_ASC',
  ConversationsByCreatorIdStddevSampleAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_ABSTRACT_DESC',
  ConversationsByCreatorIdStddevSampleCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  ConversationsByCreatorIdStddevSampleCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  ConversationsByCreatorIdStddevSampleCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_CREATOR_ID_ASC',
  ConversationsByCreatorIdStddevSampleCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_CREATOR_ID_DESC',
  ConversationsByCreatorIdStddevSampleIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_ID_ASC',
  ConversationsByCreatorIdStddevSampleIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_ID_DESC',
  ConversationsByCreatorIdStddevSampleIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_IS_PUBLIC_ASC',
  ConversationsByCreatorIdStddevSampleIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_IS_PUBLIC_DESC',
  ConversationsByCreatorIdStddevSampleLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_LANGUAGE_ASC',
  ConversationsByCreatorIdStddevSampleLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_LANGUAGE_DESC',
  ConversationsByCreatorIdStddevSamplePrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdStddevSamplePrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdStddevSampleTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  ConversationsByCreatorIdStddevSampleTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  ConversationsByCreatorIdStddevSampleTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_TITLE_ASC',
  ConversationsByCreatorIdStddevSampleTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_TITLE_DESC',
  ConversationsByCreatorIdStddevSampleUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  ConversationsByCreatorIdStddevSampleUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  ConversationsByCreatorIdSumAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdSumAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdSumAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_ABSTRACT_ASC',
  ConversationsByCreatorIdSumAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_ABSTRACT_DESC',
  ConversationsByCreatorIdSumCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_CREATED_AT_ASC',
  ConversationsByCreatorIdSumCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_CREATED_AT_DESC',
  ConversationsByCreatorIdSumCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_CREATOR_ID_ASC',
  ConversationsByCreatorIdSumCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_CREATOR_ID_DESC',
  ConversationsByCreatorIdSumIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_ID_ASC',
  ConversationsByCreatorIdSumIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_ID_DESC',
  ConversationsByCreatorIdSumIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_IS_PUBLIC_ASC',
  ConversationsByCreatorIdSumIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_IS_PUBLIC_DESC',
  ConversationsByCreatorIdSumLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_LANGUAGE_ASC',
  ConversationsByCreatorIdSumLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_LANGUAGE_DESC',
  ConversationsByCreatorIdSumPrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdSumPrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdSumTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_TENANT_ID_ASC',
  ConversationsByCreatorIdSumTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_TENANT_ID_DESC',
  ConversationsByCreatorIdSumTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_TITLE_ASC',
  ConversationsByCreatorIdSumTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_TITLE_DESC',
  ConversationsByCreatorIdSumUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_UPDATED_AT_ASC',
  ConversationsByCreatorIdSumUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_SUM_UPDATED_AT_DESC',
  ConversationsByCreatorIdVariancePopulationAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdVariancePopulationAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdVariancePopulationAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_ABSTRACT_ASC',
  ConversationsByCreatorIdVariancePopulationAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_ABSTRACT_DESC',
  ConversationsByCreatorIdVariancePopulationCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  ConversationsByCreatorIdVariancePopulationCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  ConversationsByCreatorIdVariancePopulationCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_CREATOR_ID_ASC',
  ConversationsByCreatorIdVariancePopulationCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_CREATOR_ID_DESC',
  ConversationsByCreatorIdVariancePopulationIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_ID_ASC',
  ConversationsByCreatorIdVariancePopulationIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_ID_DESC',
  ConversationsByCreatorIdVariancePopulationIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_IS_PUBLIC_ASC',
  ConversationsByCreatorIdVariancePopulationIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_IS_PUBLIC_DESC',
  ConversationsByCreatorIdVariancePopulationLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_LANGUAGE_ASC',
  ConversationsByCreatorIdVariancePopulationLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_LANGUAGE_DESC',
  ConversationsByCreatorIdVariancePopulationPrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdVariancePopulationPrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdVariancePopulationTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  ConversationsByCreatorIdVariancePopulationTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  ConversationsByCreatorIdVariancePopulationTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_TITLE_ASC',
  ConversationsByCreatorIdVariancePopulationTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_TITLE_DESC',
  ConversationsByCreatorIdVariancePopulationUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  ConversationsByCreatorIdVariancePopulationUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  ConversationsByCreatorIdVarianceSampleAboutCommentableIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  ConversationsByCreatorIdVarianceSampleAboutCommentableIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  ConversationsByCreatorIdVarianceSampleAbstractAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_ABSTRACT_ASC',
  ConversationsByCreatorIdVarianceSampleAbstractDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_ABSTRACT_DESC',
  ConversationsByCreatorIdVarianceSampleCreatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  ConversationsByCreatorIdVarianceSampleCreatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  ConversationsByCreatorIdVarianceSampleCreatorIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_CREATOR_ID_ASC',
  ConversationsByCreatorIdVarianceSampleCreatorIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_CREATOR_ID_DESC',
  ConversationsByCreatorIdVarianceSampleIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_ID_ASC',
  ConversationsByCreatorIdVarianceSampleIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_ID_DESC',
  ConversationsByCreatorIdVarianceSampleIsPublicAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_IS_PUBLIC_ASC',
  ConversationsByCreatorIdVarianceSampleIsPublicDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_IS_PUBLIC_DESC',
  ConversationsByCreatorIdVarianceSampleLanguageAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_LANGUAGE_ASC',
  ConversationsByCreatorIdVarianceSampleLanguageDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_LANGUAGE_DESC',
  ConversationsByCreatorIdVarianceSamplePrivateToGroupIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_PRIVATE_TO_GROUP_ID_ASC',
  ConversationsByCreatorIdVarianceSamplePrivateToGroupIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_PRIVATE_TO_GROUP_ID_DESC',
  ConversationsByCreatorIdVarianceSampleTenantIdAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  ConversationsByCreatorIdVarianceSampleTenantIdDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  ConversationsByCreatorIdVarianceSampleTitleAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_TITLE_ASC',
  ConversationsByCreatorIdVarianceSampleTitleDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_TITLE_DESC',
  ConversationsByCreatorIdVarianceSampleUpdatedAtAsc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  ConversationsByCreatorIdVarianceSampleUpdatedAtDesc = 'CONVERSATIONS_BY_CREATOR_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CredentialsIdAsc = 'CREDENTIALS_ID_ASC',
  CredentialsIdDesc = 'CREDENTIALS_ID_DESC',
  EmailAddressIdAsc = 'EMAIL_ADDRESS_ID_ASC',
  EmailAddressIdDesc = 'EMAIL_ADDRESS_ID_DESC',
  EmaEntries2SbySubjectIdAverageAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_ANGRY_ASC',
  EmaEntries2SbySubjectIdAverageAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_ANGRY_DESC',
  EmaEntries2SbySubjectIdAverageAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdAverageAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdAverageAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdAverageAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdAverageAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdAverageAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdAverageBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_BURDEN_ASC',
  EmaEntries2SbySubjectIdAverageBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_BURDEN_DESC',
  EmaEntries2SbySubjectIdAverageComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdAverageComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdAverageCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdAverageCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdAverageCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdAverageCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdAverageDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_DELETED_ASC',
  EmaEntries2SbySubjectIdAverageDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_DELETED_DESC',
  EmaEntries2SbySubjectIdAverageGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_GUILTY_ASC',
  EmaEntries2SbySubjectIdAverageGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_GUILTY_DESC',
  EmaEntries2SbySubjectIdAverageHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdAverageHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdAverageHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_HAPPY_ASC',
  EmaEntries2SbySubjectIdAverageHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_HAPPY_DESC',
  EmaEntries2SbySubjectIdAverageIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_ID_ASC',
  EmaEntries2SbySubjectIdAverageIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_ID_DESC',
  EmaEntries2SbySubjectIdAverageLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdAverageLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdAverageLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_LONELY_ASC',
  EmaEntries2SbySubjectIdAverageLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_LONELY_DESC',
  EmaEntries2SbySubjectIdAverageLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdAverageLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdAverageNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdAverageNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdAverageNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdAverageNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdAveragePlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdAveragePlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdAverageRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_RELAXED_ASC',
  EmaEntries2SbySubjectIdAverageRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_RELAXED_DESC',
  EmaEntries2SbySubjectIdAverageRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_RUMINATION_ASC',
  EmaEntries2SbySubjectIdAverageRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_RUMINATION_DESC',
  EmaEntries2SbySubjectIdAverageSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_SAD_ASC',
  EmaEntries2SbySubjectIdAverageSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_SAD_DESC',
  EmaEntries2SbySubjectIdAverageSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_SATISFIED_ASC',
  EmaEntries2SbySubjectIdAverageSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_SATISFIED_DESC',
  EmaEntries2SbySubjectIdAverageScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdAverageScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdAverageStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdAverageStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdAverageSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdAverageSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdAverageTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdAverageTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdAverageTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TESTY_ASC',
  EmaEntries2SbySubjectIdAverageTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TESTY_DESC',
  EmaEntries2SbySubjectIdAverageTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdAverageTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdAverageThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdAverageThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdAverageTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdAverageTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdAverageTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdAverageTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdAverageTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdAverageTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdAverageTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdAverageTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdAverageTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdAverageTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdAverageTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdAverageTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdAverageUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdAverageUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdAverageUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdAverageUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdAverageWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdAverageWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_AVERAGE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdCountAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_COUNT_ASC',
  EmaEntries2SbySubjectIdCountDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_COUNT_DESC',
  EmaEntries2SbySubjectIdDistinctCountAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_ANGRY_ASC',
  EmaEntries2SbySubjectIdDistinctCountAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_ANGRY_DESC',
  EmaEntries2SbySubjectIdDistinctCountAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdDistinctCountAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdDistinctCountAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdDistinctCountAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdDistinctCountAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdDistinctCountAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdDistinctCountBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_BURDEN_ASC',
  EmaEntries2SbySubjectIdDistinctCountBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_BURDEN_DESC',
  EmaEntries2SbySubjectIdDistinctCountComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdDistinctCountComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdDistinctCountCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdDistinctCountCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdDistinctCountCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdDistinctCountCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdDistinctCountDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_DELETED_ASC',
  EmaEntries2SbySubjectIdDistinctCountDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_DELETED_DESC',
  EmaEntries2SbySubjectIdDistinctCountGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_GUILTY_ASC',
  EmaEntries2SbySubjectIdDistinctCountGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_GUILTY_DESC',
  EmaEntries2SbySubjectIdDistinctCountHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdDistinctCountHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdDistinctCountHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_HAPPY_ASC',
  EmaEntries2SbySubjectIdDistinctCountHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_HAPPY_DESC',
  EmaEntries2SbySubjectIdDistinctCountIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_ID_ASC',
  EmaEntries2SbySubjectIdDistinctCountIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_ID_DESC',
  EmaEntries2SbySubjectIdDistinctCountLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdDistinctCountLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdDistinctCountLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_LONELY_ASC',
  EmaEntries2SbySubjectIdDistinctCountLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_LONELY_DESC',
  EmaEntries2SbySubjectIdDistinctCountLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdDistinctCountLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdDistinctCountNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdDistinctCountNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdDistinctCountNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdDistinctCountNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdDistinctCountPlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdDistinctCountPlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdDistinctCountRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_RELAXED_ASC',
  EmaEntries2SbySubjectIdDistinctCountRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_RELAXED_DESC',
  EmaEntries2SbySubjectIdDistinctCountRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_RUMINATION_ASC',
  EmaEntries2SbySubjectIdDistinctCountRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_RUMINATION_DESC',
  EmaEntries2SbySubjectIdDistinctCountSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_SAD_ASC',
  EmaEntries2SbySubjectIdDistinctCountSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_SAD_DESC',
  EmaEntries2SbySubjectIdDistinctCountSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_SATISFIED_ASC',
  EmaEntries2SbySubjectIdDistinctCountSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_SATISFIED_DESC',
  EmaEntries2SbySubjectIdDistinctCountScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdDistinctCountScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdDistinctCountStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdDistinctCountStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdDistinctCountSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdDistinctCountSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdDistinctCountTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdDistinctCountTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdDistinctCountTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TESTY_ASC',
  EmaEntries2SbySubjectIdDistinctCountTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TESTY_DESC',
  EmaEntries2SbySubjectIdDistinctCountTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdDistinctCountTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdDistinctCountThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdDistinctCountThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdDistinctCountTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdDistinctCountTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdDistinctCountTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdDistinctCountTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdDistinctCountTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdDistinctCountTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdDistinctCountTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdDistinctCountTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdDistinctCountTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdDistinctCountTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdDistinctCountTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdDistinctCountTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdDistinctCountUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdDistinctCountUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdDistinctCountUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdDistinctCountUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdDistinctCountWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdDistinctCountWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_DISTINCT_COUNT_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdMaxAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_ANGRY_ASC',
  EmaEntries2SbySubjectIdMaxAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_ANGRY_DESC',
  EmaEntries2SbySubjectIdMaxAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdMaxAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdMaxAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMaxAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMaxAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdMaxAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdMaxBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_BURDEN_ASC',
  EmaEntries2SbySubjectIdMaxBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_BURDEN_DESC',
  EmaEntries2SbySubjectIdMaxComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMaxComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMaxCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdMaxCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdMaxCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdMaxCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdMaxDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_DELETED_ASC',
  EmaEntries2SbySubjectIdMaxDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_DELETED_DESC',
  EmaEntries2SbySubjectIdMaxGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_GUILTY_ASC',
  EmaEntries2SbySubjectIdMaxGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_GUILTY_DESC',
  EmaEntries2SbySubjectIdMaxHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdMaxHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdMaxHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_HAPPY_ASC',
  EmaEntries2SbySubjectIdMaxHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_HAPPY_DESC',
  EmaEntries2SbySubjectIdMaxIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_ID_ASC',
  EmaEntries2SbySubjectIdMaxIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_ID_DESC',
  EmaEntries2SbySubjectIdMaxLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdMaxLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdMaxLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_LONELY_ASC',
  EmaEntries2SbySubjectIdMaxLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_LONELY_DESC',
  EmaEntries2SbySubjectIdMaxLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdMaxLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdMaxNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMaxNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMaxNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdMaxNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdMaxPlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdMaxPlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdMaxRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_RELAXED_ASC',
  EmaEntries2SbySubjectIdMaxRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_RELAXED_DESC',
  EmaEntries2SbySubjectIdMaxRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_RUMINATION_ASC',
  EmaEntries2SbySubjectIdMaxRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_RUMINATION_DESC',
  EmaEntries2SbySubjectIdMaxSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_SAD_ASC',
  EmaEntries2SbySubjectIdMaxSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_SAD_DESC',
  EmaEntries2SbySubjectIdMaxSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_SATISFIED_ASC',
  EmaEntries2SbySubjectIdMaxSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_SATISFIED_DESC',
  EmaEntries2SbySubjectIdMaxScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdMaxScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdMaxStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdMaxStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdMaxSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdMaxSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdMaxTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMaxTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMaxTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TESTY_ASC',
  EmaEntries2SbySubjectIdMaxTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TESTY_DESC',
  EmaEntries2SbySubjectIdMaxTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMaxTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMaxThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMaxThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMaxTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMaxTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMaxTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdMaxTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdMaxTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdMaxTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdMaxTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMaxTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMaxTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMaxTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMaxTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMaxTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMaxUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMaxUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMaxUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdMaxUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdMaxWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdMaxWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MAX_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdMinAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_ANGRY_ASC',
  EmaEntries2SbySubjectIdMinAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_ANGRY_DESC',
  EmaEntries2SbySubjectIdMinAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdMinAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdMinAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMinAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMinAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdMinAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdMinBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_BURDEN_ASC',
  EmaEntries2SbySubjectIdMinBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_BURDEN_DESC',
  EmaEntries2SbySubjectIdMinComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMinComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMinCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdMinCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdMinCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdMinCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdMinDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_DELETED_ASC',
  EmaEntries2SbySubjectIdMinDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_DELETED_DESC',
  EmaEntries2SbySubjectIdMinGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_GUILTY_ASC',
  EmaEntries2SbySubjectIdMinGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_GUILTY_DESC',
  EmaEntries2SbySubjectIdMinHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdMinHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdMinHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_HAPPY_ASC',
  EmaEntries2SbySubjectIdMinHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_HAPPY_DESC',
  EmaEntries2SbySubjectIdMinIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_ID_ASC',
  EmaEntries2SbySubjectIdMinIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_ID_DESC',
  EmaEntries2SbySubjectIdMinLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdMinLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdMinLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_LONELY_ASC',
  EmaEntries2SbySubjectIdMinLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_LONELY_DESC',
  EmaEntries2SbySubjectIdMinLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdMinLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdMinNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMinNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMinNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdMinNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdMinPlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdMinPlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdMinRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_RELAXED_ASC',
  EmaEntries2SbySubjectIdMinRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_RELAXED_DESC',
  EmaEntries2SbySubjectIdMinRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_RUMINATION_ASC',
  EmaEntries2SbySubjectIdMinRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_RUMINATION_DESC',
  EmaEntries2SbySubjectIdMinSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_SAD_ASC',
  EmaEntries2SbySubjectIdMinSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_SAD_DESC',
  EmaEntries2SbySubjectIdMinSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_SATISFIED_ASC',
  EmaEntries2SbySubjectIdMinSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_SATISFIED_DESC',
  EmaEntries2SbySubjectIdMinScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdMinScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdMinStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdMinStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdMinSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdMinSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdMinTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMinTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMinTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TESTY_ASC',
  EmaEntries2SbySubjectIdMinTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TESTY_DESC',
  EmaEntries2SbySubjectIdMinTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMinTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMinThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMinThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMinTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMinTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMinTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdMinTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdMinTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdMinTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdMinTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMinTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMinTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMinTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMinTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdMinTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdMinUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdMinUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdMinUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdMinUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdMinWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdMinWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_MIN_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_ANGRY_ASC',
  EmaEntries2SbySubjectIdStddevPopulationAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_ANGRY_DESC',
  EmaEntries2SbySubjectIdStddevPopulationAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_BURDEN_ASC',
  EmaEntries2SbySubjectIdStddevPopulationBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_BURDEN_DESC',
  EmaEntries2SbySubjectIdStddevPopulationComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_DELETED_ASC',
  EmaEntries2SbySubjectIdStddevPopulationDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_DELETED_DESC',
  EmaEntries2SbySubjectIdStddevPopulationGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_GUILTY_ASC',
  EmaEntries2SbySubjectIdStddevPopulationGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_GUILTY_DESC',
  EmaEntries2SbySubjectIdStddevPopulationHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdStddevPopulationHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdStddevPopulationHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_HAPPY_ASC',
  EmaEntries2SbySubjectIdStddevPopulationHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_HAPPY_DESC',
  EmaEntries2SbySubjectIdStddevPopulationIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_ID_ASC',
  EmaEntries2SbySubjectIdStddevPopulationIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_ID_DESC',
  EmaEntries2SbySubjectIdStddevPopulationLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdStddevPopulationLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdStddevPopulationLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_LONELY_ASC',
  EmaEntries2SbySubjectIdStddevPopulationLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_LONELY_DESC',
  EmaEntries2SbySubjectIdStddevPopulationLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationPlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdStddevPopulationPlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdStddevPopulationRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_RELAXED_ASC',
  EmaEntries2SbySubjectIdStddevPopulationRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_RELAXED_DESC',
  EmaEntries2SbySubjectIdStddevPopulationRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_RUMINATION_ASC',
  EmaEntries2SbySubjectIdStddevPopulationRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_RUMINATION_DESC',
  EmaEntries2SbySubjectIdStddevPopulationSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_SAD_ASC',
  EmaEntries2SbySubjectIdStddevPopulationSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_SAD_DESC',
  EmaEntries2SbySubjectIdStddevPopulationSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_SATISFIED_ASC',
  EmaEntries2SbySubjectIdStddevPopulationSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_SATISFIED_DESC',
  EmaEntries2SbySubjectIdStddevPopulationScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdStddevPopulationSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TESTY_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TESTY_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevPopulationTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevPopulationUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdStddevPopulationWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevPopulationWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevSampleAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_ANGRY_ASC',
  EmaEntries2SbySubjectIdStddevSampleAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_ANGRY_DESC',
  EmaEntries2SbySubjectIdStddevSampleAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdStddevSampleAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdStddevSampleAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevSampleAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevSampleAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevSampleAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevSampleBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_BURDEN_ASC',
  EmaEntries2SbySubjectIdStddevSampleBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_BURDEN_DESC',
  EmaEntries2SbySubjectIdStddevSampleComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevSampleComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevSampleCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdStddevSampleCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdStddevSampleCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdStddevSampleCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdStddevSampleDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_DELETED_ASC',
  EmaEntries2SbySubjectIdStddevSampleDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_DELETED_DESC',
  EmaEntries2SbySubjectIdStddevSampleGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_GUILTY_ASC',
  EmaEntries2SbySubjectIdStddevSampleGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_GUILTY_DESC',
  EmaEntries2SbySubjectIdStddevSampleHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdStddevSampleHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdStddevSampleHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_HAPPY_ASC',
  EmaEntries2SbySubjectIdStddevSampleHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_HAPPY_DESC',
  EmaEntries2SbySubjectIdStddevSampleIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_ID_ASC',
  EmaEntries2SbySubjectIdStddevSampleIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_ID_DESC',
  EmaEntries2SbySubjectIdStddevSampleLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdStddevSampleLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdStddevSampleLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_LONELY_ASC',
  EmaEntries2SbySubjectIdStddevSampleLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_LONELY_DESC',
  EmaEntries2SbySubjectIdStddevSampleLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdStddevSampleLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdStddevSampleNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevSampleNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevSampleNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdStddevSampleNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdStddevSamplePlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdStddevSamplePlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdStddevSampleRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_RELAXED_ASC',
  EmaEntries2SbySubjectIdStddevSampleRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_RELAXED_DESC',
  EmaEntries2SbySubjectIdStddevSampleRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_RUMINATION_ASC',
  EmaEntries2SbySubjectIdStddevSampleRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_RUMINATION_DESC',
  EmaEntries2SbySubjectIdStddevSampleSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_SAD_ASC',
  EmaEntries2SbySubjectIdStddevSampleSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_SAD_DESC',
  EmaEntries2SbySubjectIdStddevSampleSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_SATISFIED_ASC',
  EmaEntries2SbySubjectIdStddevSampleSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_SATISFIED_DESC',
  EmaEntries2SbySubjectIdStddevSampleScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdStddevSampleScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdStddevSampleStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdStddevSampleStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdStddevSampleSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdStddevSampleSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdStddevSampleTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevSampleTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevSampleTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TESTY_ASC',
  EmaEntries2SbySubjectIdStddevSampleTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TESTY_DESC',
  EmaEntries2SbySubjectIdStddevSampleTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevSampleTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevSampleThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevSampleThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevSampleTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevSampleTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevSampleTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdStddevSampleTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdStddevSampleTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdStddevSampleTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdStddevSampleTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevSampleTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevSampleTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevSampleTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevSampleTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdStddevSampleTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdStddevSampleUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevSampleUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdStddevSampleUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdStddevSampleUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdStddevSampleWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdStddevSampleWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_STDDEV_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdSumAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_ANGRY_ASC',
  EmaEntries2SbySubjectIdSumAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_ANGRY_DESC',
  EmaEntries2SbySubjectIdSumAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdSumAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdSumAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdSumAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdSumAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdSumAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdSumBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_BURDEN_ASC',
  EmaEntries2SbySubjectIdSumBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_BURDEN_DESC',
  EmaEntries2SbySubjectIdSumComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdSumComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdSumCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdSumCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdSumCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdSumCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdSumDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_DELETED_ASC',
  EmaEntries2SbySubjectIdSumDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_DELETED_DESC',
  EmaEntries2SbySubjectIdSumGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_GUILTY_ASC',
  EmaEntries2SbySubjectIdSumGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_GUILTY_DESC',
  EmaEntries2SbySubjectIdSumHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdSumHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdSumHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_HAPPY_ASC',
  EmaEntries2SbySubjectIdSumHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_HAPPY_DESC',
  EmaEntries2SbySubjectIdSumIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_ID_ASC',
  EmaEntries2SbySubjectIdSumIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_ID_DESC',
  EmaEntries2SbySubjectIdSumLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdSumLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdSumLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_LONELY_ASC',
  EmaEntries2SbySubjectIdSumLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_LONELY_DESC',
  EmaEntries2SbySubjectIdSumLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdSumLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdSumNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdSumNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdSumNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdSumNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdSumPlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdSumPlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdSumRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_RELAXED_ASC',
  EmaEntries2SbySubjectIdSumRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_RELAXED_DESC',
  EmaEntries2SbySubjectIdSumRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_RUMINATION_ASC',
  EmaEntries2SbySubjectIdSumRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_RUMINATION_DESC',
  EmaEntries2SbySubjectIdSumSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_SAD_ASC',
  EmaEntries2SbySubjectIdSumSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_SAD_DESC',
  EmaEntries2SbySubjectIdSumSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_SATISFIED_ASC',
  EmaEntries2SbySubjectIdSumSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_SATISFIED_DESC',
  EmaEntries2SbySubjectIdSumScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdSumScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdSumStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdSumStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdSumSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdSumSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdSumTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdSumTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdSumTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TESTY_ASC',
  EmaEntries2SbySubjectIdSumTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TESTY_DESC',
  EmaEntries2SbySubjectIdSumTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdSumTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdSumThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdSumThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdSumTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdSumTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdSumTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdSumTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdSumTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdSumTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdSumTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdSumTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdSumTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdSumTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdSumTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdSumTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdSumUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdSumUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdSumUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdSumUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdSumWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdSumWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_SUM_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_ANGRY_ASC',
  EmaEntries2SbySubjectIdVariancePopulationAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_ANGRY_DESC',
  EmaEntries2SbySubjectIdVariancePopulationAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_BURDEN_ASC',
  EmaEntries2SbySubjectIdVariancePopulationBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_BURDEN_DESC',
  EmaEntries2SbySubjectIdVariancePopulationComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_DELETED_ASC',
  EmaEntries2SbySubjectIdVariancePopulationDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_DELETED_DESC',
  EmaEntries2SbySubjectIdVariancePopulationGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_GUILTY_ASC',
  EmaEntries2SbySubjectIdVariancePopulationGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_GUILTY_DESC',
  EmaEntries2SbySubjectIdVariancePopulationHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdVariancePopulationHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdVariancePopulationHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_HAPPY_ASC',
  EmaEntries2SbySubjectIdVariancePopulationHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_HAPPY_DESC',
  EmaEntries2SbySubjectIdVariancePopulationIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_ID_ASC',
  EmaEntries2SbySubjectIdVariancePopulationIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_ID_DESC',
  EmaEntries2SbySubjectIdVariancePopulationLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdVariancePopulationLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdVariancePopulationLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_LONELY_ASC',
  EmaEntries2SbySubjectIdVariancePopulationLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_LONELY_DESC',
  EmaEntries2SbySubjectIdVariancePopulationLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationPlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdVariancePopulationPlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdVariancePopulationRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_RELAXED_ASC',
  EmaEntries2SbySubjectIdVariancePopulationRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_RELAXED_DESC',
  EmaEntries2SbySubjectIdVariancePopulationRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_RUMINATION_ASC',
  EmaEntries2SbySubjectIdVariancePopulationRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_RUMINATION_DESC',
  EmaEntries2SbySubjectIdVariancePopulationSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_SAD_ASC',
  EmaEntries2SbySubjectIdVariancePopulationSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_SAD_DESC',
  EmaEntries2SbySubjectIdVariancePopulationSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_SATISFIED_ASC',
  EmaEntries2SbySubjectIdVariancePopulationSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_SATISFIED_DESC',
  EmaEntries2SbySubjectIdVariancePopulationScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdVariancePopulationSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TESTY_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TESTY_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVariancePopulationTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVariancePopulationUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdVariancePopulationWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdVariancePopulationWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleAngryAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_ANGRY_ASC',
  EmaEntries2SbySubjectIdVarianceSampleAngryDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_ANGRY_DESC',
  EmaEntries2SbySubjectIdVarianceSampleAnxiousAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_ANXIOUS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleAnxiousDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_ANXIOUS_DESC',
  EmaEntries2SbySubjectIdVarianceSampleAnxiousDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleAnxiousDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleAvoidedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleAvoidedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleBurdenAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_BURDEN_ASC',
  EmaEntries2SbySubjectIdVarianceSampleBurdenDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_BURDEN_DESC',
  EmaEntries2SbySubjectIdVarianceSampleComfortableDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleComfortableDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleCompletedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleCompletedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleCreatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleCreatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleDeletedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_DELETED_ASC',
  EmaEntries2SbySubjectIdVarianceSampleDeletedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_DELETED_DESC',
  EmaEntries2SbySubjectIdVarianceSampleGuiltyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_GUILTY_ASC',
  EmaEntries2SbySubjectIdVarianceSampleGuiltyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_GUILTY_DESC',
  EmaEntries2SbySubjectIdVarianceSampleHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntries2SbySubjectIdVarianceSampleHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntries2SbySubjectIdVarianceSampleHappyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_HAPPY_ASC',
  EmaEntries2SbySubjectIdVarianceSampleHappyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_HAPPY_DESC',
  EmaEntries2SbySubjectIdVarianceSampleIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_ID_ASC',
  EmaEntries2SbySubjectIdVarianceSampleIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_ID_DESC',
  EmaEntries2SbySubjectIdVarianceSampleLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntries2SbySubjectIdVarianceSampleLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntries2SbySubjectIdVarianceSampleLonelyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_LONELY_ASC',
  EmaEntries2SbySubjectIdVarianceSampleLonelyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_LONELY_DESC',
  EmaEntries2SbySubjectIdVarianceSampleLookedForAdviceAndComfortFromOthersAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleLookedForAdviceAndComfortFromOthersDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_LOOKED_FOR_ADVICE_AND_COMFORT_FROM_OTHERS_DESC',
  EmaEntries2SbySubjectIdVarianceSampleNotTriedToAlterMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleNotTriedToAlterMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_NOT_TRIED_TO_ALTER_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVarianceSampleNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntries2SbySubjectIdVarianceSamplePlannedHowToProceedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_PLANNED_HOW_TO_PROCEED_ASC',
  EmaEntries2SbySubjectIdVarianceSamplePlannedHowToProceedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_PLANNED_HOW_TO_PROCEED_DESC',
  EmaEntries2SbySubjectIdVarianceSampleRelaxedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_RELAXED_ASC',
  EmaEntries2SbySubjectIdVarianceSampleRelaxedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_RELAXED_DESC',
  EmaEntries2SbySubjectIdVarianceSampleRuminationAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_RUMINATION_ASC',
  EmaEntries2SbySubjectIdVarianceSampleRuminationDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_RUMINATION_DESC',
  EmaEntries2SbySubjectIdVarianceSampleSadAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_SAD_ASC',
  EmaEntries2SbySubjectIdVarianceSampleSadDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_SAD_DESC',
  EmaEntries2SbySubjectIdVarianceSampleSatisfiedAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_SATISFIED_ASC',
  EmaEntries2SbySubjectIdVarianceSampleSatisfiedDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_SATISFIED_DESC',
  EmaEntries2SbySubjectIdVarianceSampleScheduledAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_SCHEDULED_AT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleScheduledAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_SCHEDULED_AT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleStartedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_STARTED_AT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleStartedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_STARTED_AT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleSubjectIdAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_SUBJECT_ID_ASC',
  EmaEntries2SbySubjectIdVarianceSampleSubjectIdDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_SUBJECT_ID_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTenseDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TENSE_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTenseDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TENSE_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTestyAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TESTY_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTestyDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TESTY_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTestyDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TESTY_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTestyDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TESTY_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleThoughtALotAboutMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleThoughtALotAboutMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_THOUGHT_A_LOT_ABOUT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToAcceptMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToAcceptMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_ACCEPT_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToCalmMyselfDownAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_CALM_MYSELF_DOWN_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToCalmMyselfDownDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_CALM_MYSELF_DOWN_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToChangeMyPerspectiveAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_CHANGE_MY_PERSPECTIVE_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToChangeMyPerspectiveDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_CHANGE_MY_PERSPECTIVE_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToDistractMyselfFromMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToDistractMyselfFromMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_DISTRACT_MYSELF_FROM_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToHideMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToHideMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_HIDE_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToRepressMyThoughtsAndFeelingsAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_ASC',
  EmaEntries2SbySubjectIdVarianceSampleTriedToRepressMyThoughtsAndFeelingsDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_REPRESS_MY_THOUGHTS_AND_FEELINGS_DESC',
  EmaEntries2SbySubjectIdVarianceSampleUnconfidentDuringContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleUnconfidentDuringContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleUpdatedAtAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleUpdatedAtDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EmaEntries2SbySubjectIdVarianceSampleWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntries2SbySubjectIdVarianceSampleWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES2_SBY_SUBJECT_ID_VARIANCE_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdAverageAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_ANGRY_ASC',
  EmaEntriesBySubjectIdAverageAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_ANGRY_DESC',
  EmaEntriesBySubjectIdAverageAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_ANXIOUS_ASC',
  EmaEntriesBySubjectIdAverageAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_ANXIOUS_DESC',
  EmaEntriesBySubjectIdAverageAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdAverageAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdAverageAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdAverageAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdAverageComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdAverageComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdAverageCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdAverageCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdAverageCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdAverageCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdAverageCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdAverageCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdAverageCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_CREATED_AT_ASC',
  EmaEntriesBySubjectIdAverageCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_CREATED_AT_DESC',
  EmaEntriesBySubjectIdAverageDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_DELETED_ASC',
  EmaEntriesBySubjectIdAverageDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_DELETED_DESC',
  EmaEntriesBySubjectIdAverageGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_GUILTY_ASC',
  EmaEntriesBySubjectIdAverageGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_GUILTY_DESC',
  EmaEntriesBySubjectIdAverageHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdAverageHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdAverageHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdAverageHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdAverageHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_HAPPY_ASC',
  EmaEntriesBySubjectIdAverageHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_HAPPY_DESC',
  EmaEntriesBySubjectIdAverageIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_ID_ASC',
  EmaEntriesBySubjectIdAverageIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_ID_DESC',
  EmaEntriesBySubjectIdAverageLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdAverageLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdAverageLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_LONELY_ASC',
  EmaEntriesBySubjectIdAverageLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_LONELY_DESC',
  EmaEntriesBySubjectIdAverageNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdAverageNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdAverageRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_RELAXED_ASC',
  EmaEntriesBySubjectIdAverageRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_RELAXED_DESC',
  EmaEntriesBySubjectIdAverageSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_SAD_ASC',
  EmaEntriesBySubjectIdAverageSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_SAD_DESC',
  EmaEntriesBySubjectIdAverageSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_SATISFIED_ASC',
  EmaEntriesBySubjectIdAverageSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_SATISFIED_DESC',
  EmaEntriesBySubjectIdAverageScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdAverageScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdAverageStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_STARTED_AT_ASC',
  EmaEntriesBySubjectIdAverageStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_STARTED_AT_DESC',
  EmaEntriesBySubjectIdAverageSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdAverageSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdAverageTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdAverageTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdAverageTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TESTY_ASC',
  EmaEntriesBySubjectIdAverageTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TESTY_DESC',
  EmaEntriesBySubjectIdAverageTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdAverageTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdAverageTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdAverageTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdAverageTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdAverageTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdAverageTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdAverageTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdAverageTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdAverageTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdAverageTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdAverageTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdAverageTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdAverageTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdAverageTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdAverageTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdAverageTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdAverageTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdAverageTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdAverageTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdAverageUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdAverageUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdAverageUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdAverageUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdAverageWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdAverageWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_AVERAGE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdCountAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_COUNT_ASC',
  EmaEntriesBySubjectIdCountDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_COUNT_DESC',
  EmaEntriesBySubjectIdDistinctCountAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_ANGRY_ASC',
  EmaEntriesBySubjectIdDistinctCountAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_ANGRY_DESC',
  EmaEntriesBySubjectIdDistinctCountAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_ANXIOUS_ASC',
  EmaEntriesBySubjectIdDistinctCountAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_ANXIOUS_DESC',
  EmaEntriesBySubjectIdDistinctCountAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdDistinctCountAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdDistinctCountAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdDistinctCountAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdDistinctCountComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdDistinctCountComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdDistinctCountCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdDistinctCountCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdDistinctCountCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdDistinctCountCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdDistinctCountCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdDistinctCountCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdDistinctCountCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  EmaEntriesBySubjectIdDistinctCountCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  EmaEntriesBySubjectIdDistinctCountDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_DELETED_ASC',
  EmaEntriesBySubjectIdDistinctCountDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_DELETED_DESC',
  EmaEntriesBySubjectIdDistinctCountGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_GUILTY_ASC',
  EmaEntriesBySubjectIdDistinctCountGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_GUILTY_DESC',
  EmaEntriesBySubjectIdDistinctCountHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdDistinctCountHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdDistinctCountHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdDistinctCountHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdDistinctCountHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_HAPPY_ASC',
  EmaEntriesBySubjectIdDistinctCountHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_HAPPY_DESC',
  EmaEntriesBySubjectIdDistinctCountIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_ID_ASC',
  EmaEntriesBySubjectIdDistinctCountIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_ID_DESC',
  EmaEntriesBySubjectIdDistinctCountLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdDistinctCountLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdDistinctCountLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_LONELY_ASC',
  EmaEntriesBySubjectIdDistinctCountLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_LONELY_DESC',
  EmaEntriesBySubjectIdDistinctCountNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdDistinctCountNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdDistinctCountRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_RELAXED_ASC',
  EmaEntriesBySubjectIdDistinctCountRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_RELAXED_DESC',
  EmaEntriesBySubjectIdDistinctCountSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_SAD_ASC',
  EmaEntriesBySubjectIdDistinctCountSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_SAD_DESC',
  EmaEntriesBySubjectIdDistinctCountSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_SATISFIED_ASC',
  EmaEntriesBySubjectIdDistinctCountSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_SATISFIED_DESC',
  EmaEntriesBySubjectIdDistinctCountScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdDistinctCountScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdDistinctCountStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_STARTED_AT_ASC',
  EmaEntriesBySubjectIdDistinctCountStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_STARTED_AT_DESC',
  EmaEntriesBySubjectIdDistinctCountSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdDistinctCountSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdDistinctCountTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdDistinctCountTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdDistinctCountTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TESTY_ASC',
  EmaEntriesBySubjectIdDistinctCountTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TESTY_DESC',
  EmaEntriesBySubjectIdDistinctCountTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdDistinctCountTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdDistinctCountTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdDistinctCountTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdDistinctCountUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdDistinctCountUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdDistinctCountUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdDistinctCountUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdDistinctCountWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdDistinctCountWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_DISTINCT_COUNT_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdMaxAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_ANGRY_ASC',
  EmaEntriesBySubjectIdMaxAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_ANGRY_DESC',
  EmaEntriesBySubjectIdMaxAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_ANXIOUS_ASC',
  EmaEntriesBySubjectIdMaxAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_ANXIOUS_DESC',
  EmaEntriesBySubjectIdMaxAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMaxAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMaxAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdMaxAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdMaxComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMaxComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMaxCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdMaxCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdMaxCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdMaxCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdMaxCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdMaxCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdMaxCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_CREATED_AT_ASC',
  EmaEntriesBySubjectIdMaxCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_CREATED_AT_DESC',
  EmaEntriesBySubjectIdMaxDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_DELETED_ASC',
  EmaEntriesBySubjectIdMaxDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_DELETED_DESC',
  EmaEntriesBySubjectIdMaxGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_GUILTY_ASC',
  EmaEntriesBySubjectIdMaxGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_GUILTY_DESC',
  EmaEntriesBySubjectIdMaxHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdMaxHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdMaxHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdMaxHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdMaxHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_HAPPY_ASC',
  EmaEntriesBySubjectIdMaxHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_HAPPY_DESC',
  EmaEntriesBySubjectIdMaxIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_ID_ASC',
  EmaEntriesBySubjectIdMaxIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_ID_DESC',
  EmaEntriesBySubjectIdMaxLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdMaxLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdMaxLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_LONELY_ASC',
  EmaEntriesBySubjectIdMaxLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_LONELY_DESC',
  EmaEntriesBySubjectIdMaxNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdMaxNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdMaxRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_RELAXED_ASC',
  EmaEntriesBySubjectIdMaxRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_RELAXED_DESC',
  EmaEntriesBySubjectIdMaxSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_SAD_ASC',
  EmaEntriesBySubjectIdMaxSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_SAD_DESC',
  EmaEntriesBySubjectIdMaxSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_SATISFIED_ASC',
  EmaEntriesBySubjectIdMaxSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_SATISFIED_DESC',
  EmaEntriesBySubjectIdMaxScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdMaxScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdMaxStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_STARTED_AT_ASC',
  EmaEntriesBySubjectIdMaxStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_STARTED_AT_DESC',
  EmaEntriesBySubjectIdMaxSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdMaxSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdMaxTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMaxTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMaxTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TESTY_ASC',
  EmaEntriesBySubjectIdMaxTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TESTY_DESC',
  EmaEntriesBySubjectIdMaxTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMaxTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMaxTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdMaxTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdMaxTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdMaxTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdMaxTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdMaxTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdMaxTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdMaxTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdMaxTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdMaxTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdMaxTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdMaxTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdMaxTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdMaxTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdMaxTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdMaxTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdMaxTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdMaxTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdMaxUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMaxUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMaxUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdMaxUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdMaxWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdMaxWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MAX_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdMinAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_ANGRY_ASC',
  EmaEntriesBySubjectIdMinAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_ANGRY_DESC',
  EmaEntriesBySubjectIdMinAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_ANXIOUS_ASC',
  EmaEntriesBySubjectIdMinAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_ANXIOUS_DESC',
  EmaEntriesBySubjectIdMinAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMinAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMinAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdMinAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdMinComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMinComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMinCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdMinCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdMinCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdMinCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdMinCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdMinCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdMinCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_CREATED_AT_ASC',
  EmaEntriesBySubjectIdMinCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_CREATED_AT_DESC',
  EmaEntriesBySubjectIdMinDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_DELETED_ASC',
  EmaEntriesBySubjectIdMinDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_DELETED_DESC',
  EmaEntriesBySubjectIdMinGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_GUILTY_ASC',
  EmaEntriesBySubjectIdMinGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_GUILTY_DESC',
  EmaEntriesBySubjectIdMinHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdMinHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdMinHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdMinHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdMinHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_HAPPY_ASC',
  EmaEntriesBySubjectIdMinHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_HAPPY_DESC',
  EmaEntriesBySubjectIdMinIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_ID_ASC',
  EmaEntriesBySubjectIdMinIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_ID_DESC',
  EmaEntriesBySubjectIdMinLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdMinLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdMinLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_LONELY_ASC',
  EmaEntriesBySubjectIdMinLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_LONELY_DESC',
  EmaEntriesBySubjectIdMinNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdMinNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdMinRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_RELAXED_ASC',
  EmaEntriesBySubjectIdMinRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_RELAXED_DESC',
  EmaEntriesBySubjectIdMinSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_SAD_ASC',
  EmaEntriesBySubjectIdMinSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_SAD_DESC',
  EmaEntriesBySubjectIdMinSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_SATISFIED_ASC',
  EmaEntriesBySubjectIdMinSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_SATISFIED_DESC',
  EmaEntriesBySubjectIdMinScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdMinScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdMinStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_STARTED_AT_ASC',
  EmaEntriesBySubjectIdMinStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_STARTED_AT_DESC',
  EmaEntriesBySubjectIdMinSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdMinSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdMinTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMinTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMinTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TESTY_ASC',
  EmaEntriesBySubjectIdMinTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TESTY_DESC',
  EmaEntriesBySubjectIdMinTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMinTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMinTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdMinTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdMinTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdMinTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdMinTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdMinTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdMinTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdMinTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdMinTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdMinTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdMinTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdMinTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdMinTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdMinTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdMinTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdMinTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdMinTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdMinTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdMinUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdMinUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdMinUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdMinUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdMinWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdMinWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_MIN_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevPopulationAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_ANGRY_ASC',
  EmaEntriesBySubjectIdStddevPopulationAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_ANGRY_DESC',
  EmaEntriesBySubjectIdStddevPopulationAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_ANXIOUS_ASC',
  EmaEntriesBySubjectIdStddevPopulationAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_ANXIOUS_DESC',
  EmaEntriesBySubjectIdStddevPopulationAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevPopulationAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevPopulationAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevPopulationAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevPopulationComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevPopulationComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevPopulationCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdStddevPopulationCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdStddevPopulationCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevPopulationCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevPopulationCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdStddevPopulationCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdStddevPopulationCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  EmaEntriesBySubjectIdStddevPopulationCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  EmaEntriesBySubjectIdStddevPopulationDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_DELETED_ASC',
  EmaEntriesBySubjectIdStddevPopulationDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_DELETED_DESC',
  EmaEntriesBySubjectIdStddevPopulationGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_GUILTY_ASC',
  EmaEntriesBySubjectIdStddevPopulationGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_GUILTY_DESC',
  EmaEntriesBySubjectIdStddevPopulationHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdStddevPopulationHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdStddevPopulationHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevPopulationHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevPopulationHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_HAPPY_ASC',
  EmaEntriesBySubjectIdStddevPopulationHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_HAPPY_DESC',
  EmaEntriesBySubjectIdStddevPopulationIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_ID_ASC',
  EmaEntriesBySubjectIdStddevPopulationIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_ID_DESC',
  EmaEntriesBySubjectIdStddevPopulationLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdStddevPopulationLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdStddevPopulationLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_LONELY_ASC',
  EmaEntriesBySubjectIdStddevPopulationLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_LONELY_DESC',
  EmaEntriesBySubjectIdStddevPopulationNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdStddevPopulationNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdStddevPopulationRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_RELAXED_ASC',
  EmaEntriesBySubjectIdStddevPopulationRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_RELAXED_DESC',
  EmaEntriesBySubjectIdStddevPopulationSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_SAD_ASC',
  EmaEntriesBySubjectIdStddevPopulationSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_SAD_DESC',
  EmaEntriesBySubjectIdStddevPopulationSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_SATISFIED_ASC',
  EmaEntriesBySubjectIdStddevPopulationSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_SATISFIED_DESC',
  EmaEntriesBySubjectIdStddevPopulationScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdStddevPopulationScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdStddevPopulationStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_STARTED_AT_ASC',
  EmaEntriesBySubjectIdStddevPopulationStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_STARTED_AT_DESC',
  EmaEntriesBySubjectIdStddevPopulationSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdStddevPopulationSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdStddevPopulationTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevPopulationTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevPopulationTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TESTY_ASC',
  EmaEntriesBySubjectIdStddevPopulationTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TESTY_DESC',
  EmaEntriesBySubjectIdStddevPopulationTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevPopulationTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdStddevPopulationTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdStddevPopulationTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdStddevPopulationUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevPopulationUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevPopulationUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdStddevPopulationUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdStddevPopulationWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevPopulationWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevSampleAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_ANGRY_ASC',
  EmaEntriesBySubjectIdStddevSampleAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_ANGRY_DESC',
  EmaEntriesBySubjectIdStddevSampleAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_ANXIOUS_ASC',
  EmaEntriesBySubjectIdStddevSampleAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_ANXIOUS_DESC',
  EmaEntriesBySubjectIdStddevSampleAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevSampleAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevSampleAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevSampleAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevSampleComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevSampleComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevSampleCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdStddevSampleCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdStddevSampleCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevSampleCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevSampleCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdStddevSampleCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdStddevSampleCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  EmaEntriesBySubjectIdStddevSampleCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  EmaEntriesBySubjectIdStddevSampleDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_DELETED_ASC',
  EmaEntriesBySubjectIdStddevSampleDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_DELETED_DESC',
  EmaEntriesBySubjectIdStddevSampleGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_GUILTY_ASC',
  EmaEntriesBySubjectIdStddevSampleGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_GUILTY_DESC',
  EmaEntriesBySubjectIdStddevSampleHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdStddevSampleHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdStddevSampleHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevSampleHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevSampleHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_HAPPY_ASC',
  EmaEntriesBySubjectIdStddevSampleHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_HAPPY_DESC',
  EmaEntriesBySubjectIdStddevSampleIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_ID_ASC',
  EmaEntriesBySubjectIdStddevSampleIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_ID_DESC',
  EmaEntriesBySubjectIdStddevSampleLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdStddevSampleLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdStddevSampleLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_LONELY_ASC',
  EmaEntriesBySubjectIdStddevSampleLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_LONELY_DESC',
  EmaEntriesBySubjectIdStddevSampleNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdStddevSampleNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdStddevSampleRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_RELAXED_ASC',
  EmaEntriesBySubjectIdStddevSampleRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_RELAXED_DESC',
  EmaEntriesBySubjectIdStddevSampleSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_SAD_ASC',
  EmaEntriesBySubjectIdStddevSampleSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_SAD_DESC',
  EmaEntriesBySubjectIdStddevSampleSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_SATISFIED_ASC',
  EmaEntriesBySubjectIdStddevSampleSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_SATISFIED_DESC',
  EmaEntriesBySubjectIdStddevSampleScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdStddevSampleScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdStddevSampleStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_STARTED_AT_ASC',
  EmaEntriesBySubjectIdStddevSampleStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_STARTED_AT_DESC',
  EmaEntriesBySubjectIdStddevSampleSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdStddevSampleSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdStddevSampleTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevSampleTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevSampleTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TESTY_ASC',
  EmaEntriesBySubjectIdStddevSampleTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TESTY_DESC',
  EmaEntriesBySubjectIdStddevSampleTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevSampleTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdStddevSampleTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdStddevSampleTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdStddevSampleUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevSampleUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdStddevSampleUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdStddevSampleUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdStddevSampleWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdStddevSampleWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_STDDEV_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdSumAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_ANGRY_ASC',
  EmaEntriesBySubjectIdSumAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_ANGRY_DESC',
  EmaEntriesBySubjectIdSumAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_ANXIOUS_ASC',
  EmaEntriesBySubjectIdSumAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_ANXIOUS_DESC',
  EmaEntriesBySubjectIdSumAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdSumAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdSumAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdSumAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdSumComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdSumComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdSumCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdSumCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdSumCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdSumCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdSumCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdSumCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdSumCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_CREATED_AT_ASC',
  EmaEntriesBySubjectIdSumCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_CREATED_AT_DESC',
  EmaEntriesBySubjectIdSumDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_DELETED_ASC',
  EmaEntriesBySubjectIdSumDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_DELETED_DESC',
  EmaEntriesBySubjectIdSumGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_GUILTY_ASC',
  EmaEntriesBySubjectIdSumGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_GUILTY_DESC',
  EmaEntriesBySubjectIdSumHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdSumHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdSumHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdSumHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdSumHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_HAPPY_ASC',
  EmaEntriesBySubjectIdSumHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_HAPPY_DESC',
  EmaEntriesBySubjectIdSumIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_ID_ASC',
  EmaEntriesBySubjectIdSumIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_ID_DESC',
  EmaEntriesBySubjectIdSumLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdSumLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdSumLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_LONELY_ASC',
  EmaEntriesBySubjectIdSumLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_LONELY_DESC',
  EmaEntriesBySubjectIdSumNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdSumNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdSumRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_RELAXED_ASC',
  EmaEntriesBySubjectIdSumRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_RELAXED_DESC',
  EmaEntriesBySubjectIdSumSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_SAD_ASC',
  EmaEntriesBySubjectIdSumSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_SAD_DESC',
  EmaEntriesBySubjectIdSumSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_SATISFIED_ASC',
  EmaEntriesBySubjectIdSumSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_SATISFIED_DESC',
  EmaEntriesBySubjectIdSumScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdSumScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdSumStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_STARTED_AT_ASC',
  EmaEntriesBySubjectIdSumStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_STARTED_AT_DESC',
  EmaEntriesBySubjectIdSumSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdSumSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdSumTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdSumTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdSumTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TESTY_ASC',
  EmaEntriesBySubjectIdSumTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TESTY_DESC',
  EmaEntriesBySubjectIdSumTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdSumTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdSumTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdSumTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdSumTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdSumTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdSumTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdSumTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdSumTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdSumTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdSumTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdSumTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdSumTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdSumTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdSumTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdSumTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdSumTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdSumTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdSumTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdSumTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdSumUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdSumUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdSumUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdSumUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdSumWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdSumWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_SUM_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdVariancePopulationAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_ANGRY_ASC',
  EmaEntriesBySubjectIdVariancePopulationAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_ANGRY_DESC',
  EmaEntriesBySubjectIdVariancePopulationAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_ANXIOUS_ASC',
  EmaEntriesBySubjectIdVariancePopulationAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_ANXIOUS_DESC',
  EmaEntriesBySubjectIdVariancePopulationAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVariancePopulationAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVariancePopulationAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdVariancePopulationAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdVariancePopulationComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVariancePopulationComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVariancePopulationCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdVariancePopulationCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdVariancePopulationCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdVariancePopulationCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdVariancePopulationCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdVariancePopulationCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdVariancePopulationCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  EmaEntriesBySubjectIdVariancePopulationCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  EmaEntriesBySubjectIdVariancePopulationDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_DELETED_ASC',
  EmaEntriesBySubjectIdVariancePopulationDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_DELETED_DESC',
  EmaEntriesBySubjectIdVariancePopulationGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_GUILTY_ASC',
  EmaEntriesBySubjectIdVariancePopulationGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_GUILTY_DESC',
  EmaEntriesBySubjectIdVariancePopulationHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdVariancePopulationHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdVariancePopulationHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdVariancePopulationHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdVariancePopulationHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_HAPPY_ASC',
  EmaEntriesBySubjectIdVariancePopulationHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_HAPPY_DESC',
  EmaEntriesBySubjectIdVariancePopulationIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_ID_ASC',
  EmaEntriesBySubjectIdVariancePopulationIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_ID_DESC',
  EmaEntriesBySubjectIdVariancePopulationLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdVariancePopulationLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdVariancePopulationLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_LONELY_ASC',
  EmaEntriesBySubjectIdVariancePopulationLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_LONELY_DESC',
  EmaEntriesBySubjectIdVariancePopulationNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdVariancePopulationNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdVariancePopulationRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_RELAXED_ASC',
  EmaEntriesBySubjectIdVariancePopulationRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_RELAXED_DESC',
  EmaEntriesBySubjectIdVariancePopulationSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_SAD_ASC',
  EmaEntriesBySubjectIdVariancePopulationSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_SAD_DESC',
  EmaEntriesBySubjectIdVariancePopulationSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_SATISFIED_ASC',
  EmaEntriesBySubjectIdVariancePopulationSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_SATISFIED_DESC',
  EmaEntriesBySubjectIdVariancePopulationScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdVariancePopulationScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdVariancePopulationStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_STARTED_AT_ASC',
  EmaEntriesBySubjectIdVariancePopulationStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_STARTED_AT_DESC',
  EmaEntriesBySubjectIdVariancePopulationSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdVariancePopulationSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdVariancePopulationTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVariancePopulationTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVariancePopulationTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TESTY_ASC',
  EmaEntriesBySubjectIdVariancePopulationTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TESTY_DESC',
  EmaEntriesBySubjectIdVariancePopulationTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVariancePopulationTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdVariancePopulationTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdVariancePopulationTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdVariancePopulationUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVariancePopulationUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVariancePopulationUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdVariancePopulationUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdVariancePopulationWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdVariancePopulationWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_POPULATION_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdVarianceSampleAngryAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_ANGRY_ASC',
  EmaEntriesBySubjectIdVarianceSampleAngryDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_ANGRY_DESC',
  EmaEntriesBySubjectIdVarianceSampleAnxiousAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_ANXIOUS_ASC',
  EmaEntriesBySubjectIdVarianceSampleAnxiousDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_ANXIOUS_DESC',
  EmaEntriesBySubjectIdVarianceSampleAnxiousDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_ANXIOUS_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVarianceSampleAnxiousDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_ANXIOUS_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVarianceSampleAvoidedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_AVOIDED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdVarianceSampleAvoidedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_AVOIDED_SOCIAL_CONTACT_DESC',
  EmaEntriesBySubjectIdVarianceSampleComfortableDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_COMFORTABLE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVarianceSampleComfortableDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_COMFORTABLE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVarianceSampleCompletedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_COMPLETED_AT_ASC',
  EmaEntriesBySubjectIdVarianceSampleCompletedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_COMPLETED_AT_DESC',
  EmaEntriesBySubjectIdVarianceSampleCopingHelpedInTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_COPING_HELPED_IN_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdVarianceSampleCopingHelpedInTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_COPING_HELPED_IN_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdVarianceSampleCouldThinkOfNothingElseThanTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_ASC',
  EmaEntriesBySubjectIdVarianceSampleCouldThinkOfNothingElseThanTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_COULD_THINK_OF_NOTHING_ELSE_THAN_THE_SITATION_DESC',
  EmaEntriesBySubjectIdVarianceSampleCreatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  EmaEntriesBySubjectIdVarianceSampleCreatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  EmaEntriesBySubjectIdVarianceSampleDeletedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_DELETED_ASC',
  EmaEntriesBySubjectIdVarianceSampleDeletedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_DELETED_DESC',
  EmaEntriesBySubjectIdVarianceSampleGuiltyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_GUILTY_ASC',
  EmaEntriesBySubjectIdVarianceSampleGuiltyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_GUILTY_DESC',
  EmaEntriesBySubjectIdVarianceSampleHadAnyContactWithOthersSinceTheLastSurveyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_ASC',
  EmaEntriesBySubjectIdVarianceSampleHadAnyContactWithOthersSinceTheLastSurveyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAD_ANY_CONTACT_WITH_OTHERS_SINCE_THE_LAST_SURVEY_DESC',
  EmaEntriesBySubjectIdVarianceSampleHadAStressfulSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAD_A_STRESSFUL_SITUATION_ASC',
  EmaEntriesBySubjectIdVarianceSampleHadAStressfulSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAD_A_STRESSFUL_SITUATION_DESC',
  EmaEntriesBySubjectIdVarianceSampleHappyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAPPY_ASC',
  EmaEntriesBySubjectIdVarianceSampleHappyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAPPY_DESC',
  EmaEntriesBySubjectIdVarianceSampleIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_ID_ASC',
  EmaEntriesBySubjectIdVarianceSampleIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_ID_DESC',
  EmaEntriesBySubjectIdVarianceSampleLackOfSocialContactBothersMeAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_ASC',
  EmaEntriesBySubjectIdVarianceSampleLackOfSocialContactBothersMeDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_LACK_OF_SOCIAL_CONTACT_BOTHERS_ME_DESC',
  EmaEntriesBySubjectIdVarianceSampleLonelyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_LONELY_ASC',
  EmaEntriesBySubjectIdVarianceSampleLonelyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_LONELY_DESC',
  EmaEntriesBySubjectIdVarianceSampleNoSocialContactDueToOuterConditionsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_ASC',
  EmaEntriesBySubjectIdVarianceSampleNoSocialContactDueToOuterConditionsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_NO_SOCIAL_CONTACT_DUE_TO_OUTER_CONDITIONS_DESC',
  EmaEntriesBySubjectIdVarianceSampleRelaxedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_RELAXED_ASC',
  EmaEntriesBySubjectIdVarianceSampleRelaxedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_RELAXED_DESC',
  EmaEntriesBySubjectIdVarianceSampleSadAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_SAD_ASC',
  EmaEntriesBySubjectIdVarianceSampleSadDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_SAD_DESC',
  EmaEntriesBySubjectIdVarianceSampleSatisfiedAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_SATISFIED_ASC',
  EmaEntriesBySubjectIdVarianceSampleSatisfiedDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_SATISFIED_DESC',
  EmaEntriesBySubjectIdVarianceSampleScheduledAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_SCHEDULED_AT_ASC',
  EmaEntriesBySubjectIdVarianceSampleScheduledAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_SCHEDULED_AT_DESC',
  EmaEntriesBySubjectIdVarianceSampleStartedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_STARTED_AT_ASC',
  EmaEntriesBySubjectIdVarianceSampleStartedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_STARTED_AT_DESC',
  EmaEntriesBySubjectIdVarianceSampleSubjectIdAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_SUBJECT_ID_ASC',
  EmaEntriesBySubjectIdVarianceSampleSubjectIdDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_SUBJECT_ID_DESC',
  EmaEntriesBySubjectIdVarianceSampleTenseDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TENSE_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVarianceSampleTenseDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TENSE_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVarianceSampleTestyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TESTY_ASC',
  EmaEntriesBySubjectIdVarianceSampleTestyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TESTY_DESC',
  EmaEntriesBySubjectIdVarianceSampleTestyDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TESTY_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVarianceSampleTestyDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TESTY_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedNotToThinkOfTheSitationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_NOT_TO_THINK_OF_THE_SITATION_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedNotToThinkOfTheSitationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_NOT_TO_THINK_OF_THE_SITATION_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedSomethingElseToCopeWithTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedSomethingElseToCopeWithTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_SOMETHING_ELSE_TO_COPE_WITH_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedToAcceptTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_ACCEPT_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedToAcceptTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_ACCEPT_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedToAlterFeelingsAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_ALTER_FEELINGS_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedToAlterFeelingsDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_ALTER_FEELINGS_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedToCalmMyselfAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_CALM_MYSELF_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedToCalmMyselfDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_CALM_MYSELF_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedToChangeTheSituationAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_CHANGE_THE_SITUATION_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedToChangeTheSituationDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_CHANGE_THE_SITUATION_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedToFindASolutionForTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedToFindASolutionForTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_FIND_A_SOLUTION_FOR_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedToFindTheCauseOfTheProblemAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedToFindTheCauseOfTheProblemDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_FIND_THE_CAUSE_OF_THE_PROBLEM_DESC',
  EmaEntriesBySubjectIdVarianceSampleTriedToThinkDifferentlyAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_THINK_DIFFERENTLY_ASC',
  EmaEntriesBySubjectIdVarianceSampleTriedToThinkDifferentlyDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_TRIED_TO_THINK_DIFFERENTLY_DESC',
  EmaEntriesBySubjectIdVarianceSampleUnconfidentDuringContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_UNCONFIDENT_DURING_CONTACT_ASC',
  EmaEntriesBySubjectIdVarianceSampleUnconfidentDuringContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_UNCONFIDENT_DURING_CONTACT_DESC',
  EmaEntriesBySubjectIdVarianceSampleUpdatedAtAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  EmaEntriesBySubjectIdVarianceSampleUpdatedAtDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  EmaEntriesBySubjectIdVarianceSampleWouldHaveLikedSocialContactAsc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_ASC',
  EmaEntriesBySubjectIdVarianceSampleWouldHaveLikedSocialContactDesc = 'EMA_ENTRIES_BY_SUBJECT_ID_VARIANCE_SAMPLE_WOULD_HAVE_LIKED_SOCIAL_CONTACT_DESC',
  ExternalRolesAsc = 'EXTERNAL_ROLES_ASC',
  ExternalRolesDesc = 'EXTERNAL_ROLES_DESC',
  GravatarHashAsc = 'GRAVATAR_HASH_ASC',
  GravatarHashDesc = 'GRAVATAR_HASH_DESC',
  GroupsByCreatorIdAverageCanJoinAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_CAN_JOIN_ASC',
  GroupsByCreatorIdAverageCanJoinDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_CAN_JOIN_DESC',
  GroupsByCreatorIdAverageCanManageAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_CAN_MANAGE_ASC',
  GroupsByCreatorIdAverageCanManageDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_CAN_MANAGE_DESC',
  GroupsByCreatorIdAverageCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_CREATED_AT_ASC',
  GroupsByCreatorIdAverageCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_CREATED_AT_DESC',
  GroupsByCreatorIdAverageCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_CREATOR_ID_ASC',
  GroupsByCreatorIdAverageCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_CREATOR_ID_DESC',
  GroupsByCreatorIdAverageExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdAverageExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdAverageFlagsAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_FLAGS_ASC',
  GroupsByCreatorIdAverageFlagsDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_FLAGS_DESC',
  GroupsByCreatorIdAverageHomeIdAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_HOME_ID_ASC',
  GroupsByCreatorIdAverageHomeIdDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_HOME_ID_DESC',
  GroupsByCreatorIdAverageIdAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_ID_ASC',
  GroupsByCreatorIdAverageIdDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_ID_DESC',
  GroupsByCreatorIdAverageNameAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_NAME_ASC',
  GroupsByCreatorIdAverageNameDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_NAME_DESC',
  GroupsByCreatorIdAverageTenantIdAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_TENANT_ID_ASC',
  GroupsByCreatorIdAverageTenantIdDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_TENANT_ID_DESC',
  GroupsByCreatorIdAverageUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_AVERAGE_UPDATED_AT_ASC',
  GroupsByCreatorIdAverageUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_AVERAGE_UPDATED_AT_DESC',
  GroupsByCreatorIdCountAsc = 'GROUPS_BY_CREATOR_ID_COUNT_ASC',
  GroupsByCreatorIdCountDesc = 'GROUPS_BY_CREATOR_ID_COUNT_DESC',
  GroupsByCreatorIdDistinctCountCanJoinAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_CAN_JOIN_ASC',
  GroupsByCreatorIdDistinctCountCanJoinDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_CAN_JOIN_DESC',
  GroupsByCreatorIdDistinctCountCanManageAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_CAN_MANAGE_ASC',
  GroupsByCreatorIdDistinctCountCanManageDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_CAN_MANAGE_DESC',
  GroupsByCreatorIdDistinctCountCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  GroupsByCreatorIdDistinctCountCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  GroupsByCreatorIdDistinctCountCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_CREATOR_ID_ASC',
  GroupsByCreatorIdDistinctCountCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_CREATOR_ID_DESC',
  GroupsByCreatorIdDistinctCountExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdDistinctCountExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdDistinctCountFlagsAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_FLAGS_ASC',
  GroupsByCreatorIdDistinctCountFlagsDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_FLAGS_DESC',
  GroupsByCreatorIdDistinctCountHomeIdAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_HOME_ID_ASC',
  GroupsByCreatorIdDistinctCountHomeIdDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_HOME_ID_DESC',
  GroupsByCreatorIdDistinctCountIdAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_ID_ASC',
  GroupsByCreatorIdDistinctCountIdDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_ID_DESC',
  GroupsByCreatorIdDistinctCountNameAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_NAME_ASC',
  GroupsByCreatorIdDistinctCountNameDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_NAME_DESC',
  GroupsByCreatorIdDistinctCountTenantIdAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  GroupsByCreatorIdDistinctCountTenantIdDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  GroupsByCreatorIdDistinctCountUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  GroupsByCreatorIdDistinctCountUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  GroupsByCreatorIdMaxCanJoinAsc = 'GROUPS_BY_CREATOR_ID_MAX_CAN_JOIN_ASC',
  GroupsByCreatorIdMaxCanJoinDesc = 'GROUPS_BY_CREATOR_ID_MAX_CAN_JOIN_DESC',
  GroupsByCreatorIdMaxCanManageAsc = 'GROUPS_BY_CREATOR_ID_MAX_CAN_MANAGE_ASC',
  GroupsByCreatorIdMaxCanManageDesc = 'GROUPS_BY_CREATOR_ID_MAX_CAN_MANAGE_DESC',
  GroupsByCreatorIdMaxCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_MAX_CREATED_AT_ASC',
  GroupsByCreatorIdMaxCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_MAX_CREATED_AT_DESC',
  GroupsByCreatorIdMaxCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_MAX_CREATOR_ID_ASC',
  GroupsByCreatorIdMaxCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_MAX_CREATOR_ID_DESC',
  GroupsByCreatorIdMaxExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_MAX_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdMaxExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_MAX_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdMaxFlagsAsc = 'GROUPS_BY_CREATOR_ID_MAX_FLAGS_ASC',
  GroupsByCreatorIdMaxFlagsDesc = 'GROUPS_BY_CREATOR_ID_MAX_FLAGS_DESC',
  GroupsByCreatorIdMaxHomeIdAsc = 'GROUPS_BY_CREATOR_ID_MAX_HOME_ID_ASC',
  GroupsByCreatorIdMaxHomeIdDesc = 'GROUPS_BY_CREATOR_ID_MAX_HOME_ID_DESC',
  GroupsByCreatorIdMaxIdAsc = 'GROUPS_BY_CREATOR_ID_MAX_ID_ASC',
  GroupsByCreatorIdMaxIdDesc = 'GROUPS_BY_CREATOR_ID_MAX_ID_DESC',
  GroupsByCreatorIdMaxNameAsc = 'GROUPS_BY_CREATOR_ID_MAX_NAME_ASC',
  GroupsByCreatorIdMaxNameDesc = 'GROUPS_BY_CREATOR_ID_MAX_NAME_DESC',
  GroupsByCreatorIdMaxTenantIdAsc = 'GROUPS_BY_CREATOR_ID_MAX_TENANT_ID_ASC',
  GroupsByCreatorIdMaxTenantIdDesc = 'GROUPS_BY_CREATOR_ID_MAX_TENANT_ID_DESC',
  GroupsByCreatorIdMaxUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_MAX_UPDATED_AT_ASC',
  GroupsByCreatorIdMaxUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_MAX_UPDATED_AT_DESC',
  GroupsByCreatorIdMinCanJoinAsc = 'GROUPS_BY_CREATOR_ID_MIN_CAN_JOIN_ASC',
  GroupsByCreatorIdMinCanJoinDesc = 'GROUPS_BY_CREATOR_ID_MIN_CAN_JOIN_DESC',
  GroupsByCreatorIdMinCanManageAsc = 'GROUPS_BY_CREATOR_ID_MIN_CAN_MANAGE_ASC',
  GroupsByCreatorIdMinCanManageDesc = 'GROUPS_BY_CREATOR_ID_MIN_CAN_MANAGE_DESC',
  GroupsByCreatorIdMinCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_MIN_CREATED_AT_ASC',
  GroupsByCreatorIdMinCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_MIN_CREATED_AT_DESC',
  GroupsByCreatorIdMinCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_MIN_CREATOR_ID_ASC',
  GroupsByCreatorIdMinCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_MIN_CREATOR_ID_DESC',
  GroupsByCreatorIdMinExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_MIN_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdMinExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_MIN_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdMinFlagsAsc = 'GROUPS_BY_CREATOR_ID_MIN_FLAGS_ASC',
  GroupsByCreatorIdMinFlagsDesc = 'GROUPS_BY_CREATOR_ID_MIN_FLAGS_DESC',
  GroupsByCreatorIdMinHomeIdAsc = 'GROUPS_BY_CREATOR_ID_MIN_HOME_ID_ASC',
  GroupsByCreatorIdMinHomeIdDesc = 'GROUPS_BY_CREATOR_ID_MIN_HOME_ID_DESC',
  GroupsByCreatorIdMinIdAsc = 'GROUPS_BY_CREATOR_ID_MIN_ID_ASC',
  GroupsByCreatorIdMinIdDesc = 'GROUPS_BY_CREATOR_ID_MIN_ID_DESC',
  GroupsByCreatorIdMinNameAsc = 'GROUPS_BY_CREATOR_ID_MIN_NAME_ASC',
  GroupsByCreatorIdMinNameDesc = 'GROUPS_BY_CREATOR_ID_MIN_NAME_DESC',
  GroupsByCreatorIdMinTenantIdAsc = 'GROUPS_BY_CREATOR_ID_MIN_TENANT_ID_ASC',
  GroupsByCreatorIdMinTenantIdDesc = 'GROUPS_BY_CREATOR_ID_MIN_TENANT_ID_DESC',
  GroupsByCreatorIdMinUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_MIN_UPDATED_AT_ASC',
  GroupsByCreatorIdMinUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_MIN_UPDATED_AT_DESC',
  GroupsByCreatorIdStddevPopulationCanJoinAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_CAN_JOIN_ASC',
  GroupsByCreatorIdStddevPopulationCanJoinDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_CAN_JOIN_DESC',
  GroupsByCreatorIdStddevPopulationCanManageAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_CAN_MANAGE_ASC',
  GroupsByCreatorIdStddevPopulationCanManageDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_CAN_MANAGE_DESC',
  GroupsByCreatorIdStddevPopulationCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  GroupsByCreatorIdStddevPopulationCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  GroupsByCreatorIdStddevPopulationCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_CREATOR_ID_ASC',
  GroupsByCreatorIdStddevPopulationCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_CREATOR_ID_DESC',
  GroupsByCreatorIdStddevPopulationExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdStddevPopulationExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdStddevPopulationFlagsAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_FLAGS_ASC',
  GroupsByCreatorIdStddevPopulationFlagsDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_FLAGS_DESC',
  GroupsByCreatorIdStddevPopulationHomeIdAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_HOME_ID_ASC',
  GroupsByCreatorIdStddevPopulationHomeIdDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_HOME_ID_DESC',
  GroupsByCreatorIdStddevPopulationIdAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_ID_ASC',
  GroupsByCreatorIdStddevPopulationIdDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_ID_DESC',
  GroupsByCreatorIdStddevPopulationNameAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_NAME_ASC',
  GroupsByCreatorIdStddevPopulationNameDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_NAME_DESC',
  GroupsByCreatorIdStddevPopulationTenantIdAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  GroupsByCreatorIdStddevPopulationTenantIdDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  GroupsByCreatorIdStddevPopulationUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  GroupsByCreatorIdStddevPopulationUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  GroupsByCreatorIdStddevSampleCanJoinAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_CAN_JOIN_ASC',
  GroupsByCreatorIdStddevSampleCanJoinDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_CAN_JOIN_DESC',
  GroupsByCreatorIdStddevSampleCanManageAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_CAN_MANAGE_ASC',
  GroupsByCreatorIdStddevSampleCanManageDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_CAN_MANAGE_DESC',
  GroupsByCreatorIdStddevSampleCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  GroupsByCreatorIdStddevSampleCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  GroupsByCreatorIdStddevSampleCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_CREATOR_ID_ASC',
  GroupsByCreatorIdStddevSampleCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_CREATOR_ID_DESC',
  GroupsByCreatorIdStddevSampleExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdStddevSampleExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdStddevSampleFlagsAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_FLAGS_ASC',
  GroupsByCreatorIdStddevSampleFlagsDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_FLAGS_DESC',
  GroupsByCreatorIdStddevSampleHomeIdAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_HOME_ID_ASC',
  GroupsByCreatorIdStddevSampleHomeIdDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_HOME_ID_DESC',
  GroupsByCreatorIdStddevSampleIdAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_ID_ASC',
  GroupsByCreatorIdStddevSampleIdDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_ID_DESC',
  GroupsByCreatorIdStddevSampleNameAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_NAME_ASC',
  GroupsByCreatorIdStddevSampleNameDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_NAME_DESC',
  GroupsByCreatorIdStddevSampleTenantIdAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  GroupsByCreatorIdStddevSampleTenantIdDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  GroupsByCreatorIdStddevSampleUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  GroupsByCreatorIdStddevSampleUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  GroupsByCreatorIdSumCanJoinAsc = 'GROUPS_BY_CREATOR_ID_SUM_CAN_JOIN_ASC',
  GroupsByCreatorIdSumCanJoinDesc = 'GROUPS_BY_CREATOR_ID_SUM_CAN_JOIN_DESC',
  GroupsByCreatorIdSumCanManageAsc = 'GROUPS_BY_CREATOR_ID_SUM_CAN_MANAGE_ASC',
  GroupsByCreatorIdSumCanManageDesc = 'GROUPS_BY_CREATOR_ID_SUM_CAN_MANAGE_DESC',
  GroupsByCreatorIdSumCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_SUM_CREATED_AT_ASC',
  GroupsByCreatorIdSumCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_SUM_CREATED_AT_DESC',
  GroupsByCreatorIdSumCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_SUM_CREATOR_ID_ASC',
  GroupsByCreatorIdSumCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_SUM_CREATOR_ID_DESC',
  GroupsByCreatorIdSumExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_SUM_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdSumExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_SUM_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdSumFlagsAsc = 'GROUPS_BY_CREATOR_ID_SUM_FLAGS_ASC',
  GroupsByCreatorIdSumFlagsDesc = 'GROUPS_BY_CREATOR_ID_SUM_FLAGS_DESC',
  GroupsByCreatorIdSumHomeIdAsc = 'GROUPS_BY_CREATOR_ID_SUM_HOME_ID_ASC',
  GroupsByCreatorIdSumHomeIdDesc = 'GROUPS_BY_CREATOR_ID_SUM_HOME_ID_DESC',
  GroupsByCreatorIdSumIdAsc = 'GROUPS_BY_CREATOR_ID_SUM_ID_ASC',
  GroupsByCreatorIdSumIdDesc = 'GROUPS_BY_CREATOR_ID_SUM_ID_DESC',
  GroupsByCreatorIdSumNameAsc = 'GROUPS_BY_CREATOR_ID_SUM_NAME_ASC',
  GroupsByCreatorIdSumNameDesc = 'GROUPS_BY_CREATOR_ID_SUM_NAME_DESC',
  GroupsByCreatorIdSumTenantIdAsc = 'GROUPS_BY_CREATOR_ID_SUM_TENANT_ID_ASC',
  GroupsByCreatorIdSumTenantIdDesc = 'GROUPS_BY_CREATOR_ID_SUM_TENANT_ID_DESC',
  GroupsByCreatorIdSumUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_SUM_UPDATED_AT_ASC',
  GroupsByCreatorIdSumUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_SUM_UPDATED_AT_DESC',
  GroupsByCreatorIdVariancePopulationCanJoinAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_CAN_JOIN_ASC',
  GroupsByCreatorIdVariancePopulationCanJoinDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_CAN_JOIN_DESC',
  GroupsByCreatorIdVariancePopulationCanManageAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_CAN_MANAGE_ASC',
  GroupsByCreatorIdVariancePopulationCanManageDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_CAN_MANAGE_DESC',
  GroupsByCreatorIdVariancePopulationCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  GroupsByCreatorIdVariancePopulationCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  GroupsByCreatorIdVariancePopulationCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_CREATOR_ID_ASC',
  GroupsByCreatorIdVariancePopulationCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_CREATOR_ID_DESC',
  GroupsByCreatorIdVariancePopulationExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdVariancePopulationExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdVariancePopulationFlagsAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_FLAGS_ASC',
  GroupsByCreatorIdVariancePopulationFlagsDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_FLAGS_DESC',
  GroupsByCreatorIdVariancePopulationHomeIdAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_HOME_ID_ASC',
  GroupsByCreatorIdVariancePopulationHomeIdDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_HOME_ID_DESC',
  GroupsByCreatorIdVariancePopulationIdAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_ID_ASC',
  GroupsByCreatorIdVariancePopulationIdDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_ID_DESC',
  GroupsByCreatorIdVariancePopulationNameAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_NAME_ASC',
  GroupsByCreatorIdVariancePopulationNameDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_NAME_DESC',
  GroupsByCreatorIdVariancePopulationTenantIdAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  GroupsByCreatorIdVariancePopulationTenantIdDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  GroupsByCreatorIdVariancePopulationUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  GroupsByCreatorIdVariancePopulationUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  GroupsByCreatorIdVarianceSampleCanJoinAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_CAN_JOIN_ASC',
  GroupsByCreatorIdVarianceSampleCanJoinDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_CAN_JOIN_DESC',
  GroupsByCreatorIdVarianceSampleCanManageAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_CAN_MANAGE_ASC',
  GroupsByCreatorIdVarianceSampleCanManageDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_CAN_MANAGE_DESC',
  GroupsByCreatorIdVarianceSampleCreatedAtAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  GroupsByCreatorIdVarianceSampleCreatedAtDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  GroupsByCreatorIdVarianceSampleCreatorIdAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_CREATOR_ID_ASC',
  GroupsByCreatorIdVarianceSampleCreatorIdDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_CREATOR_ID_DESC',
  GroupsByCreatorIdVarianceSampleExternalNamesAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_EXTERNAL_NAMES_ASC',
  GroupsByCreatorIdVarianceSampleExternalNamesDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_EXTERNAL_NAMES_DESC',
  GroupsByCreatorIdVarianceSampleFlagsAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_FLAGS_ASC',
  GroupsByCreatorIdVarianceSampleFlagsDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_FLAGS_DESC',
  GroupsByCreatorIdVarianceSampleHomeIdAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_HOME_ID_ASC',
  GroupsByCreatorIdVarianceSampleHomeIdDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_HOME_ID_DESC',
  GroupsByCreatorIdVarianceSampleIdAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_ID_ASC',
  GroupsByCreatorIdVarianceSampleIdDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_ID_DESC',
  GroupsByCreatorIdVarianceSampleNameAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_NAME_ASC',
  GroupsByCreatorIdVarianceSampleNameDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_NAME_DESC',
  GroupsByCreatorIdVarianceSampleTenantIdAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  GroupsByCreatorIdVarianceSampleTenantIdDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  GroupsByCreatorIdVarianceSampleUpdatedAtAsc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  GroupsByCreatorIdVarianceSampleUpdatedAtDesc = 'GROUPS_BY_CREATOR_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsTenantAdminAsc = 'IS_TENANT_ADMIN_ASC',
  IsTenantAdminDesc = 'IS_TENANT_ADMIN_DESC',
  MembershipsAverageCreatedAtAsc = 'MEMBERSHIPS_AVERAGE_CREATED_AT_ASC',
  MembershipsAverageCreatedAtDesc = 'MEMBERSHIPS_AVERAGE_CREATED_AT_DESC',
  MembershipsAverageFlagsAsc = 'MEMBERSHIPS_AVERAGE_FLAGS_ASC',
  MembershipsAverageFlagsDesc = 'MEMBERSHIPS_AVERAGE_FLAGS_DESC',
  MembershipsAverageGroupIdAsc = 'MEMBERSHIPS_AVERAGE_GROUP_ID_ASC',
  MembershipsAverageGroupIdDesc = 'MEMBERSHIPS_AVERAGE_GROUP_ID_DESC',
  MembershipsAverageIdAsc = 'MEMBERSHIPS_AVERAGE_ID_ASC',
  MembershipsAverageIdDesc = 'MEMBERSHIPS_AVERAGE_ID_DESC',
  MembershipsAverageIsManagedExternallyAsc = 'MEMBERSHIPS_AVERAGE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsAverageIsManagedExternallyDesc = 'MEMBERSHIPS_AVERAGE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsAverageRoleAsc = 'MEMBERSHIPS_AVERAGE_ROLE_ASC',
  MembershipsAverageRoleDesc = 'MEMBERSHIPS_AVERAGE_ROLE_DESC',
  MembershipsAverageSubgroupIdAsc = 'MEMBERSHIPS_AVERAGE_SUBGROUP_ID_ASC',
  MembershipsAverageSubgroupIdDesc = 'MEMBERSHIPS_AVERAGE_SUBGROUP_ID_DESC',
  MembershipsAverageUpdatedAtAsc = 'MEMBERSHIPS_AVERAGE_UPDATED_AT_ASC',
  MembershipsAverageUpdatedAtDesc = 'MEMBERSHIPS_AVERAGE_UPDATED_AT_DESC',
  MembershipsAverageUserIdAsc = 'MEMBERSHIPS_AVERAGE_USER_ID_ASC',
  MembershipsAverageUserIdDesc = 'MEMBERSHIPS_AVERAGE_USER_ID_DESC',
  MembershipsCountAsc = 'MEMBERSHIPS_COUNT_ASC',
  MembershipsCountDesc = 'MEMBERSHIPS_COUNT_DESC',
  MembershipsDistinctCountCreatedAtAsc = 'MEMBERSHIPS_DISTINCT_COUNT_CREATED_AT_ASC',
  MembershipsDistinctCountCreatedAtDesc = 'MEMBERSHIPS_DISTINCT_COUNT_CREATED_AT_DESC',
  MembershipsDistinctCountFlagsAsc = 'MEMBERSHIPS_DISTINCT_COUNT_FLAGS_ASC',
  MembershipsDistinctCountFlagsDesc = 'MEMBERSHIPS_DISTINCT_COUNT_FLAGS_DESC',
  MembershipsDistinctCountGroupIdAsc = 'MEMBERSHIPS_DISTINCT_COUNT_GROUP_ID_ASC',
  MembershipsDistinctCountGroupIdDesc = 'MEMBERSHIPS_DISTINCT_COUNT_GROUP_ID_DESC',
  MembershipsDistinctCountIdAsc = 'MEMBERSHIPS_DISTINCT_COUNT_ID_ASC',
  MembershipsDistinctCountIdDesc = 'MEMBERSHIPS_DISTINCT_COUNT_ID_DESC',
  MembershipsDistinctCountIsManagedExternallyAsc = 'MEMBERSHIPS_DISTINCT_COUNT_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsDistinctCountIsManagedExternallyDesc = 'MEMBERSHIPS_DISTINCT_COUNT_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsDistinctCountRoleAsc = 'MEMBERSHIPS_DISTINCT_COUNT_ROLE_ASC',
  MembershipsDistinctCountRoleDesc = 'MEMBERSHIPS_DISTINCT_COUNT_ROLE_DESC',
  MembershipsDistinctCountSubgroupIdAsc = 'MEMBERSHIPS_DISTINCT_COUNT_SUBGROUP_ID_ASC',
  MembershipsDistinctCountSubgroupIdDesc = 'MEMBERSHIPS_DISTINCT_COUNT_SUBGROUP_ID_DESC',
  MembershipsDistinctCountUpdatedAtAsc = 'MEMBERSHIPS_DISTINCT_COUNT_UPDATED_AT_ASC',
  MembershipsDistinctCountUpdatedAtDesc = 'MEMBERSHIPS_DISTINCT_COUNT_UPDATED_AT_DESC',
  MembershipsDistinctCountUserIdAsc = 'MEMBERSHIPS_DISTINCT_COUNT_USER_ID_ASC',
  MembershipsDistinctCountUserIdDesc = 'MEMBERSHIPS_DISTINCT_COUNT_USER_ID_DESC',
  MembershipsMaxCreatedAtAsc = 'MEMBERSHIPS_MAX_CREATED_AT_ASC',
  MembershipsMaxCreatedAtDesc = 'MEMBERSHIPS_MAX_CREATED_AT_DESC',
  MembershipsMaxFlagsAsc = 'MEMBERSHIPS_MAX_FLAGS_ASC',
  MembershipsMaxFlagsDesc = 'MEMBERSHIPS_MAX_FLAGS_DESC',
  MembershipsMaxGroupIdAsc = 'MEMBERSHIPS_MAX_GROUP_ID_ASC',
  MembershipsMaxGroupIdDesc = 'MEMBERSHIPS_MAX_GROUP_ID_DESC',
  MembershipsMaxIdAsc = 'MEMBERSHIPS_MAX_ID_ASC',
  MembershipsMaxIdDesc = 'MEMBERSHIPS_MAX_ID_DESC',
  MembershipsMaxIsManagedExternallyAsc = 'MEMBERSHIPS_MAX_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsMaxIsManagedExternallyDesc = 'MEMBERSHIPS_MAX_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsMaxRoleAsc = 'MEMBERSHIPS_MAX_ROLE_ASC',
  MembershipsMaxRoleDesc = 'MEMBERSHIPS_MAX_ROLE_DESC',
  MembershipsMaxSubgroupIdAsc = 'MEMBERSHIPS_MAX_SUBGROUP_ID_ASC',
  MembershipsMaxSubgroupIdDesc = 'MEMBERSHIPS_MAX_SUBGROUP_ID_DESC',
  MembershipsMaxUpdatedAtAsc = 'MEMBERSHIPS_MAX_UPDATED_AT_ASC',
  MembershipsMaxUpdatedAtDesc = 'MEMBERSHIPS_MAX_UPDATED_AT_DESC',
  MembershipsMaxUserIdAsc = 'MEMBERSHIPS_MAX_USER_ID_ASC',
  MembershipsMaxUserIdDesc = 'MEMBERSHIPS_MAX_USER_ID_DESC',
  MembershipsMinCreatedAtAsc = 'MEMBERSHIPS_MIN_CREATED_AT_ASC',
  MembershipsMinCreatedAtDesc = 'MEMBERSHIPS_MIN_CREATED_AT_DESC',
  MembershipsMinFlagsAsc = 'MEMBERSHIPS_MIN_FLAGS_ASC',
  MembershipsMinFlagsDesc = 'MEMBERSHIPS_MIN_FLAGS_DESC',
  MembershipsMinGroupIdAsc = 'MEMBERSHIPS_MIN_GROUP_ID_ASC',
  MembershipsMinGroupIdDesc = 'MEMBERSHIPS_MIN_GROUP_ID_DESC',
  MembershipsMinIdAsc = 'MEMBERSHIPS_MIN_ID_ASC',
  MembershipsMinIdDesc = 'MEMBERSHIPS_MIN_ID_DESC',
  MembershipsMinIsManagedExternallyAsc = 'MEMBERSHIPS_MIN_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsMinIsManagedExternallyDesc = 'MEMBERSHIPS_MIN_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsMinRoleAsc = 'MEMBERSHIPS_MIN_ROLE_ASC',
  MembershipsMinRoleDesc = 'MEMBERSHIPS_MIN_ROLE_DESC',
  MembershipsMinSubgroupIdAsc = 'MEMBERSHIPS_MIN_SUBGROUP_ID_ASC',
  MembershipsMinSubgroupIdDesc = 'MEMBERSHIPS_MIN_SUBGROUP_ID_DESC',
  MembershipsMinUpdatedAtAsc = 'MEMBERSHIPS_MIN_UPDATED_AT_ASC',
  MembershipsMinUpdatedAtDesc = 'MEMBERSHIPS_MIN_UPDATED_AT_DESC',
  MembershipsMinUserIdAsc = 'MEMBERSHIPS_MIN_USER_ID_ASC',
  MembershipsMinUserIdDesc = 'MEMBERSHIPS_MIN_USER_ID_DESC',
  MembershipsStddevPopulationCreatedAtAsc = 'MEMBERSHIPS_STDDEV_POPULATION_CREATED_AT_ASC',
  MembershipsStddevPopulationCreatedAtDesc = 'MEMBERSHIPS_STDDEV_POPULATION_CREATED_AT_DESC',
  MembershipsStddevPopulationFlagsAsc = 'MEMBERSHIPS_STDDEV_POPULATION_FLAGS_ASC',
  MembershipsStddevPopulationFlagsDesc = 'MEMBERSHIPS_STDDEV_POPULATION_FLAGS_DESC',
  MembershipsStddevPopulationGroupIdAsc = 'MEMBERSHIPS_STDDEV_POPULATION_GROUP_ID_ASC',
  MembershipsStddevPopulationGroupIdDesc = 'MEMBERSHIPS_STDDEV_POPULATION_GROUP_ID_DESC',
  MembershipsStddevPopulationIdAsc = 'MEMBERSHIPS_STDDEV_POPULATION_ID_ASC',
  MembershipsStddevPopulationIdDesc = 'MEMBERSHIPS_STDDEV_POPULATION_ID_DESC',
  MembershipsStddevPopulationIsManagedExternallyAsc = 'MEMBERSHIPS_STDDEV_POPULATION_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsStddevPopulationIsManagedExternallyDesc = 'MEMBERSHIPS_STDDEV_POPULATION_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsStddevPopulationRoleAsc = 'MEMBERSHIPS_STDDEV_POPULATION_ROLE_ASC',
  MembershipsStddevPopulationRoleDesc = 'MEMBERSHIPS_STDDEV_POPULATION_ROLE_DESC',
  MembershipsStddevPopulationSubgroupIdAsc = 'MEMBERSHIPS_STDDEV_POPULATION_SUBGROUP_ID_ASC',
  MembershipsStddevPopulationSubgroupIdDesc = 'MEMBERSHIPS_STDDEV_POPULATION_SUBGROUP_ID_DESC',
  MembershipsStddevPopulationUpdatedAtAsc = 'MEMBERSHIPS_STDDEV_POPULATION_UPDATED_AT_ASC',
  MembershipsStddevPopulationUpdatedAtDesc = 'MEMBERSHIPS_STDDEV_POPULATION_UPDATED_AT_DESC',
  MembershipsStddevPopulationUserIdAsc = 'MEMBERSHIPS_STDDEV_POPULATION_USER_ID_ASC',
  MembershipsStddevPopulationUserIdDesc = 'MEMBERSHIPS_STDDEV_POPULATION_USER_ID_DESC',
  MembershipsStddevSampleCreatedAtAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_CREATED_AT_ASC',
  MembershipsStddevSampleCreatedAtDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_CREATED_AT_DESC',
  MembershipsStddevSampleFlagsAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_FLAGS_ASC',
  MembershipsStddevSampleFlagsDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_FLAGS_DESC',
  MembershipsStddevSampleGroupIdAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_GROUP_ID_ASC',
  MembershipsStddevSampleGroupIdDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_GROUP_ID_DESC',
  MembershipsStddevSampleIdAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_ID_ASC',
  MembershipsStddevSampleIdDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_ID_DESC',
  MembershipsStddevSampleIsManagedExternallyAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsStddevSampleIsManagedExternallyDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsStddevSampleRoleAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_ROLE_ASC',
  MembershipsStddevSampleRoleDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_ROLE_DESC',
  MembershipsStddevSampleSubgroupIdAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_SUBGROUP_ID_ASC',
  MembershipsStddevSampleSubgroupIdDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_SUBGROUP_ID_DESC',
  MembershipsStddevSampleUpdatedAtAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_AT_ASC',
  MembershipsStddevSampleUpdatedAtDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_AT_DESC',
  MembershipsStddevSampleUserIdAsc = 'MEMBERSHIPS_STDDEV_SAMPLE_USER_ID_ASC',
  MembershipsStddevSampleUserIdDesc = 'MEMBERSHIPS_STDDEV_SAMPLE_USER_ID_DESC',
  MembershipsSumCreatedAtAsc = 'MEMBERSHIPS_SUM_CREATED_AT_ASC',
  MembershipsSumCreatedAtDesc = 'MEMBERSHIPS_SUM_CREATED_AT_DESC',
  MembershipsSumFlagsAsc = 'MEMBERSHIPS_SUM_FLAGS_ASC',
  MembershipsSumFlagsDesc = 'MEMBERSHIPS_SUM_FLAGS_DESC',
  MembershipsSumGroupIdAsc = 'MEMBERSHIPS_SUM_GROUP_ID_ASC',
  MembershipsSumGroupIdDesc = 'MEMBERSHIPS_SUM_GROUP_ID_DESC',
  MembershipsSumIdAsc = 'MEMBERSHIPS_SUM_ID_ASC',
  MembershipsSumIdDesc = 'MEMBERSHIPS_SUM_ID_DESC',
  MembershipsSumIsManagedExternallyAsc = 'MEMBERSHIPS_SUM_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsSumIsManagedExternallyDesc = 'MEMBERSHIPS_SUM_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsSumRoleAsc = 'MEMBERSHIPS_SUM_ROLE_ASC',
  MembershipsSumRoleDesc = 'MEMBERSHIPS_SUM_ROLE_DESC',
  MembershipsSumSubgroupIdAsc = 'MEMBERSHIPS_SUM_SUBGROUP_ID_ASC',
  MembershipsSumSubgroupIdDesc = 'MEMBERSHIPS_SUM_SUBGROUP_ID_DESC',
  MembershipsSumUpdatedAtAsc = 'MEMBERSHIPS_SUM_UPDATED_AT_ASC',
  MembershipsSumUpdatedAtDesc = 'MEMBERSHIPS_SUM_UPDATED_AT_DESC',
  MembershipsSumUserIdAsc = 'MEMBERSHIPS_SUM_USER_ID_ASC',
  MembershipsSumUserIdDesc = 'MEMBERSHIPS_SUM_USER_ID_DESC',
  MembershipsVariancePopulationCreatedAtAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_CREATED_AT_ASC',
  MembershipsVariancePopulationCreatedAtDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_CREATED_AT_DESC',
  MembershipsVariancePopulationFlagsAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_FLAGS_ASC',
  MembershipsVariancePopulationFlagsDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_FLAGS_DESC',
  MembershipsVariancePopulationGroupIdAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_GROUP_ID_ASC',
  MembershipsVariancePopulationGroupIdDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_GROUP_ID_DESC',
  MembershipsVariancePopulationIdAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_ID_ASC',
  MembershipsVariancePopulationIdDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_ID_DESC',
  MembershipsVariancePopulationIsManagedExternallyAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsVariancePopulationIsManagedExternallyDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsVariancePopulationRoleAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_ROLE_ASC',
  MembershipsVariancePopulationRoleDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_ROLE_DESC',
  MembershipsVariancePopulationSubgroupIdAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_SUBGROUP_ID_ASC',
  MembershipsVariancePopulationSubgroupIdDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_SUBGROUP_ID_DESC',
  MembershipsVariancePopulationUpdatedAtAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_AT_ASC',
  MembershipsVariancePopulationUpdatedAtDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_AT_DESC',
  MembershipsVariancePopulationUserIdAsc = 'MEMBERSHIPS_VARIANCE_POPULATION_USER_ID_ASC',
  MembershipsVariancePopulationUserIdDesc = 'MEMBERSHIPS_VARIANCE_POPULATION_USER_ID_DESC',
  MembershipsVarianceSampleCreatedAtAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_AT_ASC',
  MembershipsVarianceSampleCreatedAtDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_AT_DESC',
  MembershipsVarianceSampleFlagsAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_FLAGS_ASC',
  MembershipsVarianceSampleFlagsDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_FLAGS_DESC',
  MembershipsVarianceSampleGroupIdAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_GROUP_ID_ASC',
  MembershipsVarianceSampleGroupIdDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_GROUP_ID_DESC',
  MembershipsVarianceSampleIdAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_ID_ASC',
  MembershipsVarianceSampleIdDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_ID_DESC',
  MembershipsVarianceSampleIsManagedExternallyAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_IS_MANAGED_EXTERNALLY_ASC',
  MembershipsVarianceSampleIsManagedExternallyDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_IS_MANAGED_EXTERNALLY_DESC',
  MembershipsVarianceSampleRoleAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_ROLE_ASC',
  MembershipsVarianceSampleRoleDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_ROLE_DESC',
  MembershipsVarianceSampleSubgroupIdAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_SUBGROUP_ID_ASC',
  MembershipsVarianceSampleSubgroupIdDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_SUBGROUP_ID_DESC',
  MembershipsVarianceSampleUpdatedAtAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  MembershipsVarianceSampleUpdatedAtDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  MembershipsVarianceSampleUserIdAsc = 'MEMBERSHIPS_VARIANCE_SAMPLE_USER_ID_ASC',
  MembershipsVarianceSampleUserIdDesc = 'MEMBERSHIPS_VARIANCE_SAMPLE_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdAverageAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdAverageAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdAverageAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdAverageAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdAverageDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdAverageDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdAverageGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdAverageGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdAverageIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_ID_ASC',
  MembershipInvitationsByInvitedUserIdAverageIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_ID_DESC',
  MembershipInvitationsByInvitedUserIdAverageInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdAverageInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdAverageInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdAverageInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdAverageInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdAverageInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdAverageInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdAverageInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdAverageInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdAverageInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdAverageMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdAverageMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdAverageNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdAverageNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdAverageValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdAverageValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_AVERAGE_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedUserIdCountAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_COUNT_ASC',
  MembershipInvitationsByInvitedUserIdCountDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_COUNT_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_ID_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_ID_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdDistinctCountValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdDistinctCountValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_DISTINCT_COUNT_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedUserIdMaxAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdMaxAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdMaxAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdMaxAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdMaxDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdMaxDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdMaxGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdMaxGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdMaxIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_ID_ASC',
  MembershipInvitationsByInvitedUserIdMaxIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_ID_DESC',
  MembershipInvitationsByInvitedUserIdMaxInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdMaxInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdMaxInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdMaxInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdMaxInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdMaxInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdMaxInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdMaxInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdMaxInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdMaxInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdMaxMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdMaxMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdMaxNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdMaxNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdMaxValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdMaxValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MAX_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedUserIdMinAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdMinAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdMinAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdMinAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdMinDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdMinDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdMinGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdMinGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdMinIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_ID_ASC',
  MembershipInvitationsByInvitedUserIdMinIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_ID_DESC',
  MembershipInvitationsByInvitedUserIdMinInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdMinInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdMinInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdMinInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdMinInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdMinInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdMinInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdMinInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdMinInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdMinInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdMinMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdMinMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdMinNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdMinNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdMinValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdMinValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_MIN_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdStddevPopulationValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdStddevPopulationValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_POPULATION_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdStddevSampleValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdStddevSampleValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_STDDEV_SAMPLE_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedUserIdSumAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdSumAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdSumAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdSumAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdSumDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdSumDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdSumGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdSumGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdSumIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_ID_ASC',
  MembershipInvitationsByInvitedUserIdSumIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_ID_DESC',
  MembershipInvitationsByInvitedUserIdSumInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdSumInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdSumInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdSumInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdSumInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdSumInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdSumInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdSumInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdSumInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdSumInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdSumMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdSumMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdSumNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdSumNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdSumValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdSumValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_SUM_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_ID_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_ID_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdVariancePopulationValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdVariancePopulationValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_POPULATION_VALID_UNTIL_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_ID_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_ID_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITED_AS_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITED_AS_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITED_AT_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITED_AT_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITER_ID_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_INVITER_ID_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_NOTE_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_NOTE_DESC',
  MembershipInvitationsByInvitedUserIdVarianceSampleValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_VALID_UNTIL_ASC',
  MembershipInvitationsByInvitedUserIdVarianceSampleValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITED_USER_ID_VARIANCE_SAMPLE_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdAverageAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdAverageAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdAverageAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdAverageAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdAverageDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdAverageDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdAverageGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdAverageGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdAverageIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_ID_ASC',
  MembershipInvitationsByInviterIdAverageIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_ID_DESC',
  MembershipInvitationsByInviterIdAverageInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdAverageInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdAverageInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdAverageInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdAverageInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdAverageInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdAverageInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdAverageInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdAverageInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdAverageInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdAverageMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdAverageMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdAverageNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_NOTE_ASC',
  MembershipInvitationsByInviterIdAverageNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_NOTE_DESC',
  MembershipInvitationsByInviterIdAverageValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdAverageValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_AVERAGE_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdCountAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_COUNT_ASC',
  MembershipInvitationsByInviterIdCountDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_COUNT_DESC',
  MembershipInvitationsByInviterIdDistinctCountAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdDistinctCountAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdDistinctCountAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdDistinctCountAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdDistinctCountDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdDistinctCountDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdDistinctCountGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdDistinctCountGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdDistinctCountIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_ID_ASC',
  MembershipInvitationsByInviterIdDistinctCountIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_ID_DESC',
  MembershipInvitationsByInviterIdDistinctCountInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdDistinctCountInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdDistinctCountInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdDistinctCountInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdDistinctCountInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdDistinctCountInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdDistinctCountInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdDistinctCountInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdDistinctCountInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdDistinctCountInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdDistinctCountMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdDistinctCountMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdDistinctCountNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_NOTE_ASC',
  MembershipInvitationsByInviterIdDistinctCountNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_NOTE_DESC',
  MembershipInvitationsByInviterIdDistinctCountValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdDistinctCountValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_DISTINCT_COUNT_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdMaxAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdMaxAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdMaxAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdMaxAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdMaxDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdMaxDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdMaxGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdMaxGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdMaxIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_ID_ASC',
  MembershipInvitationsByInviterIdMaxIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_ID_DESC',
  MembershipInvitationsByInviterIdMaxInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdMaxInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdMaxInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdMaxInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdMaxInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdMaxInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdMaxInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdMaxInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdMaxInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdMaxInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdMaxMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdMaxMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdMaxNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_NOTE_ASC',
  MembershipInvitationsByInviterIdMaxNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_NOTE_DESC',
  MembershipInvitationsByInviterIdMaxValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdMaxValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MAX_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdMinAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdMinAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdMinAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdMinAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdMinDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdMinDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdMinGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdMinGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdMinIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_ID_ASC',
  MembershipInvitationsByInviterIdMinIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_ID_DESC',
  MembershipInvitationsByInviterIdMinInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdMinInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdMinInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdMinInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdMinInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdMinInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdMinInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdMinInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdMinInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdMinInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdMinMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdMinMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdMinNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_NOTE_ASC',
  MembershipInvitationsByInviterIdMinNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_NOTE_DESC',
  MembershipInvitationsByInviterIdMinValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdMinValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_MIN_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdStddevPopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdStddevPopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdStddevPopulationAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdStddevPopulationAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdStddevPopulationDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdStddevPopulationDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdStddevPopulationGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdStddevPopulationGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdStddevPopulationIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_ID_ASC',
  MembershipInvitationsByInviterIdStddevPopulationIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_ID_DESC',
  MembershipInvitationsByInviterIdStddevPopulationInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdStddevPopulationInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdStddevPopulationInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdStddevPopulationInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdStddevPopulationInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdStddevPopulationInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdStddevPopulationInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdStddevPopulationInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdStddevPopulationInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdStddevPopulationInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdStddevPopulationMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdStddevPopulationMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdStddevPopulationNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_NOTE_ASC',
  MembershipInvitationsByInviterIdStddevPopulationNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_NOTE_DESC',
  MembershipInvitationsByInviterIdStddevPopulationValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdStddevPopulationValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_POPULATION_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdStddevSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdStddevSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdStddevSampleAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdStddevSampleAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdStddevSampleDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdStddevSampleDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdStddevSampleGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdStddevSampleGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdStddevSampleIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_ID_ASC',
  MembershipInvitationsByInviterIdStddevSampleIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_ID_DESC',
  MembershipInvitationsByInviterIdStddevSampleInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdStddevSampleInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdStddevSampleInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdStddevSampleInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdStddevSampleInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdStddevSampleInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdStddevSampleInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdStddevSampleInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdStddevSampleInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdStddevSampleInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdStddevSampleMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdStddevSampleMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdStddevSampleNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_NOTE_ASC',
  MembershipInvitationsByInviterIdStddevSampleNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_NOTE_DESC',
  MembershipInvitationsByInviterIdStddevSampleValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdStddevSampleValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_STDDEV_SAMPLE_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdSumAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdSumAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdSumAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdSumAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdSumDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdSumDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdSumGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdSumGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdSumIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_ID_ASC',
  MembershipInvitationsByInviterIdSumIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_ID_DESC',
  MembershipInvitationsByInviterIdSumInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdSumInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdSumInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdSumInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdSumInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdSumInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdSumInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdSumInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdSumInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdSumInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdSumMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdSumMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdSumNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_NOTE_ASC',
  MembershipInvitationsByInviterIdSumNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_NOTE_DESC',
  MembershipInvitationsByInviterIdSumValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdSumValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_SUM_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdVariancePopulationAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdVariancePopulationAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdVariancePopulationAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdVariancePopulationAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdVariancePopulationDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdVariancePopulationDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdVariancePopulationGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdVariancePopulationGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdVariancePopulationIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_ID_ASC',
  MembershipInvitationsByInviterIdVariancePopulationIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_ID_DESC',
  MembershipInvitationsByInviterIdVariancePopulationInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdVariancePopulationInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdVariancePopulationInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdVariancePopulationInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdVariancePopulationInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdVariancePopulationInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdVariancePopulationInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdVariancePopulationInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdVariancePopulationInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdVariancePopulationInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdVariancePopulationMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdVariancePopulationMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdVariancePopulationNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_NOTE_ASC',
  MembershipInvitationsByInviterIdVariancePopulationNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_NOTE_DESC',
  MembershipInvitationsByInviterIdVariancePopulationValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdVariancePopulationValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_POPULATION_VALID_UNTIL_DESC',
  MembershipInvitationsByInviterIdVarianceSampleAcceptedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_ACCEPTED_AT_ASC',
  MembershipInvitationsByInviterIdVarianceSampleAcceptedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_ACCEPTED_AT_DESC',
  MembershipInvitationsByInviterIdVarianceSampleAlsoAppliesInSubgroupsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_ASC',
  MembershipInvitationsByInviterIdVarianceSampleAlsoAppliesInSubgroupsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_ALSO_APPLIES_IN_SUBGROUPS_DESC',
  MembershipInvitationsByInviterIdVarianceSampleDirectedToMembersAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_DIRECTED_TO_MEMBERS_ASC',
  MembershipInvitationsByInviterIdVarianceSampleDirectedToMembersDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_DIRECTED_TO_MEMBERS_DESC',
  MembershipInvitationsByInviterIdVarianceSampleGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdVarianceSampleGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdVarianceSampleIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_ID_ASC',
  MembershipInvitationsByInviterIdVarianceSampleIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_ID_DESC',
  MembershipInvitationsByInviterIdVarianceSampleInvitedAsAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITED_AS_ASC',
  MembershipInvitationsByInviterIdVarianceSampleInvitedAsDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITED_AS_DESC',
  MembershipInvitationsByInviterIdVarianceSampleInvitedAtAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITED_AT_ASC',
  MembershipInvitationsByInviterIdVarianceSampleInvitedAtDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITED_AT_DESC',
  MembershipInvitationsByInviterIdVarianceSampleInvitedGroupIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITED_GROUP_ID_ASC',
  MembershipInvitationsByInviterIdVarianceSampleInvitedGroupIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITED_GROUP_ID_DESC',
  MembershipInvitationsByInviterIdVarianceSampleInvitedUserIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITED_USER_ID_ASC',
  MembershipInvitationsByInviterIdVarianceSampleInvitedUserIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITED_USER_ID_DESC',
  MembershipInvitationsByInviterIdVarianceSampleInviterIdAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITER_ID_ASC',
  MembershipInvitationsByInviterIdVarianceSampleInviterIdDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_INVITER_ID_DESC',
  MembershipInvitationsByInviterIdVarianceSampleMaximumUsesAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_MAXIMUM_USES_ASC',
  MembershipInvitationsByInviterIdVarianceSampleMaximumUsesDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_MAXIMUM_USES_DESC',
  MembershipInvitationsByInviterIdVarianceSampleNoteAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_NOTE_ASC',
  MembershipInvitationsByInviterIdVarianceSampleNoteDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_NOTE_DESC',
  MembershipInvitationsByInviterIdVarianceSampleValidUntilAsc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_VALID_UNTIL_ASC',
  MembershipInvitationsByInviterIdVarianceSampleValidUntilDesc = 'MEMBERSHIP_INVITATIONS_BY_INVITER_ID_VARIANCE_SAMPLE_VALID_UNTIL_DESC',
  Natural = 'NATURAL',
  PostingsBySenderIdAverageAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdAverageAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdAverageAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdAverageAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdAverageAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdAverageAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdAverageBodyAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_BODY_ASC',
  PostingsBySenderIdAverageBodyDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_BODY_DESC',
  PostingsBySenderIdAverageConversationIdAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_CONVERSATION_ID_ASC',
  PostingsBySenderIdAverageConversationIdDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_CONVERSATION_ID_DESC',
  PostingsBySenderIdAverageCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_CREATED_AT_ASC',
  PostingsBySenderIdAverageCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_CREATED_AT_DESC',
  PostingsBySenderIdAverageHashtagsAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_HASHTAGS_ASC',
  PostingsBySenderIdAverageHashtagsDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_HASHTAGS_DESC',
  PostingsBySenderIdAverageIdAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_ID_ASC',
  PostingsBySenderIdAverageIdDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_ID_DESC',
  PostingsBySenderIdAverageLanguageAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_LANGUAGE_ASC',
  PostingsBySenderIdAverageLanguageDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_LANGUAGE_DESC',
  PostingsBySenderIdAverageMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdAverageMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdAverageSenderIdAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_SENDER_ID_ASC',
  PostingsBySenderIdAverageSenderIdDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_SENDER_ID_DESC',
  PostingsBySenderIdAverageSubjectAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_SUBJECT_ASC',
  PostingsBySenderIdAverageSubjectDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_SUBJECT_DESC',
  PostingsBySenderIdAverageSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_SUBMITTED_AT_ASC',
  PostingsBySenderIdAverageSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_SUBMITTED_AT_DESC',
  PostingsBySenderIdAverageUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_AVERAGE_UPDATED_AT_ASC',
  PostingsBySenderIdAverageUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_AVERAGE_UPDATED_AT_DESC',
  PostingsBySenderIdCountAsc = 'POSTINGS_BY_SENDER_ID_COUNT_ASC',
  PostingsBySenderIdCountDesc = 'POSTINGS_BY_SENDER_ID_COUNT_DESC',
  PostingsBySenderIdDistinctCountAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdDistinctCountAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdDistinctCountAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdDistinctCountAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdDistinctCountAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdDistinctCountAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdDistinctCountBodyAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_BODY_ASC',
  PostingsBySenderIdDistinctCountBodyDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_BODY_DESC',
  PostingsBySenderIdDistinctCountConversationIdAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_CONVERSATION_ID_ASC',
  PostingsBySenderIdDistinctCountConversationIdDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_CONVERSATION_ID_DESC',
  PostingsBySenderIdDistinctCountCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  PostingsBySenderIdDistinctCountCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  PostingsBySenderIdDistinctCountHashtagsAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_HASHTAGS_ASC',
  PostingsBySenderIdDistinctCountHashtagsDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_HASHTAGS_DESC',
  PostingsBySenderIdDistinctCountIdAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_ID_ASC',
  PostingsBySenderIdDistinctCountIdDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_ID_DESC',
  PostingsBySenderIdDistinctCountLanguageAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_LANGUAGE_ASC',
  PostingsBySenderIdDistinctCountLanguageDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_LANGUAGE_DESC',
  PostingsBySenderIdDistinctCountMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdDistinctCountMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdDistinctCountSenderIdAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_SENDER_ID_ASC',
  PostingsBySenderIdDistinctCountSenderIdDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_SENDER_ID_DESC',
  PostingsBySenderIdDistinctCountSubjectAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_SUBJECT_ASC',
  PostingsBySenderIdDistinctCountSubjectDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_SUBJECT_DESC',
  PostingsBySenderIdDistinctCountSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_SUBMITTED_AT_ASC',
  PostingsBySenderIdDistinctCountSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_SUBMITTED_AT_DESC',
  PostingsBySenderIdDistinctCountUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  PostingsBySenderIdDistinctCountUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  PostingsBySenderIdMaxAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_MAX_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdMaxAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_MAX_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdMaxAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_MAX_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdMaxAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_MAX_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdMaxAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_MAX_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdMaxAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_MAX_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdMaxBodyAsc = 'POSTINGS_BY_SENDER_ID_MAX_BODY_ASC',
  PostingsBySenderIdMaxBodyDesc = 'POSTINGS_BY_SENDER_ID_MAX_BODY_DESC',
  PostingsBySenderIdMaxConversationIdAsc = 'POSTINGS_BY_SENDER_ID_MAX_CONVERSATION_ID_ASC',
  PostingsBySenderIdMaxConversationIdDesc = 'POSTINGS_BY_SENDER_ID_MAX_CONVERSATION_ID_DESC',
  PostingsBySenderIdMaxCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_MAX_CREATED_AT_ASC',
  PostingsBySenderIdMaxCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_MAX_CREATED_AT_DESC',
  PostingsBySenderIdMaxHashtagsAsc = 'POSTINGS_BY_SENDER_ID_MAX_HASHTAGS_ASC',
  PostingsBySenderIdMaxHashtagsDesc = 'POSTINGS_BY_SENDER_ID_MAX_HASHTAGS_DESC',
  PostingsBySenderIdMaxIdAsc = 'POSTINGS_BY_SENDER_ID_MAX_ID_ASC',
  PostingsBySenderIdMaxIdDesc = 'POSTINGS_BY_SENDER_ID_MAX_ID_DESC',
  PostingsBySenderIdMaxLanguageAsc = 'POSTINGS_BY_SENDER_ID_MAX_LANGUAGE_ASC',
  PostingsBySenderIdMaxLanguageDesc = 'POSTINGS_BY_SENDER_ID_MAX_LANGUAGE_DESC',
  PostingsBySenderIdMaxMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_MAX_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdMaxMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_MAX_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdMaxSenderIdAsc = 'POSTINGS_BY_SENDER_ID_MAX_SENDER_ID_ASC',
  PostingsBySenderIdMaxSenderIdDesc = 'POSTINGS_BY_SENDER_ID_MAX_SENDER_ID_DESC',
  PostingsBySenderIdMaxSubjectAsc = 'POSTINGS_BY_SENDER_ID_MAX_SUBJECT_ASC',
  PostingsBySenderIdMaxSubjectDesc = 'POSTINGS_BY_SENDER_ID_MAX_SUBJECT_DESC',
  PostingsBySenderIdMaxSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_MAX_SUBMITTED_AT_ASC',
  PostingsBySenderIdMaxSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_MAX_SUBMITTED_AT_DESC',
  PostingsBySenderIdMaxUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_MAX_UPDATED_AT_ASC',
  PostingsBySenderIdMaxUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_MAX_UPDATED_AT_DESC',
  PostingsBySenderIdMinAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_MIN_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdMinAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_MIN_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdMinAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_MIN_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdMinAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_MIN_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdMinAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_MIN_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdMinAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_MIN_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdMinBodyAsc = 'POSTINGS_BY_SENDER_ID_MIN_BODY_ASC',
  PostingsBySenderIdMinBodyDesc = 'POSTINGS_BY_SENDER_ID_MIN_BODY_DESC',
  PostingsBySenderIdMinConversationIdAsc = 'POSTINGS_BY_SENDER_ID_MIN_CONVERSATION_ID_ASC',
  PostingsBySenderIdMinConversationIdDesc = 'POSTINGS_BY_SENDER_ID_MIN_CONVERSATION_ID_DESC',
  PostingsBySenderIdMinCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_MIN_CREATED_AT_ASC',
  PostingsBySenderIdMinCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_MIN_CREATED_AT_DESC',
  PostingsBySenderIdMinHashtagsAsc = 'POSTINGS_BY_SENDER_ID_MIN_HASHTAGS_ASC',
  PostingsBySenderIdMinHashtagsDesc = 'POSTINGS_BY_SENDER_ID_MIN_HASHTAGS_DESC',
  PostingsBySenderIdMinIdAsc = 'POSTINGS_BY_SENDER_ID_MIN_ID_ASC',
  PostingsBySenderIdMinIdDesc = 'POSTINGS_BY_SENDER_ID_MIN_ID_DESC',
  PostingsBySenderIdMinLanguageAsc = 'POSTINGS_BY_SENDER_ID_MIN_LANGUAGE_ASC',
  PostingsBySenderIdMinLanguageDesc = 'POSTINGS_BY_SENDER_ID_MIN_LANGUAGE_DESC',
  PostingsBySenderIdMinMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_MIN_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdMinMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_MIN_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdMinSenderIdAsc = 'POSTINGS_BY_SENDER_ID_MIN_SENDER_ID_ASC',
  PostingsBySenderIdMinSenderIdDesc = 'POSTINGS_BY_SENDER_ID_MIN_SENDER_ID_DESC',
  PostingsBySenderIdMinSubjectAsc = 'POSTINGS_BY_SENDER_ID_MIN_SUBJECT_ASC',
  PostingsBySenderIdMinSubjectDesc = 'POSTINGS_BY_SENDER_ID_MIN_SUBJECT_DESC',
  PostingsBySenderIdMinSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_MIN_SUBMITTED_AT_ASC',
  PostingsBySenderIdMinSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_MIN_SUBMITTED_AT_DESC',
  PostingsBySenderIdMinUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_MIN_UPDATED_AT_ASC',
  PostingsBySenderIdMinUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_MIN_UPDATED_AT_DESC',
  PostingsBySenderIdStddevPopulationAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdStddevPopulationAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdStddevPopulationAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdStddevPopulationAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdStddevPopulationAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdStddevPopulationAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdStddevPopulationBodyAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_BODY_ASC',
  PostingsBySenderIdStddevPopulationBodyDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_BODY_DESC',
  PostingsBySenderIdStddevPopulationConversationIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_CONVERSATION_ID_ASC',
  PostingsBySenderIdStddevPopulationConversationIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_CONVERSATION_ID_DESC',
  PostingsBySenderIdStddevPopulationCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  PostingsBySenderIdStddevPopulationCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  PostingsBySenderIdStddevPopulationHashtagsAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_HASHTAGS_ASC',
  PostingsBySenderIdStddevPopulationHashtagsDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_HASHTAGS_DESC',
  PostingsBySenderIdStddevPopulationIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_ID_ASC',
  PostingsBySenderIdStddevPopulationIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_ID_DESC',
  PostingsBySenderIdStddevPopulationLanguageAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_LANGUAGE_ASC',
  PostingsBySenderIdStddevPopulationLanguageDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_LANGUAGE_DESC',
  PostingsBySenderIdStddevPopulationMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdStddevPopulationMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdStddevPopulationSenderIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_SENDER_ID_ASC',
  PostingsBySenderIdStddevPopulationSenderIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_SENDER_ID_DESC',
  PostingsBySenderIdStddevPopulationSubjectAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_SUBJECT_ASC',
  PostingsBySenderIdStddevPopulationSubjectDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_SUBJECT_DESC',
  PostingsBySenderIdStddevPopulationSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_SUBMITTED_AT_ASC',
  PostingsBySenderIdStddevPopulationSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_SUBMITTED_AT_DESC',
  PostingsBySenderIdStddevPopulationUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  PostingsBySenderIdStddevPopulationUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  PostingsBySenderIdStddevSampleAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdStddevSampleAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdStddevSampleAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdStddevSampleAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdStddevSampleAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdStddevSampleAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdStddevSampleBodyAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_BODY_ASC',
  PostingsBySenderIdStddevSampleBodyDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_BODY_DESC',
  PostingsBySenderIdStddevSampleConversationIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_CONVERSATION_ID_ASC',
  PostingsBySenderIdStddevSampleConversationIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_CONVERSATION_ID_DESC',
  PostingsBySenderIdStddevSampleCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  PostingsBySenderIdStddevSampleCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  PostingsBySenderIdStddevSampleHashtagsAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_HASHTAGS_ASC',
  PostingsBySenderIdStddevSampleHashtagsDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_HASHTAGS_DESC',
  PostingsBySenderIdStddevSampleIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_ID_ASC',
  PostingsBySenderIdStddevSampleIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_ID_DESC',
  PostingsBySenderIdStddevSampleLanguageAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_LANGUAGE_ASC',
  PostingsBySenderIdStddevSampleLanguageDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_LANGUAGE_DESC',
  PostingsBySenderIdStddevSampleMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdStddevSampleMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdStddevSampleSenderIdAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_SENDER_ID_ASC',
  PostingsBySenderIdStddevSampleSenderIdDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_SENDER_ID_DESC',
  PostingsBySenderIdStddevSampleSubjectAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_SUBJECT_ASC',
  PostingsBySenderIdStddevSampleSubjectDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_SUBJECT_DESC',
  PostingsBySenderIdStddevSampleSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_SUBMITTED_AT_ASC',
  PostingsBySenderIdStddevSampleSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_SUBMITTED_AT_DESC',
  PostingsBySenderIdStddevSampleUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  PostingsBySenderIdStddevSampleUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  PostingsBySenderIdSumAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_SUM_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdSumAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_SUM_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdSumAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_SUM_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdSumAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_SUM_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdSumAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_SUM_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdSumAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_SUM_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdSumBodyAsc = 'POSTINGS_BY_SENDER_ID_SUM_BODY_ASC',
  PostingsBySenderIdSumBodyDesc = 'POSTINGS_BY_SENDER_ID_SUM_BODY_DESC',
  PostingsBySenderIdSumConversationIdAsc = 'POSTINGS_BY_SENDER_ID_SUM_CONVERSATION_ID_ASC',
  PostingsBySenderIdSumConversationIdDesc = 'POSTINGS_BY_SENDER_ID_SUM_CONVERSATION_ID_DESC',
  PostingsBySenderIdSumCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_SUM_CREATED_AT_ASC',
  PostingsBySenderIdSumCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_SUM_CREATED_AT_DESC',
  PostingsBySenderIdSumHashtagsAsc = 'POSTINGS_BY_SENDER_ID_SUM_HASHTAGS_ASC',
  PostingsBySenderIdSumHashtagsDesc = 'POSTINGS_BY_SENDER_ID_SUM_HASHTAGS_DESC',
  PostingsBySenderIdSumIdAsc = 'POSTINGS_BY_SENDER_ID_SUM_ID_ASC',
  PostingsBySenderIdSumIdDesc = 'POSTINGS_BY_SENDER_ID_SUM_ID_DESC',
  PostingsBySenderIdSumLanguageAsc = 'POSTINGS_BY_SENDER_ID_SUM_LANGUAGE_ASC',
  PostingsBySenderIdSumLanguageDesc = 'POSTINGS_BY_SENDER_ID_SUM_LANGUAGE_DESC',
  PostingsBySenderIdSumMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_SUM_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdSumMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_SUM_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdSumSenderIdAsc = 'POSTINGS_BY_SENDER_ID_SUM_SENDER_ID_ASC',
  PostingsBySenderIdSumSenderIdDesc = 'POSTINGS_BY_SENDER_ID_SUM_SENDER_ID_DESC',
  PostingsBySenderIdSumSubjectAsc = 'POSTINGS_BY_SENDER_ID_SUM_SUBJECT_ASC',
  PostingsBySenderIdSumSubjectDesc = 'POSTINGS_BY_SENDER_ID_SUM_SUBJECT_DESC',
  PostingsBySenderIdSumSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_SUM_SUBMITTED_AT_ASC',
  PostingsBySenderIdSumSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_SUM_SUBMITTED_AT_DESC',
  PostingsBySenderIdSumUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_SUM_UPDATED_AT_ASC',
  PostingsBySenderIdSumUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_SUM_UPDATED_AT_DESC',
  PostingsBySenderIdVariancePopulationAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdVariancePopulationAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdVariancePopulationAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdVariancePopulationAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdVariancePopulationAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdVariancePopulationAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdVariancePopulationBodyAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_BODY_ASC',
  PostingsBySenderIdVariancePopulationBodyDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_BODY_DESC',
  PostingsBySenderIdVariancePopulationConversationIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_CONVERSATION_ID_ASC',
  PostingsBySenderIdVariancePopulationConversationIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_CONVERSATION_ID_DESC',
  PostingsBySenderIdVariancePopulationCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  PostingsBySenderIdVariancePopulationCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  PostingsBySenderIdVariancePopulationHashtagsAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_HASHTAGS_ASC',
  PostingsBySenderIdVariancePopulationHashtagsDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_HASHTAGS_DESC',
  PostingsBySenderIdVariancePopulationIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_ID_ASC',
  PostingsBySenderIdVariancePopulationIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_ID_DESC',
  PostingsBySenderIdVariancePopulationLanguageAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_LANGUAGE_ASC',
  PostingsBySenderIdVariancePopulationLanguageDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_LANGUAGE_DESC',
  PostingsBySenderIdVariancePopulationMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdVariancePopulationMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdVariancePopulationSenderIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_SENDER_ID_ASC',
  PostingsBySenderIdVariancePopulationSenderIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_SENDER_ID_DESC',
  PostingsBySenderIdVariancePopulationSubjectAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_SUBJECT_ASC',
  PostingsBySenderIdVariancePopulationSubjectDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_SUBJECT_DESC',
  PostingsBySenderIdVariancePopulationSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_SUBMITTED_AT_ASC',
  PostingsBySenderIdVariancePopulationSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_SUBMITTED_AT_DESC',
  PostingsBySenderIdVariancePopulationUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  PostingsBySenderIdVariancePopulationUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  PostingsBySenderIdVarianceSampleAboutCommentableIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_ASC',
  PostingsBySenderIdVarianceSampleAboutCommentableIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_ABOUT_COMMENTABLE_ID_DESC',
  PostingsBySenderIdVarianceSampleAnsweredPostingIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_ANSWERED_POSTING_ID_ASC',
  PostingsBySenderIdVarianceSampleAnsweredPostingIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_ANSWERED_POSTING_ID_DESC',
  PostingsBySenderIdVarianceSampleAnsweredPostingWasDeletedAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_ANSWERED_POSTING_WAS_DELETED_ASC',
  PostingsBySenderIdVarianceSampleAnsweredPostingWasDeletedDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_ANSWERED_POSTING_WAS_DELETED_DESC',
  PostingsBySenderIdVarianceSampleBodyAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_BODY_ASC',
  PostingsBySenderIdVarianceSampleBodyDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_BODY_DESC',
  PostingsBySenderIdVarianceSampleConversationIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_CONVERSATION_ID_ASC',
  PostingsBySenderIdVarianceSampleConversationIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_CONVERSATION_ID_DESC',
  PostingsBySenderIdVarianceSampleCreatedAtAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  PostingsBySenderIdVarianceSampleCreatedAtDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  PostingsBySenderIdVarianceSampleHashtagsAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_HASHTAGS_ASC',
  PostingsBySenderIdVarianceSampleHashtagsDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_HASHTAGS_DESC',
  PostingsBySenderIdVarianceSampleIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_ID_ASC',
  PostingsBySenderIdVarianceSampleIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_ID_DESC',
  PostingsBySenderIdVarianceSampleLanguageAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_LANGUAGE_ASC',
  PostingsBySenderIdVarianceSampleLanguageDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_LANGUAGE_DESC',
  PostingsBySenderIdVarianceSampleMentionedUsernamesAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_MENTIONED_USERNAMES_ASC',
  PostingsBySenderIdVarianceSampleMentionedUsernamesDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_MENTIONED_USERNAMES_DESC',
  PostingsBySenderIdVarianceSampleSenderIdAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_SENDER_ID_ASC',
  PostingsBySenderIdVarianceSampleSenderIdDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_SENDER_ID_DESC',
  PostingsBySenderIdVarianceSampleSubjectAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_SUBJECT_ASC',
  PostingsBySenderIdVarianceSampleSubjectDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_SUBJECT_DESC',
  PostingsBySenderIdVarianceSampleSubmittedAtAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_SUBMITTED_AT_ASC',
  PostingsBySenderIdVarianceSampleSubmittedAtDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_SUBMITTED_AT_DESC',
  PostingsBySenderIdVarianceSampleUpdatedAtAsc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  PostingsBySenderIdVarianceSampleUpdatedAtDesc = 'POSTINGS_BY_SENDER_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  PostingReceiptsAverageManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_AVERAGE_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsAverageManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_AVERAGE_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsAveragePostingIdAsc = 'POSTING_RECEIPTS_AVERAGE_POSTING_ID_ASC',
  PostingReceiptsAveragePostingIdDesc = 'POSTING_RECEIPTS_AVERAGE_POSTING_ID_DESC',
  PostingReceiptsAverageReceivedAtAsc = 'POSTING_RECEIPTS_AVERAGE_RECEIVED_AT_ASC',
  PostingReceiptsAverageReceivedAtDesc = 'POSTING_RECEIPTS_AVERAGE_RECEIVED_AT_DESC',
  PostingReceiptsAverageUserIdAsc = 'POSTING_RECEIPTS_AVERAGE_USER_ID_ASC',
  PostingReceiptsAverageUserIdDesc = 'POSTING_RECEIPTS_AVERAGE_USER_ID_DESC',
  PostingReceiptsAverageViewedAtAsc = 'POSTING_RECEIPTS_AVERAGE_VIEWED_AT_ASC',
  PostingReceiptsAverageViewedAtDesc = 'POSTING_RECEIPTS_AVERAGE_VIEWED_AT_DESC',
  PostingReceiptsCountAsc = 'POSTING_RECEIPTS_COUNT_ASC',
  PostingReceiptsCountDesc = 'POSTING_RECEIPTS_COUNT_DESC',
  PostingReceiptsDistinctCountManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_DISTINCT_COUNT_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsDistinctCountManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_DISTINCT_COUNT_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsDistinctCountPostingIdAsc = 'POSTING_RECEIPTS_DISTINCT_COUNT_POSTING_ID_ASC',
  PostingReceiptsDistinctCountPostingIdDesc = 'POSTING_RECEIPTS_DISTINCT_COUNT_POSTING_ID_DESC',
  PostingReceiptsDistinctCountReceivedAtAsc = 'POSTING_RECEIPTS_DISTINCT_COUNT_RECEIVED_AT_ASC',
  PostingReceiptsDistinctCountReceivedAtDesc = 'POSTING_RECEIPTS_DISTINCT_COUNT_RECEIVED_AT_DESC',
  PostingReceiptsDistinctCountUserIdAsc = 'POSTING_RECEIPTS_DISTINCT_COUNT_USER_ID_ASC',
  PostingReceiptsDistinctCountUserIdDesc = 'POSTING_RECEIPTS_DISTINCT_COUNT_USER_ID_DESC',
  PostingReceiptsDistinctCountViewedAtAsc = 'POSTING_RECEIPTS_DISTINCT_COUNT_VIEWED_AT_ASC',
  PostingReceiptsDistinctCountViewedAtDesc = 'POSTING_RECEIPTS_DISTINCT_COUNT_VIEWED_AT_DESC',
  PostingReceiptsMaxManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_MAX_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsMaxManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_MAX_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsMaxPostingIdAsc = 'POSTING_RECEIPTS_MAX_POSTING_ID_ASC',
  PostingReceiptsMaxPostingIdDesc = 'POSTING_RECEIPTS_MAX_POSTING_ID_DESC',
  PostingReceiptsMaxReceivedAtAsc = 'POSTING_RECEIPTS_MAX_RECEIVED_AT_ASC',
  PostingReceiptsMaxReceivedAtDesc = 'POSTING_RECEIPTS_MAX_RECEIVED_AT_DESC',
  PostingReceiptsMaxUserIdAsc = 'POSTING_RECEIPTS_MAX_USER_ID_ASC',
  PostingReceiptsMaxUserIdDesc = 'POSTING_RECEIPTS_MAX_USER_ID_DESC',
  PostingReceiptsMaxViewedAtAsc = 'POSTING_RECEIPTS_MAX_VIEWED_AT_ASC',
  PostingReceiptsMaxViewedAtDesc = 'POSTING_RECEIPTS_MAX_VIEWED_AT_DESC',
  PostingReceiptsMinManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_MIN_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsMinManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_MIN_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsMinPostingIdAsc = 'POSTING_RECEIPTS_MIN_POSTING_ID_ASC',
  PostingReceiptsMinPostingIdDesc = 'POSTING_RECEIPTS_MIN_POSTING_ID_DESC',
  PostingReceiptsMinReceivedAtAsc = 'POSTING_RECEIPTS_MIN_RECEIVED_AT_ASC',
  PostingReceiptsMinReceivedAtDesc = 'POSTING_RECEIPTS_MIN_RECEIVED_AT_DESC',
  PostingReceiptsMinUserIdAsc = 'POSTING_RECEIPTS_MIN_USER_ID_ASC',
  PostingReceiptsMinUserIdDesc = 'POSTING_RECEIPTS_MIN_USER_ID_DESC',
  PostingReceiptsMinViewedAtAsc = 'POSTING_RECEIPTS_MIN_VIEWED_AT_ASC',
  PostingReceiptsMinViewedAtDesc = 'POSTING_RECEIPTS_MIN_VIEWED_AT_DESC',
  PostingReceiptsStddevPopulationManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_STDDEV_POPULATION_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsStddevPopulationManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_STDDEV_POPULATION_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsStddevPopulationPostingIdAsc = 'POSTING_RECEIPTS_STDDEV_POPULATION_POSTING_ID_ASC',
  PostingReceiptsStddevPopulationPostingIdDesc = 'POSTING_RECEIPTS_STDDEV_POPULATION_POSTING_ID_DESC',
  PostingReceiptsStddevPopulationReceivedAtAsc = 'POSTING_RECEIPTS_STDDEV_POPULATION_RECEIVED_AT_ASC',
  PostingReceiptsStddevPopulationReceivedAtDesc = 'POSTING_RECEIPTS_STDDEV_POPULATION_RECEIVED_AT_DESC',
  PostingReceiptsStddevPopulationUserIdAsc = 'POSTING_RECEIPTS_STDDEV_POPULATION_USER_ID_ASC',
  PostingReceiptsStddevPopulationUserIdDesc = 'POSTING_RECEIPTS_STDDEV_POPULATION_USER_ID_DESC',
  PostingReceiptsStddevPopulationViewedAtAsc = 'POSTING_RECEIPTS_STDDEV_POPULATION_VIEWED_AT_ASC',
  PostingReceiptsStddevPopulationViewedAtDesc = 'POSTING_RECEIPTS_STDDEV_POPULATION_VIEWED_AT_DESC',
  PostingReceiptsStddevSampleManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsStddevSampleManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsStddevSamplePostingIdAsc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_POSTING_ID_ASC',
  PostingReceiptsStddevSamplePostingIdDesc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_POSTING_ID_DESC',
  PostingReceiptsStddevSampleReceivedAtAsc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_RECEIVED_AT_ASC',
  PostingReceiptsStddevSampleReceivedAtDesc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_RECEIVED_AT_DESC',
  PostingReceiptsStddevSampleUserIdAsc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_USER_ID_ASC',
  PostingReceiptsStddevSampleUserIdDesc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_USER_ID_DESC',
  PostingReceiptsStddevSampleViewedAtAsc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_VIEWED_AT_ASC',
  PostingReceiptsStddevSampleViewedAtDesc = 'POSTING_RECEIPTS_STDDEV_SAMPLE_VIEWED_AT_DESC',
  PostingReceiptsSumManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_SUM_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsSumManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_SUM_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsSumPostingIdAsc = 'POSTING_RECEIPTS_SUM_POSTING_ID_ASC',
  PostingReceiptsSumPostingIdDesc = 'POSTING_RECEIPTS_SUM_POSTING_ID_DESC',
  PostingReceiptsSumReceivedAtAsc = 'POSTING_RECEIPTS_SUM_RECEIVED_AT_ASC',
  PostingReceiptsSumReceivedAtDesc = 'POSTING_RECEIPTS_SUM_RECEIVED_AT_DESC',
  PostingReceiptsSumUserIdAsc = 'POSTING_RECEIPTS_SUM_USER_ID_ASC',
  PostingReceiptsSumUserIdDesc = 'POSTING_RECEIPTS_SUM_USER_ID_DESC',
  PostingReceiptsSumViewedAtAsc = 'POSTING_RECEIPTS_SUM_VIEWED_AT_ASC',
  PostingReceiptsSumViewedAtDesc = 'POSTING_RECEIPTS_SUM_VIEWED_AT_DESC',
  PostingReceiptsVariancePopulationManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsVariancePopulationManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsVariancePopulationPostingIdAsc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_POSTING_ID_ASC',
  PostingReceiptsVariancePopulationPostingIdDesc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_POSTING_ID_DESC',
  PostingReceiptsVariancePopulationReceivedAtAsc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_RECEIVED_AT_ASC',
  PostingReceiptsVariancePopulationReceivedAtDesc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_RECEIVED_AT_DESC',
  PostingReceiptsVariancePopulationUserIdAsc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_USER_ID_ASC',
  PostingReceiptsVariancePopulationUserIdDesc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_USER_ID_DESC',
  PostingReceiptsVariancePopulationViewedAtAsc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_VIEWED_AT_ASC',
  PostingReceiptsVariancePopulationViewedAtDesc = 'POSTING_RECEIPTS_VARIANCE_POPULATION_VIEWED_AT_DESC',
  PostingReceiptsVarianceSampleManuallyConfirmedAtAsc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_MANUALLY_CONFIRMED_AT_ASC',
  PostingReceiptsVarianceSampleManuallyConfirmedAtDesc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_MANUALLY_CONFIRMED_AT_DESC',
  PostingReceiptsVarianceSamplePostingIdAsc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_POSTING_ID_ASC',
  PostingReceiptsVarianceSamplePostingIdDesc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_POSTING_ID_DESC',
  PostingReceiptsVarianceSampleReceivedAtAsc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_RECEIVED_AT_ASC',
  PostingReceiptsVarianceSampleReceivedAtDesc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_RECEIVED_AT_DESC',
  PostingReceiptsVarianceSampleUserIdAsc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_USER_ID_ASC',
  PostingReceiptsVarianceSampleUserIdDesc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_USER_ID_DESC',
  PostingReceiptsVarianceSampleViewedAtAsc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_VIEWED_AT_ASC',
  PostingReceiptsVarianceSampleViewedAtDesc = 'POSTING_RECEIPTS_VARIANCE_SAMPLE_VIEWED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RawUsageDataAverageClientHashAsc = 'RAW_USAGE_DATA_AVERAGE_CLIENT_HASH_ASC',
  RawUsageDataAverageClientHashDesc = 'RAW_USAGE_DATA_AVERAGE_CLIENT_HASH_DESC',
  RawUsageDataAverageIdAsc = 'RAW_USAGE_DATA_AVERAGE_ID_ASC',
  RawUsageDataAverageIdDesc = 'RAW_USAGE_DATA_AVERAGE_ID_DESC',
  RawUsageDataAveragePayloadAsc = 'RAW_USAGE_DATA_AVERAGE_PAYLOAD_ASC',
  RawUsageDataAveragePayloadDesc = 'RAW_USAGE_DATA_AVERAGE_PAYLOAD_DESC',
  RawUsageDataAverageRemoteAddressAsc = 'RAW_USAGE_DATA_AVERAGE_REMOTE_ADDRESS_ASC',
  RawUsageDataAverageRemoteAddressDesc = 'RAW_USAGE_DATA_AVERAGE_REMOTE_ADDRESS_DESC',
  RawUsageDataAverageTrackedAtAsc = 'RAW_USAGE_DATA_AVERAGE_TRACKED_AT_ASC',
  RawUsageDataAverageTrackedAtDesc = 'RAW_USAGE_DATA_AVERAGE_TRACKED_AT_DESC',
  RawUsageDataAverageTrialIdAsc = 'RAW_USAGE_DATA_AVERAGE_TRIAL_ID_ASC',
  RawUsageDataAverageTrialIdDesc = 'RAW_USAGE_DATA_AVERAGE_TRIAL_ID_DESC',
  RawUsageDataAverageTypeAsc = 'RAW_USAGE_DATA_AVERAGE_TYPE_ASC',
  RawUsageDataAverageTypeDesc = 'RAW_USAGE_DATA_AVERAGE_TYPE_DESC',
  RawUsageDataAverageUserAgentAsc = 'RAW_USAGE_DATA_AVERAGE_USER_AGENT_ASC',
  RawUsageDataAverageUserAgentDesc = 'RAW_USAGE_DATA_AVERAGE_USER_AGENT_DESC',
  RawUsageDataAverageUserIdAsc = 'RAW_USAGE_DATA_AVERAGE_USER_ID_ASC',
  RawUsageDataAverageUserIdDesc = 'RAW_USAGE_DATA_AVERAGE_USER_ID_DESC',
  RawUsageDataCountAsc = 'RAW_USAGE_DATA_COUNT_ASC',
  RawUsageDataCountDesc = 'RAW_USAGE_DATA_COUNT_DESC',
  RawUsageDataDistinctCountClientHashAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_CLIENT_HASH_ASC',
  RawUsageDataDistinctCountClientHashDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_CLIENT_HASH_DESC',
  RawUsageDataDistinctCountIdAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_ID_ASC',
  RawUsageDataDistinctCountIdDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_ID_DESC',
  RawUsageDataDistinctCountPayloadAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_PAYLOAD_ASC',
  RawUsageDataDistinctCountPayloadDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_PAYLOAD_DESC',
  RawUsageDataDistinctCountRemoteAddressAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_REMOTE_ADDRESS_ASC',
  RawUsageDataDistinctCountRemoteAddressDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_REMOTE_ADDRESS_DESC',
  RawUsageDataDistinctCountTrackedAtAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TRACKED_AT_ASC',
  RawUsageDataDistinctCountTrackedAtDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TRACKED_AT_DESC',
  RawUsageDataDistinctCountTrialIdAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TRIAL_ID_ASC',
  RawUsageDataDistinctCountTrialIdDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TRIAL_ID_DESC',
  RawUsageDataDistinctCountTypeAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TYPE_ASC',
  RawUsageDataDistinctCountTypeDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_TYPE_DESC',
  RawUsageDataDistinctCountUserAgentAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_USER_AGENT_ASC',
  RawUsageDataDistinctCountUserAgentDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_USER_AGENT_DESC',
  RawUsageDataDistinctCountUserIdAsc = 'RAW_USAGE_DATA_DISTINCT_COUNT_USER_ID_ASC',
  RawUsageDataDistinctCountUserIdDesc = 'RAW_USAGE_DATA_DISTINCT_COUNT_USER_ID_DESC',
  RawUsageDataMaxClientHashAsc = 'RAW_USAGE_DATA_MAX_CLIENT_HASH_ASC',
  RawUsageDataMaxClientHashDesc = 'RAW_USAGE_DATA_MAX_CLIENT_HASH_DESC',
  RawUsageDataMaxIdAsc = 'RAW_USAGE_DATA_MAX_ID_ASC',
  RawUsageDataMaxIdDesc = 'RAW_USAGE_DATA_MAX_ID_DESC',
  RawUsageDataMaxPayloadAsc = 'RAW_USAGE_DATA_MAX_PAYLOAD_ASC',
  RawUsageDataMaxPayloadDesc = 'RAW_USAGE_DATA_MAX_PAYLOAD_DESC',
  RawUsageDataMaxRemoteAddressAsc = 'RAW_USAGE_DATA_MAX_REMOTE_ADDRESS_ASC',
  RawUsageDataMaxRemoteAddressDesc = 'RAW_USAGE_DATA_MAX_REMOTE_ADDRESS_DESC',
  RawUsageDataMaxTrackedAtAsc = 'RAW_USAGE_DATA_MAX_TRACKED_AT_ASC',
  RawUsageDataMaxTrackedAtDesc = 'RAW_USAGE_DATA_MAX_TRACKED_AT_DESC',
  RawUsageDataMaxTrialIdAsc = 'RAW_USAGE_DATA_MAX_TRIAL_ID_ASC',
  RawUsageDataMaxTrialIdDesc = 'RAW_USAGE_DATA_MAX_TRIAL_ID_DESC',
  RawUsageDataMaxTypeAsc = 'RAW_USAGE_DATA_MAX_TYPE_ASC',
  RawUsageDataMaxTypeDesc = 'RAW_USAGE_DATA_MAX_TYPE_DESC',
  RawUsageDataMaxUserAgentAsc = 'RAW_USAGE_DATA_MAX_USER_AGENT_ASC',
  RawUsageDataMaxUserAgentDesc = 'RAW_USAGE_DATA_MAX_USER_AGENT_DESC',
  RawUsageDataMaxUserIdAsc = 'RAW_USAGE_DATA_MAX_USER_ID_ASC',
  RawUsageDataMaxUserIdDesc = 'RAW_USAGE_DATA_MAX_USER_ID_DESC',
  RawUsageDataMinClientHashAsc = 'RAW_USAGE_DATA_MIN_CLIENT_HASH_ASC',
  RawUsageDataMinClientHashDesc = 'RAW_USAGE_DATA_MIN_CLIENT_HASH_DESC',
  RawUsageDataMinIdAsc = 'RAW_USAGE_DATA_MIN_ID_ASC',
  RawUsageDataMinIdDesc = 'RAW_USAGE_DATA_MIN_ID_DESC',
  RawUsageDataMinPayloadAsc = 'RAW_USAGE_DATA_MIN_PAYLOAD_ASC',
  RawUsageDataMinPayloadDesc = 'RAW_USAGE_DATA_MIN_PAYLOAD_DESC',
  RawUsageDataMinRemoteAddressAsc = 'RAW_USAGE_DATA_MIN_REMOTE_ADDRESS_ASC',
  RawUsageDataMinRemoteAddressDesc = 'RAW_USAGE_DATA_MIN_REMOTE_ADDRESS_DESC',
  RawUsageDataMinTrackedAtAsc = 'RAW_USAGE_DATA_MIN_TRACKED_AT_ASC',
  RawUsageDataMinTrackedAtDesc = 'RAW_USAGE_DATA_MIN_TRACKED_AT_DESC',
  RawUsageDataMinTrialIdAsc = 'RAW_USAGE_DATA_MIN_TRIAL_ID_ASC',
  RawUsageDataMinTrialIdDesc = 'RAW_USAGE_DATA_MIN_TRIAL_ID_DESC',
  RawUsageDataMinTypeAsc = 'RAW_USAGE_DATA_MIN_TYPE_ASC',
  RawUsageDataMinTypeDesc = 'RAW_USAGE_DATA_MIN_TYPE_DESC',
  RawUsageDataMinUserAgentAsc = 'RAW_USAGE_DATA_MIN_USER_AGENT_ASC',
  RawUsageDataMinUserAgentDesc = 'RAW_USAGE_DATA_MIN_USER_AGENT_DESC',
  RawUsageDataMinUserIdAsc = 'RAW_USAGE_DATA_MIN_USER_ID_ASC',
  RawUsageDataMinUserIdDesc = 'RAW_USAGE_DATA_MIN_USER_ID_DESC',
  RawUsageDataStddevPopulationClientHashAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_CLIENT_HASH_ASC',
  RawUsageDataStddevPopulationClientHashDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_CLIENT_HASH_DESC',
  RawUsageDataStddevPopulationIdAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_ID_ASC',
  RawUsageDataStddevPopulationIdDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_ID_DESC',
  RawUsageDataStddevPopulationPayloadAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_PAYLOAD_ASC',
  RawUsageDataStddevPopulationPayloadDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_PAYLOAD_DESC',
  RawUsageDataStddevPopulationRemoteAddressAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_REMOTE_ADDRESS_ASC',
  RawUsageDataStddevPopulationRemoteAddressDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_REMOTE_ADDRESS_DESC',
  RawUsageDataStddevPopulationTrackedAtAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TRACKED_AT_ASC',
  RawUsageDataStddevPopulationTrackedAtDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TRACKED_AT_DESC',
  RawUsageDataStddevPopulationTrialIdAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TRIAL_ID_ASC',
  RawUsageDataStddevPopulationTrialIdDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TRIAL_ID_DESC',
  RawUsageDataStddevPopulationTypeAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TYPE_ASC',
  RawUsageDataStddevPopulationTypeDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_TYPE_DESC',
  RawUsageDataStddevPopulationUserAgentAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_USER_AGENT_ASC',
  RawUsageDataStddevPopulationUserAgentDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_USER_AGENT_DESC',
  RawUsageDataStddevPopulationUserIdAsc = 'RAW_USAGE_DATA_STDDEV_POPULATION_USER_ID_ASC',
  RawUsageDataStddevPopulationUserIdDesc = 'RAW_USAGE_DATA_STDDEV_POPULATION_USER_ID_DESC',
  RawUsageDataStddevSampleClientHashAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_CLIENT_HASH_ASC',
  RawUsageDataStddevSampleClientHashDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_CLIENT_HASH_DESC',
  RawUsageDataStddevSampleIdAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_ID_ASC',
  RawUsageDataStddevSampleIdDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_ID_DESC',
  RawUsageDataStddevSamplePayloadAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_PAYLOAD_ASC',
  RawUsageDataStddevSamplePayloadDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_PAYLOAD_DESC',
  RawUsageDataStddevSampleRemoteAddressAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_REMOTE_ADDRESS_ASC',
  RawUsageDataStddevSampleRemoteAddressDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_REMOTE_ADDRESS_DESC',
  RawUsageDataStddevSampleTrackedAtAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TRACKED_AT_ASC',
  RawUsageDataStddevSampleTrackedAtDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TRACKED_AT_DESC',
  RawUsageDataStddevSampleTrialIdAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TRIAL_ID_ASC',
  RawUsageDataStddevSampleTrialIdDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TRIAL_ID_DESC',
  RawUsageDataStddevSampleTypeAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TYPE_ASC',
  RawUsageDataStddevSampleTypeDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_TYPE_DESC',
  RawUsageDataStddevSampleUserAgentAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_USER_AGENT_ASC',
  RawUsageDataStddevSampleUserAgentDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_USER_AGENT_DESC',
  RawUsageDataStddevSampleUserIdAsc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_USER_ID_ASC',
  RawUsageDataStddevSampleUserIdDesc = 'RAW_USAGE_DATA_STDDEV_SAMPLE_USER_ID_DESC',
  RawUsageDataSumClientHashAsc = 'RAW_USAGE_DATA_SUM_CLIENT_HASH_ASC',
  RawUsageDataSumClientHashDesc = 'RAW_USAGE_DATA_SUM_CLIENT_HASH_DESC',
  RawUsageDataSumIdAsc = 'RAW_USAGE_DATA_SUM_ID_ASC',
  RawUsageDataSumIdDesc = 'RAW_USAGE_DATA_SUM_ID_DESC',
  RawUsageDataSumPayloadAsc = 'RAW_USAGE_DATA_SUM_PAYLOAD_ASC',
  RawUsageDataSumPayloadDesc = 'RAW_USAGE_DATA_SUM_PAYLOAD_DESC',
  RawUsageDataSumRemoteAddressAsc = 'RAW_USAGE_DATA_SUM_REMOTE_ADDRESS_ASC',
  RawUsageDataSumRemoteAddressDesc = 'RAW_USAGE_DATA_SUM_REMOTE_ADDRESS_DESC',
  RawUsageDataSumTrackedAtAsc = 'RAW_USAGE_DATA_SUM_TRACKED_AT_ASC',
  RawUsageDataSumTrackedAtDesc = 'RAW_USAGE_DATA_SUM_TRACKED_AT_DESC',
  RawUsageDataSumTrialIdAsc = 'RAW_USAGE_DATA_SUM_TRIAL_ID_ASC',
  RawUsageDataSumTrialIdDesc = 'RAW_USAGE_DATA_SUM_TRIAL_ID_DESC',
  RawUsageDataSumTypeAsc = 'RAW_USAGE_DATA_SUM_TYPE_ASC',
  RawUsageDataSumTypeDesc = 'RAW_USAGE_DATA_SUM_TYPE_DESC',
  RawUsageDataSumUserAgentAsc = 'RAW_USAGE_DATA_SUM_USER_AGENT_ASC',
  RawUsageDataSumUserAgentDesc = 'RAW_USAGE_DATA_SUM_USER_AGENT_DESC',
  RawUsageDataSumUserIdAsc = 'RAW_USAGE_DATA_SUM_USER_ID_ASC',
  RawUsageDataSumUserIdDesc = 'RAW_USAGE_DATA_SUM_USER_ID_DESC',
  RawUsageDataVariancePopulationClientHashAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_CLIENT_HASH_ASC',
  RawUsageDataVariancePopulationClientHashDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_CLIENT_HASH_DESC',
  RawUsageDataVariancePopulationIdAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_ID_ASC',
  RawUsageDataVariancePopulationIdDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_ID_DESC',
  RawUsageDataVariancePopulationPayloadAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_PAYLOAD_ASC',
  RawUsageDataVariancePopulationPayloadDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_PAYLOAD_DESC',
  RawUsageDataVariancePopulationRemoteAddressAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_REMOTE_ADDRESS_ASC',
  RawUsageDataVariancePopulationRemoteAddressDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_REMOTE_ADDRESS_DESC',
  RawUsageDataVariancePopulationTrackedAtAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TRACKED_AT_ASC',
  RawUsageDataVariancePopulationTrackedAtDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TRACKED_AT_DESC',
  RawUsageDataVariancePopulationTrialIdAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TRIAL_ID_ASC',
  RawUsageDataVariancePopulationTrialIdDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TRIAL_ID_DESC',
  RawUsageDataVariancePopulationTypeAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TYPE_ASC',
  RawUsageDataVariancePopulationTypeDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_TYPE_DESC',
  RawUsageDataVariancePopulationUserAgentAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_USER_AGENT_ASC',
  RawUsageDataVariancePopulationUserAgentDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_USER_AGENT_DESC',
  RawUsageDataVariancePopulationUserIdAsc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_USER_ID_ASC',
  RawUsageDataVariancePopulationUserIdDesc = 'RAW_USAGE_DATA_VARIANCE_POPULATION_USER_ID_DESC',
  RawUsageDataVarianceSampleClientHashAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_CLIENT_HASH_ASC',
  RawUsageDataVarianceSampleClientHashDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_CLIENT_HASH_DESC',
  RawUsageDataVarianceSampleIdAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_ID_ASC',
  RawUsageDataVarianceSampleIdDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_ID_DESC',
  RawUsageDataVarianceSamplePayloadAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_PAYLOAD_ASC',
  RawUsageDataVarianceSamplePayloadDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_PAYLOAD_DESC',
  RawUsageDataVarianceSampleRemoteAddressAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_REMOTE_ADDRESS_ASC',
  RawUsageDataVarianceSampleRemoteAddressDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_REMOTE_ADDRESS_DESC',
  RawUsageDataVarianceSampleTrackedAtAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TRACKED_AT_ASC',
  RawUsageDataVarianceSampleTrackedAtDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TRACKED_AT_DESC',
  RawUsageDataVarianceSampleTrialIdAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TRIAL_ID_ASC',
  RawUsageDataVarianceSampleTrialIdDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TRIAL_ID_DESC',
  RawUsageDataVarianceSampleTypeAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TYPE_ASC',
  RawUsageDataVarianceSampleTypeDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_TYPE_DESC',
  RawUsageDataVarianceSampleUserAgentAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_USER_AGENT_ASC',
  RawUsageDataVarianceSampleUserAgentDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_USER_AGENT_DESC',
  RawUsageDataVarianceSampleUserIdAsc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_USER_ID_ASC',
  RawUsageDataVarianceSampleUserIdDesc = 'RAW_USAGE_DATA_VARIANCE_SAMPLE_USER_ID_DESC',
  TenantIdAsc = 'TENANT_ID_ASC',
  TenantIdDesc = 'TENANT_ID_DESC',
  TrialsByCreatedByIdAverageAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_AVATAR_ASC',
  TrialsByCreatedByIdAverageAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_AVATAR_DESC',
  TrialsByCreatedByIdAverageCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdAverageCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdAverageCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdAverageCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdAverageCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdAverageCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdAverageCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdAverageCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdAverageCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CREATED_AT_ASC',
  TrialsByCreatedByIdAverageCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CREATED_AT_DESC',
  TrialsByCreatedByIdAverageCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdAverageCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdAverageEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdAverageEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdAverageHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdAverageHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdAverageHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdAverageHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdAverageHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdAverageHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdAverageHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdAverageHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdAverageHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdAverageHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdAverageHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdAverageHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdAverageHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdAverageHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdAverageIdAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_ID_ASC',
  TrialsByCreatedByIdAverageIdDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_ID_DESC',
  TrialsByCreatedByIdAverageInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdAverageInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdAverageMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdAverageMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdAverageMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdAverageMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdAverageMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdAverageMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdAverageMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_MESSAGES_ID_ASC',
  TrialsByCreatedByIdAverageMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_MESSAGES_ID_DESC',
  TrialsByCreatedByIdAverageNameAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_NAME_ASC',
  TrialsByCreatedByIdAverageNameDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_NAME_DESC',
  TrialsByCreatedByIdAverageStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_STAFF_ID_ASC',
  TrialsByCreatedByIdAverageStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_STAFF_ID_DESC',
  TrialsByCreatedByIdAverageSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdAverageSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdAverageSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_SUBJECT_ID_ASC',
  TrialsByCreatedByIdAverageSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_SUBJECT_ID_DESC',
  TrialsByCreatedByIdAverageTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_TENANT_ID_ASC',
  TrialsByCreatedByIdAverageTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_TENANT_ID_DESC',
  TrialsByCreatedByIdAverageUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_UPDATED_AT_ASC',
  TrialsByCreatedByIdAverageUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_AVERAGE_UPDATED_AT_DESC',
  TrialsByCreatedByIdCountAsc = 'TRIALS_BY_CREATED_BY_ID_COUNT_ASC',
  TrialsByCreatedByIdCountDesc = 'TRIALS_BY_CREATED_BY_ID_COUNT_DESC',
  TrialsByCreatedByIdDistinctCountAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_AVATAR_ASC',
  TrialsByCreatedByIdDistinctCountAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_AVATAR_DESC',
  TrialsByCreatedByIdDistinctCountCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdDistinctCountCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdDistinctCountCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdDistinctCountCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdDistinctCountCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdDistinctCountCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdDistinctCountCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdDistinctCountCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdDistinctCountCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  TrialsByCreatedByIdDistinctCountCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  TrialsByCreatedByIdDistinctCountCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdDistinctCountCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdDistinctCountEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdDistinctCountEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdDistinctCountHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdDistinctCountHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdDistinctCountHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdDistinctCountHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdDistinctCountHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdDistinctCountHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdDistinctCountHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdDistinctCountHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdDistinctCountHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdDistinctCountHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdDistinctCountHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdDistinctCountHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdDistinctCountHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdDistinctCountHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdDistinctCountIdAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_ID_ASC',
  TrialsByCreatedByIdDistinctCountIdDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_ID_DESC',
  TrialsByCreatedByIdDistinctCountInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdDistinctCountInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdDistinctCountMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdDistinctCountMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdDistinctCountMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdDistinctCountMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdDistinctCountMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_MESSAGES_ID_ASC',
  TrialsByCreatedByIdDistinctCountMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_MESSAGES_ID_DESC',
  TrialsByCreatedByIdDistinctCountNameAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_NAME_ASC',
  TrialsByCreatedByIdDistinctCountNameDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_NAME_DESC',
  TrialsByCreatedByIdDistinctCountStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_STAFF_ID_ASC',
  TrialsByCreatedByIdDistinctCountStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_STAFF_ID_DESC',
  TrialsByCreatedByIdDistinctCountSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdDistinctCountSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdDistinctCountSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_SUBJECT_ID_ASC',
  TrialsByCreatedByIdDistinctCountSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_SUBJECT_ID_DESC',
  TrialsByCreatedByIdDistinctCountTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  TrialsByCreatedByIdDistinctCountTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  TrialsByCreatedByIdDistinctCountUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  TrialsByCreatedByIdDistinctCountUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  TrialsByCreatedByIdMaxAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_AVATAR_ASC',
  TrialsByCreatedByIdMaxAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_AVATAR_DESC',
  TrialsByCreatedByIdMaxCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdMaxCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdMaxCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdMaxCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdMaxCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdMaxCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdMaxCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdMaxCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdMaxCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_CREATED_AT_ASC',
  TrialsByCreatedByIdMaxCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_CREATED_AT_DESC',
  TrialsByCreatedByIdMaxCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdMaxCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdMaxEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdMaxEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdMaxHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdMaxHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdMaxHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdMaxHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdMaxHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdMaxHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdMaxHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdMaxHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdMaxHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdMaxHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdMaxHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdMaxHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdMaxHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdMaxHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdMaxIdAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_ID_ASC',
  TrialsByCreatedByIdMaxIdDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_ID_DESC',
  TrialsByCreatedByIdMaxInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdMaxInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdMaxMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdMaxMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdMaxMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdMaxMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdMaxMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdMaxMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdMaxMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_MESSAGES_ID_ASC',
  TrialsByCreatedByIdMaxMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_MESSAGES_ID_DESC',
  TrialsByCreatedByIdMaxNameAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_NAME_ASC',
  TrialsByCreatedByIdMaxNameDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_NAME_DESC',
  TrialsByCreatedByIdMaxStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_STAFF_ID_ASC',
  TrialsByCreatedByIdMaxStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_STAFF_ID_DESC',
  TrialsByCreatedByIdMaxSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdMaxSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdMaxSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_SUBJECT_ID_ASC',
  TrialsByCreatedByIdMaxSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_SUBJECT_ID_DESC',
  TrialsByCreatedByIdMaxTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_TENANT_ID_ASC',
  TrialsByCreatedByIdMaxTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_TENANT_ID_DESC',
  TrialsByCreatedByIdMaxUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_MAX_UPDATED_AT_ASC',
  TrialsByCreatedByIdMaxUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_MAX_UPDATED_AT_DESC',
  TrialsByCreatedByIdMinAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_AVATAR_ASC',
  TrialsByCreatedByIdMinAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_AVATAR_DESC',
  TrialsByCreatedByIdMinCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdMinCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdMinCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdMinCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdMinCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdMinCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdMinCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdMinCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdMinCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_CREATED_AT_ASC',
  TrialsByCreatedByIdMinCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_CREATED_AT_DESC',
  TrialsByCreatedByIdMinCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdMinCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdMinEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdMinEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdMinHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdMinHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdMinHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdMinHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdMinHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdMinHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdMinHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdMinHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdMinHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdMinHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdMinHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdMinHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdMinHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdMinHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdMinIdAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_ID_ASC',
  TrialsByCreatedByIdMinIdDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_ID_DESC',
  TrialsByCreatedByIdMinInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdMinInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdMinMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdMinMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdMinMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdMinMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdMinMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdMinMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdMinMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_MESSAGES_ID_ASC',
  TrialsByCreatedByIdMinMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_MESSAGES_ID_DESC',
  TrialsByCreatedByIdMinNameAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_NAME_ASC',
  TrialsByCreatedByIdMinNameDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_NAME_DESC',
  TrialsByCreatedByIdMinStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_STAFF_ID_ASC',
  TrialsByCreatedByIdMinStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_STAFF_ID_DESC',
  TrialsByCreatedByIdMinSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdMinSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdMinSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_SUBJECT_ID_ASC',
  TrialsByCreatedByIdMinSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_SUBJECT_ID_DESC',
  TrialsByCreatedByIdMinTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_TENANT_ID_ASC',
  TrialsByCreatedByIdMinTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_TENANT_ID_DESC',
  TrialsByCreatedByIdMinUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_MIN_UPDATED_AT_ASC',
  TrialsByCreatedByIdMinUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_MIN_UPDATED_AT_DESC',
  TrialsByCreatedByIdStddevPopulationAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_AVATAR_ASC',
  TrialsByCreatedByIdStddevPopulationAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_AVATAR_DESC',
  TrialsByCreatedByIdStddevPopulationCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdStddevPopulationCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdStddevPopulationCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdStddevPopulationCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdStddevPopulationCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdStddevPopulationCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdStddevPopulationCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdStddevPopulationCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdStddevPopulationCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  TrialsByCreatedByIdStddevPopulationCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  TrialsByCreatedByIdStddevPopulationCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdStddevPopulationCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdStddevPopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdStddevPopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdStddevPopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdStddevPopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdStddevPopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdStddevPopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdStddevPopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdStddevPopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdStddevPopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdStddevPopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdStddevPopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdStddevPopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdStddevPopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdStddevPopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdStddevPopulationHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdStddevPopulationHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdStddevPopulationIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_ID_ASC',
  TrialsByCreatedByIdStddevPopulationIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_ID_DESC',
  TrialsByCreatedByIdStddevPopulationInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdStddevPopulationInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdStddevPopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdStddevPopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdStddevPopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdStddevPopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdStddevPopulationMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_MESSAGES_ID_ASC',
  TrialsByCreatedByIdStddevPopulationMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_MESSAGES_ID_DESC',
  TrialsByCreatedByIdStddevPopulationNameAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_NAME_ASC',
  TrialsByCreatedByIdStddevPopulationNameDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_NAME_DESC',
  TrialsByCreatedByIdStddevPopulationStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_STAFF_ID_ASC',
  TrialsByCreatedByIdStddevPopulationStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_STAFF_ID_DESC',
  TrialsByCreatedByIdStddevPopulationSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdStddevPopulationSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdStddevPopulationSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_SUBJECT_ID_ASC',
  TrialsByCreatedByIdStddevPopulationSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_SUBJECT_ID_DESC',
  TrialsByCreatedByIdStddevPopulationTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  TrialsByCreatedByIdStddevPopulationTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  TrialsByCreatedByIdStddevPopulationUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  TrialsByCreatedByIdStddevPopulationUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  TrialsByCreatedByIdStddevSampleAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_AVATAR_ASC',
  TrialsByCreatedByIdStddevSampleAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_AVATAR_DESC',
  TrialsByCreatedByIdStddevSampleCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdStddevSampleCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdStddevSampleCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdStddevSampleCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdStddevSampleCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdStddevSampleCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdStddevSampleCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdStddevSampleCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdStddevSampleCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  TrialsByCreatedByIdStddevSampleCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  TrialsByCreatedByIdStddevSampleCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdStddevSampleCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdStddevSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdStddevSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdStddevSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdStddevSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdStddevSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdStddevSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdStddevSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdStddevSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdStddevSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdStddevSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdStddevSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdStddevSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdStddevSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdStddevSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdStddevSampleHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdStddevSampleHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdStddevSampleIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_ID_ASC',
  TrialsByCreatedByIdStddevSampleIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_ID_DESC',
  TrialsByCreatedByIdStddevSampleInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdStddevSampleInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdStddevSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdStddevSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdStddevSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdStddevSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdStddevSampleMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_MESSAGES_ID_ASC',
  TrialsByCreatedByIdStddevSampleMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_MESSAGES_ID_DESC',
  TrialsByCreatedByIdStddevSampleNameAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_NAME_ASC',
  TrialsByCreatedByIdStddevSampleNameDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_NAME_DESC',
  TrialsByCreatedByIdStddevSampleStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_STAFF_ID_ASC',
  TrialsByCreatedByIdStddevSampleStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_STAFF_ID_DESC',
  TrialsByCreatedByIdStddevSampleSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdStddevSampleSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdStddevSampleSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_SUBJECT_ID_ASC',
  TrialsByCreatedByIdStddevSampleSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_SUBJECT_ID_DESC',
  TrialsByCreatedByIdStddevSampleTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  TrialsByCreatedByIdStddevSampleTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  TrialsByCreatedByIdStddevSampleUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TrialsByCreatedByIdStddevSampleUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TrialsByCreatedByIdSumAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_AVATAR_ASC',
  TrialsByCreatedByIdSumAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_AVATAR_DESC',
  TrialsByCreatedByIdSumCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdSumCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdSumCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdSumCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdSumCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdSumCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdSumCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdSumCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdSumCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_CREATED_AT_ASC',
  TrialsByCreatedByIdSumCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_CREATED_AT_DESC',
  TrialsByCreatedByIdSumCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdSumCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdSumEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdSumEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdSumHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdSumHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdSumHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdSumHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdSumHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdSumHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdSumHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdSumHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdSumHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdSumHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdSumHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdSumHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdSumHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdSumHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdSumIdAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_ID_ASC',
  TrialsByCreatedByIdSumIdDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_ID_DESC',
  TrialsByCreatedByIdSumInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdSumInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdSumMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdSumMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdSumMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdSumMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdSumMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdSumMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdSumMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_MESSAGES_ID_ASC',
  TrialsByCreatedByIdSumMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_MESSAGES_ID_DESC',
  TrialsByCreatedByIdSumNameAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_NAME_ASC',
  TrialsByCreatedByIdSumNameDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_NAME_DESC',
  TrialsByCreatedByIdSumStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_STAFF_ID_ASC',
  TrialsByCreatedByIdSumStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_STAFF_ID_DESC',
  TrialsByCreatedByIdSumSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdSumSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdSumSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_SUBJECT_ID_ASC',
  TrialsByCreatedByIdSumSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_SUBJECT_ID_DESC',
  TrialsByCreatedByIdSumTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_TENANT_ID_ASC',
  TrialsByCreatedByIdSumTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_TENANT_ID_DESC',
  TrialsByCreatedByIdSumUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_SUM_UPDATED_AT_ASC',
  TrialsByCreatedByIdSumUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_SUM_UPDATED_AT_DESC',
  TrialsByCreatedByIdVariancePopulationAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_AVATAR_ASC',
  TrialsByCreatedByIdVariancePopulationAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_AVATAR_DESC',
  TrialsByCreatedByIdVariancePopulationCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdVariancePopulationCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdVariancePopulationCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdVariancePopulationCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdVariancePopulationCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdVariancePopulationCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdVariancePopulationCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdVariancePopulationCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdVariancePopulationCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  TrialsByCreatedByIdVariancePopulationCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  TrialsByCreatedByIdVariancePopulationCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdVariancePopulationCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdVariancePopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdVariancePopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdVariancePopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdVariancePopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdVariancePopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdVariancePopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdVariancePopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdVariancePopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdVariancePopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdVariancePopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdVariancePopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdVariancePopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdVariancePopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdVariancePopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdVariancePopulationHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdVariancePopulationHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdVariancePopulationIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_ID_ASC',
  TrialsByCreatedByIdVariancePopulationIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_ID_DESC',
  TrialsByCreatedByIdVariancePopulationInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdVariancePopulationInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdVariancePopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdVariancePopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdVariancePopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdVariancePopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdVariancePopulationMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_MESSAGES_ID_ASC',
  TrialsByCreatedByIdVariancePopulationMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_MESSAGES_ID_DESC',
  TrialsByCreatedByIdVariancePopulationNameAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_NAME_ASC',
  TrialsByCreatedByIdVariancePopulationNameDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_NAME_DESC',
  TrialsByCreatedByIdVariancePopulationStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_STAFF_ID_ASC',
  TrialsByCreatedByIdVariancePopulationStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_STAFF_ID_DESC',
  TrialsByCreatedByIdVariancePopulationSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdVariancePopulationSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdVariancePopulationSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_SUBJECT_ID_ASC',
  TrialsByCreatedByIdVariancePopulationSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_SUBJECT_ID_DESC',
  TrialsByCreatedByIdVariancePopulationTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  TrialsByCreatedByIdVariancePopulationTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  TrialsByCreatedByIdVariancePopulationUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TrialsByCreatedByIdVariancePopulationUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TrialsByCreatedByIdVarianceSampleAvatarAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_AVATAR_ASC',
  TrialsByCreatedByIdVarianceSampleAvatarDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_AVATAR_DESC',
  TrialsByCreatedByIdVarianceSampleCannotUseTheInterventionAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsByCreatedByIdVarianceSampleCannotUseTheInterventionDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsByCreatedByIdVarianceSampleCanAccessErSinceAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsByCreatedByIdVarianceSampleCanAccessErSinceDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsByCreatedByIdVarianceSampleCanAccessSipSinceAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsByCreatedByIdVarianceSampleCanAccessSipSinceDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsByCreatedByIdVarianceSampleCanBeIncludedAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsByCreatedByIdVarianceSampleCanBeIncludedDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsByCreatedByIdVarianceSampleCreatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TrialsByCreatedByIdVarianceSampleCreatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TrialsByCreatedByIdVarianceSampleCreatedByIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CREATED_BY_ID_ASC',
  TrialsByCreatedByIdVarianceSampleCreatedByIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_CREATED_BY_ID_DESC',
  TrialsByCreatedByIdVarianceSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsByCreatedByIdVarianceSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsByCreatedByIdVarianceSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsByCreatedByIdVarianceSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsByCreatedByIdVarianceSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsByCreatedByIdVarianceSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsByCreatedByIdVarianceSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsByCreatedByIdVarianceSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsByCreatedByIdVarianceSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsByCreatedByIdVarianceSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsByCreatedByIdVarianceSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsByCreatedByIdVarianceSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsByCreatedByIdVarianceSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsByCreatedByIdVarianceSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsByCreatedByIdVarianceSampleHumanReadableIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsByCreatedByIdVarianceSampleHumanReadableIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsByCreatedByIdVarianceSampleIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_ID_ASC',
  TrialsByCreatedByIdVarianceSampleIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_ID_DESC',
  TrialsByCreatedByIdVarianceSampleInternalMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsByCreatedByIdVarianceSampleInternalMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsByCreatedByIdVarianceSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdVarianceSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsByCreatedByIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsByCreatedByIdVarianceSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsByCreatedByIdVarianceSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsByCreatedByIdVarianceSampleMessagesIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_MESSAGES_ID_ASC',
  TrialsByCreatedByIdVarianceSampleMessagesIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_MESSAGES_ID_DESC',
  TrialsByCreatedByIdVarianceSampleNameAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_NAME_ASC',
  TrialsByCreatedByIdVarianceSampleNameDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_NAME_DESC',
  TrialsByCreatedByIdVarianceSampleStaffIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_STAFF_ID_ASC',
  TrialsByCreatedByIdVarianceSampleStaffIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_STAFF_ID_DESC',
  TrialsByCreatedByIdVarianceSampleSubjectsNicknameAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsByCreatedByIdVarianceSampleSubjectsNicknameDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsByCreatedByIdVarianceSampleSubjectIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_SUBJECT_ID_ASC',
  TrialsByCreatedByIdVarianceSampleSubjectIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_SUBJECT_ID_DESC',
  TrialsByCreatedByIdVarianceSampleTenantIdAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  TrialsByCreatedByIdVarianceSampleTenantIdDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  TrialsByCreatedByIdVarianceSampleUpdatedAtAsc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TrialsByCreatedByIdVarianceSampleUpdatedAtDesc = 'TRIALS_BY_CREATED_BY_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  TrialsBySubjectIdAverageAvatarAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_AVATAR_ASC',
  TrialsBySubjectIdAverageAvatarDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_AVATAR_DESC',
  TrialsBySubjectIdAverageCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdAverageCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdAverageCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdAverageCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdAverageCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdAverageCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdAverageCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdAverageCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdAverageCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CREATED_AT_ASC',
  TrialsBySubjectIdAverageCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CREATED_AT_DESC',
  TrialsBySubjectIdAverageCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CREATED_BY_ID_ASC',
  TrialsBySubjectIdAverageCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_CREATED_BY_ID_DESC',
  TrialsBySubjectIdAverageEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdAverageEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdAverageHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdAverageHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdAverageHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdAverageHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdAverageHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdAverageHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdAverageHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdAverageHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdAverageHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdAverageHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdAverageHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdAverageHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdAverageHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdAverageHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdAverageIdAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_ID_ASC',
  TrialsBySubjectIdAverageIdDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_ID_DESC',
  TrialsBySubjectIdAverageInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdAverageInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdAverageMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdAverageMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdAverageMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdAverageMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdAverageMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdAverageMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdAverageMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_MESSAGES_ID_ASC',
  TrialsBySubjectIdAverageMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_MESSAGES_ID_DESC',
  TrialsBySubjectIdAverageNameAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_NAME_ASC',
  TrialsBySubjectIdAverageNameDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_NAME_DESC',
  TrialsBySubjectIdAverageStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_STAFF_ID_ASC',
  TrialsBySubjectIdAverageStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_STAFF_ID_DESC',
  TrialsBySubjectIdAverageSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdAverageSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdAverageSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_SUBJECT_ID_ASC',
  TrialsBySubjectIdAverageSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_SUBJECT_ID_DESC',
  TrialsBySubjectIdAverageTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_TENANT_ID_ASC',
  TrialsBySubjectIdAverageTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_TENANT_ID_DESC',
  TrialsBySubjectIdAverageUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_UPDATED_AT_ASC',
  TrialsBySubjectIdAverageUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_AVERAGE_UPDATED_AT_DESC',
  TrialsBySubjectIdCountAsc = 'TRIALS_BY_SUBJECT_ID_COUNT_ASC',
  TrialsBySubjectIdCountDesc = 'TRIALS_BY_SUBJECT_ID_COUNT_DESC',
  TrialsBySubjectIdDistinctCountAvatarAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_AVATAR_ASC',
  TrialsBySubjectIdDistinctCountAvatarDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_AVATAR_DESC',
  TrialsBySubjectIdDistinctCountCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdDistinctCountCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdDistinctCountCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdDistinctCountCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdDistinctCountCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdDistinctCountCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdDistinctCountCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdDistinctCountCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdDistinctCountCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CREATED_AT_ASC',
  TrialsBySubjectIdDistinctCountCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CREATED_AT_DESC',
  TrialsBySubjectIdDistinctCountCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CREATED_BY_ID_ASC',
  TrialsBySubjectIdDistinctCountCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_CREATED_BY_ID_DESC',
  TrialsBySubjectIdDistinctCountEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdDistinctCountEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdDistinctCountHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdDistinctCountHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdDistinctCountHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdDistinctCountHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdDistinctCountHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdDistinctCountHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdDistinctCountHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdDistinctCountHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdDistinctCountHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdDistinctCountHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdDistinctCountHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdDistinctCountHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdDistinctCountHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdDistinctCountHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdDistinctCountIdAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_ID_ASC',
  TrialsBySubjectIdDistinctCountIdDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_ID_DESC',
  TrialsBySubjectIdDistinctCountInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdDistinctCountInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdDistinctCountMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdDistinctCountMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdDistinctCountMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdDistinctCountMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdDistinctCountMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdDistinctCountMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_MESSAGES_ID_ASC',
  TrialsBySubjectIdDistinctCountMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_MESSAGES_ID_DESC',
  TrialsBySubjectIdDistinctCountNameAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_NAME_ASC',
  TrialsBySubjectIdDistinctCountNameDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_NAME_DESC',
  TrialsBySubjectIdDistinctCountStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_STAFF_ID_ASC',
  TrialsBySubjectIdDistinctCountStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_STAFF_ID_DESC',
  TrialsBySubjectIdDistinctCountSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdDistinctCountSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdDistinctCountSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_SUBJECT_ID_ASC',
  TrialsBySubjectIdDistinctCountSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_SUBJECT_ID_DESC',
  TrialsBySubjectIdDistinctCountTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_TENANT_ID_ASC',
  TrialsBySubjectIdDistinctCountTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_TENANT_ID_DESC',
  TrialsBySubjectIdDistinctCountUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_UPDATED_AT_ASC',
  TrialsBySubjectIdDistinctCountUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_DISTINCT_COUNT_UPDATED_AT_DESC',
  TrialsBySubjectIdMaxAvatarAsc = 'TRIALS_BY_SUBJECT_ID_MAX_AVATAR_ASC',
  TrialsBySubjectIdMaxAvatarDesc = 'TRIALS_BY_SUBJECT_ID_MAX_AVATAR_DESC',
  TrialsBySubjectIdMaxCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_MAX_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdMaxCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_MAX_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdMaxCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_MAX_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdMaxCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_MAX_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdMaxCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_MAX_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdMaxCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_MAX_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdMaxCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_MAX_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdMaxCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_MAX_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdMaxCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_MAX_CREATED_AT_ASC',
  TrialsBySubjectIdMaxCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_MAX_CREATED_AT_DESC',
  TrialsBySubjectIdMaxCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_MAX_CREATED_BY_ID_ASC',
  TrialsBySubjectIdMaxCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_MAX_CREATED_BY_ID_DESC',
  TrialsBySubjectIdMaxEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_MAX_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdMaxEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_MAX_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdMaxHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdMaxHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdMaxHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdMaxHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdMaxHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdMaxHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdMaxHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdMaxHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdMaxHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdMaxHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdMaxHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdMaxHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_MAX_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdMaxHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_MAX_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdMaxHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_MAX_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdMaxIdAsc = 'TRIALS_BY_SUBJECT_ID_MAX_ID_ASC',
  TrialsBySubjectIdMaxIdDesc = 'TRIALS_BY_SUBJECT_ID_MAX_ID_DESC',
  TrialsBySubjectIdMaxInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_MAX_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdMaxInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_MAX_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdMaxMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdMaxMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdMaxMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdMaxMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_MAX_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdMaxMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdMaxMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_MAX_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdMaxMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_MAX_MESSAGES_ID_ASC',
  TrialsBySubjectIdMaxMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_MAX_MESSAGES_ID_DESC',
  TrialsBySubjectIdMaxNameAsc = 'TRIALS_BY_SUBJECT_ID_MAX_NAME_ASC',
  TrialsBySubjectIdMaxNameDesc = 'TRIALS_BY_SUBJECT_ID_MAX_NAME_DESC',
  TrialsBySubjectIdMaxStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_MAX_STAFF_ID_ASC',
  TrialsBySubjectIdMaxStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_MAX_STAFF_ID_DESC',
  TrialsBySubjectIdMaxSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_MAX_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdMaxSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_MAX_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdMaxSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_MAX_SUBJECT_ID_ASC',
  TrialsBySubjectIdMaxSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_MAX_SUBJECT_ID_DESC',
  TrialsBySubjectIdMaxTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_MAX_TENANT_ID_ASC',
  TrialsBySubjectIdMaxTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_MAX_TENANT_ID_DESC',
  TrialsBySubjectIdMaxUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_MAX_UPDATED_AT_ASC',
  TrialsBySubjectIdMaxUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_MAX_UPDATED_AT_DESC',
  TrialsBySubjectIdMinAvatarAsc = 'TRIALS_BY_SUBJECT_ID_MIN_AVATAR_ASC',
  TrialsBySubjectIdMinAvatarDesc = 'TRIALS_BY_SUBJECT_ID_MIN_AVATAR_DESC',
  TrialsBySubjectIdMinCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_MIN_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdMinCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_MIN_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdMinCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_MIN_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdMinCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_MIN_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdMinCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_MIN_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdMinCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_MIN_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdMinCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_MIN_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdMinCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_MIN_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdMinCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_MIN_CREATED_AT_ASC',
  TrialsBySubjectIdMinCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_MIN_CREATED_AT_DESC',
  TrialsBySubjectIdMinCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_MIN_CREATED_BY_ID_ASC',
  TrialsBySubjectIdMinCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_MIN_CREATED_BY_ID_DESC',
  TrialsBySubjectIdMinEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_MIN_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdMinEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_MIN_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdMinHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdMinHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdMinHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdMinHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdMinHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdMinHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdMinHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdMinHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdMinHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdMinHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdMinHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdMinHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_MIN_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdMinHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_MIN_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdMinHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_MIN_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdMinIdAsc = 'TRIALS_BY_SUBJECT_ID_MIN_ID_ASC',
  TrialsBySubjectIdMinIdDesc = 'TRIALS_BY_SUBJECT_ID_MIN_ID_DESC',
  TrialsBySubjectIdMinInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_MIN_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdMinInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_MIN_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdMinMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdMinMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdMinMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdMinMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_MIN_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdMinMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdMinMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_MIN_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdMinMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_MIN_MESSAGES_ID_ASC',
  TrialsBySubjectIdMinMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_MIN_MESSAGES_ID_DESC',
  TrialsBySubjectIdMinNameAsc = 'TRIALS_BY_SUBJECT_ID_MIN_NAME_ASC',
  TrialsBySubjectIdMinNameDesc = 'TRIALS_BY_SUBJECT_ID_MIN_NAME_DESC',
  TrialsBySubjectIdMinStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_MIN_STAFF_ID_ASC',
  TrialsBySubjectIdMinStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_MIN_STAFF_ID_DESC',
  TrialsBySubjectIdMinSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_MIN_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdMinSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_MIN_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdMinSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_MIN_SUBJECT_ID_ASC',
  TrialsBySubjectIdMinSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_MIN_SUBJECT_ID_DESC',
  TrialsBySubjectIdMinTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_MIN_TENANT_ID_ASC',
  TrialsBySubjectIdMinTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_MIN_TENANT_ID_DESC',
  TrialsBySubjectIdMinUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_MIN_UPDATED_AT_ASC',
  TrialsBySubjectIdMinUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_MIN_UPDATED_AT_DESC',
  TrialsBySubjectIdStddevPopulationAvatarAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_AVATAR_ASC',
  TrialsBySubjectIdStddevPopulationAvatarDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_AVATAR_DESC',
  TrialsBySubjectIdStddevPopulationCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdStddevPopulationCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdStddevPopulationCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdStddevPopulationCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdStddevPopulationCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdStddevPopulationCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdStddevPopulationCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdStddevPopulationCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdStddevPopulationCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CREATED_AT_ASC',
  TrialsBySubjectIdStddevPopulationCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CREATED_AT_DESC',
  TrialsBySubjectIdStddevPopulationCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CREATED_BY_ID_ASC',
  TrialsBySubjectIdStddevPopulationCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_CREATED_BY_ID_DESC',
  TrialsBySubjectIdStddevPopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdStddevPopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdStddevPopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdStddevPopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdStddevPopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdStddevPopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdStddevPopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdStddevPopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdStddevPopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdStddevPopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdStddevPopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdStddevPopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdStddevPopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdStddevPopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdStddevPopulationHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdStddevPopulationHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdStddevPopulationIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_ID_ASC',
  TrialsBySubjectIdStddevPopulationIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_ID_DESC',
  TrialsBySubjectIdStddevPopulationInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdStddevPopulationInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdStddevPopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdStddevPopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdStddevPopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdStddevPopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdStddevPopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdStddevPopulationMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_MESSAGES_ID_ASC',
  TrialsBySubjectIdStddevPopulationMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_MESSAGES_ID_DESC',
  TrialsBySubjectIdStddevPopulationNameAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_NAME_ASC',
  TrialsBySubjectIdStddevPopulationNameDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_NAME_DESC',
  TrialsBySubjectIdStddevPopulationStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_STAFF_ID_ASC',
  TrialsBySubjectIdStddevPopulationStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_STAFF_ID_DESC',
  TrialsBySubjectIdStddevPopulationSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdStddevPopulationSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdStddevPopulationSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_SUBJECT_ID_ASC',
  TrialsBySubjectIdStddevPopulationSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_SUBJECT_ID_DESC',
  TrialsBySubjectIdStddevPopulationTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_TENANT_ID_ASC',
  TrialsBySubjectIdStddevPopulationTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_TENANT_ID_DESC',
  TrialsBySubjectIdStddevPopulationUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_UPDATED_AT_ASC',
  TrialsBySubjectIdStddevPopulationUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_POPULATION_UPDATED_AT_DESC',
  TrialsBySubjectIdStddevSampleAvatarAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_AVATAR_ASC',
  TrialsBySubjectIdStddevSampleAvatarDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_AVATAR_DESC',
  TrialsBySubjectIdStddevSampleCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdStddevSampleCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdStddevSampleCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdStddevSampleCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdStddevSampleCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdStddevSampleCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdStddevSampleCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdStddevSampleCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdStddevSampleCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CREATED_AT_ASC',
  TrialsBySubjectIdStddevSampleCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CREATED_AT_DESC',
  TrialsBySubjectIdStddevSampleCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CREATED_BY_ID_ASC',
  TrialsBySubjectIdStddevSampleCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_CREATED_BY_ID_DESC',
  TrialsBySubjectIdStddevSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdStddevSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdStddevSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdStddevSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdStddevSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdStddevSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdStddevSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdStddevSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdStddevSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdStddevSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdStddevSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdStddevSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdStddevSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdStddevSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdStddevSampleHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdStddevSampleHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdStddevSampleIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_ID_ASC',
  TrialsBySubjectIdStddevSampleIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_ID_DESC',
  TrialsBySubjectIdStddevSampleInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdStddevSampleInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdStddevSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdStddevSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdStddevSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdStddevSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdStddevSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdStddevSampleMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_MESSAGES_ID_ASC',
  TrialsBySubjectIdStddevSampleMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_MESSAGES_ID_DESC',
  TrialsBySubjectIdStddevSampleNameAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_NAME_ASC',
  TrialsBySubjectIdStddevSampleNameDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_NAME_DESC',
  TrialsBySubjectIdStddevSampleStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_STAFF_ID_ASC',
  TrialsBySubjectIdStddevSampleStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_STAFF_ID_DESC',
  TrialsBySubjectIdStddevSampleSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdStddevSampleSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdStddevSampleSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_SUBJECT_ID_ASC',
  TrialsBySubjectIdStddevSampleSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_SUBJECT_ID_DESC',
  TrialsBySubjectIdStddevSampleTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_TENANT_ID_ASC',
  TrialsBySubjectIdStddevSampleTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_TENANT_ID_DESC',
  TrialsBySubjectIdStddevSampleUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_UPDATED_AT_ASC',
  TrialsBySubjectIdStddevSampleUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_STDDEV_SAMPLE_UPDATED_AT_DESC',
  TrialsBySubjectIdSumAvatarAsc = 'TRIALS_BY_SUBJECT_ID_SUM_AVATAR_ASC',
  TrialsBySubjectIdSumAvatarDesc = 'TRIALS_BY_SUBJECT_ID_SUM_AVATAR_DESC',
  TrialsBySubjectIdSumCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_SUM_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdSumCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_SUM_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdSumCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_SUM_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdSumCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_SUM_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdSumCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_SUM_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdSumCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_SUM_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdSumCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_SUM_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdSumCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_SUM_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdSumCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_SUM_CREATED_AT_ASC',
  TrialsBySubjectIdSumCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_SUM_CREATED_AT_DESC',
  TrialsBySubjectIdSumCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_SUM_CREATED_BY_ID_ASC',
  TrialsBySubjectIdSumCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_SUM_CREATED_BY_ID_DESC',
  TrialsBySubjectIdSumEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_SUM_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdSumEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_SUM_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdSumHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdSumHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdSumHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdSumHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdSumHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdSumHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdSumHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdSumHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdSumHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdSumHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdSumHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdSumHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_SUM_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdSumHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_SUM_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdSumHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_SUM_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdSumIdAsc = 'TRIALS_BY_SUBJECT_ID_SUM_ID_ASC',
  TrialsBySubjectIdSumIdDesc = 'TRIALS_BY_SUBJECT_ID_SUM_ID_DESC',
  TrialsBySubjectIdSumInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_SUM_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdSumInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_SUM_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdSumMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdSumMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdSumMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdSumMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_SUM_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdSumMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdSumMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_SUM_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdSumMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_SUM_MESSAGES_ID_ASC',
  TrialsBySubjectIdSumMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_SUM_MESSAGES_ID_DESC',
  TrialsBySubjectIdSumNameAsc = 'TRIALS_BY_SUBJECT_ID_SUM_NAME_ASC',
  TrialsBySubjectIdSumNameDesc = 'TRIALS_BY_SUBJECT_ID_SUM_NAME_DESC',
  TrialsBySubjectIdSumStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_SUM_STAFF_ID_ASC',
  TrialsBySubjectIdSumStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_SUM_STAFF_ID_DESC',
  TrialsBySubjectIdSumSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_SUM_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdSumSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_SUM_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdSumSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_SUM_SUBJECT_ID_ASC',
  TrialsBySubjectIdSumSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_SUM_SUBJECT_ID_DESC',
  TrialsBySubjectIdSumTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_SUM_TENANT_ID_ASC',
  TrialsBySubjectIdSumTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_SUM_TENANT_ID_DESC',
  TrialsBySubjectIdSumUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_SUM_UPDATED_AT_ASC',
  TrialsBySubjectIdSumUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_SUM_UPDATED_AT_DESC',
  TrialsBySubjectIdVariancePopulationAvatarAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_AVATAR_ASC',
  TrialsBySubjectIdVariancePopulationAvatarDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_AVATAR_DESC',
  TrialsBySubjectIdVariancePopulationCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdVariancePopulationCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdVariancePopulationCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdVariancePopulationCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdVariancePopulationCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdVariancePopulationCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdVariancePopulationCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdVariancePopulationCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdVariancePopulationCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CREATED_AT_ASC',
  TrialsBySubjectIdVariancePopulationCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CREATED_AT_DESC',
  TrialsBySubjectIdVariancePopulationCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CREATED_BY_ID_ASC',
  TrialsBySubjectIdVariancePopulationCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_CREATED_BY_ID_DESC',
  TrialsBySubjectIdVariancePopulationEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdVariancePopulationEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdVariancePopulationHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdVariancePopulationHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdVariancePopulationHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdVariancePopulationHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdVariancePopulationHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdVariancePopulationHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdVariancePopulationHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdVariancePopulationHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdVariancePopulationHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdVariancePopulationHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdVariancePopulationHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdVariancePopulationHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdVariancePopulationHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdVariancePopulationHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdVariancePopulationIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_ID_ASC',
  TrialsBySubjectIdVariancePopulationIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_ID_DESC',
  TrialsBySubjectIdVariancePopulationInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdVariancePopulationInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdVariancePopulationMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdVariancePopulationMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdVariancePopulationMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdVariancePopulationMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdVariancePopulationMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdVariancePopulationMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_MESSAGES_ID_ASC',
  TrialsBySubjectIdVariancePopulationMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_MESSAGES_ID_DESC',
  TrialsBySubjectIdVariancePopulationNameAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_NAME_ASC',
  TrialsBySubjectIdVariancePopulationNameDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_NAME_DESC',
  TrialsBySubjectIdVariancePopulationStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_STAFF_ID_ASC',
  TrialsBySubjectIdVariancePopulationStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_STAFF_ID_DESC',
  TrialsBySubjectIdVariancePopulationSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdVariancePopulationSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdVariancePopulationSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_SUBJECT_ID_ASC',
  TrialsBySubjectIdVariancePopulationSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_SUBJECT_ID_DESC',
  TrialsBySubjectIdVariancePopulationTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_TENANT_ID_ASC',
  TrialsBySubjectIdVariancePopulationTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_TENANT_ID_DESC',
  TrialsBySubjectIdVariancePopulationUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_UPDATED_AT_ASC',
  TrialsBySubjectIdVariancePopulationUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_POPULATION_UPDATED_AT_DESC',
  TrialsBySubjectIdVarianceSampleAvatarAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_AVATAR_ASC',
  TrialsBySubjectIdVarianceSampleAvatarDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_AVATAR_DESC',
  TrialsBySubjectIdVarianceSampleCannotUseTheInterventionAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_ASC',
  TrialsBySubjectIdVarianceSampleCannotUseTheInterventionDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CANNOT_USE_THE_INTERVENTION_DESC',
  TrialsBySubjectIdVarianceSampleCanAccessErSinceAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_ASC',
  TrialsBySubjectIdVarianceSampleCanAccessErSinceDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CAN_ACCESS_ER_SINCE_DESC',
  TrialsBySubjectIdVarianceSampleCanAccessSipSinceAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_ASC',
  TrialsBySubjectIdVarianceSampleCanAccessSipSinceDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CAN_ACCESS_SIP_SINCE_DESC',
  TrialsBySubjectIdVarianceSampleCanBeIncludedAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_ASC',
  TrialsBySubjectIdVarianceSampleCanBeIncludedDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CAN_BE_INCLUDED_DESC',
  TrialsBySubjectIdVarianceSampleCreatedAtAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CREATED_AT_ASC',
  TrialsBySubjectIdVarianceSampleCreatedAtDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CREATED_AT_DESC',
  TrialsBySubjectIdVarianceSampleCreatedByIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CREATED_BY_ID_ASC',
  TrialsBySubjectIdVarianceSampleCreatedByIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_CREATED_BY_ID_DESC',
  TrialsBySubjectIdVarianceSampleEmergencyPhoneNumbersAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_ASC',
  TrialsBySubjectIdVarianceSampleEmergencyPhoneNumbersDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_EMERGENCY_PHONE_NUMBERS_DESC',
  TrialsBySubjectIdVarianceSampleHasAccessToASmartphoneAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_ASC',
  TrialsBySubjectIdVarianceSampleHasAccessToASmartphoneDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_ACCESS_TO_A_SMARTPHONE_DESC',
  TrialsBySubjectIdVarianceSampleHasAdequateKnowledgeOfGermanAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_ASC',
  TrialsBySubjectIdVarianceSampleHasAdequateKnowledgeOfGermanDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_ADEQUATE_KNOWLEDGE_OF_GERMAN_DESC',
  TrialsBySubjectIdVarianceSampleHasAgeBetween_18And_25Asc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_ASC',
  TrialsBySubjectIdVarianceSampleHasAgeBetween_18And_25Desc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_AGE_BETWEEN_18_AND_25_DESC',
  TrialsBySubjectIdVarianceSampleHasCurrentSuicidalIdeationAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_ASC',
  TrialsBySubjectIdVarianceSampleHasCurrentSuicidalIdeationDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_CURRENT_SUICIDAL_IDEATION_DESC',
  TrialsBySubjectIdVarianceSampleHasFacedAdverseChildhoodExperiencesAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_ASC',
  TrialsBySubjectIdVarianceSampleHasFacedAdverseChildhoodExperiencesDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_FACED_ADVERSE_CHILDHOOD_EXPERIENCES_DESC',
  TrialsBySubjectIdVarianceSampleHasGivenInformedConsentAtAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_ASC',
  TrialsBySubjectIdVarianceSampleHasGivenInformedConsentAtDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HAS_GIVEN_INFORMED_CONSENT_AT_DESC',
  TrialsBySubjectIdVarianceSampleHumanReadableIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_ASC',
  TrialsBySubjectIdVarianceSampleHumanReadableIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_HUMAN_READABLE_ID_DESC',
  TrialsBySubjectIdVarianceSampleIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_ID_ASC',
  TrialsBySubjectIdVarianceSampleIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_ID_DESC',
  TrialsBySubjectIdVarianceSampleInternalMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_ASC',
  TrialsBySubjectIdVarianceSampleInternalMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_INTERNAL_MESSAGES_ID_DESC',
  TrialsBySubjectIdVarianceSampleMeetsAllInclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdVarianceSampleMeetsAllInclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_ASC',
  TrialsBySubjectIdVarianceSampleMeetsAllInclusionCriteriaExceptInformedConsentDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_MEETS_ALL_INCLUSION_CRITERIA_EXCEPT_INFORMED_CONSENT_DESC',
  TrialsBySubjectIdVarianceSampleMeetsAnyExclusionCriteriaAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_ASC',
  TrialsBySubjectIdVarianceSampleMeetsAnyExclusionCriteriaDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_MEETS_ANY_EXCLUSION_CRITERIA_DESC',
  TrialsBySubjectIdVarianceSampleMessagesIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_MESSAGES_ID_ASC',
  TrialsBySubjectIdVarianceSampleMessagesIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_MESSAGES_ID_DESC',
  TrialsBySubjectIdVarianceSampleNameAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_NAME_ASC',
  TrialsBySubjectIdVarianceSampleNameDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_NAME_DESC',
  TrialsBySubjectIdVarianceSampleStaffIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_STAFF_ID_ASC',
  TrialsBySubjectIdVarianceSampleStaffIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_STAFF_ID_DESC',
  TrialsBySubjectIdVarianceSampleSubjectsNicknameAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_ASC',
  TrialsBySubjectIdVarianceSampleSubjectsNicknameDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_SUBJECTS_NICKNAME_DESC',
  TrialsBySubjectIdVarianceSampleSubjectIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_SUBJECT_ID_ASC',
  TrialsBySubjectIdVarianceSampleSubjectIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_SUBJECT_ID_DESC',
  TrialsBySubjectIdVarianceSampleTenantIdAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_TENANT_ID_ASC',
  TrialsBySubjectIdVarianceSampleTenantIdDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_TENANT_ID_DESC',
  TrialsBySubjectIdVarianceSampleUpdatedAtAsc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC',
  TrialsBySubjectIdVarianceSampleUpdatedAtDesc = 'TRIALS_BY_SUBJECT_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC'
}

/** All input for the create mn`PostingReceipt` mutation. */
export type MnCreatePostingReceiptInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `PostingReceipt` to be created by this mutation. */
  mnPostingReceipt?: InputMaybe<Array<PostingReceiptInput>>;
};

/** The output of our many create `PostingReceipt` mutation. */
export type MnCreatePostingReceiptPayload = {
  __typename?: 'mnCreatePostingReceiptPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Posting` that is related to this `PostingReceipt`. */
  posting?: Maybe<Posting>;
  /** The `PostingReceipt` that was created by this mutation. */
  postingReceipt?: Maybe<PostingReceipt>;
  /** An edge for our `PostingReceipt`. May be used by Relay 1. */
  postingReceiptEdge?: Maybe<PostingReceiptsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostingReceipt`. */
  user?: Maybe<User>;
};


/** The output of our many create `PostingReceipt` mutation. */
export type MnCreatePostingReceiptPayloadPostingReceiptEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};

/** All input for the delete `mnDeletePostingReceipt` mutation. */
export type MnDeletePostingReceiptInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `PostingReceipt` to be deleted. You must provide the PK values! */
  mnPatch?: InputMaybe<Array<PostingReceiptPatch>>;
};

/** The output of our delete mn `PostingReceipt` mutation. */
export type MnDeletePostingReceiptPayload = {
  __typename?: 'mnDeletePostingReceiptPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  deletedPostingReceiptNodeId?: Maybe<Scalars['ID']>;
  /** Reads a single `Posting` that is related to this `PostingReceipt`. */
  posting?: Maybe<Posting>;
  /** The `PostingReceipt` that was deleted by this mutation. */
  postingReceipt?: Maybe<PostingReceipt>;
  /** An edge for our `PostingReceipt`. May be used by Relay 1. */
  postingReceiptEdge?: Maybe<PostingReceiptsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostingReceipt`. */
  user?: Maybe<User>;
};


/** The output of our delete mn `PostingReceipt` mutation. */
export type MnDeletePostingReceiptPayloadPostingReceiptEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};

/** All input for the update `mnUpdatePostingReceipt` mutation. */
export type MnUpdatePostingReceiptInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The one or many `PostingReceipt` to be updated. */
  mnPatch?: InputMaybe<Array<PostingReceiptPatch>>;
};

/** The output of our update mn `PostingReceipt` mutation. */
export type MnUpdatePostingReceiptPayload = {
  __typename?: 'mnUpdatePostingReceiptPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input,                 unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Reads a single `Posting` that is related to this `PostingReceipt`. */
  posting?: Maybe<Posting>;
  /** The `PostingReceipt` that was updated by this mutation. */
  postingReceipt?: Maybe<PostingReceipt>;
  /** An edge for our `PostingReceipt`. May be used by Relay 1. */
  postingReceiptEdge?: Maybe<PostingReceiptsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `PostingReceipt`. */
  user?: Maybe<User>;
};


/** The output of our update mn `PostingReceipt` mutation. */
export type MnUpdatePostingReceiptPayloadPostingReceiptEdgeArgs = {
  orderBy?: InputMaybe<Array<PostingReceiptsOrderBy>>;
};
