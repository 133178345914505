<template>
  <slot
    v-for="task in tasks"
    :key="task.id"
    :task="task"
    :complete="((body: any) => completeTask({ id: task.id, ...body }))"
  ></slot>
  <slot v-if="!tasks || tasks?.length === 0" name="empty"></slot>
</template>

<script lang="ts" setup>
import { toRefs } from '@vueuse/core';
import { rawAccessToken } from '~/auth';
import { TypedVariable, VariableFilter } from '~/domain/TaskPostQuery';
import { useTasks, completeTask } from '~/utils/use-tasks';

// https://docs.camunda.org/manual/7.18/reference/rest/task/get-query/
// https://docs.camunda.org/manual/7.18/reference/rest/task/post-query/
const props = defineProps<{
  id?: string;
  assignedTo?: string | boolean;
  withKey?: string | string[];
  withVariables?: VariableFilter[];
  includeVariables?: boolean;
  includeLocalVariables?: boolean;
  inTrial?: string;
  poll?: number;
}>();

interface CompleteParams {
  id: string;
  variables?: Record<string, TypedVariable>;
  withVariablesInReturn?: boolean;
}

const {
  assignedTo,
  withKey,
  withVariables,
  includeLocalVariables,
  includeVariables,
  poll,
} = toRefs(props);

const { tasks } = useTasks({
  assignedTo,
  key: withKey,
  variables: withVariables,
  includeLocalVariables,
  includeVariables,
  poll,
});
</script>
