<template>
  <!-- 
  Proper nesting of <router-view>, <suspense>, and other related components is documented here:
  https://v3.vuejs.org/guide/migration/suspense.html#combining-with-other-components
-->
  <div>
    <router-view v-slot="{ Component, route }">
      <template v-if="Component">
        <suspense>
          <!-- show page-->
          <component :is="Component"></component>
          <!-- show fallback while `async setup()` is resolving... -->
          <template #fallback>loading...</template>
        </suspense>
      </template>
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { AvatarIcon } from '~/graphql/types';
import { useMyCurrentTrial } from '~/domain/use-my-current-trial';
const { trial } = useMyCurrentTrial();

const avatarImage = computed(() => {
  switch (trial.value?.avatar) {
    case AvatarIcon.Bird:
      return `url('/images/avatare/avatarbird_circle.svg')`;
    case AvatarIcon.Blue:
      return `url('/images/avatare/avatarblue_circle.svg')`;
    case AvatarIcon.Tree:
      return `url('/images/avatare/avatartree_circle.svg')`;
    case AvatarIcon.Elefant:
      return `url('/images/avatare/avatarelefant_circle.svg')`;
    case AvatarIcon.Purple:
      return `url('/images/avatare/avatarpurple_circle.svg')`;
    case AvatarIcon.Space:
      return `url('/images/avatare/avatarspace_circle.svg')`;
    case AvatarIcon.Dog:
      return `url('/images/avatare/avatardog_circle.svg')`;
    case AvatarIcon.Yellow:
      return `url('/images/avatare/avataryellow_circle.svg')`;
    case AvatarIcon.Waves:
      return `url('/images/avatare/avatarwaves_circle.svg')`;
    default:
      return `url('/images/avatare/avatarpurple_circle.svg')`;
  }
});
</script>

<style>
.avatar::before {
  background-image: v-bind(avatarImage);
}
</style>
