import { reverse } from 'lodash'
import { watch, Ref } from 'vue'

export const linkWithLocalStorage = (ref: Ref<string | null | undefined>, key: string) => {
  ref.value = localStorage.getItem(key)
  watch(ref, newValue => {
    if (newValue) localStorage.setItem(key, newValue)
    else localStorage.removeItem(key)
  })
  return ref
}

export const linkWithSessionStorage = (ref: Ref<string | null | undefined>, key: string) => {
  ref.value = sessionStorage.getItem(key)
  watch(ref, newValue => {
    if (newValue) sessionStorage.setItem(key, newValue)
    else sessionStorage.removeItem(key)
  })
  return ref
}